import React from 'react';
import {Card} from '../Card';
import {ImageContainer} from 'styles/styledComponents/containers';
import {BodyText, BoxTitle} from '../../stylesOpus/typography';
import {Link} from 'react-router-dom';
import {CountDown} from '../CountDown';

type CardItemProps = {
	link?: string;
    picture: string;
    title: string;
    subtitle: string;
	onClick?: () => void;
	score?:number
	remainTime?:string
}

export const CardItem = ({title,picture,subtitle,link,onClick, score, remainTime}:CardItemProps): JSX.Element => {
    
	return (
		<Link to ={link} className={'h-100 block'} onClick={onClick}>
			<Card $height={'100%'} padding ={'1.25rem 1rem'}>
				<div
					className={'w-100 h-100 flex direction-column row-gap'}
				>
					<div className={'flex align-start relative'}>
						{score && score > 0 ?
							<div
								className="absolute top-quarter right-quarter w-2rem h-2rem br-50 flex align-center justify-center f-10px t-white red">
								{(score * 100).toFixed(0)}
							</div>
							:
							null
						}
						<ImageContainer
							$width={'100%'}

							$aspectRatio={'1/1'}
							$src={picture}
							$borderRadius={'12px'}
						/>
					</div>
					<div>
						<BoxTitle>{title}</BoxTitle>
						<BodyText fontSize={'12px'}> {subtitle || ``}</BodyText>
					</div>
				</div>
				{remainTime ? <CountDown  className={'mt'} date={remainTime} /> :null}
			</Card>
		</Link>
	);
};