import React from 'react';
import styled from 'styled-components';
import {color} from 'styles/colors';

export interface BudgetProps {
	color?:string
	size?:number
	label?:{
		text:string
		size?:string
		color?:string
	}
}

const BudgetContainer:any=styled.div`
  width:${({size}:BudgetProps):string=>`${size}px`};
  height:${({size}:BudgetProps):string=>`${size}px`};
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({color}:BudgetProps):string=>color};
`;

const Label:any=styled.p`
  color: ${({color}:any):string=>color};
  font-size: ${({size}:any):string=>size};
`;



export const Budget = ({label,color:bgColor=color.get('neutral'),size=14}:BudgetProps):JSX.Element => {
	return <BudgetContainer color={bgColor} size={size}>
		<Label {...label}>{label.text}</Label>
	</BudgetContainer>;
};