import React from 'react';
import {IMultiSelector, IOption} from '../../DiscoverInterfaces';
import {InputBox, InputMultiSelect, InputOptionsListItem} from '../styledComponents';
import {LikeButton} from '../../../../components/ui/LikeCheck/LikeButton';

export default function MultiSelector({options=[], value=[], setValue}:IMultiSelector):JSX.Element {

	const setFieldValue = (id:string):void => {
		if(value.includes(id)){
			let v:string[] = [...value];
			const index:number = value.indexOf(id);
			if (index !== -1) {
				v.splice(index, 1);
				setValue(v);
			}
		}
		else{
			let v:string[] = [...value, id];
			setValue(v);
		}
	};

	return (
		<div className="mt">
			{options.map((option:IOption):JSX.Element=>{
				return (
					<InputBox margin="0 0 12px" key={option._id} onClick={():void=>setFieldValue(option._id)}>
						<InputMultiSelect>
							<div className="grows">
								{option.label}
							</div>
							<LikeButton isChecked={value.includes(option._id)}/>
						</InputMultiSelect>

					</InputBox>
				);
			})}
		</div>
	);
}