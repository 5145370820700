import styled,{css} from 'styled-components';
import {getSize,getVariant} from '../themes/button';
import {color} from '../colors';

interface ButtonStyleProps{
	variant:string
	size:string
	width?:string
	rounded?:boolean
}

export const buttonStyles:any=css`
  color: white;
  width: ${({width}:ButtonStyleProps):string=>width || '355px'};
  white-space: nowrap;
  padding: 0 2rem;
  ${({variant}:ButtonStyleProps):string=>getVariant(variant)}
  ${({size}:ButtonStyleProps):string=>getSize(size)}
  border-radius:${ (props:ButtonStyleProps):string=>!props.rounded? '0.5rem' : '40px'  };
  font-family: Urbanist, sans-serif;
		font-weight: 800;
  cursor:pointer;
  border:0;

  :focus {
    // outline: 2px solid ${color.get('neutral-400')};
  }

  :disabled{
			background-color: ${color.get('neutral-600')};
			color: ${color.get('neutral-400')};
  }
`;
export const StyledButton:any=styled.button<ButtonStyleProps>`
	${buttonStyles}
`;

export const StyledAnchorButton:any=styled.a<ButtonStyleProps>`
  	display :flex;
  	align-items: center;
  	justify-content: center;
	${buttonStyles}

`;
