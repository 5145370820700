import {color} from 'styles/colors';
import styled from 'styled-components';

export const Container:any=styled.div`
  position:relative;
  display:flex;
  background: ${color.get('neutral-600')};
  border-radius: 24px;
  padding: 0.5rem;
`;
export const DBOption:any=styled.p`
  padding: 6px 16px;
  width: 93px;
  height: 30px;
  
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  	z-index: 10;
  cursor: pointer;
`;

export const Selected:any=styled.div`
	position:absolute;
  width: 93px;
  height: 30px;
	
  background: #FF5656;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  
  transform: translateX(${({selected}:any):string=>`${selected*100}%`});

`;

