import styled from 'styled-components';
import {mediaQuery} from '../mediaQueries';
import {themeOpus} from '../../stylesOpus/theme';
import {CardPropsRenderProps} from '../../components/Card/styles';

interface LinkTabProps {
	$active?:boolean
}

export const ProductContent:any=styled.section`
  flex-grow: 1;
  height: 100%;
  padding: 20px 24px ;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HelloAvatar:any=styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
`;

export const Paragraph:any=styled.p`
  font-size: 14px;
  line-height: 150%;
`;

export const Section:any = styled.section`
  width:100%;
  margin-bottom: 2rem;
 ${mediaQuery('mobile')}{
   display: flex;
   flex-direction: column;
 }
`;

export const SectionTitle:any = styled.h6`
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 1rem;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
`;

type GenericContainerProps = {
	color?:string
	rounded?:boolean
	padding ? :string
	margin? :string
	width? :string
}
export const GenericContainer:any = styled.div<GenericContainerProps>`
	display: flex;

	padding: ${ ({padding}:CardPropsRenderProps):string=> padding? padding : '0 1.5rem' };
	margin: ${ ({margin}:CardPropsRenderProps):string=> margin? margin : '0' };
	align-items: center;
	width: ${ ({width}:GenericContainerProps):string=> width? width : 'fit-content' };
	border-radius: ${({rounded}:GenericContainerProps):string=>rounded? '100%': '6.25rem'};
	background: ${({color}:GenericContainerProps):string=>color? color: themeOpus.colors.grey_900};

  	p{
      font-family: ${({fontFamily}:any):string=> fontFamily || `'Urbanist', sans-serif`};
	  font-size: 14px;
	}
`;

type ImageProps = {
	$src:string

	$borderRadius?:string
	$width?:string
	$height?:string
	$aspectRatio?:string
}

// eslint-disable-next-line @typescript-eslint/typedef
export const ImageContainer = styled.div<ImageProps>`
	width: ${({$width}:ImageProps):string=> $width? $width : '100%'};
	height: ${({$height}:ImageProps):string=> $height? $height : 'auto'};
	aspect-ratio: ${({$aspectRatio}:ImageProps):string=> $aspectRatio? $aspectRatio : 'auto'};
	background-position: center;
	background-color: ${themeOpus.colors.grey_1000};
	background-image: url(${({$src}:ImageProps):string=> $src});
	background-size: cover;
  	flex-shrink: 0;

	border-radius: ${({$borderRadius}:ImageProps):string=> $borderRadius? $borderRadius : '0'};
`;
