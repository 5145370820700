import {Item} from 'interfaces';
import {color} from 'styles/colors';
import styled from 'styled-components';

export interface CheckBoxButtonProps {
	defaultSelected?:boolean
	active?:boolean
	item:Item

	onClick?: (value)=>void
}

export const Container:any =styled.div`
  display: flex;
  padding: 6px 6px 6px 16px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  height: 30px;
  border-radius: 30px;
  border: 1px solid ${({defaultSelected}:CheckBoxButtonProps):string=>defaultSelected? color.get('primary'):color.get('neutral-400')};
  cursor: pointer;
  margin: 0.8rem 0;
`;

export const Label:any =styled.p`
  margin-left: 0.5rem;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${({defaultSelected}:CheckBoxButtonProps):string=>defaultSelected?  color.get('white'):color.get('neutral-400')};
`;