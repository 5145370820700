import React from 'react';
import AudienceByCountryRender from './AudienceByCountryRender';
import {Chart} from 'interfaces';
import {sortItems} from 'tools';


export const AudienceByCountry=({items,length}:Chart):JSX.Element=>{
	sortItems(items);
	return <AudienceByCountryRender
		countries={items.slice(0,length)}
	/>;
};
