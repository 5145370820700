import styled from 'styled-components';
import {themeOpus} from 'stylesOpus/theme';
import {Link} from 'react-router-dom';
import {CircleButton} from '../ui';
import {ReactComponent as ChevronLeft} from 'styles/icons/chevron-left.svg';

export const ChevronRight:any = styled(ChevronLeft)`
  transform: rotate(180deg);
  >path{
    stroke: #585E81
  }
`;

export const BreadCrumbContainer:any = styled.div`
    display: flex;
    align-items: center;
    gap: 1.25rem;
  .crumbs{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: ${themeOpus.colors.grey_900};
    padding: 0 1rem;
    height: 30px;
    border-radius: 50px;
  }
`;

export const CrumbLink:any = styled(Link)<{$active:boolean}>`
    display: flex;
    padding: 0.5rem ;
    height: 100%;
    font-size: 10px;
    color: ${ ({ $active }:any):string=> $active ? themeOpus.colors.white : '#585E81' };
    text-transform: capitalize;
    font-family: 'Lato', sans-serif;

`;

export const BackButton:any = styled(CircleButton)`
  width: 30px;
  height: 30px;
`;