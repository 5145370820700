import React, {useState} from 'react';
import styled from 'styled-components';
import {CulturalItem, ItemsCollection} from 'components';
import {LinkButton} from 'components/ui';
import {CulturalAlert} from 'interfaces';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import {DeleteModal} from './components/DeleteModal';
import {ContentLayout} from '../../containers';
import {AlertsHeader, CreateAlertModal} from '../../containers/AlertsContainer/components';

interface AlertsProps {
	alerts:CulturalAlert[]
	deleteModal : {
		isOpen:boolean
		open : (id:string)=>void
		close : ()=>void
	}

	deleteAction: ()=>Promise<void>
}

const Title:any =styled.p`
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
`;

const Text1:any =styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 136%;
`;

const Text2:any =styled.p`
  margin-top: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
`;

export default function CompanyAlertsRender({alerts, deleteModal, deleteAction}:AlertsProps):JSX.Element {
	const navigate:NavigateFunction= useNavigate();
	const [showModal,setShowModal]=useState<boolean>(false);
	const editPaht=(alert:CulturalAlert):string=> {
		const path : string = alert.culturalType?.toLowerCase() === 'musicvideo' ? `/company/alerts/${alert._id}/edit/musicvideo`
			: `/company/alerts/${alert._id}/edit/event`;
		console.log({path, alert});
		return path;
	};
	return <ContentLayout
		header={ alerts?.length ?
			<AlertsHeader action={():void=>setShowModal(true)}/> : null
		}
	>
		{showModal && <CreateAlertModal
			close={():void=>setShowModal(false)}
		/>}
		{alerts?.length ?
			<ItemsCollection>
				{ deleteModal.isOpen ?
					<DeleteModal
						close={deleteModal.close}
						deleteAction={deleteAction}
					/> : null
				}
				{alerts.map((alert:CulturalAlert):JSX.Element=>
					<CulturalItem
						key={alert._id}
						showAction
						culturalAlert= {alert}
						button={{
							label:'Edit',
							action:():void=>navigate(editPaht(alert))
						}}
						openDeleteModal={deleteModal.open}
					/>
				)}
			</ItemsCollection>
			:

			<div className={'w-100 grows flex justify-center direction-column align-center'}>
				<Title className={''}>Let’s Get Started!</Title>
				<div className={'mt-4 mb flex direction-column align-center'}>
					<Text1>Create an Opportunity Alert</Text1>
					<Text2>Select Alert Type</Text2>
				</div>
				<LinkButton
					className="mb"
					to={'/company/alerts/new/musicvideo'}
					label={'Music Video'}
				/>
				<LinkButton
					to={'/company/alerts/new/event'}
					label={'Events'}
					variant={'secondary'}
				/>
			</div>
		}
	</ContentLayout>;
}