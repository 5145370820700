import React, {useState} from 'react';
import {InputProps} from 'interfaces';
import InputRender from './InputRender';
import {CheckIcon, InvalidIcon} from 'icons';

export const Input=({value,valid,onBlur,...props}:InputProps):JSX.Element=>{
	const [focus,setFocus]=useState(false);

	const renderIcon =():JSX.Element =>{
		if(focus)
			return;
		return valid? <CheckIcon/> : <InvalidIcon/>;
	} ;

	const onblurHandle =():void=>{
		setFocus(false);
		onBlur();
	};
	return <InputRender
		value={value}
		valid={valid}
		StatusIcon={renderIcon()}
		onFocus={():void=>setFocus(true)}
		onBlur={onblurHandle}
		{...props}
	/>;
};
