import React, {useContext} from 'react';
import BioRender from './BioRender';
import {QueryResult, useQuery} from '@apollo/client';
import {query} from 'actions/queries';
import {ProfileContext} from '../../index';
import {useOutletContext} from 'react-router-dom';
import {SerpapiKnowledge} from 'interfaces';
import {UserContext} from '../../../../context/userContext';

export const Bio=():JSX.Element=>{

	const {feed,user}:ProfileContext=useOutletContext();
	let knowledge_graph:SerpapiKnowledge=feed?.knowledge_graph||{};
	const {data:userData}:QueryResult=useQuery(query('usersFindOne'),{
		variables:{userId:user._id}
	});

	const {data:profileData,loading:profileLoading}:QueryResult=useQuery(query('usersTalentProfileFindOne'),{variables:{
		user:user._id
	}});

	console.log(profileData?.usersTalentProfileFindOne?.description);

	console.log({knowledge_graph});
	return <BioRender
		user={user}
		bio={profileData?.usersTalentProfileFindOne?.description||''}
		birthday={profileData?.usersTalentProfileFindOne?.birthday||''}
		{...knowledge_graph}
		nationality={userData?.usersFindOne.nationality}
		parents={userData?.usersFindOne.parents}
		interests={userData?.usersFindOne.parents||[]}
	/>;
};
