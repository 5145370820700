import styled from 'styled-components';

export type TextProps = {
    textAlign?: 'left' | 'center' | 'right'
	fontSize?: string
	fontFamily?: `'Urbanist', sans-serif`| `'Lato', sans-serif`
    color?: string
    lineHeight?: string
}


export const PageTitle:any = styled.div<TextProps>`
	font-family: 'Urbanist', sans-serif;
	font-size: ${({fontSize}:any):string => fontSize || '18px'};
	line-height: 110%;
  	font-weight: bold;
    ${({theme: {colors}}:any):string => colors.white};
`;


export const BodyText:any = styled.p<TextProps>`
	font-family: ${({fontFamily}:TextProps):string=> fontFamily || `'Lato', sans-serif`};
	font-size: ${({fontSize}:TextProps):string => fontSize || '1rem'};
	line-height: 150%;
  	color: ${({theme: {colors}, color}:any):string => color ? color : colors.grey_80};
    text-align: ${({textAlign}:TextProps):string => textAlign || 'left'};

    a{
        color: ${({theme: {colors}}:any):string => colors.grey_80};
        text-decoration: underline;
    }
`;

export const BoxTitle:any = styled.h3<TextProps>`
  	font-family: 'Urbanist', sans-serif;
  	font-weight: ${({fontWeight}:any):string => fontWeight || 'bold'};
  	font-size: ${({fontSize}:any):string => fontSize || '14px'};
  	color: ${({color, theme: {colors}}:any):string => color ? color: colors.white};
    text-align: ${({textAlign}:any):string => textAlign || 'left'};
  	letter-spacing: 1px;
    line-height: ${({lineHeight}:any):string => lineHeight || 'auto'};
`;

export const ErrorText:any = styled.h3<TextProps>`
	font-family: 'Urbanist', sans-serif;
  	font-weight: 600;
  	font-size: ${({fontSize}:any):string => fontSize || '12px'};
    text-align: ${({textAlign}:any):string => textAlign || 'left'};
  	color: ${({theme: {colors}}:any):string => colors.red};
  	letter-spacing: 1px;
`;


export const InputLabel :any = styled.label<TextProps>`
  display: block;
  color: #FFF;
  font-family: 'Urbanist', sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 137.5%;
`;
