import styled from 'styled-components';
import {themeOpus} from 'stylesOpus/theme';

export const TotalReachContainer : any = styled.div<{$grow:string, padding:string, $gap:string}>`
  display: flex;
  flex-direction: column;
		position: relative;
  height: auto;
  justify-content: center;
  align-items: center;
  padding: ${({padding}:any):string=>padding ? padding : '0'};
  border-radius: 2rem;
  flex-grow: ${({$grow}:any):string=>$grow? $grow : 'unset'};
  gap: ${({$gap}:any):string=>$gap ? $gap : '0'};
  background-color: ${themeOpus.colors.grey_1000};
		box-shadow: 4px 4px 20px rgba(0,0,0,0.25);
		overflow: hidden;
`;
