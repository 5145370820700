import React from 'react';

export const TikTokIcon=():JSX.Element=>{
	return <svg
		width={40}
		height={40}
		viewBox="0 0 40 40"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect width={40} height={40} rx={15} fill="black"/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M21.7308 25.6759C21.639 27.7501 19.9333 29.3851 17.8579 29.3876C17.2463 29.3851 16.644 29.238 16.1 28.9584C15.5561 28.6788 15.086 28.2745 14.728 27.7786C15.2801 28.0664 15.8941 28.2203 16.5169 28.2252C18.6022 28.2215 20.3129 26.5704 20.3898 24.4863V6H25.0132C25.0093 6.39255 25.0421 6.78462 25.1112 7.17105H21.7308V25.6759ZM31.4242 12.421V13.4493C30.0629 13.1535 28.8352 12.4218 27.9272 11.3653C28.963 12.0538 30.18 12.421 31.4242 12.421ZM17.6518 15.9353V17.027C15.5667 17.0731 13.5716 17.8852 12.0471 19.3083C8.62576 22.5052 8.44341 27.8705 11.6402 31.2918C8.59351 29.1718 7.27732 25.3162 8.39255 21.7758C9.50777 18.2353 12.7939 15.8299 16.5068 15.8373C16.8903 15.8427 17.2729 15.8754 17.6518 15.9353Z"
			fill="#25F4EE"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M26.3463 7.16237C26.3453 8.70957 26.9081 10.2041 27.9292 11.3665C27.1983 10.8942 26.5715 10.2778 26.0871 9.5549C25.6027 8.83202 25.2709 8.01791 25.112 7.16237H26.3463ZM12.6459 24.3635C12.6348 22.2199 14.3641 20.4732 16.5077 20.462C16.8935 20.4509 17.2793 20.4955 17.6527 20.5973V17.0196H17.8586C18.242 17.0221 18.6253 17.0518 19.0036 17.1089V21.8142C18.6327 21.7001 18.2469 21.6393 17.8586 21.6343C17.0416 21.6332 16.2452 21.8907 15.5835 22.37C13.8517 23.6254 13.4647 26.0469 14.7189 27.7787C14.0963 27.4507 13.5745 26.9598 13.2094 26.3582C12.8442 25.7567 12.6495 25.0672 12.6459 24.3635ZM31.4249 17.027V13.4494C31.8666 13.5436 32.3156 13.5908 32.7672 13.5933V18.1981C30.4643 18.2017 28.2184 17.4823 26.3462 16.1413V25.5147C26.3461 26.681 26.1056 27.8347 25.6396 28.9038C25.1737 29.9729 24.4923 30.9345 23.6381 31.7285C20.2043 34.9204 14.8341 34.7244 11.6422 31.2919C13.0656 32.2959 14.7656 32.8334 16.5076 32.8301C21.198 32.8301 25.0027 29.0329 25.0126 24.3424V14.943C26.8783 16.2935 29.1217 17.0226 31.4249 17.027Z"
			fill="#FE2C55"
		/>
		<path
			d="M25.0118 24.3437V14.9442C26.8834 16.2863 29.1297 17.0058 31.4327 17.001V13.4233C30.0722 13.1357 28.8419 12.4139 27.927 11.3665C27.1965 10.8938 26.57 10.2773 26.0856 9.55447C25.6012 8.83165 25.2692 8.01773 25.1098 7.16236H21.7294V25.676C21.6413 27.8184 19.8351 29.4832 17.6927 29.3964C17.1066 29.3726 16.5335 29.2162 16.0165 28.9391C15.4996 28.6619 15.0522 28.2711 14.708 27.7961C12.8112 26.7975 12.0831 24.4504 13.0817 22.5536C13.4094 21.9308 13.9003 21.4089 14.5019 21.0438C15.1035 20.6786 15.7931 20.484 16.4968 20.4807C16.8851 20.4844 17.2709 20.544 17.6418 20.6593V17.0283C12.9601 17.1077 9.22861 21.0216 9.30801 25.7033C9.34392 27.8235 10.1729 29.8533 11.6315 31.3924C13.0689 32.3633 14.7712 32.8664 16.5055 32.8326C21.1972 32.8314 25.0019 29.0341 25.0118 24.3437Z"
			fill="white"
		/>
	</svg>;
};
