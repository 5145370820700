import React, { ForwardedRef, forwardRef } from 'react';
import {Input, InputWrapper} from 'styles/ui';
import {AutoCompleteSearchProps, Item, SelectProps} from 'interfaces';
import {Spinner} from '../Spinner';
import {Wrapper, OptionsContainer, Option} from  'styles/ui';
import {WithGenericItem} from '../../../hocs/WithGenericItem';
import {CloseIcon, PlusIcon, SearchIcon} from 'icons';
import {themeOpus} from 'stylesOpus/theme';
import styled from 'styled-components';

type appendableSearchAutoCompleteRender = {
	findOneAction:string
} & AutoCompleteSearchProps<Item> & AppendableSelectRenderProps;

const ItemWrapper: any = styled(InputWrapper)`
    display: flex;
  justify-content: space-between;
  >p{
    font-weight: 700;
    font-family: 'Urbanist', Snas-serif;
    color: ${themeOpus.colors.light_grey_500};
  }
`;
const Label:any = styled.label`
	font-weight: 700;
	font-family: 'Urbanist', Snas-serif;
	
	display: block;
`;

interface AppendableSelectRenderProps{
	selectProps:SelectProps
	selectedItems:Item[]
	unSelect:(item:Item)=>void
	isSelectShown:boolean
	isEmpty:boolean
	showSelect:()=>void
}

export const SearchAutoCompleteRender:any= forwardRef((props:appendableSearchAutoCompleteRender, ref:ForwardedRef<any>):JSX.Element=>{
	const {selectedOptions,
		label,
		options,
		findOneAction,
		required,
		onChangeQuery,
		onSelectOption,
		onUnselectOption,
		isSelectShown,
		isEmpty,
		showSelect,
		...rest}:appendableSearchAutoCompleteRender=props;

	return 	<div className="mb">
		<Label className ='mb'>{label}</Label>
		

		{ selectedOptions.map((i:Item):JSX.Element =>
			<WithGenericItem key={i.id} id={i.id} findOneAction={findOneAction}>
				{ (item:Item):JSX.Element=>
					<ItemWrapper
						key = {item.id}
						localTheme={'opusV2'}
					>
						<p >{item.label}</p>
						<div onClick={():void=>onUnselectOption(item)}>
							<CloseIcon/>
						</div>
					</ItemWrapper>
				}
			</WithGenericItem>
		)}

	


		{isSelectShown || isEmpty ?
			<Wrapper localTheme={'opusV2'}>
				<div>
					<div style={{marginBottom:'4px'}}>
						<Label>{label}</Label>
					</div>
					<Input
						{...rest}
						required={required}
						onChange={onChangeQuery}
					/>
				</div>
	
				<>
					{options.show&& <OptionsContainer  ref={ref}>
						{ options.loading?
							<div className={'w-100 h-100 flex justify-center align-center'}>
								<Spinner color={'neutral-400'} />
							</div>
							:
							options.items.map((item:Item,index:number):JSX.Element=>
								<Option
									key={index+item.label}
									onClick={():void=>onSelectOption(item)}
								>{item.label}
								</Option>
							)
						}
					</OptionsContainer>}
				</>
				<div onClick={showSelect}>
					<SearchIcon/>
				</div>
			</Wrapper>
			:
			<ItemWrapper localTheme={'opusV2'}>
				<Label>Add another</Label>
				<div onClick={showSelect}>
					<PlusIcon/>
				</div>
			</ItemWrapper>
		}


	</div>;
});

SearchAutoCompleteRender.displayName = 'SearchAutoCompleteRender';