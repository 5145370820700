export * from './chart';
export const addHeaders=(value:any):any=>
	localStorage.getItem('token') ? {...value,'token':localStorage.getItem('token')}
		:
		value;

export const authResponse=(response:Response):Error|any=>{
	console.log('token...');
	if(!response.ok && response.headers.get('content-type')?.indexOf('application/json')=== -1){
		return response.text().then((res:string):void=>{
			throw new Error(res);
		});
	}
	return response.json().then((res:any):any=>{
		if(res['token'])
			localStorage.setItem('token',res['token']);
		return res;
	});
};

export const verifyToken = ():boolean=>{
	return !!localStorage.getItem('token');
};

export const NumberTenthsFilter=(number:string,decPlaces:number):string=>{
	let abr:any = ['K','M','B','T'];
	let Number:number = parseInt(number);
	decPlaces = Math.pow(10,decPlaces);
	for(let i:number=abr.length-1 ; i>=0 ; i-- ){
		let size:number = Math.pow(10,(i+1)*3);
		if(size <= Number){
			Number = Math.round((Number*decPlaces)/size)/decPlaces;
			if((Number===1000) && (i<abr.length-1)){
				Number=1;
				i++;
			}
			Number+=abr[i];
			break;
		}
	} return Number.toString();
};

export const DateOrderFilter = (date:string):string => {
	let DateOrder:Date = new Date(date);
	return DateOrder.toLocaleDateString().split('/').join('-');
};

export const RegexURLValidation = (url:any):any => {
	if(url!=null){
		let regex:RegExpMatchArray|null = url.split(/((https:\/\/)|(http:\/\/))(www\.)?(twitter)|(tiktok)|(instagram)|(snapchat)\.com\/[A-Za-z0-9_.]{1,30}\/[A-Za-z0-9_.]{1,30}\/?/g);
		if(regex){
			let arr:Array<string> = url.split('/').reverse();
			return arr[0].toString();
		}else{
			return new Error('invalid username');
		}
	}else{
		return new Error('invalid URL');
	}
};