import React from 'react';
import {Container, Grid, OverLay, Wrapper} from './styles';
import {ImageSelect} from '../../ImageSelect';
import {Item} from 'interfaces';

export interface GridSelectRenderProps {
	options:Item[]
	selected:string[]
	onClick:(item:Item)=>void
}

export default function GridSelectRender({options,selected,onClick}:GridSelectRenderProps):JSX.Element{

	return <Container id="container">
		<Grid>
			{options.length&& options.map((item: Item, index: any):JSX.Element => (
				<Wrapper selected={selected.includes(item.id)} id="wrapper" key={index} onClick={():void => onClick(item)}>
					<OverLay id="overlay" selected={selected.includes(item.id)} />
					<ImageSelect id="image" image={item.url} text={item.label}  />
				</Wrapper>
			))}

		</Grid>
	</Container>;
}
