import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { NavItem } from 'interfaces';

type SelectDotContainerProps = {
	index: number;
	array: NavItem[];
};

type DotProps = {
	init: number;
	end: number;
};

const Container : any = styled.section<{ count: number }>`
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	row-gap: 1rem;
	height: fit-content;
	width: 1.25rem;
`;

const translateYAnimation = ({ init, end }: DotProps):any => keyframes`
  
	0% {
		transform: translateY( ${`calc(${init * 100}% + ${init}rem)`} );
	} 
	
	80%{
		transform: translateY( ${`calc(${end * 100}% + ${end}rem ${ (end === init )?  '' :  end- init >0 ? '+' : '-' } 0.5rem )`} )
	}
  
	100% {
		transform: translateY(${`calc(${end * 100}% + ${end}rem)`});
	}
`;

const DotContainer : any = styled.div<DotProps>`
	width: 2.38rem;
	height: 2.38rem;
	display: flex;
	justify-content: center;
	align-items: center;
	animation: ${(props:DotProps):string=>translateYAnimation(props)} 0.4s 1 ease-in-out;
	transform: translateY(${(props:DotProps):string=>`calc(${props.end * 100}% + ${props.end}rem)`});
`;

const Dot : any = styled.div`
	background: #1c2fdd;
	box-shadow: 0 0 8px 0 #1c2fdd;
	border-radius: 100%;
	height: 0.5rem;
	width: 0.5rem;
`;

export const SelectDotContainer = ({ index, array }: SelectDotContainerProps): JSX.Element => {
	const [DotPos, setDotPos] = useState(index);
	const [prev, setPrev] = useState(index);

	useEffect(():void => {
		handleChangePos(index);
	}, [index]);
	const handleChangePos = (newIndex: number): void => {
		setPrev(DotPos);
		setDotPos(newIndex);
	};

	const animationProps: DotProps = {
		init: prev,
		end: DotPos
	};

	return (
		<Container>
			<DotContainer className={'absolute top-0 '} {...animationProps}>
				{index<0 ? null : <Dot/>}
			</DotContainer>
			{array.map((item: NavItem, idx: number):JSX.Element => (
				<DotContainer key={idx} />
			))}
		</Container>
	);
};
