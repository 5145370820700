import React from 'react';
import {IconProps} from 'interfaces';

export const LikeIcon=({size=17}:IconProps):JSX.Element=>

	<svg width={size} height={size} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M4.98804 7.74995L7.98804 0.999954C8.58477 0.999954 9.15707 1.23701 9.57903 1.65896C10.001 2.08092 10.238 2.65322 10.238 3.24995V6.24995H14.483C14.7005 6.24749 14.9158 6.29233 15.1142 6.38137C15.3126 6.4704 15.4892 6.60151 15.6319 6.76559C15.7746 6.92968 15.8799 7.12282 15.9405 7.33164C16.0012 7.54047 16.0157 7.75997 15.983 7.97495L14.948 14.725C14.8938 15.0826 14.7121 15.4087 14.4365 15.643C14.1608 15.8773 13.8098 16.004 13.448 16H4.98804M4.98804 7.74995V16M4.98804 7.74995H2.73804C2.34021 7.74995 1.95868 7.90799 1.67738 8.18929C1.39607 8.4706 1.23804 8.85213 1.23804 9.24995V14.5C1.23804 14.8978 1.39607 15.2793 1.67738 15.5606C1.95868 15.8419 2.34021 16 2.73804 16H4.98804"
			stroke="white"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>;
    