import React, {useContext, useEffect} from 'react';
import {Button, FormButton, GridSelect, LinkButton} from 'components/ui';
import {FormProps, Item} from 'interfaces';
import styled from 'styled-components';
import {OnboardingContext} from 'context/onboardingContext';
import {OnboardingForm, OnboardingStepper} from 'containers/OnBoarding/components';
import {ModalHook} from 'hooks/useModal';
import {Modal} from 'components/Modal';
import {Card, Message} from 'components';
import {color} from '../../../../styles/colors';
interface InterestProps  extends FormProps{
	interestsOptions:Item[]
	cancelModal:ModalHook
}

const Content:any = styled.main`
	padding: 4rem 4rem ;
	flex-grow: 1;
	overflow-y: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Container:any = styled.div`
	max-height: 30.25rem;
	max-width: 79rem;
	flex-grow: 1;
	overflow-y: hidden;
	display: flex;
	column-gap: 5rem;
	
	>div:first-child{
		overflow-y: scroll;
	}

	>div:last-child{
		flex-grow: 1;
		width: 27.5rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

`;


export const InterestRender = ({interestsOptions,registerList,errors,cancelModal, onSubmit}:InterestProps): JSX.Element => {
	const {setCurrentStep}:any =useContext(OnboardingContext);
	useEffect(():void => {
		setCurrentStep(3);
	}, []);

	return<Content>
		{cancelModal.isOpen && <Modal onClickOut={(): void => cancelModal.close()}>
			<Card className="max-w-500px">
				<h2>Are you sure you want to exit?</h2>
				<div className={'flex direction-column row-gap align-center mt-3'}>
					<LinkButton
						to={'/dashboard'}
						label={'Exit Sign Up'}
						variant={'white'}
					/>
					<Button variant="secondary"
						onClick={(): void => cancelModal.close()}
						label={'Keep Going'}
					/>
				</div>

			</Card>
		</Modal>}
		<Container>
			<GridSelect
				options={interestsOptions}
				{...registerList('interests')}
				max={5}
			/>
			<div className={'w-100 flex direction-column align-center'}>
				<OnboardingStepper/>
				<OnboardingForm
					title={'What are you into ?'}
					onSubmit={onSubmit}
				>

					<FormButton
						variant={'white'}
						label={'Finish'}
					/>
					<Button
						variant={'dark-grey'}
						label={'Cancel'}
						onClick={cancelModal.open}
					/>
					{errors&&<div className={'mt center'}>
						{errors.get('submitError') && <Message message={errors.get('submitError')} color={color.get('primary')}/>}
						{errors.get('interests')?.required && <Message message={'select at least one interest'} color={color.get('primary')}/>}
					</div>}
				</OnboardingForm>
			</div>
		</Container>
	</Content>;
};
