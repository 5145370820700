import React from 'react';

interface FieldBlockProps{
	title?:string
	description?:string
	children?:React.ReactNode
}

export default function FieldBlock({title, description, children}:FieldBlockProps):JSX.Element {
	return (
		<div className="pver mb">
			<h4 className="f-bold">
				{title}
			</h4>
			<p>
				{description}
			</p>
			{children}
		</div>
	);
}