import React from 'react';
import {Item, TalentOpportunity, User} from 'interfaces';

import styled from 'styled-components';

import {LinkTab} from 'containers/Opportunities/styles';

interface OpportunitiesProps {
	children:React.ReactNode
	opportunities:TalentOpportunity[]
	path:string
	tabsLinks:Item[]
	user:User
}

const ContentContainer:any = styled.section`
	width: 100%;
	justify-self: stretch;
  	padding: 0 1.5rem;
  overflow-x:hidden;
`;

export const Content=({children,path,tabsLinks}:OpportunitiesProps):JSX.Element=>{

	return <ContentContainer >
		<div className={'flex w-200px col-gap justify-between mb pb-half'}>
			{tabsLinks.map((tab:Item):JSX.Element=>
				<LinkTab
					styleTheme={'opusV2'}
					key={tab.id}
					to={tab.id}
					$active={path===tab.label}
				>{tab.label}
				</LinkTab>
			)}
		</div>
		{children}
	</ContentContainer>;
};