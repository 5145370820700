import React, {useState} from 'react';
import DiscoveryTalentModalRender from './DiscoveryTalentModalRender';

interface TalentModalsProps{
	talentId: string
	isVisible: boolean
	setShowTalent: (show:boolean)=>void
	add:boolean
	score?:any
}

export const DiscoveryTalentModal = ({talentId, isVisible, setShowTalent, add, score}:TalentModalsProps):JSX.Element => {
	const [showAddList, setShowAddList] = useState<boolean>(false);

	return <DiscoveryTalentModalRender
		currentTalent={talentId}
		showTalent={isVisible}
		setShowTalent={setShowTalent}
		setShowAddList={setShowAddList}
		showAddList={showAddList}
		add={add}
		score={score}
	/>;
};