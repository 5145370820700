import React from 'react';

import {color} from 'styles/colors';
import {Message, PictureInput} from 'components';
import {FormProps} from 'interfaces';
import { FormButton, Input, SearchAutoComplete, TextBox} from 'components/ui';

export interface CreateProductFormProps extends FormProps {
	defaultPicture?:string
	edit?:boolean
	onChangeFile:(file:File)=>void
}


export const CreateProductForm=({edit=false, defaultPicture, disabled, submitLoading,errors,onSubmit,registerList, registerTextArea,registerInput,onChangeFile}:CreateProductFormProps):JSX.Element =>{
	// console.log({registerName:registerInput('name')});
	return (
		<div className='w-100 p'>
			<div className='w-100'>
				<h2 className="center" >{edit?`Edit product`:'Create a product'}</h2>
				{edit||<p className="mb-2 center">Add a brand/product</p>}
			</div>
			<div className='w-100 flex row justify-center mver'>
				<PictureInput
					defaultImage={defaultPicture}
					onChange={onChangeFile}
					rounded='20%'
					plus
				/>
			</div>
			<form onSubmit={onSubmit} autoComplete='off'>
				<Input
					label='PRODUCT NAME'
					{...registerInput('name')}
				/>
				<SearchAutoComplete
					label={'Categories *'}
					placeholder={'Type for search'}
					searchName={'interestSearchMany'}
					findOneAction={'interestFindOne'}
					{...registerList('interest')}
				/>
				<TextBox
					label='DESCRIPTION'
					{...registerTextArea('description')}
				/>

				<p className="center">
					<FormButton
						disabled={disabled}
						loading={submitLoading}
						label='CONTINUE'
					/>
					{errors?<div className={'mt center'}>
						{errors.get('submitError') && <Message message={errors.get('submitError')} color={color.get('primary')}/>}
					</div>:null}
				</p>
			</form>
		</div>
	);
};
