import React, {useEffect, useState} from 'react';
import GenderPie from '../../../../pages/Insights/Components/GenderPie';
import GenderAge from '../../../../pages/Insights/Components/GenderAge';
import AnalyticsPie from '../../../analytics/Pie';
import {Line, Tab, Tabs, Circle} from '../../../../stylesOpus/ui';
import {ContentBox, Flex} from '../../../../stylesOpus/layout';
import {AudienceItem} from '../../../../pages/Insights/interfaces';
import {BoxTitle} from '../../../../stylesOpus/typography';

interface AudienceGraphsProps{
	genderAge?: AudienceItem[]
	ethnicities?: AudienceItem[]
	reach?:number
}

const colors:string[] = [
	'purple',
	'lile',
	'light_grey_500',
	'grey_600',
	'grey_300',
	'purple',
	'lile',
	'light_grey_500',
	'grey_600',
	'grey_300'

];

export default function AudienceGraphs({genderAge, ethnicities, reach}:AudienceGraphsProps):JSX.Element {

	const [tab, setTab] = useState<string>('gender');
	const [gender, setGender] = useState<AudienceItem[]>([]);
	const [age, setAge] = useState<AudienceItem[]>([]);

	useEffect(():any => {
		if(genderAge) {
			let m:number = 0, f:number = 0;
			genderAge.map(({name, weight}:AudienceItem):void=>{
				if(name.charAt(0) === 'M'){
					m = m+weight;
				}
				else if(name.charAt(0) === 'F'){
					f = f+weight;
				}
			});
			setGender([{name : 'Male', weight: m}, {name : 'Female', weight: f}]);
		}

		if(genderAge) {
			let one:number = 0, two:number = 0, three:number = 0, four:number = 0, five:number = 0, six:number = 0;

			genderAge.map(({name, weight}:AudienceItem):void=>{
				if(name.includes('13-17')){
					one = one+weight;
				}
				else if(name.includes('18-24')){
					two = two+weight;
				}
				else if(name.includes('25-34')){
					three = three+weight;
				}
				else if(name.includes('35-44')){
					four = four+weight;
				}
				else if(name.includes('45-64')){
					five = five+weight;
				}
				else if(name.includes('65-')){
					six = six+weight;
				}
			});
			setAge([{name : '13-17', weight: one}, {name : '18-24', weight: two}, {name : '25-34', weight: three}, {name : '35-44', weight: four}, {name : '45-64', weight: five}, {name : '65+', weight: six}]);
		}

	},[genderAge]);

	return (
		<ContentBox padding="2rem">
			<BoxTitle textAlign="center" className="mver">
				Total Reach
			</BoxTitle>
			<h1 className="center">
				{reach?.toLocaleString()}
			</h1>

			<Tabs className='mt-2 mb' background="grey_1000">
				<Tab onClick={():void=>setTab('gender')} selected={tab === 'gender' ? true : false}>
					Gender
				</Tab>
				<Tab onClick={():void=>setTab('age')} selected={tab === 'age' ? true : false}>
					Age
				</Tab>
				<Tab onClick={():void=>setTab('ethnicity')} selected={tab === 'ethnicity' ? true : false}>
					Ethnicity
				</Tab>
			</Tabs>
			{tab==='gender' &&
				<>
					<div className="mb-2">
						{genderAge &&
							<GenderPie colors={['#6125B2', '#95AAFC', '#424242','#5B5E7A','#262932']} doughtnut={true} items={genderAge} />
						}
					</div>

					{gender && gender.length && gender.map(({name, weight}:AudienceItem, index:number):JSX.Element => {
						return (
							<div key={`${name}-${index}-gender`}>
								<Flex className="pver" key={name}>
									<Circle color={colors[index]} width="8px" height="8px" className="mr" /> <BoxTitle fontSize="13px">{name}</BoxTitle> <div className="grows" /> <BoxTitle fontSize="13px">{(weight * 100).toFixed(2)}%</BoxTitle>
								</Flex>
								{index < 4 && <Line color="grey_600" /> }
							</div>
						);
					})}

				</>
			}
			{tab === 'age' &&
				<>
					<div className="mb-2">
						{genderAge &&
							<GenderAge colors={['#6125B2', '#95AAFC', '#424242','#5B5E7A','#262932']} doughtnut={true} items={genderAge}/>
						}
					</div>
					<div>
						{age && age.length && age.map(({name, weight}:AudienceItem, index:number):JSX.Element => {
							return (
								<div  key={`${name}-${index}-age`}>
									<Flex className="pver" key={name}>
										<Circle color={colors[index]} width="8px" height="8px" className="mr" /> <BoxTitle fontSize="13px">{name}</BoxTitle> <div className="grows" /> <BoxTitle fontSize="13px">{(weight * 100).toFixed(2)}%</BoxTitle>
									</Flex>
									{index < age.length - 1 && <Line color="grey_600" /> }
								</div>
							);
						})}
					</div>
				</>
			}
			{tab === 'ethnicity' &&
				<>
					<div className="mb-2">
						{ethnicities &&
							<AnalyticsPie colors={['#6125B2', '#95AAFC', '#424242','#5B5E7A','#262932']} doughtnut={true} items={ethnicities}/>
						}
					</div>
					{ethnicities && ethnicities.length && ethnicities.map(({name, weight}:AudienceItem, index:number):JSX.Element => {
						return (
							<div  key={`${name}-${index}-eth`}>
								<Flex className="pver" key={name}>
									<Circle color={colors[index]} width="8px" height="8px" className="mr" /> <BoxTitle fontSize="13px">{name}</BoxTitle> <div className="grows" /> <BoxTitle fontSize="13px">{(weight * 100).toFixed(2)}%</BoxTitle>
								</Flex>
								{index < ethnicities.length - 1 && <Line color="grey_600" /> }
							</div>
						);
					})}
				</>
			}


		</ContentBox>
	);
}