import React, {useEffect, useState} from 'react';
import Selector from '../form/Selector';
import {IFilter, IInterest, IMatch, IMatchAudience, IOption, ITalent, ITalentFilter} from '../../DiscoverInterfaces';
import {QueryResult, useQuery} from '@apollo/client';
import {query} from '../../../../actions/queries';
import {InputBox, InterestBox} from '../styledComponents';
import {CloseIcon} from '../../../../icons';
import {useRecoilState} from 'recoil';
import {filterAtom, interestFormAtom, matchQueryAtom} from '../../../../context/recoil/store';

interface AIRI {
	options: IOption[]
}

export default function AudienceInterestRender({options}:AIRI):JSX.Element {

	const [matchQuery, setMatchQuery] = useRecoilState<IMatch>(matchQueryAtom);
	const [filter, setFilter] = useRecoilState<IFilter>(filterAtom);
	const [value, setValue] = useState<string[]>([]);
	const [interestform, setInterestForm] = useRecoilState<string[]>(interestFormAtom);
	const [loadingC, setLoadingC] = useState<boolean>(true);


	useEffect(():void=>{
		if(loadingC) {
			setValue(interestform);
			setLoadingC(false);
		}
	},[loadingC]);


	const setFieldValue = (interest:string):void => {
		const v:string[] = [...value];
		if(!value.includes(interest)){
			v.push(interest);
			setValue(v);
		}
	};

	const removeInterest = (_id:string):void => {
		if(value.includes(_id)){
			let v:string[] = [...value];
			const index:number = value.indexOf(_id);
			if (index !== -1) {
				v.splice(index, 1);
				setValue(v);
			}
		}
	};

	useEffect(():void => {
		let m: IMatch = {...matchQuery};
		let a: IMatchAudience = {...matchQuery.audience};

		m.audience = a;

		const valFinal:string[] = value.map((v:string):string=>{
			const n:IOption = options.find((e:IOption):boolean=>e.value === v);
			return n.label;
		});

		setInterestForm(value);

		m.audience.topic = [...valFinal];
		setMatchQuery(m);

		// const ids:string[] = value.map((name:string):string => {
		// 	let o:IOption = options.find((e:IOption):boolean => name === e.label);
		// 	return o._id;
		// });
		// let f: IFilter = {...filter};
		// let t: ITalentFilter = {...filter.talent};
		// f.talent = t;
		// f.talent.authority = [...ids];
		//setFilter(f);

	},[value]);


	return (
		<div>
			{value?.length > 0 && value.map((label:string):JSX.Element => {
				return(
					<InputBox key={label}>
						<InterestBox>
							<div className="grows">
								{label}
							</div>
							<a onClick={():void=>removeInterest(label)}>
								<CloseIcon width={16} height={16} />
							</a>
						</InterestBox>
					</InputBox>
				);
			})}
			<Selector title="Interest" placeholder="Select Interest" options={options} setFieldValue={setFieldValue} />
		</div>
	);
}