import React from 'react';

import CampaignListRender from './CampaignListRender';
import {Opportunity} from 'interfaces';

type OpportunityListProps = {
	opportunities: Opportunity[]
}
export default function CampaignList({opportunities}:OpportunityListProps):JSX.Element {

	return (
		<CampaignListRender opportunities={opportunities}  />
	);
}