import React from 'react';
import {IconProps} from 'interfaces';

export const CopyIcon=({color='white', size=14}:IconProps):JSX.Element=>
	<svg width={size} height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M2.57031 10.2734C1.9375 10.2734 1.45898 10.1133 1.13477 9.79297C0.810547 9.47266 0.648438 8.99805 0.648438 8.36914V2.39844C0.648438 1.76953 0.810547 1.29492 1.13477 0.974609C1.45898 0.654297 1.9375 0.494141 2.57031 0.494141H8.50586C9.13867 0.494141 9.61719 0.65625 9.94141 0.980469C10.2656 1.30078 10.4277 1.77344 10.4277 2.39844V3.77539H9.27344V2.49805C9.27344 2.21289 9.20117 2 9.05664 1.85938C8.91211 1.71484 8.70508 1.64258 8.43555 1.64258H2.64062C2.36719 1.64258 2.1582 1.71484 2.01367 1.85938C1.87305 2 1.80273 2.21289 1.80273 2.49805V8.26953C1.80273 8.55469 1.87305 8.76953 2.01367 8.91406C2.1582 9.05469 2.36719 9.125 2.64062 9.125H4.13477V10.2734H2.57031ZM5.48828 13.0625C4.85547 13.0625 4.37695 12.9023 4.05273 12.582C3.72852 12.2617 3.56641 11.7871 3.56641 11.1582V5.18164C3.56641 4.55664 3.72852 4.08398 4.05273 3.76367C4.37695 3.43945 4.85547 3.27734 5.48828 3.27734H11.4238C12.0566 3.27734 12.5352 3.43945 12.8594 3.76367C13.1836 4.08789 13.3457 4.56055 13.3457 5.18164V11.1582C13.3457 11.7832 13.1836 12.2559 12.8594 12.5762C12.5352 12.9004 12.0566 13.0625 11.4238 13.0625H5.48828ZM5.55859 11.9141H11.3535C11.623 11.9141 11.8301 11.8418 11.9746 11.6973C12.1191 11.5566 12.1914 11.3438 12.1914 11.0586V5.28711C12.1914 5.00195 12.1191 4.78906 11.9746 4.64844C11.8301 4.50391 11.623 4.43164 11.3535 4.43164H5.55859C5.28516 4.43164 5.07617 4.50391 4.93164 4.64844C4.78711 4.78906 4.71484 5.00195 4.71484 5.28711V11.0586C4.71484 11.3438 4.78711 11.5566 4.93164 11.6973C5.07617 11.8418 5.28516 11.9141 5.55859 11.9141Z"
			fill={color}
		/>
	</svg>


;
