
import {ApolloClient, InMemoryCache} from '@apollo/client';
import {addHeaders} from '../tools';

const REACT_APP_GRAPHQL:string|undefined = process.env.REACT_APP_GRAPHQL;

export const client =():ApolloClient<any> => new ApolloClient({
	uri: REACT_APP_GRAPHQL,
	headers:addHeaders({}),
	cache: new InMemoryCache()
});