import React, {useEffect, useState} from 'react';
import {OpportunityCreateRender} from './OpportunityCreateRender';
import {Opportunity, OpportunityAudience} from 'interfaces';
import {Outlet, useLocation, Location, Params, useParams} from 'react-router-dom';
import {MutationFunction, MutationTuple, QueryTuple, useLazyQuery, useMutation} from '@apollo/client';
import {mutation} from 'actions/mutations';
import {query} from 'actions/queries';

export type OpportunityFormContext = {
    active:boolean
    opportunity:Opportunity
    audience:OpportunityAudience
    activateOpportunity:any
    refetch:()=>Promise<void>
}

export const OpportunityCreate = ():JSX.Element => {
	const { id }:Params= useParams();
	const [isFirstRender,setIsFirstRender] = useState<boolean>(!!id);
	const [isLoading,setIsLoading] = useState<boolean>(true);
	const {pathname}:Location=useLocation();
	let form :string = pathname.split('/')[4];
	!form && (form ='details');
	// eslint-disable-next-line @typescript-eslint/typedef
	const[getOpportunity, {data:opportunityData,loading:opportunityLoading, refetch:opportunityRefetch}]:QueryTuple<any, any>=useLazyQuery(query('opportunityFindOne'),{
		variables:{filter:{_id:id}}
	});
	// eslint-disable-next-line @typescript-eslint/typedef
	const[getAudience, {data:audienceData,loading:audienceLoading,refetch:audienceRefetch}]:QueryTuple<any, any>=useLazyQuery(query('opportunityAudienceFindOne'),{
		variables:{filter:{opportunity:id}},
		fetchPolicy: 'network-only'
	});

	const refetch = async():Promise<void> => {
		opportunityRefetch();
		audienceRefetch();
	};

	const getData = async():Promise<void> => {
		await getOpportunity();
		getAudience();
	};

	useEffect(():void => {
		if(id){
			getData();
		}
	}, []);

	const [isActive,setIsActive] = React.useState<boolean>(opportunityData?.opportunityFindOne?.active || false);
	/// update active


	const [activateOpportunity]:MutationTuple<MutationFunction, any> =useMutation(mutation('opportunityUpdateOne'),{
		variables:{
			_id:id, record:{active:isActive}
		}
	});

	useEffect(():void => {
		setIsFirstRender(false);
		if(!isFirstRender){
			setIsLoading(opportunityLoading || audienceLoading);
		}
	}, [opportunityLoading,audienceLoading]);

	const formsContext:OpportunityFormContext = {
		active: isActive,
		opportunity: opportunityData?.opportunityFindOne,
		audience: audienceData?.opportunityAudienceFindOne,
		activateOpportunity,
		refetch
	};
	const handleIsActive =  ({target}: React.ChangeEvent<HTMLInputElement>):void => {
		setIsActive(target.checked);
	};
	return (
		<OpportunityCreateRender
			id={id}
			form={form}
			isLoading={isLoading}
			opportunity={opportunityData?.opportunityFindOne}
			handleIsActive={handleIsActive}
		>
			<Outlet  context={formsContext}/>
		</OpportunityCreateRender>
	);
};