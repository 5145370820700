import React from 'react';

export const VerifiedIcon=():JSX.Element=>
	<svg
		width={115}
		height={115}
		viewBox="0 0 115 115"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M57.5004 0C25.7945 0 0 25.7941 0 57.4996C0 89.2051 25.7945 114.999 57.5004 114.999C89.2059 114.999 115 89.2051 115 57.4996C115 25.7941 89.2059 0 57.5004 0ZM57.5004 105.573C30.9921 105.573 9.42617 84.0079 9.42617 57.4996C9.42617 30.9917 30.9921 9.42617 57.5004 9.42617C84.0083 9.42617 105.574 30.9917 105.574 57.4996C105.574 84.0079 84.0083 105.573 57.5004 105.573Z"
			fill="#2DB669"
		/>
		<path
			d="M82.3744 35.4308L48.2344 69.5712L32.6254 53.9623C30.7846 52.1219 27.8007 52.1219 25.96 53.9623C24.1196 55.803 24.1196 58.787 25.96 60.6277L44.9017 79.5694C45.822 80.4894 47.0282 80.9498 48.2344 80.9498C49.4405 80.9498 50.6467 80.4894 51.5671 79.5694L89.0399 42.0966C90.8802 40.2559 90.8802 37.2719 89.0399 35.4312C87.1991 33.5904 84.2148 33.5904 82.3744 35.4308Z"
			fill="#2DB669"
		/>
	</svg>
;
