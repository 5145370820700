import React, {useEffect, useState} from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie, Doughnut } from 'react-chartjs-2';
import {AudienceItem} from '../interfaces';

ChartJS.register(ArcElement, Tooltip, Legend);

const initialColors:string[] = [
	'rgba(149, 150, 272, 1)',
	'rgba(97, 37, 178, 1)'
];

interface AudienceCat {
	items?: AudienceItem[],
	colors?: string[]
	doughtnut?: boolean
}


interface DataSetItem {
	label?: string
	data: number[]
	backgroundColor?: string[],
	borderWidth? : number
}

interface DataItem {
	labels: string[]
	datasets: DataSetItem[]
}



export default function GenderPie({items, colors=initialColors, doughtnut=false}:AudienceCat):JSX.Element {

	const [data, setData] = useState<DataItem>({
		labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
		datasets: [
			{
				label: '# of Votes',
				data: [12, 19, 3, 5, 2, 3],
				backgroundColor: [
					'rgba(255, 99, 132, 0.2)',
					'rgba(54, 162, 235, 0.2)',
					'rgba(255, 206, 86, 0.2)',
					'rgba(75, 192, 192, 0.2)',
					'rgba(153, 102, 255, 0.2)',
					'rgba(255, 159, 64, 0.2)'
				],
				borderWidth: 0
			}
		]
	});

	useEffect(():any => {

		let m:number = 0;
		let f:number = 0;

		items.map(({name, weight}:AudienceItem):void=>{
			if(name.charAt(0) === 'M'){
				m = m+weight;
			}
			else if(name.charAt(0) === 'F'){
				f = f+weight;
			}
		});

		let d:DataItem = {
			labels: ['Male', 'Female'],
			datasets: [
				{
					label: '# of Votes',
					data: [m*100, f*100],
					backgroundColor: colors,
					borderWidth: 0
				}
			]
		};
		setData(d);

	}, [items]);

	return doughtnut ?
		<Doughnut
			data={data}
			options={{
				cutout: 98,
				plugins: {
					legend : {
						display: false
					}
				}

			}}
		/>
		:
		<Pie data={data} />;
}
