import React from 'react';
import {FormProps, Item} from 'interfaces';
import {DropDown, FormButton} from 'components/ui';
import {TagsSelect} from 'components/ui/TagsSelect';

export interface BrandFormRenderProps extends FormProps {
	brands:Item[]
	shoeSizes:Item[]
	clothingSizes:Item[]
}

export const BrandFormRender = ({brands,shoeSizes,clothingSizes,registerList,onSubmit}:BrandFormRenderProps):JSX.Element=>{
	return (
		<div className="pb-4 w-100 max-w-700px">
			<form onSubmit={onSubmit} >
				<DropDown
					label={'Add your favorite brands'}
					placeholder={'select to add brands'}
					options={brands}
					{...registerList('brands')}
				/>
				<div className="grid2 col-gap-2">
					<TagsSelect
						label={'Shoe Size'}
						options={shoeSizes}
						{...registerList('shoeSizes')}
					/>
					<TagsSelect
						label={'Apparel Size'}
						options={clothingSizes}
						{...registerList('clothingSizes')}
					/>
				</div>

				<div className="center">
					<FormButton
						variant={'secondary'}
						label={'Save'}
					/>
				</div>
			</form>
		</div>
	);
};