import React from 'react';
import {RoundImage} from '../RoundImage';
import {ImageWrapper} from '../RoundImage/styles';
import {TPicture} from 'interfaces';

export interface AvatarUser {
	profilePicture?:TPicture
	firstName?:string;
	lastName?:string;
}

export interface AvatarRenderProps{
	profilePicture:AvatarUser['profilePicture'];
	size:string;
	fontSize?:string;
	userInitials:string;
	plus?: boolean
	rounded?: string
}

export default function AvatarRender({
	plus,
	profilePicture,
	size,
	rounded,
	userInitials,
	fontSize='0.5rem'
}:AvatarRenderProps):JSX.Element{
	return (
		<>
			{ profilePicture ?
				<RoundImage
					src={profilePicture?.url}
					size={size}
					rounded={rounded}
				/>
				:
				<ImageWrapper
					size={size}
					rounded={rounded}
					className={'neutral-500 flex justify-center align-center'}
				>
					{plus ? '+' : <p style={{fontSize:fontSize}}>{userInitials}</p>}
				</ImageWrapper>
			}
		</>
	);
}
