import React from 'react';
import {Link} from 'react-router-dom';
import {TabLinkDiv} from './styles';

interface TabLinkProps {
	styleTheme?:'opusV2'|'opus'
	link:string;
	selected?:boolean;
	children?:React.ReactNode;
}

export default function TabLink({children, styleTheme='opus', link, selected}:TabLinkProps):JSX.Element {
	return (
		<Link to={link} className="inline-flex mr">
			<TabLinkDiv
				styleTheme={styleTheme}
				selected={selected}
			>
				{children}
			</TabLinkDiv>
		</Link>
	);
}