import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {buttonStyles} from 'styles/ui';

export const StyledLinkButton:any=styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  ${buttonStyles}
`;