import React from 'react';
import {CompanyProfile, NavItem, User} from 'interfaces';
import {LogoContainer, MenuItemContainer, MenuWrapper, TextLogo} from './sideMenu.styles';
import {BodyText} from 'stylesOpus/typography';
import {Logout} from '../Logout';
import {Profile} from './components/Profile';
import {VerticalMenu} from 'components/VerticalMenu';

interface SideMenuRenderProps {
	user:User
	styleTheme?:'opus'|'opusV2'
	companyProfile:CompanyProfile
	show:boolean
	activePath:string
	navItems:NavItem[]
	generalNav:NavItem[]
}

export default function SideMenuRender({styleTheme,show,activePath,navItems,generalNav}:SideMenuRenderProps):JSX.Element{
	const array : NavItem[] = navItems.concat(generalNav);
	return (
		<MenuWrapper styleTheme={styleTheme} className={`${show? `show` : `hide`}`}>
			<div>
				<LogoContainer>
					<TextLogo >ØPUS</TextLogo>
					<BodyText
						className={'align-self-end'}
						fontSize={'8px'}
						fontFamily = {`'Urbanist'`}
					>V1.0.0</BodyText>
				</LogoContainer>
				<section className={'flex direction-column row-gap'}>

					<VerticalMenu
						navItems={array}
						currentItem={activePath}
					/>

					<MenuItemContainer $active={false}>
						<Logout/>
					</MenuItemContainer>

				</section>
				<section >
					<Profile/>
				</section>
			</div>
		</MenuWrapper>
	);
}
