import React from 'react';
import {FormButton, LinkButton, VerificationCode} from 'components/ui';
import {VerifiedIcon} from 'icons';
import {FormProps, User} from 'interfaces';
import {Message} from 'components';
import {color} from 'styles/colors';
import {OnboardingForm, OnboardingStepper} from '../../containers/OnBoarding/components';

interface VerifyRenderProps extends FormProps{
	user:User;
	verified:boolean;
	loading?:boolean;
	nextPath:string;
	sendCode():any;
}

export default function VerifyRender({user,verified,nextPath,onSubmit,onChangeInput,errors,submitLoading,sendCode}:VerifyRenderProps):JSX.Element{

	return (
		<section className="verified flex direction-column align-center justify-center w-100">
			<OnboardingStepper/>
			<>{!verified ?
				<OnboardingForm
					title={'Let\'s Verify Your Email'}
					onSubmit={onSubmit}
				>
					{/*<div className="lh-150 mver center">*/}
					{/*	<p>Please enter the 4 digit code</p>*/}
					{/*	<p>sent to</p>*/}
					{/*	<p>{user.email}</p>*/}
					{/*</div>*/}

					<div className="w-100 mb-2">
						<VerificationCode onChange={onChangeInput} codeSize={4}/>
					</div>

					<FormButton
						loading={submitLoading}
						variant={'white'}
						label={'VERIFY'}
					/>
					{errors.size?<div className={'mt center'}>
						<Message message={errors.get('network')} color={color.get('primary')}/>
						{errors.submitError && <Message message={errors.get('submitError')} color={color.get('primary')}/>}
					</div>:null}

					<div className="mt center">
						<p className={'lh-200'}>Didn’t Receive OTP Code?</p>
						<p onClick={sendCode} className={'t-primary lh-200 f-700 cursor-pointer'}>Resend Code</p>
					</div>
				</OnboardingForm> :

				<>
					<h1 className={'h-100 center'}>Lets Go!</h1>
					<div>
						<div className={'mver-3 flex justify-center align-center'}>
							<VerifiedIcon/>
						</div>
						<p className="center mb-2">You have successfully verified your account.</p>
					</div>
					<div className="flex justify-center">
						<LinkButton
							className={'align-self-end'}
							variant={'white'}
							// to={'/onboarding/link-social'}
							to={nextPath}
							label={'CONTINUE'}
						/>
					</div>
				</>
			}
			</>
		</section>
	);
}
