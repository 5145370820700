import React, {useContext} from 'react';
import LogoutRender from './LogoutRender';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import {UserContext} from 'context/userContext';

export const Logout=():JSX.Element=>{
	const {logout}:any=useContext(UserContext);
	const navigate: NavigateFunction=useNavigate();
	const logoutAction =():void=>{
		logout();
		navigate('/');
	};
	return <LogoutRender logout={logoutAction} />;
};