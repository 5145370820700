import React from 'react';
import styled from 'styled-components';
import heroImage from 'styles/images/discover.png';
import {OpusLogo} from 'icons';

const StyledHero:any=styled.div`
  width: 100%;
  max-height: 136px;
  aspect-ratio: 65/12;	  
  background-image: url(${heroImage});
  background-size: cover;	
  border-radius: 10px;
  font-weight: 700;
  >div{
    height: 100%;
    background-color: rgba(0,0,0,0.8);
  	padding: 12px 20px 16px 18px ;
  }
`;

const Top:any=styled.p`
	font-size: 26px;
`;

const Women:any=styled.div`
  display: flex;
  align-items: center;
	>p:first-child{
	  font-size: 44px;
	  border-bottom: solid 1px ;
	}
  >p{
	font-size: 36px;
  }
`;

const Logo:any=styled.div`
  width: fit-content;
	border-bottom: 1px solid;
`;

export const DiscoverHero = ():JSX.Element => {
	return <StyledHero>
		<div>
			<Logo>
				<OpusLogo/>
			</Logo>

			<Top>Top</Top>
			<Women>
				<p>50</p>
				<p>Women</p>
			</Women>
		</div>
	</StyledHero>;
};