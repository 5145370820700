import React from 'react';
import {Content, ContentColumn, ContentHeader, ContentMain} from './styles';
import {LayoutProps} from './styles';

type ContentLayoutProps ={
    header?:React.ReactNode
    column?:React.ReactNode
    children:React.ReactNode

}& LayoutProps

export const ContentLayout = ({header,column,children,...props}:ContentLayoutProps):JSX.Element => {

	return (
		<Content {...props}>
			{header &&<ContentHeader>
				{header}
			</ContentHeader>}

			{ column && <ContentColumn>
				{column}
			</ContentColumn>}
            
			<ContentMain>
				{children}
			</ContentMain>
		</Content>
	);
};