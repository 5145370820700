import React, {useEffect, useState} from 'react';
import {CulturalAlert} from 'interfaces';
import {Carousel} from 'components/complexComponents';
import {ArticlesGrid, TacticsGrid} from 'components';
import {UserContext, userContextInterface} from '../../context/userContext';
import ArticleRow from '../HotList/components/ArticleRow';
import SubtacticAlertRowLoad from 'components/SubtacticAlertRow/SubtacticAlertRow.load';
import {IInterest, IMatch, IMatchAudience, IOption} from '../Discover/DiscoverInterfaces';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {TStrapiItem} from '../../interfaces/strapi';
import {useRecoilState} from 'recoil';
import {interestFormAtom, matchQueryAtom, searchingAtom} from '../../context/recoil/store';
import {TArticle} from '../../components/ArticleCard/ArticleCard';
import {opusContent} from '../../actions/strapiActions';

interface TagI {
	id: number | string
	title: string
}
interface TagRowI {
	title?: string
	cover?: string | any
	coverId?: string | number
	tags?: TagI[] | any
	id?: string | number
	titleColor?:string
}


type HomeCompanyRenderProps = {
    opportunities: CulturalAlert[];
	tags?:  {
		tagRow? : TagRowI[]
	}
	interests?: IInterest[]
	hotListArtists?: string[]
	articlesHero?: TArticle[]
};

const InterestBox:any = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: center;
	
	color: #FFF;
	border-radius: 12px;
	background: #24262F;
	height: 108px;
	font-size: 14px;
	font-style: normal;
	font-weight: 800;
	line-height: normal;
	letter-spacing: 1.4px;
`;

export const HomeCompanyRender = ({opportunities,tags,interests,hotListArtists, articlesHero}:HomeCompanyRenderProps): JSX.Element => {
	const createHoListDetalLink = (id:string):string => `/hotlist/${id}/detail`;
	const [interestform, setInterestForm] = useRecoilState<string[]>(interestFormAtom);
	const [searching, setSearching] = useRecoilState<boolean>(searchingAtom);
	const [matchQuery, setMatchQuery] = useRecoilState<IMatch>(matchQueryAtom);

	const {user} : userContextInterface = React.useContext(UserContext);

	useEffect(():void=>{
		console.log('HLA USER', user);
	},[user]);

	return (
		<div >
			<Carousel
				images={articlesHero?.map((t:TArticle):string => t.picture || '') || []}
				titles={articlesHero?.map((t:TArticle):string => t.title || '') || []}
				bodies={articlesHero?.map((t:TArticle):string => t.subtitle ? t.subtitle : '') || []}
				// colors={tags?.tagRow.map((t:TagRowI):string => t.tags?.data[0]?.attributes.titleColor || '') || []}
				paths={articlesHero?.map((t:TArticle):string => t.link) || []}
				aspectRatio={'728/275'}
			/>
			<div className={'w-100 flex direction-column row-gap mt-2'}>
				{/*<ArticleRow*/}
				{/*	title="Stories we love"*/}
				{/*	subtitle="Lorem ipsum dolor sit amet consectetur. Tristique eget vestibulum volutpat diam ac sagittis integer nibh lacus."*/}
				{/*	params={{*/}
				{/*		'filters[tags]' : 7,*/}
				{/*		'sort':'publishDate:desc'*/}
				{/*	}}*/}
				{/*	viewAll="/hotlist/spotlight"*/}
				{/*/>*/}

				<SubtacticAlertRowLoad
					title={'Opportunities: Expiring Soon'}
					viewAll='/opportunities/featured/expiring'
					sort='ENDDATE_ASC'
					filter={{
						active:true,
						ENDDATE_GREATERTHAN: new Date().toISOString(),
						_operators:{tenant:{in:user.tenantList}}
					}}
					limit={4}
					description={'Act now before these opportunities close'}
					withRemainTime
				/>


				<SubtacticAlertRowLoad
					title={'Opportunities: Just In'}
					viewAll='/opportunities/featured/new'
					limit={4}
					filter={{
						active:true,
						ENDDATE_GREATERTHAN: new Date().toISOString(),
						_operators:{tenant:{in:user.tenantList}}
					}}
					description={'Discover the latest opportunities from our members'}
				/>
				<ArticlesGrid
					title={'Discover more opportunities'}
					subtitle={'Have a campaign in mind? Explore opportunities that bring these tactics to life'}
					columns={1}
				>
					<TacticsGrid />
				</ArticlesGrid>

				{/*<ArticlesGrid*/}
				{/*	title={'Interesting audiences'}*/}
				{/*	subtitle={'Lorem ipsum dolor sit amet consectetur. Tristique eget vestibulum volutpat diam ac sagittis integer nibh lacus.'}*/}
				{/*	columns={5}*/}
				{/*>*/}
				{/*	{hotListArtists && hotListArtists?.length > 0 && hotListArtists?.slice(0.5).map((talent: string): ReactNode =>*/}
				{/*		<WithTalentDetailData talentId={talent} key={talent}>*/}
				{/*			{({discoverData, instagramAudience}: TalentDetailData): ReactNode =>*/}
				{/*				!discoverData.loading && !instagramAudience.loading ?*/}
				{/*					<CardItem*/}
				{/*						picture={discoverData?.data?.userObj?.profilePicture?.url}*/}
				{/*						title={`${discoverData?.data?.userObj?.firstName} ${discoverData.data?.userObj?.lastName}`}*/}
				{/*						subtitle={`${discoverData?.data?.industryObj[0]?.label} ${ discoverData?.data?.industryObj[0] && discoverData?.data?.industrySegmentObj? '|':''} ${discoverData?.data?.industrySegmentObj[0]?.label}`}*/}
				{/*						link={`/talent/${talent}/opus`}*/}
				{/*					/>*/}
				{/*					:*/}
				{/*					<Spinner/>*/}
				{/*			}*/}
				{/*		</WithTalentDetailData>*/}
				{/*	)}*/}
				{/*</ArticlesGrid>*/}
				
				<div className={'pt'}>
					<div className="flex">
						<h3 className="f-1rem f-urbanist  f-900 ls-1-6 mb-half grows">
							Tap into audience interests
						</h3>
					</div>

					<div className="f-12px t-neutral-300 ls-1-2 mb">
						Discover talent that can help you reach your target audience
					</div>


					<div className="grid4 col-gap-1half row-gap-1half">
						{ interests.length ? interests.map((interest:IInterest, index:number):JSX.Element =>
							<InterestBox
								key={interest._id}
								to ='/discover'
								onClick={():void=>{

									let m: IMatch = {...matchQuery};
									let a: IMatchAudience = {...matchQuery.audience};

									m.audience = a;

									m.audience.topic = [interest.label];
									setInterestForm([interest.title]);
									setMatchQuery(m);
									setSearching(true);
								}}
							>
								{interest.title}
							</InterestBox>)
							:
							null
						}
					</div>
				</div>

			</div>

		</div>
	);
};