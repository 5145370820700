import React,{ForwardedRef,forwardRef} from 'react';
import {InputProps} from 'interfaces';
import {StyledDigit} from './styles';

const VerifyInputRender:any=forwardRef(({
	name,
	value,
	onChange
}:InputProps,ref:ForwardedRef<HTMLInputElement>):JSX.Element=>
	(<StyledDigit
		ref={ref}
		name={name}
		value={value}
		maxLength={1}
		type={'number'}
		onChange={onChange}
	/>)
);

VerifyInputRender.displayName='VerifyInputRender';
export default VerifyInputRender;
