import React from 'react';
import DiscoverForm from './DiscoverForm';
import {ContentLayout} from '../../containers';
import {DiscoveryHeader} from './components/DiscoveryHeader';
import {ITalent} from './DiscoverInterfaces';
import {CardItem, ItemsCollection} from '../../components';
import {TalentsContainer} from './components/styledComponents';
import {useRecoilValue} from 'recoil';
import {talentsWithScoresSelector} from 'context/recoil/store';

interface IDiscoverRender {
	loading: boolean
	loadingScores: boolean
}

export default function DiscoverRender({loading,loadingScores}:IDiscoverRender):JSX.Element {

	const scoredTalentsSelector:ITalent[] = useRecoilValue<ITalent[]>(talentsWithScoresSelector);
	return (
		<ContentLayout
			header={<DiscoveryHeader matchCount={scoredTalentsSelector?.length}/>}
			column={<DiscoverForm />}
		>
			<div className="grows w-100 relative">
				<TalentsContainer id="talents-container">
					{loading ?
						<div>Loading talents...</div>
						:
						loadingScores ?
							<div>Loading scores...</div>
							:
							null
					}
					{!loadingScores &&
						<ItemsCollection>
							{scoredTalentsSelector && scoredTalentsSelector?.length > 0 && scoredTalentsSelector?.map((e: ITalent,index:number): JSX.Element =>{
								return (
									<CardItem
										key={e?._id}
										link={`/talent/${e?._id}/opus`}
										picture={e?.profilePicture?.url}
										title={`${e?.firstName} ${e?.lastName}`}
										subtitle={`${e?.profile?.industry[0] || ''} ${ e?.profile?.industry[0] && e?.profile?.industrySegment? '|':''} ${e?.profile?.industrySegment[0] || ''}`}
										score={e?.score}
									/>
								);
							})}
						</ItemsCollection>
					}
				</TalentsContainer>
			</div>

		</ContentLayout>
	);
}