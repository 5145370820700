import React from 'react';

export const AirplaneIcon=():JSX.Element=>
	<svg
		width={55}
		height={55}
		viewBox="0 0 55 55"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M38.7469 15.1063C38.5593 14.9752 38.314 14.9649 38.1161 15.078L13.2978 29.2599C13.0953 29.3753 12.9798 29.5992 13.0029 29.8313C13.026 30.0633 13.183 30.26 13.404 30.3333L20.8154 32.8036L23.6723 40.6126C23.7582 40.8475 23.9815 41.0004 24.2268 41.0004C24.2493 41.0004 24.2724 40.9992 24.2949 40.9963C24.4545 40.978 24.5889 40.8921 24.6838 40.773L24.6896 40.7776L28.8938 35.4966L34.6769 37.4243C34.738 37.4451 34.801 37.4549 34.8638 37.4549C34.97 37.4549 35.0756 37.426 35.1685 37.3701C35.3168 37.2812 35.4184 37.1317 35.4467 36.9609L38.9922 15.688C39.0297 15.4628 38.9344 15.2367 38.7469 15.1063ZM15.0319 29.6304L34.6948 18.394L21.1176 31.659L15.0319 29.6304ZM24.5834 33.2434C24.5228 33.3259 24.4842 33.4223 24.4721 33.5239L23.9383 37.8992L21.959 32.4892L33.5159 21.1983L24.5834 33.2434ZM25.0669 38.4061L25.5576 34.3844L27.7 35.0986L25.0669 38.4061ZM34.3947 36.0848L26.0188 33.2929L37.423 17.9136L34.3947 36.0848Z"
			fill="white"
		/>
		<rect x="0.5" y="0.5" width={54} height={54} rx="19.5" stroke="white"/>
	</svg>
;
