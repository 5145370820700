import {Inode, Irelation, Idata, Iaudience, Igraph, Iuser} from './types';

const graph:Igraph = {
	nodes : [
		{
			id : 'user',
			title: 'User',
			group: 'user'
		}
	],
	relations : []
};

/**
 * Map Island
 * @param island
 * @param source
 * @param title
 */
function mapper(island:Iaudience[], source:string, title:string):void {
	island.map(({weight, name}:Iaudience, i:number):void => {
		if(i<30){
			let node:Inode = {
				id : name,
				title : name,
				group: source,
				weight
			};

			/**
			 * Create parent node
			 */
			if(i === 0){
				graph.nodes?.push({
					id: source,
					title,
					group: source
				});
			}

			graph.nodes?.push(node);

			let relation:Irelation = {
				source,
				target : name
			};

			/**
			 * Create parent relation
			 */
			if(i === 0) {
				graph.relations?.push({
					source: 'user',
					target: source
				});
			}
			graph.relations?.push(relation);
		}
	});
}

/**
 * Create all islands
 * @param followersCount
 * @param followsCount
 * @param audienceCity
 * @param audienceCountry
 * @param audienceInterests
 * @param audienceBrand
 * @param audienceLocale
 * @param audienceEthnicities
 * @param audienceGenderAge
 */
export function kgFormat({followersCount, followsCount, audienceCity, audienceCountry, audienceInterests, audienceBrand, audienceLocale, audienceEthnicities, audienceGenderAge}:Idata, {userObj : {firstName, lastName}}:Iuser):Igraph {

	graph.nodes = [
		{
			id : 'user',
			title: `${firstName} ${lastName}`,
			weight: 0.5,
			group: 'user'
		}
	];
	graph.relations = [];
	mapper(audienceCity, 'citiesIsland', 'Cities');
	mapper(audienceCountry, 'countriesIsland', 'Countries');
	mapper(audienceEthnicities, 'ethnicitiesIsland', 'Ethnicities');
	mapper(audienceBrand, 'brandIsland', 'Brands');
	mapper(audienceLocale, 'localesIsland', 'Locales');
	mapper(audienceGenderAge, 'genderAgeIsland', 'Gender/Age');
	mapper(audienceInterests, 'interestsIsland', 'Interests');
	return(graph);
}
