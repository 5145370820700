import React, {useContext, useState} from 'react';
import OpportunityOldDetailOldRender from './OpportunityOldDetailOldRender';
import {MutationTuple, QueryResult, QueryTuple, useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {query} from 'actions/queries';
import {NavigateFunction, useNavigate, useParams} from 'react-router-dom';
import {mutation} from 'actions/mutations';
import {OppEmail} from 'interfaces';
import {sendEmail} from 'actions/restActions';
import {Spinner} from 'components/ui';
import {UserContext, userContextInterface} from 'context/userContext';

const queryByUSerType:Record<string, string>  = {
	'regular': 'opportunityFindOneByUser',
	'company': 'opportunityFindOne'
};

const getQueryByUser = (userType?:string|undefined):string=>{

	return queryByUSerType[userType.toLowerCase()]||'opportunityFindOne';
};

export const OpportunityDetailOld =():JSX.Element=>{
	const {user}:userContextInterface = useContext(UserContext);


	let {id}:any = useParams();
	const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
	const [confirmPop, setConfirmPop] = useState<boolean>(false);
	const navigate : NavigateFunction = useNavigate() ;

	const opportunitiesQuery:string =getQueryByUser(user.type.label);

	// eslint-disable-next-line @typescript-eslint/typedef
	const [getAudience,{data:oppAudienceData,loading:oppAudienceLoading,error:oppAudienceError}]:QueryTuple<any, any> =
        useLazyQuery(query('opportunityAudienceFindOne'));
    
	const {data:oppData,loading:oppLoading,error:oppError}:QueryResult=useQuery(query(opportunitiesQuery),{
		variables:{
			filter: {_id : id}
		},
		fetchPolicy:'network-only',
		onCompleted:(data:any):Promise<QueryResult<any, any>> =>getAudience({variables:{
			filter: {opportunity: data[opportunitiesQuery]._id}
		}})
		// onCompleted:({opportunityFindOne}:any):void=> console.log({opportunityFindOne})
	});

	const [ deleteOpportunity ]:MutationTuple<any, any>=useMutation(mutation('opportunityDeleteOne'), {
		variables:{ _id : id }
	});
    
	const deleteAction = async():Promise<void>=>{
		await deleteOpportunity();
		setModalIsOpen(false);
		navigate('/campaigns/active');
	};

	const emailAlert = async():Promise<any> => {
		const d:OppEmail = {
			emails: ['sergio@moadw.com, david@moadw.com', 'davecotter@opusintel.co'],
			subject: 'I’m Interested in This Opportunity',
			body: ``,
			html: `<p>
				I’m interested in learning more about this Opportunity! Please have someone on your team reach out with more details.
				</p>
				<p>
				Looking forward to hearing from you soon!
				</p>
			`
		};

		const r:any = await sendEmail(d);
		console.log('Email Sent', r);
		setConfirmPop(true);

	};
	
	return (
		<>
			{oppLoading ?
				<div className={'w-100'}>
					<Spinner/>
				</div>
				:
				<OpportunityOldDetailOldRender
					opportunityQuery={{
						data: oppData && oppData[opportunitiesQuery],
						loading: oppLoading,
						error: oppError
					}}
					opportunityAudienceQuery={{
						data: oppAudienceData?.opportunityAudienceFindOne,
						loading: oppAudienceLoading,
						error: oppAudienceError
					}}
					deleteModal ={{
						isOpen: modalIsOpen,
						open: ():void=>setModalIsOpen(true),
						close: ():void=>setModalIsOpen( false )
					}}
					emailAlert={emailAlert}
					deleteAction={deleteAction}
					confirmPop={confirmPop}
					setConfirmPop={setConfirmPop}></OpportunityOldDetailOldRender>
			}
		</>
	);
};