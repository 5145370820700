import React from 'react';

interface IconProps {
	size?: number
	color?: string
}

export const BookMarkIcon=({size=24, color='white'}:IconProps):JSX.Element=>
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M2.78162 1.72106C2.96958 1.45176 3.22269 1.25481 3.5404 1.12988C3.74274 1.04348 3.9548 1.0002 4.17648 1.0002V1H19.3235C19.5451 1 19.757 1.04328 19.9593 1.12968C20.2773 1.25451 20.5303 1.45171 20.7181 1.72086C20.9059 1.99002 21 2.28785 21 2.61473V21.199C21 21.526 20.9059 21.8237 20.7181 22.0931C20.5303 22.3622 20.2773 22.5594 19.9593 22.6841C19.7572 22.7705 19.5451 22.8137 19.3235 22.8137C18.8705 22.8137 18.4708 22.6551 18.1239 22.3381L11.75 16.2247L5.37603 22.3383C5.03873 22.6454 4.63886 22.7992 4.17653 22.7992C3.93556 22.7992 3.72355 22.761 3.54045 22.6841C3.22274 22.5593 2.96963 22.3622 2.78167 22.0927C2.59386 21.8237 2.5 21.5259 2.5 21.1989V2.61493C2.49995 2.28806 2.59401 1.99022 2.78162 1.72106ZM4.34991 20.7518L10.4636 14.8983L11.75 13.673L13.0362 14.8983L19.1498 20.7518V2.84566H4.34991V20.7518V20.7518Z"
			fill={color}
		/>
	</svg>
;
