import React from 'react';

export const PeopleIcon=():JSX.Element=>
	<svg
		width={55}
		height={55}
		viewBox="0 0 55 55"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_1_970)">
			<path
				d="M27.4999 15.0869C24.563 15.0869 22.1738 17.476 22.1738 20.4131C22.1738 23.3501 24.5629 25.7392 27.4999 25.7392C30.4369 25.7392 32.826 23.35 32.826 20.4131C32.826 17.4761 30.4369 15.0869 27.4999 15.0869ZM27.4999 24.2174C25.403 24.2174 23.6956 22.51 23.6956 20.4131C23.6956 18.3161 25.403 16.6087 27.4999 16.6087C29.5969 16.6087 31.3042 18.3161 31.3042 20.4131C31.3042 22.51 29.5969 24.2174 27.4999 24.2174Z"
				fill="white"
			/>
			<path
				d="M38.1522 21.174C36.0553 21.174 34.3479 22.8814 34.3479 24.9783C34.3479 27.0752 36.0553 28.7826 38.1522 28.7826C40.2492 28.7826 41.9565 27.0752 41.9565 24.9783C41.9565 22.8814 40.2492 21.174 38.1522 21.174ZM38.1522 27.2609C36.8937 27.2609 35.8696 26.2368 35.8696 24.9783C35.8696 23.7198 36.8937 22.6957 38.1522 22.6957C39.4107 22.6957 40.4348 23.7198 40.4348 24.9783C40.4348 26.2368 39.4107 27.2609 38.1522 27.2609Z"
				fill="white"
			/>
			<path
				d="M16.9558 21.174C14.8573 21.174 13.1515 22.8814 13.1515 24.9783C13.1515 27.0752 14.8589 28.7826 16.9558 28.7826C19.0527 28.7826 20.7601 27.0752 20.7601 24.9783C20.7601 22.8814 19.0542 21.174 16.9558 21.174ZM16.9558 27.2609C15.6973 27.2609 14.6732 26.2368 14.6732 24.9783C14.6732 23.7198 15.6973 22.6957 16.9558 22.6957C18.2143 22.6957 19.2384 23.7198 19.2384 24.9783C19.2384 26.2368 18.2142 27.2609 16.9558 27.2609Z"
				fill="white"
			/>
			<path
				d="M27.5 27.2609C22.046 27.2609 17.6086 31.6982 17.6086 37.1522C17.6086 37.5722 17.9495 37.9131 18.3695 37.9131C18.7895 37.9131 19.1304 37.5722 19.1304 37.1522C19.1304 32.5367 22.8845 28.7826 27.5 28.7826C32.1154 28.7826 35.8695 32.5367 35.8695 37.1522C35.8695 37.5722 36.2104 37.9131 36.6304 37.9131C37.0504 37.9131 37.3913 37.5722 37.3913 37.1522C37.3913 31.6982 32.9539 27.2609 27.5 27.2609Z"
				fill="white"
			/>
			<path
				d="M38.1524 30.3044C36.9001 30.3044 35.6735 30.6468 34.6053 31.2935C34.2477 31.5126 34.132 31.9798 34.3496 32.3389C34.5702 32.6981 35.0359 32.8122 35.395 32.5946C36.2243 32.0909 37.177 31.8261 38.1524 31.8261C41.0894 31.8261 43.4785 34.2152 43.4785 37.1522C43.4785 37.5722 43.8194 37.9131 44.2394 37.9131C44.6594 37.9131 45.0003 37.5722 45.0003 37.1522C45.0003 33.3767 41.9279 30.3044 38.1524 30.3044Z"
				fill="white"
			/>
			<path
				d="M20.3935 31.2919C19.3267 30.6468 18.1003 30.3044 16.8478 30.3044C13.0724 30.3044 10 33.3767 10 37.1522C10 37.5722 10.3409 37.9131 10.7609 37.9131C11.1809 37.9131 11.5218 37.5722 11.5218 37.1522C11.5217 34.2152 13.9109 31.8261 16.8478 31.8261C17.8233 31.8261 18.7758 32.0909 19.6037 32.593C19.9613 32.8107 20.43 32.6965 20.6491 32.3374C20.8683 31.9782 20.7526 31.5111 20.3935 31.2919Z"
				fill="white"
			/>
		</g>
		<rect x="0.5" y="0.5" width={54} height={54} rx="19.5" stroke="white"/>
		<defs>
			<clipPath id="clip0_1_970">
				<rect width={35} height={35} fill="white" transform="translate(10 9)"/>
			</clipPath>
		</defs>
	</svg>
;
