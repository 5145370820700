import styled from 'styled-components';


export const StyledScore:any=styled.p`
  position: absolute;
  top: 0;
  right: 1rem;
  
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${({theme: {colors}}:any):string => colors.red};
  width: 36px;
  height: 36px;
  border-radius: 50%;
  padding: 0.4rem 0.4rem;
  margin-top: 1rem;


  font-family: sans-serif, Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const ItemContainer:any = styled.div`
  position: relative;
  font-family: 'Urbanist', sans-serif;
  font-size: 16px;
  font-weight: 900;
  
`;