import styled, {keyframes} from 'styled-components';
import {themeOpus} from 'stylesOpus/theme';

type ImgeContainerProps = {
    $aspectRatio?: string;

    $startPosition?: number;
    $endPosition?: number;
}

export const CarouselWrapper: any = styled.div<{$width:string, $aspectRatio:string}>`
  width: ${({$width}:any):string => $width || '100%'};
  min-width: ${({$width}:any):string => $width || '100%'};
  
  display: flex; 
  flex-direction: column;
  
  gap: 1rem;
  align-items: center;
  position: relative;
  .carousel-dimension{
    border-radius: 1rem;
    overflow: hidden;
    width: inherit;
    position: relative;
    aspect-ratio: ${({$aspectRatio}:any):string => $aspectRatio || '16/9'};
  }
`;
export const SliderImageContainer: any = styled.div<ImgeContainerProps>`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 1rem;
  
  position: absolute;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  flex-grow: 1;
  //overflow: hidden;
   Transform : ${(props:ImgeContainerProps):string=>`translateX(${props.$endPosition}px)`};
  animation: ${(props:ImgeContainerProps):string=>TranslateImages(props)} 0.4s 1 ease-in-out;
  
  border-radius: inherit;
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-right: 0;
    box-sizing: border-box;
    transform-origin: center center;

    overflow-x: hidden; /* Habilita el desplazamiento horizontal */
  >div{
    width: 100%;
    height: 100%;
  }

  }
`;

export const CarouselImageContainer: any = styled.div<{ $width:number }>`
  //position: absolute;
  width: ${({$width}:any):string => `${$width}%`};
  border-radius:  inherit;
`;

export const CarouselButton: any = styled.button<{active:boolean}>`
  height: 0.5rem;
  width: ${({active}:any):string=> active? '4rem' : '0.5rem'};
  border-radius: 0.5rem;
  border: none;
  background-color: ${themeOpus.colors.grey_300};
  cursor: ${({active}:any):string=> !active && 'pointer'};
`;

export const SliderArrow: any = styled.a<{active:boolean}>`
  position: absolute;
  top: calc(50% - 2.2rem);
  height: 2.2rem;
  width: 2.2rem;
  border-radius: 1rem;
  display: flex;
  place-items: center;
  justify-content: center;
  background-color: ${ themeOpus.colors.grey_800 };
  opacity: ${({active}:any):string=> active ? '1' : '0.4' };
  cursor: ${({active}:any):string=> !active && 'pointer'};
`;

// animation


const TranslateImages = ({ $startPosition=0, $endPosition=0}:ImgeContainerProps):any => keyframes`
  
	0% {
		transform: translateX( ${$startPosition}px );
	} 


	100% {
		transform: translateX(${$endPosition}px );
	}
`;
