import React, {ChangeEvent, useEffect} from 'react';
import {ListInput, Item, SelectProps} from 'interfaces';
import DropDownRender from './DropDownRender';
import {listHandler, useListHandler} from 'hooks/useListHandler';
import {SelectStylesProps} from '../Select/styles';

interface DropDownProps extends ListInput {
	withAllOption?:boolean
	styleProps?: SelectStylesProps
}
export const DropDown=({options,onChange,name,value,withAllOption=false,...props}:DropDownProps):JSX.Element=>{
	
	const optionsWithAll:Item[]=[...options ];
	const {handleUnselected,handleSelected,setSelectedItems,getSelectedItems, resetSelected}:listHandler=useListHandler({
		name, value,
		options: withAllOption? optionsWithAll : options,
		onChange
	});
	const handleSelect=(e:ChangeEvent<HTMLSelectElement>):void=>{
		console.log({target:e.target.name, value:e.target.value});
		if(withAllOption && e.target.value===''){
			setSelectedItems([]);
			return;
		}
		let item:Item=options.find((i:Item):boolean=>i.id===e.target.value);
		handleSelected(item);
	};
	useEffect(():void=>{
		if( !value || !value.length){
			resetSelected();
		}
	},[value]);
	const selectProps:SelectProps={
		...props,
		value:getSelectedItems()[0]?.id || '',
		placeholder:withAllOption? 'All' : 'Select',
		options: withAllOption? optionsWithAll : options,
		onChange:handleSelect
	};
	const selectedITems: Item[] = (withAllOption && !getSelectedItems().length) ?
		[{id:'',label:'All'}] : getSelectedItems();

	return <DropDownRender
		selectProps={selectProps}
		selectedItems={selectedITems}
		handleClickTag={handleUnselected}
	/>;
};
