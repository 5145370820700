import React, {useEffect, useState} from 'react';
import {SetterOrUpdater, useSetRecoilState} from 'recoil';
import {BreadCrumbAtom, TCrumb} from '../../context/recoil/store';
import {Params, useParams} from 'react-router-dom';
import {CategoryDetailRender} from './CategoryDetailRender';
import {opusContent} from '../../actions/strapiActions';
import {TStrapiCover, TStrapiItem} from '../../interfaces/strapi';
import {AxiosResponse} from 'axios';
import {CategoryBoxProps} from '../../components/ui/CategoryBox/CategoryBox';
import {TArticle} from '../../components/ArticleCard/ArticleCard';

type TStrapiHotListItem = {
	id: string
	attributes:{
		title:string
		subtitle:string
		description:string
		body:string
		embed:string
		archive:string
		author:string
		publishDate:string
		featuredTalent?:any[]
		relatedTalent?:any[]
		titleColor:string
		visible:boolean
		cover:TStrapiCover
	}
}

export const CategoryDetail = ():JSX.Element => {
	//Breadcrumb
	const {category}:Params = useParams();
	const setBreadCrumbs: SetterOrUpdater<TCrumb[]>=useSetRecoilState(BreadCrumbAtom);


	// get data
	const [tag, setTag] = useState<CategoryBoxProps['category']|null>(null);
	const [articles, setArticles] = useState<TArticle[]>(null);


	const getCategoriesData = async():Promise<any> => opusContent('tags', {
		'filters[slug][$eqi]': category,
		'populate[0]': 'cover'
	});

	const getArticles= async(category:{name:string, id:string}):Promise<any> =>
		opusContent('hotlists', {
			'filters[tags]': category.id,
			'populate[0]':'tags',
			'populate[1]': 'cover'
		});

	const getData = async():Promise<void> => {
		try {
			const tagResponse:AxiosResponse = await getCategoriesData();
			const tag :TStrapiItem = tagResponse?.data?.data[0];
			setTag({
				text: tag?.attributes?.title,
				color:tag?.attributes?.titleColor,
				path: tag?.attributes?.slug,
				picture: `${tag?.attributes?.cover?.data?.attributes?.url}`
			});

			const articlesResponse:AxiosResponse = await getArticles({name: tag?.attributes?.title, id: tag?.id});
			const items :TStrapiItem[] = articlesResponse.data.data;
			setArticles(items.map((item:TStrapiItem):TArticle => ({
				title: item?.attributes?.title,
				subtitle: item?.attributes?.subtitle,
				date: item?.attributes?.publishDate,
				picture: `${item?.attributes?.cover?.data?.attributes?.url}`,
				link: `/hotlist/${category}/${item?.id}/detail`
			})));
		}
		catch (error) {
			console.error(error);
		}
	};

	useEffect(():void => {
		setBreadCrumbs([
			{id: 'hotlist', label: 'hotlist', path:'/hotlist'},
			{id: category, label: category, path:`/hotlist/${tag}`}
		]);
		getData();
	}, []);

	return (tag &&
		<CategoryDetailRender
			category={tag}
			articles={articles}
		/>
	);
};
