import React, {useEffect, useState} from 'react';
import {HomeCompanyRender} from './HomeCompanyRender';
import { TStrapiItem} from '../../interfaces/strapi';
import {opusContent} from '../../actions/strapiActions';
import {QueryResult, useQuery} from '@apollo/client';
import {query} from '../../actions/queries';
import {IInterest} from '../Discover/DiscoverInterfaces';
import {TArticle} from '../../components/ArticleCard/ArticleCard';

export const HomeCompany = ():JSX.Element => {

	const [tags, setTags] = useState();
	const {data:interestData, loading:interestLoading, error:interestError}:QueryResult = useQuery(query('interestFindMany'));

	const [articles, setArticles] = useState<TArticle[]>([]);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(():void => {
		if(loading){
			getEntries();
		}
	}, [loading]);

	const getEntries = async():Promise<any> => {

		const h:any = await opusContent('hotlists', {
			'filters[tags]' : 7,
			'sort':'publishDate:desc',
			'pagination[pageSize]': 4,
			'populate[0]': 'cover'
		});
		setLoading(false);
		const a:TArticle[] = h?.data?.data?.map(({id, attributes}:any):TArticle => {
			return {
				title: attributes?.title || '',
				subtitle: attributes?.subtitle || '',
				body: attributes?.body || '',
				picture: attributes?.cover?.data?.attributes?.url,
				date: attributes?.publishDate,
				link: `/hotlist/${id}/detail`
			};
		});
		if(a.length > 0){
			setArticles(a);
		}
	};

	const getHotListTags = async():Promise<void>=>{
		const h:any = await opusContent('hotlist-home', {
			'populate[0]' : 'tagRow',
			'populate[1]' : 'Feed',
			'populate[2]' : 'tagRow.cover',
			'populate[3]' : 'tagRow.tags',
			'populate[4]' : 'Feed.tags'
		});

		setTags(h?.data?.data?.attributes);
	};


	const getHotlists = async():Promise<void>=>{
		const res:any = await opusContent('hotlists', {
			'pagination[pageSize]': 20,
			'sort':'title'
		});
		const hotLists:TStrapiItem[] = res.data?.data || [];

		let relatedArtists:string[]=[];
		hotLists.forEach((h:TStrapiItem):void => {
			relatedArtists=h.attributes.relatedTalent?relatedArtists.concat([...h.attributes.relatedTalent] ): relatedArtists;
		} );
		const talentsSet:Set<string> = new Set(relatedArtists);
		// @ts-ignore
		//setHotListArtists([...talentsSet]);
	};


	useEffect(():void => {
		getHotListTags();
		getHotlists();
	}, []);

	const interests:IInterest[] = interestData?.interestFindMany || [];
	return (
		<HomeCompanyRender
			tags={tags||{ tagRow:[]}}
			articlesHero={articles}
			opportunities={[]}
			interests={interests.slice(0, 8)}
		/>
	);
};