import React from 'react';
import {Opportunity} from '../../../interfaces';
import CampaignList from '../../../containers/Campaigns/CampaignList';

interface CampapaingsRenderProps {
	filter: string
	data: Opportunity[]
}

export default function OpportunitiesRender({filter,data} : CampapaingsRenderProps):JSX.Element {

	return (
		<CampaignList opportunities={data} />
	);
}