import React, {ReactNode} from 'react';
import {FavoritesGroup} from './FavoritesGroup';
import {FavoriteCard} from './FavoriteCard';
import {WithCulturalAlert} from 'hocs';
import {CulturalAlertResponse} from 'hocs/WithCulturalAlert';
import {Spinner} from 'components/ui';
import {TStrapiItem} from 'interfaces/strapi';
import {CardItem} from 'components';

type AllFavoritesProps = {
    talentsSlice: string[];
    opportunitiesSlice: string[];
    hotlistArticlesSlice: TStrapiItem[];
};
export const AllFavorites = ({talentsSlice,opportunitiesSlice,hotlistArticlesSlice}:AllFavoritesProps):JSX.Element => {
    
	return (
		<div className={'w-100 h-100 flex direction-column row-gap-3'}>
			{opportunitiesSlice.length> 0 ?
				<FavoritesGroup
					title={'Saved Opportunities'}
					link={'/favorites/opportunities'}
				>
					{opportunitiesSlice.map((opportunity: string): ReactNode =>
						<WithCulturalAlert id={opportunity} key={opportunity}>
							{({data:culturalAlert, loading}:CulturalAlertResponse): JSX.Element =>
								loading ? <Spinner/> :
									<CardItem
										key={culturalAlert?._id}
										title={culturalAlert?.title}
										subtitle={''}
										picture={culturalAlert?.photos[0]?.url}
										link={`/opportunities/${culturalAlert?._id}`}
									/>
							}
						</WithCulturalAlert>

					)}
				</FavoritesGroup>
				:null
			}


			{talentsSlice.length> 0 ?
				<FavoritesGroup
					title={'Saved Talent'}
					link={'/favorites/talents'}
				>
					{talentsSlice.map((talent: string, index:number): ReactNode =>
						<FavoriteCard talentId={talent} key={`${talent}-${index}`}/>
					)}
				</FavoritesGroup>
				:null
			}

			{hotlistArticlesSlice.length> 0 ?
				<FavoritesGroup
					title={'Saved Hotlist Articles'}
					link={'/favorites/hotlist'}
				>
					{hotlistArticlesSlice.map((ha:TStrapiItem): ReactNode =>
						<CardItem
							link={`/hotlist/${ha.id}/detail`}
							key={ha.id}
							picture={ha?.attributes.cover.data.attributes.url}
							title={`${ha?.attributes.title}`}
							subtitle={''}
						/>
					)}
				</FavoritesGroup>
				:null
			}
		</div>
	);
};