import React from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ChartData,
	ChartOptions, ChartDataset
} from 'chart.js';
import {BarsRender} from './BarsRender';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

interface BarsProps  {
	backgroundColor?:string|number
	dataset:ChartDataset<'bar'>
	labels:string[]
}

export const Bars= ({dataset,labels}:BarsProps):JSX.Element=>{

	const data:ChartData<'bar'> = {
		labels,
		datasets: [dataset]
	};

	const options:ChartOptions<'bar'> = {
		responsive: true,
		maintainAspectRatio:false,
		scales:{
			y:{display:false},
			x:{grid:{display:false}}
		},
		plugins: {
			legend: {
				display:false,
				position: 'top' as const
			},
			title: {
				display: false,
				text: 'Chart.js Bar Chart'
			}
		}
	};


	return <BarsRender
		data={data}
		options={options}
	/>;
};