import styled from 'styled-components';

export const CountryAudience:any=styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.2rem 0 ;
  >div{
	display: flex;
	align-items: center;
	>p{
      font-size: 12px;
      line-height: 15px;
	  margin-left: 0.8rem;
      font-weight: 700;
	}
  }
  >p{
    font-weight: 800;
    font-size: 14px;
    line-height: 17px;
  }
`;

export const Flag:any=styled.div`
  width: 21px;
  height: 15px;
  border-radius: 3px;
  background-image:${({country}:any):string=>` url("https://flagcdn.com/w80/${country}.png")`} ;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;
