import {ChangeEvent, useEffect, useState} from 'react';

export interface FilterInputArgs {
	onChange?:(file:File)=>void
}

export interface FileInputHook {
	file:File
	handleChangeFile:(e:ChangeEvent<HTMLInputElement>)=>void
}

export const useFileInput=({onChange}:FilterInputArgs):FileInputHook=>{
	const [file,setFile]=useState<File|null>(null);

	const handleChangeFile=(e:ChangeEvent<HTMLInputElement>):void=>{
		setFile(e.target.files[0]);
	};

	useEffect(():void=> {
		onChange && onChange(file);
	},[file]);

	return {
		file,
		handleChangeFile
	};
};

