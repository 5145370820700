import React from 'react';
import {BrandSkusForm} from 'components';
import {Brand, CompanyProfile, Sku} from 'interfaces';
import {BrandNav} from 'components/BrandNav';

interface ProductDetailProps {
	productId:string
	product:Brand
	profile:CompanyProfile
	skus:Sku[]
}

export const ProductDetailRender= ({productId,product,profile,skus}:ProductDetailProps):JSX.Element=>{
	return<>
		<BrandNav companyName={profile.name} ProductName={product.name} productId={productId}/>
		<BrandSkusForm
			editLink={`/products/${productId}/sku`}
			addSkuLink={`/products/${productId}/create-sku`}
			skus={skus}
		/>
	</>;
};