import React, {useEffect, useState} from 'react';
import DoubleRangeSlider from './form/DoubleRangeSlider';
import {IMatch, IMatchAudience} from '../DiscoverInterfaces';
import {useRecoilState} from 'recoil';
import {matchQueryAtom} from '../../../context/recoil/store';

export default function AudienceAge():JSX.Element {

	const [matchQuery, setMatchQuery] = useRecoilState<IMatch>(matchQueryAtom);
	const [minVal, setMinVal] = useState<number>(18);
	const [maxVal, setMaxVal] = useState<number>(80);
	const [leftVal, setLeftVal] = useState<number>(18);
	const [rightVal, setRightVal] = useState<number>(80);

	useEffect(():void=>{

		let m: IMatch = {...matchQuery};
		let a: IMatchAudience = {...matchQuery.audience};
		m.audience = a;
		m.audience.ages= {
			min_age: Number(leftVal),
			max_age: Number(rightVal)
		};
		setMatchQuery(m);

	}, [leftVal, rightVal]);


	return (
		<div className="mhor-quarter mver">
			<DoubleRangeSlider min={minVal} max={maxVal} leftVal={leftVal} rightVal={rightVal} setLeftVal={setLeftVal} setRightVal={setRightVal} />
		</div>
	);
}