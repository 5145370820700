import React from 'react';
import {LikeCheck} from '../LikeCheck';
import {listHandler, useListHandler} from 'hooks/useListHandler';
import {Item, ListInput} from 'interfaces';
import {FieldSection} from '../FieldSection';

export  const LikeCheckList = ({value,label,helperText,name,options,onChange}:ListInput):JSX.Element=>{

	const {handleSelected, handleUnselected, getSelectedItems}:listHandler = useListHandler({name,options,value,onChange});

	const verifySelected =(id:string):boolean=>
		getSelectedItems().map((item:Item):string=>item.id).includes(id);

	return <FieldSection
		label={label}
		helperText={helperText}
	>
		{options.map((option:Item):JSX.Element=>
			<LikeCheck
				defaultChecked={verifySelected(option.id)}
				value={verifySelected(option.id)}
				theme={'opusV2'}
				label={option.label}
				key={option.id}
				onChange={(value:boolean):void=>!value?
					handleUnselected(option)
					: handleSelected(option)
				}
			/>
		)}

	</FieldSection>;
};