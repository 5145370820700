import React, {useContext} from 'react';
import styled from 'styled-components';
import {Opportunities, Opportunity} from 'interfaces';
import {ImageCard, Tag} from '../ui';
import {NextIcon} from 'icons';
import {Link} from 'react-router-dom';
import {BrandAvatar, StackContainer} from 'styles/ui/index';
import {formatDate} from 'tools/date';
import {UserContext} from 'context/userContext';
import {QueryResult, useQuery} from '@apollo/client';
import {query} from 'actions/queries';

interface OpportunityCardProps {
	opportunity:Opportunities
}

const StyledDate:any=styled(Tag)`
	background-color: white;
  color: black;
`;


export const OpportunityCard=({opportunity}:OpportunityCardProps):JSX.Element=>{

	return <Link to={`/opportunity/${opportunity._id}`}>
		<ImageCard img={opportunity.image?.url}>
			<div>
				<div className={'flex justify-between pt ml mr-half'}>
					<StackContainer className={'pt-half'}
						sizeValue ={60}
						sizeType={'px'}
						length={2}
						percentage={75}
					>
						{/*<BrandAvatar img={opportunity.company?.profilePicture?.url}/>*/}
					</StackContainer>
					{/*<StyledDate*/}
					{/*	selected={true}*/}
					{/*	filled*/}
					{/*	category={{id:opportunity._id,label:formatDate(opportunity?.startDate)}}*/}
					{/*/>*/}
				</div>
			</div>
		</ImageCard>
		<div className={'flex justify-between'}>
			<div>
				<h6 className="t-red f-bold f-uppercase mt-half">{opportunity.companyProfile?.name}</h6>
				<p className={'lh-150 f-regular t-white'}>{opportunity.name}</p>
			</div>
		</div>
	</Link>;
};