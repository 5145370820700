import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {themeOpus} from '../../stylesOpus/theme';

export const MenuItemContainer: any = styled.div<{$active:boolean}>`
  display:flex;
  justify-content: space-between;
  height: 2.38rem;
  align-items:center;
  padding: 8px 20px;
  border-radius: 30px;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  >div:nth-child(2){
    width: 36px;
    text-align: left;
  }
`;

export const NavLink: any = styled(Link)`
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
  margin-left: 0.5rem;
  letter-spacing: 1.2px;
  color: ${({$active}:any):string=> $active ? themeOpus.colors.white : '#6F7386'  };
`;

export const LogoContainer: any = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 20px;
  margin-bottom: 25px;
`;

export const TextLogo: any = styled.p`
  color: #FFF;
  font-family: 'Urbanist', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 10.8px;
  opacity: 0.8;
`;
