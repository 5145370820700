import React from 'react';
import {ThumbnailWrapper} from './styles';
import {PlayIcon} from 'icons';

export interface ThumbnailProps {
	src:string
	alt:string
	size?:string
	className?:string
}

export default function VideoThumbnailRender({src,alt}:ThumbnailProps):JSX.Element{

	return (
		<ThumbnailWrapper>
			<img src={src} alt={alt}/>
			<PlayIcon/>
		</ThumbnailWrapper>
	);
}
