import styled from 'styled-components';
import {BodyText} from '../../stylesOpus/typography';

export const GridWrapper:any =styled.section`
	width: 100%;
	height: fit-content;
	margin: 1.12rem 0;
	border-radius: 1rem;
	overflow: hidden;
	border : 1px solid #2A2A39;
	background-color: #171720;
`;

export const GridContentBox:any = styled.div<any>`
	padding: ${({$padding}:any):void=>$padding||'2.41rem 3.38rem'};
	border : 1px solid #2A2A39;	
`;

export const BodyOpportunityText:any = styled(BodyText)`
	font-family: 'Urbanist', sans-serif;
	font-size:  0.75rem;
	font-weight: 700;
	opacity: 0.6;
`;
