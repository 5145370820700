import React from 'react';
import styled from 'styled-components';


interface ImageCardProps {
	img:string
	width?:string
	aspectRatio?:string
	borderRadius?:string
	children?:React.ReactNode
	onClick?:()=>void
	backgroundPosition?:string
}

const Card:any=styled.div<ImageCardProps>`
  position: relative;
  width: 100%;
  aspect-ratio: ${({aspectRatio}:ImageCardProps):string=>aspectRatio};
  border-radius:  ${({borderRadius}:ImageCardProps):string=>borderRadius};
  background-color: #13141A;
  background-image: ${({img}:ImageCardProps):string=>`url(${img})`};
  background-size: cover;
  background-position: ${({backgroundPosition}:ImageCardProps):string=>backgroundPosition || 'center'};
  >div{
	width: 100%;
	height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  }
`;


export const ImageCard=({backgroundPosition, img,children,width='100%',borderRadius='16px', aspectRatio='1', onClick}:ImageCardProps):JSX.Element=>{

	return <Card
		img={img}
		borderRadius={borderRadius}
		width={width}
		aspectRatio={aspectRatio}
		onClick={onClick}
		backgroundPosition={backgroundPosition}
	>
		{children}
	</Card>;
};