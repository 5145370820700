import React from 'react';
import {TRemainTime, useRemainTime} from 'hooks/useRemainTime';
import styled from 'styled-components';

type CountDownProps = {
    date:string
    color?:string
    backgroundColor?:string
	className?:string
}

const TimeWrapper:any =styled.div<{$color:string,$backgroundColor:string}>`
  width: 100%;
  height: 46px;
  padding: 0.5rem 0;
  border-radius: 0.5rem;
  display: flex;
	justify-content: center;
	align-items: center;
	gap : 0.25rem;
	
  color: ${({$color}:any):string => $color};
  background-color: ${({$backgroundColor}:any):string => $backgroundColor};
  
`;

const RemainTimeText :any = styled.p`
	font-size: 0.5rem;
	font-weight: 400;
	line-height: 1.77331rem;
	opacity: 0.7;
`;

const RemainTimeValue :any = styled.p`
	color: #FFF;
	font-size: 1rem;
	font-weight: 700;
	line-height: 1.77331rem;
	display: flex;
	align-items: center;
	justify-content: center;
`;
export const CountDown = ({date, color='white',backgroundColor='#2D2F39',className}:CountDownProps): JSX.Element => {
	const { days, hours }:TRemainTime = useRemainTime(date);
	return (
		<TimeWrapper className={className} $color={color} $backgroundColor={backgroundColor} >
			<RemainTimeText>Days(s):</RemainTimeText>
			<RemainTimeValue>{days}</RemainTimeValue>
			<RemainTimeText>Hour(s):</RemainTimeText>
			<RemainTimeValue>{hours} </RemainTimeValue>
		</TimeWrapper>
	);
};