import React from 'react';
import {CloseIcon} from '../icons';
import {ModalHook} from '../hooks/useModal';

interface PopupI {
	children?: JSX.Element
	modal?:ModalHook
	title?: string
}

export default function Popup({children, modal, title}:PopupI):JSX.Element {
	const handleClose = ():void => {
		console.log('close', {modal});

		modal?.close();
	};
	return (
		<div className="fixed edges overflow-y-scroll flex align-center justify-center z-1000">
			<div className="fixed edges black80" />
			<div className="neutral-900 b-neutral-600 b br max-w-600px p-3 relative">

				<div className="flex mb-2 align-center">
					<h3 className="f-20px mr-2 f-16px grows left f-urbanist">
						{title}
					</h3>
					<p className="right">
						<a onClick={():void=> handleClose() }>
							<CloseIcon />
						</a>
					</p>
				</div>
				<div className="max-h-500px overflow-y-scroll">
					{children}
				</div>
			</div>
		</div>
	);
}