import React from 'react';
import {ButtonProps} from 'interfaces';
import {StyledLinkButton} from './styles';

export interface LinkButtonProps extends ButtonProps{
	to:string;
}

export default function LinkButtonRender({to,label,variant,size,children,...props}:LinkButtonProps):JSX.Element{

	return (
		<StyledLinkButton
			to={to}
			variant={variant}
			size={size}
			{...props}
		>
			{!children? label : children}
		</StyledLinkButton>
	);
}
