import React, {useEffect, useState} from 'react';
import OpportunityRender from './OpportunityRender';
import PasswordRender from '../Password/PasswordRender';
import {QueryTuple, useLazyQuery} from '@apollo/client';
import {Params, useParams} from 'react-router-dom';
import {query} from '../../../actions/queries';
import {BoxTitle} from '../../../stylesOpus/typography';

export const Opportunity = ():JSX.Element => {

	const {id}:Params = useParams();
	const [opportunityTab, setOpportunityTab] = useState<boolean>(true);
	const [password, setPassword] = useState<string>('');
	const [submit, setSubmit] = useState<boolean>(false);

	// eslint-disable-next-line @typescript-eslint/typedef
	const [getShortLink, {data, error, loading}]:QueryTuple<any, any> = useLazyQuery(query('shortLinkFindOne'),{
		variables:{
			id: id,
			password: password
		},
		errorPolicy: 'all'
		//fetchPolicy:'network-only'
	});

	useEffect(():void => {
		if(submit) {
			getShortLink();
		}
	}, [submit]);

	useEffect(():void => {

		if(error){
			console.log('Error', error?.message);
			setSubmit(false);
		}

	}, [error]);


	if(!password || !submit || error || loading) {
		return (
			<PasswordRender
				password={password}
				setPassword={setPassword}
				setSubmit={setSubmit}
				error={error?.message || ''}
				loading={loading}
			/>
		);
	}

	return (
		<div>
			{data &&
				<OpportunityRender
					opportunityTab={opportunityTab}
					setOpportunityTab={setOpportunityTab}
					share={data?.shortLinkFindOne}
				/>
			}
		</div>
	);
};