import React from 'react';

export const TwitterIcon=():JSX.Element=>{
	return <svg
		width={40}
		height={40}
		viewBox="0 0 40 40"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect width={40} height={40} rx={15} fill="#00ACED"/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M30.7 13.9C30.15 14.7 29.4625 15.4125 28.675 15.9875C28.6875 16.1625 28.6875 16.3375 28.6875 16.5125C28.6875 21.85 24.625 28 17.2 28C14.9125 28 12.7875 27.3375 11 26.1875C11.325 26.225 11.6375 26.2375 11.975 26.2375C13.8625 26.2375 15.6 25.6 16.9875 24.5125C15.2125 24.475 13.725 23.3125 13.2125 21.7125C13.4625 21.75 13.7125 21.775 13.975 21.775C14.3375 21.775 14.7 21.725 15.0375 21.6375C13.1875 21.2625 11.8 19.6375 11.8 17.675V17.625C12.3375 17.925 12.9625 18.1125 13.625 18.1375C12.5375 17.4125 11.825 16.175 11.825 14.775C11.825 14.025 12.025 13.3375 12.375 12.7375C14.3625 15.1875 17.35 16.7875 20.7 16.9625C20.6375 16.6625 20.6 16.35 20.6 16.0375C20.6 13.8125 22.4 12 24.6375 12C25.8 12 26.85 12.4875 27.5875 13.275C28.5 13.1 29.375 12.7625 30.15 12.3C29.85 13.2375 29.2125 14.025 28.375 14.525C29.1875 14.4375 29.975 14.2125 30.7 13.9Z"
			fill="white"
		/>
	</svg>;
};
