import React from 'react';
import {FormButton, Input} from 'components/ui';
import {Subtitle, SecurityForm} from '../../styles';
import {FormProps} from 'interfaces';
import {Message} from 'components';
import {color} from 'styles/colors';
import {BoxTitle} from 'stylesOpus/typography';

export const AccountRender = ({onSubmit,submitLoading,errors}:FormProps): JSX.Element => {
	return (
		<SecurityForm
			autoComplete={'nope'}
			onSubmit={onSubmit}
		>
			<Subtitle>Delete Account</Subtitle>
			
			<div>
				<BoxTitle className={'mb'}>Enter Email Address</BoxTitle>
				<Input
					placeholder={'Email'}
				/>
				<br/>
				<BoxTitle className={'mb'}>Enter Password</BoxTitle>
				<Input
					placeholder={'Password'}
					type={'password'}
				/>
			</div>

			<div>
				<FormButton loading={submitLoading} variant={'primary'} label={'Delete Account'} />
				{errors?.submit && <Message message={errors.submit} color={color.get('primary')}/>}
			</div>
			
		</SecurityForm>
	);
};
