import styled from 'styled-components';

export const Content:any=styled.main`
 
	>h1{
	  font-size: 2rem;
	  line-height: 2.72rem;
	  margin-bottom: 0.875rem;
      max-width: 350px;
	  text-align: center;
	  align-items: center;
	  justify-content: center;
	}
  >p{
	  line-height: 1.594rem;
  }

`;

export const OnboardingHeader:any=styled.header`
  	width: 100%;
  	max-width: 500px;
  	margin: 1rem 0;
  	padding: 4rem 2rem 0;
	display: flex;
  	justify-content: center;
  	align-items: center;
  >svg{
  }
  >a{
	position: absolute;
	right: 2rem;
	top:2rem;
  }
`;