import React from 'react';
import {TrackingGraphRender} from './TrackingGraphRender';
import {ChartItem} from 'interfaces';
import {weekLabels} from 'tools';

interface BarChartProps{
	distributionData:ChartItem[]
	title:string
	value:string
}

export const TrackingGraph= ({title,distributionData,...props}:BarChartProps):JSX.Element=>{

	const getPrevDayIndex= (day:number):number=>{
		let prevDay:number= day-1;
		return prevDay<0? 6 :prevDay;
	};

	const getData=():number[]=>{
		let data:number[]=Array(7-distributionData.length).fill(0);
		return [...data,...distributionData.map((d:ChartItem):number=>d.value)];
	};

	const getWeekLabels=():string[]=>{
		let days:number[]=Array(7-distributionData.length).fill(undefined);
		days=[...days,...distributionData.map((d:ChartItem):number=>new Date(parseInt(d.label)).getDay())];
		days.reverse();
		let prevDay:number=0;
		return days.map((day:number):number=>{
			if (!day && day !==0){
				prevDay=getPrevDayIndex(prevDay);
			}else {
				prevDay=day;
			}
			return prevDay;
		})
		.map((day:number):string=>weekLabels[day])
		.reverse();
	};
	console.log({distributionData});
	if(!distributionData.length){
		return <h1>Loading...</h1>;
	}

	return <TrackingGraphRender
		title={title}
		labels={getWeekLabels()}
		dataset={{data:getData(),backgroundColor:'#fff'}}
		{...props}
	/>;
};
