import React from 'react';
import {
	DoubleRange,
	DropDown, FieldSection,
	FormButton, LikeCheckList
} from 'components/ui';
import {FormProps,Item} from 'interfaces';
import {Message} from 'components';
import {color} from 'styles/colors';
import styled from 'styled-components';

interface CampaignAudienceRenderProps extends FormProps{
	options:{
		genders: Item[]
		ethnicities: Item[]
		languages: Item[]
		countries: Item[]
	}
}

const FormContainer:any = styled.form`
	display: flex;
	flex-direction: column;
	row-gap: 3.31rem;
	margin:  2rem 0;
`;

export default function CampaignAudienceRender({submitLoading,errors,options,onSubmit,registerList}:CampaignAudienceRenderProps):JSX.Element {

	return (
		<FormContainer
			onSubmit={onSubmit}
			autoComplete='off'
		>

			<FieldSection
				label={'Age Range'}
				error={errors.ageRange?.message}
			>
				<DoubleRange
					minValue={18}
					maxValue={81}
					styleProps={{
						maxAlias:'80+',
						decimals:0
					}}
					{...registerList('ageRange')}
				/>
			</FieldSection>

			<FieldSection
				label={'Gender'}
				helperText={'Select all that apply.'}

			>
				<LikeCheckList
					value={[]}
					options={options.genders.filter((e:Item):boolean=>e.label !== 'Non-Binary'  )}
					{...registerList('genders')}
				/>
			</FieldSection>

			<FieldSection
				label={'Ethnicity'}
				helperText={'Select all that apply.'}

			>
				<LikeCheckList
					value={[]}
					options={options.ethnicities}
					{...registerList('ethnicity')}
				/>
			</FieldSection>
			{/*<FieldSection*/}
			{/*	label={'Location'}*/}
			{/*	helperText={'What country would you like to target for your Opportunity.'}*/}
			{/*>*/}
			{/*	<DropDown*/}
			{/*		placeholder={'Search countries'}*/}
			{/*		theme={'opusV2'}*/}
			{/*		styleProps={{searchIcon:true}}*/}
			{/*		options={options.countries}*/}
			{/*		{...registerList('country')}*/}
			{/*	/>*/}

			{/*</FieldSection>*/}

			<div className={'w-100 flex direction-column align-center mt-2'}>
				<FormButton
					variant={'white'}
					loading={submitLoading}>
					Continue
				</FormButton>

				{errors&&<div className={'mt center'}>
					{errors.get('submitError') && <Message message={errors.get('submitError')} color={color.get('primary')}/>}
				</div>}

			</div>

		</FormContainer>
	);
}