import React, {KeyboardEvent} from 'react';
import {InputProps} from 'interfaces';
import {Input,InputWrapper,Label} from 'styles/ui';

interface InputRenderProps extends InputProps{
	StatusIcon:JSX.Element,
	appendableElement?:React.ReactNode
}

export default function InputRender({StatusIcon,Icon,value,label,required,className,appendableElement,type,...props}:InputRenderProps):JSX.Element{
	const handleKeyDown = (e:KeyboardEvent<HTMLInputElement>):void => {

		if (type === 'number') {
			if (!/^\d$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
				e.preventDefault();
			}
		}
	};
	return (
		<InputWrapper localTheme='opusV2' className={className}>
			<div>
				<div>
					<Label>{label}</Label>
				</div>
				<div className={'flex justify-center col-gap align-center'}>
					{Icon && Icon}
					<Input
						{...props}
						value={value}
						type={type}
						required={required}
						onKeyDown={handleKeyDown}
					/>

				</div>
			</div>
			{appendableElement}
			{/*<OKIconWrapper value={value}>*/}
			{/*	{required && <RequiredText>Required</RequiredText>}*/}
			{/*	{StatusIcon}*/}
			{/*</OKIconWrapper>*/}
		</InputWrapper>
	);
}
