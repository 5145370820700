import {useEffect, useState} from 'react';

export type TTab = {
    label:string;
    identifier:string;
    action?:()=>void;
}

type TUseTabsArgs = {
    tabs:TTab[]
    defaultTab?:string
    onChange?:(tab:string)=>void

}
type TUseTabs = [string, (tab: TTab) => void]

export const useTabs = ({tabs,defaultTab,onChange}:TUseTabsArgs ): TUseTabs => {
	const [selectedTab, setSelectedTab] = useState<string>( defaultTab|| tabs[0].identifier);
	const [onChangeCount, setOnChangeCount] = useState<number>(0);
	const handleSelectedTab =(selectedTab:TTab):void=>{
		setSelectedTab(selectedTab.identifier);
		setOnChangeCount(onChangeCount+1);
		selectedTab.action && selectedTab.action();
	};

	useEffect(():void => {
		if(onChangeCount){
			onChange && onChange(selectedTab);
		}
	}, [onChangeCount]);

	useEffect(():void => {
		setSelectedTab(defaultTab|| tabs[0].identifier);
	}, [defaultTab]);

    
	return [
		selectedTab,
		handleSelectedTab
	];
    
};