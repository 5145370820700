import React from 'react';
import {ContentLayout} from 'containers';
import Stepper from './Components/Stepper';
import styled from 'styled-components';
import {Toggle} from 'components/Toggle';
import {Spinner} from 'components/ui';
import {Opportunity, OpportunityAudience} from 'interfaces';

type OpportunityCreateRenderProps = {
	id?:string
	form:string
	isLoading:boolean
	children?:React.ReactNode
	handleIsActive:(e: React.ChangeEvent<HTMLInputElement>)=>void

	opportunity?:Opportunity
}

const FormContainer:any=styled.div`
	width: 100%;
	max-width: 45rem;
	
`;

const HeaderContainer:any=styled.div`
	width: 100%;
	display: flex;
	max-width: 720px;
	position: relative;
	
`;

export const OpportunityCreateRender = ({form,isLoading,opportunity,children,handleIsActive}:OpportunityCreateRenderProps):JSX.Element => {
	return (
		<ContentLayout

		>
			{/* eslint-disable-next-line no-constant-condition */}
			{ isLoading ?
				<div className={'w-100'}>
					<Spinner/>
				</div>
				:
				<>
					<HeaderContainer  className ='mb-3' >
						<div className={'grows  flex justify-center'}>
							<Stepper steps={[
								{id:'1',label:'Details',identifier:'details'},
								{id:'2',label:'Audience',identifier:'audience'},
								{id:'2',label:'Talent',identifier:'talent'}
							]} current={form}
							/>
						</div>
						<div className={'absolute right-0'}>
							<Toggle
								activeLabel={'Published Version'}
								inactiveLabel={'Save as draft'} name={'draft'}
								id={'draft'}
								justify={'right'}
								defaultChecked={opportunity?.active}
								onChange={handleIsActive}
							/>
						</div>

					</HeaderContainer>
					<FormContainer>
						{children}
					</FormContainer>
				</>
			}
		</ContentLayout>
	);
};