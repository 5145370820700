import React from 'react';
import {
	Chart as ChartJS,
	ArcElement, ChartData, ChartOptions
} from 'chart.js';
import {DoughnutRender} from './DoughnutRender';

ChartJS.register(
	ArcElement
);

interface DonutProps extends ChartData<'doughnut'>{
	className?:string
}

export const Doughnut= ({datasets,labels,className}:DonutProps):JSX.Element=>{

	const data:ChartData = {
		labels,
		datasets: datasets
	};

	const options:ChartOptions<'doughnut'> = {
		responsive: true,
		maintainAspectRatio:false,
		radius:'100%',
		cutout:'72.5%',
		clip:100,
		plugins:{
			legend:{
				display:false
			}
		}
	};


	return <DoughnutRender
		data={data}
		options={options}
		className={className}
	/>;
};