import React, {MutableRefObject} from 'react';
import {MenuIcon, NotificationIcon, OpusLogo} from 'icons';
import {DesktopHeader, StyledHeader} from './header.styles';
import {Link} from 'react-router-dom';
import {HeaderIcon, HeaderIconProps} from './components/HeaderIcon';
import styled from 'styled-components';
import {BreadCrumb} from 'components/BreadCrumb';

export type HeaderMenu= {
	show?:boolean
	open?:()=>void
	ref:MutableRefObject<Element>
	element?:JSX.Element
}

interface HeaderRenderProps {
	theme?:'opus'|'opusV2'
	scroll:boolean
	currentPath:string
	menuAction?:()=>void
	icon?:HeaderIconProps
	menu?:HeaderMenu
	content?:JSX.Element
	description?:string
}

export const StyledPopupMenu:any=styled.div`
  position: relative;

  >div{
	position: absolute;
    right:0;
	top: 20px;
	padding: 1rem 1rem 0 0;
  }
`;


export default function HeaderRender({scroll,theme,currentPath,menuAction,icon,menu,content, description}:HeaderRenderProps):JSX.Element{
	return (
		<StyledHeader styleTheme={theme} gradient={!scroll} s>
			<DesktopHeader>
				<div className={'w-100 flex justify-between'}>
					<>
						{content ?
							<>
								{content}
							</>
							:
							<BreadCrumb/>
						}
					</>

				</div>
				{theme !== 'opusV2'&& <hr className={'w-100 mt op-60'}/>}
			</DesktopHeader>
			<div className={'z-1000'}>
				<div className='flex align-center '>
					<div className={'mr cursor-pointer'} onClick={menuAction}>
						<MenuIcon/>
					</div>
					<Link to={'/'} className={'cursor-pointer'}>
						<OpusLogo/>
					</Link>
				</div>
				{icon ?<HeaderIcon {...icon}/>:<NotificationIcon/>}
			</div>
		</StyledHeader>
	);
}
