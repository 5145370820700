import React, {ChangeEvent} from 'react';
import {UserSearchRender} from './UserSearchRender';
import {SearchHandler, useSearch} from 'hooks/useSearch';
import { QueryResult} from '@apollo/client';
import {Item, User} from 'interfaces/interfacesData';
import {ListInput} from 'interfaces';
import {listHandler, useListHandler} from 'hooks/useListHandler';
interface UserSearchProps extends Omit<ListInput, 'options'>{
	type:string
}

const userToItem = (user:User):Item=> ({
	id:user._id,
	label:`${user.firstName} ${user.lastName}`
});

export const UserSearch = ({type,value,name,onChange, ...props}:UserSearchProps):JSX.Element => {

	const { showOptions,setShowOptions,queryResult, optionWrapperRef ,setQuery }:SearchHandler=useSearch({
		searchAction:'usersSearchMany',
		variables:{type}
	});

	const { data,loading,error }:QueryResult=queryResult;
	
	const userOptions:User[]= data?.usersSearchMany || [];
	let options :Item[]=  userOptions.map((u:User):Item=>userToItem(u) ) || [];
	
	const {handleUnselected,handleSelected}:listHandler=useListHandler({name,value,options,onChange});
	

	const onUnselectOption =(user:User):void=>{
		setShowOptions(false);
		handleUnselected(userToItem(user));
	};

	const onSelectOption =(item:Item):void=>{
		setShowOptions(false);
		handleSelected(item);
	};

	const onChangeQuery=(e:ChangeEvent<HTMLInputElement>):void=>{
		setShowOptions(true);
		setQuery(e.target.value);
	};

	const onClickArrow =():void=>{
		setShowOptions(!showOptions);
	};

	return <UserSearchRender
		{...props}
		ref={optionWrapperRef}
		options={{
			items:options,
			show:showOptions,
			loading,
			error:!!error
		}}
		value = {value}
		onChangeQuery={onChangeQuery}
		onSelectOption={onSelectOption}
		onUnselectOption={onUnselectOption}
		onClickArrow={onClickArrow}
	/>;
};