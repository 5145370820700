import styled from 'styled-components';
import {themeOpus} from 'stylesOpus/theme';

export const StyledLabel:any = styled.label<{ checked: boolean }>`  
cursor: pointer;  
text-indent: -9999px;  
width: 44px;  
height: 24px;  
background: ${({ checked }:any):string => (!checked ? themeOpus.colors.red :  '#00D95F')};  
display: block;  
border-radius: 100px;  
position: relative;
&:after {    
content: "";    
position: absolute;    
left: ${({ checked }:any):string => (!checked ? '3px' : 'calc(56% - 5px)')};    
top: 2px;    
width: 20px;    
height: 20px;    
background: #fff;    
border-radius: 90px;    
transition: 0.3s;  
}`;