import React, {useEffect} from 'react';
import { SettingsRender } from './SettingsRender';
import {SetterOrUpdater, useSetRecoilState} from 'recoil';
import {BreadCrumbAtom, TCrumb} from 'context/recoil/store';
import {useLocation, Location, Navigate} from 'react-router-dom';

export const Settings = (): JSX.Element => {
	const setBreadCrumbs: SetterOrUpdater<TCrumb[]>=useSetRecoilState(BreadCrumbAtom);
	const {pathname}:Location = useLocation();
	const tab:string|undefined = pathname.split('/')[2];
	
	useEffect(():void => {
		setBreadCrumbs([
			{id: 'settings', label: 'settings', path:'/settings'}
		]);
	}, []);
	

	
	return (
		!tab ? <Navigate to={'/settings/security/email'}/> :
			<SettingsRender/>
	);
};
