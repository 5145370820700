import {QueryResult, useQuery} from '@apollo/client';
import {query} from '../../actions/queries';
import {TalentDiscoverArgs, TalentDetailData} from './types';


export const useTalentDetail = ({talentId}:TalentDiscoverArgs):TalentDetailData=>{
	const discoverResult:QueryResult = useQuery(query('discoverTalent'),{
		variables : {
			user: talentId
		}
	});
	// eslint-disable-next-line @typescript-eslint/typedef
	const instagramAudience:QueryResult=useQuery(query('instagramAudienceFindOne'),{
		variables:{
			filter:{user: talentId}
		}
	});
    
	let discoveryUser : TalentDetailData ;
	discoveryUser = {
		discoverData: {
			...discoverResult,
			data: discoverResult.data?.usersTalentProfileFindOne,
			error: discoverResult.error?.message
		}
		,
		instagramAudience: {
			...instagramAudience,
			data: instagramAudience.data?.instagramAudienceFindOne,
			error: instagramAudience.error?.message
		}
	};

	return discoveryUser;
};

