import React from 'react';
import TagsSelectRender from './TagsSelectRender';
import {Item, ListInput} from 'interfaces';
import {listHandler, useListHandler} from 'hooks/useListHandler';

export const TagsSelect=({options,name,onChange,label,value,...props}:ListInput):JSX.Element=>{
	const {handleUnselected,handleSelected,getSelectedItems}:listHandler=useListHandler({name,onChange,value,options});

	const handleClick=(item:Item):void=>{
		if (value.includes(item.id)){
			return handleUnselected(item);
		}
		handleSelected(item);
	};

	return <TagsSelectRender
		selectedItems={getSelectedItems()}
		options={options}
		label={label}
		onClickTag={handleClick}
		{...props}
	/>;
};
