import React from 'react';
import styled from 'styled-components';
import {color} from 'styles/colors';

interface TopTalentITemProps {
	category?:string
	image?:string
}

const Container:any =styled.div`
  max-width: 194px;
  max-height: 120px;
  background-image: url(${ ({image}:any):string=>image});
	background-color: ${color.get('neutral-400')};
  background-size: cover;
  border-radius: 10px;
  font-weight: 700;
  >div{
  	padding: 12px 20px 16px 18px ;
	height: 100%;
	background-color: rgba(0,0,0,0.8);
  }
`;

const Top:any=styled.p`
	font-size: 18px;
  
`;

const Text:any=styled.div`

  align-items: center;
	>p:first-child{
	  font-size: 30px;
	  border-bottom: solid 1px ;
	}
  >p:first-child{
	font-size: 30px;
	width: fit-content;
  }
  >p:last-child{
	font-size: 14px;
	
  }
`;

const Logo:any=styled.p`
  font-weight: 400;
  font-size: 8px;
  width: fit-content;
	border-bottom: 1px solid;
`;

export const TopTalentITem = ({category, image}:TopTalentITemProps):JSX.Element => {
	return <Container image={image}>
		<div>
			<Logo>The ØPUS</Logo>
			<Top>Top</Top>
			<Text>
				<p>50</p>
				<p>{category||''}</p>
			</Text>
		</div>
	</Container>;
};