import styled from 'styled-components';

export const Container: any = styled.div`
  width: 100%;
  padding: 20px 24px ;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5.4rem;
`;
export const Page: any = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #757575;
  margin: 20px;
`;
export const Top: any = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-around;
  height: 10vh;
  font-weight: 700;
  color: #fff;
  align-items: center;
`;
export const PageText: any = styled.div``;
export const FormContainer: any = styled.div`
  width: 100%;
  display: flex;
  margin-top: 5%;
  justify-content: center;
  flex-direction: column;
  text-align: start;
  align-items: center;
`;
export const FormContent: any = styled.div`
  width: 90%;
`;
export const Links: any = styled.div`
  width: 100%;
  display: flex;
  height: auto;
  justify-content: space-evenly;
  text-align: center;
`;
export const ContentFactor: any = styled.div`
  width: 100%;
  height: 15vh;
  display: flex;
  justify-content: space-evenly;

  flex-direction: column;
`;
export const Option: any = styled.div`
  width: 90%;
  display: flex;
  margin-bottom: 5%;
  align-items: center;
  justify-content: space-around;
`;
export const Button: any = styled.button`
  background: none;
  border: 1px solid #b0b0b2;
  padding: 8px 20px;
  border-radius: 15px;
  color: white;
`;
export const TwoFactor: any = styled.p`
  font-weight: 200;
  font-size: 16px;
  color: #ffffff;
`;

export const Subtitle: any = styled.p`
  font-size:16px;
  font-weight: 700;
`;

export const Text: any = styled.p`
  font-weight: 400;
  font-size: 15px;
  color: #ffffff;
`;


export const  SecurityForm:any = styled.form`
  width: 100%;
  max-width: 32.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 3.75rem;
  
  >div{
    width: 100%;
  }
`;
