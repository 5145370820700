import React from 'react';
import {CategoryBox} from 'components/ui';
import {ArticleCard} from 'components';
import {Section} from 'styles/styledComponents/containers';
import {BoxTitle} from 'stylesOpus/typography';
import {TArticle} from 'components/ArticleCard/ArticleCard';
import {CategoryBoxProps} from 'components/ui/CategoryBox/CategoryBox';


type CategoryDetailProps = {
    category:CategoryBoxProps['category']
    articles:TArticle[]
}
export const CategoryDetailRender = ({category,articles}:CategoryDetailProps):JSX.Element => {

	return (
		<>
			<Section className={'pt-2 mt-half'}>
				<div className={'flex'}>
					<BoxTitle>Latest in {category.text}</BoxTitle>
					{/*<Tag className={'ml'}>2 NEW</Tag>*/}
				</div>
				<div className={'w-100 grid4 col-gap mt row-gap-2'}>
					{articles?.length > 0 && articles.map((article:TArticle, index:number):JSX.Element =>
						<ArticleCard
							key={`${index}-${article.title}`}
							article={article}
							isNew={false}
						/>
					)}
				</div>
			</Section>
		</>
	);
};
