import React from 'react';
import TabStatic from './TabStatic';
import {useTabs} from 'hooks';
import {TTab} from 'hooks/useTabs';

type TabsProps = {
    tabs:TTab[]
    className?:string
    defaultTab?:string
	onChange?:(tab:string)=>void
};

export const Tabs =({tabs,className,defaultTab,onChange}:TabsProps):JSX.Element => {

	const [currentTab,handleSelect] = useTabs({tabs,defaultTab,onChange});

	return (
		<div className={className? className : 'w-100'}>
			{tabs.map((tab:TTab):JSX.Element =>
				<TabStatic
					key={tab.label}
					selected={currentTab === tab.identifier}
					onClick={():void=>handleSelect(tab)}
				>{tab.label}</TabStatic>
			)}
		</div>
	);
};