import React from 'react';
import {BrandAvatar, StackContainer, StackContainerProps} from 'styles/ui';
import {Avatar} from '../ui';
import {AvatarUser} from '../ui/Avatar/AvatarRender';
interface BrandTalentAvatars extends StackContainerProps{
	talent:AvatarUser
	brandPicture:string
}

export const BrandTalentAvatars = ({talent,brandPicture,sizeValue,sizeType,...props}:BrandTalentAvatars):JSX.Element => {
	return <StackContainer
		{...props}
		sizeValue={sizeValue}
		sizeType={sizeType}
	>
		<BrandAvatar img={brandPicture} size={`${sizeValue}${sizeType}`}/>
		<Avatar user={talent} size={`${sizeValue}${sizeType}`}/>
	</StackContainer>;
};
