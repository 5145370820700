import React from 'react';
import {Route} from './interfaces';
import {CreateAlert,CompanyAlerts,EditAlert,Alerts} from '../pages';
import {Outlet} from 'react-router-dom';
import AlertDetail from '../pages/AlertDetail';
import {AlertsContainer} from '../containers/AlertsContainer';
import {AdditionalInfo,Location,AboutMusicVideo} from '../pages/EditAlert/forms';
import {AuthRoute, Layout} from '../containers';
import {dashboardRoutes} from './dashboardRoutes';
import {discoverRoutes} from './discoverRoutes';
export const alertRoutes:Route[] =[
	{
		path: 'alerts',
		element: <AuthRoute><Layout styleTheme={'opusV2'}>
			<AlertsContainer/>
		</Layout>

		</AuthRoute>,
		children:[
			{
				path: '',
				element:<Alerts />
			},
			{
				path:'create/:eventType',
				element:<CreateAlert/>
			},
			{
				path:'create-event/:id',
				element:<Outlet/>,
				children:[
					{
						path:'location',
						element:<Location/>
					}
				]
			},
			{
				path:'create-music-event/:id',
				element:<AboutMusicVideo/>
			},
			{
				path:':id/additional-info',
				element:<AdditionalInfo/>
			}
		]
	},
	{
		path: 'alerts',
		element: <AuthRoute><Layout hideHeader styleTheme={'opusV2'}>
			<AlertsContainer/>
		</Layout>

		</AuthRoute>,
		children:[

			{
				path: ':id',
				element: <AlertDetail />
			}
		]
	},


	{
		path: 'company/alerts',
		element: <AuthRoute><Layout styleTheme={'opusV2'}>
			<AlertsContainer/>
		</Layout>

		</AuthRoute>,
		children:[
			{
				path: '',
				element: <CompanyAlerts/>
			},
			{
				path: 'new',
				element: <Outlet/>,
				children:[
					{
						path:':eventType',
						element:<CreateAlert/>
					}
				]
			},
			{
				path: ':id/edit',
				element: <Outlet/>,
				children:[
					{
						path: ':eventType',
						element: <EditAlert/>
					},
					{
						path:'location',
						element:<Location/>
					},
					{
						path:'music-event',
						element:<AboutMusicVideo/>
					},
					{
						path:'additional-info',
						element:<AdditionalInfo/>
					}
				]
			}
		]
	}
];

