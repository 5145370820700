import React, {useState} from 'react';
import {Talent} from 'interfaces/interfacesData';
import {ImageCard} from 'components/ui';
import {DiscoveryTalentModal} from 'components/complexComponents';
import {ShareButton, StyledDescription, StyledPlus, StyledScore, StyledTitle} from './ListItem.styles';
import {useMutation} from '@apollo/client';
import {mutation} from 'actions/mutations';

interface DiscoveryMatchItem {
	talent:Talent
	listId:string
	action:(talentId:string)=>void
	score?:any
	setTalent?:(id:string)=>void
	add?:boolean
	search?:( talent:Talent )=>void
}

export const ListItem = ({talent,score,add=true,listId, search}:DiscoveryMatchItem):JSX.Element => {

	const [showTalent, setShowTalent] = useState<boolean>(false);
	const [removeFromlsit] = useMutation(mutation('listRemoveUserOne'),{
		refetchQueries:['listFindOne']
	});
	const handleRemove = ():void => {
		removeFromlsit({
			variables: {id:listId, user:talent._id}
		});
		console.log('remove');
	};
	const profile :Talent['profile']= talent.profile;
	return <div >

		<div className="relative cursor-pointer">
			<ImageCard img={talent.profilePicture?.url} onClick={():void=>setShowTalent(true)}>

			</ImageCard>
			<div>
				{!add &&
					<ShareButton
						className=' w-0'
						width={'auto'}
						type='button'
						onClick={():void=>search(talent)}
					>
						<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M2.625 13.9863C1.99219 13.9863 1.51367 13.8262 1.18945 13.5059C0.865234 13.1855 0.703125 12.7109 0.703125 12.082V6.31641C0.703125 5.69141 0.865234 5.21875 1.18945 4.89844C1.51367 4.57422 1.99219 4.41211 2.625 4.41211H4.22461V5.56641H2.69531C2.42188 5.56641 2.21289 5.63867 2.06836 5.7832C1.92773 5.92383 1.85742 6.13672 1.85742 6.42188V11.9824C1.85742 12.2676 1.92773 12.4805 2.06836 12.6211C2.21289 12.7656 2.42188 12.8379 2.69531 12.8379H9.29883C9.56836 12.8379 9.77539 12.7656 9.91992 12.6211C10.0684 12.4805 10.1426 12.2676 10.1426 11.9824V6.42188C10.1426 6.13672 10.0684 5.92383 9.91992 5.7832C9.77539 5.63867 9.56836 5.56641 9.29883 5.56641H7.78125V4.41211H9.375C10.0078 4.41211 10.4863 4.57422 10.8105 4.89844C11.1348 5.21875 11.2969 5.69141 11.2969 6.31641V12.082C11.2969 12.707 11.1348 13.1797 10.8105 13.5C10.4863 13.8242 10.0078 13.9863 9.375 13.9863H2.625ZM6 9.31641C5.85156 9.31641 5.72266 9.26367 5.61328 9.1582C5.50781 9.05273 5.45508 8.92773 5.45508 8.7832V2.88281L5.50195 2.00977L5.13281 2.4375L4.3125 3.31641C4.21875 3.42188 4.09766 3.47461 3.94922 3.47461C3.8125 3.47461 3.69727 3.43164 3.60352 3.3457C3.51367 3.25586 3.46875 3.14258 3.46875 3.00586C3.46875 2.88086 3.51758 2.76562 3.61523 2.66016L5.58398 0.767578C5.6582 0.697266 5.72852 0.648438 5.79492 0.621094C5.86133 0.59375 5.92969 0.580078 6 0.580078C6.07031 0.580078 6.13867 0.59375 6.20508 0.621094C6.27148 0.648438 6.33984 0.697266 6.41016 0.767578L8.37891 2.66016C8.48047 2.76562 8.53125 2.88086 8.53125 3.00586C8.53125 3.14258 8.48242 3.25586 8.38477 3.3457C8.29102 3.43164 8.17773 3.47461 8.04492 3.47461C7.90039 3.47461 7.7793 3.42188 7.68164 3.31641L6.86133 2.4375L6.49805 2.00977L6.54492 2.88281V8.7832C6.54492 8.92773 6.49023 9.05273 6.38086 9.1582C6.27539 9.26367 6.14844 9.31641 6 9.31641Z" fill="white"/>
						</svg>
					</ShareButton>
				}
				{add ?
					<StyledPlus >+</StyledPlus>
					:
					<StyledPlus
						onClick={():void=>handleRemove()}
					>-</StyledPlus>
				}
			</div>
		</div>
		<div className={'flex justify-between'}>
			<div>
				<StyledTitle>{}</StyledTitle>
				{(profile?.industry[0] || profile?.industrySegment[0]) ?
					<p className={'f-700 t-neutral-400'}>
						{profile?.industry[0]}{(profile?.industry && profile?.industrySegment[0]) ? ', ' : ''}{profile?.industrySegment[0]}
					</p>
					: null
				}
				<StyledDescription>{talent.firstName} {talent.lastName} </StyledDescription>
			</div>
			{score?.match ? <StyledScore>{(score?.match*100).toFixed(0)}</StyledScore> : ''}
		</div>
		<DiscoveryTalentModal
			talentId={talent._id}
			setShowTalent={setShowTalent}
			isVisible={showTalent}
			add={add}
		/>
	</div>;
};