/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled from 'styled-components';

export const Container:any = styled.div<{image: string}>`
    aspect-ratio: 1/1;
  	width: 100%;
  	display: block;
    background-image: url(${({image}: any) => image});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0.5;
`;

export const Text:any = styled.h3`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    color: #fff;
    font-size: 0.75rem;
    z-index: 2;
`;