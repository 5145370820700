import React from 'react';
import styled from 'styled-components';
import {Button, ImageCard} from '../ui';
import {color} from 'styles/colors';
import {formatDate} from '../../tools/date';
import {CulturalAlert, User} from '../../interfaces';
import {Link} from 'react-router-dom';
import {QueryResult, useQuery} from '@apollo/client';
import {query} from '../../actions/queries';

export interface CulturalItemProps {
	culturalAlert:CulturalAlert
	showAction?:boolean
	button?:{
		action:()=>void
		label:string
	},
	openDeleteModal?: (id:string)=>void
}


const StyledTitle:any=styled.h6`
  margin-top: 1rem;
  font-weight: 900;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  font-family: "Urbanist", sans-serif;
  margin-bottom: 8px;
`;

const CulturalTagline:any=styled.div`
  color:#545766;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
`;

const CulturalButton:any=styled(Button)`
  margin-top: 0.5rem;
	text-transform: uppercase;
  width: fit-content;
`;

const StyledDate:any=styled.p`
  opacity: 0.8;
  position: absolute;
  right: 8px;
  top: 8px;
  color: #ffffff;
  background-color: ${color.get('neutral-400')};
  width: fit-content;
  border-radius: 4px;
  padding: 0.2rem 0.4rem;
`;

const formatCulturalDate =(d:string|null):string=>{
	if (!d)
		return null;
	const dateString:string=formatDate(d);

	let dateSplit:string[] =dateString.split('/');

	return dateString.replace(dateSplit[2],dateString.slice(-2));
};

export const CulturalItem=({culturalAlert,showAction,button,openDeleteModal }:CulturalItemProps):JSX.Element=>{

	const lastDate:string=culturalAlert.eventTo || culturalAlert.endDate;
	// console.log({lastDate,culturalAlert});
	const {title,photos,culturalType}:CulturalAlert = culturalAlert;
	const  {data:artistData}:QueryResult= useQuery(query('usersFindMany'),{variables:{
		filter:{_id:culturalAlert.artist[0]}
	}});
	const artisUSer:User=artistData?.usersFindMany[0] || null;

	const typeString = (type:string):string => {
		if(type === 'musicvideo'){
			return 'Music Video';
		}
		else {
			return 'Event';
		}
	};

	return <div >
		<Link to={`/alerts/${culturalAlert._id}`}>
			<ImageCard
				img={photos?.length && photos[0].url || artisUSer?.profilePicture?.url}
				aspectRatio='11/10'
			>
				{/*{lastDate &&*/}
				{/*	<StyledDate>Ends: {formatCulturalDate(lastDate) || '-- / -- / --'}</StyledDate>*/}
				{/*}*/}
			</ImageCard>
		</Link>

		<StyledTitle>{artisUSer?.firstName} {artisUSer?.lastName}</StyledTitle>
		<CulturalTagline>
			<p>
				<span>{typeString(culturalType)}: </span>{title}
			</p>
		</CulturalTagline>

		{/*culturalAlert.description && <StyledDescription>{culturalAlert.description}</StyledDescription>*/}
		<div className={'flex justify-between'}>
			{showAction && button && <CulturalButton
				size={'small'}
				onClick={button.action}
			>
				{button.label}
			</CulturalButton>}
			{openDeleteModal ?
				<CulturalButton
					variant='secondary'
					size={'small'}
					label='delete'
					onClick={(): void => openDeleteModal(culturalAlert._id)}
				/>: null
			}
		</div>
	</div>;
};