import React from 'react';

export const CameraIcon=():JSX.Element=>
	<svg
		width={24}
		height={24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M9.07317 4L7.85366 6.43902H3.70732C2.76146 6.43902 2 7.20049 2 8.14634V18.8781C2 19.8239 2.76146 20.5854 3.70732 20.5854H20.2927C21.2385 20.5854 22 19.8239 22 18.8781V8.14634C22 7.20049 21.2385 6.43902 20.2927 6.43902H16.1463L14.9268 4H9.07317V4ZM12 8.14634C14.952 8.14634 17.3659 10.5603 17.3659 13.5122C17.3659 16.4641 14.952 18.8781 12 18.8781C9.0481 18.8781 6.63415 16.4641 6.63415 13.5122C6.63415 10.5603 9.0481 8.14634 12 8.14634V8.14634ZM12 10.0976C10.1026 10.0976 8.58537 11.6148 8.58537 13.5122C8.58537 15.4096 10.1026 16.9268 12 16.9268C13.8974 16.9268 15.4146 15.4096 15.4146 13.5122C15.4146 11.6148 13.8974 10.0976 12 10.0976Z"
			fill="white"
		/>
	</svg>
;
