import React, {useState} from 'react';
import styled from 'styled-components';
import {Budget, BudgetProps} from '../Budget';
import {color} from 'styles/colors';
import {ReactComponent as ChevronUpIcon} from 'styles/icons/chevron-up.svg';
import {ReactComponent as ChevronDownIcon} from 'styles/icons/chevron-down.svg';

interface CollapsableProps {
	defaultShow?:boolean
	className?:string
	label:string
	children:React.ReactNode
	budget?:BudgetProps
}

const CollapsableContainer:any=styled.div`
  position: relative;
  width: 100%;
`;


export const Collapsable = ({children,label,budget,defaultShow=false}:CollapsableProps):JSX.Element => {
	const [show,setShow]= useState<boolean>(defaultShow);

	const handleCollapsable=():void=>{
		setShow((prev:boolean):boolean =>!prev );
	};

	return <CollapsableContainer >
		<div className={'flex w-100 justify-between  cursor-pointer'}  onClick={handleCollapsable}>
			<div className={'flex align-center'}>
				<p className={`mr ${show? 't-white': 't-neutral-400'}`}>{label}</p>
				{budget &&
					<Budget
						color={show? color.get('primary') : color.get('neutral-600')}
						label={{
							text: budget.label.text||'',
							size: '9px',
							color: show ? color.get('white') : color.get('neutral-400')
						}}

					/>
				}
			</div>
			<div >
				{show? <ChevronUpIcon /> : <ChevronDownIcon />}
			</div>
		</div>
		<div className={'w-100 pver'}>
			{show &&  children}
		</div>
	</CollapsableContainer>;
};