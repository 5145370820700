import React from 'react';
import {useParams, Outlet, NavLink} from 'react-router-dom';
import {SocialCardT, TalentData} from '../../hooks/useTalentDetail';
import {InstagramOutlineIcon} from '../../icons';
import {abbreviateMount} from '../../tools/prices';
import {AddFavoriteButton} from '../../components';
import {TiktokOutlineIcon} from '../../icons/social/TiktokOutlineIcon';
import {YoutubeOutlineIcon} from '../../icons/social/YoutubeOutlineIcon';

interface TalentDetailI {
	talent?: TalentData
	social?: SocialCardT
	totalMedia?: number
}

export default function TalentDetailRender({talent, social, totalMedia}:TalentDetailI):JSX.Element{

	const {id}:any = useParams();
	const tabStyle:string = 'br-2 f-urbanist f-bold f-12px t-white phor-2 pver block';

	window.scrollTo(0, 0);

	return (
		<div>
			{talent &&
				<div className="flex align-center br p-2 neutral-800 relative">
					<img className="w-200px h-200px shrinks-0 br-50 mr mr-20px obj-cover" src={talent?.userObj?.profilePicture?.url} />
					<div>
						<h1 className="f-30px f-700 f-urbanist">
							{talent?.userObj?.firstName} {talent?.userObj?.lastName}
						</h1>
						<p className="mt-half f-12px f-bold op-60">{talent?.description}</p>
						<div className="flex mt-2 col-gap">
							{social?.instagram?.followers &&
								<div className="center">
									<span className="op-60">
										<InstagramOutlineIcon size={20} color={'white'} />
									</span>
									<div className="f-18px f-urbanist f-900">
										{abbreviateMount(social?.instagram?.followers)}
									</div>
								</div>
							}

							{social?.tiktok?.followers &&
								<div className="center">
									<span className="op-60">
										<TiktokOutlineIcon size={20} color={'white'} />
									</span>
									<div className="f-18px f-urbanist f-900">
										{abbreviateMount(social?.tiktok?.followers)}
									</div>
								</div>
							}
							{social?.youtube?.followers &&
								<div className="center">
									<span className="op-60">
										<YoutubeOutlineIcon size={20} color={'white'} />
									</span>
									<div className="f-18px f-urbanist f-900">
										{abbreviateMount(social?.youtube?.followers)}
									</div>
								</div>
							}
						</div>
					</div>
					<div className="absolute bottom-2 right-2 f-10px f-700 t-neutral-300 f-urbanist">
						Last Update on: {social?.updatedAt &&
							new Date(social?.updatedAt).toLocaleString('en-US', {
								month: 'short',
								day: 'numeric',
								year: 'numeric'
							})
						}
					</div>
					<div className="absolute top-1 right-1">
						<AddFavoriteButton
							label={'Add to Favorites'}
							talentId={id}
						/>
					</div>
				</div>
			}
			<div className="flex justify-center">
				<div className="p-2px neutral-840 flex align-center mver-2 br-2">
					<NavLink
						to={`/talent/${id}/opus`}
						className={({ isActive }:any):string => isActive ? `${tabStyle} neutral-900` : tabStyle}
					>
						Øpus
					</NavLink>
					<NavLink
						to={`/talent/${id}/feed`}
						className={({ isActive }:any):string => isActive ? `${tabStyle} neutral-900` : tabStyle}
					>
						Feed
					</NavLink>
					{totalMedia > 0 &&
						<NavLink
							to={`/talent/${id}/media`}
							className={({ isActive }:any):string => isActive ? `${tabStyle} neutral-900` : tabStyle}
						>
							Media
						</NavLink>
					}
				</div>
			</div>
			<Outlet />
		</div>
	);
}