import React from 'react';
import styled from 'styled-components';

const NumberContainer:any = styled.div`
	> h1{
	  font-size: 40px;
	  font-weight: bold;
	}
  	>h4{
	  font-size: 14px;
	  font-weight: bold;
	  text-transform: uppercase;
	}
	>p{
      font-size: 16px;
      font-weight: normal;
	  opacity: 0.7;
	  margin-top: 4px;
	}
`;

interface NumberValueProps{
	title?:string
	value?:string
	description?:string
	className?:string
}

export default function NumberValue({title, value, description, className}:NumberValueProps):JSX.Element {
	return (
		<NumberContainer className={className}>
			<h4>{title}</h4>
			<h1>{value}</h1>
			<p>{description}</p>
		</NumberContainer>
	);
}