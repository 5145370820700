import gql from 'graphql-tag';
import {culturalAlertData} from './responses';

const queries: any = {
	'userTypesFindMany': gql`
	query userTypesFindMany{
	  userTypesFindMany {
		 _id
    	label
	  }
	}`,
	'gendersFindMany': gql`
	query gendersFindMany{
	  gendersFindMany(filter: {}, skip: 0, limit: 100, sort: _ID_ASC) {
		_id
		label
		identifier
		updatedAt
		createdAt
	  }
	}`,
	'ageRangeFindMany': gql`
	query ageRangeFindMany{
	  ageRangeFindMany(filter: {}, skip: 0, limit: 100, sort: _ID_ASC) {
		_id
		label
		updatedAt
		createdAt
	  }
	}`,
	'socialInfoFindMany': gql`
	query($filter:FilterFindManysocialNetworkInput,$skip:Int){
	  socialInfoFindMany(filter: $filter,skip:$skip) {
		_id
		url
		identifier
		connected
		fail
		access_token
		token_expires_in
		id
		refreshAt
		expiredAt
		user
		updatedAt
		createdAt
	  }
	}`,
	'socialInfoByUserFindMany': gql`
	query socialInfoByUserFindMany{
	  socialInfoByUserFindMany {
		_id
		identifier
		url
		connected
		id
		refreshAt
		expiredAt
		user
		updatedAt
		createdAt
	  }
	}`,
	'socialInfoGender': gql`
	query socialInfoGenderDistributionFindMany{
	  socialInfoGenderDistributionFindMany {
		mean
		value
		metadata
	  }
	}`,
	'socialInfoAgeDistributionFindMany': gql`
		query socialInfoAgeDistributionFindMany{
		  socialInfoAgeDistributionFindMany {
			mean
			metadata
		  }
		}`,
	'socialInfoCityDistributionFindMany': gql`
	query socialInfoCityDistributionFindMany{
	  socialInfoCityDistributionFindMany {
		mean
		metadata
	  }
	}`,
	'socialInfoCountryDistributionFindMany': gql`query socialInfoCountryDistributionFindMany{
	  socialInfoCountryDistributionFindMany {
		mean
		metadata
	  }
	}`,
	'socialInfoFollowersFindOne': gql`
	query socialInfoFollowersFindOne{
	  socialInfoFollowersFindOne {
		mean
		metadata
		value
	  }
	}`,
	'socialInfoTotalLikesFindMany': gql`
	query socialInfoTotalLikesFindMany{
	  socialInfoTotalLikesFindMany {
		mean
		value
		metadata
	  }
	}`,
	'socialInfoTotalPostFindMany': gql`
	query socialInfoTotalPostFindMany{
	  socialInfoTotalPostFindMany {
		mean
		value
		metadata
	  }
	}`,
	'socialInfoEngagementFindMany': gql`
	query socialInfoEngagementFindMany($days:Int!){
	  socialInfoEngagementFindMany(days: $days) {
		mean
		value
		metadata
	  }
	}`,
	'socialInfoLikesFindMany': gql`
	query socialInfoInteractionsFindMany($days:Int!){
	  socialInfoLikesFindMany(days: $days) {
		mean
		value
		metadata
	  }
	}`,
	'socialInfoInteractionsFindMany': gql`
	query socialInfoInteractionsFindMany($days:Int!){
	  socialInfoInteractionsFindMany(days: $days) {
		mean
		value
		metadata
	  }
	}`,
	'usersFindOne': gql`
	query usersFindOne($userId:String){
	  usersFindOne(filter: {_id:$userId}, skip: 0, sort: _ID_ASC) {
		_id
		firstName
		lastName
		email
		emailVerified
		type
		updatedAt
		createdAt
		emailVerified
	  }
	}`,
	'usersFindMany': gql`
	query usersFindMany($filter:FilterFindManyuserInput,$skip:Int,$limit:Int,$sort:SortFindManyuserInput){
	  usersFindMany(filter: $filter, skip: $skip, limit: $limit, sort:$sort) {
		_id
		firstName
		lastName
		email
		emailVerified
		type
		acceptTerms
		profilePicture {
		  url
		  type
		}
		deleted
		createdAt
		updatedAt
	  }
	}`,
	'ethnicityFindMany': gql`
	query ethnicityFindMany{
	  ethnicityFindMany(filter: {}, skip: 0, limit: 100) {
		_id
		label
		updatedAt
		createdAt
	  }
	}`,
	'languageFindMany': gql`
	query languageFindMany{
	  languageFindMany(filter: {}, skip: 0, limit: 100, sort: _ID_ASC) {
		_id
		label
		identifier
		updatedAt
		createdAt
	  }
	}`,
	'relationshipStatusFindMany': gql`
	query relationshipStatusFindMany{
	  relationshipStatusFindMany(filter: {}, skip: 0, limit: 100, sort: _ID_ASC) {
		_id
		label
		updatedAt
		createdAt
	  }
	}`,
	'shoeSizeFindMany': gql`
	query shoeSizeFindMany{
	  shoeSizeFindMany(filter: {}) {
		_id
		identifier
		updatedAt
		createdAt
	  }
	}`,
	'clothingSizeFindMany': gql`
	query clothingSizeFindMany{
	  clothingSizeFindMany(filter: {}){
	  	_id
		identifier
		updatedAt
		createdAt
	  }
	}`,
	'talentAuthorityFindOne': gql`
		query talentAuthorityFindOne($user:String!){
			talentAuthorityFindOne(user: $user){
				_id {
					_id
					label
					identifier
					hidden
					order
					father
					tensorInterests
					createdAt
					updatedAt
					title
					picture {
						url
						type
					}
				}
				value
				audience
			}
		}`,
	'usersTalentProfileFindOne': gql`
	query usersTalentProfileFindOne($user:String){
	  usersTalentProfileFindOne(filter: { user:$user}) {
		_id
		user
		interest
		birthday
		country
		description
		phone
		languages
		website
		gender
		ethnicity
		musicGenres
		brands
		hasChildren
		hasPets
		spirits
		shoeSizes
		clothingSizes
		address {
		  address1
		  address2
		  city
		  state
		  zip
		}
		relationshipStatus
		createdAt
		updatedAt
	  	industry
	  	industrySegment
		industryObj(skip: 0, limit: 100, sort: _ID_ASC) {
		  _id
		  label
		  identifier
		  createdAt
		  updatedAt
		}
		industrySegmentObj(skip: 0, limit: 100, sort: _ID_ASC) {
		  _id
		  label
		  identifier
		  createdAt
		  updatedAt
		}
		userObj {
		  	_id
		  	firstName
		  	lastName
            reportedAt
			profilePicture {
				url
			}
		}
	  }
	}`,
	'discoverTalent': gql`
					query usersTalentProfileFindOne($user:String){
            usersTalentProfileFindOne(filter: { user:$user}) {
                _id
                user
				industry
				industrySegment
                industryObj(skip: 0, limit: 100, sort: _ID_ASC) {
				  _id
				  label
				  identifier
				  createdAt
				  updatedAt
				}
				industrySegmentObj(skip: 0, limit: 100, sort: _ID_ASC) {
				  _id
				  label
				  identifier
				  createdAt
				  updatedAt
				}
                birthday
				description
                userObj(skip: 0, sort: _ID_ASC) {
                    firstName
                    lastName
                    profilePicture{
                        url
                    }
                }
            }
		}`,
	'spiritFindMany': gql`
	query spiritFindMany{
	  spiritFindMany(filter: {}) {
		_id
		label
		updatedAt
		createdAt
	  }
	}
	`,
	'categoriesFindMany': gql`
		query categoriesFindMany{
		 categoriesFindMany(filter: {}) {
		_id
		label
		hidden
		createdAt
		updatedAt
	  }
	}`,
	'brandFindMany': gql`
	query brandFindMany($findBrandFilter:FilterFindManybrandInput){
	  brandFindMany(filter:$findBrandFilter ) {
		_id
		name
		website
		description
		company
		interest
		updatedAt
		createdAt
		picture {
		  url
		  type
		}
	  }
	}`,
	'skuFindMany': gql`
	query skuFindMany($findSkuFilter:FilterFindManyskuInput,$skip:Int,$sort:SortFindManyskuInput){
	  skuFindMany(filter: $findSkuFilter, skip: $skip, sort: $sort) {
		_id
		brand
		name
		description
		sku
		picture {
		  url
		  type
		}
		updatedAt
		createdAt
	  }
	}`,
	'skuFindOne': gql`
		query skuFindOne ($id:String){
		  skuFindOne(filter: {_id:$id}) {
			_id
			brand
			name
			description
			sku
			picture {
			  url
			  type
			}
			updatedAt
			createdAt
		  }
		}`,
	'usersCompanyProfileFindOne': gql`
	query usersCompanyProfileFindOne($user:String){
	  usersCompanyProfileFindOne(filter: { user:$user}, skip: 0) {
		_id
		user
		name
		website
		phone
		logo {
		  url
		  type
		}
		createdAt
		updatedAt
	  }
	}`,
	'opportunityFindMany': gql`
	query opportunityFindMany($filter:FilterFindManyopportunityInput!,$skip:Int!,$limit:Int!,$sort:SortFindManyopportunityInput){
	  opportunityFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort) {
	    _id
	    slug
	    active
	    name
	    category
	  	company
	    campaignTypes
	    primaryGoal
	    description
	    strategy
	    requirements
	    notes
	    product
	    sku
	    type
	    budget {
	      minNumber
	      maxNumber
	      updatedAt
	      createdAt
	    }
	    startDate
	    endDate
	    image {
	      url
	      type
	    }
	    treatment{
			url
		  	type
		}
	    updatedAt
	    createdAt
        companyProfile {
			_id
			name
			logo{
				url,
				type
			}
		}
	  }
	}`,
	'interestFindMany': gql`
	query interestFindMany{
	  interestFindMany(filter: {hidden:false}, limit:50, sort: LABEL_ASC) {
	    _id
	    label
	    identifier
	  	tensorInterests
	    hidden
	    createdAt
	    updatedAt
	  	title
	  }
	}`,
	'interestFindOne':gql`
	query interestFindOne ( $_id:String ){
	  interestFindOne(filter: {_id:$_id}) {
		_id
		label
		identifier
		hidden
		order
		father
		tensorInterests
		picture {
		  url
		  type
		}
		updatedAt
		createdAt
	  }
	}
	`,
	'userSettingsFindOne': gql`
	query  userSettingsFindOne($filter:FilterFindOneuserSettingsInput){
	  userSettingsFindOne(filter: $filter) {
		_id
		user
		opportunitiesNotification
		walletNotification
		newsNotification
		paymentsNotification
		emailNotifications
		smsNotifications
		phone2FA
	  }
	}`,
	'opportunityFindOne': gql`
	query opportunityFindOne($filter:FilterFindOneopportunityInput){
	  opportunityFindOne(filter: $filter) {
		_id
		slug
		active
		company
		name
		category
		campaignTypes
		primaryGoal
		description
		strategy
		requirements
		notes
		product
		sku
		type
		budget {
		  minNumber
		  maxNumber
		  updatedAt
		  createdAt
		}
		startDate
		endDate
		treatment{
			url
		  	type
		}
		image {
		  url
		  type
		}
		updatedAt
		createdAt
	  }
	}`,
	'opportunityFindOneByUser': gql`
	query opportunityFindOneByUser ($filter:FilterFindOneopportunityInput){
	  opportunityFindOneByUser (filter: $filter) {
		 _id
		slug
		active
		company
		name
		category
		campaignTypes
		primaryGoal
		description
		strategy
		requirements
		notes
		product
		links
		sku
		type
		budget {
		  minNumber
		  maxNumber
		  updatedAt
		  createdAt
		}
		startDate
		endDate
		expirationDate
		image {
		  url
		  type
		}
		treatment {
		  url
		  type
		}
		deletedAt
		updatedAt
		createdAt
		companyProfile(skip: 0, sort: _ID_ASC) {
		  _id
		  name
		  logo{
			type
			type
		  }
		}
		productObj(skip: 0, sort: _ID_ASC) {
		  _id
		  name
		  website
		  description
		  company
		  picture{
			type
			url
		  }
		  interest
		  updatedAt
		  createdAt
		}
	  }
	}`,
	'socialCardFindOne': gql`
	query socialCardFindOne($user:String!){
	  socialCardFindOne(filter:{user:$user}) {
		_id
		user
		instagram {
		  comments
		  impressions
		  likes
		  saves
		  interactions
		  shares
		  media
		  profileViews
		  reach
		  totalPosts
		  engagementRate
		  audienceGrowthRate
		}
		twitter {
		  followersCount
		  favoritesCount
		  friendsCount
		  likedCount
		  listedCount
		  tweetCount
		}
		tiktok {
		  comments
		  impressions
		  likes
		  saves
		  interactions
		  shares
		  media
		  profileViews
		  reach
		  totalPosts
		  engagementRate
		  audienceGrowthRate
		}
		updatedAt
		createdAt
	  }
	}`,
	'talentFindMany': gql`
	query talentFindMany ($filter:FilterFindManytalentInput,$skip:Int,$limit:Int,$sort:SortFindManyUserShortInput, $tenants: [String]) {
	  talentFindMany(filter: $filter, skip: $skip, limit:$limit, sort:$sort, tenants :$tenants ) {
		_id
		firstName
		lastName
		profilePicture {
		  url
		  type
		}
		profile {
		  industry
		  industrySegment
		  gender
		}
		socialNetwork {
		  _id
		  url
		  identifier
		  connected
		  fail
		  id
		  refreshAt
		  expiredAt
		  user
		  reportId
		  reportExpiredAt
		  updatedAt
		  createdAt
		}
	  }
	}`,
	'cityFindMany': gql`query cityFindMany ($filter:FilterFindManycityInput,$skip:Int,$limit:Int,$sort:SortFindManycityInput){
	  cityFindMany(filter: $filter, skip: $skip, limit:$limit, sort:$sort) {
		_id
		label
		identifier
		createdAt
		updatedAt
	  }
	}`,
	'stateFindMany': gql`
	query stateFindMany($filter:FilterFindManystateInput,$skip:Int,$limit:Int,$sort:SortFindManystateInput){
	  stateFindMany(filter: $filter, skip: $skip, limit:$limit, sort:$sort) {
		_id
		label
		identifier
		createdAt
		updatedAt
	  }
	}`,
	'countryFindMany': gql`query countryFindMany($filter:FilterFindManycountryInput,$skip:Int,$limit:Int,$sort:SortFindManycountryInput){
	  countryFindMany(filter: $filter, skip: $skip, limit:$limit, sort:$sort)  {
		_id
		label
		identifier
		createdAt
		updatedAt
	  }
	}`,
	'listFindMany': gql`
		query listFindMany ($filter:FilterFindManylistInput,$skip:Int,$limit:Int,$sort:SortFindManylistInput, $userSkip:Int, $userLimit:Int, $userSort:SortFindManyUserShortInput) {
	  listFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort) {
		_id
		user
		talents
		featured
		name
		cover {
		  url
		  type
		}
		order
		updatedAt
		  createdAt
		users(skip: $userSkip, limit: $userLimit, \t\t\tsort:$userSort) {
		_id
		firstName
		lastName
		profilePicture {
		  url
		  type
		}
	  }

	  }
	}`,
	'talentScoreFindMany': gql`
	query talentScoreFindMany($platforms:[JSON],$audience:ScoreInput_Audience,$talent:[String]){
	  talentScoreFindMany(score: {platforms:$platforms,audience:$audience, talent:$talent}) {
#		platforms
#		audience {
#		  ages{
#			min_age
#			max_age
#		  }
#		  cities
#		  countries
#		  ethnicities
#		  gender
#		  locales
#		}
#		talent
		scores {
		  talent_id
		  instagram{
			desired_audience
			match
			authority
			audience
		  	overall_score
		  }
		}
	  }
	}`,
	'interestSearchMany': gql`
	query interestSearchMany($query:String){
	  interestSearchMany(query: $query) {
		_id
		label
		identifier
		hidden
		order

		picture {
		  url
		  type
		}
		updatedAt
		createdAt
	  }
	}`,
	'citySearchMany': gql`
	query citySearchMany($query:String!){
        citySearchMany(query: $query) {
			_id
			label
			identifier
		}
	}`,
	'insightByUserFindOne': gql`
	query insightByUserFindOne{
        insightByUserFindOne {
            stat_history {
                month
                followers
                following
                avg_likes
            }
            top_hashtags {
                tag
                weight
            }
            top_mentions {
                tag
                weight
            }
            engagements
            engagement_rate
            avg_likes
            avg_comments
            avg_views
            avg_reels_plays
		}
	}`,
	'instagramAudienceFindOneAdmin': gql`
        query instagramAudienceFindOneAdmin($filter: FilterFindOneinstagramAudienceInput) {
            instagramAudienceFindOneAdmin(filter: $filter) {
                _id
                user
                followersCount
                followsCount
                audienceCity {
                    name
                    weight
                    interest
                    affinity
                }
                audienceCountry {
                    name
                    weight
                    interest
                    affinity
                }
                audienceGenderAge {
                    name
                    weight
                    interest
                    affinity
                }
                audienceLocale {
                    name
                    weight
                    interest
                    affinity
                }
                audienceEthnicities {
                    name
                    weight
                    interest
                    affinity
                }
                audienceInterests {
                    name
                    weight
                    interest
                    affinity
                }
                audienceBrand {
                    name
                    weight
                    interest
                    affinity
                }
            }
        }
	`,
	'listFindOne': gql`
	query listFindOne($_id:String!,$userSkip:Int, $userLimit:Int, $userSort:SortFindManyUserShortInput){
	  listFindOne(filter: {_id:$_id}) {
		_id
		user
		talents
		featured
		name
		cover {
		  url
		  type
		}
		order
		updatedAt
		createdAt
		users(skip: $userSkip, limit: $userLimit, sort:$userSort) {
		  _id
		  firstName
		  lastName
		profilePicture{
			url
			type
		  }
		  socialNetwork{
			_id
			identifier
			fail
			connected
			refreshAt
			url
			expiredAt
			reportId
		  }
		}
	  }
	}`,
	'usersSearchMany': gql`
	query usersSearchMany($query:String,$tenant:String,$sort:SortFindManyuserInput,$type:String!,$gender:String,$interest:[String],$limit:Int,$skip:Int) {
	  usersSearchMany(
		query: $query
		tenant:$tenant
		sort: $sort
		type: $type
		gender: $gender
		interest: $interest
		limit: $limit
		skip: $skip
	  ) {
		_id
		firstName
		lastName
		email
		emailVerified

		acceptTerms
		profilePicture {
		  url
		  type
		}
		deleted
		tenants
		verified
		createdAt
		updatedAt

	  }
	}`,
	'culturalAlertFindMany': gql`
	query culturalAlertFindMany ($filter:FilterFindManyculturalAlertInput,$skip:Int,$limit:Int,$sort:SortFindManyculturalAlertInput){
	  culturalAlertFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort) {
          _id
          user
          title
          culturalType
          active
          videoSongTitle
          videoSongUrl
          videoDirector
          videoShootDate
          videoShootTime
          videoTreatment {
              url
              type
          }
          eventPaid
          eventCategory
          eventFrom
          eventTo
          eventPartnership
          endDate
          photos {
              url
              type
          }
          description
          feeRange {
              min
              max
              createdAt
              updatedAt
          }
          interest
          additionalInfo
          eventLocation
          city
          state
          website
          deck {
              url
              type
          }
          artist
          slug
          tenant
          tags
          subtactic
          tactic
          earnedMedia
          deliverables
          intro
          createdAt
          updatedAt
	  }
	}`,
	'culturalAlertByCompanyFindMany': gql`
	query culturalAlertByCompanyFindMany ($filter:FilterFindManybrandCulturalAlertInput,$skip:Int!,$limit:Int!){
	  culturalAlertByCompanyFindMany(filter:$filter, skip: $skip, limit:$limit) {
		${culturalAlertData}
		}
	}`,
	'culturalAlertByCompanyFindOne': gql`
	query culturalAlertByCompanyFindOne($filter:FilterFindOneculturalAlertInput){
	  culturalAlertByCompanyFindOne(filter: $filter) {
		${culturalAlertData}
	  }
	}
	`,
	'culturalAlertFindOne': gql`
	query culturalAlertFindOne($filter:FilterFindOneculturalAlertInput){
	  culturalAlertFindOne(filter: $filter, skip: 0, sort: _ID_ASC) {
		${culturalAlertData}
	  }
	}
	`,
	'opportunityAudienceFindOne': gql`
	query opportunityAudienceFindOne ($filter:FilterFindOneopportunityAudienceInput){
	  opportunityAudienceFindOne(filter: $filter) {
		_id
		genders
		place
		ageRange {
		  min
		  max
		}
		interests
		income
		opportunity
		country
		ethnicity
		language
		updatedAt
		createdAt
	  }
	}`,
	'shortLinkFindOne': gql`
	query shortLinkFindOne ($id:String, $password:String! ){
	shortLinkFindOne(id: $id, password: $password) {
		_id
        accepted
        opportunity {
            _id
            slug
            name
            interest
			description
            budget {
                minNumber
                maxNumber
            }
            image{
                url
            }
            treatment {
                url
            }
        }
        talent {
            _id
            firstName
            lastName
            profilePicture {
                url
            }
        }
        company {
            name
            userObj {
                profilePicture {
                    url
                }
            }
        }
	}
}`,
	'tenantsByUser':gql`
	query tenantsByUser {
	  tenantsByUser {
		_id
		label
		identifier
		createdAt
		updatedAt
	  }
	}
	`,
	'industrySegmentFindMany':gql`
	query industrySegmentFindMany($filter:FilterFindManyindustrySegmentInput, $skip: Int, $limit: Int, $sort: SortFindManyindustrySegmentInput ){
	  industrySegmentFindMany(filter: $filter, skip: $skip limit: $limit, sort: $sort) {
		_id
		label
		identifier
		updatedAt
		createdAt
	  }
	}`,
	'tacticsFindMany': gql`
		query tacticFindMany ($filter: FilterFindManytacticInput) {
			tacticFindMany(filter: $filter){
				_id
				slug
				active
				description
				title
				cover {
					url
					type
				}
				deletedAt
				createdAt
				updatedAt
			}
		}`,
	'subtacticFindMany': gql`
		query subtacticFindMany ($filter: FilterFindManysubtacticInput) {
			subtacticFindMany(filter: $filter){
				_id
				slug
				active
				description
				title
				deletedAt
				createdAt
				updatedAt
			}
		}`,
	'industryFindMany':gql`
        query industryFindMany($filter:FilterFindManyindustryInput, $skip: Int, $limit: Int, $sort: SortFindManyindustryInput ){
            industryFindMany(filter: $filter, skip: $skip limit: $limit, sort: $sort) {
                _id
                label
                identifier
                updatedAt
                createdAt
            }
        }`,
	'talentOpportunityFindMany':gql`query talentOpportunityFindMany($filter:FilterFindManytalentOpportunityInput,$skip:Int!,$limit:Int!,$sort:SortFindManytalentOpportunityInput){talentOpportunityFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort) {
		_id
		accepted
		opportunity(skip: 0, sort: _ID_ASC) {
		  _id
		  slug
		  active
		  company
		  name
		  category
		  campaignTypes
		  primaryGoal
		  description
		  strategy
		  requirements
		  notes
		  product
		  links
		  sku
		  type
		  budget{
			minNumber
			maxNumber
		  }
		  startDate
		  endDate
		  expirationDate
		  image{
			type
			url
		  }
		  treatment{
			type
			url
		  }
		  deletedAt
		  createdAt
		  updatedAt
		  interest
		}
		talent(skip: 0, sort: _ID_ASC) {
		  _id
		  firstName
		  lastName
		  email
		  emailVerified
		  type
		  acceptTerms
		  profilePicture{
		   type
			url
		  }
		  deleted
		  tenants
		  verified
		  createdAt
		  updatedAt
		  socialNetwork(skip: 0, sort: _ID_ASC){
			url
		  }
		  tenantsObj(skip: 0, limit: 100, sort: _ID_ASC){
			_id
			createdAt
			identifier
			label
		  }
		}
		company(skip: 0, sort: _ID_ASC) {
		  _id
		  user
		  name
		  website
		  phone
		  interest
		  position
		  linkedinUrl
		  logo{
			 type
			url
		  }
		  createdAt
		  updatedAt
		  userObj(skip: 0, sort: _ID_ASC){
			 _id
			acceptTerms
			createdAt
			deleted
		  }
		}
		createdAt
		updatedAt
	  }
	}`,
	'instagramAudienceFindOne':gql`
		query instagramAudienceFindOne(
  			$filter: FilterFindOneinstagramAudienceInput,
  			$skip :Int,
  			$sort: SortFindOneinstagramAudienceInput
		)
		{
  		instagramAudienceFindOne(filter: $filter, skip: $skip, sort: $sort) {
			_id
			user
			followersCount
			followsCount
			audienceCity {
			  name
			  weight
			  interest
			  affinity
                coords {
                    lat
                    lon
                }
                state {
                    name
                }
                country {
                    name
                }
			}
			audienceCountry {
			  name
			  weight
			  interest
			  affinity
			}
			audienceState {
			  name
			  weight
			  interest
			  affinity
			}
			audienceGenderAge {
			  name
			  weight
			  interest
			  affinity
			}
			audienceLocale {
			  name
			  weight
			  interest
			  affinity
			}
			audienceEthnicities {
			  name
			  weight
			  interest
			  affinity
			}
			audienceInterests {
			  name
			  weight
			  interest
			  affinity
			}
			audienceBrand {
			  name
			  weight
			  interest
			  affinity
			}
			updatedAt
			createdAt
	  }
	}
	`,
	'likeFindMany':gql`
	query likeFindMany($filter:FilterFindManylikeInput,$skip:Int,$limit:Int, $sort: SortFindManylikeInput){
	  likeFindMany(filter: $filter, skip: $skip, limit:$limit, sort: $sort) {
		_id
		user
		talent
		culturalAlert
		deletedAt
		updatedAt
		createdAt
	  }
	}
	`,
	'socialCardFindMany': gql`
	query socialCardFindOne($user:String){
		socialCardFindOne(filter: { user:$user}) {
			updatedAt
			instagram {
				followers
			}
			twitter {
				followers
			}
			tiktok {
				followers
			}
			youtube {
				followers
			}
		}
	}`,
	'postFindMany':gql`
		query postFindMany(
  			$filter: FilterFindManypostInput,
  			$skip :Int,
            $limit :Int,
  			$sort: SortFindManypostInput
		)
		{
            postFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort) {
                _id
                user
                platform
                postId
                caption
                likesCount
                mediaUrl
                permalink
                commentsCount
				file{
					url
					type
				}
            }
		}`,
	'talentMediaFindMany':gql`
        query talentMediaFindMany(
            $filter: FilterFindManytalentMediaInput,
            $skip :Int,
            $limit :Int,
            $sort: SortFindManytalentMediaInput
        )
        {
            talentMediaFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort) {
                _id
                user
                title
                link
                updatedAt
                createdAt
                files {
                    url
                    type
                }
            }
        }`
};


export const query = (value: string): any => {
	return queries[value];
};
