import {signInWithGooglePopup } from 'actions/firebase';
import {Button} from '../Button';
import React, {useContext} from 'react';
import {ReactComponent as GoogleIcon} from 'styles/icons/companies/google.svg';
import {UserContext} from 'context/userContext';
import {SignupWithProviderData} from 'actions/restActions';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import {TUserType, UserType} from '../../../interfaces';

type FirebaseButtonProps = {
	signup?:boolean
	type: TUserType
	setLoading?:(loading:boolean)=>void
}

const SignupNextPathByUSerType : Record<TUserType, string> = {
	Agent: '/dashboard',
	Regular: '/dashboard',
	Company: '/dashboard'
};

export const FirebaseButton = ({type,signup,setLoading}:FirebaseButtonProps):JSX.Element => {
	const navigate :NavigateFunction = useNavigate();
	const {userSignupGoogle}:any=useContext(UserContext);

	const logGoogleUser = async():Promise<void> => {
		const response:any = await signInWithGooglePopup();
		setLoading && setLoading(true);
		const signupData:SignupWithProviderData = {
			token: response._tokenResponse.oauthIdToken,
			type: UserType[type]
		};

		await userSignupGoogle(signupData);
		setLoading && setLoading(false);
		signup && navigate(SignupNextPathByUSerType[type]);
	};
	return (
		<Button
			className={'flex justify-center align-center col-gap'}
			width={'100%'}
			onClick={logGoogleUser}
			variant={'dark-grey'}
		>
			<GoogleIcon/> Sign up with Google
		</Button>
	);
};