import React from 'react';
import styled from 'styled-components';
import {CulturalItem, ItemsCollection} from 'components';
import {LinkButton} from 'components/ui';
import {CulturalAlert} from 'interfaces';
import {NavigateFunction, useNavigate} from 'react-router-dom';

interface AlertsProps {
	alerts:CulturalAlert[]
}

const Title:any =styled.p`
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
`;

const Text1:any =styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 136%;
`;


const Text2:any =styled.p`
  margin-top: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
`;


export default function AlertsRender({alerts}:AlertsProps):JSX.Element {
	const navigate:NavigateFunction= useNavigate();
	return <>
		{alerts?.length ?

			<ItemsCollection >
				{alerts.map((alert:CulturalAlert):JSX.Element=>
					<CulturalItem
						key={alert._id}
						culturalAlert= {alert}
						button={{
							label:'Edit',
							action:():void=>navigate(`/alerts/edit/event/${alert._id}`)
						}}
					/>
				)}
			</ItemsCollection>
			:

			<div className={'w-100 grows flex justify-center direction-column align-center'}>
				<Title className={''}>Let’s Get Started!</Title>
				<div className={'mt-4 mb flex direction-column align-center'}>
					<Text1>Create an Opportunity Alert</Text1>
					<Text2>Select Alert Type</Text2>
				</div>
				<LinkButton
					className="mb"
					to={'/alerts/create/musicvideo'}
					label={'Music Video'}
				/>
				<LinkButton
					to={'/alerts/create/event'}
					label={'Events'}
					variant={'secondary'}
				/>
			</div>
		}
	</>;
}