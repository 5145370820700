import React, {useEffect, useState} from 'react';
import {TacticI} from '../../interfaces';
import {Link} from 'react-router-dom';
import {QueryTuple, useLazyQuery} from '@apollo/client';
import {query} from '../../actions/queries';

export const TacticsGrid = (): JSX.Element => {

	const [tactics, setTactics] = useState<TacticI[]>([]);
	const [loading, setLoading] = useState<boolean>(true);

	// eslint-disable-next-line @typescript-eslint/typedef
	const [queryTactics, { data }]:QueryTuple<any, any> = useLazyQuery(query('tacticsFindMany'));
	useEffect(():void=>{
		if(loading){
			queryTactics({
				variables: {
					filter: {
						active: true
					}
				},
				fetchPolicy: 'no-cache',
				onCompleted: (data:any):void => {
					console.log('tactics', data);
					setLoading(false);
					setTactics(data?.tacticFindMany || []);
				}
			});
		}
	},[loading]);


	return (
		<div className="grid6 col-gap">
			{tactics && tactics.length > 0 && tactics.map(({title, _id, slug, cover}:TacticI, index:number):JSX.Element => {
				return (
					<div key={_id} className={`tactic mb overflow-hidden h-100px br-half relative flex justify-start align-end ${index === 3 || index === 4 || index === 8 || index === 9 ? 'col-span3' : 'col-span2'}`}>
						<Link to={`/opportunities/${slug}/${_id}`}>
							<img src={cover[cover.length - 1]?.url} className="absolute edges w-100 h-100 obj-cover" />
							<div className="overlay absolute edges w-100 h-100" />
							<h4 className="relative m f-urbanist f-bold f-1rem t-white">
								{title}
							</h4>
						</Link>
					</div>
				);
			})}
		</div>
	);
};