import React from 'react';
import {Opportunity, ShortLink, Talent, TalentOpportunity} from 'interfaces';
import {BrandTalentAvatars} from 'components';
import {Divider, Spinner} from 'components/ui';
import styled from 'styled-components';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {CopyIcon} from '../../../icons/CopyIcon';
import {formatDate} from '../../../tools/date';

type SharedLinkInfoProps = {
    talent:Talent
    talentOpportunity:TalentOpportunity
    shortLink:ShortLink
}


const InfoContainer:any = styled.div`
	min-width: 480px;
	>p{
		>span{
			font-weight: 700;
		}
	}
`;

const LinkCopied:any = styled.p`
	position: absolute;
	color: rgba(255, 255, 255, 0.50);
	text-align: right;
	font-feature-settings: 'clig' off, 'liga' off;
	font-family: Inter;
	font-size: 10px;
	font-style: normal;
	font-weight: 700;
	line-height: 21px; /* 210% */
	letter-spacing: 0.5px;
`;

export const SharedLinkInfo = ({talent, talentOpportunity, shortLink}:SharedLinkInfoProps): JSX.Element => {
	const [linkCopied, setLinkCopied] = React.useState(false);
	console.log({talentOpportunity, shortLink});
	const opportunity:Opportunity = talentOpportunity?.opportunity;
	console.log({linkCopied});
	return <div>
		<div className={'w-100 flex direction-column align-center justify-center mver-2 relative'}>
			{ !talentOpportunity.opportunity?
				<Spinner/>
				:
				<>
					<BrandTalentAvatars
						talent={talent}
						brandPicture={ opportunity.image&& opportunity.image[0]?.url }
						sizeValue={60}
						sizeType={'px'}
						length={2}
						percentage={75}
					/>
					<h4> {opportunity?.name} </h4>
				</>
			}
		</div>
		<Divider/>
		<InfoContainer >
			<div className={'w-100 flex justify-between'}>
				<p>Link: <span className={'t-primary'}> https://app.opusintel.co/share/{shortLink.slug}</span></p>
				<CopyToClipboard
					text ={ `The share link for ${opportunity.name} is ready. Go to https://app.opusintel.co/share/${shortLink.slug} and use this password ${shortLink.password}.` }
				>
					<div
						className={'flex align-center col-gap-half cursor-pointer' }
						onClick={():void=>setLinkCopied(true)}
					>
						<p >
							Copy Message
							{linkCopied && <LinkCopied>Link Copied!</LinkCopied>}
						</p>
						<CopyIcon size={16}/>
					</div>
				</CopyToClipboard>
			</div>
			<p className={'mt-half'}>Password: <span>{shortLink.password}</span>  </p>
			<p className={'mt-half'}>Link Expiration: <span>{formatDate(shortLink.expiredAt)}</span></p>
		</InfoContainer>

	</div>;
};