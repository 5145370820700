import {AuthLayout, AuthRoute} from '../containers';
import {Brand, Hello, LinkSocial} from '../containers/onBoaarding/components';
import React from 'react';
import {Route} from './interfaces';
import {SocialMedia, Interests} from '../pages/onBoarding/Brand';
import OnboardingContextProvider from '../context/onboardingContext';
import {Verify} from '../pages';

export const onboardingRoutes:Route[] =[
	{
		path:'/onboarding',
		element:<AuthRoute redirectTo={'/'}/>,
		children:[
			{
				path: 'link-social',
				element: <LinkSocial/>
			},
			{
				path: 'hello-user',
				element: <Hello/>
			},
			// {
			// 	path: 'brand/create-product',
			// 	element: <BrandCreateProduct/>
			// },
			// {
			// 	path: 'brand/:brandId/skus',
			// 	element: <BrandSkus />
			// },
			// {
			// 	path: 'brand/:brandId/create-sku',
			// 	element: <BrandCreateSku />
			// },
			// {
			// 	path: 'brand/create-sku',
			// 	element: <BrandCreateSku />
			// },

			{
				path: 'brand',
				element:<OnboardingContextProvider steps={3}/> ,
				children:[
					{
						path: 'links',
						element: <SocialMedia />
					},
					{
						path: 'interests',
						element:<Interests/>
					},
					{
						path: 'verify',
						element: <AuthLayout
							videoUrl={'/assets/login_video.mp4'}
							direction={'row-reverse'}
						>
							<Verify/>
						</AuthLayout>
					}
				]
			},
			// {
			// 	path: 'brand/hello',
			// 	element:<BrandHello />
			// },
			// {
			// 	path: ':productId/sku/:skuId',
			// 	element: <CreateSku/>
			// },

			//Agent Routes
			{
				path: 'company/about',
				element: <Brand/>
			}
		]
	}
];