import React from 'react';
import {RoundPercentage} from 'components/ui';
import styled from 'styled-components';
import {BodyText} from 'stylesOpus/typography';

type CategoryScoreProps = {
    percentage: number
	label: string
};

const CategoryContainer:any =styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 1.32rem;
`;

const CategoryScoreLabel:any = styled(BodyText)`
	text-align: center;
`;
export const CategoryScore = ({percentage,label}:CategoryScoreProps):JSX.Element => {

	return (
		<CategoryContainer >
			<RoundPercentage
				size={'5.07694rem'}
				strokeColor={'#1916CC'}
				strokeWidth={'2'}
				percentage={percentage}
				insideStyle={{fontSize:'0.5rem'}}
			/>
			<CategoryScoreLabel fontSize={'12px'} className={'center'}>
				<span className="f-700 t-white">
					{label}
				</span>
			</CategoryScoreLabel>
		</CategoryContainer>
	);
};
