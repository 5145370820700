import React, {useEffect} from 'react';
import {BodyText, BoxTitle, PageTitle} from '../../../stylesOpus/typography';
import {ContentBox, Flex, FlexGrow} from '../../../stylesOpus/layout';
import {Tag, Tabs, Tab, Line, Avatar} from  '../../../stylesOpus/ui';
import {LinkIcon, ArrowRightIcon} from '../../../icons';
import OpportunityBox from '../components/OpportunityBox';
import TalentBrands from '../components/TalentBrands';
import AuthorityCircle from '../components/AuthorityCircle';
import {Button} from '../../../components/ui';
import {ImageContainer} from '../../../styles/styledComponents/containers';

interface OpportunityRenderProps {
	opportunityTab:boolean
	setOpportunityTab:(tab:boolean)=>void
	share?: {
		opportunity?: {
			name?: string
			image?:{
				url?:string
			}
			treatment?:{
				url?:string
			}
			budget? : {
				minNumber: number
				maxNumber: number
			}
			description?: string
			interest?: string[]
		}
		talent? : {
			_id?:string
			firstName?:string
			lastName?:string
			profilePicture?:{
				url?:string
			}
		}
		company? : {
			name? : string
			userObj? : {
				profilePicture? : {
					url : string
				}
			}
		}


	}
}

export default function OpportunityRender({opportunityTab,setOpportunityTab, share : {opportunity, talent, company}}:OpportunityRenderProps):JSX.Element {

	useEffect(():void=>{
		console.log('OPP', opportunity);
	},[opportunity]);

	return (
		<ContentBox borderRadius={'0'} background={'grey_1000'}>
			<div className="max-w-500px mhor-auto">
				<ImageContainer
					$height={'500px'}
					$width={'auto'}
					$src={opportunity?.image?.url}
				/>
				PaddingContainer
				<PageTitle className="mt-3 mb-2">
					{opportunity?.name}
				</PageTitle>
				<Tabs>
					<Tab selected={opportunityTab} onClick={():void=>setOpportunityTab(!opportunityTab)}>
						Opportunity
					</Tab>
					<Tab selected={!opportunityTab} onClick={():void=>setOpportunityTab(!opportunityTab)}>
						Why Me?
					</Tab>
				</Tabs>

				<ContentBox className="mver">
					<Flex>
						<Flex>
							<Avatar>
								<img src={talent?.profilePicture?.url} />
							</Avatar>
							<Avatar margin='0 0 0 -10px'>
								{company?.userObj?.profilePicture?.url ?
									<img src={company?.userObj?.profilePicture?.url } />
									:
									<>{company?.name?.charAt(0)}</>
								}
							</Avatar>
						</Flex>
						<div className="grows" />
						<Flex>
							<BoxTitle>
								Overall Match
							</BoxTitle>
							<AuthorityCircle talent={talent?._id} field={'overall_score'} topic={opportunity?.interest[0]} />
						</Flex>
					</Flex>
				</ContentBox>

				<ContentBox className="mver" visible={opportunityTab}>
					<Flex>
						<BoxTitle>
							Compensation
						</BoxTitle>
						<FlexGrow />
						<div>
							<Tag fontSize='16px' height='40px' borderRadius='8px' margin='0'>
								${opportunity?.budget?.minNumber}
								<Line width={'26px'} margin={'0 8px'} />
								${opportunity?.budget?.maxNumber}
							</Tag>
						</div>
					</Flex>
				</ContentBox>

				<OpportunityBox
					title='About the opportunity'
					description={opportunity?.description}
					visible={opportunityTab}
				/>

				{/*<OpportunityBox
					title='About the Product'
					description=''
					visible={opportunityTab}
				/>*/}

				{/*<OpportunityBox
					title='About the company'
					description=''
					visible={opportunityTab}
				/>*/}

				<OpportunityBox
					title='Target Audience'
					description=''
					visible={!opportunityTab}
				>
					<div>
						{opportunity?.interest && opportunity?.interest?.length > 1 && opportunity?.interest?.map((title:string):any=>
							<div key={title} className="pver">
								<Flex>
									<Avatar>
										<img src={talent?.profilePicture?.url} />
									</Avatar>
									<BoxTitle className="ml">
										{title}
									</BoxTitle>
									<div className="grows" />
									<AuthorityCircle talent={talent?._id} field={'authority'} topic={opportunity?.interest[0]} />
								</Flex>
							</div>
						)}
					</div>
				</OpportunityBox>

				<OpportunityBox
					title='Audience Brand Interests'
					description=''
					visible={!opportunityTab}
				>
					<TalentBrands user={talent?._id} />
				</OpportunityBox>

				{opportunity?.treatment?.url &&
					<>
						{opportunityTab &&
							<BoxTitle className='mver'>
								Attached Media
							</BoxTitle>
						}
						<ContentBox className="mver" visible={opportunityTab}>
							<a href={opportunity?.treatment?.url} target="_blank" rel="noreferrer">
								<Flex>
									<LinkIcon />
									<div className="ml-half" >
										<BoxTitle className='mb-quarter'>
											Download Creative Deck
										</BoxTitle>
										{/*<BodyText>*/}
										{/*	24.7mb*/}
										{/*</BodyText>*/}
									</div>
									<FlexGrow />
									<ArrowRightIcon />
								</Flex>
							</a>
						</ContentBox>
					</>
				}

				<p className="pver center br-2">
					<Button rounded={true} variant="blue" className="br-2">
						I&apos;m Interested
					</Button>
				</p>

			</div>
		</ContentBox>
	);
}