import React from 'react';

export const LinkIcon=():JSX.Element=>
	<svg
		width={24}
		height={24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M10.752 13.2935C11.4906 14.281 12.6216 14.8989 13.8516 14.987C15.0816 15.0751 16.2892 14.6246 17.161 13.7525L19.711 11.2025C21.3216 9.53492 21.2986 6.88417 19.6592 5.2448C18.0198 3.60543 15.3691 3.58239 13.7015 5.19303L12.2395 6.64653"
			stroke="white"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M14.152 11.5935C13.4134 10.6061 12.2823 9.98812 11.0524 9.90003C9.8224 9.81194 8.61482 10.2624 7.74303 11.1345L5.19303 13.6845C3.58239 15.3521 3.60543 18.0029 5.2448 19.6422C6.88417 21.2816 9.53492 21.3046 11.2025 19.694L12.656 18.2405"
			stroke="white"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
;
