import React from 'react';
import {LinkTab} from '../profile/styles';
import {Content, TabsWrapper, Title} from './styles';
import {Layout} from '../Layout';

export interface ProfileEditRenderProps{
	path:string
	children:JSX.Element;
}

export default function ProfileEditRender({path,children}:ProfileEditRenderProps):JSX.Element{

	return <Content>
		<Title>Edit Profile</Title>
		<TabsWrapper >
			<LinkTab
				$active={path==='/profile-edit/profile'}
				to={'/profile-edit/profile'}>profile</LinkTab>
			<LinkTab
				$active={path==='/profile-edit/contact'}
				to={'/profile-edit/contact'}>contact</LinkTab>
			<LinkTab
				$active={path==='/profile-edit/interests'}
				to={'/profile-edit/interests'}>interests</LinkTab>
			<LinkTab
				$active={path==='/profile-edit/family'}
				to={'/profile-edit/family'}>family</LinkTab>
			<LinkTab
				$active={path==='/profile-edit/spirits'}
				to={'/profile-edit/spirits'}>spirits</LinkTab>
			<LinkTab
				$active={path==='/profile-edit/brand'}
				to={'/profile-edit/brand'}>brand</LinkTab>
			<LinkTab
				$active={path==='/profile-edit/ethnicity'}
				to={'/profile-edit/ethnicity'}>ethnicity </LinkTab>
		</TabsWrapper>

		{children}
	</Content>
	;
}
