import React from 'react';

export const KebabIcon = ():JSX.Element => <svg
	width={26}
	height={27}
	viewBox="0 0 26 27"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		fillRule="evenodd"
		clipRule="evenodd"
		d="M12.9873 7.98101C14.1828 7.98101 15.1519 7.01191 15.1519 5.81646C15.1519 4.62101 14.1828 3.6519 12.9873 3.6519C11.7919 3.6519 10.8228 4.62101 10.8228 5.81646C10.8228 7.01191 11.7919 7.98101 12.9873 7.98101Z"
		fill="white"
	/>
	<path
		fillRule="evenodd"
		clipRule="evenodd"
		d="M12.9873 15.557C14.1828 15.557 15.1519 14.5879 15.1519 13.3924C15.1519 12.197 14.1828 11.2278 12.9873 11.2278C11.7919 11.2278 10.8228 12.197 10.8228 13.3924C10.8228 14.5879 11.7919 15.557 12.9873 15.557Z"
		fill="white"
	/>
	<path
		fillRule="evenodd"
		clipRule="evenodd"
		d="M12.9873 23.1329C14.1828 23.1329 15.1519 22.1638 15.1519 20.9684C15.1519 19.7729 14.1828 18.8038 12.9873 18.8038C11.7919 18.8038 10.8228 19.7729 10.8228 20.9684C10.8228 22.1638 11.7919 23.1329 12.9873 23.1329Z"
		fill="white"
	/>
</svg>;