import React, {useEffect} from 'react';
import {InputWrapper} from 'styles/ui';
import {LikeButton} from './LikeButton';
import styled from 'styled-components';

interface LikeCheckProps {
	value?: boolean
    name?: string
    label: string
	defaultChecked?:boolean
	theme?:'opus'|'opusV2'
    icon?: React.ReactNode
    onChange?:(value:boolean,name:string)=>void

}

const Option:any = styled.div`
	display: flex;
	justify-items: center;
	>p{
		margin: 0;
		padding: 4px 0;
		font-weight: 500;
		font-size: 14px;
		color: white;
		font-family: 'Urbanist', sans-serif;
	}
`;
export const LikeCheck = ({ value,name,label, icon, onChange,defaultChecked=false }:LikeCheckProps): JSX.Element => {

	const [isChecked, setIsChecked] = React.useState<boolean>(value || defaultChecked);

	useEffect(():void => {
		onChange && onChange(isChecked,name);
	}, [isChecked]);

	return <InputWrapper
		className={'cursor-pointer'}
		localTheme={'opusV2'}
		onClick={():void=>setIsChecked((prevState:boolean):boolean => !prevState)}
	>
		<Option>
			{icon ||null} <p>{label}</p>
		</Option>
		<LikeButton isChecked={isChecked}/>
	</InputWrapper>;
};