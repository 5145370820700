import styled from 'styled-components';
import {color} from 'styles/colors';
import {themeOpus} from '../../stylesOpus/theme';

export const PillContainer:any=styled.div`
  width: fit-content;
  max-width: 100%;
  padding: 2px;
  
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background-color: ${themeOpus.colors.grey_840};
  
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }
  scrollbar-width: none;
`;

export const Pill:any=styled.p`
  padding: 0.4rem 1rem;
  max-width: 90px;
  height: 2rem;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  border-radius: 15px;
  background-color: ${({active}:any):string=>active? themeOpus.colors.grey_900:'transparent'};
`;