import React from 'react';
import {Content} from './styles';
import {AuthLayout} from '../AuthLayout';
import {OnboardingStepper} from './components';

export interface OnBoardingRenderProps {

	videoUrl?:string
	direction?: 'row' | 'row-reverse'
	auth:boolean
	children:JSX.Element
}

export default function OnBoardingRender({children,...props}:OnBoardingRenderProps):JSX.Element{

	return (
		<AuthLayout {...props}>
			<div className={'w-100 grows flex direction-column justify-center align-center'}>
				<Content className={'grows max-w-500px flex direction-column align-center justify-center w-100'}>
					<OnboardingStepper/>
					{children}
				</Content>
			</div>
		</AuthLayout>
	);
}
