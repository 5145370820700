import {useMutation} from '@apollo/client';
import {mutation} from '../actions/mutations';

export type UseAddToFavoritesProps = {
    talent?: string;
	culturalAlert?: string;

	onError?: (error: string) => void;
	onSuccess?: (data: TLike) => void;
}

export type TLike = {
	_id :string
	user :string
	talent: string
	culturalAlert :string
	deletedAt: string
	updatedAt: string
	createdAt: string
	modifiedCount :number
};

export type  AddToFavorites= [()=>void, {data?:TLike,loading?:boolean,error?:string}];


export const useAddToFavorites = ({talent,culturalAlert,onError,onSuccess}: UseAddToFavoritesProps): AddToFavorites => {

	// eslint-disable-next-line @typescript-eslint/typedef
	const [addFavorite,{data,loading,error}]:any=useMutation(mutation('LikeCreateOne'),{
		refetchQueries: ['likeFindMany'],
		onError: (error: any): void => onError &&onError(error),
		onCompleted:(data:any):void=>onSuccess && onSuccess(data.LikeCreateOne)
	});
	const addToFavorites = (): void => {
		console.log('Add to favorites');
		addFavorite({
			variables: {talent,culturalAlert}
		});
	};

	return [addToFavorites, {data:data?.LikeCreateOne,loading, error:error?.message}];
};