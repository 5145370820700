import React from 'react';
import {ModalHook} from 'hooks/useModal';
import {ShortLink, Talent, TalentOpportunity} from 'interfaces';
import {Card, GenericHeader} from 'components';
import {CloseIcon} from 'icons';
import {ShareForm} from './ShareForm';
import {SharedLinkInfo} from './SharedLinkInfo';
import {Modal} from 'components/Modal';

export interface ShareModalProps {
    talent:Talent

    talentOpportunity?:TalentOpportunity
    shortLink?: ShortLink
    popup:ModalHook
    handleOpenShare:(t:Talent)=>void
    handleShare: (shortLink:ShortLink, talentOpportunity:TalentOpportunity)=> void
}
export const ShareModal = ({popup, talent, talentOpportunity, shortLink, handleShare}:ShareModalProps):JSX.Element => {

	return(
		<Modal onClickOut={ popup.close }>
			<Card >
				{!shortLink?
					<>
						<GenericHeader title={'Share Opportunity'} action={ popup.close } Element={CloseIcon} />
						<ShareForm talentId={talent._id} share={ handleShare }/>
					</>
					:
					<>
						<GenericHeader title={'Share Link Created'} action={ popup.close } Element={CloseIcon} />
						<SharedLinkInfo
							talent={talent}
							talentOpportunity={talentOpportunity}
							shortLink={shortLink}
						/>
					</>
				}
			</Card>
		</Modal>
	);
};