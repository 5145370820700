import React, {useState} from 'react';
import {CaratDown} from '../../../../icons';
import {InputBox, InputLabel, InputOptionsList, InputOptionsListItem} from '../styledComponents';
import {IOption, ISelector} from '../../DiscoverInterfaces';

export default function Selector({title,value,options,setFieldValue, placeholder}:ISelector):JSX.Element {

	const [showList, setShowList] = useState(false);

	return (
		<InputBox>
			<InputLabel className="flex align-center" onClick={():void=>setShowList(!showList)}>
				<div className="grows block" >
					<label>
						{title}
					</label>
					<span>
						{placeholder && placeholder} {value}
					</span>
				</div>
				<CaratDown/>
			</InputLabel>
			{showList &&
				<InputOptionsList onClick={():void=>setShowList(false)}>
					{options.map((option:IOption):JSX.Element=>{
						return (
							<InputOptionsListItem key={option._id} onClick={():void=>setFieldValue(option.value || option.label)}>
								{option.value || option.label}
							</InputOptionsListItem>
						);
					})}
				</InputOptionsList>
			}
		</InputBox>
	);
}