import React from 'react';
import {FamilyFormRender} from './FamilyFormRender';
import {FormField, FormHook} from 'hooks/useForm/interfaces';
import {useForm} from 'hooks/useForm/useForm';
import {QueryResult, useQuery} from '@apollo/client';
import {query} from 'actions/queries';
import {Item, UpdateTalentData} from 'interfaces';
import {EditProfileContext} from '../../index';
import {useOutletContext} from 'react-router-dom';

const booleanByString:any={'yes':true,'no':false, 'default':null};
const getBoolean=(label:string):boolean|null=>{
	return booleanByString[label||'default'];
};

export const FamilyForm = ():JSX.Element=>{
	const {profileData,updateProfile}:EditProfileContext=useOutletContext();
	const {data:relationShipData}:QueryResult=useQuery(query('relationshipStatusFindMany'));

	const  formFields:FormField[]=[
		{name:'relationshipStatus', required:false, default:profileData?.relationshipStatus||''},
		{name:'kids', required:false, default:profileData?.hasChildren? 'yes':'no'},
		{name:'pets', required:false, default:profileData?.hasChildren? 'yes':'no'}
	];


	const handleSubmit=async(formData:any):Promise<void> =>{
		let updateData:UpdateTalentData={relationshipStatus:formData.relationshipStatus};
		if(formData.kids){updateData.hasChildren=getBoolean(formData.kids);}
		if(formData.pets){updateData.hasPets=getBoolean(formData.pets);}

		await updateProfile({variables:{
			profileData : updateData
		}});
	};

	const  {errors,loading,...rest}:FormHook=useForm(formFields,handleSubmit);
	let relationShipStatus:Item[]=relationShipData?.relationshipStatusFindMany.map((i:any):Item=>({...i,id:i._id}))||[];

	return <FamilyFormRender
		relationshipOptions={relationShipStatus}
		submitLoading={loading}
		errors={errors}
		registerInput={rest.registerInput}
		registerSelect={rest.registerSelect}
		registerList={rest.registerList}
		onSubmit={rest.handleSubmitForm}
	/>;
};