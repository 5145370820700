import {useState} from 'react';

export type ModalHook = {
    isOpen:boolean
    open:()=>void
    close:()=>void
};
export const useModal=(defaultValue:boolean): ModalHook => {
	// create ref
	const [isOpen,setIsOpen]=useState<boolean>(defaultValue);
	return {
		isOpen,
		open:():void=>setIsOpen(true),
		close:():void=>setIsOpen(false)
	};
};

