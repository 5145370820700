import React from 'react';
import {
	AppendableSelect,
	DateInput,
	FieldSection,
	FileInput,
	FormButton,
	Input,
	TextBox
} from 'components/ui';
import styled from 'styled-components';
import {FormProps, Item, TPicture} from 'interfaces';
import {Message, PictureArrayInput} from 'components';
import {SelectByIcon} from 'components/ui/SelectByIcon';
import {TvIcon, ShareIcon2, ShowIcon, BrandsHexagonIcon,MicrophoneIcon} from 'icons';
import {color} from 'styles/colors';

interface CreateCampaign extends FormProps{
	options:{
		interests:Item[]
	}
	edit:boolean
	defaultPictures?:TPicture[]
	onChangeTreatment:(file:File)=>void
	onChangeFiles:(file:File[])=>void
}


const FormContainer:any = styled.form`
	display: flex;
	flex-direction: column;
	row-gap: 1.2rem;
	margin:  2rem 0;
	
`;

export default function CampaignCreateRender({formData,options,edit,defaultPictures, submitLoading,onSubmit,registerInput,registerList,registerTextArea,onChangeFiles,onChangeTreatment,errors}:CreateCampaign):JSX.Element {
	console.log({formData});
	return (
		<FormContainer onSubmit={onSubmit} autoComplete='off'>
				
			<FieldSection
				label={'Opportunity Name'}
				helperText={'Type in the name of the opportunity as it appears to talent.'}
				error= {errors.get('name')?.required &&  'the name is required'}
			>
				<Input placeholder="Enter Name Here" {...registerInput('name')}/>
			</FieldSection>

			<TextBox
				maxLength={2000}
				placeholder="Type Description Here"
				label={'Opportunity Description'}
				error={errors.get('description')?.required &&  'the description is required'}
				helperText={'Add a description of your opportunity including the product/service, notable reason for the opportunity, and what is expected in the partnership.'}
				{...registerTextArea('description')}

			/>

			<AppendableSelect
				label={'Interest(s)'}
				error ={errors.get('interests')?.required && 'select at least on interest'}
				options={options.interests}
				helperText={'Interest Required Select the interest that best match your opportunity'}
				placeholder={'Select Interest'}
				{...registerList('interests')}
			/>

			<FieldSection
				label={'Upload Media'}
				helperText={'Upload the visual and creative files for your opportunity.'}
			>
				<PictureArrayInput
					min={4}
					max={6}
					defaultImages={defaultPictures}
					onChange={onChangeFiles}
				/>

			</FieldSection>

				
			<TextBox
				className={'mt'}
				label={'Deliverables'}
				placeholder="Type Description Here"
				helperText={'List the expected deliverable for each opportunity type.'}
				maxLength={2000}
				{...registerTextArea('requirements')}
			/>

			<div className="grid2 col-gap">
				<FieldSection
					label={'Start Date'}
					error={errors.get('startDate')?.required && 'the start date is required'}
				>
					<DateInput
						picker={{
							minDate: new Date()
						}}
						{...registerInput('startDate')}
					/>
				</FieldSection>
				<FieldSection
					label={'End Date'}
					error={ errors.get('endDate')?.required && 'the end date is required'}
				>
					<DateInput
						picker={{
							minDate: new Date()
						}}
						{...registerInput('endDate')}
					/>
				</FieldSection>
			</div>

			{/*<FieldSection*/}
			{/*	label='Upload links to product'*/}
			{/*	helperText='Lorem ipsum dolor sit amet consectetur. In auctor enim lobortis diam varius. sit amet consectetur.'*/}
			{/*>*/}
			{/*	<AppendableTexts*/}
			{/*		{...registerList('links')}*/}
			{/*	/>*/}
			{/*</FieldSection>*/}



			<FieldSection
				className={'mb'}
				label={'Opportunity Types'}
				helperText={'Select all that apply.'}
			>
				<SelectByIcon
					options={[
						{id:'social',label:'Social',icon:ShareIcon2},
						{id:'video',label:'Video',icon:TvIcon},
						{id:'print',label:'Print',icon:TvIcon},
						{id:'event',label:'Event',icon:BrandsHexagonIcon},
						{id:'appearance',label:'Appearance',icon:ShowIcon},
						{id:'podcast',label:'Podcast',icon:MicrophoneIcon}
					]}

					{...registerList('type')}
				/>

			</FieldSection>

			<FieldSection
				className={''}
				label={'Opportunity Brief'}
			>
				<FileInput
					label={'Upload PDF or JPEG'}
					onChange={onChangeTreatment}
					accept={'.pdf, .jpg'}
				/>
			</FieldSection>

			<div className="flex pver-2 justify-center">
				<FormButton
					variant={'white'}
					loading={submitLoading}
				>
					Continue
				</FormButton>
			</div>
			{errors&&<div className={'mt center'}>
				{errors.get('submitError') && <Message message={errors.get('submitError')} color={color.get('primary')}/>}
			</div>}

		</FormContainer>

	);
}