import React from 'react';
import TalentDetailRender from './TalentDetailRender';
import {TalentDetailData, useTalentDetail} from 'hooks/useTalentDetail';

interface TalentDetailProps {
	id: string
	close: ()=>void
	setShowAddList: (show:boolean)=>void
	add: boolean
	score?:any
}

export default function TalentDetail({id, close, setShowAddList, add, score}:TalentDetailProps):JSX.Element{

	const { discoverData, instagramAudience } : TalentDetailData =useTalentDetail({talentId:id});

	if(id) {
		if(discoverData.loading){
			return <p>Loading...</p>;
		}
		else{
			return <TalentDetailRender
				add={add}
				setShowAddList={setShowAddList}
				close={close}
				id={id}
				data={discoverData.data}
				audience={instagramAudience.data}
				score={score}
			/>;
		}
	}
	else {
		return <p>No ID</p>;
	}

}