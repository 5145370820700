import { QueryResult, useQuery } from '@apollo/client';
import { query } from 'actions/queries';
import {Item, ItemApi, Tenant} from 'interfaces';
import React, {createContext} from 'react';
import {sortListBy} from '../tools/globalActions';

interface userProviderProps {
	children:React.ReactNode
}

export interface GeneralDataContextInterface {
	userTenants: Item[]
    genders: Item[]
    ethnicities: Item[]
    languages: Item[]

    cities: Item[]
    states: Item[]
    countries: Item[]

	getLabel? : (genderId:string, items : Item[]) => string|null
	getLabelList? : (idList : string[], items : Item[]) => string[]

	loading? : {
		userTenants: boolean
		genders: boolean
		ethnicities: boolean
		languages: boolean

		cities: boolean
		states: boolean
		countries: boolean

	}

	error? : {
		userTenants: boolean
		genders: boolean
		ethnicities: boolean
		languages: boolean

		cities: boolean
		states: boolean
		countries: boolean

	}
}
export const GeneralDataContext:React.Context<GeneralDataContextInterface> =createContext({
	userTenants: [],
	genders: [],
	ethnicities:[],
	languages : [],
	cities: [],
	states: [],
	countries: [],
	
	getLabel: undefined,
	getLabelList : undefined
});


const GeneralDataContextProvider=({children}:userProviderProps):JSX.Element=>{
	const {data:tenantsData, loading :tenantsLoading, error: tenantsError}:QueryResult=useQuery(query('tenantsByUser'));
	const {data:gendersData, loading :gendersLoading, error: gendersError}:QueryResult=useQuery(query('gendersFindMany'));
	const {data:ethnicityData, loading :ethnicitiesLoading, error: ethnicitiesError}:QueryResult=useQuery(query('ethnicityFindMany'));
	const {data:languageData, loading :languagesLoading, error: languagesError}:QueryResult=useQuery(query('languageFindMany'));
	const {data:citiesData, loading: citiesLoading, error: citiesError}:QueryResult=useQuery(query('cityFindMany'));
	const {data:statesData, loading: statesLoading, error: statesError}:QueryResult=useQuery(query('stateFindMany'));
	const {data:countriesData, loading: countriesLoading, error: countriesError}:QueryResult=useQuery(query('countryFindMany'));

	const isReady:boolean = !gendersLoading && !languagesLoading && !ethnicitiesLoading ;


	const getLabel=(genderId:string, items : Item[]):string|null=>{
		if (!items)
			return null;
		return items.find(( g:Item ):boolean=>g.id === genderId)?.label;
	};

	const getLabelList =(idList : string[], items : Item[]):string[]=>{
		if ( !idList || !items)
			return [];
		return idList.map( (id:string):string =>  getLabel( id, items ) || '' );
	};

	const userTenants : Item[]= tenantsData?.tenantsByUser.map((e:Tenant):Item=> ({ ...e, id:e._id}) )||[];
	const genders : Item[]= gendersData?.gendersFindMany.map((e:ItemApi):Item=> ({id:e._id,label:e.label}) )||[];
	const changeEthnicityLabel = (label:string):string => {
		const labelByValu: Record<string, string> = {
			'African American': 'Black',
			'Asian' : 'Asian',
			'Hispanic':'Hispanic',
			'White / Caucasian':'White'
		};
		return labelByValu[label] || label;
	};
	const ethnicities : Item[]= ethnicityData?.ethnicityFindMany.map((e:ItemApi):Item=> ({id:e._id,label:changeEthnicityLabel(e.label)  }) )||[];
	const languages : Item[]= languageData?.languageFindMany.map((e:ItemApi):Item=> ({id:e._id,label:e.label}) )||[];

	const cities: Item[]= citiesData?.cityFindMany.map((e:ItemApi):Item=> ({id:e._id,label:e.label}) )||[];
	const states: Item[]= statesData?.stateFindMany.map((e:ItemApi):Item=> ({id:e._id,label:e.label}) )||[];
	const countries: Item[]= countriesData?.countryFindMany.map((e:ItemApi):Item=> ({id:e._id,label:e.label}) )||[];

	sortListBy(genders, 'label');
	sortListBy(ethnicities, 'label');
	sortListBy(languages, 'label');
	sortListBy(cities, 'label');
	sortListBy(states, 'label');
	sortListBy(countries, 'label');

	const value:GeneralDataContextInterface={
		userTenants,
		genders,
		ethnicities,
		languages ,
		cities,
		states,
		countries,
		loading :{
			userTenants: tenantsLoading,
			genders: gendersLoading,
			ethnicities:ethnicitiesLoading,
			languages : languagesLoading,
			cities: citiesLoading,
			states: statesLoading,
			countries: countriesLoading
		},
		error :{
			userTenants: !!tenantsError,
			genders: !!gendersError,
			ethnicities: !!ethnicitiesError,
			languages : !!languagesError,
			cities: !!citiesError,
			states: !!statesError,
			countries: !!countriesError
		},
		getLabel, getLabelList
	};

	return (
		<GeneralDataContext.Provider value={value} >
			{children}
		</GeneralDataContext.Provider>
	);
};
export default GeneralDataContextProvider;
