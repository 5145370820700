import React, {useContext, useState} from 'react';
import SignupRender from './SignupRender';
import {ItemApi, SignupData} from 'interfaces';
import {useForm} from 'hooks/useForm/useForm';
import {FormField, FormHook} from 'hooks/useForm/interfaces';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import {QueryResult, useQuery} from '@apollo/client';
import {query} from 'actions/queries';
import {UserContext} from 'context/userContext';

const getUserTypeId=(userTye:string,userTypes:ItemApi[]|null):string=>{
	const Types:any={
		'talent':'Regular',
		'brand':'Company',
		'company':'Agent',
		'admin':'Administrator',
		'default':'Regular'
	};
	return userTypes?.filter((i:ItemApi):boolean=>Types[userTye||'default']===i.label)[0]?._id ||'' ;
};


const titleByUSerType:any={
	talent:'Discover and Connect With Top Brands',
	brand:'Let’s Sign You Up',
	company:'Discover and Connect With Top Brands',
	default:'Discover and Connect With Top Brands'
};

const subTitleByUSerType:any={
	talent:'',
	brand:'',
	company:'',
	default:''
};

export const Signup=():JSX.Element=>{
	const navigate:NavigateFunction=useNavigate();
	const [providerLoading,setProviderLoading] =useState(false);
	// get user type
	//const {pathname}:Location=useLocation();
	const userType:string='brand';
	const {userSignup}:any=useContext(UserContext);

	const {data:userTypesData}:QueryResult=useQuery(query('userTypesFindMany'));

	const  formFields:FormField[]=[
		{name:'firstName', required:true},
		{name:'lastName', required:true},
		{name:'email', required:true, pattern: /^\S+@\S+\.\S+$/, watch:true},
		{name:'password', required:true}
	];


	const signupAction=async(signupData:SignupData):Promise<void> =>{

		if(!userTypesData){
			throw new Error('Missing userType, please reload the page and try again');
		}

		signupData.type=getUserTypeId(userType,userTypesData.userTypesFindMany);
		signupData.email=signupData.email.toLowerCase();

		await userSignup(signupData);
		navigate(`/onboarding/${userType}/verify`);
	};

	const  {data,errors,loading,...rest}:FormHook=useForm(formFields,signupAction);

	return <SignupRender
		title={titleByUSerType[userType||'default']}
		// type={getUserTypeId(userType, userTypesData.userTypesFindMany)}
		type={'Company'} //company
		providerLoading={providerLoading}
		sibTitle={subTitleByUSerType[userType||'default']}
		formData={data}
		submitLoading={loading}
		setProviderLoading={setProviderLoading}
		errors={errors}

		onSubmit={rest.handleSubmitForm}
		registerInput={rest.registerInput}
	/>;
};
