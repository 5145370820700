import React from 'react';
import {StyledButton} from 'styles/ui';
import {FormButtonProps} from '../FormButton/FormButtonRender';
import {Spinner} from '../Spinner';

export const Button = ({label,loading,variant,size,width,children,...props}:FormButtonProps):JSX.Element => {
	return !loading?<StyledButton
		variant={variant}
		size={size}
		width={width}
		{...props}
	>{label} {children}</StyledButton>
		:<div className='w-100 flex justify-center'>
			<Spinner/>
		</div>;
};
