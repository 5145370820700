import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {ArrowRightIcon} from 'icons/ArrowRightIcon';
import {BoxTitle} from 'stylesOpus/typography';

interface SectionProps {
	title:string
	description?:string
	link?:{
		path:string
		label:string
	}

	className?:string
	children?:React.ReactNode
}

const TitleContainer:any =styled.div`
	  display: flex;
	  justify-content: space-between;
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 12px;
  		margin-bottom: 0.5rem;
    h6{ 
		font-size: 12px;
   }
	p{
		color: #ABB0C6;
		font-size: 0.75rem;
		font-style: normal;
		font-weight: 400;
		line-height: 1.4375rem; /* 191.667% */
		letter-spacing: 0.075rem;
	}
`;

export const ViewAll:any = styled.div`
	>p{
		font-family: "Urbanist", sans-serif;
		color: #FFF;
		font-size: 0.625rem;
		font-weight: 900;
		line-height: normal;
		letter-spacing: 0.0625rem;
	}
`;


export const SectionUi =({title,description,link,className,children}:SectionProps):JSX.Element=>{
	return <section className={className}>
		<TitleContainer>
			<div className="flex direction-column">
				<BoxTitle className={'mb-half'} fontSize='1rem'>
					{title}
				</BoxTitle>
				{ description &&<p>{description}</p>}
			</div>
			{link ? <Link to={link.path}>
				<ViewAll className={'flex align-center'}>
					<p className={'t-white cursor-pointer'}>{link.label}</p>
				</ViewAll>
			</Link>
				:null}
		</TitleContainer>
		{children}
	</section>;
};