import React from 'react';
import {Avatar, FormButton, Input, RadioButton, Select, Slider, SocialButton, Tag} from 'components/ui';
import {Range,Steps} from 'components';
import {fakeCompanyUser} from 'tools/MuckData/fakeUsers';
import {fakeItems} from 'tools/MuckData/items';
import {
	ActivityIcon,
	AirplaneIcon,
	AppleMusicIcon,
	BookMarkIcon,
	BrandsCubeIcon,
	BrandsHexagonIcon,
	BrandsLightningIcon,
	CameraIcon,
	CaratDown,
	CaratLeft,
	CaratRight,
	CaratUp,
	CategoryShareIcon,
	CheckIcon,
	CloseIcon,
	DashboardIcon,
	DeleteTextIcon,
	DisconnectedIcon,
	EditIcon,
	EmailIcon,
	FacebookIcon,
	FacebookOutlineIcon,
	HideIcon,
	InstagramIcon,
	InstagramOutlineIcon,
	LinkIcon,
	NewsIcon,
	NotificationsIcon,
	OpusIcon,
	OpusLogo,
	PeopleIcon,
	PlantIcon,
	ProfileIcon,
	RefreshIcon,
	RocketIcon,
	SearchIcon,
	ShareIcon,
	ShowIcon,
	SpotifyIcon,
	StartFilledIcon,
	StarUnfilledIcon,
	TalentIcon,
	TalkIcon,
	TwitterIcon,
	YoutubeIcon,
	InvalidIcon
} from '../icons';
import {LinkButton} from 'components/ui/LinkButton';
import {color} from 'styles/colors';
import {Carousel} from '../components/complexComponents';

const pictures:string[] =[
	'https://images.pexels.com/photos/674010/pexels-photo-674010.jpeg?auto=compress&cs=tinysrgb&w=600',
	'https://img.freepik.com/free-photo/painting-mountain-lake-with-mountain-background_188544-9126.jpg',
	'https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
	'https://fps.cdnpk.net/home/cover/image-7.jpg?w=1400',
	'https://images.unsplash.com/photo-1581456495146-65a71b2c8e52?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8aHVtYW58ZW58MHx8MHx8fDA%3D'
];
export const UIKIt=():JSX.Element=>{
	return (
		<div>
			<section>
				<h1>“H1” is 28px INTER Extra Bold with 140% line-spacing and -.3px letter-spacing..</h1>
				<h2>“H2” is 24px Inter Extra Bold with 140% line-spacing and -.2px letter-spacing.</h2>
				<h3>“H3” is 20px Inter Extra Bold with 140% line-spacing and -.2px letter-spacing.</h3>
				<h4>“H4” is 20px Inter Extra Bold with 140% line-spacing and -.2px letter-spacing.</h4>
				<p className="body-large">Body text</p>
				<p className="body-large-bold">Body text</p>
				<p className="body">Body text</p>
				<p className="body-bold">Body text</p>
				<p className="body">Body text</p>
				<p className="body-small-bold">Body text</p>
				<p className="body-small">Body text</p>
			</section>
			<section className={'flex justify-center'}>
				<Carousel
					width={'800px'}
					images={pictures}
					imageWidth={70}
				/>
			</section>
			<section className={'mv'}>
				<h1>Icons</h1>
				<div className={'p'}>

					<div className="grid8 align-center col-gap">
						<DisconnectedIcon/>
						<ActivityIcon/>
						<CaratLeft/>
						<CaratRight/>
						<CaratDown/>
						<CaratUp/>
						<CloseIcon/>
						<BookMarkIcon/>
						<CameraIcon/>
						<HideIcon/>
						<RefreshIcon/>
						<ShowIcon/>
						<EmailIcon/>
						<CheckIcon/>

						<DeleteTextIcon/>
						<LinkIcon/>
						<EditIcon/>
						<ShareIcon/>
						<InstagramOutlineIcon/>
						<FacebookOutlineIcon/>
						<SearchIcon/>
						<StartFilledIcon/>
						<StarUnfilledIcon/>
						<InvalidIcon/>
					</div>

					<div className="grid8 align-center col-gap">
						<DashboardIcon/>
						<NewsIcon/>
						<BrandsLightningIcon/>
						<BrandsCubeIcon/>
						<BrandsHexagonIcon/>
						<TalentIcon/>
						<NotificationsIcon/>
						<ProfileIcon/>
					</div>
					<div className="grid8 align-center col-gap">
						<TalkIcon/>
						<PlantIcon/>
						<RocketIcon/>
						<AirplaneIcon/>
						<CategoryShareIcon/>
						<PeopleIcon/>
					</div>

					<div className={'grid8 col-gap pver'}>
						<FacebookIcon/>
						<TwitterIcon/>
						<InstagramIcon/>
						<YoutubeIcon/>
						<AppleMusicIcon/>
						<SpotifyIcon/>
						<TwitterIcon/>
						<OpusLogo/>
						<OpusIcon/>
					</div>

				</div>
			</section>
			<section className={'grid2 col-gap p'}>
				<div className="flex direction-column">
					<FormButton
						label={'Continue'}
					/>
					<FormButton
						label={'Continue'}
						variant={'secondary'}
					/>
					<FormButton
						size={'small'}
						label={'Continue'}
					/>
					<FormButton
						size={'small'}
						variant={'secondary'}
						label={'Continue'}
					/>
				</div>
				<div>
					<LinkButton
						label={'Link'}
						to={'/'}
					/>
					<LinkButton
						label={'Link'}
						to={'/'}
						variant={'secondary'}
					/>

					<LinkButton
						label={'Link'}
						to={'/'}
						size={'small'}
					/>
					<LinkButton
						label={'Link'}
						to={'/'}
						variant={'secondary'}
						size={'small'}
					/>
				</div>
				<div className="flex direction-column">

				</div>

				<div className={'flex direction-column'}>
					<Avatar user={fakeCompanyUser} size={'10rem'} fontSize={'2rem'}/>
					<Avatar user={fakeCompanyUser} size={'5rem'} fontSize={'1rem'}/>
					<Avatar user={fakeCompanyUser} size={'2rem'}/>
				</div>
				<div className="grid2 col-gap">
					<Input
						label={'example label'}
						placeholder={'example placeholder'}
					/>
					<Input
						label={'example label'}
						placeholder={'example placeholder'}
						required
					/>
					<Input
						placeholder={'example placeholder'}
					/>
					<Input
						placeholder={'example placeholder'}
						required
					/>
				</div>
				<div>
					<h1>RAdio Buttons</h1>
					<RadioButton/>
					<RadioButton/>
				</div>
				<div className="grid2 col-gap">
					<Select
						label={'example'}
						placeholder={'example'}
						options={fakeItems}
					/>
					<Select
						label={'example'}
						placeholder={'example'}
						options={fakeItems}
						required
					/>
					<Select
						placeholder={'example'}
						options={fakeItems}
					/>
					<Select
						required
						placeholder={'example'}
						options={fakeItems}
					/>
				</div>
				<div>
					<Tag
						category={{label:'Telcom',id:'sdkfgjdsh'}}
						selected
					/>
					<Tag
						category={{label:'Telcom',id:'sdkfgjdsh'}}
						selected
						deletable
					/><Tag
						category={{label:'Telcom',id:'sdkfgjdsh'}}
						filled
					/>
					<Tag
						category={{label:'nft-crypto',id:'sdkfgjdsh'}}
					/>
				</div>
				<div>
					<Range className={'mb-2'} color={color.get('primary')} max={200} value={120}/>
					<Steps className={'mb-2'} steps={6} currentStep={3}/>
					<Steps className={'mb-2'} steps={8} currentStep={3}/>
					<Steps className={'mb-2'} steps={6} currentStep={3} size={'100%'}/>
					<Steps className={'mb-2'} steps={8} currentStep={3} size={'100%'}/>
				</div>
			</section>
			<section className={'grid2 col-gap p'}>
				<SocialButton
					connected={true}
					userAccount={null}
					socialNetwork={'facebook'}
					link
				/>
				<SocialButton
					connected={false}
					userAccount={null}
					socialNetwork={'twitter'}
					link
				/>
				<SocialButton
					connected={false}
					userAccount={'@Test-account'}
					socialNetwork={'instagram'}
				/>
				<SocialButton
					connected={false}
					userAccount={'@Test-account'}
					socialNetwork={'youtube'}
				/>
				<SocialButton
					connected={false}
					userAccount={'@Test-account'}
					socialNetwork={'apple-music'}
					link
				/>
				<SocialButton
					connected={false}
					userAccount={'@Test-account'}
					socialNetwork={'spotify'}
					link
				/>

			</section>
		</div>


	);
};
