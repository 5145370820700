import React from 'react';
import styled from 'styled-components';
import {DiscoveryMatchItem, SearchForm} from './components/';
import {Header, ItemsCollection, SectionUi} from 'components';
import {FilterFindManytalentInput, Match} from 'interfaces/interfacesData';
import {NotificationsIcon} from 'icons';
import {HeaderMenu} from './components/HeaderMenu';
import {Layout} from '../../containers';



interface DiscoveryProps {
	recommended:Match[]
	handleFilter:(data:FilterFindManytalentInput)=>void
	search?:boolean
	isLoading:boolean

	setAudience:(value:any)=>void

	scores:any[]
	setTenant:(value:string)=>void
	modal:{
		show:boolean
		open:(talentId:string)=>void
		close:()=>void,
		talentId:string
	}
}

const Column:any = styled.section`
  grid-area: side;
  display: block;
  box-sizing: border-box;

`;

const ContentContainer:any = styled.section`
  grid-area: content;
  justify-self: center;
  max-width: 1200px;
  width: 100%;
  overflow-x:hidden;
`;

export const DiscoveryRender = ({recommended,handleFilter,search=false,isLoading,setAudience,modal,scores, setTenant}:DiscoveryProps):JSX.Element => {
	return <>

		<Header
			icon={{
				Element:NotificationsIcon,
				label:'My Lists'
			}}
			menu={<HeaderMenu/>}
			description='Browse or search for talent to match opportunities'
		/>
		<Column>
			<SearchForm
				setTenant={setTenant}
				handleFilter={handleFilter}
				setAudience={setAudience}
			/>
		</Column>


		{!isLoading ?
			<ContentContainer>
				{search ?
					<SectionUi title={''}>
						<ItemsCollection>
							{recommended.map((e: Match,index:number): JSX.Element =>
								<DiscoveryMatchItem
									key={e._id}
									talent={e}
									action={modal.open}
									score={scores ? scores[index]: null}
								/>
							)}
						</ItemsCollection>
					</SectionUi>
					:
					<>
						<SectionUi title={'Recommended'}>
							<ItemsCollection>
								{recommended.slice(0, 4).map((e: Match): JSX.Element =>
									<DiscoveryMatchItem
										key={e._id}
										talent={e}
										action={modal.open}
									/>
								)}
							</ItemsCollection>
						</SectionUi>
						{/*
						<SectionUi title={''}>
							<DiscoverHero/>
						</SectionUi>
						*/}

						<SectionUi title={''}>
							<ItemsCollection>
								{recommended.slice(4, 12).map((e: Match): JSX.Element =>
									<DiscoveryMatchItem
										key={e._id}
										talent={e}
										action={modal.open}
									/>
								)}
							</ItemsCollection>
						</SectionUi>

						{/*
						<ItemsCollection cols={3}>
							<TopTalentITem category={'Athletes'} image={athletesImage}/>
							<TopTalentITem category={'Creators'} image={creatorsImage}/>
							<TopTalentITem category={'AfricanAmericans'} image={africanAmericansImage}/>
						</ItemsCollection>
						*/}

						<SectionUi title={''}>
							<ItemsCollection rows={2}>
								{recommended.slice(12, 20).map((e: Match): JSX.Element =>
									<DiscoveryMatchItem
										key={e._id}
										talent={e}
										action={modal.open}
									/>
								)}
							</ItemsCollection>
						</SectionUi>
					</>
				}
			</ContentContainer>
			:
			<p>Loading...</p>
		}

	</>;
};