import React from 'react';
import {ModalHook} from 'hooks/useModal';
import {Card} from 'components';

import {Modal} from 'components/Modal';
import {MutationTuple, useMutation} from '@apollo/client';
import {mutation} from 'actions/mutations';
import {BodyText} from 'stylesOpus/typography';
import {Button, LinkButton} from 'components/ui';
import {NavigateFunction, useNavigate} from 'react-router-dom';

export interface DeleteModalProps {
    listId:string
    
    popup:ModalHook
    
}
export const DeleteModal = ({listId, popup}:DeleteModalProps):JSX.Element => {
	
	const navigate: NavigateFunction = useNavigate();
	const [deleteList]:MutationTuple<any, any> = useMutation(mutation('listDeleteOne'), {
		variables: { id: listId },
		refetchQueries: ['listFindMany']
	});

	const handleDelete = ():void => {
		deleteList()
		.then(():void => {
			navigate('/discover');
		});
	};
               
	return(
		<Modal onClickOut={ popup.close }>
			<Card >
				<div className={'flex direction-column align-center'}>
					<h4 className="mt">Are you sure to delete this list? </h4>

					<div className={'w-100 col-gap mt-2 flex align-center '}>
						<Button
							width={'13rem'}
							label={'Canel'}
							variant={'secondary-white'}
							onClick={popup.close}
						/>
						<Button
							width={'13rem'}
							onClick={handleDelete}
							label={'Confirm'}
							variant={'white'}
						/>
					</div>

				</div>
			</Card>
		</Modal>
	);
};