import React, {ChangeEvent, useEffect} from 'react';
import {Item, ListInput} from 'interfaces';
import {listHandler, useListHandler} from 'hooks/useListHandler';
import {SearchAutoCompleteRender} from './SearchAutoCompleteRender';
import {QueryResult} from '@apollo/client';
import {sortList} from 'tools/comparation';
import {SearchHandler, useSearch} from 'hooks/useSearch';

interface SearchAutoCompleteProps extends Omit<ListInput, 'options'>{
	theme?:'opus' | 'opusV2'
	searchName:string
	findOneAction:string
}

export const SearchAutoComplete = ({searchName,value,name,onChange,...props}:SearchAutoCompleteProps):JSX.Element => {

	const { showOptions,setShowOptions,queryResult, optionWrapperRef ,setQuery }:SearchHandler=useSearch({searchAction:searchName});
	const { data,loading,error }:QueryResult=queryResult;

	let options :Item[]=  ( !loading && !error && data) ? data[searchName] :[];
	options = options.map((option:any):Item=>({...option,id:option._id}));
	const {handleUnselected,handleSelected,getSelectedItems, resetSelected}:listHandler=useListHandler({name,value,options,onChange});
	useEffect(():void=>{
		if(!value || !value.length){
			resetSelected();
		}
	},[value]);
	const handleSelect=(item:Item):void=>{
		handleSelected(item);
		setShowOptions(false);
	};

	const onChangeQuery=(e:ChangeEvent<HTMLInputElement>):void=>{
		setShowOptions(true);
		setQuery(e.target.value);
	};

	const onClickArrow=():void=>{
		setShowOptions(!showOptions);
	};

	sortList(options,'label');
	return <SearchAutoCompleteRender
		{...props}
		ref={optionWrapperRef}
		selectedOptions={getSelectedItems()}
		options={{
			items:options,
			show:showOptions,
			loading,
			error:!!error
		}}
		onSelectOption={handleSelect}
		onUnselectOption={handleUnselected}
		onChangeQuery={onChangeQuery}
		onClickArrow={onClickArrow}
	/>;
};