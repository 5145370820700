import React,{ChangeEvent} from 'react';
import {Code} from './styles';
import {VerifyInput} from './VerifyInput';

type digit=number|string;
interface VerificationcodeRenderProps{
	codeSize:number;
	digits:digit[];
	changeEvent:(e:ChangeEvent)=>void;
}

export default function VerificationCodeRender({codeSize,digits,changeEvent}:VerificationcodeRenderProps):JSX.Element{
	let array:any[]=Array.from(Array(codeSize).keys());
	return (
		<Code codeSize={codeSize}>
			{array.map((_:any,i:number):JSX.Element=>
				<VerifyInput
					key={i}
					type={'number'}
					value={digits[i]}
					name={i.toString()}
					onChange={changeEvent}
				/>
			)}
		
		</Code>
	);
}
