import React from 'react';

import { FormButton, Input } from 'components/ui';
import {
	FormContainer,
	FormContent,
	Subtitle
} from './styles';
import {FormProps} from 'interfaces';
import {Message} from 'components';
import {color} from 'styles/colors';
import {BodyText, BoxTitle} from 'stylesOpus/typography';
import {SecurityForm} from '../../styles';

export const PasswordRender = ({submitLoading,errors,registerInput,onSubmit}:FormProps): JSX.Element => {
	return (
	
		<SecurityForm  onSubmit={onSubmit} autoComplete={'nope'}>
			<BoxTitle>Change Password</BoxTitle>
			<div>
				<BoxTitle className={'mb'}>Enter Current Password</BoxTitle>
				<Input
					autoComplete={'new-password'}
					type={'password'}
					label={'Current Password'}
					{...registerInput('currentPassword')}
				/>
				<Input
					autoComplete={'new-password'}
					type={'password'}
					label={'New password'}
					{...registerInput('newPassword')}
				/>
				<br/>
				<BoxTitle className={'mb'} >Enter New Password</BoxTitle>
				<Input
					autoComplete={'new-password'}
					type={'password'}
					label={'Enter new password'}
					{...registerInput('RepeatNewPassword')}
				/>
			</div>
			<div>
				<FormButton loading={submitLoading} variant={'white'} label={'Save'} />
				{errors.get('submitError') && <Message message={errors.get('submitError') } color={color.get('primary')}/>}
				{errors.get('currentPassword') && <Message message={'Your current password is required'} color={color.get('primary')}/>}
				{errors.get('newPassword') && <Message message={'Ya new password is required'} color={color.get('primary')}/>}
				{errors.get('RepeatNewPassword') && <Message message={'please type again your new password'} color={color.get('primary')}/>}
			</div>
			
		</SecurityForm>

	);
};
