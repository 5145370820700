import React from 'react';
import {CulturalAlert, TacticI} from 'interfaces';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import SubtacticAlertRowLoad from '../../components/SubtacticAlertRow/SubtacticAlertRow.load';
import {UserContext, userContextInterface} from '../../context/userContext';

type OpportunitiesRenderProps = {
    opportunities: CulturalAlert[]
	subtactics?: TacticI[]
	tactics: TacticI[]
};

const PageContainer:any = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2.5rem;
`;

export const OpportunitiesRender = ({opportunities,tactics, subtactics}:OpportunitiesRenderProps):JSX.Element => {

	const {user} : userContextInterface = React.useContext(UserContext);

	return (
		<PageContainer>

			<SubtacticAlertRowLoad
				viewAll='/opportunities/featured/new'
				limit={4}
				filter={{
					active:true,
					ENDDATE_GREATERTHAN: new Date().toISOString(),
					_operators:{tenant:{in:user.tenantList}}
				}}
				title={'Latest opportunities'}
				description={'Discover the latest opportunities from our members.'}
			/>

			<SubtacticAlertRowLoad
				viewAll='/opportunities/featured/expiring'
				sort='ENDDATE_ASC'
				filter={{
					active:true,
					ENDDATE_GREATERTHAN: new Date().toISOString(),
					_operators:{tenant:{in:user.tenantList}}
				}}
				limit={4}
				title={'Leaving soon'}
				description={'Act now before these opportunities close.'}
				withRemainTime
			/>

			{subtactics && subtactics?.length > 0 && subtactics.map((subtactic:TacticI):JSX.Element=>{
				return (
					<SubtacticAlertRowLoad
						key={subtactic?._id}
						subtactic={subtactic}
						filter={{
							active:true,
							subtactic:[subtactic?._id],
							ENDDATE_GREATERTHAN: new Date().toISOString(),
							_operators:{tenant:{in:user.tenantList}}
						}}
					/>
				);
			})}


			<div className="grid6 col-gap pb">
				{tactics && tactics.length > 0 && tactics.map(({title, _id, slug, cover}:TacticI, index:number):JSX.Element => {
					return (
						<div key={_id} className={`tactic mb overflow-hidden h-100px br-half relative flex justify-start align-end ${index === 3 || index === 4 || index === 8 || index === 9 ? 'col-span3' : 'col-span2'}`}>
							<Link to={`/opportunities/${slug}/${_id}`}>
								<img src={cover[cover.length - 1]?.url} className="absolute edges w-100 h-100 obj-cover" />
								<div className="overlay absolute edges w-100 h-100" />
								<h4 className="relative m f-urbanist f-bold f-1rem t-white">
									{title}
								</h4>
							</Link>
						</div>
					);
				})}
			</div>


		</PageContainer>
	);
};
