import React from 'react';

export const TalentIcon=():JSX.Element=>
	<svg
		width={24}
		height={24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M22.0495 9.72393L22.0495 9.72396L22.0552 9.72424C22.1264 9.72781 22.1664 9.74902 22.189 9.76632C22.2143 9.78575 22.2326 9.81187 22.2423 9.84039C22.252 9.86896 22.2516 9.89481 22.246 9.9149C22.2416 9.93096 22.2294 9.96032 22.1843 9.99602C22.1842 9.99612 22.1841 9.99623 22.1839 9.99633L17.448 13.7163C16.906 14.1205 16.6958 14.7715 16.8216 15.3711L16.8264 15.3935L16.8324 15.4157L18.3819 21.0564C18.3913 21.0944 18.3862 21.1168 18.3795 21.1332C18.3708 21.1546 18.3512 21.1829 18.3156 21.2078C18.2462 21.2562 18.154 21.2693 18.0573 21.2127L12.8859 18.0672C12.3278 17.7065 11.6266 17.725 11.0758 18.0623L5.91909 21.213C5.82256 21.2693 5.7305 21.2561 5.66126 21.2078C5.62563 21.1829 5.60601 21.1546 5.59731 21.1332C5.59065 21.1168 5.58553 21.0942 5.59507 21.0561L7.14346 15.3971C7.14369 15.3963 7.14392 15.3954 7.14415 15.3946C7.3257 14.7417 7.0498 14.0674 6.5635 13.6812L6.56352 13.6812L6.55871 13.6775L1.81608 9.97427C1.81574 9.97401 1.81541 9.97375 1.81508 9.97349C1.77045 9.93801 1.75843 9.90883 1.75396 9.89285C1.74835 9.87275 1.74796 9.8469 1.7577 9.81833C1.76743 9.78981 1.78565 9.7637 1.81102 9.74426C1.8336 9.72697 1.87361 9.70576 1.94481 9.70218L1.94481 9.7022L1.95033 9.70189L8.08468 9.34899L8.08619 9.3489C8.72395 9.31093 9.31012 8.92776 9.56648 8.31709L9.5665 8.3171L9.56836 8.31258L11.8125 2.86787C11.8418 2.79937 11.906 2.75 12.0116 2.75C12.117 2.75 12.1811 2.7992 12.2105 2.86752L14.4544 8.33365L14.4544 8.33366L14.4567 8.33915C14.713 8.94982 15.2992 9.33298 15.937 9.37096L15.9383 9.37104L22.0495 9.72393Z"
			stroke="white"
			strokeWidth="1.5"
		/>
	</svg>

;
