import React, {useState} from 'react';
import { SpiritsRender } from './SpiritsRender';
import {QueryResult, useQuery} from '@apollo/client';
import {query} from 'actions/queries';
import {Item} from 'interfaces';
import {EditProfileContext} from '../../index';
import {useOutletContext} from 'react-router-dom';

export const Spirits = (): JSX.Element => {

	const {profileData,updateProfile}:EditProfileContext=useOutletContext();

	const {data:spiritsData}:QueryResult=useQuery(query('spiritFindMany'));
	const [selectedItems,setSelectedItems] = useState<string[]>(profileData?.spirits||[]);

	const onchange=({value}:any):void=>{
		console.log('spirits:',{value});
		setSelectedItems([...value]);
		updateProfile({variables:{
			profileData:{spirits:value}
		}});
	};
	let defaultSpirits:Item[]=spiritsData?.spiritFindMany.map((i:any):Item=>
		({...i,id:i._id}))||[];
	return <SpiritsRender
		interestsOptions={defaultSpirits}
		selectedInterests={selectedItems}
		onChange={onchange}
	/>;
};
