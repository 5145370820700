import {atom, selector} from 'recoil';
import {IFilter, IMatch, IOption, IScore, ITalent} from '../../pages/Discover/DiscoverInterfaces';
import {Item} from '../../interfaces';

export const filterAtom:any = atom<IFilter>({
	key:'discoverFilter',
	default: {
		talent: {}
	}
});

export const matchQueryAtom:any = atom<IMatch>({
	key:'matchQuery',
	default: {
		platforms: ['instagram'],
		audience: {
			topic: [],
			ages: {
				min_age: 18,
				max_age: 80
			}
		}
	}
});

export const interestFormAtom:any = atom<string[]>({
	key: 'interestFormAtom',
	default: []
});

export const stateFormAtom:any = atom<IOption[]>({
	key: 'stateFormAtom',
	default: []
});

export const citiesFormAtom:any = atom<IOption[]>({
	key: 'citiesFormAtom',
	default: []
});

export const segmentsFormAtom:any = atom<string[]>({
	key: 'segmentsFormAtom',
	default: []
});

export const searchingAtom:any = atom<boolean>({
	key:'discoverSearching',
	default: false
});

export const talentScoresAtom:any = atom<IScore[]>({
	key:'talentScores',
	default: []
});

export const talentsAtom:any = atom<ITalent[]>({
	key:'talents',
	default: []
});

export const talentsWithScoresSelector:any = selector<ITalent[]>({
	key: 'scoredTalents',
	get: ({get}:any):ITalent[] => {
		const tal:ITalent[] = get(talentsAtom);
		const sco:IScore[] = get(talentScoresAtom);

		const ts:ITalent[] = tal.map((e:ITalent):ITalent=> {
			const s:IScore = sco.find((f:IScore):boolean=>f.talent_id === e._id);
			const t:ITalent = {...e, score : s?.instagram?.overall_score || 0};
			return t;
		});

		ts.sort((a:ITalent,b:ITalent):any => b.score - a.score);

		return ts;
	}
});

// breadCrumb

export type TCrumb={
	id:string
	label:string
	path:string
};

export const BreadCrumbAtom:any = atom<TCrumb[]>({
	key:'breadCrumb',
	default: []

});

export const BreadCrumbSelector:any = selector<TCrumb[]>({
	key: 'breadCrumbSelector',
	get: ({get}:any):TCrumb[] => {
		const bc:TCrumb[] = get(BreadCrumbAtom);
		return bc;
	}
});