import React, {useEffect} from 'react';
import {LikeIcon} from 'icons';
import styled, {keyframes} from 'styled-components';

const scaleAnimation:any = keyframes`
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const CheckButton:any=  styled.div<{checked:boolean}>`
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  
  >svg{
    z-index: 10;
  }
  
  >div{
    position: absolute;
    ${ ({checked}:{checked:boolean}):string=> checked ? `
    width: 26px;
    height: 26px;
  ` : ''}
    border-radius: 50%;
    background-color: #2CAE50;
    transform-origin: bottom right;
    animation: ${scaleAnimation} 0.2s ease-in-out;
  }
`;

type LikeButtonProps = {
	isChecked?:boolean
}

export const LikeButton = ({ isChecked}:LikeButtonProps):JSX.Element => {

	return <div>
		<CheckButton
			checked={isChecked}
		>
			{isChecked && <div/>}
			<LikeIcon/>
		</CheckButton>
	</div>;
};