import React from 'react';
import {color} from 'styles/colors';
import {CheckIcon} from './components/CheckIcon';
import {CheckBoxButtonProps, Container, Label} from '../RadioButton/radioButton.styles';

export const CheckboxButton=({defaultSelected,item,onClick}:CheckBoxButtonProps):JSX.Element=>{
	return <Container
		selected={defaultSelected}
		onClick={():void=>onClick(item)}
	>
		<div className={'flex align-center'}>
			{item.icon && item.icon({
				size:12,
				color:defaultSelected? color.get('primary'):color.get('neutral-400')
			})}
			<Label>{item.label}</Label>
		</div>
		<CheckIcon active={defaultSelected}/>
	</Container>;
};