import React, {useEffect, useState} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import {ButtonContainer, FieldError, FieldGroup, FieldGroupTitle, FormContainer} from './components/styledComponents';
import FieldBlock from './components/FieldBlock';
import {Button} from '../../stylesOpus/ui';
import TalentReach from './components/TalentReach';
import {matchQueryAtom, searchingAtom} from '../../context/recoil/store';
import TalentGender from './components/TalentGender';
import TalentIndustry from './components/TalentIndustry';
import AudienceGender from './components/AudienceGender';
import AudienceEthnicity from './components/AudienceEthnicity';
import AudienceInterest from './components/AudienceInterest/AudienceInterest';
import AudienceAge from './components/AudienceAge';
import {ReactComponent as CaratUp} from 'styles/icons/chevron-up.svg';
import {ReactComponent as CaratDown} from 'styles/icons/chevron-down.svg';
import AudienceCity from './components/AudienceCity/AudienceCity';
import {IMatch} from './DiscoverInterfaces';
import AudienceCountry from './components/AudienceCountry/AudienceCountry';
import AudienceState from './components/AudienceState/AudienceState';

export default function DiscoverForm():JSX.Element {

	const [searching, setSearching] = useRecoilState<boolean>(searchingAtom);
	const matchQuery:IMatch = useRecoilValue(matchQueryAtom);
	const [showAudience, setShowAudience] = useState<boolean>(true);
	const [showTalent, setShowTalent] = useState<boolean>(false);
	const [error, setError] = useState('');

	const checkSearch = ():void => {
		setError('');
		if(matchQuery.audience?.topic && matchQuery.audience?.topic?.length > 0){
			setSearching(true);
		}
		else{
			setError('Select one or more interests to get started');
		}
	};

	useEffect(():void=>{
		if(matchQuery.audience?.topic && matchQuery.audience?.topic?.length > 0){
			setError('');
		}
	},[matchQuery]);

	return (
		<div className="relative w-350px">
			<FormContainer>
				<FieldGroup>
					<FieldGroupTitle onClick={():void=>setShowAudience(!showAudience)}>
						<h3>Build Your Target Audience</h3>
						{showAudience ? <CaratUp/> : <CaratDown/>}
					</FieldGroupTitle>
					<div className={`${showAudience ? 'block' : 'hidden'}`}>
						<FieldBlock
							title="Interest(s)*"
							description="Select desired interest(s) for your target audience"
						>
							<AudienceInterest />
							{error &&
								<FieldError className="f-small">{error}</FieldError>
							}

						</FieldBlock>
						<FieldBlock
							title="Age Range"
						>
							<AudienceAge />
						</FieldBlock>
						<FieldBlock
							title="Gender(s)"
						>
							<AudienceGender />
						</FieldBlock>
						<FieldBlock
							title="Ethnicities"
						>
							<AudienceEthnicity />
						</FieldBlock>
						<FieldBlock
							title="Location(s)"
							description="Enhance Targeting by Adding Relevant Location(s) to Your Opportunity"
						>
							<AudienceCountry />
							<AudienceState />
							<AudienceCity />
						</FieldBlock>
					</div>

				</FieldGroup>

				<FieldGroup>
					<div className="pb-2">
						<FieldGroupTitle onClick={():void=>setShowTalent(!showTalent)}>
							<h3>Talent Filters</h3>
							{showTalent ? <CaratUp/> : <CaratDown/>}
						</FieldGroupTitle>
						<p>Select from the list below to refine your talent search by a specific industry</p>

						<div className={`${showTalent ? 'block' : 'hidden'}`}>
							<FieldBlock
								title="Segment(s)"
								description="Select from the list below to refine your search by a specific industry or Sports team"
							>
								<TalentIndustry />
							</FieldBlock>
							<FieldBlock
								title="Talent Gender"
							>
								<TalentGender />
							</FieldBlock>
							<FieldBlock
								title="Talent Reach"
								description="Select the follower count and outreach level for your preferred talent"
							>
								<TalentReach />
							</FieldBlock>
							{/*<FieldBlock*/}
							{/*	title="Social Channel(s)"*/}
							{/*	description="Select the preferred social channel for your campaign"*/}
							{/*>*/}
							{/*	<TalentSocialChannels />*/}
							{/*</FieldBlock>*/}
						</div>
					</div>

				</FieldGroup>

				<ButtonContainer>
					<div>
						<Button variant="white" fontFamily="'Lato', sans-serif" onClick={checkSearch}>
							Search
						</Button>
					</div>
				</ButtonContainer>

			</FormContainer>
		</div>
	);
}
