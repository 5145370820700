import {LoginData, OppEmail, SignupData, User} from 'interfaces';
import {addHeaders,authResponse} from '../tools';

const REACT_APP_REST_API:string|undefined=process.env.REACT_APP_REST_API;

export const login=(value:LoginData):any=>{

	return fetch(`${REACT_APP_REST_API}/webapi/login`,{
		headers:addHeaders({'Content-Type':'application/json'}),
		method:'post',
		body:JSON.stringify(value),
		credentials:'include'
	}).then(authResponse);
};

export const signup=(value:SignupData):any=>{
	return fetch(`${REACT_APP_REST_API}/webapi/signup`,{
		headers:addHeaders({'Content-Type':'application/json'}),
		method:'post',
		body:JSON.stringify(value),
		credentials:'include'
	}).then(authResponse);
};


export type TUSerDataQuery = {
	id: string
} & Omit<User, '_id'>


export const userData= async():Promise<TUSerDataQuery|null> =>{
	try {
		let response: Response = await fetch(`${REACT_APP_REST_API}/common/user-data`,{
			headers:addHeaders({'Content-Type':'application/json'}),
			method:'get',
			credentials:'include'
		});
		return response.json();
	}
	catch (e) {
		console.log(e);
		return null;
	}

};

export const profilePicture=(file:File,id:string):Promise<Response>=>{
	const formData:FormData=new FormData();
	formData.append('file',file);
	formData.append('_id',id);
	return fetch(`${REACT_APP_REST_API}/common/profile-picture`,{
		headers:addHeaders({}),
		method:'PUT',
		body:formData,
		credentials:'include'
	}).then((response:Response):Response=>response).catch((err:any):any=>err);
};

export const uploadAttachmentFile=(file:any,id:string):Promise<Response>=>{
	const formData:FormData = new FormData();
	formData.append('file',file);
	formData.append('id',id);
	return fetch(`${REACT_APP_REST_API}/common/attachment-file`, {
		headers:addHeaders({}),
		method:'PUT',
		body:formData,
		credentials:'include'
	}).then((response:Response):Response=>response).catch((err:any):any=>err);
};

export const uploadBrandPicture=(file:any,id:string):Promise<Response>=>{
	const formData:FormData=new FormData();
	formData.append('file',file);
	formData.append('id',id);
	return fetch(`${REACT_APP_REST_API}/common/brand-picture`,{
		headers:addHeaders({}),
		method:'PUT',
		body:formData,
		credentials:'include'
	}).then((response:Response):Response=>response).catch((err:any):any=>err);
};

export const uploadSkuPicture=(file:any,id:string):Promise<Response>=>{
	const formData:FormData=new FormData();
	formData.append('file',file);
	formData.append('id',id);
	return fetch(`${REACT_APP_REST_API}/common/sku-profile-picture`,{
		headers:addHeaders({}),
		method:'PUT',
		body:formData,
		credentials:'include'
	}).then((response:Response):Response=>response).catch((err:any):any=>err);
};

export const uploadOpportunityPicture=(file:File,id:string):Promise<Response>=>{
	const formData:FormData=new FormData();
	formData.append('file',file);
	formData.append('id',id);
	return fetch(`${REACT_APP_REST_API}/common/opportunity-image`,{
		headers:addHeaders({}),
		method:'PUT',
		body:formData,
		credentials:'include'
	}).then((response:Response):Response=>response).catch((err:any):any=>err);
};

export const uploadCulturalAlertPicture=(file:File,id:string):Promise<Response>=>{
	const formData:FormData=new FormData();
	formData.append('file',file);
	formData.append('_id',id);
	return fetch(`${REACT_APP_REST_API}/common/cultural-alert-picture`,{
		headers:addHeaders({}),
		method:'PUT',
		body:formData,
		credentials:'include'
	}).then((response:Response):Response=>response).catch((err:any):any=>err);
};

export const uploadCulturalAlertDeck=(file:File,id:string):Promise<Response>=>{
	const formData:FormData=new FormData();
	formData.append('file',file);
	formData.append('_id',id);
	return fetch(`${REACT_APP_REST_API}/common/upload-deck`,{
		headers:addHeaders({}),
		method:'PUT',
		body:formData,
		credentials:'include'
	}).then((response:Response):Response=>response).catch((err:any):any=>err);
};


export const uploadCulturalAlertVideoTreatment=(file:File,id:string):Promise<Response>=>{
	const formData:FormData=new FormData();
	formData.append('file',file);
	formData.append('_id',id);
	return fetch(`${REACT_APP_REST_API}/common/upload-videoTreatment`,{
		headers:addHeaders({}),
		method:'PUT',
		body:formData,
		credentials:'include'
	}).then((response:Response):Response=>response).catch((err:any):any=>err);
};

export const uploadCompanyLogo=(file:File):Promise<Response>=>{
	const formData:FormData=new FormData();
	formData.append('file',file);
	return fetch(`${REACT_APP_REST_API}/common/logo-picture`,{
		headers:addHeaders({}),
		method:'PUT',
		body:formData,
		credentials:'include'
	}).then((response:Response):Response=>response).catch((err:any):any=>err);
};

export const uploadOpportunityTreatment=(file:File,id:string):Promise<Response>=>{
	const formData:FormData=new FormData();
	formData.append('file',file);
	formData.append('id',id);
	return fetch(`${REACT_APP_REST_API}/common/opportunity-treatment`,{
		headers:addHeaders({}),
		method:'PUT',
		body:formData,
		credentials:'include'
	}).then((response:Response):Response=>response).catch((err:any):any=>err);
};


export const sendEmail=async(value:OppEmail):Promise<any>=>{

	try {
		let response: Response = await fetch(`${REACT_APP_REST_API}/common/Opportunity-Email-Notification`,{
			headers:addHeaders({'Content-Type':'application/json'}),
			method:'post',
			body:JSON.stringify(value),
			credentials:'include'
		});
		return response.json();
	}
	catch (e) {
		return null;
	}

};

export type SignupWithProviderData = {
	token : string
	type: string
}

export const signupGoogle=(value:SignupWithProviderData):any=>{
	return fetch(`${REACT_APP_REST_API}/webapi/signup-google`,{
		headers:addHeaders({'Content-Type':'application/json'}),
		method:'post',
		body:JSON.stringify(value),
		credentials:'include'
	}).then(authResponse);
};
export const signupApple=(value:SignupWithProviderData):any=>{
	return fetch(`${REACT_APP_REST_API}/webapi/signup-apple-203`,{
		headers:addHeaders({'Content-Type':'application/json'}),
		method:'post',
		body:JSON.stringify(value),
		credentials:'include'
	}).then(authResponse);
};
