import React,{ChangeEvent,Ref,useRef} from 'react';
import VerifyInputRender from './VerifyInputRender';
import {InputProps} from 'interfaces';

export const VerifyInput=({onChange,...props}:InputProps):JSX.Element=>{
	const ref:Ref<HTMLInputElement>=useRef(null);
	const onChangeValue=(e:ChangeEvent<HTMLInputElement>):void=>{
		if(e.target.value.length<2){
			onChange(e);
			if(e.target.value && ref.current){
				let next:any=ref.current.nextElementSibling;
				if(next){
					next.focus();
				}
			}
		}
	};
	return (
		<VerifyInputRender
			{...props}
			ref={ref}
			onChange={onChangeValue}
		/>
	);
};
