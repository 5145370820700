import React, {ForwardedRef, forwardRef} from 'react';
import {Wrapper, OptionsContainer,Option, Label, Input} from 'styles/ui';
import {AutoCompleteSearchProps, Item, Talent, User} from 'interfaces';
import {ArrowDownIcon} from 'icons';
import {Spinner} from '../Spinner';
import { SelectedUser } from './components/SelectedUser';
import styled from 'styled-components';
import { WithUserData } from 'hocs/WithUserData';

type searchTalentRender = AutoCompleteSearchProps<Talent>;

const SelectedWrapper:any=styled.div`
  margin-top: 18px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;


export const UserSearchRender:any = forwardRef((props:searchTalentRender,ref:ForwardedRef<any>):JSX.Element => {
	const {selectedOptions,
		label,
		helperText,
		options,
		required,
		value,
		onChangeQuery,
		onSelectOption,
		onUnselectOption,
		onClickArrow,
		...rest}:searchTalentRender=props;
	return  <>
		<Wrapper>
			<div>
				<div>
					<Label>{label}</Label>
				</div>
				<Input
					{...rest}
					required={required}
					onChange={onChangeQuery}
				/>
			</div>

			<div
				className='cursor-pointer'
				onClick={onClickArrow}
			>
				<ArrowDownIcon />
			</div>

			{options.show&& <OptionsContainer  ref={ref}>
				{ options.loading?
					<div className={'w-100 h-100 flex justify-center align-center'}>
						<Spinner color={'neutral-400'} />
					</div>
					:
					options.items.map((item:Item,index:number):JSX.Element=>
						<Option
							key={index+item.label}
							onClick={():void=>onSelectOption(item)}
						>{item.label}
						</Option>
					)
				}
			</OptionsContainer>}
		</Wrapper>
		{value && value.length ?
			<SelectedWrapper className={'mb'}>
				{ value.map((userId:string,i:number):React.ReactNode=>
					<WithUserData key={userId} userId={userId} >
						{ (userData:User):JSX.Element =>
							userData ?<SelectedUser
								key={userData._id+i}
								user={userData}
								action={():void=>onUnselectOption(userData)}
							/>
								:
								<Spinner/>
						}
					</WithUserData>
				) }
			</SelectedWrapper>:null
		}
	</>;
}) ;


UserSearchRender.displayName = 'UserSearchRender';