import React, {useState} from 'react';
import { InterestRender } from './InterestRender';
import {EditProfileContext} from '../../index';
import {useOutletContext} from 'react-router-dom';
import {QueryResult, useQuery} from '@apollo/client';
import {query} from 'actions/queries';
import {Item} from 'interfaces';

export const Interests = (): JSX.Element => {

	const {profileData,updateProfile}:EditProfileContext=useOutletContext();

	const {data:interestsData}:QueryResult=useQuery(query('interestFindMany'));
	const [selectedItems,setSelectedItems] = useState<string[]>(profileData?.interest||[]);

	const onchange=({value}:any):void=>{
		console.log('interests:',{value});
		setSelectedItems([...value]);
		updateProfile({variables:{
			profileData:{interest:value}
		}});
	};
	let defaultInterests:Item[]=interestsData?.interestFindMany.map((i:any):Item=>
		({...i,id:i._id}))||[];
	return <InterestRender
		interestsOptions={defaultInterests}
		selectedInterests={selectedItems}
		onChange={onchange}
	/>;
};
