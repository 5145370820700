import React, {ReactNode} from 'react';
import {BoxTitle} from 'stylesOpus/typography';

type DetailProps = {
    title: string;
    children: ReactNode;
};

export const Detail = ({title,children}:DetailProps):JSX.Element => {
    
	return (
		<div className={'h-100 w-100 flex direction-column row-gap-half'}>
			<BoxTitle fontSize={'20px'} className={'pver-half'}>{title}</BoxTitle>
			<div className={'flex direction-column col-gap-half h-100'}>
				{children}
			</div>
		</div>
	);
};