import {getRemainDays, getRemainHours} from '../tools/date';

export type TRemainTime = {
    days: number
    hours: number

}

export const useRemainTime = (isoDate: string): TRemainTime => {

	const remainingDays:number = getRemainDays( new Date(),new Date(isoDate) );
	const remainingHours:number = getRemainHours( new Date(),new Date(isoDate));

	return {
		days: remainingDays,
		hours: remainingHours
	};
};