import React, {useContext, useState} from 'react';
import {CompanyProfileForm} from 'components';
import {UserContext} from 'context/userContext';
import {MutationFunction, MutationTuple, QueryResult, useMutation, useQuery} from '@apollo/client';
import {mutation} from 'actions/mutations';
import {FormField, FormHook} from 'hooks/useForm/interfaces';
import {uploadCompanyLogo} from 'actions/restActions';
import {useForm} from 'hooks/useForm/useForm';
import {query} from 'actions/queries';
import {CompanyProfile} from 'interfaces';

export  const EditCompanyProfile=():JSX.Element=>{

	const {user,refresh}:any=useContext(UserContext);
	const {data:profileData,loading:profileLoading}:QueryResult=useQuery(query('usersCompanyProfileFindOne'),{
		variables:{user:user._id}
	});
	const companyProfile:CompanyProfile = profileData?.usersCompanyProfileFindOne;

	const [updateProfile]:MutationTuple<MutationFunction, any> =useMutation(mutation('usersUpsertCompanyProfile'), {refetchQueries:['usersTalentProfileFindOne']});
	// file
	const [picture,setPicture]=useState<File>(null);
	const handleChangePicture = async(file:File):Promise<void> => {
		setPicture(file);
	};
	const  formFields:FormField[]=[
		{name:'name', required:true, default: companyProfile?.name},
		{name:'website', required:true, default: companyProfile?.website},
		{name:'phone', required:true, default:companyProfile?.phone},
		{name:'position'},
		{name:'linkedin'}
	];

	const handleSubmit=async(updateData:any):Promise<void> =>{
		delete updateData.position;
		delete updateData.linkedin;
		await updateProfile({variables:{
			companyProfileData:updateData
		}});

		console.log({picture});
		picture && await uploadCompanyLogo(picture);
		await refresh();
	};
	const  {errors,loading,hasChanges,...rest}:FormHook=useForm(formFields,handleSubmit,true);
	if(profileLoading)
		return <h1>Loading ... </h1>;
	return <CompanyProfileForm
		defaultImage={companyProfile?.logo?.url}
		submitLoading={loading}
		errors={errors}
		disabled={!hasChanges || !!errors.size}
		onSubmit={rest.handleSubmitForm}
		registerInput={rest.registerInput}
		registerList={rest.registerList}
		onChangeFile={handleChangePicture}
	/>;
};