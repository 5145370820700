import React from 'react';
import {GalleryContainer, GalleryName, Image, ImageOption, ImagePicker} from './styles';

interface PhotoGalleryProps {
	name:string
	images:string[],
	currentImage:number
	size?:string

	setCurrentImage:(index:number)=>void
}


export const PhotoGalleryRender=({name,images,currentImage,size='316px',setCurrentImage}:PhotoGalleryProps):JSX.Element=>{
	return <GalleryContainer size={size}>
		<Image image={images[currentImage]}>
			<div id="imagePicker">
				<ImagePicker>
					{images.map((image:string, index:number):JSX.Element=>
						<ImageOption
							key={image}
							active={index===currentImage}
							onClick={():void=>setCurrentImage(index)}
						/>)}
				</ImagePicker>
			</div>
		</Image>
		<GalleryName className="mt-2">{name}</GalleryName>
	</GalleryContainer>;
};