import React, {ReactNode} from 'react';
import styled from 'styled-components';
import {BodyText, InputLabel} from 'stylesOpus/typography';
import {Message} from '../../Message';
import {color} from 'styles/colors';

type FieldSectionProps = {
    label?:string
    helperText?:string
	className?:string
	error?:string
    children: ReactNode
}

export const Container:any = styled.div`
	h4 {
	  font-size: 10px;
	  color:#B0B0B2;
	  text-transform: uppercase;
	  font-weight: 800;
	}	
  
  	p {
	  font-size: 14px;
	  color:#7D7D7D;
	  font-weight: 300;
      margin-bottom: 0.5rem;
	}
`;

export const TextContainer:any = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 0.5rem;
	
	margin-bottom: 1.5rem;
`;

export const FieldSection = ({className,label,helperText,error,children}:FieldSectionProps): JSX.Element => {
  
	return (
		<Container className={className}>
			{ (label || helperText)&&
				<TextContainer>
					<InputLabel>{label}</InputLabel>
					<BodyText>{helperText}</BodyText>
				</TextContainer>
			}
			{children}
			{error &&
				<Message message={error} color={color.get('primary')}/>
			}
		</Container>
	);
};