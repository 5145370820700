import React, {useContext} from 'react';
import AlertsRender from './AlertsRender';
import {QueryResult, useQuery} from '@apollo/client';
import {query} from 'actions/queries';
import {Spinner} from 'components/ui';
import {UserContext, userContextInterface} from '../../context/userContext';
import {Navigate} from 'react-router-dom';

export const Alerts=():JSX.Element=>{
	const { user }:userContextInterface = useContext(UserContext);
	const {data:alertsData,loading:alertsLoading}:QueryResult =useQuery(query('culturalAlertFindMany'),
		{variables:{limit :20},
			fetchPolicy:'network-only'});
	console.log({alertsData:alertsData|| []});
	
	if (user.type.label === 'Agent')
		return <Navigate to={'/company/alerts'}/>;

	if(alertsLoading)
		return <div className={'w-100 h-100 flex justify-center align-center'}>
			<Spinner/>
		</div>;
	
	return <AlertsRender
		alerts={alertsData?.culturalAlertFindMany ||[]}
	/>;
};