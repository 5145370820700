import styled from 'styled-components';

interface IislandMarker {
	color?: string
}

export const GraphContainer:any = styled.div`
  flex-grow: 1;
  border: 1px solid #222;
`;

export const IslandMarker:any = styled.div<IislandMarker>`
	width: 10px;
  	height: 10px;
  	border-radius: 50%;
  	background: ${(props:any):string => props.color || 'red'};
`;

export const GraphMarkersDiv:any = styled.div`
	display: flex;
  	margin-top: 1rem;
  	position: absolute;
  	bottom: 2rem;
  	left: 2rem;
`;

export const UserTitle:any = styled.div`
  	position: absolute;
  	top: 3rem;
  	left: 3rem;
  	display: flex;
  	align-items: center;
	> h1 {
	  	font-size: 24px;
	  	margin-left: 1rem;
	}
  
  	> img{
	  	width: 50px;
	  	height: 50px;
	  	border-radius: 50%;
	  	object-fit: cover;
	  	flex-shrink: 0;
	}
`;