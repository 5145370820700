import {Item} from '../interfaces';

export const REACH_TIERS_OPTIONS :Item[] = [
	{label: '1k - 10k', id :JSON.stringify({min:1e3,max:1e4})},
	{label: '10k - 100k', id :JSON.stringify({min:1e4,max:1e5})},
	{label: '100k - 500k', id :JSON.stringify({min:1e5,max:5e5})},
	{label: '1M - 5M', id :JSON.stringify({min:1e6,max:5e6})},
	{label: '5M+', id : JSON.stringify({min:5e6,max:10e10}) }
];

export const TOPICS: Map<string,string>= new Map([
	['Activewear','Sportswear'],
	['Art & Design','Fine Art'],
	['Beauty & Cosmetics','Beauty'],
	['Beer, Wine & Spirits','Alcoholic Beverages'],
	['Business & Careers','Alcoholic Beverages'],

	['Camera & Photograpy','Photography'],
	['Cars & Motorbikes & Accessories','Automotive'],
	['Clothes, Shoes, Handbags & Accessories','Style & Fashion'],
	['Coffee, Tea & Beverages','Coffee & Tea'],
	['Electronics & Computers','Coffee & Tea'],

	['Fitness & Yoga','Fitness and Exercise'],
	['Friends, Family & Relationships','Family and Relationships'],
	['Gaming','Video Gaming'],
	['Healthcare & Medicine','Health and Medical Services'],
	['Healthy Lifestyle','Healthy Living'],

	['Home Decor, Furniture & Garden','Home & Garden'],
	['Jewellery & Watches','Jewelry and Watches'],
	['Luxury Goods','Designer Clothes'],
	['Luxury Goods','Luxury Cars'],
	['Music','Music and Audio'],

	['Pets','Pets'],
	['Restaurants, Food & Grocery','Food & Drink'],
	['Shopping & Retail','Shopping'],
	['Sports','Sports'],
	['Television & Film','Movies'],

	['Tobacco & Smoking','Cigars'],
	['Travel, Tourism & Aviation','Travel'],
	['Wedding','Wedding Services and Supplies ']
]);