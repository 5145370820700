import React, {useContext, useEffect} from 'react';
import {Avatar, Button, LinkButton, LogoutButton} from 'components/ui/';
import {User} from 'interfaces';
import {Range} from 'components';
import {color} from 'styles/colors';
import {HelloAvatar, Paragraph} from '../../../../styles/styledComponents/containers';
import {UserContext} from '../../../../context/userContext';
import {NavigateFunction, useNavigate} from 'react-router-dom';


interface HelloRenderProps{
	user:User;
	percentage:number
}

export default function HelloRender({user,percentage}:HelloRenderProps):JSX.Element{
	const {logout}:any=useContext(UserContext);
	useEffect(():any=>{
		console.log('Active', user);
	},[user]);
	const navigate: NavigateFunction=useNavigate();
	const logoutAction =():void=>{
		logout();
		navigate('/');
	};

	return (
		<div className="w-100 w-400px">
			<h1 className="center mb-2 mt-2">Hello {user.firstName}!</h1>
			<HelloAvatar>
				<Avatar size={'9.5rem'} fontSize={'2.2rem'} user={user}/>
				<Range className="mt-2 mb" color={color.get('primary')} max={100} value={percentage}/>
				<p>Your Profile is <strong>{percentage}% complete</strong></p>
			</HelloAvatar>

			<Paragraph className="center mb-2 mt-2">
				Your account is being verified by our Cultural Intelligence (CI) Team. We will validate your social media handles and get back to you soon. If you have any questions please contact us at <a href="mailto:members@opusintel.co" style={{color:'#FF5656'}}>members@opusintel.co</a>
			</Paragraph>

			<div className="center flex direction-column align-center">
				{user.active ?
					<LinkButton
						to={'/dashboard/opportunities'}
						label={'Dashboard'}
					/>
					:
					<Button onClick={logout} variant={'secondary'}>
						Logout
					</Button>
				}
			</div>

		</div>
	);
}
