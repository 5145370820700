import React from 'react';
import {IconProps} from 'interfaces';

export const BrandsCubeIcon=({color='white'}:IconProps):JSX.Element=>
	<svg
		width={20}
		height={22}
		viewBox="0 0 20 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M19 14.9979V6.99795C18.9996 6.64722 18.9071 6.30276 18.7315 5.99911C18.556 5.69546 18.3037 5.44331 18 5.26795L11 1.26795C10.696 1.09241 10.3511 1 10 1C9.64893 1 9.30404 1.09241 9 1.26795L2 5.26795C1.69626 5.44331 1.44398 5.69546 1.26846 5.99911C1.09294 6.30276 1.00036 6.64722 1 6.99795V14.9979C1.00036 15.3487 1.09294 15.6931 1.26846 15.9968C1.44398 16.3004 1.69626 16.5526 2 16.7279L9 20.7279C9.30404 20.9035 9.64893 20.9959 10 20.9959C10.3511 20.9959 10.696 20.9035 11 20.7279L18 16.7279C18.3037 16.5526 18.556 16.3004 18.7315 15.9968C18.9071 15.6931 18.9996 15.3487 19 14.9979Z"
			stroke={color}
			strokeWidth="1.8"
			strokeLinecap="round"
			strokeLinejoin="round"

		/>
		<path
			d="M13.6015 12.6003V9.39967C13.6014 9.25935 13.5643 9.12154 13.4941 9.00005C13.4239 8.87857 13.3229 8.77769 13.2014 8.70753L10.4008 7.1072C10.2792 7.03697 10.1412 7 10.0008 7C9.8603 7 9.72232 7.03697 9.60068 7.1072L6.80011 8.70753C6.67859 8.77769 6.57765 8.87857 6.50743 9.00005C6.43721 9.12154 6.40017 9.25935 6.40002 9.39967V12.6003C6.40017 12.7406 6.43721 12.8785 6.50743 12.9999C6.57765 13.1214 6.67859 13.2223 6.80011 13.2925L9.60068 14.8928C9.72232 14.963 9.8603 15 10.0008 15C10.1412 15 10.2792 14.963 10.4008 14.8928L13.2014 13.2925C13.3229 13.2223 13.4239 13.1214 13.4941 12.9999C13.5643 12.8785 13.6014 12.7406 13.6015 12.6003Z"
			fill={color}
		/>
	</svg>

;
