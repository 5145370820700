import React from 'react';
import {BoxTitle, BodyText, ErrorText} from '../../../stylesOpus/typography';
import {ContentBox} from '../../../stylesOpus/layout';
import {Input, Button, CircleHero} from '../../../stylesOpus/ui';

interface PasswordProps {
	password : string
	setPassword : (pass:string) => void
	loading : boolean
	setSubmit : (subm:boolean) => void
	error? : string
}

export default function PasswordRender({password, setPassword, setSubmit, error, loading}:PasswordProps):JSX.Element {

	return (
		<ContentBox borderRadius={'0'} background={'grey_1000'}>



			<div className="max-w-500px mhor-auto">
				<CircleHero>
					<video autoPlay={true} controls={false} loop={true} playsInline={true} muted={true} preload="auto">
						<source src="https://opus-strapi.s3.us-west-2.amazonaws.com/OPUS_Intel_Opportunity_0bd7a1adeb.mp4?updated_at=2023-09-07T20:13:12.679Z" type="video/mp4" />
					</video>
				</CircleHero>
				<BoxTitle className='mb-quarter' fontSize='16px' textAlign='center'>
					View Your Opportunity
				</BoxTitle>
				{/*<BodyText textAlign='center'>*/}
				{/*	Lorem ipsum dolor sit amet consectetur. In auctor enim lobortis diam varius. Eu tempor enim viverra velit diam nec sagittis cursus.*/}
				{/*</BodyText>*/}

				<div className="mver">
					<Input margin='0 0 40px' type='text' placeholder='Enter Password Here' value={password} onChange={(e:any):any=>setPassword(e.target.value)} />
					<Button onClick={ ():void=>setSubmit(true) }>
						Submit
					</Button>
				</div>
				{error &&
					<ErrorText>
						{error === 'incorrect email or password' ? 'Wrong Password' : error}
					</ErrorText>
				}
				{loading &&
					<BoxTitle textAlign='center'>
						Loading ...
					</BoxTitle>
				}
			</div>
		</ContentBox>
	);
}