import React from 'react';
import {TagProps} from 'interfaces';
import {StyledTag} from 'styles/ui';
import {CloseIcon, DeleteTagIcon} from 'icons';


export default function TagRender({category,theme='opus',filled,selected,className,deletable,rounded,isClickable,onClickTag}:TagProps):JSX.Element{

	return (
		<StyledTag
			isClickable={isClickable}
			className={className}
			selected={selected}
			filled={filled}
			rounded={rounded}
			onClick={():any=>onClickTag(category)}
			color={'#cccccc'}
			borderColor="#666666"
			localTheme={theme}
		>
			<span>{category.label}</span>
			{selected && <span className='ml-half flex align-center'><CloseIcon width={16} height={16} /></span>}
			{deletable && <DeleteTagIcon/>}
		</StyledTag>
	);
}
