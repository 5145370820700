import React, {ReactNode} from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

type TArrowItem = {
    flexGrow?:number
    content?:ReactNode
}

type FlexArrowProps = {
    items?:TArrowItem[]
    flexGap?:string

    height?:string
    flexDirection?:'row'|'column' | 'row-reverse' | 'column-reverse'
    flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse'
    justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly' | 'start' | 'end' | 'left' | 'right'
    alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch'
}

const FlexContainer:any = styled.div<FlexArrowProps>`
  display: flex;
  width: 100%;
  
  height: ${({height}:any):string=>height ? height : 'auto'};
  gap: ${({flexGap}:any):string=>flexGap ? flexGap : '0'};
  flex-direction: ${({flexDirection}:any):string=>flexDirection ? flexDirection : 'row'};
    flex-wrap: ${({flexWrap}:any):string=>flexWrap ? flexWrap : 'nowrap'};
    justify-content: ${({justifyContent}:any):string=>justifyContent ? justifyContent : 'flex-start'};
    align-items: ${({alignItems}:any):string=>alignItems ? alignItems : 'unset'};
`;

const Childcontainer:any = styled.div<{flexGrow:number}>`
    flex-grow: ${({flexGrow}:any):number=>flexGrow ? flexGrow : 'unset'};
    height: 100%
  
`;
export const FlexArrow = ({items=[], ...props}:FlexArrowProps):JSX.Element => {

	return (
		<FlexContainer {...props}>
			{ items.map( (child:TArrowItem):ReactNode=>
				<Childcontainer flexGrow={child.flexGrow}  key = {uuidv4()}>
					{child.content}
				</Childcontainer>
			)}
		</FlexContainer>
	);
};