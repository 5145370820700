import React, {useState} from 'react';
import {PictureInputRender} from './PictureInputRender';
import {FileInputHook, useFileInput} from '../../hooks/useFileInput';

interface PictureInputProps {
	size?:string
	label?:string
	rounded?:string
	plus?:boolean
	disabled?:boolean
	fontSize?:string
	onChange?:(file:File)=>void
	accept?:string
	defaultImage?:string
}

export const PictureInput=({onChange,defaultImage,accept,disabled=false,...props}:PictureInputProps):JSX.Element=>{

	const [image,setImage]=useState<string|null>(defaultImage);
	const onChangeFile=(file:File):void=>{
		if(file) {
			setImage(window.URL.createObjectURL(file));
		}
		onChange && onChange(file);
	};

	const {handleChangeFile}:FileInputHook=useFileInput({onChange:onChangeFile});

	return <PictureInputRender
		{...props}
		disabled={disabled}
		accept={accept}
		image={image||defaultImage}
		onChange={handleChangeFile}
	/> ;
};