import styled from 'styled-components';
import {mediaQuery} from 'styles/mediaQueries';

export const CardsWrapper:any=styled.div`
  width: 100%;
  display: flex;
  gap:1rem;
  max-height: 300px;
  
  ${mediaQuery('tablet')}{
	display: block;
	max-height: none;
  }

`;

export const PrimaryCards:any=styled.div`
  display: flex;
  gap:1rem;
  width: 100%;

  ${mediaQuery('tablet')}{
    margin-bottom: 1rem;
    width: 100%;
    >div{
      flex-grow: 1;
    }
  }
  
  ${mediaQuery('mobile')}{
    
  }
`;

export const SocialNetWorksCards:any=styled.div`
  flex-grow: 1;
  display: flex;
  gap:1rem;
  ${mediaQuery('tablet')}{
    width: 100%;
    margin-bottom: 1rem;
  }
`;

export const Charts:any=styled.div`
	width: 100%;
`;
