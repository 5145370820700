import styled from 'styled-components';
import {color} from 'styles/colors';

export interface RoundImageStyleProps{
	size:string;
	rounded?:string
}

export const ImageWrapper:any=styled.div`
	border-radius: ${({rounded}:RoundImageStyleProps):string=>rounded ? rounded : '100%'};
  	overflow: hidden;
  	width:${({size}:RoundImageStyleProps):string=>size};
  	height:${({size}:RoundImageStyleProps):string=>size};
  >img{
	object-fit: cover;
	object-position: center;
	width: 100%;
	height: 100%;
  }
  >picture{
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
`;

export const RoundImage:any=styled.div`
  	width: 100%;
  	height: 100%;
	background-size: cover;
  	background-position: center;
  	background-image: ${({src}:any):string=>`url("${src}")`};
  background-color: ${color.get('neutral-500')};
`;

