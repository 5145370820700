import React from 'react';
import {BoxTitle} from '../../../../stylesOpus/typography';

export const AboutRender = ():JSX.Element => {
	return (
		<>

	
			<BoxTitle>About ØPUS</BoxTitle>
			<div className="max-w-700px pb-4 m-auto">
				<p className="mt lh-150">

					{`Welcome to ØPUS Intelligence, the home of some of the most talented marketers and technologists on the planet. We’ve spent the last 30 years building some of the world's most explosive companies, services, and campaigns. And we’ve spent the last 5 years building a powerful knowledge base with the same crew, plus a few world-class engineers and data scientists. We even employ a few A.I. Assistants that never sleep so that our amazing humans can enjoy life and recharge their batteries.`}
				</p>

				<p className="mt lh-150">
					{`Our mission is to revolutionize how business connects the dots between their products, their consumers, and human emotion with technology that's fun to use.`}
				</p>


				<p className="mt lh-150">
					{`ØPUS Intelligence is currently in beta and is being used by a few select brands, sports teams, agents, and venture partners.`}
				</p>
			</div>

		

		</>
	);
};
