import React from 'react';

export const SpotifyIcon=():JSX.Element=>{
	return <svg
		width={40}
		height={40}
		viewBox="0 0 40 40"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect width={40} height={40} rx={15} fill="#00D95F"/>
		<path
			d="M20.4956 9C14.1569 9 9 14.1446 9 20.4681C9 26.7956 14.1569 31.9434 20.4956 31.9434C26.8391 31.9434 32 26.7956 32 20.4681C32 14.1446 26.8391 9 20.4956 9ZM14.6618 24.682C15.9946 24.2773 17.3611 24.0721 18.7231 24.0721C21.0364 24.0721 23.3201 24.6569 25.3284 25.7638C25.4479 25.8288 25.5565 26.0435 25.6052 26.3107C25.6539 26.5779 25.6345 26.8527 25.5563 26.9956C25.4576 27.179 25.2573 27.2976 25.0459 27.2976C24.9412 27.2976 24.8491 27.2741 24.757 27.2245C22.9134 26.2136 20.827 25.6792 18.7231 25.6792C17.4521 25.6792 16.2043 25.8669 15.0154 26.2366C14.9575 26.2541 14.898 26.263 14.8384 26.263C14.5781 26.2631 14.3521 26.0977 14.2764 25.8522C14.174 25.5167 14.3857 24.772 14.6618 24.682ZM14.1603 20.4569C15.6458 20.054 17.181 19.8497 18.7232 19.8497C21.4956 19.8497 24.2487 20.5189 26.6865 21.7859C26.8574 21.8724 26.9834 22.0191 27.041 22.1989C27.0996 22.381 27.0819 22.5768 26.9906 22.7516C26.773 23.1763 26.5188 23.4617 26.3577 23.4617C26.2435 23.4617 26.1333 23.4344 26.0236 23.3797C23.7599 22.2038 21.3037 21.6076 18.7231 21.6076C17.2877 21.6076 15.8794 21.7959 14.5387 22.1669C14.4763 22.1837 14.4123 22.1922 14.3486 22.1922C14.0261 22.1923 13.7418 21.975 13.6577 21.6647C13.5499 21.2611 13.7961 20.5556 14.1603 20.4569ZM14.0319 17.6964C13.9638 17.7131 13.8942 17.7216 13.8249 17.7216C13.4352 17.7216 13.0974 17.4589 13.0036 17.0831C12.886 16.6075 13.1843 16.055 13.6164 15.9464C15.2966 15.5347 17.0147 15.326 18.7231 15.326C22.0303 15.326 25.1976 16.0677 28.1381 17.5309C28.3423 17.6314 28.4946 17.8049 28.567 18.0194C28.6394 18.2341 28.6234 18.4652 28.5223 18.6693C28.3846 18.9443 28.0871 19.2403 27.7591 19.2403C27.6326 19.2403 27.5028 19.2107 27.3878 19.1568C24.6867 17.8087 21.7715 17.1252 18.7232 17.1252C17.1566 17.1253 15.5783 17.3174 14.0319 17.6964Z"
			fill="white"
		/>
	</svg>;
};
