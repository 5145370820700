import React from 'react';
import {IconProps} from '../../interfaces';



export const YoutubeOutlineIcon=({size=24,color='#FEFEFE'}:IconProps):JSX.Element=>
	<svg width={size} height={size} viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M11.083 0.305615C11.6081 0.446678 12.0234 0.862039 12.1645 1.38711C12.4231 2.34321 12.4309 4.32596 12.4309 4.32596C12.4309 4.32596 12.4309 6.31654 12.1723 7.2648C12.0313 7.78988 11.6159 8.20523 11.0908 8.3463C10.1426 8.60492 6.32597 8.60492 6.32597 8.60492C6.32597 8.60492 2.50939 8.60492 1.56112 8.3463C1.03605 8.20523 0.620692 7.78988 0.479627 7.2648C0.221008 6.3087 0.221008 4.32596 0.221008 4.32596C0.221008 4.32596 0.221008 2.34321 0.47179 1.39495C0.612854 0.869874 1.02821 0.454518 1.55329 0.313454C2.50155 0.0548321 6.31813 0.0469971 6.31813 0.0469971C6.31813 0.0469971 10.1347 0.0469971 11.083 0.305615ZM8.26953 4.32596L5.10341 6.1598V2.49212L8.26953 4.32596Z" fill={color} />
	</svg>
;
