import React from 'react';
import {CheckBoxButtonProps} from '../RadioButton/radioButton.styles';
import styled from 'styled-components';
import {Item} from '../../../interfaces';
// checbox component

const Container:any = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const Box: any = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 0.25rem;
  border: 1px solid #FFF;
  cursor: pointer;
  >div{
	width: 80%;
	height: 80%;
	background-color: white;
    border-radius: 0.25rem;
  }
`;

const Label:any = styled.div`
  color: #626579;
  font-family: Urbanist;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 137.5%; /* 1.20313rem */
  letter-spacing: 0.025rem;
`;

export const Checkbox = ({defaultSelected, item, onClick}:CheckBoxButtonProps):JSX.Element => {
	const [selected, setSelected] = React.useState<boolean>(defaultSelected);
	const handleClick = ():void => {
		setSelected((prev:boolean):boolean=>!prev);
		onClick && onClick(item);
	};
	return(
		<Container>
			<Box onClick={handleClick}>
				{selected && <div/>}

			</Box>
			<Label>{item.label}</Label>
		</Container>
	);
};