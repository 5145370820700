import React from 'react';

export const RocketIcon=():JSX.Element=>
	<svg
		width={55}
		height={55}
		viewBox="0 0 55 55"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_1_1001)">
			<path
				d="M21.2728 28.9372C20.7509 23.2166 22.7726 17.385 27.0144 13.1925C27.2721 12.9378 27.7279 12.9378 27.9856 13.1925C32.2274 17.385 34.2491 23.2101 33.7272 28.9372C35.1939 30.4973 36 32.5093 36 34.6506C36 36.8121 35.1675 38.8692 33.6545 40.4365C33.2845 40.8218 32.3397 40.6454 32.4916 39.7965C32.5709 39.3524 32.67 38.9214 32.67 38.497C32.67 37.439 32.3463 36.4334 31.745 35.5844C31.6459 35.6367 31.5336 35.6693 31.4147 35.6693H23.5853C23.4664 35.6693 23.3541 35.6367 23.255 35.5844C22.6537 36.4334 22.33 37.439 22.33 38.497C22.33 38.9214 22.4423 39.3459 22.5084 39.7965C22.6471 40.7369 21.656 40.7695 21.3455 40.4365C19.8391 38.8627 19 36.8121 19 34.6506C19.0066 32.5093 19.8061 30.4908 21.2728 28.9372ZM27.5033 14.6553C26.2744 15.9614 25.2701 17.4307 24.4905 18.9915H30.5095C29.7365 17.4242 28.7322 15.9614 27.5033 14.6553ZM33.9782 37.5768C34.4011 36.6691 34.6191 35.6765 34.6191 34.6512C34.6191 33.2668 34.2161 31.9411 33.4629 30.8049C33.4563 30.8571 32.7361 33.5803 32.4586 34.279C33.2845 35.2194 33.8065 36.3557 33.9782 37.5768ZM24.0412 34.3051H30.9654C32.9079 29.7992 32.8881 24.7643 31.124 20.3432H23.8826C22.1185 24.7643 22.0987 29.7992 24.0412 34.3051ZM22.5414 34.279C22.1582 33.306 21.5437 30.844 21.5371 30.8049C20.7839 31.9346 20.3809 33.2603 20.3809 34.6512C20.3809 35.6765 20.6055 36.6691 21.0218 37.5768C21.2002 36.355 21.7221 35.2187 22.5414 34.279Z"
				fill="white"
			/>
			<path
				d="M30.2849 36.8044C30.6681 36.8044 30.972 37.1113 30.972 37.4836V40.8989C30.972 41.2777 30.6615 41.5781 30.2849 41.5781C29.9083 41.5781 29.5978 41.2712 29.5978 40.8989V37.4836C29.5978 37.1048 29.9083 36.8044 30.2849 36.8044Z"
				fill="white"
			/>
			<path
				d="M27.5034 36.8044C27.695 36.8044 27.8668 36.8828 27.9923 37.0068C27.9923 37.0068 27.9923 37.0068 27.9989 37.0134C28.0782 37.0917 28.1377 37.1962 28.1707 37.3138C28.1839 37.3725 28.1971 37.4313 28.1971 37.4966V44.3208C28.1971 44.3731 28.1905 44.4253 28.1773 44.4775C28.1046 44.7779 27.8338 45 27.51 45C27.1863 45 26.9154 44.7779 26.8427 44.4775C26.8295 44.4253 26.8229 44.3731 26.8229 44.3208V37.4836C26.8229 37.4183 26.8295 37.3595 26.8493 37.3007C26.922 37.014 27.1863 36.8044 27.5034 36.8044Z"
				fill="white"
			/>
			<path
				d="M24.7217 36.8044C25.1049 36.8044 25.4088 37.1113 25.4088 37.4836V40.8989C25.4088 41.2777 25.0983 41.5781 24.7217 41.5781C24.3451 41.5781 24.0345 41.2712 24.0345 40.8989V37.4836C24.0279 37.1048 24.3385 36.8044 24.7217 36.8044Z"
				fill="white"
			/>
		</g>
		<rect x="0.5" y="0.5" width={54} height={54} rx="19.5" stroke="white"/>
		<defs>
			<clipPath id="clip0_1_1001">
				<rect
					width={17}
					height={32}
					fill="white"
					transform="matrix(-1 0 0 1 36 13)"
				/>
			</clipPath>
		</defs>
	</svg>

;
