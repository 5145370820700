import React from 'react';
import {Route} from './interfaces';
import {AuthRoute, Layout} from '../containers';
import {Favorites} from '../pages';

export const favoritesRoutes:Route[] =[
	{
		path:'/favorites',
		element: <AuthRoute><Layout styleTheme={'opusV2'}/></AuthRoute>,
		children:[
			{
				path: '',
				element: <Favorites/>
			},
			{
				path: ':tab',
				element: <Favorites/>
			}
		]
	}
];