import React, {useEffect, useState} from 'react';
import {HotListDetailRender} from './HotListDetailRender';
import {Params, useParams} from 'react-router-dom';
import {SetterOrUpdater, useSetRecoilState} from 'recoil';
import {BreadCrumbAtom, TCrumb} from 'context/recoil/store';
import {opusContent} from 'actions/strapiActions';
import {TStrapiItem, TStrapiMedia, TStrapiTag} from 'interfaces/strapi';
import {AxiosResponse} from 'axios';
import {Spinner} from 'components/ui';
import {TArticle} from 'components/ArticleCard/ArticleCard';

export const HotListDetail = ():JSX.Element => {

	const {category,item}:Params=useParams();
	const setBreadCrumb: SetterOrUpdater<TCrumb[]>=useSetRecoilState(BreadCrumbAtom);
	const [hotList, setHotList]=useState<TStrapiItem>();

	const [loading, setLoading]=useState<boolean>(true);
	const [error, setError]=useState<string>('');

	const [articles, setArticles] = useState<TArticle[]>(null);


	const getHotListItem = async():Promise<any> => opusContent(`hotlists/${item}`, {
		'populate[0]': 'cover',
		'populate[1]': 'tags',
		'populate[2]': 'media'
	});

	const getArticles= async(category:{name:string, id:string|number}):Promise<any> =>
		opusContent('hotlists', {
			'filters[tags]': category.id,
			'populate[0]':'tags',
			'populate[1]': 'cover'
		});
	const itemData: TStrapiItem['attributes']= hotList?.attributes;
	const getdata= async():Promise<void> => {
		setLoading(true);
		try {
			const res:AxiosResponse= await getHotListItem();
			setHotList(res.data.data);
			////
			let hotlistData : TStrapiItem= res.data.data;
			const articlesResponse:AxiosResponse = await getArticles({
				name: hotlistData?.attributes?.title,
				id: hotlistData?.attributes?.tags?.data[0]?.id
			});
			const items :TStrapiItem[] = articlesResponse.data.data;
			setArticles(items?.map((item:TStrapiItem):TArticle => ({
				title: item?.attributes?.title,
				subtitle: item?.attributes?.subtitle,
				date: item?.attributes?.publishDate,
				picture: `${item?.attributes?.cover?.data?.attributes?.url}`,
				link: `/hotlist/${category ? category + '/' : ''}${item.id}/detail`
			})));

		}
		catch (e) {
			setError(e.message);
		}
		finally {
			setLoading(false);
		}

	};

	useEffect(():void => {
		const tags: TStrapiTag[]= itemData?.tags?.data?.map((tag:TStrapiTag):TStrapiTag=>tag);
		if (hotList && tags?.length){
			const title:string= hotList.attributes.title;
			let bc:TCrumb[] = [];
			if (category){
				bc = [
					{id: 'hotlist', label: 'Hotlist', path:'/hotlist'},
					{id: category, label: tags[0]?.attributes.title, path:`/hotlist/${tags[0]?.attributes.slug}`},
					{id: title, label: title, path:`
					/hotlist/${category}/${hotList.id}/detail`}
				];
			}
			else{
				bc = [
					{id: 'hotlist', label: 'Hotlist', path:'/hotlist'},
					{id: title, label: title, path:`/hotlist/${category}/${hotList.id}/detail`}
				];
			}
			setBreadCrumb(bc);
		}
	}, [hotList]);

	useEffect(():void => {
		if(loading){
			getdata();
		}
	}, [loading]);

	useEffect(():void => {
		setLoading(true);
	}, [item]);

	if(loading){
		return <div className={'w-100 h-100 flex justify-center align-center'}> <Spinner/></div>;
	}

	if(error){
		return <div className={'w-100 h-100 flex justify-center align-center'}>
			<div>Error: {error} </div>
		</div>;
	}


	const pictures: string[]= itemData.media?.data?.map((media:TStrapiMedia):string=>media.attributes.url) || [];


	return (
		loading ? <div>Loading...</div> :
			<HotListDetailRender
				loading={loading}
				error={error}

				title={itemData?.title}
				subtitle={itemData?.subtitle}
				picture={itemData?.cover?.data?.attributes?.url}
				pictures={pictures}
				publishedAt={itemData.publishDate}
				body={itemData?.body}
				embed={itemData?.embed}
				tags={itemData?.tags?.data?.map((tag:TStrapiTag):TStrapiTag=>tag)}
				tactics={itemData?.tactics}

				relatedTalent={itemData?.relatedTalent}
				featuredTalent={itemData?.featuredTalent}
				articles={articles}
			/>
	);
};
