import React, {useEffect, useState} from 'react';
import {SetterOrUpdater, useSetRecoilState} from 'recoil';
import {BreadCrumbAtom, TCrumb} from '../../context/recoil/store';
import {Params, useParams} from 'react-router-dom';
import {opusContent} from '../../actions/strapiActions';
import {FeaturedTagI, TStrapiItem, TStrapiTag} from '../../interfaces/strapi';
import {AxiosResponse} from 'axios';
import {CategoryBoxProps} from '../../components/ui/CategoryBox/CategoryBox';
import {TArticle} from '../../components/ArticleCard/ArticleCard';
import {LatestDetailRender} from './LatestDetailRender';
import {FeaturedDetailRender} from './FeaturedDetailRender';

export const FeaturedDetail = ():JSX.Element => {
	//Breadcrumb
	const {index}:Params = useParams();
	const setBreadCrumbs: SetterOrUpdater<TCrumb[]>=useSetRecoilState(BreadCrumbAtom);
	const [articles, setArticles] = useState<TArticle[]>(null);

	const [loading, setLoading] = useState<boolean>(true);
	const [home, setHome] = useState<FeaturedTagI>();

	// useEffect(():void => {
	// 	setBreadCrum([
	// 		{id: 'hotlist', label: 'hotlist', path:'/hotlist'}
	// 	]);
	// }, []);

	useEffect(():void => {
		if(loading){
			getHomeData();
		}
	}, [loading]);

	const getHomeData = async():Promise<any> => {
		const h:any = await opusContent('hotlist-home', {
			'populate[0]' : 'tagRow',
			'populate[1]' : 'Feed',
			'populate[2]' : 'tagRow.cover',
			'populate[3]' : 'tagRow.tags',
			'populate[4]' : 'Feed.tags'
		});
		setLoading(false);
		const tr:FeaturedTagI = h?.data?.data?.attributes?.tagRow.find((e:FeaturedTagI):boolean=>e.id==index);
		setHome(tr);
	};

	const getData = async():Promise<void> => {
		console.log('Getting Data');
		try {

			const articlesResponse:AxiosResponse = await opusContent('hotlists', {
				'sort':'publishDate:desc',
				'pagination[pageSize]': '100',
				'populate[0]': 'cover',
				'filters[tags]' : home?.tags?.data?.map(({id}:TStrapiTag):string|number=>id) || []
			});
			const items :TStrapiItem[] = articlesResponse.data.data;
			setArticles(items.map((item:TStrapiItem):TArticle => ({
				title: item?.attributes?.title,
				subtitle: item?.attributes?.subtitle,
				date: item?.attributes?.publishDate,
				picture: `${item?.attributes?.cover?.data?.attributes?.url}`,
				link: `/hotlist/latest/${item?.id}/detail`
			})));
		}
		catch (error) {
			console.error(error);
		}
	};

	useEffect(():void => {
		if(home?.title){
			setBreadCrumbs([
				{id: 'hotlist', label: 'hotlist', path:'/hotlist'},
				{id: 'featured', label: home?.title, path:`/hotlist/home/${index}`}
			]);
		}
		if (home?.tags?.data?.length > 0){
			getData();
		}

	}, [home]);

	return (
		<FeaturedDetailRender articles={articles} featured={home}/>
	);
};
