import React from 'react';
import {IconProps} from 'interfaces';

export const OpusIcon=({size=27,color='#fff'}:IconProps):JSX.Element=>{
	return <svg
		width={size}
		height={size}
		viewBox="0 0 27 27"
		fill="none"
		color={'blue'}
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M23.9839 4.9958L26.963 2.00951L24.9419 0.0319496L21.981 3.0001C19.8474 1.27291 17.1623 0.173463 14.205 0.0174355C6.75925 -0.370819 0.409294 5.34777 0.0174107 12.7935C-0.164017 16.2733 0.989861 19.5172 3.0291 22.0209L0.0355534 25.0254L2.05665 26.9666L5.01755 23.9985C7.15476 25.7293 9.83625 26.8251 12.7935 26.9811C20.2393 27.373 26.5892 21.6508 26.9811 14.205C27.1626 10.7325 26.0159 7.49587 23.9839 4.9958ZM5.03932 13.0548C5.28243 8.39212 9.27746 4.79623 13.9401 5.03934C15.6056 5.12643 17.1333 5.69248 18.3996 6.59599L6.6141 18.425C5.5328 16.9192 4.93772 15.0505 5.03932 13.0584V13.0548ZM13.0548 21.9556C11.3929 21.8685 9.86528 21.3025 8.60255 20.4026L20.3953 8.58806C21.4694 10.0939 22.0644 11.9553 21.9592 13.9402C21.7161 18.6028 17.7211 22.1987 13.0584 21.9556H13.0548Z"
			fill={color}
		/>
	</svg>;
};