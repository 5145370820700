import React, {useEffect, useState} from 'react';
import {QueryResult, useQuery} from '@apollo/client';
import {query} from '../../../actions/queries';
import {Spinner} from '../../ui';
import {CategoryScore} from '../FeaturedTalent/components';

interface AuthorityScoresI {
	talentId: string
}

export const AuthorityScores=({talentId}:AuthorityScoresI):JSX.Element =>{

	const [authScores, setAuthScores] = useState<any>([]);
	const {data, loading, error}:QueryResult = useQuery(query('talentAuthorityFindOne'),{
		variables : {
			user: talentId
		},
		fetchPolicy: 'no-cache'
	});

	useEffect(():void=>{
		if(data?.talentAuthorityFindOne){
			let d:any = [...data.talentAuthorityFindOne];
			let dd:any = d.filter((e:any):any => !e._id.hidden);
			dd.sort((a:any,b:any):any => b.value - a.value);
			const ddd:any = dd.slice(0,5);
			setAuthScores(ddd);
		}

	},[data]);

	return (
		<div>
			{loading ?
				<Spinner/> :
				<div className='grid5 col-gap-3'>
					{authScores && authScores.length > 0 && authScores?.map(({_id, value}:any):JSX.Element=>{
						return (
							<CategoryScore
								percentage={value*100 || 0}
								label={_id?.title ? _id?.title : _id?.label}
								key={_id._id}
							/>
						);
					})}
				</div>
			}
		</div>
	);
};
