import React, {useEffect} from 'react';
import {ModalContainer} from 'components/ModalContainer';
import {Card} from 'components';
import {Button, LinkButton} from 'components/ui';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {BodyText} from '../../../stylesOpus/typography';

type CampaignSuccessModalProps = {
    show?: boolean
    searchState: any
}

const Content:any= styled.div`
	width: fit-content;
	display:flex;
	align-items: center;
	font-family: 'Urbanist', sans-serif;
`;

const CardContainer:any=styled(Card)`
	max-width: 42rem;
`;
export const CampaignSuccessModal = ({show = false, searchState}: CampaignSuccessModalProps):JSX.Element => {
	const navigate:NavigateFunction = useNavigate();
	const goToDiscovery = ():void=>navigate('/discover',{
		state : searchState
	});

	useEffect(():void=>{
		console.log('Search State', searchState);
	},[searchState]);
    
	return <ModalContainer
		show={show}
	>
		<CardContainer >
			<div className={'flex direction-column align-center'}>
				<h4 className="mt">Your opportunity has been successfully created 🎉</h4>
				<BodyText className={'mver-2 phor'} textAlign={'center'}>
					Your opportunity has been successfully created. Click “View Matches” to see the Talent suggested in the Discovery Dashboard. Click “View Opportunity” to see your draft and published Opportunities.
				</BodyText>
				<Content className={'w-100 col-gap flex align-center '}>
					<LinkButton
						width={'13rem'}
						label={'Go Home'}
						to={'/campaigns'}
						variant={'secondary-white'}
					/>
					<Button
						width={'13rem'}
						onClick={goToDiscovery}
						label={'View Matches'}
						variant={'white'}
					/>
				</Content>

			</div>
		</CardContainer>
	</ModalContainer>;
};