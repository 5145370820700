import styled from 'styled-components';
import {color} from 'styles/colors';
import {Link} from 'react-router-dom';


interface LinkTabProps {
	$active?:boolean
}

export const Content:any=styled.section`
  width: 100%;
  padding: 20px 24px ;
  position: relative;
 
`;

export const LinkTab:any=styled(Link)<LinkTabProps>`
	font-family: 'Urbanist', sans-serif;
	font-size: 1rem;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: 0.1rem;
	padding-bottom: 6px;
	${({$active}:LinkTabProps):string=>$active?
		`border-bottom:solid 4px white;
		 color: ${color.get('white')};
		` :
		`border-bottom:solid 4px transparent};
		color: ${color.get('neutral-400')};
		`
}

`;