import React, {useContext} from 'react';
import {useAddToFavorites} from 'hooks';
import {AddToFavorites, TLike} from 'hooks/useAddToFavorites';
import {Button, Spinner} from '../ui';
import styled from 'styled-components';
import {BodyText} from 'stylesOpus/typography';
import {UserContext, userContextInterface} from '../../context/userContext';
import {QueryResult, useQuery} from '@apollo/client';
import {query} from '../../actions/queries';
import {BookmarkFilledIcon} from '../../icons/BookmarkFilledIcon';
import {BookmarkLineIcon} from '../../icons/BookmarkLineIcon';

export type AddToFavoriteProps = {
    culturalAlertId?: string
	talentId?: string
	style?: ButtonProps
	label?: string
}
type MakeStyled<T> = {
	[K in keyof T as `$${string & K}`]: T[K];
};
type ButtonProps = MakeStyled<React.CSSProperties>
export const StyledButton:any = styled(Button)<ButtonProps>`
  min-width: 9.5rem;
  background-color: ${({$backgroundColor}:ButtonProps):string => $backgroundColor || '#171720'};
  border-radius: ${({$borderRadius}:ButtonProps):string => $borderRadius as string || '1rem'};
	display: flex;
  column-gap: 1rem;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 0 1.5rem;
`;
export const AddFavoriteButton = ({culturalAlertId,talentId,style, label}: AddToFavoriteProps):JSX.Element => {
	const [liked,setLiked] = React.useState(false);
	const {user} :userContextInterface = useContext(UserContext);
	const {data,loading:likeLoading,error}:QueryResult = useQuery(query('likeFindMany'),{
		variables:{
			filter:{
				user: user._id,
				talent:talentId,
				culturalAlert:culturalAlertId
			}
		},
		onCompleted:({likeFindMany}:{likeFindMany:TLike[]}):void=>{
			if (likeFindMany.length>0 && likeFindMany[0].deletedAt===null) {
				setLiked(likeFindMany.length > 0);
			}
			else {
				setLiked(false);
			}
		}
	});

	// eslint-disable-next-line @typescript-eslint/typedef
	const [addToFavorites, {loading}] :AddToFavorites = useAddToFavorites({
		talent:talentId,culturalAlert:culturalAlertId,
		onError:():void=>setLiked(!liked)
	});

	const handleAddToFavorites = ():void => {
		setLiked(!liked);
		addToFavorites();
		console.log('Add to favorites');
	};
	
	return ( !likeLoading ?
		<StyledButton
			{...style}
			onClick={handleAddToFavorites}>
			
			{liked ?<BookmarkFilledIcon/> : <BookmarkLineIcon/> }
			<BodyText
				fontSize={'0.75rem'}
			>Favorite</BodyText>
		
			
		</StyledButton>
		:
			
		<Spinner/>
	);
};