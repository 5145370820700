import React from 'react';
import {ImageCard} from '../ui';
import styled from 'styled-components';
import {months} from 'tools/date';
import {ShareIcon} from 'icons';
import {FeedType, HotlistsType} from '../../interfaces';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import PaperIcon from '../../icons/PaperIcon';


interface HotListItemProps{
	feed:HotlistsType
}

const HotlistText:any=styled.div`
  font-family: "Urbanist", sans-serif;
  align-items: end;
  position: absolute;
  bottom: 0rem;
  left: 0rem;
  right: 0;
  padding: 2rem;
  display: flex;
  z-index: 100;
  h3 {
    font-weight: 900;
    line-height: 22px;
    max-width: 570px;
	margin-bottom: 1.5rem;
  }
  p{
	font-size: 14px;
	line-height: 23px;
	color: #61667E;
	max-width: 570px;
	font-weight: 500;
  }
`;

const HotlistAction:any=styled.a`
  background: #0E0F14;
  color: #fff;
  font-family: "Urbanist", sans-serif;
  font-size: 16px;
  font-weight: 700;
  display: inline-flex;
	align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 3rem;
  border-radius: 1rem;
`;

const HotlistGradient:any=styled.div`
  background: linear-gradient(rgba(19,20,26,0), rgba(19,20,26,1));
  position: absolute;
  bottom: 0rem;
  left: 0rem;
  right: 0;
  top:20%;
`;


export const HotListItem = ({feed}:HotListItemProps):JSX.Element => {

	return <div className={'cursor-pointer'}>
		<div style={{color: 'white', position: 'relative'}}>
			<ImageCard
				backgroundPosition='top'
				aspectRatio={'119/40'}
				img={feed.attributes.cover?.data.attributes.url}
				borderRadius="16px"
			/>
			<HotlistGradient />
			<HotlistText>
				<div>
					<h3>{feed.attributes.title}</h3>
					<p>
						When unlocked, the ability to inspire and move culture, capture the zeitgeist of the moment, and deliver disproportionate value can be harnessed.
					</p>
				</div>
				<div className="grows" />

				<div>
					<HotlistAction
						href={feed.attributes.file?.data.attributes.url}
						target='_blank'
						rel="noreferrer"
					>
						<PaperIcon /> View
					</HotlistAction>
				</div>

			</HotlistText>
		</div>
	</div>;
};