import React, {createContext, useState} from 'react';
import {
	login,
	signup,
	signupApple,
	signupGoogle,
	SignupWithProviderData,
	TUSerDataQuery,
	userData
} from 'actions/restActions';
import {ItemApi, LoginData, SignupData, User} from 'interfaces';

export const UserContext:React.Context<userContextInterface> =createContext({});
interface userProviderProps {
	children:JSX.Element
}
export interface userContextInterface{
	user?:User|null
	loading?:boolean|null
	error?:Error|null
	type?:ItemApi

	setUser?:(value:User)=>void
	unSetUser?:()=>void

	userLogin?:(data:LoginData)=>Promise<any>,
	userSignup?:(data:SignupData)=>Promise<any>
	refresh?:()=>Promise<any>
	logout?:()=>void
	//
	userSignupGoogle?:(data:SignupWithProviderData)=>Promise<any>
	userSignupApple?:(data:SignupWithProviderData)=>Promise<any>
}


const UserContextProvider=({children}:userProviderProps):JSX.Element=>{
	const [user,setUser]:any =useState<User|null>(null);
	const [loading,setLoading]:any =useState<boolean>(true);
	const [error,setError]:any =useState<boolean>(false);


	const userLogin=async(data:LoginData):Promise<any> => {
		await login(data);
		await refresh();
	};
	const userSignup=async(data:SignupData):Promise<any> => {
		await signup(data);
		await refresh();
	};

	const userSignupGoogle = async(data:SignupWithProviderData):Promise<any> => {
		await signupGoogle(data);
		await refresh();
	};

	const userSignupApple = async(data:SignupWithProviderData):Promise<any> => {
		await signupApple(data);
		await refresh();
	};
	const refresh=async():Promise<any> => {
		try {
			const data:TUSerDataQuery = await userData();
			const user:User = {...data,_id:data.id};
			setUser(user);
			setError(false);
		}
		catch (e){
			setError(true);
			localStorage.removeItem('token');
		}
		finally {setLoading(false);}
	};

	const logout= ():void=>{
		localStorage.setItem('token', '');
		setUser(null);
	};
	const value:userContextInterface={
		user,
		loading,
		error,
		userLogin,
		userSignup,
		refresh,
		logout,
		userSignupGoogle,
		userSignupApple
	};
	return (
		<UserContext.Provider value={value} >
			{children}
		</UserContext.Provider>
	);
};
export default UserContextProvider;
