import {User} from 'interfaces';

export const fakeCompanyUser:User={
	_id:'618d6960-3604-11ed-a588-8b75930256ec',
	type: {_id:'843ecf9e-11c4-11ed-861d-0242ac120002',label:'Regular'},
	'email':'admin@opus.com',
	'firstName':'Nike',
	'lastName':'Admin Nike',
	'interests':[],
	'preferenceCampaignType':[]
};

export const fakeTalentUser:User={
	_id:'618d6960-3604-11ed-a588-8b75930256ec',
	type: {_id:'90b4abc2-11c4-11ed-861d-0242ac120002',label:'Regular'},
	'email':'talent@opus.com',
	'firstName':'Mike',
	'lastName':'Tyson',
	'interests':[],
	'preferenceCampaignType':[]
};


