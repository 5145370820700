import React from 'react';
import {ProductContent} from 'styles/styledComponents/containers';
import {Brand, User} from 'interfaces';
import {ItemUI, LinkButton} from 'components/ui';
import styled from 'styled-components';
import {BrandsCubeIcon} from '../../icons';
import {Link} from 'react-router-dom';
import {color} from 'styles/colors';
import {Header, ProductCard} from 'components';
import {mediaQuery} from '../../styles/mediaQueries';

export interface ProductsRenderProps {
	user?:User
	products:Brand[]
}

const ProductsHeader:any= styled.div`
  width: 100%;
	display: flex;
  justify-content: space-between;
  align-items: center;
  >h1{
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
  }
  >div{
	width: 180px;
  }
`;

const NoProducts:any= styled.div`
  flex-grow:1; 
  display: flex;
  flex-direction: column;
  padding: 6rem 0;
  align-items: center;
  width: 100%;
  
  text-align: center;
  color: white;
  >h2{
	max-width: 200px;
    font-size: 30px;
    line-height: 36px;
  	margin-bottom: 2rem;
  }
`;

const ProductsWrapper:any=styled.div`
  width: 100%;
  max-width: 1200px;
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;

  ${mediaQuery('tablet')}{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  
  ${mediaQuery('mobile')}{
	display: block;
  }
`;

export default function ProductsRender({products}:ProductsRenderProps):JSX.Element{
	console.log('algo');
	return (
		<ProductContent >
			<Header/>
			<ProductsHeader>
				<h1></h1>
				<div>
					<LinkButton to={'/products/create-product'} label={'CREATE A PRODUCT'}/>
				</div>
			</ProductsHeader>

			{products.length<1&&
				<NoProducts>
					<div className="max-w-500px w-100 mhor-auto">
						<h2 className="mb left">You have no products yet</h2>
						<p className="mb left">You have no campaigns yet. Click below to start creating a campaign.</p>
						<Link className={'w-100 flex justify-center'} to={`/products/create-product`}>
							<ItemUI
								label={'Create a new product'}
								icon={<BrandsCubeIcon color={color.get('error-100')} />}
							/>

						</Link>
					</div>

				</NoProducts>
			}
			<ProductsWrapper>
				{products.map((product:Brand):JSX.Element=>
					<ProductCard key={product._id} product={product}/>
				)}
			</ProductsWrapper>

		</ProductContent>
	);
}
