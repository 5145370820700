import styled, { css, keyframes } from 'styled-components';
import { mediaQuery } from 'styles/mediaQueries';

type SideMenuStyleProps = {
    styleTheme: string;
};
const menuKeys: any = keyframes`
  from{
	left: -100%;
  }
  to{
	left: 0;
  }
`;

const menuKeysOut: any = keyframes`
  from{
	left: 0;
  }
  to{
	left: -100%;
  }
`;

const menuIn: any = css`
  ${menuKeys} 0.5s  ease-in-out ;
`;

const menuOut: any = css`
  ${menuKeysOut} 0.5s  ease-in-out;
`;

const mobileStyles: string = css`
	width: 100%;
  	padding: 40px 0 0 0;
  	position: absolute;

  	>div{
	  width: 100%;
      top: auto;
      padding: 3rem 1.5rem 0 0;
	  background-color: black;
	  position: fixed;
	  z-index: 15;
	}

    &.show {
        left: 0;
        animation: ${menuIn};
    }
    &.hide{
        left: -100%;
        animation: ${menuOut}
    }
`;

export const MenuWrapper: any = styled.aside<SideMenuStyleProps>`
  	grid-area: sideMenu;
  	position: relative;
  background-color: #0e0f14;
  padding:  24px;


  >div{
    height: 100%;
    width: 276px;
    box-sizing: border-box;
    border-radius: 32px;
  	background-color: ${({theme: {colors}}:any):string => colors.grey_900};
	position: sticky;
	top:0;
	display: flex;
	text-align: center;
	flex-direction: column;
    padding:  32px 10px 15px 10px ;
    >section:last-child{
      display: flex;
      flex-direction: column-reverse;
      flex-grow: 1;
      justify-content: end;
    }
  }

  @media (min-width: 768px) {
	>div {

      padding-top: 2rem;
      top: 0;
      left: 0;
      right: 0;
	}
  }

  ${mediaQuery('mobile')}{
  	${mobileStyles}
  }
`;

export const MenuItemContainer: any = styled.div<{$active:boolean}>`
  display:flex;
  justify-content: space-between;
  height: 2.38rem;
  align-items:center;
  padding: 8px 20px;
  border-radius: 30px;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  >div:nth-child(2){
    width: 36px;
    text-align: left;
  }
`;

export const LogoContainer: any = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 20px;
  margin-bottom: 25px;
`;

export const TextLogo: any = styled.p`
  color: #FFF;
  font-family: 'Urbanist', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 10.8px;
  opacity: 0.8;
`;
