import React from 'react';
import {FormProps} from 'interfaces';
import {FormButton, Input} from 'components/ui';

export const ContactFormRender = ({registerInput,onSubmit}:FormProps):JSX.Element=>{
	return <div className="w-100 pb-4">
		<form className="max-w-700px m-auto" onSubmit={onSubmit} >

			<div className='grid2 col-gap-2'>
				<Input
					label={'Phone'}
					{...registerInput('phone')}
				/>
				<Input
					label={'Website'}
					{...registerInput('website')}
				/>
			</div>

			<Input
				label={'Address1'}
				{...registerInput('address1')}
			/>
			<Input
				label={'Address2'}
				{...registerInput('address2')}
			/>
			<div className='grid3 col-gap-2'>
				<Input
					label={'City'}
					{...registerInput('city')}
				/>
				<Input
					label={'State'}
					{...registerInput('state')}
				/>
				<Input
					label={'Zip Code'}
					{...registerInput('zip')}
				/>
			</div>
			<div className="center">
				<FormButton
					variant={'secondary'}
					label={'Save'}
				/>
			</div>
		</form>
	</div>;
};