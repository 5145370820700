import React from 'react';
import styled from 'styled-components';

export const FieldSection:any = styled.div`
	h4 {
	  font-size: 10px;
	  color:#B0B0B2;
	  text-transform: uppercase;
	  font-weight: 800;
	}	
  
  	p {
	  font-size: 14px;
	  color:#7D7D7D;
	  font-weight: 300;
      margin-bottom: 0.5rem;
	}
`;


export const Separator:any = styled.div`
	height: 2px;
  	background: #131313;
  	margin: 2rem 0;
`;