import React from 'react';
import styled from 'styled-components';
import {PhotoGallery,PillTabs} from 'components';
import {Item, User} from 'interfaces';
import {mediaQuery} from 'styles/mediaQueries';
import {scoreData} from 'containers/profile/components/ProfileHeader/scoreSample';
import {Section, SectionTitle} from 'styles/styledComponents/containers';
import UserInterestScore from 'components/UserInterestScore';

interface AnalyticsProps {
	user:User
	socialNetworks:{
		selected:string
		options:Item[]
		handleSelect:(value:string)=>void
	}
}

const AnalyticsContainer:any = styled.section`
	width: 315px;
  display: block;
  column-gap: 0.5rem;
  box-sizing: border-box;
  >div:last-child{
	overflow: hidden;
  }
  
  
  ${mediaQuery('tablet')}{
    grid-template-areas: 'gallery statistics' ;
  }
  
  ${mediaQuery('mobile')}{
	display: block;
	padding: 0;
	>div{
	  width: 100%;
	}
	>div:last-child{
	 	padding: 0 1rem; 
	}
  }
  
`;

export const Analytics=({socialNetworks,user}:AnalyticsProps):JSX.Element=>{
	const tags:Item[] = [];
	scoreData.instagram.map((item:any, index:number):any=>{
		tags.push({id: `${item[0][0]}${index}`, label:item[0][0]});
	});
	return <AnalyticsContainer className={''}>
		<div>
			<PhotoGallery images={[user?.profilePicture?.url||'']}/>
		</div>

		<div>
			
			<UserInterestScore />

			<Section >
				<SectionTitle>Social Media Channel</SectionTitle>
				<PillTabs
					options={socialNetworks.options}
					value={socialNetworks.selected}
					setValue={socialNetworks.handleSelect}
				/>
			</Section>
			{/*<Section className ='align-center'>*/}
			{/*	<SectionTitle>account Insights</SectionTitle>*/}
			{/*	<Insights/>*/}
			{/*</Section>*/}
		</div>
	</AnalyticsContainer>;
};