import React, {useContext} from 'react';
import HelloRender from './HelloRender';
import {UserContext} from 'context/userContext';

export const Hello=():JSX.Element=>{

	const {user}:any=useContext(UserContext);
	const percentage=():number=>{
		if(user){
			let totalsFields:number=14;
			let fieldsArray:any[] =Object.keys(user)||[];
			return Math.floor((fieldsArray.length/totalsFields)*100);
		}
		else {
			return 0;
		}
	};

	return <HelloRender
		user={user}
		percentage={percentage()}
	/>;
};
