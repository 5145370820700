import React, {useEffect, useState} from 'react';
import {NavigateFunction, Params, useNavigate, useParams} from 'react-router-dom';

import { ArrowLeftLargeIcon } from 'icons';
import { Layout } from 'containers/Layout';
import { TvFil } from 'components/FeedCard';
import { CardFeed } from 'components/CardDowload/styles';
import {getFeed} from 'actions/strapiActions';
import {FeedType} from 'interfaces';
import {Spinner} from '../../../../components/ui';
import sanitizeHtml from 'sanitize-html';

export const ReadFeedRender = ():JSX.Element => {
	const navigate:NavigateFunction= useNavigate();
	const {slug}:Params=useParams();
	const [feed,setFeed]=useState<FeedType|null>(null);

	useEffect(():void=>{
		getFeed({
			id:slug,
			params:{
				populate:'*'
			}
		})
		.then((res:any):void=> res.json())
		.then((res:any):void=>setFeed(res.data));
	},[]);

	return feed?

		<div style={{margin: '10px 20px'}}>
			<CardFeed img={feed.attributes.cover.data.attributes.formats.medium}>
				<div>
					<div className='ml-1' onClick={():any => navigate(-1)}> <ArrowLeftLargeIcon/> Feed</div>
				</div>
			</CardFeed>

			<div className='flex'>
				<TvFil style={{margin: '0 10px 0 0'}}>
					<p>FILM / TV</p>
					<div/>
				</TvFil>
				<h4 className='mt-2 mb-2'>{feed.attributes.title}</h4>
			</div>
			<div
				style={{marginLeft: '20px'}}
				dangerouslySetInnerHTML={{__html:sanitizeHtml(feed.attributes.description)}}
			/>
		</div>
		:
		<div className={'w-100 flex justify-center'}>
			<Spinner/>
		</div>;
};
