import React from 'react';

export const AppleMusicIcon=():JSX.Element=>{
	return <svg
		width={40}
		height={40}
		viewBox="0 0 40 40"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect width={40} height={40} rx={15} fill="url(#paint0_linear_1_949)"/>
		<path
			d="M28 23.2616V24.1804V25.7119C28 27.0633 26.9069 28.1622 25.5627 28.1622H24.0394C22.6953 28.1622 21.6021 27.0633 21.6021 25.7119C21.6021 24.3605 22.6953 23.2616 24.0394 23.2616H26.172V14.2443L17.3978 15.7654V25.0987V26.0176V27.549C17.3978 28.9004 16.3047 29.9994 14.9606 29.9994H13.4373C12.0931 29.9994 11 28.9004 11 27.549C11 26.1977 12.0931 25.0987 13.4373 25.0987H15.5699V11.1545L28 9V23.2616Z"
			fill="white"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_1_949"
				x1="14.7656"
				y1="52.2328"
				x2="48.45"
				y2="22.7016"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#F71D1D"/>
				<stop offset={1} stopColor="#ED5353"/>
			</linearGradient>
		</defs>
	</svg>;
};
