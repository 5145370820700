import styled from 'styled-components';
import {themeOpus} from '../../stylesOpus/theme';


type PillProps = {
    $width?: string;
    $color?: string;
    $padding?: string;
    $margin?: string;
    $backgroundColor?: string;
}
export const Pill:any=styled.div<PillProps>`
  padding: 0.25rem 1rem;
  width: ${({$width}:PillProps):string=>$width || 'fit-content'};
  height: 1.4375rem;
		align-items: center;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 10px;
  line-height: 18px;
  cursor: pointer;
  border-radius: 8px;
  color: ${({$color}:PillProps):string=>$color || themeOpus.colors.white};
  background-color: ${({$backgroundColor}:PillProps):string=>$backgroundColor || '#1916CC'};
`;
