import React, {ChangeEvent} from 'react';
import { Toggle } from 'components/Toggle';
import { Container } from './styles';
import {BoxTitle} from '../../../../stylesOpus/typography';

interface NotificationsRenderProps {
	options:Toggle[]
	onChangeOption:(e:ChangeEvent<HTMLInputElement>)=>void
}

export type Toggle={
	name:string
	id:string
	defaultChecked?:boolean
}

export const NotificationsRender = ({options, onChangeOption}: NotificationsRenderProps):JSX.Element => {
	return (
		<>
			<BoxTitle>Notifications</BoxTitle>
			<div className="max-w-700px w-100 pb-4 m-auto mt-4">

				<Container>
					{options.map((option: any):any => (
						<div className='flex justify-between mb-2' key={option.id}>
							{option.name}
							<Toggle
								defaultChecked={option.defaultChecked}
								id={option.id}
								name={option.id}
								onChange={onChangeOption}
							/>
						</div>
					))}
				</Container>
			</div>
		</>
	);
};
