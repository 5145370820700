import {color} from 'styles/colors';
import styled from 'styled-components';

interface RangeStyleProps{
	$color:string;
	$percentage:string;
}

export const StyledRange:any=styled.div`
	width: 100%;
	height: 6px;
	display: flex;
	justify-content: start;
	border-radius: 32px;
	background: ${color.get('neutral-500')};
	>div{
		height: 100%;
		border-radius: 32px;
		width: ${({$percentage}:RangeStyleProps):string=>$percentage.toString()+'%' || '0%'};
		background-color: ${({$color}:RangeStyleProps):string=>$color || color.get('primary') || 'black'};
	}
`;