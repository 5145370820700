import React from 'react';
import {TextBoxProps} from 'interfaces';
import {TextAreaWrapper, TextBox} from './styles';
import {FieldSection} from '../FieldSection';

interface TextBoxRenderProps extends TextBoxProps{
	currentLength:number;
}

export default function TextBoxRender({
	label,
	currentLength,
	required,
	className,
	maxLength,
	error,
	helperText,
	...props
}:TextBoxRenderProps):JSX.Element{

	return (
		<FieldSection error={error} className={className} label={label} helperText={helperText}>
			<TextAreaWrapper localTheme={'opusV2'} className={className}>
				<TextBox
					as={'textarea'}
					{...props}
					required={required}
					maxLength={maxLength}
				/>
			</TextAreaWrapper>
		</FieldSection>
	);
}
