import React, {useContext} from 'react';
import OnBoardingRender from './OnBoardingRender';
import {Outlet} from 'react-router-dom';
import {UserContext} from 'context/userContext';

export interface OnboardingProps {
	videoUrl?:string
	direction?: 'row' | 'row-reverse'
	children?:JSX.Element;
}

export const OnBoarding=({children, ...props}:OnboardingProps):JSX.Element=>{
	const {user}:any = useContext(UserContext);

	let render:JSX.Element=children || <Outlet/>;
	return <OnBoardingRender

		auth={!!user}
		{...props}
	>
		{render}
	</OnBoardingRender>;
};
