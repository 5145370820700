import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import {FeedCard, Header} from 'components';
import { Divider } from 'components/ui';
import {Layout} from '../Layout';
import {Subtitle} from './style';
import {FeedType} from 'interfaces';

interface FeedsProps {
	feedInfo:FeedType[]
	feedDownload:[]
}

export const FeedRender = ({feedInfo}:FeedsProps):JSX.Element => {
	const navigate: NavigateFunction=useNavigate();
	return (
		<>
			<div className="grid2 col-gap-2 row-gap-2">

				{feedInfo.map((feed : FeedType):JSX.Element =>
					<div style={{margin: '8px 0'}} key={feed.id} onClick={():any => navigate(`${feed.id}`)}>
						<FeedCard feed={{
							id:feed.id,
							label:feed.attributes?.title||'----',
							url:feed.attributes?.cover?.data.attributes.url
						}} />
					</div>
				)
				}
			</div>
			
		</>
	);
};
