import React, {useEffect, useState} from 'react';
import {BodyText, BoxTitle} from 'stylesOpus/typography';
import {GridContentBox, GridWrapper} from '../opportunityDetail.styles';
import {GridContainer} from 'styles/styledComponents';
import {Detail} from './Detail';
import {PercentageGraph} from './PercentageGraph';
import {Section} from 'styles/styledComponents/containers';
import CarImage from 'styles/images/pages/opportunities/car.png';
import styled from 'styled-components';
import {AuthScoreI, CulturalAlert} from '../../../interfaces';
import AudienceOverlap from './AudienceOverlap';
import Popup from '../../../components/Popup';
import CategorySelector from './CategorySelector';
import {ModalHook, useModal} from '../../../hooks/useModal';

type TalentPOVProps = {
	id:string
	talentName:string
	followerCount:number
	alert:CulturalAlert
}

const CarIcon : any = styled.div`
	width: 3.125rem;
	height: 3.125rem;
	border-radius: 100%;
	border: 2px solid #444444;
	background-color: #2B2B3A;
	display: flex;
	justify-content: center;
	align-items: center;
	>div{
		width: 1.5rem;
		height: 1.5rem;
		background-image: url(${CarImage});
		background-size: contain;
		background-position: center;
	}
`;

const BodyOpportunityText:any = styled(BodyText)<{
	fontSize?:string
	fontWeight?:string
}>`
	font-family: 'Urbanist', sans-serif;
	font-size:  ${({fontSize}:any):string=>fontSize?fontSize:' 0.75rem'};
	font-weight: ${({fontWeight}:any):string=>fontWeight?fontWeight:'700'};
	opacity: 0.6;
`;
export const TalentPOV = ({talentName, followerCount, alert, id}:TalentPOVProps):JSX.Element=>{

	const [authSelected, setAuthSelected] = useState<AuthScoreI>();

	const [weight, setWeight] = useState<number>(0);
	const [cpm, setCpm] = useState<string>('');

	useEffect(():void=> {
		const min:number = (1000 * (alert?.feeRange?.min || 0)) / (weight * followerCount);
		const max:number = (1000 * (alert?.feeRange?.max || 0)) / (weight * followerCount);

		// @ts-ignore
		setCpm(`$${Math.round(min).toLocaleString('en-US')} - $${Math.round(max).toLocaleString('en-US')}`);

	},[weight]);

	const deliverablesModal: ModalHook =useModal(false);
	
	return (
		<Section>
			<div>
				<BoxTitle fontSize={'20px'} className="grows">{talentName} Opus POV</BoxTitle>
				{id &&
					<CategorySelector user={id} authSelected={authSelected} setAuthSelected={setAuthSelected} setWeight={setWeight} />
				}
			</div>

			<GridWrapper>
				<GridContainer $colGap={'0'} $columns={'repeat(4, 1fr)'} >
					{/*Audience Overlap*/}
					<AudienceOverlap user={id} setWeight={setWeight} authSelected={authSelected} />

					{/*Category CPM*/}
					<GridContentBox $padding = '2.81rem 1.7rem 2rem 1.5rem'>
						<Detail title={'Category CPM'}>
							<div className={'h-100 flex direction-column justify-between'}>
								<BodyOpportunityText className={'w-200px p-half'}>
									Estimated CPM to reach consumers with a demonstrated interest in the selected category.
								</BodyOpportunityText>
								<div className={'flex align-center justify-between'}>
									<div className="shrinks-0 mr w-3rem h-3rem shrinks-0 br-50 p-half flex align-center justify-center b b-neutral-500 neutral-700">
										<img src={authSelected?._id?.picture?.url} alt={authSelected?._id?.title} className="w-100" />
									</div>

									<BoxTitle textAlign={'right'} fontSize={'1.875rem'} lineHeight={'100%'}>
										{cpm}
									</BoxTitle>
								</div>
							</div>
						</Detail>
					</GridContentBox>

					{/*Earned Media Potential*/}
					<GridContentBox>
						<Detail title={'Earned Media Potential'}>
							<div className={'h-100 flex direction-column row-gap justify-between'}>
								<BodyOpportunityText>
									Measure of the likelihood and amount of additional, organic press and media coverage for this opportunity.
								</BodyOpportunityText>
								<PercentageGraph
									percentage={alert?.earnedMedia || 0}
								/>
							</div>
						</Detail>
					</GridContentBox>
                    
					{/*What You Get*/}
					<GridContentBox $padding={'3.41rem 2.5rem 3.35rem 2.5rem'}>
						<div className={'flex direction-column align-center row-gap'}>
							<BoxTitle className={'center'} fontSize={'1.25rem'}>Deliverables</BoxTitle>
							<div className="max-h-200px overflow-hidden relative">
								<div className="rich-text f-14px t-neutral-200 t-white op-60" dangerouslySetInnerHTML={{__html: alert?.deliverables || ''}} />
								<div className="absolute bottom-0 left-0 right-0 h-3rem hide-bar"></div>
							</div>
							<a className="cursor-pointer f-14px f-urbanist f-bold left op-60 w-100" onClick={():void=>deliverablesModal.open()}>
								Read more.
							</a>

							{deliverablesModal.isOpen &&
								<Popup modal={deliverablesModal} title="Deliverables">
									<div className="body small" dangerouslySetInnerHTML={{__html: alert?.deliverables || ''}} />
								</Popup>
							}

						</div>
					</GridContentBox>

				</GridContainer>
			</GridWrapper>
		</Section>
	);
};