import React from 'react';
import {FileRender} from './File';
import {FileInputHook, useFileInput} from 'hooks/useFileInput';

interface FileProps {
	file?:string
	defaultFile?:string
	helpText?:string
	label?:string
	accept?:string
	onChange?:(file:File)=>void
}

export const FileInput = ({onChange,...props}:FileProps):JSX.Element=>{

	const {file,handleChangeFile}:FileInputHook=useFileInput({onChange});

	return <FileRender
		{ ...props}
		file={file}
		onChange={handleChangeFile}
	/>;
};