import React from 'react';
import {FormButton, Input, Spinner} from 'components/ui/';
import {Message} from 'components';
import {color} from 'styles/colors';
import {FormProps} from 'interfaces';

export default function ResetPasswordRender({submitLoading,errors,onSubmit,registerInput}:FormProps):JSX.Element{
	return (
		<div className={' w-100 max-w-400px phor-2 flex direction-column'}>
			<h1 className='mb-4'>Reset Password</h1>
			<form
				className='grows max-h-600px mb-2 flex direction-column justify-between'
				onSubmit={onSubmit}
				autoComplete='off'
			>
				<div>
					<Input
						label={'PASSWORD'}
						placeholder={'password'}
						type={'password'}
						{...registerInput('password')}
					/>
					<Input
						label={'CONFIRM PASSWORD'}
						placeholder={'confirm'}
						type={'password'}
						{...registerInput('confirmPassword')}
					/>

				</div>
				<div>

					<FormButton
						label={'LOGIN'}
						loading={submitLoading}
						variant={'grey'}
					/>

					{errors?<div className={'mt center'}>
						{errors.get('submitError') && <Message message={errors.get('submitError')} color={color.get('primary')}/>}
						{errors.get('password')?.required && <Message message={'Password is required'} color={color.get('primary')}/>}
						{errors.get('confirmPassword')?.required && <Message message={'Confirm is required'} color={color.get('primary')}/>}
					</div>:null}
				</div>
			</form>
		</div>
	);
}
