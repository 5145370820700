import React, { useState} from 'react';
import {
	NavigateFunction,
	Params,
	useNavigate,
	useOutletContext,
	useParams
} from 'react-router-dom';
import {
	MutationFunction,
	MutationTuple, QueryResult,
	useMutation, useQuery
} from '@apollo/client';
import {mutation} from 'actions/mutations';
import {FormField,FormHook} from 'hooks/useForm/interfaces';
import {useForm} from 'hooks/useForm/useForm';
import CampaignCreateRender from './CamapignCreateRender';
import {uploadOpportunityPicture, uploadOpportunityTreatment} from 'actions/restActions';
import {OpportunityFormContext} from '../../OpportunityCreate';
import {query} from 'actions/queries';
import {Item, Tenant} from 'interfaces';
import {sortListBy} from 'tools/globalActions';

export function CampaignCreate():JSX.Element {

	const { id }:Params= useParams();
	const navigate:NavigateFunction = useNavigate();
	const [pictures,setPictures]=useState<File[]>([]);
	const [treatment,setTreatment]=useState<File>(null);
	const {active,opportunity,audience, refetch}:OpportunityFormContext = useOutletContext();

	// aupdate or create action
	const [createOpportunity]:MutationTuple<MutationFunction, any> =useMutation(mutation('opportunityCreateOne'));
	const [updateOpportunity]:MutationTuple<MutationFunction, any> =useMutation(mutation('opportunityUpdateOne'));
	const [createAudience]:MutationTuple<MutationFunction, any> =useMutation(mutation('audienceCreateOne'));
	const [updateAudience]:MutationTuple<MutationFunction, any> =useMutation(mutation('audienceUpdateOne'));

	const handleChangePicture = async(files:File[]):Promise<void> => {
		console.log({files});
		setPictures([...files]);
	};
	const handleChangeTreatment = async(file:File):Promise<void> => {
		setTreatment(file);
	};

	const {data:interestsData,loading:interestsLoading}:QueryResult = useQuery(query('interestFindMany'));

	const save =async(data:any):Promise<any> =>{
		const {interests}:any = data;
		const opportunityData:any = {...data};
		delete  opportunityData.interests;
		const audienceData:any = {interests};

		try {
			const res:any= await createOpportunity({variables:{
				record:{
					...opportunityData,
					active,
					slug:data.name.toLowerCase().replace(' ','-')
				}}});
			const OpportunityId:string=res.data.opportunityCreateOne._id;

			await createAudience({variables:{
				...audienceData,
				opportunity:OpportunityId,
				income:'ok'
			}});

			if(pictures.length){
				await Promise.all(pictures.map((picture:File):Promise<any> => uploadOpportunityPicture(picture,OpportunityId)));
			}
			if(treatment){
				await uploadOpportunityTreatment(treatment,OpportunityId);
			}
			refetch();
			navigate(`/opportunities/${OpportunityId}/edit/audience`,{
				state: res.data.opportunityCreateOne
			});
		}

		catch (e) {
			console.log({error:e});
		}

	};
	console.log({pictures});
	const update = async(data:any):Promise<any> =>{
		const {interests}:any = data;
		const opportunityData:any = {...data};
		delete  opportunityData.interests;
		const audienceData:any = {interests};
		
		try {
			const res:any= await updateOpportunity({variables:{
				_id:id,
				record:{
					...opportunityData,
					active: !!data.active,
					slug:data.name.toLowerCase().replace(' ','-')
				}}});
			await updateAudience({variables:{
				_id: audience._id,
				record:{ ...audienceData, opportunity: id},
				income:'ok'
			}});
			if(pictures.length){
				await Promise.all(pictures.map((picture:File):Promise<any> => uploadOpportunityPicture(picture,id)));
			}
			if(treatment){
				await uploadOpportunityTreatment(treatment,id);
			}
			refetch();
			navigate(`/opportunities/${id}/edit/audience`,{
				state: res.data.opportunityCreateOne
			});
		}

		catch (e) {
			console.log({error:e});
		}

	};

	const  formFields:FormField[]=[
		{name:'name', required:true,default: opportunity?.name},
		{name:'description', required:true,default:opportunity?.description},
		{name :'interests' ,required:true, default:audience?.interests || [], array: true},
		{name:'requirements', required:false, default: opportunity?.requirements},
		{name:'startDate', required:true,default: opportunity?.startDate || null},
		{name:'endDate', required:true,default: opportunity?.endDate || null},
		{name:'links',default: []},
		{name:'type', required:false,default: opportunity?.type}
	];

	const  {data,errors,loading,...rest}:FormHook=useForm(formFields,id ? update : save);

	const interests : Item[]= interestsData?.interestFindMany.map((e:Tenant):Item=> ({ ...e, id:e._id}) )||[];
	sortListBy(interests, 'label');
	return (
		<CampaignCreateRender
			options={{
				interests
			}}
			edit={!!id}
			formData={data}
			defaultPictures={ opportunity?.image}
			submitLoading={loading}
			errors={errors}
			onChangeFiles={handleChangePicture}
			onChangeTreatment={handleChangeTreatment}
			registerInput={rest.registerInput}
			registerTextArea={rest.registerTextArea}
			registerList={rest.registerList}
			onSubmit={rest.handleSubmitForm}
		/>
	);
}