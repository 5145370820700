import React, {useState} from 'react';
import {PageTitle} from 'stylesOpus/typography';
import {HeaderMenu} from 'pages/Discovery/components/HeaderMenu';
import {themeOpus} from 'stylesOpus/theme';
import styled from 'styled-components';
import useClickOut from 'hooks/useClickOut';

type DiscoveryHeaderProps = {
    matchCount:number
}


const ListButton:any= styled.button`
	background-color: ${ themeOpus.colors.grey_900 };
	font-family: 'Urbanist', sans-serif;
	font-weight: 700;
	font-size: 14px;
	display: flex;
	align-items: center;
  	border: 0;
  	color: #fff;
  	padding: 8px 24px;
  	border-radius: 16px;
  	cursor: pointer;
`;

export const DiscoveryHeader = ({matchCount}:DiscoveryHeaderProps):JSX.Element => {
	const [isListHidden, setIsListHidden] = useState<boolean>(true);
	// colose on click out
	const ref:React.MutableRefObject<any | null> =useClickOut(():void=> setIsListHidden(true));
	return (
		<div className={'flex justify-between align-center pb'}>
			<PageTitle>Match Results </PageTitle>
			{/*{matchCount > 0 && `(${matchCount})`}*/}
			{/*<div className={'relative'}>*/}
			{/*	<ListButton*/}
			{/*		onClick={():void=> isListHidden && setIsListHidden(false)}*/}
			{/*	>*/}
			{/*		My Lists*/}
			{/*	</ListButton>*/}
			{/*	{ !isListHidden &&*/}
			{/*		<div ref={ref} className={'absolute z-10 black w-auto right-0 mt-half p-2 br'}>*/}
			{/*			<HeaderMenu/>*/}
			{/*		</div>}*/}
			{/*</div>*/}
		</div>
	);
};