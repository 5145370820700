import React, {useState} from 'react';
import {IDoubleRange} from '../../DiscoverInterfaces';

export default function DoubleRangeSlider({min=0, max=100, leftVal=0, rightVal=0, setLeftVal, setRightVal}:IDoubleRange):JSX.Element {

	const [steps, setSteps] = useState<number>(100/(max-min));

	const setLeftValue = (e:any):void => {
		if(e.target.value < (rightVal -4)){
			setLeftVal(e.target.value);
		}
	};

	const setRightValue = (e:any):void => {
		if((leftVal + 4) < e.target.value) {
			setRightVal(e.target.value);
		}
	};

	return (
		<div>
			<div className="flex align-center relative h-halfrem">
				<div style={{height:'8px',background: '#262932',left:0, right:0, position:'absolute',borderRadius:'4px'}} />
				<div style={{height:'8px',background: '#FF5656',left:`${(leftVal-min)*steps}%`, right:`${(max-rightVal)*steps}%`, position:'absolute',borderRadius:'4px'}} />
				<input
					id="fromSlider"
					className="range-slider"
					type="range"
					min={min}
					max={max}
					value={leftVal}
					onChange={setLeftValue}
				/>
				<input
					id="toSlider"
					className="range-slider"
					type="range"
					min={min}
					max={max}
					value={rightVal}
					onChange={setRightValue}
				/>
			</div>
			<div className="flex mt-half">
				<p className="left">{leftVal}</p>
				<div className="grows" />
				<p className="right">{rightVal == 80 ? '80+' : rightVal}</p>
			</div>

		</div>

	);
}