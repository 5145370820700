import React from 'react';
import styled from 'styled-components';
import {LinkButton} from '../ui';
import {TalentList, User} from 'interfaces';


interface TalentListSummaryProps{
	list:TalentList
}

const Container:any=styled.div`
  display: grid;
  grid-template-columns: repeat(5,68px);
  height: 68px;
  gap: 8px;
  margin-bottom: 1rem;
`;

const TalentPicture:any=styled.div`
  place-self: stretch;
  border-radius: 8px;
  background-image: ${({picture}:any):string=>`url("${picture}")`};
  background-size: cover;
  background-position: center;
`;

const ListButton:any=styled(LinkButton)`
	width: fit-content;
`;

export const TalentListSummary = ({list}:TalentListSummaryProps):JSX.Element => {
	return<>
		<Container>
			{
				list.users.map((user:User):JSX.Element=>
					<TalentPicture
						key={user._id}
						picture={user.profilePicture.url}
					/>
				)
			}
		</Container>
		<ListButton
			to={`/discover/list/${list._id}`}
			variant={'secondary'}
			label={'VIEW LIST'}
			size={'small'}
		/>
	</>;
};