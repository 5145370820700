import React from 'react';
import {CategoryBox} from 'components/ui';
import {ArticleCard} from 'components';
import {Section} from 'styles/styledComponents/containers';
import {BoxTitle} from 'stylesOpus/typography';
import {TArticle} from 'components/ArticleCard/ArticleCard';
import {CategoryBoxProps} from 'components/ui/CategoryBox/CategoryBox';


type CategoryDetailProps = {
    articles:TArticle[]
}
export const LatestDetailRender = ({articles}:CategoryDetailProps):JSX.Element => {

	return (
		<>
			<Section className={''}>
				<div className="mb-2">
					<BoxTitle>Latest ( across the board )</BoxTitle>
					<p className="f-12px f-urbanist f-400 t-neutral-200 mt-half mb">
						Lorem ipsum dolor sit amet consectetur. Tristique eget vestibulum volutpat diam ac sagittis integer nibh lacus.
					</p>
				</div>
				<div className={'w-100 grid4 col-gap mt row-gap-2'}>
					{articles?.length > 0 && articles.map((article:TArticle, index:number):JSX.Element =>
						<ArticleCard
							key={`${index}-${article.title}`}
							article={article}
							isNew={false}
						/>
					)}
				</div>
			</Section>
		</>
	);
};
