import {AuthRoute, Layout} from '../containers';
import React from 'react';
import {Route} from './interfaces';
import {Outlet} from 'react-router-dom';
import {About, Help, Notifications, Security} from '../pages/Settings/components';
import {Account, Email, Password} from '../pages/Settings/components/Security/Components';
import {Settings} from '../pages';

export const settingsRoutes:Route[] =[
	{
		path:'/settings',
		element:<AuthRoute redirectTo={'/'}>
			<Layout styleTheme={'opusV2'}>
				<Outlet/>
			</Layout>
		</AuthRoute>,
		children:[
			{
				path:'',
				element: <Settings/>,
				children:[
					{
						path: 'security',
						element: <Security/>,
						children:[
							{
								path:'email',
								element:<Email/>
							},
							{
								path:'account',
								element:<Account/>
							},
							{
								path:'password',
								element:<Password/>
							}
						]
					},
					{
						path: 'account',
						element: <div/>
					},
					{
						path: 'notifications',
						element: <Notifications/>
					},
					{
						path: 'help',
						element: <Help/>
					},
					{
						path: 'about',
						element: <About/>
					}
				]
			}
		]
	}
];