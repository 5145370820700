import React from 'react';

export const ActivityIcon=():JSX.Element=>{
	return <svg
		
		width={24}
		height={24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M22 11H18L15 20L9 2L6 11H2"
			stroke="white"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>;
	
};
