import React, {ReactNode} from 'react';

import {Link} from 'react-router-dom';

type FavoritesGroupProps = {
	title: string;
	subtitle?: string;
	link?: string;
	children?: ReactNode;
};
export const FavoritesGroup = ({ title,subtitle,link, children}:FavoritesGroupProps):JSX.Element => {
	return (
		<section className={'pt'}>
			<div className={'w-100 flex justify-between'}>
				<h3 className="f-1rem f-urbanist ls-1-6">{title}</h3>

				{link ?<Link to={link} className="f-urbanist f-12px neutral-900 f-800 pver-half phor br t-white">
					View All
				</Link> : null}
			</div>
			{subtitle ?<p className="f-12px t-neutral-300 f-urbanist ls-1-2">{subtitle}</p> : null}
			<div className={'w-100 grid4 col-gap mt row-gap-2'}>
				{children}
			</div>
		</section>
	);
};