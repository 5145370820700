import React, {useState} from 'react';
import styled from 'styled-components';
import {ReactComponent as ChevronUpIcon} from 'styles/icons/chevron-up.svg';
import {ReactComponent as ChevronDownIcon} from 'styles/icons/chevron-down.svg';

interface AccordionProps {
    label?:string
	defaultHidden?:boolean
    children:React.ReactNode
}

const Label:any = styled.p`
  font-family: sans-serif, Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 137.5%; /* 19.25px */
  letter-spacing: 0.4px;
`;

const Container :any = styled.div`
	width: 100%;
	height: 72px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;


export const Accordion = ({label, defaultHidden,children}:AccordionProps):JSX.Element => {
	const [isHidden, setIsHidden] = useState<boolean>(defaultHidden||false);
	const handleClick = ():void => {
		setIsHidden(!isHidden);
	};
	return (
		<section >
			<Container className={'cursor-pointer'} onClick={handleClick} >
				<Label>{label}</Label>

				{isHidden? <ChevronUpIcon/> : <ChevronDownIcon/>}
				
			</Container>
			<div className={isHidden ? 'hidden' : ''}>
				{children}
			</div>

		</section>
	);
};