import React from 'react';
import {createBrowserRouter} from 'react-router-dom';
import {
	HotList,
	Privacy,
	TermsOfUse,
	UIKIt,
	Welcome
} from '../pages';
import {
	AuthRoute, Layout,
	Opportunity
} from '../containers';

import {Feed} from '../containers/Feed';
import {ReadFeedRender} from '../containers/Feed/components/ReadFeed/ReadFeedRender';
import TalentGraph from '../pages/TalentGraph';
import {onboardingRoutes} from './onboarding';
import {alertRoutes} from './alertsRoutes';
import {opportunitiesRoutes} from './opportunitiesRoutes';
import {discoverRoutes} from './discoverRoutes';
import {dashboardRoutes} from './dashboardRoutes';
import {settingsRoutes} from './settingsRoutes';
import {profileEditRoutes} from './profileEditRoutes';
import {productRoutes} from './productRoutes';
import {opportunityCreateRoutes} from './createOpportunityRoutes';
import {authRoutes} from './authroutes';
import {hotListRoutes} from './HotListRoutes';
import {favoritesRoutes} from './favoritesRoutes';
import {talentRoutes} from './TalentRoutes';

export const router:any =createBrowserRouter([
	{
		path: '/',
		element: <Welcome/>
	},
	{
		path: '/share/:id',
		element: <Opportunity />
	},
	...authRoutes,
	...onboardingRoutes,
	...hotListRoutes,
	...talentRoutes,
	...settingsRoutes,
	{
		path: '/',
		element: <AuthRoute><Layout/></AuthRoute>,
		children:[
			...profileEditRoutes,
			...productRoutes,
			{
				path:'/score-graph',
				element:<AuthRoute ><TalentGraph /></AuthRoute>
			},
			{
				path:'/privacy',
				element:<Privacy/>
			},
			{
				path:'/terms',
				element:<TermsOfUse/>
			},
			{
				path:'/news',
				element:<Feed/>
			},
			{
				path:'/news/:slug',
				element:<ReadFeedRender/>
			}
		]
	},
	{
		path: '/',
		element: <AuthRoute><Layout styleTheme={'opusV2'}/></AuthRoute>,
		children:[
			...dashboardRoutes,
			...discoverRoutes
		]
	},
	...alertRoutes,
	...opportunitiesRoutes,
	{
		path: '/',
		element:<AuthRoute><Layout hideHeader={true} styleTheme={'opusV2'} withColumn/></AuthRoute>,
		children:[
			...opportunityCreateRoutes
		]
	},
	{
		path:'/ui-kit',
		element:<UIKIt/>
	},

	{
		path:'*',
		element: <div><h1>Route not Found</h1></div>
	},
	...favoritesRoutes
]);
