import React from 'react';

import WelcomeRender from './WelcomeRender';
import {Navigate} from 'react-router-dom';

export const Welcome=():JSX.Element=>{

	// return <WelcomeRender/>;
	return <Navigate to={'/login'}/>;
};
