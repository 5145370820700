import React, {useContext} from 'react';
import SocialInfoRender from './SocialInfoRender';
import {UserContext} from 'context/userContext';
import {SocialsHook, useSocials} from 'hooks/useSocials';
import {ChartItem, SocialCardData, socialInterface} from 'interfaces';

interface SocialInfoProps {
	socialNetwork:'instagram'|'twitter'|'facebook'
	socialCard:SocialCardData
}

const valuesLabelsByNetwork:any=({
	default:['Followers','Posts'],
	instagram:['Followers','Posts'],
	tiktok:['Followers','Posts'],
	facebook:['Followers','Posts'],
	twitter:['Followers','Posts'],
	youtube:['Followers','Posts']
});

export const SocialInfo =({socialNetwork,socialCard}:SocialInfoProps):JSX.Element=>{
	const {user}:any=useContext(UserContext);

	const {socialsData}:SocialsHook=useSocials(user?._id);

	const getAccountName=():string=>
		socialsData?.filter((network:socialInterface):boolean=>network.identifier==='instagram')[0]?.url||'';

	// social cards tacking Data
	const cardData:SocialCardData[]=[socialCard];
	// instagram
	const likesPerPost:ChartItem[]=cardData.map((data:SocialCardData):ChartItem=> ({
		value:Math.floor(data.likes/data.totalPosts),
		label:'---'
	}));

	console.log({likesPerPost});

	let trackingData1:any={
		label:'Engagement Rate',
		total:socialCard.engagementRate||'0%',
		data:[socialCard.engagementRate||0]
	};
	let trackingData2:any={
		label:'Likes Per Post',
		total:likesPerPost[0].value,
		data:likesPerPost
	};


	return <SocialInfoRender
		user={user}
		title={socialNetwork}
		primaryData1={{value:socialCard.reach?.toString()||'', label:valuesLabelsByNetwork[socialNetwork][0]}}
		primaryData2={{value:socialCard.totalPosts?.toString()||'',label:valuesLabelsByNetwork[socialNetwork][1]}}
		accountName={'@'+getAccountName()}

		trackingData1={trackingData1}
		trackingData2={trackingData2}

	/>;
};