import React from 'react';
import {ReactComponent as ChevronLeft} from 'styles/icons/chevron-left.svg';
import {CircleContainer} from 'pages/Discover/components/styledComponents';
import {BreadCrumbContainer,BackButton,CrumbLink,ChevronRight} from './breadcrumb.styles';
import {TCrumb} from 'context/recoil/store';
import {uniqueId} from 'tools/globalActions';

type BreadCrumbProps={
    crumbs:TCrumb[]
    backLink?:string
}


export const BreadCrumb=({crumbs: crumbs,backLink}:BreadCrumbProps):JSX.Element=>{

	return (
		<BreadCrumbContainer>
			{ backLink && crumbs ?
				<>
					<BackButton
						to={backLink + ''}
						variant={'dark-grey'}
					>
						<ChevronLeft/>
					</BackButton>
					<div className={'crumbs'}>
						{crumbs.map((crumb:TCrumb, indx:number):JSX.Element=>
							<>
								<CrumbLink key={uniqueId('link',indx)} to={crumb.path} $active={indx=== crumbs.length-1}>
									{crumb.label}
								</CrumbLink>
								{indx < crumbs.length-1 &&  <CircleContainer key={uniqueId('circle',indx+100)}>
									<ChevronRight stroke='#585E81'/>
								</CircleContainer>}
							</>
						)}
					</div>
				</>
				:
				<div className={'crumbs'}>
					<CrumbLink $active={true}>{crumbs[0].label}</CrumbLink>
				</div>
			}
		</BreadCrumbContainer>
	);
};

