import React, {useState} from 'react';
import {Modal} from 'components/Modal';
import {Card, GenericHeader} from 'components';
import {CloseIcon} from 'icons';
import {Button} from 'components/ui';

type DeleteModalProps ={
    close : () =>void
    deleteAction : ()=>Promise<void>
}
export const DeleteModal = ({ close, deleteAction }: DeleteModalProps) : JSX.Element=> {
	const [loading, setLoading] = useState(false);
	const handleDelete =async():Promise<void>=>{
		setLoading(true);
		await deleteAction();
		setLoading(false);
	};

	return (
		<Modal onClickOut={close}>
			<Card>
				<GenericHeader
					Element={CloseIcon}
					title={'Are you sure to delete this alert ? '}
					action={close}
				/>
				<div className={'w-100 flex direction-column align-center align-center p-2 pb-2'}>
					<Button
						loading={loading}
						className={'mver'}
						label={'Confirm Delete'}
						variant={'secondary'}
						onClick={handleDelete}
					/>
					<Button
						label={'Cancel'}
						onClick={close}
					/>
				</div>
			</Card>
		</Modal>
	);
};