import React from 'react';
import {ImageWrapper} from '../RoundImage/styles';
import styled from 'styled-components';

interface EmptyImageProps {
	size?:string
	rounded?:string
	plus?:boolean
	fontSize?:string
}

const EmptyImageWrapper:any=styled(ImageWrapper)`
	font-size: ${({fontSize}:EmptyImageProps):string=>fontSize};
  	background: #13141B;
  	border: 1px dashed #626579;
  	color: #626579;
  	border-radius: 16px;
`;

export const EmptyImage=({plus,...props}:EmptyImageProps):JSX.Element=>{
	return<EmptyImageWrapper
		{...props}
		className={'flex justify-center align-center'}
	>
		{plus && '+'}
	</EmptyImageWrapper>;
};