import React from 'react';
import GridSelectRender from './GridSelectRender';
import {Item, ListInput} from 'interfaces';
import {listHandler, useListHandler} from 'hooks/useListHandler';

export const GridSelect=({options,name,value,onChange,max,...props}:ListInput):JSX.Element=>{

	const {handleUnselected,handleSelected}:listHandler=useListHandler({name,value,options,onChange});

	const handleClick=(item:Item):void=>{
		if (value.includes(item.id)){
			return handleUnselected(item);
		}
		if(value.length===max){return ;}
		handleSelected(item);
	};

	return <GridSelectRender
		{...props}
		selected={value}
		options={options}
		onClick={handleClick}
	/>;
};
