import React, {useContext} from 'react';
import LoginRender from './LoginRender';
import {FormField, FormHook} from 'hooks/useForm/interfaces';
import {LoginData} from 'interfaces';
import {useForm} from 'hooks/useForm/useForm';
import {UserContext, userContextInterface} from 'context/userContext';

export const Login=():JSX.Element=>{
	const {userLogin}:userContextInterface=useContext(UserContext);
	const  formFields:FormField[]=[
		{name:'email', required:true,default:'', pattern: /^\S+@\S+\.\S+$/, watch:true},
		{name:'password', required:true,default:''}
	];

	const loginAction=async(loginData:LoginData):Promise<void> =>{
		loginData.email=loginData.email.toLowerCase();
		await userLogin(loginData);
	};

	const  {data,errors,loading,...rest}:FormHook=useForm(formFields,loginAction);

	return <LoginRender
		formData={data}
		submitLoading={loading}
		errors={errors}

		registerInput={rest.registerInput}
		onSubmit={rest.handleSubmitForm}
	/>;
};
