import {NavigateFunction, useNavigate, Outlet, Location, useLocation} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {AuthLayout} from '../AuthLayout/AuthLayout';
import {PillTabs} from '../../components';

export const AccessForm = ():JSX.Element => {
	const {pathname}:Location = useLocation();
	const page: string = pathname.split('/')[1] ;
	const navigateFunction: NavigateFunction = useNavigate();
	const [authPage,setAuthPage] = useState<string>();

	useEffect(():void => {
		if (authPage){
			navigateFunction(`/${authPage}`);
		}
	}, [authPage]);
	return (
		<AuthLayout videoUrl={'/assets/login_video.mp4'}>
			{ page==='login' || page ==='signup' ? <div className={''}>
				<PillTabs
					options={[
						{id: 'login', label: 'Log in'},
						{id: 'signup', label: 'Sign-up'}
					]}
					value={authPage}
					setValue={setAuthPage}
				/>
			</div>
				:null}
			<div className={'grows flex direction-column justify-center'}>
				<Outlet/>
			</div>
		</AuthLayout>
	);
};