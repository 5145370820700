import React, {useState} from 'react';
import LayoutRender from './LayoutRender';
import LayoutContextProvider from '../../context/LayoutContext';
import {Outlet} from 'react-router-dom';


export interface LayoutProps{
	children?:React.ReactNode
	styleTheme?:'opus'|'opusV2'
	withColumn?:boolean

	hideMenu?:boolean
	hideHeader?:boolean
}

export const Layout=({children,styleTheme, ...props}:LayoutProps):JSX.Element=>{

	const[showMenu,setShowMenu]=useState(false);

	const handleShowMenu=():void=>{
		setShowMenu(!showMenu);
	};

	return <LayoutContextProvider handleShowMenu={handleShowMenu}>
		<LayoutRender
			styleTheme={styleTheme}
			showMenu={showMenu}
			{...props}
		>
			{children || <Outlet/> }
		</LayoutRender>

	</LayoutContextProvider>;
};