import React from 'react';
import {TalentList, User} from 'interfaces';
import {Button, Spinner} from 'components/ui';
import {Card, ItemsCollection, SectionUi} from 'components';
import {Modal} from '../../components/Modal';
import {ModalHook, useModal} from '../../hooks/useModal';
import {ListItem, ShareModal} from './compoents';
import {ShareModalProps} from './compoents/ShareModal';
import {ListName} from './listDetail.styles';
import {DeleteModal} from './compoents/Deletemodal';

export interface ListRenderProps {
	list:TalentList
	loading:boolean
	error:boolean

	emailAlert:() => void

	listPop: ModalHook
	confirmPop:ModalHook
	shareLink: ShareModalProps
}
export const ListDetailRender = ({list,loading,error,listPop,confirmPop,shareLink,emailAlert}:ListRenderProps):JSX.Element => {
	const alert = ():void => {
		emailAlert();
	};

	const deletePopup: ModalHook = useModal(false);

	return <>
		{error && <h4>Error has occurred</h4>}
		{loading ?
			<div className={'w-100 flex justify-center'}>
				<Spinner/>
			</div>
			:
			<>
				<section className={'w-100 flex justify-between align-center mver-2'}>
					<div>
						<ListName>{list?.name}</ListName>
					</div>
					<div className={'flex col-gap'}>
						<Button
							label={'Delete List'}
							variant={'secondary-white'}
							size={'small'}
							onClick={():void=>deletePopup.open()}
						/>
						<Button
							label={'Alert Members'}
							variant={'white'}
							size={'small'}
							onClick={():void=>listPop.open()}
						/>
					</div>
				</section>
				<SectionUi title={''}>
					<ItemsCollection>
						{list.users.map((talent:User):JSX.Element=>
							<ListItem
								listId={list._id}
								key={talent._id}
								talent={talent}
								action={():void=>console.log('hi')}
								search={shareLink.handleOpenShare}
								add={false}
							/>
						)}
					</ItemsCollection>
				</SectionUi>
			</>
		}

		{listPop.isOpen &&
			<Modal onClickOut={ listPop.close }>
				<Card className="max-w-500px">
					<h3 className="center">
						Alert List
					</h3>
					<p className="center mb">
						Notify the selected members on your list about the upcoming campaign opportunity for your brand.
						Continue to alert all members otherwise click on cancel.
					</p>
					<div className="w-100 flex align-center direction-column">
						<Button className="mb-half" onClick={alert}>Continue</Button>
						<Button variant="secondary" onClick={ listPop.close }>Cancel</Button>
					</div>

				</Card>
			</Modal>
		}

		{confirmPop.isOpen &&
			<Modal onClickOut={ confirmPop.close }>
				<Card className="max-w-500px">
					<h3 className="center">
						Your list was successfully alerted!
					</h3>
					<p className="center mb">
						The talents in your list were alerted with your campaign. We will notify you when they take action on the alert.
					</p>
					<div className="w-100 flex align-center direction-column">
						<Button onClick={ confirmPop.close }>Finish</Button>
					</div>

				</Card>
			</Modal>
		}
		{ shareLink.popup.isOpen &&
			<ShareModal {...shareLink} />
		}
		{ deletePopup.isOpen &&
			<DeleteModal listId={list._id} popup={{...deletePopup}} />
		}
	</>;
};