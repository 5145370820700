import React, {useContext, useState} from 'react';
import {MutationFunction, MutationTuple, useMutation} from '@apollo/client';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import {useForm} from 'hooks/useForm/useForm';
import {UserContext} from 'context/userContext';
import {FormField, FormHook} from 'hooks/useForm/interfaces';

import {mutation} from 'actions/mutations';
import {uploadCompanyLogo} from 'actions/restActions';
import {CompanyProfileForm} from 'components';

const navigateByUserType=(userType:string):string=>{
	const options:any={
		'Regular':'/dashboard',
		'Company':'/discover',
		'Agent':'/alerts'
	};

	return options[userType||'/dashboard'];
};

export const Brand=():JSX.Element=>{
	const navigate:NavigateFunction=useNavigate();
	const {user,refresh}:any=useContext(UserContext);

	const [updateProfile]:MutationTuple<MutationFunction, any> =useMutation(mutation('usersUpsertCompanyProfile'), {refetchQueries:['usersTalentProfileFindOne']});
	// file
	const [picture,setPicture]=useState<File>(null);
	const handleChangePicture = async(file:File):Promise<void> => {
		setPicture(file);
	};
	// const {data:profileData,loading:profileLoading}:QueryResult=useQuery(query('usersCompanyProfileFindOne'),{variables:{
	// 	user:user._id
	// }});
	const  formFields:FormField[]=[
		{name:'name', required:true},
		{name:'website', required:true},
		{name:'phone', required:true},
		{name:'position'},
		{name:'linkedin'}
	];

	const handleSubmit=async(updateData:any):Promise<void> =>{
		delete updateData.position;
		delete updateData.linkedin;
		await updateProfile({variables:{
			companyProfileData:updateData
		}});

		console.log({picture});
		picture && await uploadCompanyLogo(picture);
		await refresh();
		navigate(navigateByUserType(user.type.label));
	};

	const  {errors,loading,hasChanges,...rest}:FormHook=useForm(formFields,handleSubmit,true);
	return <>
		<CompanyProfileForm
			submitLoading={loading}
			errors={errors}
			disabled={!hasChanges || !!errors.size}
			onSubmit={rest.handleSubmitForm}
			registerInput={rest.registerInput}
			registerList={rest.registerList}
			onChangeFile={handleChangePicture}
		/>
	</>;
};
