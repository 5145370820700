import React from 'react';
import {GenericHeader} from 'components/index';

import {CloseIcon} from 'icons';
import {CreateListForm} from '../../../../../pages/Discovery/components/forms/CreateListForm';

interface createListProps{
	close:()=>void
	goToAdd:()=>void
}

export const CreateList = ({close,goToAdd}:createListProps):JSX.Element => {

	return<>
		<GenericHeader title={'Create a new list'} action={close} Element={CloseIcon}/>
		<CreateListForm onCreate={goToAdd}	/>
	</>;
};