import styled from 'styled-components';

type GridProps = {
    $width?:string
    $height?:string

    $columns?:string
    $rows?:string

    $colGap?:string
    $rowGap?:string

}
// eslint-disable-next-line @typescript-eslint/typedef
export const GridContainer = styled.div<GridProps>`
    display: grid;
    width: ${({$width}:GridProps):string=>$width ? $width : '100%'};
    height: ${({$height}:GridProps):string=>$height ? $height : 'auto'};
  
    grid-template-columns: ${({$columns}:GridProps):string=>$columns ? $columns : '1fr'};
    grid-template-rows: ${({$rows}:GridProps):string=>$rows ? $rows : 'auto'};
  
  column-gap: ${({$colGap}:GridProps):string=>$colGap ? $colGap : '1rem'};
    row-gap: ${({$rowGap}:GridProps):string=>$rowGap ? $rowGap : '1rem'};
  
   >*{
        place-self: stretch;
   }
`;