import React from 'react';
import { BrandsCubeIcon } from 'icons';
import {ItemUI, LinkButton} from 'components/ui';
import {Sku} from 'interfaces';
import {Link} from 'react-router-dom';

interface SkusProps {
	skus:Sku[]
	addSkuLink:string
	editLink:string
	navigateTo?:string
}

export const BrandSkusForm = ({skus,addSkuLink,editLink,navigateTo}:SkusProps):JSX.Element => {
	console.log({navigateTo});
	return (
		<div className='w-100 p'>
			<div className='mver phor w-100'>
				<h1>Add Skus</h1>
				<p>Add up to 5 skus for your product</p>
			</div>
			<div className={'w-100 flex direction-column align-center justify-center'}>
				{skus.map((sku:Sku):JSX.Element=>
					// <p key={sku._id}>{sku.name}</p>
					<ItemUI
						key={sku._id}
						label={sku.name}
						picture={sku.picture?.url}
						navigateTo={`${editLink}/${sku._id}`}
						icon={<BrandsCubeIcon color="#818184"/>}
					/>
				)}
			</div>
			{skus.length<5&&<Link className={'w-100 flex justify-center'} to={addSkuLink}>
				<ItemUI
					label={'Add New Sku'}
					icon={<BrandsCubeIcon color="#818184"/>}
				/>
			</Link>}

			{!!navigateTo&&
			<div className="w-100 mt-10 center flex justify-center">
				<LinkButton to={navigateTo}
					disabled={!skus.length}
					size={'large'}
					label="CONTINUE"
				/>
			</div>}
		</div>
	);
};
