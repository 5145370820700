import React, {MutableRefObject} from 'react';
import styled from 'styled-components';
import useClickOut from '../../hooks/useClickOut';

interface ModalProps {
	children:React.ReactNode
	onClickOut:()=>void
}

const ModalWrapper:any=styled.div`
  position: relative;
  width: 0;
  height: 0;
  z-index: 1000;
  >div{
	background-color: rgba(0,0,0,0.8) ;
	position: fixed;
	top:0;
	left: 0;
	width: 100vw;
	height: 100vh;
	>div{
	  width: fit-content;
	  height: fit-content;
	}
  }

`;

export const Modal =({children,onClickOut}:ModalProps):JSX.Element=>{
	const ref:MutableRefObject<any> =useClickOut(onClickOut);

	return <ModalWrapper className={' h-100'}>
		<div className={'flex justify-center align-center'}>
			<div ref={ref} className={'w-'}>
				{children}
			</div>
		</div>
	</ModalWrapper>;
};