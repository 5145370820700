import styled from 'styled-components';
import {inputField} from 'styles/ui';

export const Label:any = styled.label`
	font-weight: 700;
	font-family: 'Urbanist', Snas-serif;
	
	display: block;
`;

export const DatePickerStyle: any = styled.div`
	${inputField};
	width: 100%;
`;


export const DatePickerContainer: any = styled.div`
	width: 100%;
	>div{
		width: 100%;
	}
`;

export const PlaceHolder: any = styled.span`
	color: #9498B1;
`;

