export const scoreData:any = {
	'talent_id': 'a84a0900-91f6-11ed-bfe5-dd6a30557d3b',
	'topic': 'ALL',
	'instagram': [
		[
			['Travel and Tourism', 'Travel Apps', 'Travel', 'Travel Books'],
			{
				'talent_type': 'micro',
				'audience_upper_limit': 49999,
				'audience': 0.72,
				'authority': 0.92
			}
		],
		[
			['Cosmetics', 'Beauty', 'Beauty', 'Beauty Services'],
			{
				'talent_type': 'micro',
				'audience_upper_limit': 49999,
				'audience': 0.67,
				'authority': 0.97
			}
		],
		[
			['Design', 'Culture and Fine Arts', 'Body Art', 'Modern Art', 'Fine Art Photography', 'Digital Arts', 'Art and Photography Books', 'Music and Art'],
			{
				'talent_type': 'micro',
				'audience_upper_limit': 49999,
				'audience': 0.76,
				'authority': 0.88
			}
		],
		[
			['Music and Video Streaming', 'TV', 'Televisions', 'Television'],
			{
				'talent_type': 'micro',
				'audience_upper_limit': 49999,
				'audience': 0.54,
				'authority': 0.74
			}
		],
		[
			['Fashion Events', 'Shoe Polish & Accessories', 'Clothing and Accessories', 'Men\'s Fashion', 'Women\'s Fashion', 'Street Style', 'High Fashion', 'Fashion Trends', 'Designer Clothing', 'Children\'s Clothing'],
			{
				'talent_type': 'micro',
				'audience_upper_limit': 49999,
				'audience': 0.48,
				'authority': 0.86
			}
		],
		[
			['Photography', 'Electronics/Photography', 'Photographers', 'Photo and Video Apps', 'Photography', 'Fine Art Photography', 'Art and Photography Books', 'Cameras and Photo'],
			{
				'talent_type': 'micro',
				'audience_upper_limit': 49999,
				'audience': 0.67,
				'authority': 0.89
			}
		],
		[
			['Family Travel', 'Family and Parenting', 'Family Travel', 'Family and Relationships'],
			{
				'talent_type': 'micro',
				'audience_upper_limit': 49999,
				'audience': 0.71,
				'authority': 0.69
			}
		],
		[
			['Music TV', 'Music and Audio', 'Musical Instruments', 'Music and Art'],
			{
				'talent_type': 'micro',
				'audience_upper_limit': 49999,
				'audience': 0.45,
				'authority': 0.76
			}
		]
	]
};