import React from 'react';
import {ScoreLineCircle} from '../../../../stylesOpus/ui';

interface CircleProps {
	value?:number
}

export default function AuthorityCircleRender({value}:CircleProps):JSX.Element {
	return (
		<ScoreLineCircle>
			{value ? (value * 100).toFixed(0) || 0 : '' }
		</ScoreLineCircle>
	);
}