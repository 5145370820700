import React from 'react';
import {Route} from './interfaces';
import {ForgotPassword, Login, ResetPassword} from 'pages';
import {AccessForm, NoAuthRoute} from '../containers';
import {Signup} from 'pages';

export const authRoutes:Route[] =[
	{
		path: '/',
		element:<NoAuthRoute><AccessForm/></NoAuthRoute>,
		children:[
			{
				path:'/login',
				element:<Login />
			},
			{
				path:'/signup',
				element:< Signup/>
			},
			{
				path:'/forgot-password',
				element: <ForgotPassword/>
			},
			{
				path:'/reset-password/:token',
				element: <ResetPassword />
			}
			// {
			// 	path:'/onboarding/:user/signup',
			// 	element: <Signup />
			// }
		]
	}

];