import React from 'react';
import {AppleButton, FirebaseButton, FormButton, Input, Spinner} from 'components/ui';
import {FormProps, TUserType} from 'interfaces';
import {Message} from 'components';
import {color} from 'styles/colors';
import {PageTitle} from '../../stylesOpus/typography';

interface SignupProps extends  FormProps{
    title:string
    sibTitle?:string
	type?:TUserType
	providerLoading:boolean
	setProviderLoading?:(loading:boolean)=>void
}

export default function SignupRender({title,type,sibTitle,submitLoading,errors,onSubmit,registerInput,setProviderLoading,providerLoading}:SignupProps):JSX.Element{
	return (
		<div className="w-100 w-400px">
			{/*<h1 className="center mb-2 mt-2">Become An ØPUS Member</h1>*/}
			<PageTitle className="center mb-2 mt-2">{title}</PageTitle>
			{<p className="center mb-2">{sibTitle}</p>}
			<form onSubmit={onSubmit} autoComplete='off'>

				<Input
					placeholder={'First Name'}
					{...registerInput('firstName')}
				/>
				<Input
					placeholder={'Last Name'}
					{...registerInput('lastName')}
				/>


				<Input
					placeholder={'Email'}
					{...registerInput('email')}
				/>
				<Input
					placeholder={'Password'}
					type={'password'}
					{...registerInput('password')}
				/>

				<div className="center mver-2">
					
					<FormButton
						width={'100%'}
						variant={'white'}
						loading={submitLoading}
						label={'Sign - Up'}
					/>
						
					{errors&&<div className={'mt center'}>
						{errors.get('submitError') && <Message message={errors.get('submitError')} color={color.get('primary')}/>}
						{errors.get('email')?.required && <Message message={'Email is required'} color={color.get('primary')}/>}
					</div>}
				</div>

				<div className={'w-100 flex mver align-center'}>
					<div className={'grows white h-1px mr'}/>
					<p> or </p>
					<div className={'grows white h-1px ml'}/>
				</div>
			</form>
			{ !providerLoading ?
				<div className={'w-100 justify-center flex direction-column row-gap'}>
					<AppleButton signup type={type} setLoading={setProviderLoading}/>
					<FirebaseButton signup type={type} setLoading={setProviderLoading}/>

				</div>
				:
				<div className={'w-100 flex justify-center'}>
					<Spinner/>
				</div>
			}
		</div>
	);
}
