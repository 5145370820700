import React from 'react';
import {Divider, DoubleRange, FormButton, Input, Select} from 'components/ui';
import {FormProps, Item} from 'interfaces';

interface AboutVideoProps extends FormProps{
	options:{
		states:Item[]
	}
}

export const AboutMusicVideoRender = ({options,onSubmit,registerInput,registerList,registerSelect}:AboutVideoProps):JSX.Element => {

	return <>
		<form className={'mt-4'} onSubmit={onSubmit}>
			<h4>About the music video</h4>
			<div className={'grid3 col-gap mt-2'}>
				<Input
					label="shoot date"
					placeholder="MM/DD/YYY"
					type="date"
					{...registerInput('videoShootDate')}
				/>
				<Input
					label="shoot Time"
					{...registerInput('videoShootTime')}
				/>
				<Input
					label="release date"
					placeholder="MM/DD/YYY"
					type="date"
					{...registerInput('endDate')}
				/>
			</div>
			<Input
				label="Shoot Location"
				{...registerInput('eventLocation')}
			/>
			<div className={'grid2 col-gap'}>
				<Input
					label="city"
					{...registerInput('city')}
				/>
				<Select
					label={'state'}
					options={options.states}
					{...registerSelect('state')}
				/>
			</div>
			<div className={'mt'}>
				<DoubleRange

					label={'suggested fee range'}
					minValue={0}
					maxValue={1e6}
					prefix={'$'}
					styleProps={{
						minAlias:'$0',
						maxAlias:'$1M+'
					}}
					{...registerList('feeRange')}
				/>
			</div>
			<Divider/>
			<div className={'w-100 flex justify-between'}>
				<FormButton
					label={'continue'}
					size={'small'}
				/>
			</div>
		</form>
	</>;
};