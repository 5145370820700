import {QueryResult, useQuery} from '@apollo/client';
import {query} from 'actions/queries';
import React from 'react';
import {User} from '../interfaces';

interface UserHocProps {
	userId: string,
	children: (userData: User) => React.ReactNode;
}

export const WithUserData =({userId,children}:UserHocProps):any=>{
	const {data,loading}:QueryResult=useQuery(query('usersFindMany'),{
		variables:{
			filter:{_id:userId}
		}
	});
	if (loading) {return;}
	let user:User=data.usersFindMany[0];
	return children(user);
};
