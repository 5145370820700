import React from 'react';
import {IconProps} from 'interfaces';
export const TvIcon=({color='#929292',size=22}:IconProps ):JSX.Element=>
	<svg
		width={size}
		height={size}
		viewBox="0 0 22 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1 8C1 6.89543 1.89543 6 3 6H19C20.1046 6 21 6.89543 21 8V19C21 20.1046 20.1046 21 19 21H3C1.89543 21 1 20.1046 1 19V8Z"
			stroke={color}
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M16 1L11 6L6 1"
			stroke={color}
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
;
