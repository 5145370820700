import React from 'react';
import {Message, PictureInput, Steps} from 'components';
import {FormButton, SearchAutoComplete, TextBox} from 'components/ui';
import {FormProps} from 'interfaces';
import {color} from 'styles/colors';

interface AdditionalAlertInfoFormProps extends FormProps{
	defaultPhoto?:string|null
}

export const AdditionalInfoRender = ({defaultPhoto,errors,onSubmit,registerTextArea,registerList,onChangeFile}:AdditionalAlertInfoFormProps):JSX.Element => {

	return <>
		<form onSubmit={onSubmit} className={'mt-4'}>
			<h4 className={'mb'}>Additional Info</h4>
			<p className={'f-small mb'}>Target brands by industry</p>

			<SearchAutoComplete
				label={'Categories'}
				placeholder={'select'}
				searchName={'interestSearchMany'}
				findOneAction={'interestFindOne'}
				{...registerList('interest')}
			/>

			<p className={'f-small mb'}>Add at least one photo to cover the opportunity alert</p>
			<div className={'mver'}>
				<PictureInput
					defaultImage={defaultPhoto}
					rounded={'20px'}
					plus={true}
					fontSize={'3rem'}
					label={''}
					onChange={onChangeFile}
				/>
			</div>
			<TextBox
				label={'Alert Text'}
				maxLength={2000}
				{...registerTextArea('additionalInfo')}
			/>
			<p className="f-small mt-2 mb t-grey500">
				<input type="checkbox" /> By Clicking Submit you agree to our <a className="t-white f-bold">Terms of Use</a> & <a className="t-white f-bold">Privacy Policy</a>
			</p>

			<div className={'flex justify-between align-center'}>
				<div>
					<FormButton
						label={'Continue'}
						size={'small'}
					/>
					{errors.size?<div className={'mt center'}>
						{errors.get('submitError') && <Message message={errors.get('submitError')} color={color.get('primary')}/>}
						{errors.get('brandType')?.required && <Message message={'Brand Type is required'} color={color.get('primary')}/>}
					</div>:null}
				</div>
				<Steps steps={3} currentStep={3}/>
			</div>
		</form>
	</>;
};