import React from 'react';
import {IconProps} from '../interfaces';

export const PlayIcon=({size=42}:IconProps):JSX.Element=>
	<svg
		width={size}
		height={size}
		viewBox="0 0 42 42"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g opacity="0.8">
			<rect width={42} height={42} rx={21} fill="white" />
			<path d="M34 21L14.5 32.2583L14.5 9.74167L34 21Z" fill="black" />
		</g>
	</svg>
;
