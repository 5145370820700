import {AuthRoute, Dashboard, Opportunities} from '../containers';
import React from 'react';
import {Route} from './interfaces';
import {Highlights} from '../containers/profile/components';
import {Audience} from '../pages/TalentDashboard/components/Audience';

export const dashboardRoutes:Route[] =[
	{
		path:'/dashboard',
		element:<AuthRoute redirectTo={'/'}><Dashboard /></AuthRoute>,
		children:[
			{
				path:'opportunities/:filter',
				element: <Opportunities />
			},
			{
				path:'opportunities/',
				element: <Opportunities />
			},
			{
				path:'highlights/',
				element: <Highlights />
			},
			{
				path: 'social',
				element: <Audience/>
			},
			{
				path: 'survey',
				element: <div className={'w-100'}><h4>Coming Soon ....</h4></div>
			}
		]
	}
];