import React, {useContext, useEffect, useState} from 'react';
import UserInterestScoreRender from './UserInterestScoreRender';
import {Item} from '../../interfaces';
import {UserContext} from '../../context/userContext';
import {useQuery} from '@apollo/client';
import {query} from '../../actions/queries';

export default function UserInterestScore():JSX.Element {

	const [tags,setTags] = useState<Item[]>([]);
	const {user}:any=useContext(UserContext);

	const {data, loading}:any = useQuery(
		query('instagramAudienceFindOne'),
		{
			variables: {
				filter:{user: user._id}
			}
			//fetchPolicy: 'cache-first'
		}
	);

	useEffect(():void => {
		if(!loading && data.instagramAudienceFindOne?.audienceInterests){
			const t:Item[] = data.instagramAudienceFindOne?.audienceInterests.map(({name}:any):Item=>{
				return {
					id:name,
					label:name
				};
			});
			setTags(t);
		}
	}, [data]);

	if (tags.length > 0) {
		return <UserInterestScoreRender tags={tags} userId={user._id} />;
	}
	else{
		return <div>Loading...</div>;
	}
}