import React from 'react';

export const CategoryShareIcon=():JSX.Element=>
	<svg
		width={55}
		height={55}
		viewBox="0 0 55 55"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M34.0358 22.8125C31.8828 22.8125 30.1295 21.0604 30.1295 18.9062C30.1295 16.7521 31.8828 15 34.0358 15C36.189 15 37.942 16.7521 37.942 18.9062C37.942 21.0604 36.189 22.8125 34.0358 22.8125ZM34.0358 16.5625C32.7441 16.5625 31.692 17.6134 31.692 18.9062C31.692 20.1991 32.7441 21.25 34.0358 21.25C35.3274 21.25 36.3795 20.1991 36.3795 18.9062C36.3795 17.6134 35.3274 16.5625 34.0358 16.5625Z"
			fill="white"
		/>
		<path
			d="M34.0358 40C31.8828 40 30.1295 38.2479 30.1295 36.0938C30.1295 33.9396 31.8828 32.1875 34.0358 32.1875C36.189 32.1875 37.942 33.9396 37.942 36.0938C37.942 38.2479 36.189 40 34.0358 40ZM34.0358 33.75C32.7441 33.75 31.692 34.8009 31.692 36.0938C31.692 37.3866 32.7441 38.4375 34.0358 38.4375C35.3274 38.4375 36.3795 37.3866 36.3795 36.0938C36.3795 34.8009 35.3274 33.75 34.0358 33.75Z"
			fill="white"
		/>
		<path
			d="M18.9316 31.4062C16.7784 31.4062 15.0254 29.6542 15.0254 27.5C15.0254 25.3458 16.7784 23.5938 18.9316 23.5938C21.0848 23.5938 22.8379 25.3458 22.8379 27.5C22.8379 29.6542 21.0848 31.4062 18.9316 31.4062ZM18.9316 25.1562C17.64 25.1562 16.5879 26.2072 16.5879 27.5C16.5879 28.7928 17.64 29.8438 18.9316 29.8438C20.2233 29.8438 21.2754 28.7928 21.2754 27.5C21.2754 26.2072 20.2233 25.1562 18.9316 25.1562Z"
			fill="white"
		/>
		<path
			d="M21.6516 26.7396C21.3796 26.7396 21.115 26.5978 20.9723 26.3459C20.7587 25.9709 20.889 25.4937 21.264 25.2802L30.9297 19.7697C31.3035 19.5542 31.7817 19.6854 31.9942 20.0615C32.2078 20.4365 32.0775 20.9136 31.7025 21.1272L22.0368 26.6375C21.915 26.7062 21.7828 26.7396 21.6516 26.7396Z"
			fill="white"
		/>
		<path
			d="M31.316 35.3333C31.1848 35.3333 31.0524 35.2999 30.9305 35.2303L21.2649 29.7198C20.8899 29.5063 20.7598 29.0291 20.9732 28.6541C21.1857 28.2782 21.6629 28.1479 22.0379 28.3625L31.7034 33.8728C32.0784 34.0865 32.2087 34.5635 31.9952 34.9385C31.8514 35.1916 31.5869 35.3333 31.316 35.3333Z"
			fill="white"
		/>
		<rect x="0.5" y="0.5" width={54} height={54} rx="19.5" stroke="white"/>
	</svg>
;
