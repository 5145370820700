import React from 'react';
import {Input, Select, TextBox} from 'components/ui';
import styled from 'styled-components';


interface EventProps {
	registerInput:any
	registerSelect:any
	registerTextArea:any
}

const Container:any=styled.div`
  height: 100%;
	display: flex;
	flex-direction: column;	
`;

export const Event = ({registerInput,registerSelect, registerTextArea}:EventProps):JSX.Element => {

	return <>
		<h4 className={'mb'}>Event Info</h4>
		<p className={'f-small'}>Complete the form below  to be discovered and match with brands looking for an opportunity </p>
		<div className={'mt-2 grid2 col-gap'}>
			<Container>
				<Input
					label={'Event Name'}
					{...registerInput('title')}
				/>
				<TextBox
					className={'grows'}
					label={'Description'}
					maxLength={2000}
					{...registerTextArea('description')}
				/>
			</Container>
			<div>
				<Select
					label={'Event Category'}
					options={[
						{id:'Festival' , label:'Festival'},
						{id:'Sports competition' , label:'Sports competition'},
						{id:'Fashion show' , label:'Fashion show'},
						{id:'Concert' , label:'Concert'},
						{id:'Conference' , label:'Conference'},
						{id:'Convention' , label:'Convention'},
						{id:'Other' , label:'Other'}
					]}
					{...registerSelect('eventCategory')}
				/>
				<Input
					label={'Event Instagram Handle'}
					{...registerInput('instagram')}
				/>
				<Input
					label={'Event TikTok Handle'}
					{...registerInput('tiktok')}
				/>
				<Input
					label={'Event Website'}
					{...registerInput('website')}
				/>
			</div>
		</div>
	</>;
};