import React, {ChangeEvent, useEffect, useState} from 'react';
import { ToggleRender } from './ToggleRender';

interface ToggleProps {
	name:string
	id:string
	defaultChecked?:boolean
	activeLabel?:string,
	inactiveLabel?:string
	justify?: 'right'| 'left' |'center'
	onChange?:(e:ChangeEvent<HTMLInputElement>)=>void
}

export const Toggle = ({id,name, onChange,defaultChecked=false, ...props}:ToggleProps):JSX.Element => {
	const [switchState, setSwitchState] = useState(defaultChecked);

	useEffect(():void=>{
		setSwitchState(defaultChecked);
	},[defaultChecked]);

	const handleOnChange = (e: ChangeEvent<HTMLInputElement>):void => {
		setSwitchState((prev:boolean):boolean=> !prev);
		if (onChange)
			onChange(e);
	};
	return <ToggleRender
		name={name}
		id={id}
		switchState={switchState}
		{...props}
		onChange={handleOnChange}
	/>;
};
