import React from 'react';
import {ItemPercentage} from '../../ui';
import {WrapperScore} from '../../UserScore/styles';

interface ScoreProps {
	score: {
		talent_id:string
		instagram: {
			desired_audience:number
			audience: number
			authority: number
			match: number
		}
	}
}

export default function ScoreCircleRender({score}:ScoreProps):JSX.Element {
	return (
		<WrapperScore>
			<ItemPercentage
				label={'Audience'}
				percentage={score.instagram.audience * 100}
				size={'126px'}
			/>
			<ItemPercentage
				label={'Authority'}
				percentage={score.instagram.authority * 100}
				size={'126px'}
			/>
		</WrapperScore>
	);
}