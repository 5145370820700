const SERPAPI_KEY:string='939a5876192c16181a5d470f08acc4c323833201c272fcd9e1d5b744f9e7c909';
const SERPAPI_URL:string='https://serpapi.com/';

interface SerpapiObject {
	inline_videos:any[]
}


export const serpapiQuery= async(query:string,hl:string='en',gl:string='us'):Promise<SerpapiObject|null> =>{
	try {
		let result:any = await fetch(`${SERPAPI_URL}/search.json?api_key=${SERPAPI_KEY}&q=${query}&hl=${hl}&gl=${gl}`,{
			method:'get'
		});
		result= await result.json();
		return result;
	}
	catch (e) {
		console.log(e);
		return null;
	}
};
