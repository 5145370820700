import React, {useEffect, useState} from 'react';
import DiscoverRender from './DiscoverRender';
import {QueryTuple, useLazyQuery} from '@apollo/client';
import {query} from '../../actions/queries';
import {SetterOrUpdater, useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {
	BreadCrumbAtom,
	filterAtom,
	matchQueryAtom,
	searchingAtom,
	talentsAtom,
	talentScoresAtom,
	TCrumb
} from '../../context/recoil/store';
import {IFilter, IMatch, IScore, ITalent} from './DiscoverInterfaces';
import {UserContext, userContextInterface} from '../../context/userContext';

export default function Discover():JSX.Element {

	const {user} : userContextInterface = React.useContext(UserContext);

	useEffect(():void=>{
		console.log('Discover user', user);
	},[user]);

	// set breadcrumb
	const setBreadCrumbs: SetterOrUpdater<TCrumb[]>=useSetRecoilState(BreadCrumbAtom);
	useEffect(():void => {
		setBreadCrumbs([
			{id: 'discover', label: 'discover', path:'/discover'}
		]);
	}, []);
	///
	const filter:IFilter = useRecoilValue(filterAtom);
	const matchQuery:IMatch = useRecoilValue(matchQueryAtom);
	const [talents, setTalents] = useRecoilState<ITalent[]>(talentsAtom);
	const [talentScores, setTalentScores] = useRecoilState(talentScoresAtom);

	const [searching, setSearching] = useRecoilState<boolean>(searchingAtom);
	const [limit, setLimit] = useState<number>(20); //Initial limit
	const [skip, setSkip] = useState<number>(0);
	const [initialQuery, setInitialQuery] = useState<boolean>(true);

	// eslint-disable-next-line @typescript-eslint/typedef
	const [queryTalents, {data:talentsData,loading:talentsLoading,error:talentsError}]:QueryTuple<any, any> = useLazyQuery(query('talentFindMany'));

	// eslint-disable-next-line @typescript-eslint/typedef
	const [queryMatches, {data:matchData,loading:matchLoading,error:matchError}]:QueryTuple<any, any> = useLazyQuery(query('talentScoreFindMany'));

	/**
	 * Initial talent query
	 */
	useEffect(():void=> {
		if(initialQuery && talents.length === 0){
			queryTalents({
				variables:{
					filter,
					limit,
					skip,
					sort: '_ID_ASC',
					tenants:user.tenantList
				},
				onCompleted: (data:any):void => setTalents(data.talentFindMany),
				//errorPolicy: 'all',
				//nextFetchPolicy: 'no-cache',
				fetchPolicy:'network-only'
			});
			setInitialQuery(false);
		}
	},[initialQuery]);

	/**
	 * Search Talents
	 * - Once Completed it will search for Match Scores
	 */
	useEffect(():void=> {
		if(searching){
			setTalents([]);
			queryTalents({
				variables:{
					filter,
					limit:2500,
					skip,
					sort: '_ID_ASC',
					tenants:user.tenantList
				},
				onCompleted: getMatchScores,
				fetchPolicy:'network-only'
			});
			setSearching(false);
		}
	},[searching]);


	/**
	 * Match scores
	 * - Receives talent data
	 * - Populates the Match object
	 * - Query scores
	 * @param data
	 */
	const getMatchScores = (data:any):void => {

		setTalents(data.talentFindMany);
		if(matchQuery?.audience?.topic){
			queryMatches({
				variables:{
					...matchQuery,
					platforms:filter.audienceSize?.min ? [['instagram',filter.audienceSize.min]] : ['instagram'],
					talent: data?.talentFindMany.map((e:any):string=>e._id)
				},
				//errorPolicy: 'all',
				//nextFetchPolicy: 'no-cache',
				fetchPolicy:'network-only'
			});
		}
	};

	useEffect(():void=>{
		if(!matchLoading && matchData) {
			let s:IScore[] = matchData?.talentScoreFindMany?.scores.map((e:any):IScore=>(e));
			setTalentScores(s);
		}
	},[matchData]);



	return (
		<DiscoverRender
			loading={talentsLoading}
			loadingScores={matchLoading}
		/>
	);
}