import React from 'react';
import {Select} from '../Select';
import {Item, SelectProps} from 'interfaces';
import styled from 'styled-components';
import {InputWrapper} from 'styles/ui';
import {CloseIcon, PlusIcon} from 'icons';
import {themeOpus} from 'stylesOpus/theme';
import {FieldSection} from '../FieldSection';

export interface AppendableSelectRenderProps{
    selectProps:SelectProps
    selectedItems:Item[]
    unSelect:(item:Item)=>void
    isSelectShown:boolean
    isEmpty:boolean
    showSelect:()=>void
    error?:string
}


const DropDown:any=styled(Select)`
	margin-bottom: 0.4rem;
`;

const ItemWrapper: any = styled(InputWrapper)`
    display: flex;
  justify-content: space-between;
  align-items: center;
  >p{
    margin-bottom: 0;
    font-weight: 500;
    font-size: 0.875rem;
    font-family: 'Urbanist', Snas-serif;
    color: ${themeOpus.colors.grey_200};
  }
`;
const Label:any = styled.label`
	font-weight: 700;
	font-family: 'Urbanist', Snas-serif;
	
	display: block;
`;



export const AppendableSelectRender = ({isEmpty,isSelectShown,unSelect,selectedItems,selectProps,error,showSelect}:AppendableSelectRenderProps): JSX.Element => {

	return (
		<FieldSection
			error={error}
			label={selectProps.label}
			helperText={selectProps.helperText}
		>

			{selectedItems.map((item:Item):JSX.Element=>
				<ItemWrapper
					key = {item.id}
					localTheme={'opusV2'}
				>
					<p >{item.label}</p>
					<div className={'cursor-pointer'} onClick={():void=>unSelect(item)}>
						<CloseIcon/>
					</div>
				</ItemWrapper>
			)}
			
			<DropDown
				theme='opusV2'
				defaultValue={''}
				{...selectProps}
				label={''}
				value={''}
			/>
			
		</FieldSection>
	);
};