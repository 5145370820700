import styled from 'styled-components';
import {themeOpus} from 'stylesOpus/theme';
import {mediaQuery} from '../../../styles/mediaQueries';

export const Container: any =  styled.section`
  width: 100%;
  height: 100%;
`;

export const Grid: any =  styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  grid-column-gap: 1.75rem;
  grid-row-gap: 1.75rem;
  ${mediaQuery('laptop')}{
      grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  ${mediaQuery('tablet')}{
      grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  ${mediaQuery('mobile')}{
      grid-template-columns: repeat(1, minmax(0, 1fr));
   }
`;

export const OverLay: any = styled.div<{selected: boolean}>`
    position: absolute;
    top: 0;
    left: 0;
    right:0;
    bottom: 0;
    border-radius: 1rem;
    border ${ ({selected}: any):string => selected && '1px solid white' };
    z-index: 1;
`;

export const Wrapper: any = styled.div<{selected:boolean}>`
    position:relative;
  	width: 100%;
  	aspect-ratio: 1/1;
  	cursor: pointer;
    background-color: ${themeOpus.colors.grey_650};
    border-radius: 1rem;
  	opacity: ${ ({selected}: any):string => selected ? '1' : '0.8' };
  	&:hover{
	  opacity: 1;
	}
`;