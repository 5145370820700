import React from 'react';

export const HideIcon=():JSX.Element=>
	<svg
		width={25}
		height={24}
		viewBox="0 0 25 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1.18066 12.1804C1.18066 12.1804 5.18066 4.18042 12.1807 4.18042C19.1807 4.18042 23.1807 12.1804 23.1807 12.1804C23.1807 12.1804 19.1807 20.1804 12.1807 20.1804C5.18066 20.1804 1.18066 12.1804 1.18066 12.1804Z"
			stroke="#EFEFEF"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.1807 15.1804C13.8375 15.1804 15.1807 13.8373 15.1807 12.1804C15.1807 10.5236 13.8375 9.18042 12.1807 9.18042C10.5238 9.18042 9.18066 10.5236 9.18066 12.1804C9.18066 13.8373 10.5238 15.1804 12.1807 15.1804Z"
			stroke="#EFEFEF"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<rect
			x={1}
			y="21.7825"
			width="29.3909"
			height="1.97842"
			transform="rotate(-45 1 21.7825)"
			fill="#EFEFEF"
		/>
	</svg>
;
