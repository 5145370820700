import React from 'react';
import {listHandler, useListHandler} from 'hooks/useListHandler';
import {Item, ListInput} from 'interfaces';
import styled from 'styled-components';
import {Checkbox} from '../CheckBox';

interface CheckListProps extends  ListInput{
	theme? : 'opus' | 'opusV2'
}

const Container:any = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
	margin-bottom: 1rem;
`;


const Label:any = styled.label`
  	padding: 0.75rem  1.5rem 0.75rem 0;
	font-weight: 700;
	font-family: 'Urbanist', Snas-serif;
	display: block;
`;

const HelperText:any = styled.p`
  color: #5B5E7A;
  font-family: Lato;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
`;



export  const CheckList = ({ value,label,name,options, theme='opus',helperText,onChange}:CheckListProps):JSX.Element=>{

	const {handleSelected, handleUnselected, getSelectedItems}:listHandler = useListHandler({name,options,value,onChange});
	const selectedIds: string[]= getSelectedItems().map((i:Item):string=>i.id);
	const handleCheck= (option:Item):void=>selectedIds.includes(option.id) ? handleUnselected(option) : handleSelected(option);
	return <Container >
		<Label>{label}</Label>
		{helperText && <HelperText>{helperText}</HelperText>}
		{options.map((option:Item):JSX.Element=>
			<Checkbox
				key={option.id}
				item={option}
				onClick={():void=>handleCheck(option)}
			/>
		)}

	</Container>;
};