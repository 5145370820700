import React from 'react';
import {color} from 'styles/colors';
import {mediaQuery} from 'styles/mediaQueries';
import styled from 'styled-components';

export const StyledDivider:any=styled.div`
  width: 100%;
  height: 1rem;
  position: relative;
  hr{
	position: absolute;
	  width: 100%;
	  height: 1px;
	  border:none;
	  background-color:${color.get('neutral-600')};
	z-index: 2;
  }

  ${mediaQuery('mobile')}{
	flex-direction: column;
	justify-content: center;
	hr{
		width: 100vw;
		transform: translateX(-20px);
	}
  }
`;

export const Divider=({className}:any):JSX.Element=>{
	return<StyledDivider className={className}>
		<hr/>
	</StyledDivider>;
};
