import styled, {css} from 'styled-components';
import {color} from 'styles/colors';
import {themeOpus} from '../../stylesOpus/theme';

interface StyledTagProps{
	selected:boolean
	filled:boolean
	rounded:boolean
	localTheme?:string
}
interface StyledTagAttrs{
	color:string;
	backgroundColor:string;
	borderColor:string;
}

const selectedTheme:any={
	//color:color.get('neutral'),
	justifyContent:'center'
};
const unselectedTheme:any={
	color:color.get('neutral-300'),
	backgroundColor:'rgba(0,0,0,0)'
};

const FilledTheme:any={
	color:color.get('white'),
	borderColor:color.get('primary'),
	backgroundColor:color.get('primary')
};

const tagTheme:any =css<{localTheme:'opus'|'opusV2'}>`
	border:${(props:any):string=> props.localTheme === 'opusV2'? 'none':  `1px solid ${({borderColor}:StyledTagAttrs):string=>borderColor}` };
	background-color: ${ (props: any):string=> props.localTheme === 'opusV2'? themeOpus.colors.grey_800:  ({backgroundColor}:StyledTagAttrs):string=>backgroundColor  };
	border-radius: ${({localTheme}:StyledTagProps):string=>localTheme? '15px' :' 100%'};
`;

export const StyledTag:any=styled.div.attrs(
	({selected,filled, localTheme}:StyledTagProps):StyledTagAttrs=> {
		if (filled)
			return FilledTheme;
		return  localTheme  === 'opusV2' ? selected ? selectedTheme : unselectedTheme  : 'red';
	}
)`
	
  	display: flex;
  	align-items: center;
	width: fit-content;
  	height: 30px;
  	white-space: nowrap;
	cursor: pointer;
  	border-radius: ${({rounded}:StyledTagProps):string=>rounded? '15px' :'4px'};
	padding: 6px 10px;
	margin:0.25rem 0.2rem ;
	font-weight: 700;
	font-size: 0.75rem;
	color: ${({color}:StyledTagAttrs):string=> {
		return color;
	}};
	
	${ tagTheme };
	
  	>svg{
	  margin-left: 4px;
	}
  	>p{
	  margin-bottom: 0;
	  //color: #fff;
	  font-weight: 5300;
	  opacity: 0.7;
	}
`;
