import {Tag} from '../ui';
import styled from 'styled-components';

export const CategoriesContainer:any= styled.div`
	width: 100%;
  	position: relative;
  	display: flex;
  >div:last-child{
	height: 100%;
	width: 100px;
	position: absolute;
	right: 0;
	/* Created with https://www.css-gradient.com */
	background: linear-gradient(90deg,rgba(0,0,0,0), rgba(0,0,0,1)) 80%;
  }

	@media (max-width: 600px) {  
		padding: 0.5rem 1.5rem;
    }
`;

export const TagsWrapper:any= styled.div`
  width: 100%;
  position: relative;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-right: 50px;
  >div:first-child{
    margin-left: 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }
  scrollbar-width: none;
`;

export const ProfileTag:any= styled(Tag)`

  ${({filled}:any):string=>filled?`
		background-color: #fff;
		border:none;
 	 	color: #000;
	`:''}
  
  
`;

export const ScoresContainer:any=styled.div`
  margin: 1rem 0;
  display: grid;
  grid-template-columns: 4fr 5fr;
  >div:nth-child(2){
  	padding: 0 1rem;	
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;

	>div:nth-child(3), >div:nth-child(4) {
	  align-self: end;
	}
  }`;

export const MatchContainer: any = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding:0 5px;
  margin: 20px 0;
  >div:nth-child(2){
  	max-width: 170px;
	
  }
`;

export const WrapperScore: any = styled.div`
	display: flex;
	align-items: center;
  	gap: 1rem;
  	width: fit-content;
	margin-bottom: 20px;
`;
