import React, {createContext} from 'react';

interface userProviderProps {
	children:React.ReactNode
	handleShowMenu:()=>void
}

export interface LayoutContextInterface {
	header:{
		handleShowMenu?:()=>void
	}
}

export const LayoutContext:React.Context<LayoutContextInterface> =createContext({
	header:{}
});

const LayoutContextProvider=({children,handleShowMenu}:userProviderProps):JSX.Element=>{

	const value:LayoutContextInterface={
		header:{handleShowMenu}
	};
	return (
		<LayoutContext.Provider value={value} >
			{children}
		</LayoutContext.Provider>
	);
};
export default LayoutContextProvider;
