import React from 'react';
import {CheckBox, CheckListItem} from '../styledComponents';
import {ICheckList} from '../../DiscoverInterfaces';

export default function CheckList({_id, label, value, setValue}:ICheckList):JSX.Element {

	const setCheckListValue = ():void => {
		if(value.includes(_id)){
			let v:string[] = [...value];
			const index:number = value.indexOf(_id);
			if (index !== -1) {
				v.splice(index, 1);
				setValue(v);
			}
		}
		else{
			let v:string[] = [...value, _id];
			setValue(v);
		}
	};

	return (
		<CheckListItem
			onClick={setCheckListValue}
			fontWeight={value?.includes(_id) ? '700' : '500'}
			color={value?.includes(_id) ? 'white' : ''}
		>
			<CheckBox background={value?.includes(_id) ? 'green' : ''} /> {label}
		</CheckListItem>
	);
}