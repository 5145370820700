import React, {useEffect, useState} from 'react';
import {useLazyQuery} from '@apollo/client';
import {query} from '../../../actions/queries';
import {Link} from 'react-router-dom';
import {TacticI} from '../../../interfaces';
import {ReactComponent as ChevronLeft} from 'styles/icons/chevron-left.svg';
import styled from 'styled-components';

interface HotlistTacticsI {
	tactics?:string[]
}

const ChevronRight :any = styled(ChevronLeft)`
	rotate: 180deg;
`;


export default function HotlistTactics({tactics}:HotlistTacticsI):JSX.Element {

	const [loading, setLoading] = useState<boolean>(true);

	// eslint-disable-next-line @typescript-eslint/typedef
	const [queryEntries, { data }] = useLazyQuery(query('tacticsFindMany'));

	const getLabel = (id:string):string => {
		const ts:TacticI[] = data?.tacticFindMany;
		const t:TacticI = ts.find((e:any):boolean=>e._id === id);
		return t ? t.title : id;
	};

	const getSlug = (id:string):string => {
		const ts:TacticI[] = data?.tacticFindMany;
		const t:TacticI = ts.find((e:any):boolean=>e._id === id);
		return t ? t.slug : id;
	};

	useEffect(():void=>{
		if(loading){
			queryEntries({
				variables: {
					filter: {
						active: true
					}
				},
				fetchPolicy: 'no-cache',
				onCompleted: (data:any):void => setLoading(false)
			});
		}
	},[loading]);

	return(
		<div>
			{!loading &&
				<div className="grid3 mt col-gap">
					{tactics && tactics.length > 0 && tactics.map((id:string, index:number):JSX.Element=>{
						return (
							<Link to={`/opportunities/${getSlug(id)}/${id}`} className={`neutral-840 br block p-2`} key={`${id}-${index}`}>
								<div className="flex align-center">
									<h4 className="grows t-white f-lato f-bold f-14px mb">
										{getLabel(id)}
									</h4>
									<ChevronRight />
								</div>
								<p className="t-neutral-200 f-lato f-14px">
									Lorem ipsum dolor sit amet consectetur. Id enim tincidunt in tincidunt amet at. Viverra pellentesque turpis sed nibh porta at nam.
								</p>
							</Link>
						);
					})}
				</div>
			}
		</div>
	);
}