import React from 'react';
import {Card} from './styles';

export interface CardProps{
	theme? : 'opus' | 'opusV2'
	color? : string
	children:React.ReactNode
	className?:string;
	padding?:string
	margin?:string
	borderRadius?:string
	$gap?:string
	$flexGrow?:string
	$height?:string

	$justifyContent?:string
	$alignItems?:string
}

export default function CardRender({children,...props}:CardProps):JSX.Element{
	return (
		<Card
			{...props}
		>
			{children}
		</Card>
	);
}
