import React from 'react';
import {TotalReachRender} from './TotalReachRender';

interface TotalReachProps {
	reach:number
}

export const TotalReach=({reach}:TotalReachProps):JSX.Element=>{
	return <TotalReachRender
		reach={reach.toString()}
	/>;
};