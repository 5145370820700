import React from 'react';

export const FacebookIcon=():JSX.Element=>{
	return <svg
		width={40}
		height={40}
		viewBox="0 0 40 40"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect width={40} height={40} rx={15} fill="#3B5998"/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M25 11.1298V13.9856H23.3646C22.0833 13.9856 21.8438 14.6238 21.8438 15.5433V17.5877H24.8958L24.4896 20.7897H21.8438V29H18.6562V20.7897H16V17.5877H18.6562V15.2296C18.6562 12.4928 20.2708 11 22.625 11C23.75 11 24.7188 11.0865 25 11.1298Z"
			fill="white"
		/>
	</svg>;
};
