import React from 'react';

export const ArrowLeft=(props: any):JSX.Element=>{
	const {fill,count,width,height}:any = props;

	return<svg
		width={width || 7}
		height={height || 9}
		viewBox="0 0 7 9"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d={!count ? 'M0.282593 4.35868L6.82061 8.13341L6.82061 0.583952L0.282593 4.35868Z' : 'M7 13L1 7l6-6'}
			fill={fill || '#FF5656'}
			stroke={count && '#fff'}
		/>
	</svg>;
};