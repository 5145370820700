import React, {useContext} from 'react';
import {BodyText, BoxTitle} from 'stylesOpus/typography';
import {Card,Toggle} from 'components';
import {Spinner} from 'components/ui';
import {GenericContainer} from 'styles/styledComponents/containers';
import {ReactComponent as CalendarIcon} from 'styles/icons/calendar.svg';
import {themeOpus} from 'stylesOpus/theme';
import {Item, Opportunity, OpportunityAudience} from 'interfaces';
import {WithGenericItem} from 'hocs/WithGenericItem';
import {GeneralDataContext, GeneralDataContextInterface} from 'context/genalDataContext';
import {formatDate} from 'tools/date';
import {MutationFunction, MutationTuple, useMutation} from '@apollo/client';
import {mutation} from 'actions/mutations';
import {useParams,Params} from 'react-router-dom';

type OpportunityInfoProps = {
	opportunity?:Opportunity
	oppAudience?: OpportunityAudience
}
export const OpportunityInfo = ({opportunity, oppAudience}:OpportunityInfoProps):JSX.Element=>{
	const {id}:Params = useParams();
	const { genders, ethnicities,countries, getLabelList }:GeneralDataContextInterface =useContext(GeneralDataContext);
	// eslint-disable-next-line @typescript-eslint/typedef
	const [updateOpportunity,{loading:updateLoading}]:MutationTuple<MutationFunction, any> =useMutation(mutation('opportunityUpdateOne'));
	const handleIsActive =  ({target}: React.ChangeEvent<HTMLInputElement>):void => {
		updateOpportunity({variables:{
			_id:id,
			record:{
				active: target.checked
			}}});
	};
	return (
		<>
			<div className={'w-100 flex justify-between align-center'}>
				<BoxTitle>Dates</BoxTitle>
				{updateLoading ?
					<Spinner/>
					:
					<div className={'flex col-gap align-center'}>
						<BodyText className={'f-700'} color={themeOpus.colors.light_grey_200} >{ opportunity.active? 'ACTIVE' : 'DRAFT' }</BodyText>
						<Toggle
							name={'draft'}
							id={'draft'}
							justify={'right'}
							defaultChecked={opportunity?.active}
							onChange={handleIsActive}
						/>
					</div>
				}
			</div>
			<Card color={'#181921'} theme = 'opusV2' className='flex direction-column row-gap-half'>
				{opportunity.expirationDate && <GenericContainer padding={'0.5rem 1rem'} color={themeOpus.colors.grey_700}>
					<CalendarIcon/>
					<p className={'mhor'}>Expiration Date</p>
					<BodyText>{formatDate(opportunity.expirationDate)}</BodyText>
				</GenericContainer>}
				<div className={'w-100 flex col-gap'}>
					<GenericContainer className={'grows'} padding={ '0.5rem 1rem'} color={themeOpus.colors.grey_700}>
						<CalendarIcon/>
						<BodyText fontFamily={`'Urbanist', sans-serif`} color={'white'} className={'mhor '}>Start date</BodyText>
						<BodyText fontFamily={`'Urbanist', sans-serif`} color={'white'}>{ formatDate(opportunity.startDate) }</BodyText>
					</GenericContainer>

					<GenericContainer className={'grows'}  padding={ '0.5rem 1rem'} color={themeOpus.colors.grey_700}>
						<CalendarIcon/>
						<BodyText fontFamily={`'Urbanist', sans-serif`} color={'white'} className={'mhor'}>End date</BodyText>
						<BodyText fontFamily={`'Urbanist', sans-serif`} color={'white'}>{ formatDate(opportunity.endDate) }</BodyText>
					</GenericContainer>
				</div>

			</Card>


			<BoxTitle>Audience Details</BoxTitle>
			<Card color={'#181921'} theme = 'opusV2' className='flex direction-column row-gap-half'>
				<BoxTitle fontSize ='0.75rem'>Interest(s)</BoxTitle>

				<div className={'flex col-gap'}>
					{oppAudience?.interests?.map((item:string) :React.ReactNode=>
						<WithGenericItem key={item} id={item} findOneAction={'interestFindOne'}>
							{({label}:Item):React.ReactNode=>
								<GenericContainer padding={'0.5rem 1rem'} color={themeOpus.colors.grey_700}>
									<p className={'f-700'}>{label}</p>
								</GenericContainer>
							}
						</WithGenericItem>
					)}
				</div>
			</Card>

			<Card color={'#181921'} theme = 'opusV2' className='flex direction-column row-gap-half'>
				<BoxTitle fontSize ='0.75rem'>Age Range</BoxTitle>
				{oppAudience?.ageRange.length > 0 && <div>
					<GenericContainer padding={'0.5rem 1rem'} color={themeOpus.colors.grey_700}>
						<p className={'f-700'}><>{oppAudience.ageRange[0]?.min} - {oppAudience.ageRange[0]?.max}</>
						</p>
					</GenericContainer>
				</div>}
			</Card>

			<Card color={'#181921'} theme = 'opusV2' className='flex direction-column row-gap-half'>
				<BoxTitle fontSize ='0.75rem'>Gender(s)</BoxTitle>
				<div className={'flex col-gap'}>
					{oppAudience?.genders&& getLabelList(oppAudience.genders,genders) .map((item:string) :React.ReactNode=>
						<GenericContainer
							key={item}
							padding={'0.5rem 1rem'} color={themeOpus.colors.grey_700}>
							<p className={'f-700'}>{item}</p>
						</GenericContainer>
					)}
				</div>
			</Card>

			<Card color={'#181921'} theme = 'opusV2' className='flex direction-column row-gap-half'>
				<BoxTitle fontSize ='0.75rem'>Ethnicity(s)</BoxTitle>
				<div className={'flex col-gap'}>
					{oppAudience?.ethnicity&& getLabelList(oppAudience.ethnicity,ethnicities) .map((item:string) :React.ReactNode=>
						<GenericContainer
							key={item}
							padding={'0.5rem 1rem'} color={themeOpus.colors.grey_700}>
							<p className={'f-700'}>{item}</p>
						</GenericContainer>
					)}
				</div>
			</Card>

			<Card color={'#181921'} theme = 'opusV2' className='flex direction-column row-gap-half'>
				<BoxTitle fontSize ='0.75rem'>Location(s)</BoxTitle>
				<div className={'flex col-gap'}>
					{oppAudience?.country&& getLabelList(oppAudience.country,countries) .map((item:string) :React.ReactNode=>
						<GenericContainer
							key={item}
							padding={'0.5rem 1rem'} color={themeOpus.colors.grey_700}>
							<p className={'f-700'}>{item}</p>
						</GenericContainer>
					)}
				</div>
			</Card>
		</>
	);
};