import React from 'react';
import { Outlet} from 'react-router-dom';
import {ContentLayout} from 'containers';
import {SettingsMenu} from './components';



export const SettingsRender = (): JSX.Element => {

	return (
		<ContentLayout
			column={
				<SettingsMenu/>
			}
		>
		
			<Outlet/>
			
		</ContentLayout>
	);
};
