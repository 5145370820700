import React, {useEffect, useState} from 'react';
import AutoFillInput from '../form/AutoFillInput';
import {QueryResult, QueryTuple, useLazyQuery, useQuery} from '@apollo/client';
import {query} from '../../../../actions/queries';
import {IMatch, IMatchAudience, IOption} from '../../DiscoverInterfaces';
import {MultiSelectTag} from '../styledComponents';
import {CloseIcon} from '../../../../icons';
import {useRecoilState} from 'recoil';
import {citiesFormAtom, matchQueryAtom} from '../../../../context/recoil/store';

export default function AudienceCityRender():JSX.Element {

	const [matchQuery, setMatchQuery] = useRecoilState<IMatch>(matchQueryAtom);
	const [citiesForm, setCitiesForm] = useRecoilState<IOption[]>(citiesFormAtom);
	const [options, setOptions] = useState<IOption[]>([]);
	const [optionsFilter, setOptionsFilter] = useState<IOption[]>([]);
	const [optionsSelected, setOptionsSelected] = useState<IOption[]>([]);
	const [keyword, setKeyword] = useState<string>('');
	const [loadingC, setLoadingC] = useState<boolean>(true);

	// eslint-disable-next-line @typescript-eslint/typedef
	const [searchCities, {data,loading,error}]:QueryTuple<any, any> = useLazyQuery(query('citySearchMany'));

	useEffect(():void=> {
		if(data?.citySearchMany?.length > 0 ){
			let c:IOption[] = data.citySearchMany.map((e:any):IOption => {
				return {
					_id: e._id,
					value:e.label.toLowerCase(),
					label: e.label
				};
			});
			c.sort((a:any, b:any):any =>  a.label.localeCompare(b.label));
			setOptions(c);
			setOptionsFilter(c);
		}
	}, [data]);

	useEffect(():void => {
		const query:string = keyword.toLowerCase();

		if(query.length > 2) {
			searchCities({
				variables:{
					query
				},
				fetchPolicy:'network-only'
			});
		}

	},[keyword]);

	const selectOption = (sel:IOption):void =>{
		let os:IOption[] = [...optionsSelected];
		if (!os.includes(sel)){
			os.push(sel);
			setOptionsSelected(os);
		}
	};

	const deleteOption = (id:string):void =>{
		let os:IOption[] = optionsSelected.filter((e:IOption):boolean => e._id !== id);
		setOptionsSelected(os);
	};

	useEffect(():void => {

		let m: IMatch = {...matchQuery};
		let a: IMatchAudience = {...matchQuery.audience};
		m.audience = a;
		m.audience.cities = optionsSelected.map(({value}:IOption):string=>value);
		setMatchQuery(m);

		setCitiesForm(optionsSelected);

	}, [optionsSelected]);

	useEffect(():void=>{
		if(loadingC) {
			if(citiesForm && citiesForm.length > 0){
				setOptionsSelected(citiesForm);
			}
			setLoadingC(false);
		}
	},[loadingC]);


	return (
		<div>
			<AutoFillInput
				title=""
				options={optionsFilter}
				placeholder="Search Cities"
				keyword={keyword}
				setKeyword={setKeyword}
				selectOption={selectOption}
			/>

			<div className="mt">
				{optionsSelected.map(({label,_id,value}:IOption):any => {
					return(
						<MultiSelectTag key={_id} onClick={():void=>deleteOption(_id)}>
							{label} <CloseIcon color="#626579" width={14} height={14} />
						</MultiSelectTag>
					);
				})}
			</div>
		</div>
	);
}