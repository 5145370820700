import React from 'react';
import {ImageContainer} from 'styles/styledComponents/containers';
import {BodyText} from 'stylesOpus/typography';
import {formatDate} from 'tools/date';
import {Tag} from 'styles/styledComponents/GlowTag';
import {ArticleContainer, PaddingContainer, TextBody, TitleContainer} from './articleCard.styles';

export type TArticle = {
	title: string
	subtitle?: string
	body?:string
	picture?: string
	date: string
	link?: string
}

export type ArticleCardProps = {
    article: TArticle
    isNew?: boolean
}

export const ArticleCard = ({article, isNew}:ArticleCardProps): JSX.Element => {

	return (
		<ArticleContainer to ={article.link}>
			{isNew && <Tag $color className ='absolute top-half left-half ' >New</Tag>}
			<ImageContainer $src={article.picture} $width={'100%'} $aspectRatio={'3/2'} $borderRadius={'1rem'}/>
			<div className={'flex direction-column'}>
				<BodyText fontSize={'12px'}>
					{article?.date &&
						new Date(article.date.replace(/-/g, '/')).toLocaleString('en-US', {
							month: 'short',
							day: 'numeric',
							year: 'numeric'
						})
					}
				</BodyText>
				<PaddingContainer>
					<TitleContainer
						fontWeight={'extraBold'}
						fontSize={'16px'}
					>
						{article.title}</TitleContainer>
				</PaddingContainer>
				<PaddingContainer>
					<TextBody padding>{article.subtitle}</TextBody>
				</PaddingContainer>
			</div>
		</ArticleContainer>
	);
};
