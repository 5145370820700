import React from 'react';
import AvatarRender, {AvatarUser} from './AvatarRender';


interface AvatarProps{
	user?:AvatarUser;
	size:string;
	plus?:boolean
	fontSize?:string;
	rounded?:string
}

export const Avatar=({user,plus,rounded,...props}:AvatarProps):JSX.Element=>{
	let userInitials:string=`${user?.firstName?.charAt(0)} ${user?.lastName?.charAt(0)}`;
	return <AvatarRender
		profilePicture={user?.profilePicture}
		userInitials={userInitials}
		plus={plus}
		rounded={rounded}
		{...props}
	/>;
};
