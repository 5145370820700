import React from 'react';
import {Modal} from 'components/Modal';
import {Card, GenericHeader} from 'components';
import {CloseIcon} from 'icons';
import {Button} from 'components/ui';
import {NavigateFunction, useNavigate} from 'react-router-dom';

interface CreateAlertModal {
	close:()=>void
}

export const CreateAlertModal =({close}:CreateAlertModal):JSX.Element=>{
	const navigate:NavigateFunction = useNavigate();
	const navigateTo=(path:string):void=>{
		navigate(path);
		close();
	};
	return <Modal onClickOut={close}>

		<Card>
			<GenericHeader
				Element={CloseIcon}
				title={'Create an Opportunity'}
				action={close}
			/>
			<div className={'flex direction-column justify-center align-center w-400px phor-2 pb-2'}>
				<p className={'mver-2'}>Select a Type</p>
				<Button
					className={'mver'}
					label={'music video'}
					variant={'secondary'}
					onClick={():void=>navigateTo('/company/alerts/new/musicvideo')}
				/>
				<Button
					label={'events'}
					onClick={():void=>navigateTo('/company/alerts/new/event')}
				/>
			</div>
		</Card>

	</Modal>;
};