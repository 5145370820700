import React from 'react';
import {PillTabsRender} from './PillTabsRender';
import {Item} from 'interfaces';

export interface PillTabsProps {
	options:Item[]
	value:string
	setValue:(value:string)=>void
}

export const PillTabs =({options,value,setValue}:PillTabsProps):JSX.Element=>{

	const handleOnClick=(value:string):void=> setValue(value);

	return <PillTabsRender
		options={options}
		currentOption={value}
		handleOnClick={handleOnClick}
	/>;
};