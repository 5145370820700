import React from 'react';
import styled from 'styled-components';
import {BoxTitle} from 'stylesOpus/typography';
import {Link} from 'react-router-dom';
import {ImageContainer} from 'styles/styledComponents/containers';

type TCategory ={
	picture?:string
	text?:string
	color?:string
	path?:string

}

export type CategoryBoxProps = {
	width?:string
	height?:string
	category:TCategory
	textBottom?:string
	textLeft?:string
}

type TContainerProps={
	$width?:string
	$height?:string

	$textBottom?:string
	$textLeft?:string
}

const Container:any = styled.div<TContainerProps>`
	position: relative;
	width: ${({$width}:TContainerProps):string => $width ? $width : '100%'};
	height: ${({$height}:TContainerProps):string => $height ? $height : '100%'};
	border-radius: 1rem;
	overflow: hidden;
	a> {
		width: inherit;
		height: inherit;
    }	

  >h3{
    position: absolute;
	  font-size: 20px;
	  bottom: ${({$textBottom}:TContainerProps):string => $textBottom ? $textBottom : '0.62rem'};
	  left: ${({$textLeft}:TContainerProps):string => $textLeft ? $textLeft : '0.62rem'};
  }
`;



export const CategoryBox = ({category, width, height, textBottom, textLeft}:CategoryBoxProps):JSX.Element => {

	return (
		<Container $width={width} $height={height} $textBottom ={textBottom} $textLeft={textLeft} >
			<Link to={category.path}>
				<ImageContainer $src={category.picture} $width={'100%'} $height={'100%'}/>
			</Link>
			<BoxTitle
				fontFamily={`'Urbanist', sans-serif`}
				color={category.color}
			>{category.text}</BoxTitle>
		</Container>
	);
};