import React, {useState} from 'react';
import styled from 'styled-components';
import {Opportunity} from 'interfaces';
import {ImageCard} from '../ui';
import {color} from 'styles/colors';
import {StartFilledIcon, StarUnfilledIcon} from '../../icons';

interface OpportunityBlockProps {
	opportunity:Opportunity
}

const StyledTitle:any=styled.h6`
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: ${color.get('primary')};
`;

const StyledDescription:any=styled.p`
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
`;

export const OpportunityBlock=({opportunity}:OpportunityBlockProps):JSX.Element=>{
	const [liked,setLiked]=useState<boolean>(false);
	//const {user}:any =useContext(UserContext);
	// const {data:profileData}:QueryResult=useQuery(query('usersCompanyProfileFindOne'),{
	// 	variables:{user:opportunity?.company?._id}
	// });
	return <div className={'mt'}>
		<ImageCard img={ opportunity.image&& opportunity.image[0]?.url }/>

		<div className='w-100 flex justify-between mt'>
			<StyledTitle >{opportunity.name}</StyledTitle>
			{ liked ?
				<div onClick={():void=>setLiked(false)}>
					<StartFilledIcon/>
				</div>
				:
				<div onClick={():void=>setLiked(true)}>
					<StarUnfilledIcon/>
				</div>
			}
		</div>
		<StyledDescription >{opportunity.name}</StyledDescription>

	</div>;
};