import React, {useContext, useEffect} from 'react';
import {CreateAlertRender} from '../CreateAlert/CreateAlertRender';
import { NavigateFunction, Params, useNavigate, useParams} from 'react-router-dom';
import {MutationTuple, QueryResult, useMutation, useQuery} from '@apollo/client';
import {mutation} from 'actions/mutations';
import {FormField, FormHook} from 'hooks/useForm/interfaces';
import {useForm} from 'hooks/useForm/useForm';
import {query} from 'actions/queries';
import {UserContext, userContextInterface} from 'context/userContext';
import {CulturalAlert} from 'interfaces';

const getFieldsByEventType=(eventType:string,alertData:CulturalAlert):FormField[]=>{

	let talents:string[]=alertData.artist?.length>0 ?[alertData.artist[0]] : [];
	const fieldsByAlertType:any= {
		event:[
			{name:'talents',required:true,default: talents},
			{name:'title',required:true, default:alertData.title},
			{name:'description',required:true,watch:true, default:alertData.description },
			{name:'eventCategory', default:alertData.eventCategory},
			{name:'instagram', default:''},
			{name:'tiktok', default:''},
			{name:'website', default:alertData.website}
		],
		musicvideo:[
			{name:'videoSongTitle',  default:alertData.videoSongTitle},
			{name:'videoSongUrl', default:alertData.videoSongTitle }
		]
	};

	return fieldsByAlertType[eventType||'event'];
};


export const EditAlert = ():JSX.Element => {
	const navigate:NavigateFunction=useNavigate();
	const {eventType}:Params=useParams();
	const {id}:Params=useParams();
	const {user}:userContextInterface=useContext(UserContext);

	const {data:alertsData}:QueryResult =useQuery(query('culturalAlertFindMany'),
		{variables:{
			filter:{
				_id:id,
				user:user._id
			}
		}});
	const getNextPath= (alertId:string,eventType:string):string=>{
		const nextPathByAlertType:any={
			'event':`/company/alerts/${alertId}/edit/location`,
			'musicvideo': `/company/alerts/${alertId}/edit/music-event`
		};
		return nextPathByAlertType[eventType];
	};
	// update
	const [updateAlert]:MutationTuple<any, any> =useMutation(mutation('culturalAlertUpdateOne'));

	const onSubmit= async(data:any):Promise<void>=>{
		data.artist=data.talents[0] && data.talents[0];
		// handle artist
		delete data.talents;
		!data.artist && delete data.artist;

		await updateAlert({
			variables:{
				_id:alertsData?.culturalAlertFindMany[0]._id,
				record:{...data}
			}
		});
		navigate(getNextPath(alertsData?.culturalAlertFindMany[0]._id,eventType));


	};

	const fields:FormField[]=getFieldsByEventType(eventType,alertsData?.culturalAlertFindMany[0]||{});
	const {loading,errors,resetForm,...rest}:FormHook=useForm(fields,onSubmit);

	useEffect(():void=>{
		resetForm();
	},[alertsData]);

	return <CreateAlertRender
		typeEvent={eventType}
		submitLoading={loading}
		errors={errors}
		onSubmit={rest.handleSubmitForm}
		registerInput={rest.registerInput}
		registerList={rest.registerList}
		registerSelect={rest.registerSelect}
		registerTextArea={rest.registerTextArea}
	/>;
};