import React from 'react';

interface IconProps {
	size?: number
	color?: string
}


export const CaratUp=({size,color='white'}:IconProps):JSX.Element=>{
	
	return <svg
		width={size}
		height={size}
		viewBox="0 0 14 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M1 7L7 1L13 7"
			stroke={color}
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>;
};
