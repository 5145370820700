import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {KebabIcon} from 'icons';
import {ImageWrapper} from '../RoundImage/styles';
import {EmptyImage} from '../EmptyImage';

interface ItemProps {
	label:string
	subLabel?:string
	picture?:string
	navigateTo?:string
	icon?:JSX.Element
}

export const ItemContainer: any = styled.div`
  color:white;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 4px 0;
  justify-content: space-between;
  padding: 10px 20px 10px 10px;
  background-color: #1F1F21;
  border-radius: 20px;
`;


export const ContentWrapper: any = styled.div`
    display: flex;
    align-items: center;
`;

export const ItemUI = ({label,subLabel,icon,picture,navigateTo}:ItemProps):JSX.Element => {
	return <ItemContainer to={navigateTo}>
		<ContentWrapper>
			{picture?<ImageWrapper size="54px" rounded="20%">
				<img src={picture} alt={''}/>
			</ImageWrapper>
				:<EmptyImage size="54px" plus rounded="20%"/>
			}
			<div className="ml-2">
				<p >{label}</p>
				<p className={'f-small'}>{subLabel}</p>
			</div>
		</ContentWrapper>
		{navigateTo?
			<Link to={navigateTo}>
				<KebabIcon/>
			</Link>
			:icon
		}

	</ItemContainer>;
};