import styled from 'styled-components';
import {color} from 'styles/colors';
import {mediaQuery} from 'styles/mediaQueries';

export const SocialInfoWrapper:any=styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
  ${mediaQuery('tablet')}{
	display: block;
  }
`;

export const AvatarWrapper:any=styled.div`
  width: 222px ;
  aspect-ratio: 1;
  margin-right: 2rem;
  ${mediaQuery('tablet')}{
    width: 120px;
  }
`;

export const InfoWrapper:any=styled.div`
  
  font-size: 32px;
  line-height: 39px;
  font-weight: 700;
  text-transform: capitalize;
  
  :not(:last-child){
    margin-bottom: 1.5rem;
  }
  
  >p:last-child{
    font-size: 12px;
    line-height: 15px;
  }
  >p:last-child{
	color: ${color.get('neutral-400')};
  }
`;


export const ChartsContainer:any=styled.div`
	
`;