import React from 'react';
import {Route} from './interfaces';
import {CategoryDetail, HotList} from 'pages';
import {AuthRoute, Layout} from '../containers';
import {HotListDetail} from 'pages/HotListDetail';
import {LatestDetail} from '../pages/CategoryDetail/LatestDetail';
import {FeaturedDetail} from '../pages/CategoryDetail/FeaturedDetail';


export const hotListRoutes:Route[] =[
	{
		path: '/hotlist',
		element: <AuthRoute><Layout styleTheme={'opusV2'}/></AuthRoute>,
		children: [
			{
				path: '',
				element: <HotList />
			},
			{
				path: ':item/detail',
				element: <HotListDetail/>
			},
			{
				path: 'latest',
				element: <LatestDetail/>
			},
			{
				path: 'home/:index',
				element: <FeaturedDetail />
			},
			{
				path: ':category',
				element: <CategoryDetail/>
			},
			{
				path: ':category/:item/detail',
				element: <HotListDetail/>
			}
		]
	}

];
