export enum UserType {
	'Regular'='90b4abc2-11c4-11ed-861d-0242ac120002',
	'Company'='5b7bd05a-32d7-11ed-a261-0242ac120002',
	'Agent'='5b7bceb6-32d7-11ed-a261-0242ac120002'
}

export type TUserType = keyof typeof UserType;

export enum UserTypeLabel {
	'regular'='Regular',
	'brand'='Company',
	'Company'='Agent'
}
