import React, {ReactNode} from 'react';
import {Tabs} from 'components/Tabs';
import {AllFavorites, FavoriteCard} from './components';
import {WithCulturalAlert} from '../../hocs';
import {CulturalAlertResponse} from 'hocs/WithCulturalAlert';
import {Spinner} from 'components/ui';
import {TStrapiItem} from 'interfaces/strapi';
import {ArticlesGrid, CardItem} from 'components';

type FavoritesRenderProps={
	talents :string[]
	opportunities:string[]
	hotlistArticles:TStrapiItem[]
	currentTab:string
	onchangeTab:(tab:string)=>void
}

export const FavoritesRender = ({ talents,opportunities,hotlistArticles,currentTab,onchangeTab }:FavoritesRenderProps):JSX.Element => {
	const onSelectTab= (tab:any):void => {
		onchangeTab(tab);
	};

	return (
		<div className={'w-100 h-100'}>
			<Tabs
				defaultTab={currentTab}
				tabs={[
					{ label: 'All', identifier: 'all' },
					{ label: 'Opportunities', identifier: 'opportunities' },
					{ label: 'Talent Profiles', identifier: 'talents'},
					{ label: 'Hotlist Articles', identifier: 'hotlist'}
				]}
				onChange={onSelectTab}
			/>
			<div className={'w-100 h-100 mt pt-half'}>
				{ currentTab === 'all' || !currentTab?
					<AllFavorites
						talentsSlice={talents.slice(0,4)}
						opportunitiesSlice={opportunities.slice(0,4)}
						hotlistArticlesSlice={hotlistArticles.slice(0,4)}/>
					:null
				}

				{
					currentTab === 'opportunities' ?
						<ArticlesGrid columns={4} title={opportunities?.length > 0 ? 'Saved Opportunities' : 'Your favorited opportunities will appear here'}>
							{opportunities.map((opportunity: string): ReactNode =>
								<WithCulturalAlert id={opportunity} key={opportunity}>
									{({data:culturalAlert, loading}:CulturalAlertResponse): JSX.Element =>
										loading ? <Spinner/> :
											<CardItem
												key={culturalAlert?._id}
												title={culturalAlert?.title}
												subtitle={''}
												picture={culturalAlert?.photos[0]?.url}
												link={`/opportunities/${culturalAlert?._id}`}
											/>
									}
								</WithCulturalAlert>
								
							)}
						</ArticlesGrid>
						:null
				}

				{
					currentTab === 'talents' ?
						<ArticlesGrid columns={4} title={talents?.length > 0 ? 'Saved Talent' : 'Your favorited talent will appear here'}>
							{talents.map((talent: string): ReactNode =>
								<FavoriteCard talentId={talent} key={talent} />
							)}
						</ArticlesGrid>
						:null
				}


				{
					currentTab === 'hotlist' ?
						<ArticlesGrid columns={4} title={hotlistArticles?.length > 0 ? 'Saved Hotlist Articles' : 'Your favorited talent will appear here'}>
							{hotlistArticles.map((ha:TStrapiItem): ReactNode =>
								<CardItem
									link={`/hotlist/${ha.id}/detail`}
									key={ha.id}
									picture={ha?.attributes.cover.data.attributes.url}
									title={`${ha?.attributes.title}`}
									subtitle={''}
								/>
							)}
						</ArticlesGrid>
						:null
				}

			</div>

		</div>
	);
};