import React from 'react';
import html2canvas from 'html2canvas';

import {BookMarkIcon, CloseIcon} from 'icons';
import {ContentBox, Flex} from 'stylesOpus/layout';
import {Avatar, Tag, ButtonSmall, Circle} from 'stylesOpus/ui';
import {BodyText} from 'stylesOpus/typography';
import {AudienceItem} from 'pages/Insights/interfaces';
import {TalentInfo} from '../../TalentInfo';
import {TalentData} from 'hooks/useTalentDetail';

interface TalentDetailRenderProps {
	id: string
	data: TalentData
	close: ()=>void
	setShowAddList: (show:boolean)=>void
	add: boolean
	audience?: {
		audienceCountry?:AudienceItem[]
		audienceCity?:AudienceItem[]
		audienceBrand?:AudienceItem[]
		audienceInterests?: AudienceItem[]
		audienceGenderAge?: AudienceItem[]
		audienceEthnicities?: AudienceItem[]
		followersCount?: number
	}
	score?:any
}



export default function TalentDetailRender({id, data, close, setShowAddList, add, audience, score}:TalentDetailRenderProps):JSX.Element{

	function takeSnapShot():void {
		// @ts-ignore
		html2canvas(document.querySelector('#capture'), {scale: 2, useCORS: true, allowTaint: false})
		// @ts-ignore
		.then( (canvas9:any):any => {
			//document.body.appendChild(canvas9);
			// @ts-ignore
			let theimage9:any = canvas9.toDataURL('image/png');
			// @ts-ignore
			const element:any = document.createElement('a');
			element.download = 'profile.png';
			element.href = theimage9;
			element.click();
			element.remove();

		});
	}

	return (
		<ContentBox background="grey_1000" className="mb-2" padding="24px" id="capture">

			<ContentBox className="relative">
				<a className="top-1 right-1 absolute" onClick={close}>
					<Circle color='grey_1000'>
						<CloseIcon width={18} height={18} />
					</Circle>
				</a>
				<div className="p">
					<Flex>
						<Avatar width='106px' height='106px' borderWidth='5px' >
							<img src={data?.userObj?.profilePicture?.url} alt={`${data?.userObj?.firstName} ${data?.userObj?.lastName}`}/>
						</Avatar>

						{/*<img src="/logo512.png" />*/}

						<div className="ml-2 max-w-400px">
							<h3 className="mb-half">{data?.userObj?.firstName} {data?.userObj?.lastName}</h3>
							{score > 0 &&
								<Tag color='blue' borderRadius='4px' height='22px' fontSize='12px'>
									{(Number(score)*100).toFixed(2)}% MATCH
								</Tag>
							}

							{data?.description &&
								<BodyText className="mt">{data?.description}</BodyText>
							}
						</div>
					</Flex>
				</div>

				<div className="bottom-2 right-2 absolute">
					{add ?
						<ButtonSmall variant="white" onClick={(): void => setShowAddList(true)}>
							<span className="mr-half">Add Talent to a List</span> <BookMarkIcon color='black' size={15}  />
						</ButtonSmall>
						:
						<ButtonSmall variant="white">
							Remove from List -
						</ButtonSmall>
					}

					<ButtonSmall margin="12px 0 0" variant="white" onClick={takeSnapShot}>
						<span className="mr-half">Download Profile</span>
					</ButtonSmall>
				</div>


			</ContentBox>
			<TalentInfo audience={audience} talentData={data}/>
		</ContentBox>
	);
}