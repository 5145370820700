import React from 'react';
import {TabLinkDiv} from './styles';

interface TabLinkProps {
	action?:()=>void;
	selected?:boolean;
	children?:React.ReactNode;
	onClick ?: ()=>void;
}

export default function TabStatic({children, action, selected,onClick}:TabLinkProps):JSX.Element {
	return (
		<a onClick={action} className="inline-flex mr">
			<TabLinkDiv  selected={selected} onClick={onClick}>
				{children}
			</TabLinkDiv>
		</a>
	);
}