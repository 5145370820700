import React from 'react';
import PressCardRender, {PressCardRenderProps} from './PressCardRender';


export const PressCard=({...props}:PressCardRenderProps):JSX.Element=>{

	const fakePressInfo:any={
		videoUrl:'https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.webm',
		title:'Jahki Howard is the bOUNCIEST HOOPER In the Class of 2024...',
		body:`DT Academy went down in Atlanta this weekend and the roster was stacked. 5-star Kanaan Carlyle went off, and Jahki Howard battled it out...

	YouTube · SLAM HIGH SCHOOL
	Sep 19, 2021`
	};

	return <PressCardRender
		{...fakePressInfo}
		{ ...props}
	/>;
};
