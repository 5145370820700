import React from 'react';
import {FormProps, UserType} from 'interfaces';
import { Message, SectionUi, Steps} from 'components';
import {Divider, FormButton, UserSearch} from 'components/ui';
import {color} from 'styles/colors';
import {Music} from './componetns/Music';
import {Event} from './componetns';

interface CreateAlertRenderProps extends FormProps{
	typeEvent:string

	fileHandlers?:{
		deck?:(file:File)=>void
		videoTreatment?:(file:File)=>void
	}
}

export const CreateAlertRender = ({fileHandlers,typeEvent,errors,submitLoading,registerList,registerInput,registerTextArea,registerSelect,onSubmit}:CreateAlertRenderProps):JSX.Element => {
	const isMusic :boolean = !(typeEvent.toLowerCase() === 'event');

	return<>
		<form className={'w-100 mt-4'} onSubmit={onSubmit}>
			<SectionUi
				title={''}
			>
				<h4 className={'mb'}>Talent Info</h4>
				<p className={'f-small mb-2'}>Select one or multiple artists for the alert</p>
				<UserSearch
					label={'Search User'}
					type={UserType.Regular}
					{...registerList('talents')}
				/>
			</SectionUi>
			<Divider/>

			{isMusic ?
				<Music
					registerInput={registerInput}
					registerTextArea={registerTextArea}
					fileHandlers={fileHandlers}
				/>
				:
				<Event
					registerInput={registerInput}
					registerSelect={registerSelect}
					registerTextArea={registerTextArea}
				/>
			}
			<div className={'w-100 flex justify-between align-center'}>
				<div>
					<FormButton
						label={'Continue'}
						size={'small'}
						loading={submitLoading}
					/>
					{errors.size?<div className={'mt center'}>
						{errors.get('submitError') && <Message message={errors.get('submitError')} color={color.get('primary')}/>}
						{errors.get('talents')?.required && <Message message={'Add at least one talent'} color={color.get('primary')}/>}
						{errors.get('title')?.required && <Message message={`${isMusic? 'Song title' : 'Title'} is required`} color={color.get('primary')}/>}
						{errors.get('description')?.required && <Message message={'description is mandatory'} color={color.get('primary')}/>}
						{/*music*/}
						{/*event*/}
					</div>:null}
				</div>
				<Steps steps={3} currentStep={1}/>
			</div>
		</form>
	</>;
};