import React, {useState} from 'react';
import {UserCategories} from '../UserCategories';
import {Section, SectionTitle} from '../../styles/styledComponents/containers';
import {Item} from '../../interfaces';
import ScoreCircle from './ScoreCircle';
import {InterestsTensorMap, TensorCategory} from './TensorCategoriesMap';

interface InterestScoreProps {
	tags:Item[]
	userId:string
}

export default function UserInterestScoreRender({tags, userId}:InterestScoreProps):JSX.Element {

	const [selectedTag, setSelectedTag] = useState<string>(tags[0]?.label || '');

	const getInterest = ():string => {
		const c:TensorCategory = InterestsTensorMap.find((e:TensorCategory):any => e.tensor === selectedTag);
		return c.iab;
	};

	return (
		<div>
			<Section>
				<SectionTitle>Top scoring categories</SectionTitle>
				<UserCategories setSelectedTag={setSelectedTag} selected={selectedTag} rounded tags={tags}/>
			</Section>
			<Section className={'align-center'}>
				<ScoreCircle interest={getInterest()} userId={userId} />
			</Section>
		</div>
	);
}