import React, {useContext, useEffect, useState} from 'react';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import SocialMediaRender from './SocialMediaRender';
import {MutationTuple, useMutation} from '@apollo/client';
import {mutation} from 'actions/mutations';
import {FormField, FormHook} from 'hooks/useForm/interfaces';
import {useForm} from 'hooks/useForm/useForm';
import {UserContext, userContextInterface} from 'context/userContext';
import {OnboardingContext} from 'context/onboardingContext';
import {OnBoarding} from 'containers';

export const SocialMedia=():JSX.Element=>{
	const navigate:NavigateFunction=useNavigate();
	const [initTensor,setInitTensor] = useState(true);
	const {user}:userContextInterface = useContext(UserContext);
	const {setCurrentStep}:any =useContext(OnboardingContext);
	const [tensorinstagram] :MutationTuple<any, any> = useMutation(mutation('linkTensorSocial'));

	const formFields : FormField[] = [
		{ name:'instagram', required:false }
	];
	const onSubmit= (data:any):void=>{
		// tensorinstagram();
		console.log({data});
		data.instagram && tensorinstagram({
			variables:{
				username:data.instagram,
				firstName:user.firstName,
				email:user.email
			}
		});
		setInitTensor(true);
		navigate('/onboarding/brand/interests');
	};

	const {data,errors,loading, handleSubmitForm , ...rest} :FormHook= useForm(formFields,onSubmit);

	useEffect(():void => {
		setCurrentStep(2);
	}, []);

	return (
		<OnBoarding direction={'row-reverse'}>
			<SocialMediaRender
				initTensor={initTensor}
				errors={errors}
				submitLoading={loading}

				onSubmit={handleSubmitForm}

				{...rest}
			/>
			
		</OnBoarding>
	);
};
