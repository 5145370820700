import React, {useContext} from 'react';
import {ProfileFormRender} from './ProfileFormRender';
import {UserContext} from 'context/userContext';
import {FormField, FormHook} from 'hooks/useForm/interfaces';
import {Item, User, UpdateTalentData, CompanyProfile, UserType} from 'interfaces';
import {useForm} from 'hooks/useForm/useForm';
import {MutationFunction, MutationTuple, QueryResult, useMutation, useQuery} from '@apollo/client';
import {query} from 'actions/queries';
import {useOutletContext} from 'react-router-dom';
import {EditProfileContext} from '../../index';
import {mutation} from 'actions/mutations';
import {profilePicture, uploadCompanyLogo} from 'actions/restActions';

export const ProfileForm = ():JSX.Element=>{
	const {data:gendersData}:QueryResult=useQuery(query('gendersFindMany'));
	const {user}:any=useContext(UserContext);
	const {profileData,updateProfile}:EditProfileContext=useOutletContext();
	const [updateUser]:MutationTuple<MutationFunction, any> =useMutation(mutation('usersUpdateOne'));
	const handleChangeFile = async(picture:File):Promise<void> =>{
		let currentUserType:string= user.type._id;
		if( currentUserType === UserType.Regular){
			await profilePicture(picture,'sss');
		}else {
			await uploadCompanyLogo(picture);
		}

	};

	const  formFields:FormField[]=[
		{name:'firstName', required:false,default:user.firstName||''},
		{name:'lastName', required:false,default:user.lastName||''},
		{name:'description', required:false,default:profileData?.description||''},
		{name:'birthday', required:false, default:profileData?.birthday||''},
		{name:'gender', required:false,default:profileData?.gender||'8c27e9f8-3518-11ed-a261-0242ac120002'}
	];

	const {data:companyProfileData}:QueryResult = useQuery(query('usersCompanyProfileFindOne'),{
		variables:{user:user._id}
	});

	const handleSubmit=async(updateData:any):Promise<void> =>{
		const {description,birthday,gender}:UpdateTalentData=profileData||{};
		const {firstName,lastName}:User=user;
		if(data.firstName!==firstName|| data.lastName!==lastName){
			await updateUser({variables:{
				userRecord:{firstName:updateData.firstName, lastName:updateData.lastName}
			}});
		}

		if(data.description!==description|| data.birthday!==birthday|| data.gender!==gender){
			updateProfile({variables:{
				profileData:{
					description:updateData.description,
					birthday:updateData.birthday,
					gender:updateData.gender
				}
			}});
		}
	};

	let gendersArray:Item[]=gendersData?.gendersFindMany?.map((i:any):Item=>({...i,id:i._id}));
	const  {data,errors,loading,...rest}:FormHook=useForm(formFields,handleSubmit);
	const companyProfile:CompanyProfile=companyProfileData?.usersCompanyProfileFindOne;

	return <ProfileFormRender
		user={user}
		submitLoading={loading}
		errors={errors}
		genders={gendersArray||[]}
		onSubmit={rest.handleSubmitForm}
		formData={data}
		companyProfile={companyProfile}


		onChangeFile={handleChangeFile}
		registerInput={rest.registerInput}
		registerTextArea={rest.registerTextArea}
		registerSelect={rest.registerSelect}
	/>;
};