import React, { useContext, useEffect } from 'react';
import {RouterProvider} from 'react-router-dom';

import { UserContext } from './context/userContext';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from 'styled-components';
import { client } from 'actions/apolloClient';
import {router} from './routes';
import GeneralDataContextProvider from 'context/genalDataContext';
import {themeOpus} from './stylesOpus/theme';


function App(): JSX.Element {
	const { user, refresh }: any = useContext(UserContext);
	useEffect((): void => {
		if (!user) {
			refresh();
		}
	}, [user]);
	return (
		<ApolloProvider client={client()}>
			<GeneralDataContextProvider>
				<ThemeProvider theme={themeOpus}>
					<RouterProvider router={router}/>
				</ThemeProvider>
			</GeneralDataContextProvider>
		</ApolloProvider>
	);
}

export default App;