import styled from 'styled-components';
import {mediaQuery} from 'styles/mediaQueries';


export const TopStories:any=styled.div`
	width: 100%;
  	>div{
	  margin-top: 2rem;
	  display: grid;
	  grid-template-columns:repeat(2,1fr);
	  gap: 1rem;
	  ${mediaQuery('tablet')}{
        grid-template-columns:repeat(1,1fr);
	  }
	}
`;

export const TopVideos:any=styled.div`
	width: 100%;
  	>div{
	  margin-top: 2rem;
	  display: grid;
	  grid-template-columns:repeat(2,1fr);
	  gap: 1rem;
	  ${mediaQuery('tablet')}{
        grid-template-columns:repeat(1,1fr);
	  }
	}
`;
