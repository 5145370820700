import React from 'react';
import {IconProps} from '../interfaces';


export const ShareIcon=({color = 'white', size=16}:IconProps):JSX.Element=>
	<svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M2.75 2.25C2.61739 2.25 2.49021 2.30268 2.39645 2.39645C2.30268 2.49021 2.25 2.61739 2.25 2.75V13.25C2.25 13.3826 2.30268 13.5098 2.39645 13.6036C2.49022 13.6973 2.61739 13.75 2.75 13.75H5.75C6.30228 13.75 6.75 14.1977 6.75 14.75C6.75 15.3023 6.30228 15.75 5.75 15.75H2.75C2.08696 15.75 1.45107 15.4866 0.982233 15.0178C0.513392 14.5489 0.25 13.913 0.25 13.25V2.75C0.25 2.08696 0.513392 1.45107 0.982233 0.982233C1.45107 0.513392 2.08696 0.25 2.75 0.25H5.75C6.30228 0.25 6.75 0.697715 6.75 1.25C6.75 1.80228 6.30228 2.25 5.75 2.25H2.75Z" fill={color}/>
		<path fillRule="evenodd" clipRule="evenodd" d="M10.2929 3.54289C10.6834 3.15237 11.3166 3.15237 11.7071 3.54289L15.4571 7.29289C15.8476 7.68342 15.8476 8.31658 15.4571 8.70711L11.7071 12.4571C11.3166 12.8476 10.6834 12.8476 10.2929 12.4571C9.90237 12.0666 9.90237 11.4334 10.2929 11.0429L13.3358 8L10.2929 4.95711C9.90237 4.56658 9.90237 3.93342 10.2929 3.54289Z" fill={color}/>
		<path fillRule="evenodd" clipRule="evenodd" d="M4.75 8C4.75 7.44772 5.19772 7 5.75 7H14.75C15.3023 7 15.75 7.44772 15.75 8C15.75 8.55228 15.3023 9 14.75 9H5.75C5.19772 9 4.75 8.55228 4.75 8Z" fill={color}/>
	</svg>
;
