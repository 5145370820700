import React, {useEffect} from 'react';
import {FormButton,SocialButton} from 'components/ui';
import {FormProps} from 'interfaces';
import {Message} from 'components';
import {color} from 'styles/colors';

interface LinkSocialRender extends FormProps {
	user:string
	ayrShareLink:string
	message:string
	setConnectedAccounts:(connectedAccounts:string[])=>void
	setError:(errorMessage:string,socialNetwork:string)=>void
	userAccounts:UserAccountsProps[]
}

interface UserAccountsProps {
	identifier: string
	url: string
}

export default function LinkSocialRender({message,user,ayrShareLink,submitLoading,errors,onSubmit,setError,setConnectedAccounts, userAccounts}:LinkSocialRender):JSX.Element{

	useEffect(():any=>{
		console.log('User Accounts', userAccounts);
	},[userAccounts]);

	return (
		<>
			<h1 className="center mb-2 mt-2">
				Let’s Link Your
				Social Accounts
			</h1>
			<form onSubmit={onSubmit}>
				{/*<a href='https://test.opusintel.co/webapi/link-tiktok'>TikTok</a>*/}
				{/*<SocialButton connected={false} socialNetwork={'instagram'} userAccount={user} setError={setError}/>*/}
				{/*<WithAyrShareAccounts userId={user}>*/}
				{/*	{(connectedAccounts:string[]):ReactNode=> {*/}
				{/*		setConnectedAccounts(connectedAccounts);*/}
				{/*		return<>*/}
				{/*			<SocialButton*/}
				{/*				connected={connectedAccounts.includes('instagram')}*/}
				{/*				socialNetwork={'instagram-ayrShare'}*/}
				{/*				connectLink={ayrShareLink}*/}
				{/*				userAccount={user}*/}
				{/*				setError={setError}*/}
				{/*			/>*/}
				{/*			<SocialButton*/}
				{/*				connected={connectedAccounts.includes('tiktok')}*/}
				{/*				socialNetwork={'tiktok'}*/}
				{/*				connectLink={ayrShareLink}*/}
				{/*				userAccount={user}*/}
				{/*				setError={setError}*/}
				{/*			/>*/}
				{/*			<SocialButton*/}
				{/*				connected={connectedAccounts.includes('twitter')}*/}
				{/*				socialNetwork={'twitter'}*/}
				{/*				connectLink={ayrShareLink}*/}
				{/*				userAccount={user}*/}
				{/*				setError={setError}*/}
				{/*			/>*/}
				{/*			<SocialButton*/}
				{/*				connected={connectedAccounts.includes('youtube')}*/}
				{/*				socialNetwork={'youtube'}*/}
				{/*				connectLink={ayrShareLink}*/}
				{/*				userAccount={user}*/}
				{/*				setError={setError}*/}
				{/*			/>*/}
				{/*			<SocialButton*/}
				{/*				connected={connectedAccounts.includes('facebook')}*/}
				{/*				socialNetwork={'facebook'}*/}
				{/*				connectLink={ayrShareLink}*/}
				{/*				userAccount={user}*/}
				{/*				setError={setError}*/}
				{/*			/>*/}
				{/*		</>;*/}
				{/*	}}*/}
				{/*</WithAyrShareAccounts>*/}
				<SocialButton
					connected={userAccounts?.some((e:UserAccountsProps):boolean => e.identifier === 'instagram')}
					socialNetwork={'instagram'}
					userAccount={user}
					setError={setError}
				/>

				<FormButton
					variant={'white'}
					loading={submitLoading}
					label={'CONTINUE'}
				/>
				{message&& <div className='w-100 flex justify-center center'>
					<Message message={message} color={color.get('success-100')}/>
				</div>}
				{errors.size?<div className={'mt center'}>
					{errors.get('submit') &&<Message message={errors.get('submit')} color={color.get('primary')}/> }
					{errors.get('instagram') && <Message message={errors.get('instagram')} color={color.get('primary')}/>}
				</div>:null}
			</form>
		</>
	);
}
