import React, {useEffect, useState} from 'react';
import {SetterOrUpdater, useSetRecoilState} from 'recoil';
import {BreadCrumbAtom, TCrumb} from '../../context/recoil/store';
import {Params, useParams} from 'react-router-dom';
import {opusContent} from '../../actions/strapiActions';
import {TStrapiItem} from '../../interfaces/strapi';
import {AxiosResponse} from 'axios';
import {CategoryBoxProps} from '../../components/ui/CategoryBox/CategoryBox';
import {TArticle} from '../../components/ArticleCard/ArticleCard';
import {LatestDetailRender} from './LatestDetailRender';

export const LatestDetail = ():JSX.Element => {
	//Breadcrumb
	const {category}:Params = useParams();
	const setBreadCrumbs: SetterOrUpdater<TCrumb[]>=useSetRecoilState(BreadCrumbAtom);

	const [articles, setArticles] = useState<TArticle[]>(null);


	const getArticles= async(category:{name:string, id:string}):Promise<any> =>
		opusContent('hotlists', {
			'filters[tags]': category.id,
			'populate[0]':'tags',
			'populate[1]': 'cover'
		});

	const getData = async():Promise<void> => {
		try {

			const articlesResponse:AxiosResponse = await opusContent('hotlists', {
				'sort':'publishDate:desc',
				'pagination[pageSize]': '100',
				'populate[0]': 'cover'
			});
			const items :TStrapiItem[] = articlesResponse.data.data;
			setArticles(items.map((item:TStrapiItem):TArticle => ({
				title: item?.attributes?.title,
				subtitle: item?.attributes?.subtitle,
				date: item?.attributes?.publishDate,
				picture: `${item?.attributes?.cover?.data?.attributes?.url}`,
				link: `/hotlist/latest/${item?.id}/detail`
			})));
		}
		catch (error) {
			console.error(error);
		}
	};

	useEffect(():void => {
		setBreadCrumbs([
			{id: 'hotlist', label: 'hotlist', path:'/hotlist'},
			{id: 'latest', label: 'latest', path:`/hotlist/latest`}
		]);
		getData();
	}, []);

	return (
		<LatestDetailRender articles={articles}/>
	);
};
