import React from 'react';
import {FormProps, Item} from 'interfaces';
import {FormButton, Select} from 'components/ui';

export interface FamilyFormRenderProps extends FormProps {
	relationshipOptions:Item[]
}
export const FamilyFormRender = ({relationshipOptions,registerSelect,onSubmit}:FamilyFormRenderProps):JSX.Element=>{
	return(
		<div className="w-100 max-w-700px pb-4">
			<form onSubmit={onSubmit} >
				<Select
					label={'Relationship status'}
					options={relationshipOptions}
					{...registerSelect('relationshipStatus')}
				/>
				<div className="grid2 col-gap-2">
					<Select
						label={'Do you have kids'}
						placeholder={'Select a option'}
						options={[{label:'Yes',id:'yes'},{label:'No',id:'no'}]}
						{...registerSelect('kids')}
					/>
					<Select
						label={'Do you have Pets'}
						placeholder={'Select a option'}
						options={[{label:'Yes',id:'yes'},{label:'No',id:'no'}]}
						{...registerSelect('pets')}
					/>
				</div>

				<div className="center">
					<FormButton
						variant={'secondary'}
						label={'Save'}
					/>
				</div>


			</form>
		</div>
	);
};