import React from 'react';
import RangeRender from './RangeRender';

interface RangeProps{
	color:string;
	max:number;
	value:number;
	className?:string;

}

export const Range=({color,max,value,className}:RangeProps):JSX.Element=>{
	let percentage:number=(value/max)*100;
	return <RangeRender
		className={className}
		color={color}
		percentage={percentage}
	/>;
};
