import React from 'react';
import {Content, StyledLayout} from './styles';
import {Header, SideMenu} from 'components';

export interface LayoutRenderProps{
	children:React.ReactNode
	styleTheme?:'opus'|'opusV2'
	showMenu:boolean
	withColumn?:boolean

	hideMenu?:boolean
	hideHeader?:boolean
}

export default function LayoutRender({hideHeader,children,styleTheme='opus',showMenu}:LayoutRenderProps):JSX.Element{

	return <StyledLayout styleTheme={styleTheme} >
		{!hideHeader && <Header theme={styleTheme}/>}
		<SideMenu  styleTheme={styleTheme}   show={showMenu}/>
		
		<Content>
			{children}
		</Content>

	</StyledLayout>;
}