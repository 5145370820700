import React from 'react';
import HighlightsRender from './HighlightsRender';
import {ProfileContext} from '../../index';
import {useOutletContext} from 'react-router-dom';


export const Highlights=():JSX.Element=>{
	const {feed}:ProfileContext=useOutletContext();
	let videosFeed:any[]=feed?.inline_videos||[];
	console.log({videosFeed,feed});

	return <HighlightsRender
		topStories={feed?.top_stories||undefined}
		topVideos={feed?.inline_videos||undefined}
	/>;
};