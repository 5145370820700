import React, { ForwardedRef, forwardRef } from 'react';
import {Input,Label} from 'styles/ui';
import {Tags} from '../Tags';
import {AutoCompleteSearchProps, Item} from 'interfaces';
import {Spinner} from '../Spinner';
import {Wrapper, OptionsContainer, Option} from  'styles/ui';
import {Tag} from '../Tag';
import {WithGenericItem} from '../../../hocs/WithGenericItem';
import {SearchIcon} from '../../../icons';

type searchAutoCompleteRender = {
	findOneAction:string
} & AutoCompleteSearchProps<Item>;

export const SearchAutoCompleteRender:any= forwardRef((props:searchAutoCompleteRender,ref:ForwardedRef<any>):JSX.Element=>{
	const {selectedOptions,
		label,
		theme,
		helperText,
		options,
		findOneAction,
		required,
		onChangeQuery,
		onSelectOption,
		onUnselectOption,
		onClickArrow,
		...rest}:searchAutoCompleteRender=props;
	return <>
		<Wrapper
			localTheme={theme}
		>
			<div>
				<div style={{marginBottom:'4px'}}>
					<Label>{label}</Label>
				</div>
				<Input
					{...rest}
					required={required}
					onChange={onChangeQuery}
				/>
			</div>


			{options.show&& <OptionsContainer  ref={ref}>
				{ options.loading?
					<div className={'w-100 h-100 flex justify-center align-center'}>
						<Spinner color={'neutral-400'} />
					</div>
					:
					options.items.map((item:Item,index:number):JSX.Element=>
						<Option
							key={index+item.label}
							onClick={():void=>onSelectOption(item)}
						>{item.label}
						</Option>
					)
				}
			</OptionsContainer>}
			{theme==='opusV2' && <SearchIcon/>}
		</Wrapper>
		<div className={'mb mt-quarter'}>
			<div className={'flex wrap'}>
				{ selectedOptions.map((i:Item):JSX.Element =>
					<WithGenericItem key={i.id} id={i.id} findOneAction={findOneAction}>
						{ (item:Item):JSX.Element=>
							<Tag
								category={item}
								selected onClickTag={onUnselectOption}
								theme={'opusV2'}
							/>
						}
					</WithGenericItem>
				)}

			</div>
		</div>
	</>;
});

SearchAutoCompleteRender.displayName = 'SearchAutoCompleteRender';