import React, {FC} from 'react';
import {Item} from 'interfaces';
import styled from 'styled-components';
import {IconOption} from './IconOption';

interface IconOptionInterface extends Item{
	icon?:FC
}

interface SelectByIconRenderProps {
	options:IconOptionInterface[]
	selectedItems:Item[]
	label?:string
	helperText?:string
	handleClick?:(item:Item)=>void
}

const InputContainer:any=styled.div`
  h4 {
    font-size: 10px;
    color:#B0B0B2;
    text-transform: uppercase;
    font-weight: 800;
  }

  p {
    font-size: 14px;
    color:#7D7D7D;
    font-weight: 300;
  }
`;

const IconsContainer :any=styled.div`
  display:flex;
  gap: 1rem;
  flex-wrap: wrap;
	
`;

export const SelectByIconRender=({options,selectedItems,label,helperText,handleClick}:SelectByIconRenderProps):JSX.Element=>{

	return <InputContainer>
		<h4>{label}</h4>
		<p>{helperText}</p>
		<IconsContainer>
			{options.map((opt:IconOptionInterface):JSX.Element=>
				<IconOption
					key={opt.id}
					icon={opt.icon}
					label={opt.label}
					selected={selectedItems.map((i:Item):string=>i.id).includes(opt.id)}
					onClick={():void=>handleClick(opt)}
				/>)
			}

		</IconsContainer>
	</InputContainer>;
};