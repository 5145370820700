import styled from 'styled-components';
import {color} from 'styles/colors';

export const TotalReachWrapper:any=styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TextWrapper:any=styled.div`
  >p:first-child{
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
	margin-bottom: 0.2rem;
  }
  >P:nth-child(2){
    font-weight: 800;
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 0.2rem;
  }
  >p:last-child{
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: ${color.get('neutral-400')};
  }
`;