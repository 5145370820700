import {color} from 'styles/colors';
import {themeOpus} from 'stylesOpus/theme';

const variants:any={
	primary:`
		background-color: ${color.get('primary')};
		border: none;
	`,
	secondary:`
		background-color: rgba(0,0,0,0);
		border: 1px solid ${color.get('primary')};
	`,
	white:`
		background-color:#fff;
		color:#000000;
		border: none;
		border-radius: 8px;
	`,
	grey:`
		background-color ${ themeOpus.colors.light_grey_200 };
		color: ${themeOpus.colors.grey_1000};
		border: none;
		border-radius: 8px;
	`,
	'dark-grey':`
		background-color ${ themeOpus.colors.grey_900 };
	`,
	'secondary-white':`
		background-color ${ themeOpus.colors.grey_900 };
		border: 1px solid #fff !important;
	`,
	'blue':`
		background-color ${ themeOpus.colors.blue };
		color:#ffffff;
		border: none;
	`,
	teal:`
		background-color:#369B9D;
		color:#ffffff;
		border: none;
	`
};

const sizes:any={
	large:`
		height:50px;
		font-size: 0.875rem;
  		line-height: 1.313rem;
	`,
	small:`
		max-width:200px;
		height:30px;
		font-size: 0.75rem;
  		line-height: 1.125rem;
	`,
	largeFullWidth:`
		max-width:100%;
		height:50px;
		font-size: 0.75rem;
  		line-height: 1.125rem;
	`
};

export type ButtonVariant = keyof  typeof variants;
export const getVariant=(v?:ButtonVariant):string=> {
	return variants[v || 'primary'];
} ;
export const getSize=(s?:string):string=>sizes[s || 'large'];
