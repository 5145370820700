import React, {useEffect, useState} from 'react';
import InsightsRender from './InsightsRender';
import {QueryResult, useQuery} from '@apollo/client';
import {query} from '../../../actions/queries';
import {ChartItem} from '../../../interfaces';

interface InsightsProps {
	id: string
}

interface AudienceItem {
	name: string
	weight: number
}

export default function Insights({id}:InsightsProps):JSX.Element {

	const [countries, setCountries] = useState<ChartItem[]>([]);
	const {data, loading, error}:QueryResult=useQuery(query('instagramAudienceFindOne'),{
		variables:{
			filter:{user: id}
		}
	});

	useEffect(():void => {
		console.log('Instagram Audience', data);
		if(!loading && data) {
			const c:ChartItem[] = data.instagramAudienceFindOne.audienceCountry.map(({name, weight}:AudienceItem, index:number):ChartItem => {
				return {label: name, value: weight*100};
			});
			setCountries(c);
		}
	}, [data]);

	if (loading){
		return <p>Loading...</p>;
	}
	else{
		return <InsightsRender
			countries={countries}
			brands={data?.instagramAudienceFindOne?.audienceBrand}
			interests={data?.instagramAudienceFindOne?.audienceInterests}
			genders={data?.instagramAudienceFindOne?.audienceGenderAge}
			followersCount={data?.instagramAudienceFindOne?.followersCount}
		/>;
	}
}