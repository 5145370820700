import {Item} from 'interfaces';

export const fakeItems:Item[]=[
	{label:'Gaming',id:'gfgdfgrSEQWR,'},
	{label:'Sports',id:'jhjhsfkjfhlsktrgdffhiusdhjdsh'},
	{label:'Beauty',id:'jhjhsfkjfhlskfhiusdrtreterdhjdsh'},
	{label:'Soccer',id:'jhjhsfkjfhrtreterdhjdsh'},
	{label:'Paris',id:'jhjhsfkjfh'},
	{label:'Food',id:'gfgfdgdf'},
	{label:'Cars',id:'gfgfdgdw23f'}
];

export const fakeLanguages:Item[]=[
	{label:'Spanish',id:'gfgdfgrSEQWR,'},
	{label:'English',id:'jhjhsfkjfhlsktrgdffhiusdhjdsh'},
	{label:'French',id:'jhjhsfkjfhlskfhiusdrtreterdhjdsh'},
	{label:'Japanese',id:'jhjhsfkjfhrtreterdhjdsh'},
	{label:'Korean',id:'jhjhsfkjfh'}
];

export const fakeShoesSizes:Item[]=[
	{label:'6',id:'gfgdfgrSEQWR,'},
	{label:'6.5',id:'jhjhsfkjfhlsktrgdffhiusdhjdsh'},
	{label:'7',id:'jhjhsfkjfhlskfhiusdrtreterdhjdsh'},
	{label:'7.5',id:'jhjhsfkjfhrtreterdhjdsh'},
	{label:'8',id:'jhjhsfkjfh'},
	{label:'8.5',id:'jhjhsfdewkjfh'},
	{label:'9',id:'jhjhsfkjfh'},
	{label:'9.5',id:'jhjh656sfkjfh'},
	{label:'10',id:'jhjhsf565kjfh'},
	{label:'10.5',id:'jh354jhsfkjfh'},
	{label:'11',id:'jhjhsf5654kjfh'},
	{label:'11.5',id:'jhjh645645sfkjfh'},
	{label:'12',id:'jhjhsfk645654jfh'},
	{label:'12.5',id:'jhjhs12312fkjfh'},
	{label:'13',id:'jhjhsf78876kjfh'},
	{label:'13.5',id:'jhjhsf546546kjfh'}
];

export const fakeApparelSize:Item[]=[
	{label:'XXS',id:'gfgdfgrSEQWR,'},
	{label:'XS',id:'jhjhsfkjfhlsktrgdffhiusdhjdsh'},
	{label:'S',id:'jhjhsfkjfhlskfhiusdrtreterdhjdsh'},
	{label:'M',id:'jhjhsfkjfhrtreterdhjdsh'},
	{label:'L',id:'jhjhsfkj76575fh'},
	{label:'XL',id:'jhjhsfloowkjfh'}
];

export const fakeBrands:Item[]=[
	{label:'Adidas',id:'gfgdfgrSEQWR,'},
	{label:'Nike',id:'jhjhsfkjfhlsktrgdffhiusdhjdsh'},
	{label:'UnderArmor',id:'jhjhsfkjfhlskfhiusdrtreterdhjdsh'}
];

export const fakeInterests:Item[] = [
	{
		id: '1',
		url: 'https://www.kreedon.com/wp-content/uploads/2022/03/sunsetyoga-2_647_062115121022.jpg',
		label: 'Yoga'
	},
	{
		id: '2',
		url: 'https://www2.claro.com.co/portal/co/noticias/imagenes/1636732108798-1-2-bm-Streaming-Gaming.jpg',
		label: 'Gaming'
	},
	{
		id: '3',
		url: 'https://www2.claro.com.co/portal/co/noticias/imagenes/1636732108798-1-2-bm-Streaming-Gaming.jpg',
		label: 'Gaming'
	},
	{
		id: '4',
		url: 'https://www.kreedon.com/wp-content/uploads/2022/03/sunsetyoga-2_647_062115121022.jpg',
		label: 'Yoga'
	},
	{
		id: '5',
		url: 'https://www2.claro.com.co/portal/co/noticias/imagenes/1636732108798-1-2-bm-Streaming-Gaming.jpg',
		label: 'Gaming'
	},
	{
		id: '6',
		url: 'https://www2.claro.com.co/portal/co/noticias/imagenes/1636732108798-1-2-bm-Streaming-Gaming.jpg',
		label: 'Gaming'
	},
	{
		id: '7',
		url: 'https://www2.claro.com.co/portal/co/noticias/imagenes/1636732108798-1-2-bm-Streaming-Gaming.jpg',
		label: 'Gaming'
	},
	{
		id: '8',
		url: 'https://www2.claro.com.co/portal/co/noticias/imagenes/1636732108798-1-2-bm-Streaming-Gaming.jpg',
		label: 'Gaming'
	},
	{
		id: '9',
		url: 'https://www2.claro.com.co/portal/co/noticias/imagenes/1636732108798-1-2-bm-Streaming-Gaming.jpg',
		label: 'Gaming'
	}
];