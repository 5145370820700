import styled, { css } from 'styled-components';
import { mediaQuery } from 'styles/mediaQueries';
import { Header, SideMenu } from 'components';
import {themeOpus} from '../../stylesOpus/theme';

const mobileLayout: string = css`
  grid-template-rows: 70px auto;
  position: relative;
  display: flex;
  flex-direction: column;
  >header{
    position: fixed;
  }
`;

export const StyledLayout: any = styled.div<{styleTheme:'opus'|'opusV2'}>`
  flex-grow: 1;
  position: relative;
  width: 100%;

  display: grid;
  grid-template-areas:
    "sideMenu header"
    "sideMenu main"
    "sideMenu main"
  ;
  ${(props:any):string=> props.styleTheme === 'opusV2' && `background-color: ${themeOpus.colors.grey_1000}`};
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;

  row-gap: .5rem;

  margin: 0 auto;
  ${mediaQuery('mobile')}{
    grid-template-areas:
      "sideMenu main"
  ;
    ${mobileLayout}
  }
`;

export const Content: any = styled.main`
  box-sizing: border-box;
  flex-grow: 1;
  grid-area: main;
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem 1.25rem 0 ;
  max-width: 1400px;
  width: 100%;

  justify-self: stretch;

  ${mediaQuery('mobile')}{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

`;
