/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {OpusLogo} from '../../icons';

export const Privacy = ():JSX.Element =>{
	return(
		<div className={'w-100 flex phor-2 direction-column align-center justify-center'}>
			<div className="max-w-940px p-3">
				<div className="w-100 flex justify-center pver-4">
					<a href="/">
						<OpusLogo size={120}/>
					</a>
				</div>
				<h1>Privacy Policy</h1>
				<p>Last Modified: May 22, 2023</p>
				<p>
					This Privacy Policy (“<strong>Privacy Policy</strong>”) describes the types
					of information Opus Intelligence Labs, Inc. and any of its subsidiaries ors
					affiliates (collectively, “<strong>Company</strong>”, “<strong>we</strong>”,
					“<strong>us</strong>”, or “<strong>our</strong>”) collect, use, and share
					about you when you visit or use our website at <a>www.opusintel.co</a>, and
					all related services, features, and content offered by Company or when you
					otherwise contact or interact with us (collectively, “
					<strong>Services</strong>”). The terms “<strong>you</strong>” and “
					<strong>your</strong>” refers to you, the user. If you are using the
					Services on behalf of a business, association, or other entity, “you” or
					“your” will also refer to such business, association, or other entity,
					unless the context clearly dictates otherwise. You agree that you are
					authorized to consent to these terms on behalf of such business,
					association, or other entity, and we can rely on this. This Privacy Policy
					also explains how Company may use and share your Personal Information (as
					defined in Section 1), as well as the choices available to you.&nbsp;
				</p>
				<p>
					By using/continuing to use the Services, you acknowledge you have read and
					understand and agree to the collection, storage, use, and disclosure of your
					Personal Information as described in this Privacy Policy, and you agree to
					the Terms of Use which is incorporated by reference. If you do not agree,
					please do not access or use the Services.&nbsp;
				</p>
				<h2>Eligibility to Use the Services</h2>
				<p>
					To use the Services you must be, and represent and warrant that you are, at
					least the age of majority in your state, province or jurisdiction of
					residence and competent to agree to these terms; or if you are under the age
					of majority in your state, province or jurisdiction of residence, you
					represent and warrant that your parent or legal guardian has reviewed this
					Privacy Policy with you and accepts them on your behalf; parents or legal
					guardians are responsible for the activities of their minor dependents while
					using the Services.
				</p>
				<h2>Terms of Use</h2>
				<p>
					If you choose to access or use the Services, your access and use, and any
					dispute over privacy is subject to this Privacy Policy and our Terms of Use,
					including, but not limited to, limitations on damages and resolution of
					disputes.&nbsp;
				</p>
				<ol>
					<li>
						<p>Personal Information We Collect</p>
					</li>
				</ol>
				<p>
					Generally, we collect four (4) types of information about you: (A)
					information and content you give us directly; (B) information we obtain
					automatically when you use our Services; (C) demographic information; and
					(D) information we get about you from other sources. When we talk about
					“Personal Information” in this Privacy Policy, we are talking about any
					information collected in accordance with this section. Please see below for
					more information on each category.
				</p>
				<ol style={{ listStyleType: 'upper-alpha' }}>
					<li style={{ fontWeight: 'bold' }}>
						<p>
							<strong>Information and Content You Give Us Directly</strong>
						</p>
						<ol style={{ listStyleType: 'lower-roman', fontWeight: 'initial' }}>
							<li>
								<p>
									<u>Personal Information.&nbsp;</u>Personal information, such as your
									name, address, e-mail address, username, password, and any other
									information you directly provide us on or through the
									Services.&nbsp;
								</p>
							</li>
							<li>
								<p>
									<u>Email Correspondences.</u> Records and copies of your email
									messages together with your email address and our responses, if you
									choose to correspond with us through email.&nbsp;
								</p>
							</li>
							<li>
								<p>
									<u>Transaction Information.</u> We or service providers working on
									our behalf may collect information and details about any purchase or
									transactions made on the Services. This includes payment
									information, such as your credit or debit card number and other card
									information; other account and authentication information; and
									billing, shipping, and contact details. We do not collect or store
									payment card information ourselves; rather we rely on third party
									payment processors (e.g., Stripe) to store and process this
									information as part of the Services.&nbsp;
								</p>
							</li>
						</ol>
					</li>
					<li style={{ fontWeight: 'bold' }}>
						<p>
							<strong>
								Information We Obtain Automatically When You Use Our Services
							</strong>
						</p>
						<ol style={{ fontWeight: 'initial', listStyleType: 'lower-roman' }}>
							<li>
								<p>
									<u>Activity Information.</u> Details of your visits to our Services,
									including the types of content you view or engage with; the features
									you use; the actions you take; the people or accounts you interact
									with; the time, frequency, and duration of your activities; and
									other information about your use of and actions on the Services.
								</p>
							</li>
							<li>
								<p>
									<u>Equipment Information.</u> Information about your computer and
									internet connection, including your device or computer operating
									system, IP address, browser type, and browser language.
								</p>
							</li>
							<li>
								<p>
									<u>Location Information.&nbsp;</u>Information about the location of
									your device, including&nbsp;GPS location, for purposes of enhancing
									or facilitating the Services. For example, we may use information
									about the location of the device you are using to help us understand
									how the Services and functionality are being used and to deliver
									more relevant advertising. If you do not want to share your
									location, you can disable location sharing in the settings on your
									device.
								</p>
							</li>
							<li>
								<p>
									<u>Cookies, Pixel Tags/Web Beacons, and Other Technologies.</u>{' '}
									Cookies, pixel tags, web beacons, clear GIFs, javascript, entity
									tags, HTML5 local storage, resettable device identifiers, or other
									similar technologies (collectively, the “Technologies”) may be used
									by us, as well as third parties that provide the content,
									advertising, or other functionality on the Services to automatically
									collect information through your use of the Services. Please see
									Section 9 for more information on the technologies we may use for
									this automatic data collection.
								</p>
							</li>
						</ol>
					</li>
					<li>
						<p>
							<strong>Demographic Information</strong>
							<br />
							<br />
							We may collect demographic, statistical, or other aggregate information
							that is about you, but individually does not identify you. Some of this
							information may be derived from Personal Information, but it is not
							Personal Information and cannot be tied back to you. Examples of such
							aggregate information include gender, age, and race. &nbsp;
						</p>
					</li>
					<li>
						<strong>Information We Get About You from Other Sources</strong>
						<br />
						<br />
						We may receive information about you from other sources and add it to our
						information, including from third-party services and organizations who
						have the right to provide us with such information. We protect this
						information according to the practices described in this Privacy Policy,
						plus any additional restrictions imposed by the source of the data. These
						sources may include: online and offline data providers, from which we
						obtain demographic, interest-based, and online advertising related data;
						publicly-available sources such as open government databases or social
						networks; and service providers who provide us with information, and
						updates to that information, based on their relationship with you. By
						gathering additional information about you, we can correct inaccurate
						information, enhance the security of your transactions, and give you
						product or service recommendations and special offers that are more likely
						to interest you.
					</li>
				</ol>
				<h2>2. How We Use Your Information</h2>
				<p>
					We may use the information we collect about you in&nbsp;a variety of ways,
					to provide our Services, for administrative purposes, and to market and
					advertise our Services and products.
				</p>
				<ol>
					<li>
						<p>
							<strong>
								We Use Your Personal Information to Provide Our Services
							</strong>
							<br />
							<br />
							<br />
							We may use your Personal Information to:
						</p>
						<ol style={{ listStyleType: 'lower-roman' }}>
							<li>
								<p>provide the Services and its content to you.</p>
							</li>
							<li>
								<p>respond to comments, questions, and provide customer service.</p>
							</li>
							<li>
								<p>
									fulfill any other purpose for which you provide Personal
									Information.
								</p>
								<p>communicate with you about an account.</p>
							</li>
							<li>
								<p>
									inform you about important changes to, or other news about, the
									Services or any of its features or content.
									<br />
									<br />
								</p>
							</li>
						</ol>
					</li>
					<li>
						<strong>We Use Your Information for Administrative Purposes</strong>
						<br />
						<br />
						We may use your Personal Information to:
						<ol style={{ listStyleType: 'lower-roman' }}>
							<li>
								<p>
									operate, maintain, improve, personalize, and analyze the Services.
								</p>
							</li>
							<li>
								<p>
									monitor and analyze trends, usage, and activities for marketing or
									advertising purposes.
								</p>
							</li>
							<li>
								<p>
									detect, prevent, or investigate security breaches, fraud, and other
									unauthorized or illegal activity.
								</p>
							</li>
							<li>
								<p>
									carry out our obligations and enforce our rights arising from
									any&nbsp;contracts entered into between you and us, including for
									billing and collection.
								</p>
							</li>
							<li>
								<p>
									maintain appropriate records for internal administrative purposes.
								</p>
							</li>
							<li>
								<p>
									allow you to participate in interactive features on the Services.
								</p>
							</li>
							<li>
								<p>
									develop, improve, and analyze our predictive models, both
									experimental and underlying the Services.
								</p>
							</li>
						</ol>
					</li>
					<li>
						We Use Your Information to Market and Advertise Our Services and Products
						<br />
						<br />
						We may use your Personal Information to:&nbsp;
						<ol style={{ listStyleType: 'lower-roman' }}>
							<li>
								<p>
									send promotional communications, such as information about features,
									newsletters, offers, promotions, contests, and events.
								</p>
							</li>
							<li>
								<p>
									share information across Services and devices to provide a more
									tailored and consistent experience on the Services.
								</p>
							</li>
							<li>
								<p>
									develop, test, and improve new products or services, including by
									conducting surveys and research and testing and troubleshooting new
									products and features.
								</p>
							</li>
						</ol>
					</li>
				</ol>
				<h2>3. How We May Share or Disclose Your Information</h2>
				<h2>
					<br />
				</h2>
				<p>
					We may share or disclose aggregated or anonymized information about our
					users to third parties for a variety of business purposes without any
					restrictions, including to provide our Services and/or to protect us or
					others. We may also share or disclose non-anonymized information and data
					about our users to third parties to provide our Services (e.g., displaying
					certain user information to other members as part of the Services
					functionality). We may share or disclose information in the event of a major
					business transaction such as a merger, sale, or asset transfer. The
					following circumstances describe in additional detail the ways we may share
					or disclose your Personal Information that we collect or that you provide
					under this Privacy Policy:
				</p>
				<ol style={{ listStyleType: 'upper-alpha' }}>
					<li style={{ fontWeight: 'bold' }}>
						<p>
							<strong>We Disclose Your Information to Provide Our Services</strong>
						</p>
						<ol style={{ listStyleType: 'lower-roman', fontWeight: 'initial' }}>
							<li>
								<p>
									Subsidiaries and Affiliates. We may share your Personal Information
									with our parent companies, subsidiaries, joint ventures, and
									affiliated companies for purposes of management and analysis,
									decision-making, and other business purposes, consistent with this
									Privacy Policy.&nbsp;
								</p>
							</li>
							<li>
								<p>
									Service Providers. We may share your Personal Information with our
									third-party service providers, contractors, and any other similar
									third parties that help us provide our Services. This may include
									service providers that help us with analytics services or support
									services, website hosting, email and postal delivery, location
									mapping, product and service delivery. Some of our aspects of our
									Services utilize framing techniques to serve content to you from our
									third-party providers, while preserving the look and feel of the
									Services. In such cases, please note that the information you
									provide is being provided to the third party. Service providers are
									bound by contractual obligations to keep Personal Information
									confidential and use it only for the purposes for which we disclose
									it to them.&nbsp;
								</p>
							</li>
							<li>
								<p>
									Advertising/Marketing Service Providers. We may share your Personal
									Information with marketing service providers to assess, develop, and
									provide you with promotions and special offers that may interest
									you, administer contests, sweepstakes, events, or for other
									promotional purposes.
								</p>
							</li>
							<li>
								<p>
									Consent or to Fulfill the Purpose that Information was Provided. We
									may share your Personal Information to fulfill the purpose for which
									you provide that information, with your consent, or for any other
									purpose disclosed by us when you provide the information.
								</p>
							</li>
							<li>
								<p>
									Improvement of Analytics and Research Studies. We may share your
									Personal Information with research associates and other third-party
									research organizations to assess, develop, improve, and exchange
									predictive modeling and data analytics for purposes of improving
									outcomes. For example, we may use your Personal Information as a
									part of a data set that will be used to improve the accuracy of our
									product outcomes predictive modeling algorithms or in connection
									with various Company product studies.&nbsp;
								</p>
							</li>
						</ol>
					</li>
					<li>
						<p>
							<strong>
								We May Disclose Your Information in the Event of a Merger, Sale or
								Other Asset Transfers
							</strong>
							<br />
							<br />
							If we become involved in a merger, acquisition, financing due diligence,
							divestiture, restructuring, reorganization, bankruptcy, dissolution,
							sale, or transfer of some or all of our assets (whether as a going
							concern or as part of bankruptcy, liquidation, or similar proceeding),
							or transition of Services to another provider, your Personal Information
							may be sold or transferred to business entities or people involved in
							such process.
							<br />
							<br />
						</p>
					</li>
					<li style={{ fontWeight: 'bold' }}>
						<p>
							<strong>We Disclose Your Information to Protect Us or Others</strong>
						</p>
						<ol style={{ listStyleType: 'lower-roman', fontWeight: 'initial' }}>
							<li>
								<p>
									When Required by Law. We may share your Personal Information to
									comply with any court order, law, or legal process, including to
									respond to any government or regulatory request.
								</p>
							</li>
							<li>
								<p>
									To Enforce Our Rights. We may share your Personal Information to
									enforce or apply this Privacy Policy, our Terms of Use, and other
									agreements, including for billing and collection purposes.
								</p>
							</li>
							<li>
								<p>
									To Protect Lawful Interests. We may share your personal information
									if we believe disclosure will help us protect the rights, property,
									or safety of Company, our users, partners, agents, and others. This
									may include exchanging information with other companies and
									organizations for fraud protection, and spam and malware prevention.
									<br />
									<br />
									Personal Information that you post on or through the public areas of
									the Services (e.g., chat rooms, bulletin boards, and discussion
									groups) are generally accessible to, and may be collected and used
									by, others which may result in unsolicited messages or other contact
									from others. Users of the Services are encouraged to exercise
									caution when providing personal information about themselves in
									public or interactive areas.
								</p>
							</li>
						</ol>
					</li>
				</ol>
				<h2>4. Your Privacy Choices and Rights</h2>
				<h2>
					<br />
				</h2>
				<p>
					You have certain choices and rights with respect to your privacy. For
					example, you may be able to opt out of receiving marketing messages from us,
					make choices regarding cookies, and exercise other privacy rights under
					applicable law.
				</p>
				<ol style={{ listStyleType: 'upper-alpha' }}>
					<li style={{ fontWeight: 'bold' }}>
						<p>
							<strong>Mechanisms to Control Your Information</strong>
						</p>
						<ol style={{ listStyleType: 'lower-roman', fontWeight: 'initial' }}>
							<li>
								<p>
									Cookies and Other Tracking Technologies. You may be able to set your
									browser to reject cookies and certain other technologies by
									adjusting the appropriate settings in your browser. Each browser is
									different, but many common browsers have preferences that may be
									adjusted to allow you to either accept or reject cookies and certain
									other technologies before they are set or installed, or allow you to
									remove or reject the use or installation of certain technologies
									altogether. We recommend that you refer to the “Help” menu in your
									browser to learn how to modify your browser settings. Please note
									that you cannot remove Flash cookies simply by changing your browser
									settings. To learn how you can manage your Flash cookie settings,
									visit the Flash player settings page on Adobe's website. If you
									disable or refuse cookies, please note that some parts of the
									Services may become inaccessible or may not function properly.
								</p>
							</li>
							<li>
								<p>
									Communications from Company. If you do not wish to have your contact
									information used by Company to promote our own or third-party
									products or services, you can opt-out by: (1) informing us of your
									preference at the time you sign up for your Services account (if
									applicable), or complete any other form on or through the Services
									which we collect your data; (2) modifying your user preferences in
									your account profile by checking or unchecking the relevant boxes;
									(3) following the opt-out instructions at the bottom of the
									promotional emails we send you; or (4) sending us an email stating
									your request. Please note that we may also send you non-promotional
									communications, however you will not be able to opt-out of these
									communications (e.g., transactional communications, including emails
									about your account; communications regarding our Services; and
									communications about updates to this Privacy Policy and the Terms of
									Use).
								</p>
							</li>
							<li>
								<p>
									“Do Not Track”. "Do Not Track" (“DNT”) is a privacy preference you
									can set in certain web browsers. When you turn on this preference,
									it sends a signal or message to the websites you visit indicating
									that you do not wish to be tracked. Please note that we currently do
									not respond to or honor DNT signals or similar mechanisms
									transmitted by web browsers.
								</p>
							</li>
						</ol>
					</li>
				</ol>
				<p>
					The online advertising industry also provides websites from which you may
					opt out of receiving targeted ads from data partners and other advertising
					partners that participate in self-regulatory programs. You can access these
					and learn more about targeted advertising and consumer choice and privacy by
					visiting the <a>Network Advertising Initiative</a>,{' '}
					<a>the Digital Advertising Alliance</a>,{' '}
					<a>the European Digital Advertising Alliance</a>, and{' '}
					<a>the Digital Advertising Alliance of Canada</a>.
				</p>
				<p>
					<strong>B. Accessing and Correcting Your Information</strong>
				</p>
				<p>In accordance with applicable law, you may have the right to:&nbsp;</p>
				<ol>
					<ol>
						<li>
							<p>
								Access Personal Information. You may access Personal Information about
								you, including: (1) confirming whether we are processing your Personal
								Information; (2) obtaining access to or a copy of your Personal
								Information; and (3) receiving an electronic copy of personal
								information that you have provided to us, or asking us to send that
								information to another company (the "right of data
								portability").&nbsp;
							</p>
						</li>
						<li>
							<p>
								Request Correction of Personal Information. You may request correction
								of your Personal Information where it is inaccurate, incomplete, or
								improperly possessed.
							</p>
						</li>
						<li>
							<p>
								Request Deletion of Personal Information. You may request deletion of
								your Personal Information held by us about you. Please note: we cannot
								delete your Personal Information except by also deleting your account.
							</p>
						</li>
						<li>
							<p>
								Opt-out. You may request to opt-out of the processing of your Personal
								Information for the purpose(s) of: (1) targeted advertising; (2) sale
								or sharing of personal information; or (3) profiling to make decisions
								that have legal or other significant effects on you.
							</p>
						</li>
						<li>
							<p>
								Withdraw Consent. You may have the right to withdraw consent where
								such consent is required to share or use Personal Information.
							</p>
						</li>
						<li>
							<p>
								If you would like to exercise any of these rights, you may send us an
								email to request access to, correction of or removal of any Personal
								Information that you have provided to us. We will process such
								requests in accordance with applicable law.&nbsp;
							</p>
							<p>The following are additional Consumer Privacy Rights:</p>
							<ol style={{ listStyleType: 'lower-roman' }}>
								<li>
									<p>
										Non-Discrimination. &nbsp;Residents have the right not to receive
										discriminatory treatment by covered businesses for the exercise of
										their rights conferred by the applicable privacy law.
									</p>
								</li>
								<li>
									<p>
										Authorized Agent. &nbsp;Only you, or someone legally authorized to
										act on your behalf, may make a verifiable consumer request related
										to your Personal Information. You may also make a verifiable
										consumer request on behalf of your minor child. To designate an
										authorized agent, please contact us as set forth in “Contact
										Information” below and provide written authorization signed by you
										and your designated agent.
									</p>
								</li>
								<li>
									<p>
										Verification. &nbsp;To protect your privacy, we will take the
										following steps to verify your identity before fulfilling your
										request. When you make a request, we will ask you to provide
										sufficient information that allows us to reasonably verify you are
										the person about whom we collected Personal Information or an
										authorized representative, which may include asking you to answer
										questions regarding your account and use of our Services.
									</p>
								</li>
							</ol>
						</li>
					</ol>
				</ol>
				<p>
					<strong>C. Your Right to Appeal</strong>
				</p>
				<p>
					If you are dissatisfied with the refusal of Company to take action in
					accordance with the exercise of your rights in the “Accessing and Correcting
					Your Information” section above, you may request reconsideration by Company,
					by sending a written request for reconsideration to the mailing address
					found in the “Contact Information” section below. Within sixty (60) days of
					Company’s receipt of such written request for reconsideration, Company shall
					inform you in writing (at the address indicated in your initial written
					request) of any action taken or not taken in response to your request for
					reconsideration, including a written explanation of the reasons for the
					decision. In addition, if your request for reconsideration is denied, you
					have the right to appeal to the Attorney General in your state of residence.
				</p>
				<p>
					<strong>D. Complaints to Data Protection Authority</strong>
				</p>
				<p>
					You have the right to complain to a Data Protection Authority about our
					collection and use of your Personal Information. For more information, if
					you are in the European Economic Area (EEA), please contact your local data
					protection authority in the EEA.
				</p>
				<h2>5. How We Protect Your Information</h2>
				<p>
					We have implemented safeguards reasonably designed to secure your Personal
					Information. Such safeguards include the implementation of various
					technical, physical, administrative and organizational security measures
					intended to reduce the risk of loss, misuse, unauthorized access,
					disclosure, or modification of your information. All information you provide
					to us is stored on our secure servers behind firewalls.
				</p>
				<p>
					The safety and security of your information is also dependent&nbsp;on you.
					If we have given you (or where you have chosen) a password for access to
					certain parts of the Services, you are responsible for keeping this password
					confidential. We ask you not to share your password with anyone.
				</p>
				<p>
					While we have employed security technologies and procedures to assist
					safeguarding your Personal Information, no system or network can be
					guaranteed to be 100% secure, and we cannot ensure or warrant the security
					of any information you provide to us. Unauthorized entry or use, hardware or
					software failure, and other factors may compromise the security of user
					information at any time. Any transmission of Personal Information is at your
					own risk. We are not responsible for circumvention of any privacy settings
					or security measures contained on the Services.
				</p>
				<h2>6. International Transfer of Personal Information</h2>
				<p>
					If you provide Personal Information through the Services, you acknowledge
					and agree that such Personal Information may be transferred from your
					current location to the offices and servers of Company and the other third
					parties referenced in this Privacy Policy located in the United States or
					other countries, which may have data protection laws that are different from
					the laws where you live. We endeavor to safeguard your information
					consistent with the requirements of such laws, where applicable.
				</p>
				<h2>7. Personal Information Retention Period</h2>
				<p>
					We keep your information for the length of time needed to carry out the
					purpose outlined in this Privacy Policy and to adhere to our policies on
					keeping records (unless a longer period is needed by law). Our records
					policies reflect applicable laws. We will retain and use your information to
					the extent necessary to manage your relationship with us, personalize and
					improve your overall customer experience, and to comply with our legal
					obligations. Where we retain data, we do so in accordance with our record
					retention policies and any limitation periods and records retention
					obligations that are imposed by applicable law.
				</p>
				<h2>8. State Privacy Rights</h2>
				<ol style={{ listStyleType: 'upper-alpha' }}>
					<li>
						<p>
							California Privacy Rights. The California Consumer Privacy Act of 2018
							and California Privacy Rights Act of 2020 (CPRA) (together, "CCPA")
							requires covered businesses to provide California residents with some
							additional information regarding how they collect, use, and share your
							"personal information" (as defined in the CCPA). While Company is not
							currently a covered business under the CCPA, we value privacy and strive
							to be transparent with our customers. As such, we have provided
							additional details below about the information we collect, how we
							disclose it, and how you can exercise your privacy rights under the
							CCPA, in the event it applies to our activities in the future.
						</p>
						<ol style={{ listStyleType: 'lower-roman' }}>
							<li>
								<p>
									Categories of Personal Information that is Collected, Disclosed and
									Shared. The CCPA provides California residents with the right to
									know what categories of personal information covered businesses have
									collected about them and whether such businesses have disclosed that
									personal information for a business purpose (e.g., to a service
									provider) in the preceding 12 months. California residents can find
									this information below:
								</p>
							</li>
						</ol>
					</li>
				</ol>
				<p>
					<br />
				</p>
				<div>
					<table>
						<tbody>
							<tr>
								<td>
									<p>
										<strong>Category of Personal Information Collected</strong>
									</p>
								</td>
								<td>
									<p>
										<strong>
										Category of Third Parties Personal Information is Disclosed to
										for a Business Purpose
										</strong>
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>
										<strong>Identifiers</strong>.
									</p>
									<p>
									A real name, postal address, Internet Protocol address, email
									address, or other similar identifiers.
									</p>
								</td>
								<td>
									<br />
									<ul>
										<li>
											<p>Advertising partners</p>
										</li>
										<li>
											<p>Service providers</p>
										</li>
										<li>
											<p>Business partners</p>
										</li>
										<li>
											<p>Members</p>
										</li>
									</ul>
									<br />
								</td>
							</tr>
							<tr>
								<td>
									<p>
										<strong>
										Personal information categories listed in the California
										Customer Records statute (Cal. Civ. Code § 1798.80(e))
										</strong>
									</p>
									<p>
									A name, address, telephone number, employment, employment history,
									bank account number, credit card number, debit card number, or any
									other financial information.
									</p>
									<br />
								</td>
								<td>
									<ul>
										<li>
											<p>Service providers</p>
										</li>
									</ul>
									<br />
								</td>
							</tr>
							<tr>
								<td>
									<p>
										<strong>Commercial information</strong>
									</p>
									<p>
									Records of products or services purchased, obtained, or
									considered, or other purchasing or consuming histories or
									tendencies.
									</p>
									<br />
								</td>
								<td>
									<ul>
										<li>
											<p>Advertising partners</p>
										</li>
										<li>
											<p>Service providers</p>
										</li>
									</ul>
									<br />
								</td>
							</tr>
							<tr>
								<td>
									<p>
										<strong>Internet or other electronic network activity</strong>
									</p>
									<p>
									Browsing history, search history, information on a consumer's
									interaction with an internet website, application, or
									advertisement.
									</p>
									<br />
								</td>
								<td>
									<ul>
										<li>
											<p>Advertising partners</p>
										</li>
										<li>
											<p>Service providers</p>
										</li>
										<li>
											<p>Business partners</p>
										</li>
									</ul>
									<br />
								</td>
							</tr>
							<tr>
								<td>
									<p>
										<strong>
										Inferences drawn from other personal information to create a
										profile about a consumer
										</strong>
									</p>
									<p>
									Profile reflecting a consumer's preferences, characteristics,
									psychological trends, predispositions, behavior, attitudes,
									intelligence, abilities, and aptitudes.
									</p>
									<br />
								</td>
								<td>
									<ul>
										<li>
											<p>Advertising partners</p>
										</li>
										<li>
											<p>Service providers</p>
										</li>
										<li>
											<p>Business partners</p>
										</li>
									</ul>
									<br />
								</td>
							</tr>
							<tr>
								<td>
									<p>
										<strong>Professional or employment-related information</strong>
									</p>
									<p>
									Collect the employment information of Customers, to provide the
									appropriate level of service
									</p>
								</td>
								<td>
									<ul>
										<li>
											<p>Advertising partners</p>
										</li>
										<li>
											<p>Service providers</p>
										</li>
										<li>
											<p>Business partners</p>
										</li>
										<li>
											<p>Members</p>
										</li>
									</ul>
									<br />
								</td>
							</tr>
							<tr>
								<td>
									<p>
										<strong>Geolocation data</strong>
									</p>
									<p>
									As described above, we may collect your IP address automatically
									when you use our Services. We may be able to determine your
									general location based on your device’s IP address.
									</p>
								</td>
								<td>
									<ul>
										<li>
											<p>Advertising partners</p>
										</li>
										<li>
											<p>Service providers</p>
										</li>
									</ul>
									<br />
								</td>
							</tr>
							<tr>
								<td>
									<p>
										<strong>
										Category of Sensitive Personal Information Collected
										</strong>
									</p>
								</td>
								<td>
									<p>
										<strong>
										Category of Third Parties Sensitive Personal Information is
										Disclosed to for a Business Purpose
										</strong>
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>
										<strong>Racial or Ethnic Origin</strong>
									</p>
									<p>
									Personal information that consists of your racial or ethnic
									origin.&nbsp;
									</p>
								</td>
								<td>
									<ul>
										<li>
											<p>Service providers</p>
										</li>
										<li>
											<p>Business partners</p>
										</li>
										<li>
											<p>Members</p>
										</li>
									</ul>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<p>
					<br />
				</p>
				<p>
					We may use any of the categories of information listed above for other
					business or operational purposes compatible with the context in which the
					personal information was collected. The categories of sources from which we
					collect personal information and our business and commercial purposes for
					using personal information are set forth in&nbsp;"Personal Information We
					Collect"&nbsp;and&nbsp;"How We Use Your Information"&nbsp;above,
					respectively.
				</p>
				<p>
					We may share any of the information listed above with service providers,
					which are companies that we engage for business purposes to conduct
					activities on our behalf. Service providers are restricted from using
					personal information for any purpose that is not related to our
					engagement.&nbsp;
				</p>
				<ol style={{ listStyleType: 'upper-alpha' }}>
					<li>
						<p>
							"Sales or Sharing" of Personal Information under the CCPA. California
							residents have the right to opt out of the "sale" of their personal
							information. Under the CCPA, "sale" is defined broadly and includes the
							transfer of personal information by a business to a third party for
							valuable consideration (even if there is no exchange of money).
							<br />
							Company may "sell or share" personal information. The categories of
							personal information we have "sold" and the categories of third parties
							we have "sold or shared" personal information to in the preceding twelve
							months are listed below:
						</p>
						<table>
							<tbody>
								<tr>
									<td>
										<p>
											<strong>
											Category of Personal Information Sold or Shared by Company
											</strong>
										</p>
									</td>
									<td>
										<p>
											<strong>
											Category of Third Parties Personal Information is Sold or
											Shared to
											</strong>
										</p>
									</td>
								</tr>
								<tr>
									<td>
										<p>
											<strong>Identifiers.</strong>
										</p>
										<p>
										A real name, postal address, telephone number, unique personal
										identifier, online identifier, email address, or other similar
										identifiers.
										</p>
									</td>
									<td>
										<ul>
											<li>
												<p>Business partners</p>
											</li>
										</ul>
									</td>
								</tr>
								<tr>
									<td>
										<p>
											<strong>
											Personal information categories listed in the California
											Customer Records statute (Cal. Civ. Code § 1798.80(e))
											</strong>
										</p>
										<p>A name, address, telephone number.</p>
										<br />
									</td>
									<td>
										<ul>
											<li>
												<p>Business partners</p>
											</li>
										</ul>
									</td>
								</tr>
								<tr>
									<td>
										<p>
											<strong>
											Category of Sensitive Personal Information Sold or Shared by
											Company
											</strong>
										</p>
									</td>
									<td>
										<p>
											<strong>
											Category of Third Parties Sensitive Personal Information is
											Sold or Shared to
											</strong>
										</p>
									</td>
								</tr>
								<tr>
									<td>
										<p>
											<strong>Racial or Ethnic Origin</strong>
										</p>
										<p>
										Personal information that consists of your racial or ethnic
										origin.
										</p>
									</td>
									<td>
										<ul>
											<li>
												<p>Business partners</p>
											</li>
										</ul>
									</td>
								</tr>
							</tbody>
						</table>
						<br />
						<br />
						Company’s business and commercial purposes for "selling" personal
						information can be found in&nbsp;"How We Use Your Information"&nbsp;above.
						Company does not have actual knowledge of any "sale" of personal
						information of minors under 18 years of age.
						<p>
							<br />
						</p>
						<ol style={{ listStyleType: 'lower-roman' }}>
							<li>
								<p>Additional Privacy Rights for California Residents.</p>
								<ol>
									<li>
										<p>
											&nbsp;Opt-out of "Sales or Sharing" &nbsp;California residents
											may opt-out of the "sale or sharing" of their personal
											information by contacting us as set forth in "Contact
											Information" below. California residents (or their authorized
											agent) may also exercise your right to limit the disclosure of
											your sensitive personal information, by clicking on this "Limit
											the Use of My Sensitive Personal Information" link.&nbsp;
										</p>
									</li>
									<li>
										<p>
											&nbsp;California Shine the Light. &nbsp;The California "Shine
											the Light" law permits users who are California residents to
											request and obtain from us once a year, free of charge, a list
											of the third parties to whom we have disclosed their personal
											information (if any) for their direct marketing purposes in the
											prior calendar year, as well as the type of personal information
											disclosed to those parties. If you are a California resident and
											would like to exercise any of your rights under the law, please
											contact us as set forth in "Contact Information" below. We will
											process such requests in accordance with applicable laws.
										</p>
									</li>
								</ol>
							</li>
						</ol>
					</li>
					<li>
						<p>
							<strong>Nevada Privacy Rights.</strong> If you are a resident of Nevada,
							you have the right to opt-out of the sale of certain Personal
							Information to third parties who intend to sell or license that Personal
							Information, even if your Personal Information is not currently being
							sold. If you would like to exercise this right, please contact us via
							the information found in the “Contact Information” section below.
						</p>
					</li>
					<li>
						<p>
							<strong>
								Virginia, Colorado, Connecticut and Utah Privacy Rights.
							</strong>{' '}
							Residents of Virginia, Colorado, Connecticut and Utah may have
							additional rights under relevant privacy laws, including under the
							Virginia Consumer Data Protection Act (“VCDPA”), Colorado Privacy Act
							(“CPA”), Connecticut Data Privacy Act (“CTDPA”) (effective July 1, 2023)
							and Utah Consumer Privacy Act (“UCPA”) (effective Jan. 1, 2024), as
							applicable. The following additional information is required to be
							provided by covered businesses under applicable state laws.
						</p>
					</li>
					<li>
						<p>
							<strong>
								Sharing of Personal Data under VCDPA, CPA, CTPDA and UCPA.
							</strong>{' '}
							The VCDPA, CPA, CTPDA and UCPA require covered businesses to provide
							residents of their respective states with the right to know the
							categories of “personal data” (as defined under applicable law) covered
							businesses shared with third parties and the categories of third parties
							with whom such personal data has been shared. Residents of Virginia,
							Colorado, Connecticut and Utah can find this information below:&nbsp;
						</p>
					</li>
				</ol>
				<p>
					<br />
				</p>
				<ol style={{ listStyleType: 'upper-alpha' }}>
					<li>
						<div>
							<table style={{ marginLeft: 'calc(0%)', width: '100%' }}>
								<thead>
									<tr>
										<th>
											<ol>
												<li>Category of Personal Data Shared</li>
											</ol>
										</th>
										<th>
											<p>Category of Third Parties Personal Data is Shared With</p>
										</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<p>
												<strong>Identifiers</strong>.
											</p>
											<p>
											A real name, Internet Protocol address, email address, or
											other similar identifiers.
											</p>
										</td>
										<td>
											<ul>
												<li>
													<p>Advertising partners</p>
												</li>
												<li>
													<p>Service providers</p>
												</li>
												<li>
													<p>Business partners</p>
												</li>
												<li>
													<p>Members</p>
												</li>
											</ul>
											<br />
										</td>
									</tr>
									<tr>
										<td>
											<p>
												<strong>Commercial information</strong>
											</p>
											<p>
											Records of products or services purchased, obtained, or
											considered, or other purchasing or consuming histories or
											tendencies.
											</p>
										</td>
										<td>
											<ul>
												<li>
													<p>Advertising partners</p>
												</li>
												<li>
													<p>Service providers</p>
												</li>
											</ul>
										</td>
									</tr>
									<tr>
										<td>
											<p>
												<strong>Internet or other electronic network activity</strong>
											</p>
											<p>
											Browsing history, search history, information on a consumer's
											interaction with an internet website, application, or
											advertisement.
											</p>
										</td>
										<td>
											<ul>
												<li>
													<p>Advertising partners</p>
												</li>
												<li>
													<p>Service providers</p>
												</li>
												<li>
													<p>Business partners</p>
												</li>
											</ul>
										</td>
									</tr>
									<tr>
										<td>
											<p>
												<strong>
												Inferences drawn from other personal information to create a
												profile about a consumer
												</strong>
											</p>
											<p>
											Profile reflecting a consumer's preferences, characteristics,
											psychological trends, predispositions, behavior, attitudes,
											intelligence, abilities, and aptitudes.
											</p>
										</td>
										<td>
											<ul>
												<li>
													<p>Advertising partners</p>
												</li>
												<li>
													<p>Service providers</p>
												</li>
												<li>
													<p>Business partners</p>
												</li>
											</ul>
										</td>
									</tr>
									<tr>
										<td>
											<p>
												<strong>
												Professional or employment-related information
												</strong>
											</p>
											<p>
											Collect the employment information of Customers, to provide
											the appropriate level of service
											</p>
										</td>
										<td>
											<ul>
												<li>
													<p>Advertising partners</p>
												</li>
												<li>
													<p>Service providers</p>
												</li>
												<li>
													<p>Business partners</p>
												</li>
												<li>
													<p>Members</p>
												</li>
											</ul>
										</td>
									</tr>
									<tr>
										<td>
											<p>
												<strong>Geolocation data</strong>
											</p>
											<p>
											As described above, we may collect your IP address
											automatically when you use our Services. We may be able to
											determine your general location based on your device’s IP
											address.
											</p>
										</td>
										<td>
											<ul>
												<li>
													<p>Advertising partners</p>
												</li>
												<li>
													<p>Service providers</p>
												</li>
											</ul>
										</td>
									</tr>
									<tr>
										<td>
											<p>
												<strong>Racial or Ethnic Origin</strong>
											</p>
											<p>
											Personal information that consists of your racial or ethnic
											origin.&nbsp;
											</p>
										</td>
										<td>
											<ul>
												<li>
													<p>Service Providers</p>
												</li>
												<li>
													<p>Business Partners</p>
												</li>
												<li>
													<p>Members</p>
												</li>
											</ul>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<p>
							<br />
						</p>
						<ol style={{ listStyleType: 'lower-roman' }}>
							<li>
								<p>
									“Sales” or Sharing for Targeted Advertising under VCDPA, CPA, CTPDA
									and UCPA. Residents of Virginia, Colorado, Connecticut and Utah have
									the right to opt-out of the “sale” of their personal data to third
									parties or the processing of their personal data for targeted
									advertising (see Section 4(B)(iv) above). For purposes of this
									paragraph the definition of “sale”, “sell” or “sold” has the meaning
									set forth in applicable privacy law. If a consumer wishes to
									exercise their right to opt-out of the sale of personal data or
									processing of personal data for targeted advertising, they may do so
									by following this link. The categories of personal data “sold” or
									processed for targeted advertising can be found below:&nbsp;
								</p>
							</li>
						</ol>
					</li>
				</ol>
				<p>
					<br />
				</p>
				<div>
					<table>
						<thead>
							<tr>
								<th>
									<p>
									Category of Personal Data Sold to Third Parties or Processed for
									Targeted Advertising
									</p>
								</th>
								<th>
									<p>
									Category of Third Parties Personal Data is Sold to or Processed by
									for Targeted Advertising
									</p>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<p>
										<strong>Identifiers.</strong>
									</p>
									<p>
									A real name, postal address, telephone number, unique personal
									identifier, online identifier, email address, or other similar
									identifiers.
									</p>
								</td>
								<td>
									<ul>
										<li>
											<p>Business partners</p>
										</li>
									</ul>
								</td>
							</tr>
							<tr>
								<td>
									<p>
										<strong>Racial or Ethnic Origin</strong>
									</p>
									<p>
									Personal information that consists of your racial or ethnic
									origin.&nbsp;
									</p>
								</td>
								<td>
									<ul>
										<li>
											<p>Business partners</p>
										</li>
									</ul>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<p>
					<br />
				</p>
				<h2>9. Cookies and Other Tracking Technologies</h2>
				<ol style={{ listStyleType: 'upper-alpha' }}>
					<li>
						<p>
							Description of the Technologies. We as well as third parties that
							provide the content, advertising, or other functionality on the Services
							may use Technologies to automatically collect information through your
							use of the Services. The following describes some of these Technologies
							we may use for this automatic data collection:
						</p>
						<ol style={{ listStyleType: 'lower-roman' }}>
							<li>
								<p>
									Cookies. A cookie is a small data file stored on the hard drive of
									your computer either for (a) the duration of your visit on a website
									("session cookies") or (b) for a fixed period ("persistent
									cookies"). Cookies contain information that can later be read by a
									web server. We may use cookies to provide you with a more personal
									and interactive experience on the Services.
								</p>
							</li>
							<li>
								<p>
									Web Beacons. Web beacons are small files that are embedded in
									webpages, applications, and emails (also known as "clear gifs",
									"pixel tags", "web bugs", and "single-pixel gifs") that collect
									information about engagement on our Services. For example, web
									beacons allow us to track who has visited those webpages or opened
									an email, to test the effectiveness of our marketing, and for other
									related website statistics.
								</p>
							</li>
							<li>
								<p>
									JavaScripts. JavaScripts are code snippets embedded in various parts
									of websites and applications that facilitate a variety of operations
									including accelerating the refresh speed of certain functionality or
									monitoring usage of various online components.
								</p>
							</li>
							<li>
								<p>
									Entity Tags. Entity Tags are HTTP code mechanisms that allow
									portions of websites to be stored or "cached" within your browser
									and validates these caches when the website is opened, accelerating
									website performance since the web server does not need to send a
									full response if the content has not changed.
								</p>
							</li>
							<li>
								<p>
									HTML5 Local Storage. HTML5 local storage allows data from websites
									to be stored or "cached" within your browser to store and retrieve
									data in HTML5 pages when the website is revisited.
								</p>
							</li>
							<li>
								<p>
									Resettable Device Identifiers. Resettable device identifiers (also
									known as "advertising identifiers") are similar to cookies and are
									found on many mobile devices and tablets (for example, the
									"Identifier for Advertisers" or "IDFA" on Apple iOS devices and the
									"Google Advertising ID" on Android devices), and certain streaming
									media devices. Like cookies, resettable device identifiers are used
									to make online advertising more relevant.
								</p>
							</li>
						</ol>
					</li>
					<li>
						<p>
							<strong>Our Uses of the Technologies.</strong> We may also use these
							technologies for security purposes, to facilitate navigation, to display
							information more effectively, and to better serve you with more tailored
							information, as well as for website administration purposes, e.g., to
							gather statistical information about the usage of our websites in order
							to continually improve the design and functionality, to understand how
							users use our websites, and to assist us with resolving questions
							regarding use of the websites.
						</p>
					</li>
					<li>
						<p>
							<strong>
								Mechanisms to Control Cookies and Other Technologies.&nbsp;
							</strong>
							You may be able to set your browser to reject cookies and certain other
							technologies by adjusting the appropriate settings in your browser. Each
							browser is different, but many common browsers have preferences that may
							be adjusted to allow you to either accept or reject cookies and certain
							other technologies before they are set or installed, or allow you to
							remove or reject the use or installation of certain technologies
							altogether. We recommend that you refer to the “Help” menu in your
							browser to learn how to modify your browser settings. Please note that
							you cannot remove Flash cookies simply by changing your browser
							settings. To learn how you can manage your Flash cookie settings, visit
							the Flash player settings page on Adobe's website. If you disable or
							refuse cookies, please note that some parts of the Services may become
							inaccessible or may not function properly.
						</p>
					</li>
					<li>
						<p>
							<strong>Third Party Technologies.</strong> This Privacy Policy covers
							the use of cookies by Company and does not cover the use of tracking
							technologies by any third parties. The Services may contain links,
							content, advertising, or references to other websites or applications
							run by third parties, including advertisers, ad networks and servers,
							content providers, and application providers. These third parties may
							use cookies or other tracking technologies to collect information about
							you when you interact with their content on the Services, such as member
							recruitment vendors to using Web beacons and cookies on our registration
							pages for payment verification. The information they collect may be
							associated with your Personal Information or they may collect
							information about your online activities over time and across different
							websites. Please be aware that we do not control these third parties'
							tracking technologies or when and how they may be used. Therefore,
							Company does not claim nor accept responsibility for any privacy
							policies, practices, or procedures of any such third party. We encourage
							you to read the privacy statements and terms and conditions of linked or
							referenced websites you enter. We do not endorse, screen, or approve,
							and are not responsible for the practices of such third parties or the
							content of their application or website. Providing Personal Information
							to third-party websites or applications is at your own risk. If you have
							any questions about an ad or other targeted content, you should contact
							the responsible provider directly.
						</p>
					</li>
					<li>
						<p>
							<strong>Google Analytics.</strong> The Services may use Google
							Analytics, which uses cookies and similar technologies to collect and
							analyze information about use of the Services and report on activities
							and trends. This service may also collect information regarding the use
							of other websites, apps and online resources. You can learn about
							Google’s practices by going to{' '}
							<a>www.google.com/policies/privacy/partners/</a>, and opt-out of them by
							downloading the Google Analytics opt-out browser add-on, available at{' '}
							<a>https://tools.google.com/dlpage/gaoptout</a>.&nbsp;
						</p>
					</li>
				</ol>
				<h2>10. Children Using or Accessing The Services</h2>
				<p>
					We are especially committed to protecting the privacy of children. Company’s
					Services are directed at a general audience over the age of eighteen (18)
					and are not targeted to children. If we learn that we have inadvertently
					collected or received Personal Information from an individual under the age
					of eighteen (18), we will use reasonable efforts to immediately remove such
					information, unless we have a legal obligation to keep it. If you are a
					parent or legal guardian and think your child under the age of eighteen (18)
					has given us information without your consent, please contact us via the
					information found in the “Contact Information” section below.
				</p>
				<h2>11. Changes to Our Privacy Policy</h2>
				<p>
					We reserve the right to update this Privacy Policy from time to time in
					order to reflect, changes to our practices or for other operational, legal,
					or regulatory reasons. When we do update this Privacy Policy, we will post
					the updates and changes on our website. We may elect to notify you of
					material changes by mail, email, posting of modified Privacy Policy, or some
					other similar manner. However, it is your responsibility to check our
					website regularly for changes to this Privacy Policy. Your continued use of
					or access to the Services following the posting of any changes to this
					Privacy Policy constitutes acceptance of those changes.
				</p>
				<h2>12. Third-Party Websites and Applications; Social Networking Services</h2>
				<p>
					Our Services may offer links to websites or applications that are not run by
					us but by third parties. These third-party services, websites or
					applications are not controlled by us, and may have privacy policies that
					differ from our own. We encourage our users to read the privacy policies and
					terms and conditions of each website and application with which they
					interact. We do not endorse, screen or approve, and are not responsible for
					the practices of such third parties or the content of their application or
					website. Providing Personal Information to third-party websites or
					applications is at your own risk.
				</p>
				<h2>13. Contact Information&nbsp;</h2>
				<p>
					For more information about our privacy practices, if you have questions, or
					if you would like to make a complaint, please contact us by e-mail at{' '}
					<a>legalnotices@opusintel.co</a>.
				</p>
			</div>
		</div>
	);
};
