import React from 'react';

export const EditProductIcon = ():JSX.Element => <svg
	width={24}
	height={24}
	viewBox="0 0 24 24"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<circle cx={12} cy={12} r={12} fill="#FF5656" />
	<path
		d="M8.5752 16.1709L16.2588 8.4873L14.9463 7.16797L7.25586 14.8516L6.58594 16.458C6.54948 16.5583 6.56999 16.6494 6.64746 16.7314C6.72949 16.8135 6.82064 16.8363 6.9209 16.7998L8.5752 16.1709ZM16.915 7.84473L17.6533 7.11328C17.8356 6.92643 17.9313 6.7373 17.9404 6.5459C17.9495 6.35449 17.8721 6.1722 17.708 5.99902L17.4414 5.73242C17.2728 5.5638 17.0905 5.48633 16.8945 5.5C16.6986 5.51367 16.5094 5.60938 16.3271 5.78711L15.5889 6.51855L16.915 7.84473Z"
		fill="white"
	/>
</svg>;