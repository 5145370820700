import React, {useEffect, useState} from 'react';
import {CategoryBox} from 'components/ui';
import {ArticleCard} from 'components';
import {Section} from 'styles/styledComponents/containers';
import {BoxTitle} from 'stylesOpus/typography';
import {TArticle} from 'components/ArticleCard/ArticleCard';
import {CategoryBoxProps} from 'components/ui/CategoryBox/CategoryBox';
import {FeaturedTagI} from '../../interfaces/strapi';


type CategoryDetailProps = {
	articles:TArticle[]
	featured?:FeaturedTagI
}
export const FeaturedDetailRender = ({articles, featured}:CategoryDetailProps):JSX.Element => {

	const [loadedCover, setLoadedCover] = useState<boolean>(false);

	return (
		<>
			<Section className={'pt-2 mt-half'}>
				<div className="mb-2">
					<BoxTitle>Latest in {featured?.title}</BoxTitle>
					<p className="f-12px f-urbanist f-400 t-neutral-200 mt-half mb">
						Lorem ipsum dolor sit amet consectetur. Tristique eget vestibulum volutpat diam ac sagittis integer nibh lacus.
					</p>
				</div>
				<div className={'w-100 grid4 col-gap mt row-gap-2'}>
					{articles?.length > 0 && articles.map((article:TArticle, index:number):JSX.Element =>
						<ArticleCard
							key={`${index}-${article.title}`}
							article={article}
							isNew={false}
						/>
					)}
				</div>
			</Section>
		</>
	);
};
