import styled from 'styled-components';

interface VerificationcodeStyles{
	codeSize:number;
}

export const Code:any=styled.div<VerificationcodeStyles>`
  
  display:grid;
  grid-template-columns:${({codeSize}:VerificationcodeStyles):string=>`repeat(${codeSize}, minmax(0, 1fr))`};
  column-gap: 3rem;
`;
