import styled from 'styled-components';

export const Item:any=styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PercentageLAbel:any=styled.p`
  font-weight: 700;
  margin-top: 0.625em;
`;


