import React from 'react';
import { PasswordRender } from './PasswordRender';
import {FormField, FormHook} from 'hooks/useForm/interfaces';
import {useForm} from 'hooks/useForm/useForm';
import {MutationTuple, useMutation} from '@apollo/client';
import {mutation} from 'actions/mutations';

export const Password = (): JSX.Element => {

	const [changePassword]:MutationTuple<any, any> =useMutation(mutation('changePassword'));
	const formFields:FormField[]=[
		{name:'currentPassword',required:true},
		{name:'newPassword',required:true},
		{name:'RepeatNewPassword',required:true}
	];
	const handleChangePassword=async(data:any):Promise<void> =>{
		const {currentPassword,newPassword,RepeatNewPassword}:any = data;
		if(newPassword !==RepeatNewPassword)
			throw new Error('Please chek the new password');
		await changePassword({
			variables:{newPassword,password:currentPassword}
		});
	};
	const {loading,errors,registerInput,handleSubmitForm}:FormHook =useForm(formFields,handleChangePassword);

	return <PasswordRender
		submitLoading={loading}
		errors={errors}
		registerInput={registerInput}

		onSubmit={handleSubmitForm}
	/>;
};