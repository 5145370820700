import React, {FC} from 'react';
import {SelectByIconRender} from './SelectByIconRender';
import {Item, ListInput} from 'interfaces';
import {listHandler, useListHandler} from 'hooks/useListHandler';

interface iconOption extends Item{
	icon?:FC
}

interface SelectByIconProps extends  Omit<ListInput,'options'>{
	options:iconOption[]
}

export const SelectByIcon =({options,label,helperText,name,value=[],onChange}:SelectByIconProps):JSX.Element=>{

	const opts:Item[]=options.map((i:iconOption):Item=>i);
	const {handleUnselected,handleSelected,getSelectedItems}:listHandler=useListHandler({name,value,options:opts,onChange});

	const handleClick=(item:Item):void=>{
		if (value.includes(item.id)){
			return handleUnselected(item);
		}
		handleSelected(item);
	};

	return <SelectByIconRender
		selectedItems={getSelectedItems()}
		options={options}
		label={label}
		helperText={helperText}
		handleClick={handleClick}
	/>;
};