import React from 'react';
import styled from 'styled-components';
import {Button} from '../Button';
import {ButtonProps} from '../../../interfaces';
import {LinkButton} from '../LinkButton';

const StyledCircleButton:any =styled(Button)`
  padding: 0!important;
  aspect-ratio:  1;
  border-radius: 50%;
  >div{
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const StyledCircleLinkButton:any =styled(LinkButton)`
  padding: 0;
  width: 2.2rem;
	height: 2.2rem;
  border-radius: 100%;
  >div{
    display: flex;
    align-items: center;
    justify-content: center;
	  width: 100%;
	  height: 100%;
  }
`;

type CircleButtonProps = ButtonProps & {
    to?:string | number
};

export const CircleButton = ({to,children,...props}:CircleButtonProps):JSX.Element => {

	return (!to ?
		<StyledCircleButton
			{...props}
		>
			<div>
				{children}
			</div>
		</StyledCircleButton>
		:
		<StyledCircleLinkButton
			to={to}
			{...props}
		>
			<div>
				{children}
			</div>
		</StyledCircleLinkButton>
	);
};