import styled from 'styled-components';
import {InputWrapper} from './FormInputs';

type theme = 'primary' | 'secondary';

type StyleProps = {
    theme?: theme;
}


export const Wrapper:any=styled(InputWrapper)<StyleProps>`
  position: relative;
  margin-bottom: 0;
`;

export const OptionsContainer:any=styled.div<StyleProps>`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  padding-top: 1rem;
  max-height: 40vh;
  min-height: 140px;
  min-width: 100%;
  border-radius: 4px;
  background-color: #eee;
  overflow-y:scroll;
  z-index: 20;
  >p{
	font-weight: bold !important;
  }
`;

export const Option:any=styled.p<StyleProps>`
  
  color:black;
  font-weight: 600;
  font-size: 14px;
  width:100%;
  padding: 0.2rem 1rem;
  cursor: pointer;
  :hover{
	background-color: #444;
	color: white;
  }
`;