import React from 'react';
import { ArrowRightIcon } from 'icons/ArrowRightIcon';
import { Container } from '../Notifications/styles';
import {BoxTitle} from '../../../../stylesOpus/typography';


export const HelpRender = (options: any):JSX.Element => {
	return (
		<>
			
			<BoxTitle>Help</BoxTitle>
			<Container>
				{options.options.map((option: any):any => (
					<div className='flex justify-between mb-2' key={option.id}>
						{option.title}
						<ArrowRightIcon />
					</div>
				))}
			</Container>
		
		</>
	);
};
