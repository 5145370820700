import React from 'react';
import {QueryResult, useQuery} from '@apollo/client';
import {query} from 'actions/queries';
import {Item, ItemApi} from '../interfaces';

interface UserHocProps {
    id: string,
    findOneAction: string
    children: (item: Item) => React.ReactNode;
}

export const WithGenericItem =({id,findOneAction,children}:UserHocProps):any=>{
	const {data,loading}:QueryResult=useQuery(query(findOneAction),{
		variables:{
			_id:id
		}
	});
	if (loading) {return;}
	const itemData: ItemApi = data[findOneAction];

	let item:Item= itemData ? {...itemData, id: itemData._id} : {id:'', label :'No Data'};
	return children(item);
};
