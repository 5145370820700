import React from 'react';
import styled from 'styled-components';
import {color} from 'styles/colors';
import {Match} from 'interfaces';

interface MatchesGroupProps{
	matches:Match[]
}

const Container:any = styled.div`
  display: grid;
  aspect-ratio: 1;
  justify-items: stretch;
  align-items: stretch;
  grid-template-rows: repeat(2,1fr);
  grid-template-columns: repeat(2,1fr);
  grid-gap: 5px;
  border-radius: 10px;
  overflow: hidden;
`;

const StyledMatch:any = styled.div`
  overflow: hidden;
  background-image: url('${({image}:any):string=>image}');
  background-color: ${color.get('neutral-600')};
  background-size: cover;
  background-position: center;
`;

export const MatchesGroup = ({matches}:MatchesGroupProps):JSX.Element => {
	console.log({matches});
	return <Container>
		<StyledMatch image={matches[0]?.picture||''}/>
		<StyledMatch image={matches[1]?.picture||''}/>
		<StyledMatch image={matches[2]?.picture||''}/>
		<StyledMatch image={matches[3]?.picture||''}/>
	</Container>;
};