import {color} from 'styles/colors';
import styled from 'styled-components';

export const CardMainWrapper: any = styled.div`
  display: flex;
  width: 100%;
  
  justify-content: center;
  margin: 1rem 0;
  
`;

export const Border: any = styled.div`
  width:20px ;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: ${color.get('primary')};

  > p {
    writing-mode:vertical-rl;
	transform: rotate(-180deg);
  }
`;

export const Content: any = styled.div`
	
`;