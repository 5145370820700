import React, {useState} from 'react';
import CampaignTalentRender from './CamapignTalentRender';
import {Params, useOutletContext, useParams} from 'react-router-dom';
import {MutationFunction, MutationTuple, useMutation} from '@apollo/client';
import {mutation} from 'actions/mutations';
import {FormField,FormHook} from 'hooks/useForm/interfaces';
import {useForm} from 'hooks/useForm/useForm';
import {OpportunityFormContext} from '../../OpportunityCreate';

export function CampaignTalent():JSX.Element {
	let { id }: Params<string> = useParams();

	const {active,opportunity:opportunityData, audience:audienceData, refetch}:OpportunityFormContext = useOutletContext();

	console.log({opportunityData,audienceData});

	const [updateOpportunity]:MutationTuple<MutationFunction, any> =useMutation(mutation('opportunityUpdateOne'));
	const [ searchState, setSearchState ] = useState(null);

	const update = async(data:any):Promise<any>=>{
		const oppId:string=id;
		const updateData:any= {};

		data.compensation && (updateData.budget= {
			minNumber:0,
			maxNumber:parseInt(data.compensation)
		});

		data.totalReach && (updateData.notes= data.totalReach);
		console.log({updateData,data});

		await updateOpportunity({variables:{
			_id:oppId,
			record:{
				active,
				...updateData
			}}});
		refetch();
		setSearchState({
			opportunity: {...opportunityData},
			audience: {...audienceData}
		});
	};

	const  formFields:FormField[]=[
		{name:'compensation', default: opportunityData?.budget?.maxNumber|| null},
		{name:'social',default:['instagram']},
		{name:'totalReach', default:opportunityData?.notes || ''}

	];
	const  {data:FormData,errors,loading,...rest}:FormHook=useForm(formFields,update);

	return (
		<CampaignTalentRender
			formData={FormData}
			submitLoading={loading}
			errors={errors}
			modal={{
				searchState,
				show:!!searchState
			}}
			registerInput={rest.registerInput}
			registerList={rest.registerList}
			onSubmit={rest.handleSubmitForm}
			{...rest}
		/>
	);
}