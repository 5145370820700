import { Avatar } from 'components/ui/Avatar';
import { User } from 'interfaces';
import React from 'react';
import styled from 'styled-components';
import {color} from 'styles/colors';

interface SelectedUsrProps{
    user:User
    action : (user)=>void
}

const Username:any=styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 136%;
`;

const UserAlias:any=styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 136%;
  color: ${color.get('primary')};
  :before{
	content: '@';
  }
`;

export const SelectedUser = ({user, action}:SelectedUsrProps):JSX.Element => {
	return (
		<div
			className={'flex align-center'}
			onClick={():void=>action(user)}
		>
			<Avatar user={user} size={'70px'}/>
			<div className={'ml'}>
				<Username>{user.firstName}</Username>
				<UserAlias>{user.lastName}</UserAlias>
			</div>
		</div>
	);
};
