import React, {useContext, useState} from 'react';
import {Button, Select, Spinner} from 'components/ui';
import {MutationTuple, QueryResult, useMutation, useQuery} from '@apollo/client';
import {UserContext, userContextInterface} from 'context/userContext';
import {query} from 'actions/queries';
import {mutation} from 'actions/mutations';
import {useForm} from 'hooks/useForm/useForm';
import {FieldErrors, FormHook} from 'hooks/useForm/interfaces';
import {Item, Opportunity, ShortLink, TalentOpportunity} from 'interfaces';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {color} from 'styles/colors';
import {Message} from '../../../components';


type ShareFormProps = {
    talentId: string
	share: (shortLink:ShortLink, talentOpportunity:TalentOpportunity)=>void
}

const ShareCopy:any = styled.p`
	width: 481px;
	margin: 2rem 0;
`;

const OpportunitiesLink : any= styled(Link)`
	color: ${color.get('primary')};
	text-transform: uppercase;
`;

export const ShareForm = ({talentId,share}:ShareFormProps): JSX.Element => {
	const {user}:userContextInterface = useContext(UserContext);
	const { data:opportunityData, loading:opportunityLoading }:QueryResult = useQuery(query('opportunityFindMany'),{
		variables:{
			filter:{company: user?._id, active:true},
			skip:0,
			limit:100
		},
		fetchPolicy:'network-only'
	});
	// mutations
	const [createTalentOpportunity ]:MutationTuple<any, any> = useMutation(mutation('talentOpportunityUserCreateOne'));
	// eslint-disable-next-line @typescript-eslint/typedef
	const [createLink, {loading:linkLoading} ]:MutationTuple<any, any> = useMutation(mutation('shortLinkCreateOne'));

	const onSubmit= async(data:any):Promise<void>=>{
		console.log({data, talentId});
		try {
			const res: any =await createTalentOpportunity({
				variables:{
					talent:talentId,
					opportunity:data.opportunity
				}
			});
			const link:any= await createLink({
				variables:{
					type:res.data.talentOpportunityUserCreateOne._id
				}
			});
			share(link.data.shortLinkCreateOne, res.data.talentOpportunityUserCreateOne);
		}
		catch (e) {
			console.log({e});
		}

	};

	const { registerSelect, handleSubmitForm, errors }:FormHook = useForm([{ name:'opportunity', default:'', required:true }], onSubmit);
	const opportunityOptions:Item[]= opportunityData?.opportunityFindMany?.map((opportunity:Opportunity):Item=>({
		label:opportunity.name,
		id:opportunity._id
	}));
	return ( opportunityLoading?
		<Spinner/>
		:

		<form onSubmit={handleSubmitForm} className={'flex direction-column align-center'}>
			<ShareCopy>
						Share one of your opportunities with this talent. A secure link will be generated with password access. You
						will be able to share the link and the talent will learn more about the opportunity.
			</ShareCopy>
			{opportunityOptions.length ?
				<Select
					label={'Select Opportunity'}
					options={opportunityOptions}
					{...registerSelect('opportunity')}
				/>
				: null
			}
			<Button
				label={'Create Share Link'}
				type={'submit'}
				size={'small'}
				disabled={opportunityOptions.length<1}
				loading={linkLoading}
			/>
			{(errors.get('opportunity') as FieldErrors)?.required as boolean && <Message className={'mt'} message={'Please select an opportunity'} color={color.get('primary')}/>}
			{opportunityOptions.length<1?
				<div className={'pt-2 center'}>
					<p className={'pb-half'}>You have no opportunities yet</p>
					<OpportunitiesLink to={'/campaigns/form/create'} className={'t-primary'}>
						Create aN Opportunity
					</OpportunitiesLink>
				</div>
				:
				null
			}
		</form>

	);
};