import React, {useEffect, useState} from 'react';
import {CreateAlertRender} from './CreateAlertRender';
import {useForm} from 'hooks/useForm/useForm';
import {FormField, FormHook} from 'hooks/useForm/interfaces';
import {NavigateFunction, useNavigate, useParams, Params} from 'react-router-dom';
import {MutationTuple, useMutation} from '@apollo/client';
import {mutation} from 'actions/mutations';
import {uploadCulturalAlertDeck, uploadCulturalAlertVideoTreatment} from '../../actions/restActions';


const fieldsByAlertType:any= {
	'event':[
		{name:'eventCategory'},
		{name:'instagram'},
		{name:'tiktok'},
		{name:'website'}
	],
	'musicvideo':[
		{name:'videoSongUrl'}
	]

};

export const CreateAlert = ():JSX.Element=>{
	const navigate:NavigateFunction=useNavigate();
	const {eventType}:Params=useParams();

	// files
	const [deck,setDeck]=useState<File|null>(null);
	const [videoTreatment,setVideoTreatment]=useState<File|null>(null);

	const getNextPath= (alertId:string,eventType:string):string=>{
		const nextPathByAlertType:any={
			event:`/company/alerts/${alertId}/edit/location`,
			musicvideo:`/company/alerts/${alertId}/edit/music-event`
		};
		return nextPathByAlertType[eventType];
	};

	const [createAlert]:MutationTuple<any, any> =useMutation(mutation('culturalAlertCreateOne'));
	// const [updateAlert]:MutationTuple<any, any> =useMutation(mutation('culturalAlertUpdateOne'));

	const onSubmit= async(data:any):Promise<void>=>{

		data.artist=data.talents[0] && data.talents[0];
		delete data.talents;

		let culturalData:any = await createAlert({
			variables:{...data, culturalType:eventType}
		});
		const alert:any= culturalData.data.culturalAlertCreateOne;
		deck && await uploadCulturalAlertDeck(deck,alert._id);
		videoTreatment && await uploadCulturalAlertVideoTreatment(videoTreatment,alert._id);
		navigate(getNextPath(alert._id,eventType));
	};

	const  formFields:FormField[]=[
		{name:'talents', required:true, array: true},
		{name:'title',required:true},
		{name:'description', required:true},
		...fieldsByAlertType[eventType]
	];

	const {loading,errors,...rest}:FormHook=useForm(formFields,onSubmit);

	useEffect(():void=>{
		console.log({eventType});
		rest.resetForm();
	},[eventType]);

	return <CreateAlertRender
		typeEvent={eventType}
		submitLoading={loading}
		errors={errors}
		onSubmit={rest.handleSubmitForm}
		registerInput={rest.registerInput}
		registerList={rest.registerList}
		registerSelect={rest.registerSelect}
		registerTextArea={rest.registerTextArea}
		fileHandlers={{
			videoTreatment:(file:File):void=>setVideoTreatment(file),
			deck:(file:File):void=>setDeck(file)
		}}
	/>;
};