import React from 'react';
import {FormProps, Item} from 'interfaces';
import {DropDown, FormButton, Select} from 'components/ui';
import {sortListBy} from '../../../../tools/globalActions';

export interface EthnicityProps extends FormProps {
	languages:Item[]
	ethnicities:Item[]
	countries:Item[]
}

export const EthnicityRender = ({languages,submitLoading,ethnicities,countries,registerSelect,registerList,onSubmit}:EthnicityProps):JSX.Element=>{
	return (
		<div className="pb-4 max-w-700px w-100">
			<form onSubmit={onSubmit} >
				<DropDown
					label={'Add Languages'}
					placeholder={'select to add language'}
					options={sortListBy(languages,'label')}
					{...registerList('languages')}
				/>
				<Select
					label={'Add Ethnicity'}
					options={ethnicities}
					{...registerSelect('ethnicity')}
				/>
				<Select
					label={'Country of Origin'}
					options={countries}
					{...registerSelect('country')}
				/>

				<div className="center">
					<FormButton
						loading={submitLoading}
						variant={'secondary'}
						label={'Save'}
					/>
				</div>

			</form>
		</div>
	);
};