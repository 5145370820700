import React, {useContext} from 'react';
import LogoutButtonRender from './LogoutButtonRender';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import {UserContext} from 'context/userContext';

export const LogoutButton=():JSX.Element=>{
	const {logout}:any=useContext(UserContext);
	const navigate: NavigateFunction=useNavigate();
	const logoutAction =():void=>{
		logout();
		navigate('/');
	};
	return <LogoutButtonRender logout={logoutAction} />;
};
