import React from 'react';
import {Picture} from 'stylesOpus/layout';
import {SliderImageContainer, SliderArrow, SliderButton} from './Slider.styles';
import {NavigateFunction, useLocation, useNavigate, Location} from 'react-router-dom';
import {ReactComponent as ChevronLeft} from 'styles/icons/chevron-left.svg';
import styled from 'styled-components';

type SliderProps = {
    images: string[];
	width?: string;
	height?: string;
	aspectRatio?: string;
}

const ChevronRight :any = styled(ChevronLeft)`
	rotate: 180deg;
`;

export const Slider = ({images, width, height, aspectRatio}:SliderProps): JSX.Element => {
	const location: Location = useLocation();
	let defaultActive: number= parseInt(location.hash?.slice(-1)[0]) || 0;
	defaultActive = defaultActive >=0 && defaultActive < images.length ? defaultActive : 0;
	const [activeImage, setActiveImage] = React.useState(defaultActive);
	const navigate:NavigateFunction = useNavigate();

	const handleChangeImage = (newIndex:number):void => {
		if(newIndex >=0 && newIndex < images.length)  {
			setActiveImage(newIndex);
			navigate(`#slide_${newIndex}`);
		}

	};

	return (
		<div className={'relative flex direction-column row-gap align-center grows'}>
			<SliderImageContainer $width={width} $height ={height} $$aspectRatio={aspectRatio} >
				{images.map((image:string,index:number):JSX.Element =>
					<div key={image}  className={'w-100'}>
						<Picture src={image} id={`slide_${index}`} ratio={'16/9'} />
					</div>

				)}
				{/*<div className={'absolute w-100 '}>*/}
				{/*	<button onClick={():void=>changeCurrentImage(activeImage-1)}>click -</button>*/}
				{/*	<button onClick={():void=>changeCurrentImage(activeImage+1)}>click +</button>*/}
				{/*</div>*/}
			</SliderImageContainer>

			{ images.length > 1 &&
				<>
					<SliderArrow
						className={'absolute left-1'}
						href={`#slide_${activeImage}`}
						active={activeImage > 0}
						onClick={(): void => handleChangeImage(-1 + activeImage)}>
						<ChevronLeft/>
					</SliderArrow>

					<SliderArrow
						className={'absolute right-1'}
						href={`#slide_${activeImage}`}
						active={(activeImage < images.length - 1)}
						onClick={(): void => handleChangeImage(1 + activeImage)}
					>
						<ChevronRight/>
					</SliderArrow>
				</>
			}

			{images.length > 1 &&
				<div className={'flex col-gap'}>
					{images.map((image: string, index: number): JSX.Element =>
						<SliderButton
							key={image}
							href={`#slide_${index}`}
							active={activeImage === index}
							onClick={(): void => handleChangeImage(index)}
						/>
					)}
				</div>
			}
		</div>
	);
};
