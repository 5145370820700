import styled from 'styled-components';
import {color} from '../../styles/colors';

export const BarchartWrapper:any=styled.div`
	>h4{
      font-weight: 800;
      font-size: 32px;
      line-height: 39px;
	}
  >p{
	color : ${color.get('neutral-400')};
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }
`;