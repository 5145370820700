import React from 'react';
import SubtacticAlertRowLoad from '../../components/SubtacticAlertRow/SubtacticAlertRow.load';
import {Params, useParams} from 'react-router-dom';
import {UserContext, userContextInterface} from '../../context/userContext';


export default function OpportunitiesFeaturedRender():JSX.Element {

	const {slug}:Params = useParams();
	const {user} : userContextInterface = React.useContext(UserContext);

	return (
		<div>
			<SubtacticAlertRowLoad
				hideViewAll={true}
				limit={40}
				title={slug==='new' ? 'Row based on upload date' : 'Row based on expiration date'}
				description={'Lorem ipsum dolor sit amet consectetur. Tristique eget vestibulum volutpat diam ac sagittis integer nibh lacus.'}
				filter={{
					active:true,
					ENDDATE_GREATERTHAN: new Date().toISOString(),
					_operators:{tenant:{in:user.tenantList}}
				}}
				sort={slug==='new' ? 'CREATEDAT_DESC' : 'ENDDATE_ASC'}
			/>
		</div>
	);
}
