import React from 'react';
import {IconProps} from 'interfaces';

export const SecurityIcon=({color,size}: IconProps):JSX.Element=>{

	return(
		<svg
			width={size || 24}
			height={size || 26}
			viewBox="0 0 16 22"
			fill={color || '#fff'}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2.8 21.503h9.829c.788 0 1.386-.215 1.794-.645.415-.422.623-1.06.623-1.912v-7.401c0-.845-.208-1.479-.623-1.901-.408-.43-1.006-.645-1.794-.645h-9.83c-.787 0-1.389.215-1.804.645-.408.422-.612 1.056-.612 1.9v7.402c0 .852.204 1.49.612 1.912.415.43 1.017.645 1.805.645zm.054-1.622c-.23 0-.409-.072-.538-.215-.128-.136-.193-.337-.193-.602v-7.637c0-.265.064-.466.193-.602.13-.136.308-.204.538-.204h9.721c.237 0 .416.068.537.204.13.136.194.337.194.602v7.637c0 .265-.065.466-.194.602-.121.143-.3.215-.537.215H2.854zM2.263 9.826H3.97v-3.62c0-.902.172-1.661.515-2.277.344-.616.799-1.082 1.365-1.397a3.794 3.794 0 011.858-.472c.673 0 1.293.157 1.858.472.573.315 1.032.78 1.375 1.397.351.616.527 1.375.527 2.277v3.62h1.697V6.432c0-1.347-.261-2.464-.784-3.352-.516-.888-1.189-1.55-2.02-1.987A5.619 5.619 0 007.71.438c-.931 0-1.812.218-2.643.655-.83.437-1.507 1.1-2.03 1.987-.515.888-.773 2.005-.773 3.352v3.394z"
				fill={color || '#fff'}
			/>
		</svg>);
}
;
