import React, {useContext, useEffect} from 'react';
import AlertDetailRender from './AlertDetailRender';
import {Params, useParams} from 'react-router-dom';
import {QueryResult, QueryTuple, useLazyQuery, useQuery} from '@apollo/client';
import {query} from 'actions/queries';
import {UserContext, userContextInterface} from 'context/userContext';
import {Spinner} from 'components/ui';
import {CulturalAlert, User} from 'interfaces';


export default function AlertDetail():JSX.Element {
	const {id}:Params=useParams();
	const {user}:userContextInterface=useContext(UserContext);

	const filter:any ={_id:id};
	if (user.type.label === 'Agent') filter.user=user._id;

	// get cultural alerts
	const {data:alertData,loading:alertsLoading}:QueryResult =useQuery(query('culturalAlertFindOne'),
		{variables:{
			filter:{_id:id}
		}});
	const alert:CulturalAlert|null=alertData?.culturalAlertFindOne || null;

	// eslint-disable-next-line @typescript-eslint/typedef
	const [getArtist, {data:artistData}]:QueryTuple<any, any> =
		useLazyQuery(query('usersFindMany'));
	// eslint-disable-next-line @typescript-eslint/typedef
	const [getCompany, {data:companyData}]:QueryTuple<any, any> =
		useLazyQuery(query('usersCompanyProfileFindOne'));
	// eslint-disable-next-line @typescript-eslint/typedef
	// eslint-disable-next-line @typescript-eslint/typedef
	const [getInstagramAudience,{data:instagramData}]:QueryTuple<any, any> =
		useLazyQuery(query('instagramAudienceFindOne'));

	//
	const artist:User|null=artistData?.usersFindMany?.length ? artistData?.usersFindMany[0] : null;

	const {data, loading, error}:QueryResult = useQuery(query('discoverTalent'),{
		variables : {
			user: artist?._id
		}
	});


	useEffect(():void=>{

		if(alert){
			console.log('init artist');
			getArtist({variables:{
				filter:{_id:alert.artist[0]}
			}});
			getCompany({variables:{
				user: alert.user
			}});
		}

	},[alertData]);

	useEffect(():void=>{
		if(artist){
			getInstagramAudience({
				variables:{
					filter:{user: artist._id}
				}
			});
		}
	},[artistData]);

	if(alertsLoading ||  loading || !artist)
		return <div className={'w-100 '}><Spinner/></div>;

	return (
		<AlertDetailRender
			talentData={data.usersTalentProfileFindOne }
			audience={instagramData?.instagramAudienceFindOne}
			alert={alert}
			artist={artistData?.usersFindMany[0]}
			company={companyData?.usersCompanyProfileFindOne}
			followersCount={instagramData?.instagramAudienceFindOne?.followersCount}
		/>
	);
}