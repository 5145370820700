import {ContentBox, Flex} from 'stylesOpus/layout';
import {BodyText, BoxTitle} from 'stylesOpus/typography';
import {Line} from 'stylesOpus/ui';
import {AudienceItem} from 'pages/Insights/interfaces';
import AuthorityRow from '../alerts/TalentDetail/components/AuthorityRow';
import {Flag} from '../AudienceByCountry/styles';
import AudienceGraphs from '../alerts/TalentDetail/components/AudienceGraphs';
import React from 'react';
import {getFlag} from 'tools/globalActions';
import {TalentData} from 'hooks/useTalentDetail';

type TalentInfoProps = {
    audience?: {
        audienceCountry?:AudienceItem[]
        audienceCity?:AudienceItem[]
        audienceBrand?:AudienceItem[]
        audienceInterests?: AudienceItem[]
        audienceGenderAge?: AudienceItem[]
        audienceEthnicities?: AudienceItem[]
        followersCount?: number
    }
    talentData:TalentData
}

export const TalentInfo = ({ audience,talentData }:TalentInfoProps):JSX.Element => {
	
	return (
		<div className="w-100 grid3 col-gap-2 mt-2">
			<ContentBox padding="2rem">
				<BoxTitle className="mt">
					{talentData?.userObj?.firstName}{`'`}s Relevant Authority
				</BoxTitle>
				<BodyText className="mb mt-half">
                    ØPUS’ scores represent how much Authority a talent has in a given category or product. It gauges the level of influence and impact this talent has to convert your target audience.
				</BodyText>

				{audience && audience?.audienceInterests?.length && audience.audienceInterests.slice(0,4).map(({name, weight}:AudienceItem, index:number):JSX.Element => {
					if (name!=='Friends, Family & Relationships') {
						return (
							<div key={`${name}-${index}-int`}>
								<AuthorityRow
									talentId={talentData?.user}
									interest={name}
									weight={weight}
									key={name}
									avatar={talentData?.userObj?.profilePicture?.url}
									audienceInterests={audience.audienceInterests}
									audienceBrand={audience.audienceBrand}
								/>
								{index < 2 && <Line color="grey_600" /> }
							</div>
						);
					}
				})}
			</ContentBox>
			<ContentBox padding="2rem">
				<BoxTitle textAlign="center" className="mt mb">
                    Audience Location
				</BoxTitle>

				{audience && audience?.audienceCountry?.length && audience.audienceCountry.slice(0,4).map(({name, weight}:AudienceItem, index:number):JSX.Element => {
					return (
						<div key={`${name}-${index}`}>
							<Flex className="pver" key={name}>
								<Flag className="mr" country={getFlag(name)}/>
								<BoxTitle fontSize="13px">{name}</BoxTitle>
								<div className="grows" />
								<BoxTitle fontSize="13px">{(weight * 100).toFixed(2)}%</BoxTitle>
							</Flex>
							{index < 3 && <Line color="grey_600" /> }
						</div>
					);
				})}

				<BoxTitle textAlign="center" className="mt-2 mb">
                    Top Cities
				</BoxTitle>

				{audience && audience?.audienceCity?.length && audience.audienceCity.slice(0,4).map(({name, weight}:AudienceItem, index:number):JSX.Element => {
					return (
						<div key={`${name}-${index}-city`}>
							<Flex className="pver" key={name}>
								<BoxTitle fontSize="13px">{name}</BoxTitle> <div className="grows" /> <BoxTitle fontSize="13px">{(weight * 100).toFixed(2)}%</BoxTitle>
							</Flex>
							{index < 4 && <Line color="grey_600" /> }
						</div>
					);
				})}

			</ContentBox>
			{audience &&
                <AudienceGraphs genderAge={audience?.audienceGenderAge} ethnicities={audience?.audienceEthnicities} reach={audience?.followersCount} />
			}
		</div>
	);
};