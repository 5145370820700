import React, {useEffect, useState} from 'react';
import {AudienceItem} from '../interfaces';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import faker from 'faker';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

const options:any = {
	responsive: true,
	maintainAspectRatio: false,
	indexAxis: 'y' as const,
	scales: {
		YAxis: {
			display: true
		}
	},
	plugins: {
		legend: {
			display: false,
			position: 'top' as const
		}
	}
};


interface AudienceCat {
	items?: AudienceItem[]
	color?: string
}

interface DataSetItem {
	label?: string
	data: number[]
	backgroundColor?: string
	borderRadius?: number
}

interface DataItem {
	labels: string[]
	datasets: DataSetItem[]
}


export default function HorizontalChart({items, color}:AudienceCat):JSX.Element {

	const [data, setData] = useState<DataItem>({
		labels: [],
		datasets: [
			{
				label: 'Dataset 1',
				data: [],
				backgroundColor: 'rgba(255, 99, 132, 0.5)'
			}
		]
	});

	useEffect(():any => {
		if(items && items.length > 0){
			const ll:string[] = [];
			const ww:number[] = [];
			items.map(({name}:AudienceItem, index:number):void =>{
				if(index < 8){
					ll.push(name);
				}
			});

			items.map(({weight}:AudienceItem, index:number):void=>{
				if(index < 8){
					ww.push(weight);
				}
			});

			const d:DataItem = {
				labels: ll,
				datasets: [
					{
						label: 'Brands',
						data: ww,
						backgroundColor: color || 'rgba(255, 99, 132, 0.5)',
						borderRadius: 20
					}
				]
			};

			setData(d);

		}
	},[items]);

	if (data.labels.length > 0){
		return <div className="flex grows min-h-300px">
			<Bar className="grows" options={options} data={data} />
		</div>;
	}
	else {
		return <p>Loading...</p>;
	}

}
