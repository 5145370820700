import React, {useContext} from 'react';
import {Avatar, ImageCard} from '../ui';
import {StackContainer} from 'styles/ui';
import {UserContext, userContextInterface} from 'context/userContext';
import {Brand} from 'interfaces';
import {Link} from 'react-router-dom';
import {QueryResult, useQuery} from '@apollo/client';
import {query} from 'actions/queries';

interface ProductCardProps {
	product:Brand
}

export const ProductCard = ({product}:ProductCardProps):JSX.Element => {
	const {user}:userContextInterface=useContext(UserContext);
	const {data:skusData, loading:skusLoading}:QueryResult=useQuery(query('skuFindMany'),{
		variables:{
			findSkuFilter:{brand:product._id}
		}
	});

	let totalSkus:number= !skusLoading? skusData?.skuFindMany.length : '';

	return <Link
		to={`/products/${product._id}`}
		className={'w-100 mt t-white'}
	>
		<ImageCard img={product.picture?.url}>
			<div className='grows flex direction-column justify-between p'>
				<StackContainer
					sizeValue ={60}
					sizeType={'px'}
					length={2}
					percentage={75}
				>
					<Avatar user={user} size={'60px'}/>
				</StackContainer>

				<div className=''>
					<h5 className={'lh-150'}>{product.name}</h5>
					<p className={'f-500 f-small'}>SKUS: {totalSkus}</p>
				</div>
			</div>
		</ImageCard>
	</Link>;
};