import styled from 'styled-components';
import {themeOpus} from 'stylesOpus/theme';

type ImgeContainerProps = {
    $width?: string;
    $height?: string;
    $aspectRatio?: string;
}

export const SliderImageContainer: any = styled.div<ImgeContainerProps>`
  width: ${({$width}:any):string => $width || '100%'};
  display: flex;
  overflow: hidden; /* Oculta el scrollbar */
  position: relative;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  flex-grow: 1;
  &::before {
    content: '';
    width: 20px; /* Ancho suficiente para ocultar el scrollbar */
    flex-shrink: 0; /* No afecta al ancho del contenedor principal */
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 100%;
    margin-right: 0;
    box-sizing: border-box;
    transform-origin: center center;
    transform: scale(1);
    scroll-snap-align: center;
    overflow-x: scroll; /* Habilita el desplazamiento horizontal */
  >div{
    width: 100%;
    height: 100%;
  }
    /* Puedes personalizar el scrollbar de esta manera si es necesario */
    ::-webkit-scrollbar {
      width: 0; /* Ancho 0 para ocultar el scrollbar en navegadores basados en WebKit */
    }
  }
`;

export const SliderButton: any = styled.a<{active:boolean}>`
  height: 0.5rem;
  width: ${({active}:any):string=> active? '4rem' : '0.5rem'};
  border-radius: 0.5rem;
  background-color: ${themeOpus.colors.grey_300};
  cursor: ${({active}:any):string=> !active && 'pointer'};
`;

export const SliderArrow: any = styled.a<{active:boolean}>`
  position: absolute;
  top: calc(50% - 2.2rem);
  height: 2.2rem;
  width: 2.2rem;
  border-radius: 1rem;
  display: flex;
  place-items: center;
  justify-content: center;
  background-color: ${ themeOpus.colors.grey_800 };
  opacity: ${({active}:any):string=> active ? '1' : '0.4' };
  cursor: ${({active}:any):string=> !active && 'pointer'};
`;