import {color} from 'styles/colors';
import styled from 'styled-components';
import selectIcon from 'styles/icons/carat-down.svg';
import searchIcon from 'styles/icons/search-icon.svg';
import {inputField} from 'styles/ui';

export type SelectStylesProps = {
	searchIcon?: boolean
};

export const StyledSelect:any=styled.select<SelectStylesProps>`
  	${inputField};
	  color: white;
	  background-image: url(${ (props:SelectStylesProps):any=> props.searchIcon? searchIcon: selectIcon});
	  background-repeat: no-repeat;
	  background-position: right;
      
		padding-top:4px ;
	  
	  option:first-child {
		color: ${color.get('neutral-400')};
	  }
  
	  -webkit-appearance: none;
	  -moz-appearance: none;
`;

export const SelectOption:any=styled.option`
	color:black;
`;
