import React, {useContext, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {MutationTuple, useMutation} from '@apollo/client';

import Stepper from 'pages/OpprtunityCreate/Components/Stepper';
import {Opportunity} from 'interfaces';
import {Button, CircleButton, LinkButton} from 'components/ui';
import {DeleteIcon, EditIcon, NotificationIcon, SearchIcon} from 'icons';
import {ReactComponent as ChevronLeft} from 'styles/icons/chevron-left.svg';
import {ModalContainer} from 'components/ModalContainer';
import {Card} from 'components';
import {mutation} from 'actions/mutations';
import {UserContext, userContextInterface} from 'context/userContext';
import {themeOpus} from 'stylesOpus/theme';

type OpportunityDetailHeaderProps = {
        opportunity:Opportunity
}

export const OpportunityDetailHeader = ({opportunity}:OpportunityDetailHeaderProps): JSX.Element => {
	const {user}:userContextInterface = useContext(UserContext);
	const userType:string = user.type.label.toLowerCase();
	const [del, setDel] = useState(false);
	let {id}:any = useParams();
	const navigate:any = useNavigate();

	const [ deleteOpportunity ] : MutationTuple<any, any> = useMutation(mutation('opportunityDeleteOne'), {
		//refetchQueries:['culturalAlertFindMany']
	});

	const handleDelete = async():Promise<void>=> {
		await deleteOpportunity({
			variables: {_id: id}
		});
		navigate('/campaigns/active');

	};

	const backLink:string= userType === 'regular'?'/dashboard' :`/campaigns/${opportunity.active?'active':'draft'}`;

	return (
		<header className={'flex justify-between align-center pt pb-2'}>
			<div className={'flex col-gap align-center'}>
				<CircleButton
					to={backLink}
					size={'small'}
					variant={'dark-grey'}
				>
					<ChevronLeft/>

				</CircleButton>
				<Stepper
					steps={[
						{id:'opportunities',label:'Opportunity', identifier:''},
						{id:'-',label:opportunity.name, identifier:'_'}
					]}
					links={[backLink]}
					current={'_'}
				/>

			</div>
			<div className={'flex col-gap'}>

				{userType === 'company' &&
					<LinkButton
						rounded
						size={'small'}
						variant={'blue'}
						to={`/discover`}
					>
						<div className={'flex col-gap'}>
							<SearchIcon size={16} />
							<p>View Matches</p>
						</div>
					</LinkButton>
				}


				{userType === 'company' &&
					<LinkButton
						rounded
						size={'small'}
						variant={'white'}
						to={`/opportunities/${opportunity._id}/edit`}
					>
						<div className={'flex col-gap'}>
							<EditIcon size={16} color={themeOpus.colors.grey_1000} />
							<p>Edit</p>
						</div>
					</LinkButton>
				}


				{userType === 'company' &&
					<Button
						rounded
						size={'small'}
						variant={'white'}
						onClick={(): void => setDel(true)}
					>
						<div className={'flex col-gap'}>
							<div className={'flex col-gap'}>
								<DeleteIcon color={themeOpus.colors.grey_1000} />
								<p>Delete</p>
							</div>
						</div>
					</Button>
				}

				<CircleButton
					to={-'/campaigns'}
					size={'small'}
					variant={'dark-grey'}
				>
					<NotificationIcon/>
				</CircleButton>
			</div>

			<ModalContainer show={del} close={():void=>setDel(false)}>
				<Card>
					<div className={'flex direction-column align-center'}>
						<h4 className="mt">Delete opportunity</h4>
						<p className={'mver-2 center phor'}>
							Are you sure to delete this opportunity?
						</p>
						<div className={'w-100 col-gap flex align-center '}>
							<Button
								label={'Cancel'}
								variant={'secondary-white'}
								onClick={():void=>setDel(false)}
							/>
							<Button
								label={'Confirm Delete'}
								variant={'white'}
								onClick={():Promise<void>=>handleDelete()}
							/>
						</div>

					</div>
				</Card>

			</ModalContainer>
		</header>
	);
};

//show={showTalent} close={():void => setShowTalent(false)} centered={false}