import React, {useContext} from 'react';
import {ContactFormRender} from './ContactFormRender';
import {FormField, FormHook} from 'hooks/useForm/interfaces';
import {useForm} from 'hooks/useForm/useForm';
import {UserContext} from 'context/userContext';
import {User, UpdateTalentData} from 'interfaces';
import {EditProfileContext} from '../../index';
import {useOutletContext} from 'react-router-dom';

export const ContactForm = ():JSX.Element=>{
	const {user}:any=useContext(UserContext);
	const {profileData,updateProfile}:EditProfileContext=useOutletContext();

	const  formFields:FormField[]=[
		{name:'phone', required:false,default:profileData?.phone||''},
		{name:'website', required:false,default:profileData?.website||''},
		{name:'address1', required:false,default:profileData?.address?.address1||''},
		{name:'address2', required:false,default:profileData?.address?.address2||''},
		{name:'city', required:false,default:profileData?.address?.city||''},
		{name:'state', required:false,default:profileData?.address?.state||''},
		{name:'zip', required:false,default:profileData?.address?.zip||''}
	];

	const handleSubmit=async(formData:any):Promise<void> =>{
		const {phone,website,address1,address2,city,state,zip}:User=user;
		let hasChanges:boolean=data.phone!==phone ||
			data.website!==website ||
			data.address1!==address1 ||
			data.address2!==address2 ||
			data.city!==city ||
			data.state!==state ||
			data.zip!==zip;
		// let someNull:boolean=(!!phone||!!website||!!address1||!!address2||!!city||!!state||!!zip);
		if(hasChanges){
			console.log('submit', {data});
			let updateData:UpdateTalentData={
				phone:formData.phone,
				website:formData.website,

				address:{
					address1:formData.address1,
					address2:formData.address2,
					city:formData.city,
					state:formData.state,
					zip:formData.zip
				}
			};
			updateProfile({variables:{
				profileData:updateData
			}});
		}

	};

	const  {data,errors,loading,...rest}:FormHook=useForm(formFields,handleSubmit);

	return <ContactFormRender
		submitLoading={loading}
		errors={errors}
		registerInput={rest.registerInput}
		onSubmit={rest.handleSubmitForm}
	/>;
};