import React from 'react';
import {BreadCrumb} from './BreadCrumb';
import {useRecoilValue} from 'recoil';
import {BreadCrumbAtom, TCrumb} from '../../context/recoil/store';
import {Location, useLocation} from 'react-router-dom';

export const BreadCrumbLoad=():JSX.Element=>{
	const {pathname}: Location= useLocation();
	const path:string = pathname.split('/')[1];
	let crumbs:TCrumb[] = useRecoilValue<TCrumb[]>(BreadCrumbAtom);
	
	
	if (crumbs.length === 0){
		crumbs = [{id:path, label:path, path:'/'+path}];
	}

	const backLink:string = crumbs.length>1 ? crumbs[crumbs.length-2].path :'';
	return <BreadCrumb
		crumbs={crumbs}
		backLink={ crumbs.length>1 && backLink }
	/>;
};