import React, {useEffect, useState} from 'react';
import MultiSelector from './form/MultiSelector';
import {IMatch, IMatchAudience, IOption} from '../DiscoverInterfaces';
import {useRecoilState} from 'recoil';
import {matchQueryAtom} from '../../../context/recoil/store';

export default function AudienceGender():JSX.Element {

	const [matchQuery, setMatchQuery] = useRecoilState<IMatch>(matchQueryAtom);
	const [value, setValue] = useState<string[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const options: IOption[] = [
		{
			label: 'Male',
			_id: 'male'
		},
		{
			label: 'Female',
			_id: 'female'
		}
	];

	useEffect(():void=>{
		if(loading){
			if(matchQuery?.audience?.gender){
				setValue([matchQuery?.audience?.gender]);
			}
			setLoading(false);
		}
	},[loading]);

	useEffect((): void => {

		let m: IMatch = {...matchQuery};
		let a: IMatchAudience = {...matchQuery.audience};
		m.audience = a;

		if (value.length === 1 && !value.includes('nb')) {
			m.audience.gender = value[0];
		} else {
			delete m.audience.gender;
		}
		setMatchQuery(m);

	}, [value]);

	return (
		<div>
			<MultiSelector options={options} value={value} setValue={setValue}/>
		</div>
	);
}