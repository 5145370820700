import React from 'react';
import {AutoCompleteProps,Item} from 'interfaces';
import {color} from 'styles/colors';
import styled from 'styled-components';
import {StyledButton} from 'styles/ui';
import {Tags} from '../Tags';

interface AutoCompleteRenderProps extends AutoCompleteProps{
	onEnter:()=>void;
	selectedTags:Array<Item>;
	selectCategory:(tag:Item)=>void;
	unselectCategory:(tag:Item)=>void;
}

export const StyledInput:any=styled.div`
	width: 100%;
	margin: 1rem 0;
	>{
		display: flex;
		justify-content: space-between;
		font-size: 1.125rem;
		line-height: 1.375rem;
		margin-bottom: 1rem;
		color: ${color.get('white')};
	}
	>.auto-complete {
		display: flex;
		align-items: center;
		padding: 0 8px;
		height: 64px;
		border-radius: 8px;
		border: 1px solid ${color.get('neutral-500')};
		>input{
			-moz-appearance: textfield;
			flex-grow: 1;
			border: none;
			color: white;
			font-size: 1rem;
			background-color: transparent;
			:focus-visible{
				border: none;
			}
			:focus{
				outline: none;
			}
			::placeholder{
				color: ${color.get('neutral-400')};
			}
	
			:-webkit-autofill,
			:-webkit-autofill:hover,
			:-webkit-autofill:focus{
				-webkit-text-fill-color: white;
				background-color: transparent ;
				transition: background-color 1000s ease-in-out 0s;
			}
		}
	}
	:focus-within{
		border-color: white;
	  >div{
		border-color: white;
	  }
		>.auto-complete button{
			background-color: ${color.get('primary')};
		}
	}
	
`;

const StyledTexts:any=styled.div`
	display: flex;
	margin-bottom: 1rem;
	justify-content: space-between;
	align-items: center;
	label{
		font-weight: 700;
		line-height: 22px;
	}
	p{
		font-size: 0.75rem;
		line-height: 15px;
	}
	>p{
		font-size: 0.75rem;
		color: ${color.get('neutral-500')};
	}
`;

const EnterButton:any=styled(StyledButton)`
	width: 85px;
	height: 40px;
	border-radius: 8px;
	background-color: ${color.get('neutral-500')};
`;

export default function AutoCompleteRender({
	selectedTags,
	label,
	helperText,
	options,
	required,
	className,
	type,
	onChange,
	onChangeCapture,
	onEnter,
	selectCategory,
	unselectCategory,
	...props
}:AutoCompleteRenderProps):JSX.Element{

	return (
		<>

			<Tags tags={selectedTags} selected onClickTag={unselectCategory}/>
			<StyledInput className={className}>
				{(label || required) &&
					<StyledTexts>
						<div>
							<label>{label}</label>
							{helperText && <p>{helperText}</p>}
						</div>
						{required && <p>Required</p>}
					</StyledTexts>
				}
				<div className="auto-complete">
					<input
						style={{display:'none'}}
						readOnly
					/>
					<input
						{...props}
						type={type}
						required={required}
						onChangeCapture={onChangeCapture}
					/>
					<EnterButton
						type="button"
						onClick={onEnter}
					>Enter</EnterButton>

				</div>
			</StyledInput>
			<Tags tags={options} onClickTag={selectCategory}/>
		</>
	);
}
