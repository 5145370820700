import React, {useEffect, useState} from 'react';
import {CulturalAlert, TPicture, User} from 'interfaces';
import {getRemainDays} from 'tools/date';
import {TalentData} from 'hooks/useTalentDetail';
import {Section} from 'styles/styledComponents/containers';
import {BoxTitle} from 'stylesOpus/typography';
import {Carousel, FeaturedTalent} from 'components/complexComponents';
import {OpportunityDetails, TalentPOV} from './components';
import {InquireSection} from './components';


interface AlertDetailRenderProps {
	alert:CulturalAlert
	artist:User|null
	audience :any
	talentData:TalentData
}

export const OpportunityDetailRender = ({audience,talentData,alert,artist}:AlertDetailRenderProps):JSX.Element  =>{

	const [photos, setPhotos] = useState<string[]>([]);
	const respondBy:string= alert.endDate || '';
	const remainingDays:number = getRemainDays( new Date(),new Date(respondBy) );

	useEffect(():void=>{
		setPhotos(alert.photos.map(({url}:TPicture):string=>url));
	},[alert]);


	return (
		<Section>
			<Carousel
				images={[...photos]}
				imageWidth={photos?.length>1? 70 : 100}
				aspectRatio={'728/275'}
			></Carousel>

			<OpportunityDetails alert={alert}/>
			<TalentPOV
				id={artist._id}
				talentName={`${talentData?.userObj?.firstName} ${talentData?.userObj.lastName}`}
				followerCount={audience?.followersCount || 0}
				alert={alert}
			/>

			<Section>
				<BoxTitle fontSize={'20px'}>Talent Profiles</BoxTitle>
				<br/>
				<FeaturedTalent talentId={artist._id}/>
			</Section>

			<InquireSection
				remainingDays={remainingDays}
				opportunityId={alert._id}
			/>
			
		</Section>
	);
};