import React from 'react';
import {InputProps, Item} from 'interfaces';
import {CloseIcon, PlusIcon} from 'icons';
import {Input, InputWrapper} from 'styles/ui';
import {themeOpus} from 'stylesOpus/theme';
import styled from 'styled-components';

export interface AppendableTextsRenderProps extends Omit<InputProps,'value'>{

	value:Item[]
	showInput:boolean
	currentInputValue:string
	actions:{
		quit:(item:string)=>void
		add:()=>void
		showInput:()=>void
		hideInput:()=>void
		changeInputValue:(e:React.ChangeEvent<HTMLInputElement>)=>void
	}
}


const ItemWrapper: any = styled(InputWrapper)`
    display: flex;
  justify-content: space-between;
  >p{
    font-weight: 700;
    font-family: 'Urbanist', Snas-serif;
    color: ${themeOpus.colors.light_grey_500};
  }
`;
const Label:any = styled.label`
	font-weight: 700;
	font-family: 'Urbanist', Snas-serif;
	
	display: block;
`;

export const AppendableTextsRender = ({value, currentInputValue, label, showInput,actions}:AppendableTextsRenderProps): JSX.Element => {

	return (
		<div className="mb">
			<Label className ='mb'>{label}</Label>

			{value.map((item:Item):JSX.Element=>
				<ItemWrapper
					key = {item.id}
					localTheme={'opusV2'}
				>
					<p >{item.label}</p>
					<div onClick={():void=>actions.quit(item.id)} className={'cursor-pointer'}>
						<CloseIcon/>
					</div>
				</ItemWrapper>
			)}

			{showInput?
				<ItemWrapper localTheme={'opusV2'}>
					<Input onChange={actions.changeInputValue} value={currentInputValue}/>
					<div onClick={actions.add} className={'cursor-pointer'}>
						<PlusIcon/>
					</div>
				</ItemWrapper>

				:
				<ItemWrapper localTheme={'opusV2'}>
					<Label>Add another</Label>
					<div onClick={actions.showInput} className={'cursor-pointer'}>
						<PlusIcon/>
					</div>
				</ItemWrapper>
			}


		</div>
	);
};