import React, {useContext, useEffect} from 'react';
import {SocialButtonProps, socialInterface} from 'interfaces';
import SocialButtonRender from './SocialButtonRender';
import {
	AppleMusicIcon,
	CaratRight,
	CheckIcon,
	DisconnectedIcon,
	FacebookIcon,
	InstagramIcon,
	SpotifyIcon,
	TikTokIcon,
	TwitterIcon,
	YoutubeIcon
} from 'icons';

import {SocialsHook, useSocials} from 'hooks/useSocials';
import {UserContext} from 'context/userContext';

export const SocialButton=({userAccount,connected,socialNetwork,connectLink,link,setError}:SocialButtonProps):JSX.Element=>{
	const {socialsData,instagramConnect,connectionError}:SocialsHook=useSocials(userAccount);
	const {user}:any =useContext(UserContext);

	useEffect(():void=>{
		if(connectionError){
			setError(connectionError,socialNetwork);
		}
	},[connectionError]);

	const onClick=(linkFunction:()=>void ):()=>void=>{
		if(!connected){
			return linkFunction;
		}
		if (link){
			return ():void=>console.log(`redirect to: ${socialNetwork}`);
		}
	};


	const connectLinkAction = ():string=>window.location.href=connectLink;
	// const connectLinkAction = ():Window=>window.open(connectLink);
	const propsBySocialNetwork:any={
		'facebook':{
			SocialIcon:<FacebookIcon/>,
			label:'Facebook',
			onClick:onClick(connectLinkAction)
		},
		'twitter':{
			SocialIcon:<TwitterIcon/>,
			label:'Twitter',
			onClick:onClick(connectLinkAction)
		},
		'instagram-ayrShare':{
			SocialIcon:<InstagramIcon/>,
			label:'Instagram',
			// onClick:onClick(instagramConnect)
			onClick:onClick(connectLinkAction)
		},'instagram':{
			SocialIcon:<InstagramIcon/>,
			label:'Instagram *',
			onClick:onClick(instagramConnect)
		},
		'youtube':{
			SocialIcon:<YoutubeIcon/>,
			label:'Youtube',
			onClick:onClick(connectLinkAction)
		},
		'apple-music':{
			SocialIcon:<AppleMusicIcon/>,
			label:'Apple Music',
			onClick:onClick(():void=>console.log(`Click social ${socialNetwork}`))
		},
		'spotify':{
			SocialIcon:<SpotifyIcon/>,
			label:'Spotify',
			onClick:onClick(():void=>console.log(`Click social ${socialNetwork}`))
		},
		'tiktok':{
			SocialIcon:<TikTokIcon/>,
			label:'Tik Tok',
			onClick:onClick(connectLinkAction)
		}
	};

	const stateIcon=():JSX.Element=>{
		if(!connected){
			return <DisconnectedIcon/>;
		}
		return link ? <CaratRight/> : <CheckIcon/>;
	};

	const getAccountName=():string=> {
		const username:string = socialsData?.filter((network:socialInterface):boolean=>network.identifier===socialNetwork)[0]?.url||'';
		return username;
	};


	return <SocialButtonRender
		{...propsBySocialNetwork[socialNetwork]}
		connected={connected}
		link={link}
		StateIcon={stateIcon()}
		accountName={'@'+getAccountName()}
		setError={setError}
	/>;
};
