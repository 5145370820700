import styled from 'styled-components';
import {Button} from 'components/ui';

export const HotListContainer:any = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  
  row-gap: 2.25rem;
`;

export const MainContainer:any = styled.div`
	width: 50rem;
  padding:0 2rem 2rem 2rem ;
	display: flex;
	flex-direction: column;
	align-items: center;
  row-gap: 1.62rem;
`;

export const BookmarkButton:any = styled(Button)`
  min-width: 9.5rem;
  background-color: #171720;
  display: flex;
  column-gap: 1rem;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 0 1.5rem;
`;