export const culturalAlertData:string=`
    _id
    user
    culturalType
    title
    videoSongTitle
    videoSongUrl
    videoDirector
    videoShootDate
    videoShootTime
    videoTreatment {
      url
      type
    }
    eventPaid
    eventCategory
    eventFrom
    eventTo
    eventPartnership
    endDate
    photos {
      url
      type
    }
    description
    feeRange {
      min
      max
      createdAt
      updatedAt
    }
    interest
    additionalInfo
    eventLocation
    city
    state
    website
    deck {
      url
      type
    }
    
    companyProfile(skip: 0, sort: _ID_ASC) {
      _id
      user
      name
      website
      phone
      interest
      position
      linkedinUrl
      logo{
        type
        url
      }
      createdAt
      updatedAt
      userObj(skip: 0, sort: _ID_ASC){
        firstName
        lastName
        email
        profilePicture{
          type
          url
        }
      }
      createdAt
      updatedAt
    }
    artist
    slug
    tenant
    tags
    subtactic
    tactic
    earnedMedia
    active
    deliverables
    additionalFees
	expectedAttendees
	participationRequirements
	product
	productDescription
	productIntegrationGuidelines
	paymentDetails
	availableSlots
`;