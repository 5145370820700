import React, {FC} from 'react';
import {IconProps} from './IconsInterfaces';
import {ReactComponent as Icon} from 'styles/icons/file-text.svg';
import styled from 'styled-components';

const StyledFileIcon:any =styled(Icon)<IconProps>`
  aspect-ratio: 16/17;
  width: ${({size}:IconProps):string=>size+'px'};
  >path{
    background-color: #1D3745;
    stroke:${({color}:IconProps):string=>color};
    background-color: #003f5f;
  };
`;
export const FileIcon :FC<IconProps> =({size=16,color}:IconProps):JSX.Element=>{

	return <StyledFileIcon width={size} height={size*(17/16)} color={color} />;
};
