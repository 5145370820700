import React, {useContext, useState} from 'react';
import {MutationFunction, MutationTuple, QueryResult, useMutation, useQuery} from '@apollo/client';
import {NavigateFunction, Params,Location, useLocation, useNavigate, useParams} from 'react-router-dom';
import {useForm} from 'hooks/useForm/useForm';
import {FormField, FormHook} from 'hooks/useForm/interfaces';
import {uploadSkuPicture} from 'actions/restActions';
import {mutation} from 'actions/mutations';
import {CreateSkuRender} from './CreateSkuRender';
import {query} from 'actions/queries';
import {UserContext, userContextInterface} from 'context/userContext';
import {Spinner} from 'components/ui';

export const CreateSku=():JSX.Element=>{
	const {productId,skuId}:Params=useParams();
	const edit:boolean=!!skuId;
	const {user}:userContextInterface=useContext(UserContext);
	const navigate:NavigateFunction=useNavigate();
	const [skuPicture,setSkuPicture]=useState<File|null>(null);
	const {pathname}:Location=useLocation();

	const{data:profileData, loading:profileLoading}:QueryResult=useQuery(query('usersCompanyProfileFindOne'),{
		variables:{user:user._id}
	});
	const{data:brandData, loading:brandLoading}:QueryResult=useQuery(query('brandFindMany'),{
		variables:{findBrandFilter: {_id:productId}}
	});
	const{data:skuData, loading:skuLoading}:QueryResult=useQuery(query('skuFindOne'),{
		variables:{id:skuId}
	});

	const [createSku]:MutationTuple<MutationFunction, any> =useMutation(mutation('skuCreateOne'),{
		refetchQueries:['skuFindMany']
	});

	const [updateSku]:MutationTuple<MutationFunction, any> =useMutation(mutation('skuUpdateOne'),{
		refetchQueries:['skuFindMany']
	});

	const  formFields:FormField[]=[
		{name:'brand',required:true,default: productId},
		{name:'name', required:true, default:edit && skuData?.skuFindOne.name||''},
		{name:'sku', required:true, default:edit &&  skuData?.skuFindOne.sku||''},
		{name:'description', required:false, default:edit &&  skuData?.skuFindOne.description||''}
	];

	const handleSubmit=async(skuData:any):Promise<void> =>{

		if(edit){
			console.log('updateSku', {skuData,skuPicture});
			await updateSku({
				variables:{_id:skuId,...skuData}
			});
			if(skuPicture) await uploadSkuPicture(skuPicture,skuId);

		}
		else{
			let response:any=await createSku({variables:{...skuData}});
			if(skuPicture) await uploadSkuPicture(skuPicture,response.data.skuCreateOne._id);
		}
		console.log({done:true});
		if(pathname.includes('onboarding')){
			navigate(`/onboarding/brand/${productId}/skus`);
		}
		else{
			navigate(`/products/${productId}`);
		}
	};

	const  {data,errors,loading,...rest}:FormHook=useForm(formFields,handleSubmit);

	const handleChangeSkuPicture = (file:File):void => {
		setSkuPicture(file);
	};
	if (profileLoading||brandLoading||skuLoading)
		return <Spinner/>;
	let skuDefaultPicture:string=skuData.skuFindOne.picture?.url||'';
	return<CreateSkuRender
		nav={!pathname.includes('onboarding')}
		edit={edit}
		product={brandData.brandFindMany[0]}
		profile={profileData.usersCompanyProfileFindOne}
		defaultPicture={skuDefaultPicture}

		productId={productId}
		formData={data}
		submitLoading={loading}
		errors={errors}
		onChangeFile={handleChangeSkuPicture}
		onSubmit={rest.handleSubmitForm}
		registerInput={rest.registerInput}
		registerTextArea={rest.registerTextArea}
	/>;
};
