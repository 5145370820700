import React from 'react';
import {NavItem} from 'interfaces';
import {MenuItemContainer, NavLink} from 'components/VerticalMenu/verticalMenu.styles';
import {GlowTag} from 'styles/styledComponents';

type MenuItemProps = {
    active?: boolean;
    nav:NavItem
};
export const MenuItem = ({active, nav}:MenuItemProps): JSX.Element =>

	<MenuItemContainer $active={active}>
		<div className={'flex align-center'}>
			<div className="w-1halfrem aspect-1-1 flex align-center justify-center">
				{nav.Icon( {color: active? 'white' : '#6F7386', size: 18 })}
			</div>
			<NavLink
				to={nav.path}
				$active={active}
			>{nav.label}</NavLink>
		</div>

		{nav.beta && <GlowTag >BETA</GlowTag>}
	</MenuItemContainer>;
