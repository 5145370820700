import React, {useEffect, useState} from 'react';
import SubtacticAlertRowRender from './SubtacticAlertRow.render';
import {QueryTuple, useLazyQuery} from '@apollo/client';
import {query} from '../../actions/queries';
import {CulturalAlert, TacticI} from '../../interfaces';

interface SubtacticAlertLoadI {
	subtactic? : TacticI
	title? : string
	description? : string
	limit?: number
	hideViewAll?:boolean
	viewAll?: string
	filter?:any
	sort?:string
	withRemainTime?: boolean
}

export default function SubtacticAlertRowLoad({viewAll, subtactic, title, description, limit=4, hideViewAll=false, filter, sort='CREATEDAT_DESC',withRemainTime=false}:SubtacticAlertLoadI):JSX.Element | null {

	const [loading, setLoading] = useState<boolean>(true);
	const [alerts, setAlerts] = useState<CulturalAlert[]>([]);

	// eslint-disable-next-line @typescript-eslint/typedef
	const [queryAlerts, { data }]:QueryTuple<any, any> = useLazyQuery(query('culturalAlertFindMany'));

	useEffect(():void=>{
		if(loading){
			queryAlerts({
				variables:{
					filter: filter || null,
					sort,
					skip:0,
					limit
				},
				fetchPolicy: 'no-cache',
				onCompleted: (data:any):void => {
					setLoading(false);
					setAlerts(data?.culturalAlertFindMany || []);
				}
			});
		}
	},[loading]);


	return (
		alerts.length > 0 ?
			<SubtacticAlertRowRender
				viewAll={viewAll}
				hideViewAll={hideViewAll}
				title={title}
				description={description}
				subtactic={subtactic}
				alerts={alerts}
				withRemainTime={withRemainTime}
			/>
			:
			null
	);

}
