import React from 'react';
import styled from 'styled-components';

import {color} from 'styles/colors';

import {ImageCard} from '../ui';
import {Item} from '../../interfaces';

export interface FeedCardProps {
	feed:Item
}

export const TvFil:any=styled.div`
  display: flex;
  width: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  >p{
	font-size: 12px;
	writing-mode: vertical-rl;
    transform: rotate(180deg);
	margin-bottom: 0.5rem;
  }
	>div{
	  background-color: ${color.get('primary')};
	  width: 9px;
	  aspect-ratio: 1;
	  border-radius: 50%;
	}
`;

const Title:any=styled.h5`
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 1rem;
`;

export const FeedCard =({feed}:FeedCardProps):JSX.Element=>{
	return <div className={'cursor-pointer'}>
		<ImageCard aspectRatio="4/3" img={feed.url} >
			<div className={'flex direction-column justify-between phor-2 pver-2'}>
				<TvFil>
					<p>FILM / TV</p>
					<div/>
				</TvFil>
				<Title >{feed.label}</Title>
			</div>
		</ImageCard>
	</div>;
};