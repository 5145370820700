import React from 'react';
import {Link} from 'react-router-dom';
import {BackIcon, EditProductIcon} from '../icons';
import {Divider} from './ui';

interface BrandNavProps {
	companyName: string
	ProductName: string
	productId: string
}

export const BrandNav= ({companyName,ProductName,productId}:BrandNavProps):JSX.Element=> {

	return <div className={'phor mt-2'}>
		<div className={'flex align-center justify-between'}>
			<Link className={'flex t-white align-center'} to={'/products'}>
				<BackIcon/>
				<p className={'ml f-small'}>Products</p>
			</Link>
			<div className={'flex align-center'}>
				<p className={'mr f-700'}>{companyName} <span className={'f-400'}>{ProductName}</span></p>
				<Link to={`/products/${productId}/edit`}>
					<EditProductIcon/>
				</Link>
			</div>
		</div>
		<Divider/>
	</div>;

};