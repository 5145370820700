import React, {useState} from 'react';
import {AddFavoriteButton, Card} from 'components/index';
import {ImageContainer} from 'styles/styledComponents/containers';
import {BodyText, BoxTitle} from 'stylesOpus/typography';
import {useTalentDetail} from 'hooks';
import {TalentData, TalentDetailData, TAudience} from 'hooks/useTalentDetail';
import {Spinner} from 'components/ui';
import {abbreviateMount} from 'tools/prices';
import {TotalReachContainer} from './featuredTalent.styles';
import {DiscoveryTalentModal} from 'components/complexComponents';
import {BookmarkButton} from 'pages/HotListDetail/hotListDetail.styles';
import { ReactComponent as TrendingUp } from 'styles/icons/trending-up.svg';
import { ReactComponent as UserIcon } from 'styles/icons/user.svg';
import {themeOpus} from 'stylesOpus/theme';
import {InstagramOutlineIcon} from 'icons';
import {AuthorityScores} from 'components/complexComponents';
import {useNavigate} from 'react-router-dom';

type FeaturedTalentProps = {
	talentId: string
};
export const FeaturedTalent = ({talentId}:FeaturedTalentProps):JSX.Element => {
	const [showFullInfo, setShowFullInfo] = useState<boolean>(false);
	const navigate:any = useNavigate();

	const {discoverData,instagramAudience} : TalentDetailData =useTalentDetail({talentId});
	const detailData :TalentData = !discoverData.loading && discoverData.data;
	const audienceData :TAudience = !instagramAudience.loading && instagramAudience.data;
	return (
		<Card
			padding={'1.5rem'}
			$gap={'1.75rem'}
			color={themeOpus.colors.grey_840}
			margin={'0 0 1rem'}
		>
			<section className={'flex w-100 justify-between align-center '}>
				{!discoverData.loading ?
					<div className={'flex col-gap p-half align-center'}>
						<ImageContainer
							$src={detailData?.userObj?.profilePicture?.url}
							$height={'115px'}
							$width={'115px'}
							$borderRadius={'1rem'}
						/>
						<div className="pr-2">
							<BoxTitle>
								<span className="f-700 f-30px">
									{detailData?.userObj?.firstName} {detailData?.userObj?.lastName}
								</span>
							</BoxTitle>
							<p className="mt-half mb-half f-12px f-bold op-60">{detailData?.description}</p>
							<div className={'w-100 flex col-gap-half align-center mt'}>
								<InstagramOutlineIcon size={20} color={'white'} />
								<BodyText>
									<span className="f-14px f-900 f-urbanist t-neutral-250">
										{abbreviateMount(audienceData?.followersCount)}
									</span>
								</BodyText>
							</div>
						</div>
					</div>
					:
					<Spinner/>
				}
				<div className={'flex direction-column row-gap'}>
					<BookmarkButton
						variant={'white'}
						onClick={():void=>navigate(`/talent/${talentId}/opus`)}
					>
						<UserIcon/>
						<BodyText
							fontSize={'0.75rem'}
						>View Profile</BodyText>
					</BookmarkButton>

					<AddFavoriteButton
						label={'Add to Favorites'}
						talentId={talentId}
					/>
				</div>
			</section>
			<section className={'flex w-100 col-gap-2 justify-center'}>

				<TotalReachContainer padding="0 5rem" $grow={'3'}>
					<div className="br-50 w-250px aspect-1-1 neutral-840 absolute bottom-4-n right-4-n" />
					<div className={'flex direction-column justify-center align-center h-100 relative'}>
						<BoxTitle
							lineHeight={'1.2'}
							fontSize={'4.625rem'}
						>
							<span className="f-900">
								{abbreviateMount(audienceData?.followersCount)}
							</span>
						</BoxTitle>
						<BodyText >
							<span className="f-18px f-700 t-neutral-300">
								Connections
							</span>
						</BodyText>
					</div>

				</TotalReachContainer>

				<Card
					borderRadius={'2rem'}
					color={themeOpus.colors.grey_1000}
					$flexGrow={'7'}
					$alignItems={'center'}
					padding={'0.75rem 4rem 1.38rem 4rem'}
					className="box-shadow"
				>
					<div className={' w-100 flex direction-column row-gap'}>
						<div className={'flex justify-between align-center'}>
							<div className={'flex col-gap align-center pver-half'}>
								<TrendingUp/>
								<BoxTitle fontSize={'14px'} >Authority Categories</BoxTitle>
							</div>
						</div>

						<AuthorityScores talentId={talentId} />

					</div>
				</Card>
			</section>
			<DiscoveryTalentModal
				talentId={talentId}
				isVisible={showFullInfo}
				score={10000}
				add={true}
				setShowTalent={setShowFullInfo}
			/>
		</Card>
	);
};
