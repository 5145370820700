import React from 'react';
import {Card} from '../Card';
import {SerpapiVideo} from 'interfaces';
import {VideoThumbnail} from '../ui/VideoThumbnail';

export interface VideoCardRenderProps{
	video:SerpapiVideo
}

export default function VideoCardRender({video}:VideoCardRenderProps):JSX.Element{
	const{thumbnail,title}:SerpapiVideo=video;
	return (
		<Card className={''}>
			<VideoThumbnail src={thumbnail} alt={thumbnail}/>
			<h2 className={'mver-2'}>{title}</h2>
		</Card>
	);
}
