import React from 'react';
import OpportunitiesRender from './OpportunitiesRender';
import {Location, useLocation} from 'react-router-dom';
import {Opportunity, TalentOpportunity} from 'interfaces';
import {QueryResult, useQuery} from '@apollo/client';
import {query} from '../../actions/queries';

export interface OpportunitiesContext {
	talentOpportunities:TalentOpportunity[]
	accepted:Opportunity[]
	rejected:Opportunity[]
}


export const Opportunities=():JSX.Element=>{
	const {pathname}:Location=useLocation();

	const {data,loading}:QueryResult=useQuery(query('opportunityFindMany'),{
		variables:{
			limit : 12,
			skip:0,
			sort: 'CREATEDAT_DESC',
			filter: {
				active:true
			}
		},
		fetchPolicy:'network-only'
	});


	return <OpportunitiesRender
		path={pathname}
		loading={loading}
		talentOpportunities={data?.opportunityFindMany}
	/> ;
};
