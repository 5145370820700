import React, {ChangeEventHandler, MouseEventHandler, Ref, TouchEventHandler} from 'react';
import {ListInput} from 'interfaces';
import {RangeSlider, RangeSelected, RangeInput, LimitValue} from './styles';
import styled from 'styled-components';
import {InputLabel} from 'stylesOpus/typography';

interface DoubleRangeInputRenderProps extends Omit<ListInput, 'options'>{
	leftPercentage:number
	rightPercentage:number

	values:{
		min:number
		max:number
	}

	labels:{
		leftValue:string
		rightValue:string
		minValue:string
		maxValue:string
	}

	range1:Ref<HTMLInputElement>
	range2:Ref<HTMLInputElement>

	actions: {
		onChangeMin:ChangeEventHandler
		onChangeMax:ChangeEventHandler
		onMouseUpLeft:MouseEventHandler
		onMouseUpRight:MouseEventHandler
		onTouchEndRight:TouchEventHandler
		onTouchEndLeft:TouchEventHandler
		showValue:(value:number|string)=>string
	}
}

const StyledLabel:any=styled(InputLabel)`
	display: block;
	padding-bottom: 1rem;
`;

export const DoubleRangeRender =({label,leftPercentage,rightPercentage,range1,range2,values,labels,actions}:DoubleRangeInputRenderProps):JSX.Element=>{
	const {onChangeMin,onChangeMax,onMouseUpLeft,onMouseUpRight,showValue}
		:DoubleRangeInputRenderProps['actions']=actions;

	return <div className='w-100 mb-2'>
		<StyledLabel >{label}</StyledLabel>
		<div className='w-100'>
			<RangeSlider>
				<RangeSelected
					min={leftPercentage}
					max={rightPercentage}
				/>
				{/*<ValueLabelLeft percentage={leftPercentage}>{ labels.leftValue }</ValueLabelLeft>*/}
				{/*<ValueLabelRight percentage={rightPercentage}>{ labels.rightValue }</ValueLabelRight>*/}
			</RangeSlider>
			<RangeInput >
				<input
					name={'left'}
					min={values.min}
					max = {values.max}
					ref={range1}
					type='range'
					onChange={onChangeMin}
					onTouchEnd={actions.onTouchEndLeft}
					onMouseUp={onMouseUpLeft}
				/>
				<input
					name={'right'}
					min={values.min}
					max = {values.max}
					ref={range2}
					type='range'
					onChange={onChangeMax}
					onMouseUp={onMouseUpRight}
					onTouchEnd={actions.onTouchEndRight}
				/>
			</RangeInput>
			<div className={'w-100 flex justify-between'}>
				{/*<LimitValue>{labels.minValue}</LimitValue>*/}
				{/*<LimitValue>{labels.maxValue}</LimitValue>*/}
				<LimitValue>{labels.leftValue}</LimitValue>
				<LimitValue>{labels.rightValue}</LimitValue>
			</div>
		</div>
	</div>;
};