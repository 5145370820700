import React from 'react';
import PrimaryCardRender from './PrimaryCardRender';
import {InstagramOutlineIcon} from 'icons';
import {SocialCardData} from 'interfaces';

interface PrimaryCardProps {
	primary:SocialCardData|null
}

export const PrimaryCard=({primary}:PrimaryCardProps):JSX.Element=>{

	if(!primary)
		return <div className='w-100'>
			<h4 className='center'> This User do not have audience data</h4>
		</div>;
	let followers:number=primary.reach||0;
	let fix:number=0;

	let suffix:string='';
	if((followers/1000)>=1){
		followers = followers/1000;
		suffix='K';
		fix=1;
	}
	if((followers/1000)>=1){
		followers = followers/1000;
		suffix='M';
	}

	return <PrimaryCardRender
		icon={<InstagramOutlineIcon/>}
		label={'Instagram'}
		followers={followers.toFixed(fix)+suffix}
	/>;
};
