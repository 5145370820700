import React from 'react';
import {color} from 'styles/colors';
import {Message, PictureInput} from 'components';
import {FormProps} from 'interfaces';
import {Divider, FormButton, Input, SearchAutoComplete} from 'components/ui';

export interface CompanyProfile extends FormProps{
	edit?:boolean
	defaultImage?:string
}

export const CompanyProfileForm = ({defaultImage,submitLoading,disabled,errors,onSubmit,registerInput,registerList,onChangeFile}:CompanyProfile):JSX.Element=>{

	return (
		<>
			<h2 className="mb-half mt-2">About The Brand</h2>
			<p className="mb-2">Enter your company information below</p>

			<PictureInput
				defaultImage={defaultImage||''}
				label={'Company Logo'}
				rounded='50%'
				accept={'image/jpeg, image/png'}
				plus
				onChange={onChangeFile}
			/>
			<p className="f-small">(JPEG or PNG, 1024 x 1024 recommended)</p>
			<form onSubmit={onSubmit} className={'w-100 mver-2'} autoComplete='off'>
				<Input
					label='COMPANY NAME'
					{...registerInput('name')}
				/>
				<Divider/>
				<SearchAutoComplete
					label={'Categories'}
					placeholder={'type ...'}
					searchName={'interestSearchMany'}
					findOneAction={'interestFindOne'}
					{...registerList('interest')}
				/>
				<div className={'grid2 col-gap'}>
					<Input
						label='Contact Phone NUMBER'
						{...registerInput('phone')}
					/>
					<Input
						label='COMPANY WEBSITE'
						{...registerInput('website')}
					/>
				</div>
				<div className={'w-100 flex justify-center'}>
					<FormButton
						loading={submitLoading}
						disabled={disabled}
						label='CONTINUE'
					/>
				</div>
				{errors?<div className={'mt center'}>
					{errors.get('submitError') && <Message message={errors.get('submitError')} color={color.get('primary')}/>}
				</div>:null}
			</form>
		</>
	);
};
