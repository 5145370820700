import React from 'react';
import styled from 'styled-components';
interface ModalContainerProps {
	show?:boolean
	close?:()=>void
	children:JSX.Element
	centered?:boolean
	maxWidth?:string
}

const ModalWrapper:any=styled.div`
	background-color: rgba(0,0,0,0.8);
`;

export const ModalContainer =({children,show=false, close, centered=true, maxWidth='max-w-800px'}:ModalContainerProps):JSX.Element=>{

	return show && <div className={`z-1000 fixed top-0 left-0 z-30 w-100vw h-100vh ${centered ? 'flex align-center justify-center' : 'overflow-y-scroll pver-2'}`}>
		<ModalWrapper className={'fixed top-0 left-0 relative w-100vw h-100vh z-10'} onClick={close}>
		</ModalWrapper>
		<div className={`relative z-20 w-100 ${maxWidth} mhor-auto`}>
			{children}
		</div>
	</div>;
};