import styled from 'styled-components';

export const StoryWrapper:any=styled.a`
  padding-right: 50px;
 display: flex;
  gap: 1rem;
  color: #fff;
`;

export const Thumbnail:any =styled.img`
  width: 92px;	
  height: 92px;
  object-fit: cover;
`;

export const StoryText:any =styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-transform: capitalize;
  >p:nth-child(2){
	margin: .2rem 0 ;
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
	display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
  }
`;

