import React from 'react';
import {Route} from './interfaces';
import {OpportunityDetail ,Opportunities,OpportunitiesTactic} from 'pages';
import {OpportunityDetailOld,OpportunitiesOld} from 'pages/Old';
import {AuthRoute, Layout} from '../containers';
import {OpportunitiesSubtacticLoad} from '../pages/OpportunitiesSubtactic/OpportunitiesSubtactic.load';
import {OpportunitiesFeaturedLoad} from '../pages/OpportunitiesFeatured/OpportunitiesFeatured.load';

export const opportunitiesRoutes:Route[] =[
	{
		path:'/campaigns',
		element: <AuthRoute><Layout styleTheme={'opusV2'}/></AuthRoute>,
		children:[
			{
				path:'/campaigns',
				element: <OpportunitiesOld/>
			},
			{
				path:'/campaigns/:filter',
				element: <OpportunitiesOld/>
			},
			{
				path: '/campaigns/:id',
				element: <OpportunityDetailOld/>
			}
		]
	},
	{
		path: '/opportunities',
		element: <AuthRoute>
			<Layout styleTheme={'opusV2'}/>
		</AuthRoute>,
		children:[
			{
				path: '',
				element: <Opportunities/>
			},
			{
				path: ':tactic/:id',
				element: <OpportunitiesTactic/>
			},
			{
				path: 'subtactic/:slug',
				element: <OpportunitiesSubtacticLoad />
			},
			{
				path: 'featured/:slug',
				element: <OpportunitiesFeaturedLoad />
			},
			{
				path: ':id',
				element: <OpportunityDetail/>
			}
		]
	}

];
