import {AuthRoute, Layout, Products} from '../containers';
import React from 'react';
import {Route} from './interfaces';
import {CreateProduct, CreateSku, ProductDetail} from '../containers/Products/components';

export const productRoutes:Route[] =[
	{
		path:'/products',
		element:<AuthRoute redirectTo={'/'}><Layout/></AuthRoute>,
		children:[
			{
				path: '',
				element: <Products/>
			},
			{
				path: 'create-product',
				element: <CreateProduct/>
			},
			{
				path: ':productId/edit',
				element: <CreateProduct/>
			},
			{
				path: ':productId/',
				element: <ProductDetail/>
			},
			{
				path: ':productId/create-sku',
				element: <CreateSku/>
			},
			{
				path: ':productId/sku/:skuId',
				element: <CreateSku/>
			}
		]
	}
];