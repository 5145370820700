export const formatDate=(stringDate:string):string=>{
	const date:Date = new Date(stringDate);
	return `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`;
};

export const months:string[]= [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December'
];

export const getRemainDays = (initialDate:Date,finishDate:Date):number=>{
	const oneDay:number = 24 * 60 * 60 * 1000;
	// @ts-ignore
	const miliseconds:number=finishDate- initialDate;
	const days:number = Math.round( miliseconds/oneDay);
	if(days >= 0) {
		return days;
	}
	else{
		return 0;
	}

};


export const getRemainHours = (initialDate:Date,finishDate:Date):number=>{
	const oneDay:number = 24 * 60 * 60 * 1000;
	const oneHour:number = 60 * 60 * 1000;
	const days :number = getRemainDays(initialDate,finishDate);
	// @ts-ignore
	const miliseconds:number=finishDate- initialDate - (oneDay * days);
	const hours:number = Math.round( miliseconds/oneHour);
	if(hours >= 0) {
		return hours;
	}
	else{
		return 0;
	}

	return Math.round( miliseconds/oneHour);

};