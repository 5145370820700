import React, {useContext} from 'react';
import AppleLogin from 'react-apple-login';
import {Button} from '../Button';
import {ReactComponent as AppleIcon} from 'styles/icons/companies/apple.svg';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import {UserContext} from 'context/userContext';
import {SignupWithProviderData} from 'actions/restActions';
import {TUserType, UserType} from 'interfaces';


type AppleButtonProps = {
	signup?:boolean
	type: TUserType
	setLoading?:(loading:boolean)=>void
}

const SignupNextPathByUSerType : Record<TUserType, string> = {
	Agent: '/dashboard',
	Regular: '/dashboard',
	Company: '/dashboard'
};
export const AppleButton = ({type,signup,setLoading}:AppleButtonProps):JSX.Element => {
	const navigate :NavigateFunction = useNavigate();
	const {userSignupApple}:any=useContext(UserContext);
	const appleAuthAction = async(response:any):Promise<void> => {
		if (!response.authorization)
			return;
		setLoading && setLoading(true);
		const signupData:SignupWithProviderData = {
			token: response.authorization.code,
			type: UserType[type]
		};
		console.log({appleResponse:response, type, signupData, UserType});

		await userSignupApple(signupData);
		setLoading && setLoading(false);
		signup && navigate(SignupNextPathByUSerType[type]);
	};
	return (
		<AppleLogin
			clientId="co.opusintel.test"
			redirectURI="https://test.opusintel.co/signup-apple-203"
			usePopup={true}
			callback={appleAuthAction} // Catch the response
			scope="email name"
			responseMode="query"

			render={(renderProps:any):JSX.Element=> (  //Custom Apple Sign in Button
				<Button
					className={'flex justify-center align-center col-gap'}
					width={'100%'}
					onClick={renderProps.onClick}
					variant={'dark-grey'}
				>
					<AppleIcon/> Sign up with Apple
				</Button>
			)}
		/>
	);
};