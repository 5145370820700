import React from 'react';
import {Tag} from '../../../../stylesOpus/ui';
import {Flex} from '../../../../stylesOpus/layout';

interface BrandProps {
	name: string
}

interface BrandsProps {
	brands : BrandProps[]
}

export default function TalentBrandsRender({brands}:BrandsProps):JSX.Element {
	return (
		<Flex className='mt-2' wrap='wrap'>
			{brands?.slice(0,10).map(({name}:BrandProps):JSX.Element=><Tag key={name} margin='0 12px 12px 0'>{name}</Tag>)}
		</Flex>
	);
}