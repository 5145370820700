import styled from 'styled-components';
import {mediaQuery} from '../../../styles/mediaQueries';

export const DoughnutWrapper:any=styled.div`
	width: 200px;
	${mediaQuery('laptop')}{
	  width: 110px;
	}${mediaQuery('mobile')}{
	  width: 132px;
	}
`;