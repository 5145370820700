import React from 'react';
import SocialRender from './SocialRender';
import {useOutletContext} from 'react-router-dom';
import {Spinner} from 'components/ui';

export const Social=():JSX.Element=>{
	const {socialCards}:any=useOutletContext();
	if(socialCards.loading)
		return <div className={'w-100'}>
			<Spinner/>
		</div>;

	if (!socialCards.data || socialCards.error)
		return  <div className={'w-100'}>
			<p>Something went wrong</p>
		</div>;
	const {instagram,tiktok,twitter,facebook}:any=socialCards.data;
	return <SocialRender
		instagram={instagram}
		tiktok={tiktok}
		twitter={twitter}
		facebook={facebook}
		primary={instagram||tiktok}
	/>;
};
