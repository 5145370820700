import React, {useEffect, useState} from 'react';
import AutoFillInput from '../form/AutoFillInput';
import {QueryResult, useQuery} from '@apollo/client';
import {query} from '../../../../actions/queries';
import {IMatch, IMatchAudience, IOption} from '../../DiscoverInterfaces';
import {MultiSelectTag} from '../styledComponents';
import {CloseIcon} from '../../../../icons';
import {useRecoilState} from 'recoil';
import {matchQueryAtom} from '../../../../context/recoil/store';
import AudienceStateRender from './AudienceState.render';

export default function AudienceState():JSX.Element {

	const [options, setOptions] = useState<IOption[]>([]);
	const [optionsFilter, setOptionsFilter] = useState<IOption[]>([]);

	const {data,loading,error,networkStatus}:QueryResult=useQuery(query('stateFindMany'), {
		variables:{
			filter: {},
			limit: 500,
			skip: 0,
			sort: '_ID_ASC'
		},
		fetchPolicy: 'network-only'
	});

	useEffect(():void=> {
		if(data?.stateFindMany?.length > 0 ){
			let c:IOption[] = data.stateFindMany.map((e:any):IOption => {
				return {
					_id: e._id,
					value:e.label.toLowerCase(),
					label: e.label
				};
			});
			c.sort((a:any, b:any):any =>  a.label.localeCompare(b.label));
			setOptions(c);
			setOptionsFilter(c);
		}
	}, [data]);

	return (
		<div>
			<AudienceStateRender options={options} optionsFilter={optionsFilter} setOptionsFilter={setOptionsFilter} />
		</div>
	);
}