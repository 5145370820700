import {compareStrings} from './comparation';
import {CountryCodeItem, countryCodes} from '../components/AudienceByCountry/countryCodes';
import {v4 as uuidv4} from 'uuid';

export const enumToMap =(enumObject:Record<string, string>):Map<string,string|number> =>{
	const enumMap: Map<string,string> = new Map();
	Object.entries(enumObject).forEach(([key, value]:any):void=> {
		enumMap.set(key, value);
	});
	return enumMap;
};

export const sortListBy=(array:any[],sortBy:string):any[]=>{
	if (array.length<1 || !array[0][sortBy] ){
		return array;
	}
	return array.sort((prev:any,current:any):number=>compareStrings(prev[sortBy],current[sortBy]));
};


export const getFlag = (country:string):string => {
	const find:any = countryCodes.find((e:CountryCodeItem):any => e.name === country);
	return find?.code || country;
};

// create keys
export const uniqueId = (text:string,index:number): string=>`${text}_${uuidv4()}_${index}`;