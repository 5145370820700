import styled from 'styled-components';
import {color} from 'styles/colors';
import {themeOpus} from '../../../stylesOpus/theme';

export const RangeSlider:any = styled.div`
  height: 8px;
  position: relative;
  background-color: #13141B;
  border-radius: 2px;
`;

export const LimitValue:any=styled.p`
	color: #FFF;
	font-family: 'Urbanist', font-family;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 137.5%; /* 22px */
`;

export const RangeInput:any = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0.6rem;
  >input{
    position: absolute;
    width: 100%;
    height: 5px;
    top: -6px;
    background: none;
    pointer-events: none;
    -webkit-appearance: none;
    -moz-appearance: none;
	
	::-webkit-slider-thumb{
      height: 16px;
      width: 16px;
      border-radius: 100%;
      background-color:  white;
      pointer-events: auto;
      -webkit-appearance: none;
	  pointer-events: auto;
	}
	:active::-webkit-slider-thumb{
	  background-color: ${themeOpus.colors.light_grey_200};
	}
    ::-moz-range-thumb {
      height: 20px;
      width: 6px;
      border-radius: 4px;
      background-color: ${color.get('neutral-300')};
      pointer-events: auto;
      -webkit-appearance: none;
	  pointer-events: auto;
    }
	:active::-moz-range-thumb{
      background-color: ${color.get('primary')};
    }
  }
`;

export const RangeSelected:any = styled.div.attrs(({min,max}:any):any=>({
	style: {
		left: `${min}%`,
		right: `${100-max}%`
	}
}))`
  height: 100%;
  position: absolute;
  border-radius: 5px;
  background-color: ${color.get('primary')};
`;

export const ValueLabelLeft:any = styled.span.attrs(({percentage}:any):any=>({
	style:{
		left: `${percentage}%`
	}
}))`
  position: absolute;
	color: #FFF;
	font-family: Urbanist;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 137.5%;
	;
  top: 17px;
  
`;

export const ValueLabelRight:any = styled(ValueLabelLeft)`
  transform: translateX(-100%);
`;


