import React, {useContext, useEffect, useState} from 'react';
import {BookmarkLineIcon} from '../../../icons/BookmarkLineIcon';
import {BodyText} from '../../../stylesOpus/typography';
import {BookmarkButton} from '../hotListDetail.styles';
import {deleteHotlist, likedHotlist, likeHotlist} from '../../../actions/strapiActions';
import { UserContext } from '../../../context/userContext';
import {Params, useParams} from 'react-router-dom';
import {BookmarkFilledIcon} from '../../../icons/BookmarkFilledIcon';

export default function BookmarkHotlist():JSX.Element {

	const {item}:Params=useParams();
	const {user}:any = useContext(UserContext);
	const [bookmark, setBookMark] = useState<boolean>(false);
	const [bookmarkId, setBookMarkId] = useState<null | number>();
	const [loading, setLoading] = useState<boolean>(true);
	const [saving, setSaving] = useState<boolean>(false);

	useEffect(():void=>{
		if(loading){
			getBookmark();
		}
	},[loading]);

	const getBookmark = async():Promise<any> => {
		if(user?._id){
			const my:any = await likedHotlist({
				'filters[user][0]':user._id,
				'filters[hotlist][1]': item
			});
			if(my?.data?.data?.length > 0){
				setBookMark(true);
			}
		}
		setLoading(false);
	};

	const saveBookmark = async():Promise<any> => {
		setSaving(true);
		if(user?._id){
			if(!bookmark) {
				setBookMark(true);
				await likeHotlist({
					user:user._id,
					hotlist: item
				});
				setSaving(false);
				setLoading(true);
			}
			else{
				setBookMark(false);
				setBookMarkId(null);
				const my:any = await likedHotlist({
					'filters[user][0]':user._id,
					'filters[hotlist][1]': item
				});
				if(my?.data?.data?.length > 0){
					await deleteHotlist({
						id: my?.data?.data[0]?.id
					});
				}
				setSaving(false);
				setLoading(true);
			}
		}
	};

	return (
		<BookmarkButton className={'flex col-gap'} onClick={():any=>loading ? null : saving ? null : saveBookmark()}>
			{bookmark ? <BookmarkFilledIcon /> : <BookmarkLineIcon />}
			<BodyText
				fontSize={'0.75rem'}
				color={'#fff'}
			>Favorite</BodyText>
		</BookmarkButton>
	);
}
