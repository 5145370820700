import React from 'react';
import {ImageWrapper,RoundImage} from './styles';

export interface RoundImageProps{
	size?:string;
	src:string;
	alt?:string;
	className?:string;
	rounded?:string
	sources?:PictureSource[];
}

interface PictureSource{
	srcSet:string;
	media:string;
}

export default function RoundImageRender({
	size='10rem',
	src,
	alt='round image',
	className,
	sources,
	rounded
}:RoundImageProps):JSX.Element{
	return (
		<ImageWrapper size={size} className={className} rounded={rounded}>
			<picture>
				{sources?.map(({...props}:PictureSource,id:number):JSX.Element=>
					<source key={id} {...props}  />
				)}
				<RoundImage
					src={src}
					alt={alt}
				/>
			</picture>
		</ImageWrapper>
	);
}
