import React, {useContext, useEffect, useState} from 'react';
import {NavigateFunction, Params, useNavigate, useParams} from 'react-router-dom';
import {UserContext, userContextInterface} from 'context/userContext';
import {MutationFunction, MutationTuple, QueryResult, useMutation, useQuery} from '@apollo/client';
import {query} from 'actions/queries';
import {mutation} from 'actions/mutations';
import {FormField, FormHook} from 'hooks/useForm/interfaces';
import {uploadBrandPicture} from 'actions/restActions';
import {useForm} from 'hooks/useForm/useForm';
import {CreateProductForm} from 'components';
import {Brand} from 'interfaces';

type brandActionArgs={
	picture?:File,brandData:Brand
};

export const CreateProduct =():JSX.Element=>{
	const {productId}:Params=useParams();
	const navigate:NavigateFunction=useNavigate();
	const {user}:userContextInterface =useContext(UserContext);
	const {data:brandData,loading:brandLoading}:QueryResult=useQuery(query('brandFindMany'),{
		fetchPolicy:'network-only',
		variables:{findBrandFilter:{_id:productId||''}}
	});
	const [brandPicture,setBrandPicture]=useState<File|null>(null);
	const [createBrand]:MutationTuple<MutationFunction, any> =useMutation(mutation('brandCreateOne'));
	const [updateBrand]:MutationTuple<MutationFunction, any> =useMutation(mutation('brandUpdateById'));

	const handleChangeBrandPicture = async(file:File):Promise<void> => {
		setBrandPicture(file);
	};

	const [brandDefaultData,setBrandData]=useState<Brand|null>(null);

	useEffect(():void=>{
		setBrandData(():Brand=>brandData?.brandFindMany[0]||null);
	},[brandData]);

	const  formFields:FormField[]=[
		{name:'name', required:true, default:brandDefaultData?.name||''},
		{name:'interest', required:true, default:brandDefaultData?.interest||[]},
		{name:'description', required:false, default:brandDefaultData?.description||''},
		{name:'company', required:true, default:user._id}
	];

	const createBrandActionHandler=async({picture,brandData}:brandActionArgs):Promise<void> =>{

		let response:any=await createBrand({
			variables:{...brandData},
			refetchQueries:['brandFindMany']
		});
		let brandId:string=response?.data?.brandCreateOne?._id;

		(brandId && picture) && await uploadBrandPicture(brandPicture,brandId);
		navigate(`/products/${brandId}`);
	};

	const upDateBrandHandler= async({picture,brandData}:brandActionArgs):Promise<void> =>{
		await updateBrand({
			variables:{id:productId,record:{...brandData}}
			,refetchQueries:['brandFindMany']
		});
		picture && await uploadBrandPicture(brandPicture,productId);
		navigate(`/products/${productId}`);
	};

	const submitAction=async(brandData:any):Promise<void> =>{

		if(productId){
			upDateBrandHandler({picture:brandPicture,brandData});
		}
		else {
			createBrandActionHandler({picture:brandPicture,brandData});
		}

	};

	const  {hasChanges,errors,loading,...rest}:FormHook=useForm(formFields,submitAction,true);

	if(brandLoading)
		return <h1>Loading</h1>;

	let defaultPicture:string=brandData?.brandFindMany[0]?.picture?.url;
	console.log({brandData,brandDefaultData});
	return <>
		<CreateProductForm
			edit={!!productId}
			defaultPicture={defaultPicture}
			submitLoading={loading}
			errors={errors}
			onChangeFile={handleChangeBrandPicture}
			disabled={!(hasChanges || brandPicture) || !!errors.size }
			onSubmit={rest.handleSubmitForm}
			registerInput={rest.registerInput}
			registerList={rest.registerList}
			registerTextArea={rest.registerTextArea}
		/>
	</>;
};