import React, {FC, useEffect} from 'react';
import {useState} from 'react';
import {PictureInput} from '../PictureInput';
import {TPicture} from 'interfaces';
import {EmptyImage, RoundImage} from '../ui';
import { v4 as uuidv4 } from 'uuid';
type PictureArrayInputProps={
	min:number
	max:number
    onChange?:(files:File[])=>void
	defaultImages?:TPicture[]
}

const uniqueIdForInput = (index:number): string=>`input_${uuidv4()}_${index}`;
export const PictureArrayInput: FC<PictureArrayInputProps> = ({onChange, min, max=5, defaultImages=[]}: PictureArrayInputProps): JSX.Element => {
	const [firstRender, setFirstRender] =useState(true);
	const [pictures, setPictures] = useState<File[]>( []);
	const numOfInputs : number =  max - defaultImages.length -pictures.length;
	let inputs : React.ReactNode[] =Array.from({ length: numOfInputs });

	const handlePictureChange = (file: File, index:number): void => {
		if(file){
			const newPictures: File[] = [...pictures];
			newPictures.push(file);
			setPictures(newPictures);
			inputs.splice(index, 1);
		}
	};
	useEffect(():void => {
		setFirstRender(false);
		if(!firstRender) {
			onChange && !firstRender && onChange(pictures);
		}
	}, [pictures]);

	const arraySize : number = pictures.length < max ? defaultImages.length : max;

	return(
		<div className={'flex col-gap '}>
			{defaultImages.map((p:TPicture, index:number):React.ReactNode => (
				<RoundImage
					key={index}
					rounded="20%"
					size="75px"
					src={p.url}
				/>
			))}
			{pictures.map((f:File, index:number):React.ReactNode => (
				<RoundImage
					key={index}
					rounded="00%"
					size="75px"
					src={URL.createObjectURL(f)}
				/>
			))}
			{inputs.map((_:any, index:number):React.ReactNode => (
				<PictureInput
					key={uniqueIdForInput(index)}
					// rounded="20%"
					label={''}
					plus
					size="75px"
					disabled={index < defaultImages.length}
					accept={'.png, .jpg'}
					onChange={(file: File): void => handlePictureChange(file, index)}
				/>
			))}
		</div>
	);
};


