import React from 'react';
import {TalentDetailData, useTalentDetail} from 'hooks/useTalentDetail';

interface DiscoveryTalentHOCProps {
	talentId: string,
    children: (detailData: TalentDetailData) => React.ReactNode;
}
export const WithTalentDetailData =({talentId,children}:DiscoveryTalentHOCProps):any=>{
	const talentDetailData : TalentDetailData =useTalentDetail({talentId});

	if (talentDetailData.discoverData.loading && talentDetailData.instagramAudience.loading) {return;}
	return children(talentDetailData);
};
