import React from 'react';
import {Item, SelectProps} from 'interfaces';
import {Select} from '../Select';
import {Tags} from '../Tags';
import styled from 'styled-components';

export interface DropDownRenderProps{
	selectProps:SelectProps
	selectedItems:Item[]
	theme?:'opus'|'opusV2'
	handleClickTag:(item:Item)=>void
}

const DropDown:any=styled(Select)`
	margin-bottom: 0.4rem;
`;

export default function DropDownRender({theme,selectProps,selectedItems,handleClickTag}:DropDownRenderProps):JSX.Element{
	return <div className="mb">
		<DropDown
			theme={theme}
			defaultValue={''}
			{...selectProps}
		/>
		{selectedItems.length>0&&<Tags
			selected
			onClickTag={handleClickTag}
			tags={selectedItems}
		/>}
	</div>
	;
}
