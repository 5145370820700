import React from 'react';

export const InvalidIcon=():JSX.Element=>
	<svg
		width={14}
		height={14}
		viewBox="0 0 14 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M13 13L7 7L13 1"
			stroke="#FF5656"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M1 13L7 7L1 1"
			stroke="#FF5656"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
;
