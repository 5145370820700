import React from 'react';
import {Item} from 'interfaces';
import {Pill, PillContainer} from './styles';

export interface PillTabsRenderProps {
	options:Item[]
	currentOption:string
	handleOnClick:(value:string)=>void
}

export const PillTabsRender = ({options,currentOption,handleOnClick}:PillTabsRenderProps):JSX.Element=>{
	return <PillContainer>
		{options.map((option:Item):JSX.Element=>
			<Pill
				active={currentOption===option.id}
				key={option.id}
				onClick={():void=>handleOnClick(option.id)}
			>
				{option.label}
			</Pill>
		)}
	</PillContainer>;
};