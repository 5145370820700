import React from 'react';
import {IconProps} from 'interfaces';

export const ScoreGraphIcon=({color='white',size=32}:IconProps):JSX.Element=>
	<svg
		width={size}
		height={size*( 24/32 )}
		viewBox="0 0 32 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx={17} cy={4} r="3.25" stroke={color} strokeWidth="1.5" />
		<circle cx="12.5" cy="19.5" r="3.75" stroke={color} strokeWidth="1.5" />
		<circle cx={28} cy={16} r="3.25" stroke={color} strokeWidth="1.5" />
		<circle cx={4} cy={10} r="3.25" stroke={color} strokeWidth="1.5" />
		<path d="M16 8L14 16" stroke={color} />
		<path d="M25.0593 16.7635L15.914 19.3785" stroke={color} />
		<path d="M5.5554 12.1954L10.7914 16.7788" stroke={color} />
	</svg>

;
