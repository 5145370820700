import React from 'react';
import {TopStories,TopVideos} from './styles';
import {PressCard, VideoCard} from 'components';
import {Story} from './components';
import {SerpapiStory, SerpapiVideo} from 'interfaces';
import {Divider} from '../Divider';

interface HighlightsRenderProps {
	topStories:SerpapiStory[]|null
	topVideos:SerpapiVideo[]|null
}

export default function HighlightsRender({topStories,topVideos}:HighlightsRenderProps):JSX.Element{

	return (
		<div className={'w-100'}>
			{topStories&&<TopStories>
				<h6>Top Stories</h6>
				<div>
					{topStories.map((s: SerpapiStory): JSX.Element =>
						<Story
							key={s.link}
							story={s}
						/>)}
				</div>
			</TopStories>}

			<Divider/>
			{topVideos&&<TopVideos className="grid mt-2">
				<h6>Top Videos</h6>
				<div>
					{topVideos.map((v: SerpapiVideo): JSX.Element =>
						<VideoCard
							key={v.link}
							video={v}
						/>)}
				</div>
			</TopVideos>}

			{/*<Divider/>*/}
			{/*<div className='grid mt-2'>*/}
			{/*	<h6 className={'mb'}>Recent Press</h6>*/}
			{/*	<PressCard className={'mb'}/>*/}
			{/*	<PressCard/>*/}
			{/*</div>*/}
		</div>
	);
}
