import React from 'react';
import {IconProps} from 'interfaces';

export const BrandsHexagonIcon=({color='white'}:IconProps):JSX.Element=>
	<svg
		width={24}
		height={24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M21 15.9979V7.99795C20.9996 7.64722 20.9071 7.30276 20.7315 6.99911C20.556 6.69546 20.3037 6.44331 20 6.26795L13 2.26795C12.696 2.09241 12.3511 2 12 2C11.6489 2 11.304 2.09241 11 2.26795L4 6.26795C3.69626 6.44331 3.44398 6.69546 3.26846 6.99911C3.09294 7.30276 3.00036 7.64722 3 7.99795V15.9979C3.00036 16.3487 3.09294 16.6931 3.26846 16.9968C3.44398 17.3004 3.69626 17.5526 4 17.7279L11 21.7279C11.304 21.9035 11.6489 21.9959 12 21.9959C12.3511 21.9959 12.696 21.9035 13 21.7279L20 17.7279C20.3037 17.5526 20.556 17.3004 20.7315 16.9968C20.9071 16.6931 20.9996 16.3487 21 15.9979Z"
			stroke={color}
			strokeWidth="1.8"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M15.6015 13.6003V10.3997C15.6014 10.2594 15.5643 10.1215 15.4941 10.0001C15.4239 9.87857 15.3229 9.77769 15.2014 9.70753L12.4008 8.1072C12.2792 8.03697 12.1412 8 12.0008 8C11.8603 8 11.7223 8.03697 11.6007 8.1072L8.80011 9.70753C8.67859 9.77769 8.57765 9.87857 8.50743 10.0001C8.43721 10.1215 8.40017 10.2594 8.40002 10.3997V13.6003C8.40017 13.7406 8.43721 13.8785 8.50743 13.9999C8.57765 14.1214 8.67859 14.2223 8.80011 14.2925L11.6007 15.8928C11.7223 15.963 11.8603 16 12.0008 16C12.1412 16 12.2792 15.963 12.4008 15.8928L15.2014 14.2925C15.3229 14.2223 15.4239 14.1214 15.4941 13.9999C15.5643 13.8785 15.6014 13.7406 15.6015 13.6003Z"
			fill={color}
		/>
	</svg>
;
