import React from 'react';
import {Route} from './interfaces';
import {AuthRoute, Layout} from '../containers';
import TalentDetailLoad from '../pages/TalentDetail/TalentDetail.load';
import TalentOpusRender from '../pages/TalentDetail/Opus/TalentOpus.render';
import TalentFeedRender from '../pages/TalentDetail/Feed/TalentFeed.render';
import TalentMediaRender from '../pages/TalentDetail/Media/TalentMedia.render';
import TalentOpusLoad from '../pages/TalentDetail/Opus/TalentOpus.load';
import TalentFeedLoad from '../pages/TalentDetail/Feed/TalentFeed.load';
import TalentMediaLoad from '../pages/TalentDetail/Media/TalentMedia.load';


export const talentRoutes:Route[] =[
	{
		path: '/talent',
		element: <AuthRoute><Layout styleTheme={'opusV2'}/></AuthRoute>,
		children: [
			{
				path: ':id',
				element: <TalentDetailLoad />,
				children: [
					{
						path: 'opus',
						element: <TalentOpusLoad />
					},
					{
						path: 'feed',
						element: <TalentFeedLoad />
					},
					{
						path: 'media',
						element: <TalentMediaLoad />
					}
				]
			}
		]
	}

];
