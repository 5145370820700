import React, {useContext} from 'react';
import styled from 'styled-components';
import {Opportunity} from 'interfaces';
import {ImageCard} from '../ui';
import {Link} from 'react-router-dom';
import {UserContext} from 'context/userContext';



interface OpportunityCardProps {
	opportunity:Opportunity
}

const Text:any=styled.div`
	font-size: 1rem;
	font-family: sans-serif, Urbanist;
	color: white;
	font-style: normal;
	font-weight: 900;
	line-height: normal;
	letter-spacing: 1.6px;
	>h6{
		font-family: 'Urbanist', sans-serif;
		font-size: 1rem;
	}
	>p{
		font-family: 'Lato', sans-serif;
		font-size: 0.875rem;
		color: #545766;
		font-weight: 500;
		letter-spacing: 1.4px;
	}
`;

export const TalentOpportunityCard=({opportunity}:OpportunityCardProps):JSX.Element=>{

	const {user}:any =useContext(UserContext);

	return <div className={'mt'}>
		<Link to={user?.type?.label === 'Company' ? `/opportunity/${opportunity?._id}` :`/opportunities/deals/${opportunity?._id}/brief` }>
			<ImageCard img={ opportunity.image && opportunity.image[0]?.url }/>
			<div className={'flex justify-between'}>
				<Text className={'mt-half'}>
					<h6 className={'mb-quarter'}>{opportunity?.name}</h6>
					<p className={'f-small f-uppercase t-red f-700'}>{opportunity?.companyProfile?.name}</p>
				</Text>

			</div>
		</Link>

	</div>;
};