
import {Item} from '../interfaces';
import {Dispatch, SetStateAction, useEffect, useState} from 'react';

type ChangeEventList ={
	name:string
	value:string[]
}
export interface listHandlerProps {
	name:string
	value:string[]
	options:Item[]
	onChange:(event:ChangeEventList)=>void
}

export interface listHandler {
	handleSelected:(item:Item)=>void
	handleUnselected:(item:Item)=>void
	getSelectedItems:()=>Item[]
	resetSelected: ()=> void
	setSelectedItems: Dispatch<SetStateAction<Item[]>>
}

const stringToItem=(value:string,items:Item[]):Item=>{
	if (!items) return { id:value, label: '---' };
	let item: Item | undefined = items.find((i:Item):boolean=>i.id===value);
	return item || { id:value, label: '---' };
};

export const useListHandler=({name,value=[],options=[],onChange}:listHandlerProps):listHandler=>{

	if ( !Array.isArray(value)){
		value =[value];
	}

	const onChangeHandler=({name,value:list}:ChangeEventList):void=>{
		if (onChange){

			onChange({name,value:list.filter((id:string):boolean=>id!=='')});
		}
	};
	const getSelectedItems=():Item[]=>
		value?.map((v: string): Item => stringToItem(v, options));
		
	const [selectedItems,setSelectedItems]=useState<Item[]>(():Item[] => getSelectedItems());
	useEffect(():void => {
		const defaultSelected: Item[] = getSelectedItems();
		setSelectedItems(defaultSelected);
	}, []);

	const handleSelected=(item:Item):void=>{
		if(!value.includes(item.id)){
			let newSelected : Item[] =  [...selectedItems, item];

			onChangeHandler({
				name,
				value: [...selectedItems, item].map((i: Item): string => i.id)
			});
			setSelectedItems(newSelected);
		}
	};
	const handleUnselected=(item:Item):void=>{
		// let values:string[]=value.filter((v:string):boolean=>v!==item.id);
		onChangeHandler({
			name,
			value: selectedItems.filter((i:Item):boolean=>i.id!==item.id).map((i: Item): string => i.id)
		});
		setSelectedItems(selectedItems.filter((i:Item):boolean=>i.id!==item.id));
	};

	const resetSelected=():void=>{
		setSelectedItems([]);
		//onChangeHandler({name,value:[]});
	};

	const handleSetSelect=(items:Item[]):void=>{
		setSelectedItems(items);

		onChangeHandler({
			name,
			value: items.map((i: Item): string => i.id)
		});
	};
	return {
		handleSelected,
		handleUnselected,
		getSelectedItems: ():Item[]=>selectedItems,
		resetSelected,
		setSelectedItems : handleSetSelect
	};
};

