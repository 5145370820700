import React, {useEffect, useState} from 'react';
import {OpportunitiesTacticRender} from './OpportunitiesTacticRender';
import {QueryTuple, useLazyQuery} from '@apollo/client';
import {query} from 'actions/queries';
import {TacticI} from 'interfaces';
import {SetterOrUpdater, useSetRecoilState} from 'recoil';
import {BreadCrumbAtom, TCrumb} from 'context/recoil/store';
import {Params, useParams} from 'react-router-dom';

export const OpportunitiesTactic = ():JSX.Element => {
	const setBreadCrumb: SetterOrUpdater<TCrumb[]>=useSetRecoilState(BreadCrumbAtom);
	const {tactic}:Params = useParams();
	const [loading, setLoading] = useState<boolean>(true);
	const [tactics, setTactics] = useState<TacticI>();
	const [subtactics, setSubtactics] = useState<TacticI[]>();
	const [loadingSub, setLoadingSub] = useState<boolean>(true);

	// eslint-disable-next-line @typescript-eslint/typedef
	const [queryTactics, { data }]:QueryTuple<any, any> = useLazyQuery(query('tacticsFindMany'));

	// eslint-disable-next-line @typescript-eslint/typedef
	const [querySubtactics, { data:datasub }]:QueryTuple<any, any> = useLazyQuery(query('subtacticFindMany'));

	useEffect(():void=>{
		if(loading){
			queryTactics({
				variables: {
					filter: {
						slug : tactic,
						active: true
					}
				},
				fetchPolicy: 'no-cache',
				onCompleted: (data:any):void => {
					setLoading(false);
					setTactics(data?.tacticFindMany[0]);
					querySubtactics({
						variables: {
							filter: {
								active: true
							}
						},
						fetchPolicy: 'no-cache',
						onCompleted: (data:any):void => {
							setLoadingSub(false);
							setSubtactics(data?.subtacticFindMany || []);
						}
					});
				}
			});
		}
	},[loading]);

	useEffect(():void => {
		setBreadCrumb([
			{id: 'opportunities', label: 'opportunities', path:'/opportunities'},
			{id: 'tactic', label: tactics?.title || '', path:`/opportunities/${tactic}/1`}
		]);
	}, [tactics]);


	return (
		<OpportunitiesTacticRender
			tactic={tactics}
			subtactics={subtactics}
		/>
	);
};
