import React from 'react';
import {FileInput, Input, TextBox} from 'components/ui';

interface MusicProps {
	registerInput:any
	registerTextArea:any
	fileHandlers?:{
		deck?:(file:File)=>void
		videoTreatment?:(file:File)=>void
	}
}

export const Music = ({registerInput,registerTextArea,fileHandlers}:MusicProps):JSX.Element => {
	return <>
		<h4 className={'mb'}>Additional media</h4>

		<div className={'mt-2 grid2 col-gap'}>

			<div>
				<FileInput
					label={'add artist one sheet / video treatment'}
					helpText={'Upload Document (PDF or JPG)'}
					onChange={fileHandlers?.deck}
					accept={'.pdf, .jpg'}
				/>
				<Input
					label={'Add song Tittle'}
					{...registerInput('title')}
				/>
			</div>

			<div>
				<FileInput
					label={'Audio Stream'}
					helpText={'Upload Audio Stream'}
					accept={'.pdf, .jpg'}
					onChange={fileHandlers?.videoTreatment}
				/>
				<Input
					label={'Song Url'}
					{...registerInput('videoSongUrl')}
				/>
			</div>
		</div>
		<TextBox
			className={'grows'}
			label={'Bio'}
			maxLength={2000}
			{...registerTextArea('description')}
		/>
	</>;
};