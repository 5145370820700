import React, {useContext, useEffect} from 'react';
import InsightsRender from './InsightsRender';
import {QueryResult, useQuery} from '@apollo/client';
import {query} from '../../actions/queries';

import {UserContext} from '../../context/userContext';

export const Insights=():JSX.Element=>{

	const {user}:any=useContext(UserContext);
	const {data, loading, error}:QueryResult=useQuery(query('insightByUserFindOne'),{
		variables:{}
	});

	const {data:d, loading:l, error:e}:QueryResult=useQuery(query('instagramAudienceFindOne'),{
		variables: {
			filter: {user: user._id}
		}
	});



	useEffect(():any=> {
		console.log('IA', d);
	}, [d]);


	if (!data) {
		return <p>Loading...</p>;
	}
	else {
		return <InsightsRender
			engagement_rate={data?.insightByUserFindOne?.engagement_rate}
			engagements={data?.insightByUserFindOne?.engagements}
			avg_likes={data?.insightByUserFindOne?.avg_likes}
			avg_reels_plays={data?.insightByUserFindOne?.avg_reels_plays}
			avg_comments={data?.insightByUserFindOne?.avg_comments}
			avg_views={data?.insightByUserFindOne?.avg_views}
			top_hashtags={data?.insightByUserFindOne?.top_hashtags}
			top_mentions={data?.insightByUserFindOne?.top_mentions}
			stat_history={data?.insightByUserFindOne?.stat_history}
			audience={{
				audienceBrand:d?.instagramAudienceFindOne?.audienceBrand,
				audienceInterests: d?.instagramAudienceFindOne?.audienceInterests,
				audienceGenderAge: d?.instagramAudienceFindOne?.audienceGenderAge
			}}
		/>;
	}

};