import React from 'react';
import {TacticI} from 'interfaces';
import SubtacticAlertRowLoad from '../../components/SubtacticAlertRow/SubtacticAlertRow.load';
import {UserContext, userContextInterface} from '../../context/userContext';

type OpportunitiesTacticRenderProps = {
	subtactic?: TacticI
};

export default function OpportunitiesSubtacticRender({subtactic}:OpportunitiesTacticRenderProps):JSX.Element {

	const {user} : userContextInterface = React.useContext(UserContext);

	return (
		<div>
			{subtactic &&
				<SubtacticAlertRowLoad
					hideViewAll={true}
					subtactic={subtactic}
					limit={40}
					filter={{
						active:true,
						subtactic:[subtactic?._id],
						ENDDATE_GREATERTHAN: new Date().toISOString(),
						_operators:{tenant:{in:user.tenantList}}
					}}
				/>
			}
		</div>
	);
}
