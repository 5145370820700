import React from 'react';
import {Container} from './styles';
import {PillTabs} from 'components';

export interface ProfileEditRenderProps {
  tab: string;
  setTab: (tab: string) => void;
  children: JSX.Element;
}

export const SecurityRender = ({children, tab, setTab}: ProfileEditRenderProps): JSX.Element => {

	return (
		<>
			<Container>
				<PillTabs
					options={[
						{id:'email',label:'Email'},
						{id:'password',label:'Password'},
						{id:'account',label:'Account'}
					]}
					value={tab}
					setValue={setTab}
				/>

				{children}
			</Container>
		</>
	);
};
