import React from 'react';
import { HelpRender } from './HelpRender';

export const Help = ():JSX.Element => {
	const optionsHelpCenter: any = [
		{
			id:'3',
			title:'Privacy Policy'
		},
		{
			id:'6',
			title:'Terms Of Use & Conditions'
		}
	];
	return (
		<HelpRender options={optionsHelpCenter} />
	);
};
