import styled from 'styled-components';
import {themeOpus} from 'stylesOpus/theme';

export const StyledDigit:any=styled.input`
  background-color: ${themeOpus.colors.grey_550};
  color: white;
  border: none;
  border-radius: 1.5rem;
  aspect-ratio: 1 / 1;
  font-family: 'Urbanist', sans-serif;
  text-align: center;
  font-weight: 700;
  font-size: 4.5rem;
  line-height: 27px;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;
`;
