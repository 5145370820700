import React, {useState} from 'react';
import ForgotPasswordRender from './ForgotPasswordRender';
import {FormField, FormHook} from 'hooks/useForm/interfaces';
import {useForm} from 'hooks/useForm/useForm';
import {MutationTuple, useMutation} from '@apollo/client';
import {mutation} from 'actions/mutations';

export const ForgotPassword=():JSX.Element=>{
	const [message, setMessage] =useState<string>('');
	const [forgotPassword]:MutationTuple<any, any>=useMutation(mutation('userForgotPassword'));
	const  formFields:FormField[]=[
		{name:'email', required:true,default:'', pattern: /^\S+@\S+\.\S+$/, watch:true}
	];

	const handleForgotPassword=async(data:any):Promise<void> =>{
		await forgotPassword({
			variables:{email:data.email}
		});
		setMessage('Please check out your email for instructions');
	};

	const  {data,errors,loading,...rest}:FormHook=useForm(formFields,handleForgotPassword);

	return <ForgotPasswordRender
		formData={data}
		message={message}
		submitLoading={loading}
		errors={errors}

		registerInput={rest.registerInput}
		onSubmit={rest.handleSubmitForm}
	/>;
};
