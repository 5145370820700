import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {DoughnutWrapper} from './styles';

export interface DonutRenderProps{
	options:any
	data:any,
	className?:string
}

export const DoughnutRender= ({data,options,className}:DonutRenderProps):JSX.Element=>{
	return<DoughnutWrapper className={className}>
		<Doughnut options={options} data={data} />
	</DoughnutWrapper>
	;
};