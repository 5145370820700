import React from 'react';

interface IconProps {
	width?:number
	height?:number
}

export const NotificationIcon=({width=16, height=16}:IconProps):JSX.Element=>{
	return <svg
		width={20}
		height={20}
		viewBox="0 0 16 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.18885 17.0807C6.58629 16.964 7.00306 17.1916 7.11973 17.5891C7.23469 17.9807 7.59401 18.2497 8.00216 18.2497C8.4103 18.2497 8.76962 17.9807 8.88459 17.5891C9.00126 17.1916 9.41803 16.964 9.81547 17.0807C10.2129 17.1974 10.4405 17.6141 10.3239 18.0116C10.0214 19.042 9.076 19.7497 8.00216 19.7497C6.92831 19.7497 5.98294 19.042 5.68046 18.0116C5.56379 17.6141 5.7914 17.1974 6.18885 17.0807Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8 0.25C8.41421 0.25 8.75 0.585786 8.75 1V2.80007C8.75 3.21428 8.41421 3.55007 8 3.55007C7.58579 3.55007 7.25 3.21428 7.25 2.80007V1C7.25 0.585786 7.58579 0.25 8 0.25Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.00008 3.55035C5.10046 3.55035 2.74986 5.90096 2.74986 8.80058C2.74986 11.3926 2.44303 13.1158 2.11917 14.2133C2.07233 14.372 2.02514 14.5176 1.97847 14.6508H13.9554C13.932 14.5842 13.9086 14.5144 13.8855 14.4414C13.5546 13.3989 13.2503 11.6665 13.2503 8.80058C13.2503 5.90096 10.8997 3.55035 8.00008 3.55035ZM15.709 14.8497C15.7003 14.8393 15.6786 14.8119 15.6472 14.7622C15.5742 14.6463 15.4492 14.4099 15.3152 13.9877C15.046 13.1394 14.7503 11.5717 14.7503 8.80058C14.7503 5.07253 11.7281 2.05035 8.00008 2.05035C4.27203 2.05035 1.24986 5.07253 1.24986 8.80058C1.24986 11.276 0.956663 12.8529 0.680501 13.7887C0.542498 14.2563 0.408734 14.564 0.317779 14.7442C0.272276 14.8343 0.237353 14.8928 0.21776 14.9238C0.208864 14.9378 0.203102 14.9462 0.200915 14.9494C0.0314202 15.1742 0.00192282 15.4755 0.125553 15.7293C0.251185 15.9872 0.512931 16.1508 0.799811 16.1508H15.2004C15.5186 16.1508 15.8023 15.9499 15.9079 15.6497C16.0077 15.3657 15.9278 15.0515 15.709 14.8497Z"
			fill="white"
		/>
	</svg>;
};