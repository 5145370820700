import React from 'react';
import {OpusLogo} from '../../icons';
import {LogoutButton} from '../../components/ui';
import {OnboardingHeader} from '../../containers/OnBoarding/styles';

export const TermsOfUse = ():JSX.Element =>{

	return(
		<div className={'w-100 flex phor-2 direction-column align-center justify-center'}>
			<div className="max-w-940px p-3">
				<div className="w-100 flex justify-center pver-4">
					<a href="/">
						<OpusLogo size={120}/>
					</a>
				</div>
				<h1>Terms of Use</h1>
				<p>Last Modified: May 22, 2023</p>
				<h2>Welcome to Opus</h2>
				<p>
					Welcome to the Opus website, owned and operated by Opus Intelligence Labs,
					Inc. and any of its subsidiaries or affiliates (“<strong>Company</strong>,”
					“<strong>we</strong>,” “<strong>us</strong>” or “<strong>our</strong>”).
					These Terms of Use (“T<strong>erms of Use</strong>”) govern your use of the
					website located at <a>www.opusintel.co</a> (the “Site”) and all related
					products, services, tools, mobile applications, web applications, and any
					other technology platforms or tools located at any Company websites,
					including without limitation, successor website(s) or application(s) thereto
					(collectively, the “Services”). The terms “<strong>you</strong>,” “
					<strong>your</strong>” or “<strong>user</strong>” refers to you, the user.
					If you are using the Services on behalf of a business, association, or other
					entity, “you” or “your” will also refer to such business, association, or
					other entity, unless the context clearly dictates otherwise. You agree that
					you are authorized to consent to these terms on behalf of such business,
					association, or other entity, and we can rely on this. If you are using the
					Services on behalf of another individual (e.g., management/agent for social
					media talent), you represent and warrant that you are an authorized agent on
					behalf of the individual, and you have been authorized to consent to these
					terms on behalf of such individual. &nbsp;
				</p>
				<h2>Important Notices</h2>
				<p>
					PLEASE READ THESE TERMS OF USE CAREFULLY, AS THEY CONTAIN AN AGREEMENT TO
					ARBITRATE AND OTHER IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS,
					REMEDIES AND OBLIGATIONS. THE AGREEMENT TO ARBITRATE REQUIRES (WITH LIMITED
					EXCEPTION) THAT YOU SUBMIT CLAIMS YOU HAVE AGAINST US TO BINDING AND FINAL
					ARBITRATION, AND FURTHER (a) YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS
					AGAINST COMPANY ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER
					IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING, (b) YOU WILL ONLY BE
					PERMITTED TO SEEK RELIEF (INCLUDING MONETARY, INJUNCTIVE AND DECLARATORY
					RELIEF) ON AN INDIVIDUAL BASIS, AND (c) YOU MAY NOT BE ABLE TO HAVE ANY
					CLAIMS YOU HAVE AGAINST US RESOLVED BY A JURY OR IN A COURT OF LAW.
				</p>
				<h2>These Terms Set Forth a Legally Binding Agreement</h2>
				<p>
					Please read these Terms of Use very carefully before accessing or using our
					Services. By using/ continuing to use our Services, you acknowledge you have
					read and understand and agree to be bound by the Terms of Use, including
					those additional terms and conditions and policies referenced herein and/or
					available by hyperlink. Please print a copy of this agreement for your
					records. If you do not agree to all the terms and conditions of these Terms
					of Use, then you may not access or use any Services. If these Terms of Use
					are considered an offer, acceptance is expressly limited to these Terms of
					Use.
				</p>
				<h2>Eligibility to Use the Services</h2>
				<p>
					To use the Services you must be, and represent and warrant that you are, at
					least the age of majority in your state, province or jurisdiction of
					residence, or if you are under the age of majority in your state, province,
					or jurisdiction of residence, you represent and warrant that your parent or
					legal guardian has reviewed these Terms of Use with you and accepts them on
					your behalf; parents or legal guardians are responsible for the activities
					of their minor dependents while using the Services. You warrant and
					represent that you (a) have all necessary rights, power, and authority to
					agree to these Terms of Use and perform your obligations hereunder, and (b)
					nothing contained in these Terms of Use or in the performance of such
					obligations will place you in breach of any other contract or obligation.
				</p>
				<h2>Privacy Policy</h2>
				<p>
					Please refer to our Privacy Policy for information about how we collect,
					use, store and disclose your personal information.&nbsp;
				</p>
				<ol>
					<li>
						<h3>The Services</h3>
						<ol style={{ listStyleType: 'upper-alpha' }}>
							<li>
								<p>
									Services. Images of items on the Services are examples only and may
									not be identical to the product and/or service you receive from
									Company. Differences may be due to your device’s display of colors
									or other factors such as the natural variation in each item. We
									reserve the right, but are not obligated, to limit the sales of our
									Services to any person, geographic region, or jurisdiction. We may
									exercise this right on a case-by-case basis, in our sole discretion.
									We reserve the right to limit the quantities of any Services that we
									offer. All descriptions of Services are subject to change at any
									time without notice, at the sole discretion of us. We reserve the
									right to discontinue any Service at any time. Any offer for any
									Service is void where prohibited.
								</p>
							</li>
							<li>
								<p>
									Intellectual Property Ownership. All content on the Services
									(including, for example, text, designs, graphics, logos, icons,
									images, audio clips, downloads, interfaces, information, code and
									software, and the selection and manner of compilation and
									presentation) (collectively, the “Content”), is owned by Company,
									our content providers, or our licensors (as applicable), and may be
									protected by copyright, trademark, and other applicable laws.
									<br />
									<br />
									Your access to and use of the Services does not grant you any
									license or right to use any copyrighted materials or any trademark,
									logo, or service mark displayed on the Services. Company, our
									content providers, or our licensors (as applicable) retain full and
									complete title to and reserve all rights in the material on the
									Services, including all associated intellectual property rights.
									Company neither warrants nor represents that your use of materials
									on the Services will not infringe rights of third parties.
									<br />
									<br />
									{`You may access the Services only for your permitted use under these
									Terms of Use, and you may not modify or delete any copyright,
									trademark, or other proprietary notice relating to any material you
									access. You agree not to display or use in any manner the Company
									marks without Company's advance written permission.`}
									<br />
									<br />
									All software used on the Services is the property of Company or our
									licensors and protected by United States and international copyright
									laws, and subject to separate license terms, in which case those
									license terms will govern such software. You agree not to reproduce,
									duplicate, copy, sell, resell, or exploit any portion of the
									Services, use of the Services, or access to the Services or any
									contact on the website through which the Services are provided,
									without express written permission by us.
									<br />
									<br />
									All rights not expressly granted herein are reserved by Company, our
									affiliates, and licensors. You agree to abide by all additional
									restrictions displayed on the Services as they may be updated from
									time to time.
								</p>
							</li>
							<li>
								<p>
									Feedback. By sending us any feedback, comments, questions, ideas,
									proposals, or suggestions concerning Company or any of our Services
									whether online, by email, by postal mail, or otherwise
									(collectively, “Feedback”), you represent and warrant (i) that you
									have the right to disclose the Feedback, (ii) that the Feedback does
									not violate the rights of any other person or entity, including, but
									not limited to, intellectual property rights, such as infringing a
									copyright, trademark, or patent; violating a right of privacy,
									attribution or withdrawal; or otherwise misappropriating a trade
									secret, and (iii) that your Feedback does not contain the
									confidential or proprietary information of any third party or
									parties. By sending us any Feedback, you further (1) agree that we
									are under no obligation of confidentiality, express or implied, with
									respect to the Feedback, (2) acknowledge that we may have something
									similar to the Feedback already under consideration or in
									development, and (3) grant us an irrevocable, non-exclusive,
									royalty-free, perpetual, worldwide license, under all intellectual
									property rights, to use, make, have made, incorporate into our
									Services, modify, copy, display, perform, distribute, prepare
									derivative works, publish, distribute, and sublicense the Feedback,
									without any credit or compensation to you. This Feedback section
									shall survive any termination of your account or any aspect of the
									Services.
								</p>
							</li>
							<li>
								<p>
									Third-Party Websites. Certain content, products, and services
									available via the Services may include materials from third-parties
									or provide you with access to third-party tools, products, and
									resources over which we neither monitor nor have any control nor
									input. &nbsp;Further, third-party links on our Site may direct you
									to third-party websites that are not affiliated with us. We are not
									responsible for examining or evaluating the content or accuracy of
									any third-party materials or websites, or for any other materials,
									products, or services of third-parties. The views expressed in
									third-party materials, websites, resources, products, or services
									are those of such third-party, and do not necessarily reflect our
									views.
									<br />
									<br />
									You acknowledge and agree that we provide access to such materials,
									products, websites, tools, and resources “as is” and “as available”
									without any warranties, representations, or conditions of any kind
									and without any endorsement. We do not warrant and will not have any
									liability or responsibility arising from or relating to third-party
									materials, websites, tools, products, and resources. Any use by you
									of third-party materials, tools, products, services, and resources
									offered through the Services are entirely at your own risk and
									discretion and you should ensure that you are familiar with and
									approve of the terms on which such items are provided by the
									relevant third-party provider(s).
									<br />
									<br />
									{`We are not liable for any harm or damages related to the purchase or
									use of goods, services, resources, content, or any other
									transactions made in connection with any third-party websites.
									Please review carefully the third-party's policies and practices and
									make sure you understand them before you engage in any transaction.
									You may not use third-party content without that third-party’s
									permission, or as otherwise allowed by law. Complaints, claims,
									concerns, or questions regarding third-party products or services
									should be directed to the applicable third-party.`}
								</p>
							</li>
							<li>
								<p>
									Errors, Inaccuracies and Omissions. Occasionally there may be
									information on the Services that contains typographical errors,
									inaccuracies, or omissions that may relate to Services’
									descriptions, pricing, promotions, offers, Services shipping
									charges, transit times, and availability. We reserve the right,
									without prior notice, to (i) correct any errors, inaccuracies, or
									omissions, and (ii) change or update information or cancel orders,
									if any information in the Services or on any related website is
									inaccurate at any time (including after you have submitted your
									order).
									<br />
									<br />
									We undertake no obligation to update, amend, or clarify information
									in the Services or on any related website, including without
									limitation, pricing information, except as required by law. No
									specified update or refresh date applied in the Services or on any
									related website, should be taken to indicate that all information in
									the Services or on any related website has been modified or
									updated.&nbsp;
								</p>
							</li>
						</ol>
					</li>
					<li>
						<h2>User Account and Security&nbsp;</h2>
						<ol style={{ listStyleType: 'upper-alpha' }}>
							<li>
								<p>
									<strong>
										Agreement to Provide Accurate Registration Information.
									</strong>{' '}
									If you wish to participate in certain aspects of the Services, you
									may need to register for an account on the Services (“Account”). By
									creating an Account, you agree to (i) provide accurate, current, and
									complete registration information about yourself, (ii) maintain and
									promptly update as necessary your Account information, (iii)
									maintain the security of your password and accept all risks of
									unauthorized access to your Account and the information you provide
									us, and (iv) immediately notify us if you discover or otherwise
									suspect any security breaches related to the Services or your
									Account.
									<br />
									<br />
									By creating an Account, you consent to receive electronic
									communications from Company (e.g., via email). These communications
									may include notices about your Account (e.g., password changes and
									other transactional information) and are part of your relationship
									with us. You agree that any notices, agreements, disclosures, or
									other communications that we send to you electronically will satisfy
									any legal communication requirements, including, but not limited to,
									that such communications be in writing. We may also send you
									promotional communications via email, including, but not limited to,
									newsletters, special offers, surveys, and other news and information
									we think will be of interest to you. You may opt out of receiving
									these promotional emails at any time by following the unsubscribe
									instructions provided therein.&nbsp;
								</p>
							</li>
							<li>
								<p>
									<strong>
										User is Responsible for Equipment and Software to Connect to
										Services.
									</strong>{' '}
									You must provide all equipment and software necessary to connect to
									the Services. You are solely responsible for any fees, including
									Internet connection or mobile fees, that you incur when accessing
									the Services.&nbsp;
								</p>
							</li>
							<li>
								<p>
									<strong>User Submission of Personal Information.</strong> By
									submitting personal information through our Services, you agree to
									the terms of our Privacy Policy and you expressly consent to the
									collection, use and disclosure of your personal information in
									accordance with the Privacy Policy.&nbsp;
								</p>
							</li>
							<li>
								<p>
									<strong>Company May Suspend Accounts.</strong> Company reserves the
									right to terminate, suspend, or restrict your access to any
									Account(s) if (i) we discover you have created multiple Accounts for
									the same user, or (ii) we suspect that the Account(s) have been or
									will be used for any illegal, fraudulent, or otherwise unauthorized
									purposes. Under no circumstances shall Company or other persons be
									responsible or liable for any direct, indirect, consequential, or
									other losses (including lost revenue, lost profits, lost business
									opportunities, loss of good will, or reputational harm), damages, or
									costs suffered by you or any other person or entity due to any such
									termination, suspension, or restriction of access to any
									Account(s).&nbsp;
								</p>
							</li>
							<li>
								<p>
									<strong>User May Not Use the Services for Illegal Activity.</strong>{' '}
									You agree to use the Services only for purposes that are legal,
									proper, and in accordance with these Terms of Use and any applicable
									laws or regulations. Without limitation, you may not, and may not
									allow any third-party to: (i) undertake any unlawful activity which
									would violate, or assist in violation of, any law, statute,
									ordinance, or regulation, sanctions program administered in any
									relevant country; (ii) impersonate another person (via the use of an
									email address or otherwise); (iii) upload, post, transmit, or
									otherwise make available through the Services any content that
									infringes the intellectual or proprietary rights of any party; (iv)
									operate to defraud Company, other users, or any other person; (v)
									provide false, inaccurate, or misleading information; (vi) use the
									Services to violate the legal rights (such as rights of privacy and
									publicity) of others; (vii) engage in, promote, or encourage illegal
									activity (including, without limitation, tax evasion or money
									laundering); (viii) harvest or otherwise collect information from
									the Services about others, including without limitation email
									addresses, without proper consent; (ix) exploit the Services for any
									unauthorized commercial purpose; (x) modify, adapt, translate, or
									reverse engineer any portion of the Services; (xi) remove any
									copyright, trademark, or other proprietary rights notices contained
									in or on the Services or any part of it; (x) use any robot, spider,
									site search/retrieval application, or other device to retrieve or
									index any portion of the Services or the content posted on the
									Services, or to collect information about its users for any
									unauthorized purpose; (xi) create user accounts by automated means,
									or under false or fraudulent pretenses; or (xii) access or use the
									Services for the purpose of creating a product or service that is
									competitive with any of our products or Services.
								</p>
							</li>
						</ol>
					</li>
					<li>
						<h2>Payment Services; Fees&nbsp;</h2>
						<ol style={{ listStyleType: 'upper-alpha' }}>
							<li>
								<p>
									<strong>Fees</strong>. Company offers certain enhanced features of
									the Services which you can purchase as a subscription
									(“Subscription”) for monthly or yearly durations (“Subscription
									Term”). A description of features associated with Subscriptions is
									available in the Services. &nbsp;
								</p>
							</li>
							<li>
								<p>
									<strong>Payment Processors.</strong> Company uses third-party
									providers to securely store your payment card information and
									process your payments (“Payment Processors”). When you purchase a
									Subscription or otherwise make an order through the Services (a
									“Transaction”), you expressly authorize our Payment Processors to
									charge you for each Transaction. &nbsp;Our Payment Processors may
									ask you to supply additional information relevant to your
									Transaction, such as your credit card number, the expiration date of
									your credit card, and your address(es) for billing (such
									information, “Payment Information”). You will provide all Payment
									Information directly to our Payment Processors. You represent and
									warrant that you have the legal right to use all payment method(s)
									represented by any such Payment Information. The amounts due and
									payable by you for a Transaction will be presented to you before you
									place your order. &nbsp;If you choose to initiate a Transaction via
									the Services, you agree (i) to pay the applicable fees and any
									taxes; (ii) that our Payment Processor may charge your credit card
									or third-party payment processing account, for verification,
									pre-authorization and payment purposes; and (iii) to bear any
									additional charges that your bank or other financial service
									provider may levy on you as well as any taxes or fees that may apply
									to your order. You’ll receive a confirmation email after we confirm
									the payment for your order. Your order is not binding on Company
									until accepted and confirmed by Company. All payments made are
									non-refundable and non-transferable except as expressly provided in
									this Agreement. By initiating a Transaction, you agree to the
									pricing, payment, and billing policies applicable to such fees and
									charges, as posted or otherwise communicated to you. All fees and
									applicable taxes, if any, are payable in United States dollars.
								</p>
							</li>
							<li>
								<p>
									<strong>Fee Disputes.&nbsp;</strong>If you have any concerns or
									objections regarding charges, you agree to raise them with us first
									and you agree not to cancel or reject any credit card or third-party
									payment processing charges unless you have made a reasonable attempt
									at resolving the matter directly with Company.
								</p>
							</li>
							<li>
								<p>
									<strong>Cancelling Payment and Chargebacks.</strong> You may only
									cancel a Transaction pursuant the policies as communicated to you by
									us or by our Payment Processors. We may institute a chargeback
									policy as we deem appropriate in the event that you or your bank
									does not honor a payment obligation or if our Payment Processors
									question our ability to collect funds from you. As part of such
									chargeback policy, we may in our sole discretion suspend, terminate,
									or otherwise limit your ability to use the Services or otherwise
									take any action we or our Payment Processors deem necessary.&nbsp;
								</p>
							</li>
							<li>
								<p>
									<strong>Changes to Price Terms for Subscriptions.</strong> Company
									reserves the right to change its pricing terms for Subscriptions at
									any time, in which case Company will notify you in advance of such
									changes becoming effective. Changes to the pricing terms will not
									apply retroactively and will only apply for Subscription renewals
									after such changed pricing terms have been communicated to you
									and/or the general public. If you do not agree with the changes to
									Company’s pricing, you may choose not to renew your Subscription in
									accordance with the section “How to Cancel Your Subscription.”
								</p>
							</li>
							<li>
								<p>
									<strong>How to Cancel Your Subscription.</strong> All amounts are
									payable and charged at the beginning of the Subscription and,
									because each such Subscription renews automatically for an
									additional period equal in length to the expiring Subscription Term
									until you cancel it, at the time of each renewal until you cancel,
									using the Payment Information you have provided. You must cancel
									your monthly or yearly Subscription before it renews to avoid the
									billing of the fees for the next Subscription period. You will not
									receive a refund for the fees you already paid for your current
									Subscription period and you will continue to receive the Services
									ordered until the end of your current Subscription period.
								</p>
							</li>
							<li>
								<p>
									<strong>Future Functionality.</strong> You agree that your purchases
									are not contingent on the delivery of any future functionality or
									features, or dependent on any oral or written public comments made
									by Company regarding future functionality or features.
								</p>
							</li>
						</ol>
					</li>
					<li>
						<h2>DMCA</h2>
					</li>
				</ol>
				<p>
					Company respects the intellectual property rights of others. It is our
					policy to respond promptly to any claim that Content infringes the copyright
					or other intellectual property rights of any person. Company will use
					reasonable efforts to investigate notices of alleged infringement and will
					take appropriate action the Digital Millennium Copyright Act (“
					<strong>DMCA</strong>”) and these Terms of Use. If you believe that your
					copyrighted work is infringed by Content, please provide a written DMCA
					notice to Company at: <a>legalnotices@opusintel.co</a>&nbsp;
				</p>
				<p>
					<br />
				</p>
				<ol style={{ listStyleType: 'upper-alpha' }}>
					<li>
						<p>
							Filing a DMCA “Take Down” Notification. If you are a copyright owner or
							an agent thereof and believe that any Content infringes upon your
							copyrights, you may submit a take-down notification (“Take-Down
							Notification”) pursuant to the DMCA by providing us with the following
							information in writing (see 17 U.S.C. § 512 for further detail):
						</p>
					</li>
				</ol>
				<p>
					<br />
				</p>
				<ol style={{ listStyleType: 'lower-roman' }}>
					<ol style={{ listStyleType: 'lower-roman' }}>
						<li>
							<p>
								A physical or electronic signature of a person authorized to act on
								behalf of the owner of an exclusive right that is allegedly infringed;
							</p>
						</li>
						<li>
							<p>
								Identification of the copyrighted work claimed to have been infringed,
								or, if multiple copyrighted works, a representative list of such works
								at that website;
							</p>
						</li>
						<li>
							<p>
								Identification of the material that is claimed to be infringing or to
								be the subject of infringing activity and that is to be removed or
								access to which is to be disabled, and information reasonably
								sufficient to permit us to locate the material; **Providing URLs in
								the body of your DMCA notification is the best way to help us locate
								content quickly**
							</p>
						</li>
						<li>
							<p>
								Information reasonably sufficient to permit us to contact you (the
								complaining party), such as an address, telephone number, and
								electronic mail address at which you (the complaining party) may be
								contacted;
							</p>
						</li>
						<li>
							<p>
								A statement that you (the complaining party) have a good faith belief
								that use of the material in the manner complained of is not authorized
								by the copyright owner, its agent, or the law;
							</p>
						</li>
						<li>
							<p>
								A statement that the information in the notification is accurate, and
								under penalty of perjury, that you (the complaining party) are
								authorized to act on behalf of the owner of an exclusive right that is
								allegedly infringed; and
							</p>
						</li>
						<li>
							<p>
								**(Optional) Provide information, if possible, sufficient to permit us
								to notify the user(s) who posted the content that allegedly contains
								infringing material.
							</p>
						</li>
					</ol>
				</ol>
				<p>
					<em>
						Any person who knowingly materially misrepresents that content or an
						activity is infringing or that any material or activity was removed or
						disabled by mistake or misidentification, shall be liable to us and
						possibly others for any damages, including costs and attorneys’ fees
						incurred by us in removing or disabling access to the material or activity
						claimed to be infringing or in replacing the removed material or enabling
						access to it.
					</em>
				</p>
				<h2>5. Indemnification</h2>
				<p>
					You agree to indemnify, defend and hold harmless Company and its
					subsidiaries, affiliates, partners, officers, directors, agents,
					contractors, licensors, service providers, subcontractors, suppliers,
					interns, and employees, harmless from and against any and all losses,
					claims, damages, judgments, demands, actions, proceedings, investigations
					(whether formal or informal), or expenses (including reasonable attorneys’
					fees), or threats thereof, due to, arising out of or relating to (a) your
					breach of these Terms of Use or the documents they incorporate by reference,
					(b) your violation of (i) any law or (ii) the rights of a third-party, (c)
					your use of the Services, or (d) your fraud or misrepresentation, including,
					but not limited to, misrepresentation of an authorization as agent of
					talent.
				</p>
				<p>
					<br />
				</p>
				<p>
					In the event of such a claim, suit, or action, we will attempt to provide
					you notice of the claim, suit, or action at the contact information we have
					for your account/on file (provided, that failure to deliver such notice
					shall not eliminate or reduce your indemnification obligations hereunder).
					Company reserves the right, at its own cost, to assume the exclusive defense
					and control of any matter otherwise subject to indemnification by you, in
					which event you will fully cooperate with Company in asserting any available
					defenses. You agree that the provisions in this section will survive any
					termination of your account, these Terms of Use, or your access to the
					Services, including the purchase or use of any benefits through the
					Services.
				</p>
				<h2>6. Disclaimers and Limitations on Our Liability</h2>
				<p>
					TO THE EXTENT PERMITTED BY APPLICABLE LAW, COMPANY AND ITS OFFICERS,
					EMPLOYEES, DIRECTORS, SHAREHOLDERS, PARENTS, SUBSIDIARIES, AFFILIATES,
					AGENTS, AND LICENSORS DISCLAIM ALL WARRANTIES, CONDITIONS, AND
					REPRESENTATIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY, OR
					OTHERWISE, INCLUDING, WITHOUT LIMITATION, THOSE RELATED TO MERCHANTABILITY,
					FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, AND THOSE ARISING OUT OF
					COURSE OF DEALING OR USAGE OF TRADE.
				</p>
				<ol style={{ listStyleType: 'upper-alpha' }}>
					<li>
						<p>
							<strong>
								Company Makes No Representations or Warranties; Disclaimer.&nbsp;
							</strong>
							THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE.” TO THE EXTENT
							PERMITTED BY APPLICABLE LAW, COMPANY AND ITS AFFILIATES MAKE NO
							REPRESENTATIONS OR WARRANTIES ABOUT THE ACCURACY OR COMPLETENESS OF
							CONTENT AVAILABLE ON OR THROUGH THE SERVICES, OR THE CONTENT OF ANY
							THIRD-PARTY WEBSITES OR SERVICES LINKED TO OR INTEGRATED WITH OUR
							SERVICES. WE DO NOT REPRESENT OR WARRANT THAT (i) YOUR USE OF OUR
							SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (ii) ANY
							ERRORS IN THE SERVICE WILL BE CORRECTED, (iii) THE QUALITY OF THE
							SERVICES, INFORMATION OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU
							WILL MEET YOUR EXPECTATIONS, (iv) THE SERVICES WILL BE FREE OF ANY WORMS
							OR VIRUSES OR ANY CODE OF A MALICIOUS AND/ OR DESTRUCTIVE NATURE, OR (v)
							THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE WILL BE
							ACCURATE OR RELIABLE. YOU (AND NOT COMPANY) ASSUME THE ENTIRE COST OF
							ALL NECESSARY SERVICING, REPAIR, OR CORRECTION.
							<br />
							<br />
							COMPANY AND ITS AFFILIATES WILL HAVE NO LIABILITY FOR ANY: (i) ERRORS,
							MISTAKES OR INACCURACIES OF CONTENT; (ii) PERSONAL INJURY OR PROPERTY
							DAMAGE RESULTING FROM YOUR ACCESS TO OR USE OF THE SERVICES OR
							CONSUMPTION OF ANY CONTENT; (iii) ANY UNAUTHORIZED ACCESS TO OR USE OF
							OUR SERVERS OR OF ANY PERSONAL INFORMATION OR USER DATA; (iv) ANY
							INTERRUPTION OF TRANSMISSION TO OR FROM THE SERVICES; (v) ANY BUGS,
							VIRUSES, TROJAN HORSES OR THE LIKE WHICH MAY BE TRANSMITTED ON OR
							THROUGH THE SERVICES; (vi) ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A
							RESULT OF THE USE OF ANY CONTENT POSTED OR SHARED THROUGH THE SERVICES;
							OR (vii) LOSS OR DAMAGED CAUSED BY ANOTHER USER’S VIOLATION OF THESE
							TERMS OF USE.
						</p>
					</li>
					<li>
						<p>
							<strong>Limitation on Liability.</strong> TO THE FULLEST EXTENT
							PERMITTED BY LAW, IN NO EVENT WILL COMPANY AND ITS AFFILIATES, OFFICERS,
							DIRECTORS, AFFILIATES, AGENTS, CONTRACTORS, REPRESENTATIVES, INTERNS,
							SUPPLIERS, SERVICE PROVIDERS, OR LICENSORS BE RESPONSIBLE FOR ANY LOSS
							INCLUDING, WITHOUT LIMITATION, LOST PROFITS, REVENUES, OR FINANCIAL
							LOSSES, OR ANY INDIRECT, SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE
							DAMAGES ARISING FROM THESE TERMS OF USE OR THE SERVICES, OR FOR ANY
							DAMAGES RELATED TO THE LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF
							BUSINESS, LOSS OF USE, LOSS OF GOODWILL OR LOSS OF DATA, AND WHETHER
							CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE,
							EVEN IF FORESEEABLE AND EVEN IF COMPANY HAS BEEN ADVISED OF THE
							POSSIBILITY OF SUCH DAMAGES.
							<br />
							<br />
							NOTWITHSTANDING ANYTHING TO THE CONTRARY HEREIN, IN NO EVENT SHALL THE
							MAXIMUM TOTAL LIABILITY OF COMPANY AND ITS AFFILIATES, FOR ANY CLAIMS
							ARISING OUT OF OR IN ANY WAY RELATED TO THESE TERMS OF USE OR THE ACCESS
							TO AND USE OF THE SERVICES, EXCEED THE GREATER OF (i) $100 OR (ii) TO
							THE TOTAL AMOUNT YOU PAID TO COMPANY IN SUBSCRIPTION FEES OVER THE SIX
							(6) MONTHS IMMEDIATELY PRECEDING THE CLAIM.
							<br />
							<br />
							Some jurisdictions do not allow the exclusion of certain warranties and
							limitations of liability provided in this Section. If you are in such a
							jurisdiction, some of the above limitations and disclaimers may not
							apply to you. To the extent we may not, as a matter of applicable law,
							disclaim any implied warranty or limit our liabilities, the scope and
							duration of such warranty and the extent of our liability will be the
							minimum permitted by applicable law.
						</p>
						<p>
							<br />
						</p>
					</li>
				</ol>
				<h2>&nbsp;7. Communications</h2>
				<p>
					Company may communicate with you using email, phone calls, chatbots and text
					messages, including autodialed or prerecorded calls and text messages, at
					any email address or telephone number that you provide us, to: (a) notify
					you regarding your Account; (b) provide customer support; (c) troubleshoot
					problems with your Account; (d) resolve a dispute; (e) collect a debt; (f)
					poll your opinions through surveys or questionnaires; or (g) as otherwise
					necessary to service your account or enforce these Terms of Use, our
					policies, applicable law, or any other agreement we may have with you.
				</p>
				<p>
					If you would like to receive mobile alerts, you may sign up to do so. By
					signing up, you provide your consent to receive mobile alerts from or on
					behalf of us. You understand that consent is not a condition of purchase.
					Data rates may apply. If you would like to be removed from the Company
					marketing text list, you must opt-out via your Account settings.
					<br />
					<br />
					YOU AGREE THAT YOU SHALL INDEMNIFY, DEFEND, AND HOLD US HARMLESS FROM ANY
					CLAIM OR LIABILITY RESULTING FROM YOUR FAILURE TO NOTIFY US OF A CHANGE IN
					THE INFORMATION YOU HAVE PROVIDED, INCLUDING ANY CLAIM OR LIABILITY UNDER
					THE TELEPHONE CONSUMER PROTECTION ACT, 47 U.S.C. § 227, et seq., OR SIMILAR
					STATE AND FEDERAL LAWS, AND ANY REGULATIONS PROMULGATED THEREUNDER RESULTING
					FROM US ATTEMPTING TO CONTACT YOU AT THE MOBILE TELEPHONE NUMBER YOU
					PROVIDED.
				</p>
				<p>
					<br />
					Company may route phone and text communications through a third-party
					service provider, and we or the service provider may record telephone
					conversations or chatbot exchanges you have with Company or its agents for
					quality control and training purposes, or for our own protection.
				</p>
				<h2>8. Dispute Resolution</h2>
				<ol style={{ listStyleType: 'upper-alpha' }}>
					<li>
						<p>
							<strong>Mandatory Arbitration of Disputes.</strong> We each agree that
							any dispute, claim, or controversy arising out of or relating to these
							Terms of Use or the breach, termination, enforcement, interpretation or
							validity thereof or the use of the Services (collectively, “Disputes”)
							will be resolved solely by binding, individual arbitration and not in a
							class, representative or consolidated action or proceeding. You and
							Company agree that the U.S. Federal Arbitration Act governs the
							interpretation and enforcement of these Terms of Use, and that you and
							Company are each waiving the right to a trial by jury or to participate
							in a class action. This arbitration provision shall survive termination
							of these Terms of Use.&nbsp;
						</p>
					</li>
					<li>
						<p>
							<strong>Exceptions</strong>. As limited exceptions to Section 8.a.
							above: (i) we both may seek to resolve a Dispute in small claims court
							if it qualifies; and (ii) we each retain the right to seek injunctive or
							other equitable relief from a court to prevent (or enjoin) the
							infringement or misappropriation of our respective intellectual property
							rights.&nbsp;
						</p>
					</li>
					<li>
						<p>
							<strong>Conducting Arbitration and Arbitration Rules</strong>. The
							arbitration will be conducted by the American Arbitration Association
							(“AAA”) under its Consumer Arbitration Rules (the “AAA Rules”) then in
							effect, except as modified by these Terms of Use. The AAA Rules are
							available at www.adr.org or by calling 1-800-778-7879. A party who
							wishes to start arbitration must submit a written Demand for Arbitration
							to AAA and give notice to the other party as specified in the AAA Rules.
							The AAA provides a form Demand for Arbitration at <a>www.adr.org</a>
							.&nbsp;
						</p>
					</li>
					<li>
						<p>
							<strong>Arbitration Costs.</strong> Payment of all filing,
							administration and arbitrator fees will be governed by the AAA Rules,
							and each party shall bear its own costs and expenses of arbitration,
							including legal fees.&nbsp;
						</p>
					</li>
					<li>
						<p>
							<strong>Injunctive and Declaratory Relief.</strong> &nbsp;Except as
							provided in Section 8.b. above, the arbitrator shall determine all
							issues of liability on the merits of any claim asserted by either party
							and may award declaratory or injunctive relief only in favor of the
							individual party seeking relief and only to the extent necessary to
							provide relief warranted by that party’s individual claim. To the extent
							that you or we prevail on a claim and seek public injunctive relief
							(that is, injunctive relief that has the primary purpose and effect of
							prohibiting unlawful acts that threaten future injury to the public),
							the entitlement to and extent of such relief must be litigated in a
							civil court of competent jurisdiction and not in arbitration. The
							parties agree that litigation of any issues of public injunctive relief
							shall be stayed pending the outcome of the merits of any individual
							claims in arbitration.
						</p>
					</li>
					<li>
						<p>
							<strong>Class Action Waiver.</strong> YOU AND COMPANY AGREE THAT EACH
							PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL
							CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS
							OR REPRESENTATIVE PROCEEDING. Further, if the parties’ Dispute is
							resolved through arbitration, the arbitrator may not consolidate another
							person’s claims with your claims, and may not otherwise preside over any
							form of a representative or class proceeding. If this specific provision
							is found to be unenforceable, then the entirety of this Dispute
							Resolution section shall be null and void.
							<br />
							<br />
						</p>
					</li>
				</ol>
				<h2>8. Miscellaneous Terms</h2>
				<ol style={{ fontWeight: 'initial', listStyleType: 'upper-alpha' }}>
					<li>
						<p>
							<strong>These Terms May Change.</strong> We reserve the right, at our
							sole discretion, to update, change, modify, or replace any part of these
							Terms of Use by posting updates and changes to the Site. We may elect to
							notify you of such changes by mail, email, posting of modified Terms of
							Use, or some other similar manner. However, it is your responsibility to
							check the Site regularly for changes to these Terms of Use. Your
							continued use of or access to the Site or the Services following the
							posting of any changes to these Terms of Use constitutes acceptance of
							those changes.
						</p>
					</li>
					<li>
						<p>
							<strong>Severability</strong>. In the event that any provision of these
							Terms of Use is determined to be unlawful, void or unenforceable, such
							provision shall nonetheless be enforceable to the fullest extent
							permitted by applicable law, and the unenforceable portion shall be
							deemed to be severed from these Terms of Use, such determination shall
							not affect the validity and enforceability of any other remaining
							provisions.&nbsp;
						</p>
					</li>
					<li>
						<p>
							<strong>Termination</strong>. These Terms of Use are effective unless
							and until terminated by either you or us. You may terminate these Terms
							of Use at any time by notifying us that you no longer wish to use the
							Services, or when you cease using our Services.
							<br />
							<br />
							We are free to terminate (or suspend access to) to your use of the
							Services (or any part thereof) or your Account, if (i) you fail, or we
							suspect that you have failed, to comply with any term or provision of
							these Terms of Use, or (ii) for any other reason in our sole discretion.
							We also may terminate these Terms of Use at any time without notice.
							Even after your right to use the Services is terminated, the obligations
							and liabilities of the parties incurred prior to the termination date
							shall survive the termination and the Terms of Use will remain
							enforceable against you.
							<br />
							<br />
							Provisions that, by their nature, should survive termination of these
							Terms of Use shall survive termination. By way of example, but without
							limitation, all of the following will survive termination: any
							obligation you have to pay us or indemnify us, any limitations on our
							liability, and any terms regarding ownership or intellectual property
							rights.
						</p>
					</li>
					<li>
						<p>
							<strong>No Assignment of the Terms of Use.</strong> You may not assign
							these Terms of Use to any other party. We may assign these Terms of Use
							or delegate any or all of our rights and responsibilities under these
							Terms of Use to any third parties, without notice to you.
						</p>
					</li>
					<li>
						<p>
							<strong>Governing Law and Jurisdiction.</strong> These Terms of Use and
							all disputes arising out of or relating to the Terms of Use shall be
							governed by, construed, and enforced in accordance with the laws of the
							State of New York in the United States, without regard to its conflict
							of laws principles. These disputes will be resolved exclusively in the
							federal and state courts in the State of New York, and you and we
							consent to personal jurisdiction in those courts.
						</p>
					</li>
					<li>
						<p>
							<strong>Waiver</strong>. No delay or omission by us in exercising any
							rights or remedies thereunder shall impair such right or remedy or be
							construed as a waiver of any such right or remedy. Any single or partial
							exercise of a right or remedy by us shall not preclude further exercise
							or any right or remedy by us. No waiver by us shall be valid unless in
							writing signed by us.
						</p>
					</li>
					<li>
						<p>
							<strong>Headings</strong>. The headings used in the Terms of Use are
							included for convenience only and will not limit or otherwise affect
							these Terms of Use.
						</p>
					</li>
					<li>
						<p>
							<strong>Entire Agreement.</strong> These Terms of Use and any policies
							or operating rules posted by us on this website or in respect to the
							Services constitutes the complete and exclusive agreement and
							understanding between you and us related to the Services, and supersedes
							any prior or contemporaneous agreements, communications and proposals,
							whether oral or written, between you and us (including, but not limited
							to, any prior versions of the Terms of Use). Any ambiguities in the
							interpretation of these Terms of Use shall not be construed against the
							drafting party.
						</p>
					</li>
					<li>
						<p>
							<strong>Contact Information.</strong> Questions about the Terms of Use
							should be sent to us at <a>legalnotices@opusintel.co</a>&nbsp;
						</p>
					</li>
				</ol>
			</div>
		</div>
	);
};
