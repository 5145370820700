import styled from 'styled-components';
import {getVariant} from 'styles/themes/button';
import {mediaQuery} from 'styles/mediaQueries';

interface ProfileInfoProps {
	imageSrc?:string
}

export const ProfileHeaderWrapper:any=styled.section`
	display: grid;
  	padding: 72px 1.5rem 0;
  	grid-template-columns: 50% 50% ;
  	column-gap: 1.5rem;
	${mediaQuery('tablet')}{
	  padding: 0;
	  display: block;
	}
`;

export const ProfileAvatar:any=styled.section`
  width: 100% ;

  aspect-ratio: 1/1;
  ${({imageSrc}:ProfileInfoProps):string=>
		imageSrc?`background-image: url(${imageSrc})` : 'background-color:black'};
  background-size: cover;
 	
    
  border-radius: 17px;
  >div{
	display: none;
  }
  
  ${mediaQuery('tablet')}{
	border-radius: 0;
	aspect-ratio: auto;
	>div{
	  display: block;
      background: rgb(0,0,0);
      background: linear-gradient(0deg, rgba(0,0,0,1) 8%, rgba(0,0,0,0) 90%);
	  padding: 0 24px;
	}
  }
`;

export const ProfileHeaderContent:any=styled.div`
  border-bottom: 1px solid #2c2c2c;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  ${mediaQuery('tablet')}{
	  >div:first-child{
		display: none;
	  }
}
`;

export const InfoProfile:any=styled.div`
  text-transform: capitalize;
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  ${mediaQuery('tablet')}{
  	justify-content: end;
  	height: 350px;
  }
`;

export const ProfileName:any=styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
  >svg{
    margin-left: 1rem;
    cursor: pointer;
  }
  >h1{
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
  }
`;

export const CPM:any=styled.div`
  height: 30px;
  margin: 0 0.2rem;
  border-radius: 4px;
  padding: 5px 16px 3px 16px ;
  
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
  ${getVariant('secondary')}
`;

export const StrengthContainer:any=styled.div`
  
  >p{
    font-size: 10px;
    font-weight: 700;
  }
  >div>div:nth-child(1){
	margin-bottom: 0.5rem;
	align-self: end;
	margin-right: 1.5rem;
  }
`;
