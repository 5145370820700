import React, {useContext, useEffect} from 'react';
import OpportunitiesRender from './OpportunitiesRender';
import {Location, NavigateFunction, useLocation, useNavigate, useParams} from 'react-router-dom';
import {QueryResult, useQuery} from '@apollo/client';
import {query} from 'actions/queries';
import {UserContext, userContextInterface} from '../../../context/userContext';
import {Spinner} from 'components/ui';

export const OpportunitiesOld=():JSX.Element=>{

	const {user, loading}:userContextInterface=useContext(UserContext);
	const navigate:NavigateFunction=useNavigate();
	const {pathname}:Location=useLocation();
	let pathArray:string[]=pathname.split('/');

	const {filter}:any = useParams();

	useEffect(():void=>{
		if (pathArray.length<3 || !pathArray[2]){
			navigate('/campaigns/active');
		}
	},[pathname]);

	const {data}:QueryResult=useQuery(query('opportunityFindMany'),{
		fetchPolicy:'network-only',
		variables:{
			filter:{
				company: user?._id,
				active: filter === 'active'
			},
			skip:0,
			limit:50
		}}
	);

	return !loading?
		<OpportunitiesRender data={data?.opportunityFindMany} filter={filter}/>
		:
		<div className={'flex justify-center items-center h-screen'}>
			<Spinner/>
		</div>
			
	;
};
