import React, {useContext} from 'react';
import SideMenuRender from './SideMenuRender';
import {CompanyProfile, NavItem} from 'interfaces';
import {
	BrandsLightningIcon,
	DashboardIcon,
	NotificationsIcon, ScoreGraphIcon, SearchIcon, HomeIcon, BookMarkIcon
} from 'icons';
import {UserContext} from 'context/userContext';
import SettingsIcon from 'icons/SettingsIcon';
import {QueryResult, useQuery} from '@apollo/client';
import {query} from '../../actions/queries';
import {BreadCrumbAtom, TCrumb} from '../../context/recoil/store';
import {useRecoilValue} from 'recoil';

interface SideMenuProps {
    show:boolean
    styleTheme?:'opus'|'opusV2'
}

const GeneralNav:NavItem[]=[
	{
		id: 'settings',
		label: 'Settings',
		path: '/settings',
		Icon: SettingsIcon
	}
];

const NavItemsByUserType=(userType:string):NavItem[]=> {

	let NavItems: Record<string, NavItem[]>={
		'Regular':[
			{
				id: 'dashboard',
				label: 'Home',
				path: '/dashboard',
				Icon: DashboardIcon
			},
			{
				id: 'score',
				label: 'Score Graph',
				path: '/score-graph',
				Icon: ScoreGraphIcon
			},
			// {
			// 	id: 'news',
			// 	label: 'In the News',
			// 	path: '/news',
			// 	Icon:NotificationsIcon
			// },
			{
				id: 'settings',
				label: 'Settings',
				path: '/settings',
				Icon: SettingsIcon
			}
		],
		'Company':[
			{
				id: 'dashboard',
				label: 'Home',
				path: '/dashboard',
				Icon: HomeIcon
			},
			{
				id: 'opportunities',
				label: 'Opportunities',
				path: '/opportunities',
				Icon: BrandsLightningIcon
			},
			{
				id: 'hotlist',
				label: 'Hotlist',
				path: '/hotlist',
				Icon: NotificationsIcon
			},
			{
				id: 'discover',
				label: 'Discovery Dash',
				path: '/discover',
				beta : true,
				Icon: SearchIcon
			},
			{
				id: 'favorites',
				label: 'Favorites',
				path: '/favorites',
				Icon: BookMarkIcon
			}
		],
		'Agent':[
			{
				id: 'opportunities',
				label: 'Opportunities',
				path: '/company/alerts',
				Icon: BrandsLightningIcon
			},
			{
				id: 'news',
				label: 'In the News',
				path: '/news',
				Icon: NotificationsIcon
			},
			{
				id: 'settings',
				label: 'Settings',
				path: '/settings',
				Icon: SettingsIcon
			}
		],
		'default':[
			{
				id: 'dashboard',
				label: 'Dashboard',
				path: '/dashboard',
				Icon: DashboardIcon
			},
			{
				id: 'news',
				label: 'In the News',
				path: '/news',
				Icon: NotificationsIcon
			},
			{
				id: 'settings',
				label: 'Settings',
				path: '/settings',
				Icon: SettingsIcon
			}
		]
	};
	return NavItems[userType];
};

export const SideMenu=({show,styleTheme='opus',...props}:SideMenuProps):JSX.Element=>{
	let crumbs:TCrumb[] = useRecoilValue<TCrumb[]>(BreadCrumbAtom);
	const {user}:any=useContext(UserContext);


	const {data:companyProfileData}:QueryResult = useQuery(query('usersCompanyProfileFindOne'),{
		variables:{user:user?._id}
	});


	const companyProfile:CompanyProfile=companyProfileData?.usersCompanyProfileFindOne;
	return <SideMenuRender
		styleTheme={styleTheme}
		user={user}
		companyProfile={companyProfile}
		activePath={crumbs[0]?.id}
		navItems={NavItemsByUserType(user?.type?.label||'default')}
		generalNav={GeneralNav}
		show={show}
		{...props}
	/>;
};
