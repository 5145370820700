import React from 'react';
import {IconWrapper,SocialLabel,SocialSubLabel,SocialWrapper} from './styles';

interface SocialConnectRenderProps{
	label:string;
	accountName?:string;
	connected:boolean
	link:boolean
	SocialIcon:JSX.Element;
	StateIcon:JSX.Element;

	onClick:()=>void;
	setError:(errorMessage:string,socialNetwork:string)=>void
}

export default function SocialButtonRender({
	label,
	accountName,
	connected,
	link,
	SocialIcon,
	StateIcon,
	onClick
}:SocialConnectRenderProps):JSX.Element{

	return (
		<SocialWrapper>
			<div>
				{SocialIcon}
				<div>
					{connected && <SocialSubLabel>{label}</SocialSubLabel>}
					<SocialLabel>{connected? accountName: label}</SocialLabel>
				</div>
			</div>
			<IconWrapper onClick={onClick} pointer = {!connected || link}>
				{StateIcon}
			</IconWrapper>
		</SocialWrapper>
	);
}
