import React from 'react';
import {StyledRange} from './styles';


interface RangeRenderProps{
	color:string;
	percentage:number;
	className?:string;
}

export default function RangeRender({color,percentage,className}:RangeRenderProps):JSX.Element{
	
	return (
		<StyledRange
			className={className}
			$color={color}
			$percentage={percentage}
		>
			<div/>
		</StyledRange>
	);
}
