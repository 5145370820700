import styled from 'styled-components';

export const ThumbnailWrapper:any=styled.div`
  position: relative;
	>img{
      width: 100%;
	  object-fit: cover;
    }
	>svg{
      position: absolute;
      left: 0;
      right: 0;
	  margin: auto;
      top: 0;
	  bottom: 0;
	}
`;



