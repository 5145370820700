import React from 'react';
import {CountryAudience,Flag} from './styles';
import {ChartItem} from '../../interfaces';
import {countryCodes, CountryCodeItem} from './countryCodes';


interface AudienceByCountryProps {
	countries:ChartItem[]
	showTitle?:boolean
}

export default function AudienceByCountryRender({countries, showTitle=false}:AudienceByCountryProps):JSX.Element{

	const getFlag = (country:string):string => {
		const find:any = countryCodes.find((e:CountryCodeItem):any => e.name === country);
		return find?.code || country;
	};

	return <div>
		{showTitle &&
			<h6>Audience by Country</h6>
		}
		{countries.map((c:ChartItem,i:number):any=>
			i<10 &&
			<CountryAudience key={i}>
				<div>
					<Flag country={getFlag(c.label)}/>
					<p>{c.label.toUpperCase()}</p>
				</div>
				<p>{c.value.toFixed(2)}%</p>
			</CountryAudience>)
		}
	</div>;
}