import styled from 'styled-components';
import {themeOpus} from '../../stylesOpus/theme';

export const LayoutContainer :any = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: ${themeOpus.colors.grey_1000};
  flex-direction: ${({direction}:any):string=>direction};
`;

export const AuthContent:any = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  
  flex-direction: column;
  align-items: center;
  justify-items: start;
  
  padding: 2rem 4rem;
`;
export const  VideoContainer:any = styled.div`
  width:  100%;
  height: 100vh;
  object-fit: cover;
  padding: 2rem 4rem 2rem 0;
  >video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 40px;
  }
`;