import React, {useContext, useEffect} from 'react';
import {TalentDashboard} from 'pages/TalentDashboard';
import {UserContext, userContextInterface} from 'context/userContext';
import {SetterOrUpdater, useSetRecoilState} from 'recoil';
import {BreadCrumbAtom, TCrumb} from '../../context/recoil/store';
import {HomeCompany} from 'pages/HomeCompany';

export interface DashboardRenderProps {
	children?:JSX.Element;
}

const renderByUserType:any={
	'Regular':<TalentDashboard/>,
	'Company':<HomeCompany/>,
	'default':<></>
};

const gerRenderByUserType=(userType:string):JSX.Element=>
	renderByUserType[userType||'default'];

export const Dashboard=():JSX.Element=>{
	const setBreadCrum: SetterOrUpdater<TCrumb[]>=useSetRecoilState(BreadCrumbAtom);

	useEffect(():void => {
		setBreadCrum([
			{id: 'dashboard', label: 'Dash Board', path:'/dashbord'}
		]);
	}, []);

	const {user}:userContextInterface=useContext(UserContext);
	return <>
		{gerRenderByUserType(user.type.label)}
	</>;
};