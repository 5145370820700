import React from 'react';
import {SimpleChartRender} from './SimpleChartRender';
import {color} from 'styles/colors';
import {Chart, ChartItem, ChartItemUI} from 'interfaces';
import {getPercentage, sortItems} from 'tools';

interface SimpleChartProps extends Chart{
	inLine?:boolean
	percentage?:boolean
}

export const SimpleChart=({items,percentage,maxValue,length,sort,sortBy,sortType,...props}:SimpleChartProps):JSX.Element=>{
	let dataItems:ChartItemUI[]= items.map((item:ChartItem,i:number):ChartItemUI=>{
		let itemUI:ChartItemUI={...item,id:item.label};
		let index:number=i+1;
		if(index%3===1){itemUI.color=color.get('primary');}
		if(index%3===2){itemUI.color='#8D6CEC';}
		if(index%3===0){itemUI.color=color.get('success-100');}
		return itemUI;
	});
	if(sort){
		sortItems(items,sortType,sortBy);
	}
	if (percentage){
		dataItems = dataItems.map((item:ChartItemUI):ChartItemUI=> ({
			...item,
			value:getPercentage(item.value,maxValue)
		}));
	}

	return <SimpleChartRender
		items={dataItems.slice(0,length)}
		{...props}
	/>;
};
