import React, {ChangeEvent} from 'react';
import {EmptyImage, RoundImage} from '../ui';
import styled from 'styled-components';

interface PictureInputRenderProps {
	image?:string
	label?:string
	size?:string
	rounded?:string
	disabled?:boolean
	plus?:boolean
	fontSize?:string
	accept?:string
	onChange?:(e:ChangeEvent<HTMLInputElement>)=>void
}

const InputWrapper:any=styled.label`
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: center;
`;

const Label:any=styled.label`
    cursor: pointer;
    margin-top: 1rem;
    background: none;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    >input{
      display: none;
    }
`;

export const PictureInputRender = ({accept='image/*',image,size='120px',label='Select Picture',disabled,rounded,plus,fontSize,onChange}:PictureInputRenderProps):JSX.Element => {

	return <InputWrapper className={!disabled &&'cursor-pointer'}>
		{image?
			<RoundImage src={image} size={size} rounded={rounded}/>
			:
			<EmptyImage size={size} rounded={rounded} plus={plus} fontSize={fontSize}/>
		}
		{label ?
			<Label>
				<input
					disabled={disabled}
					type='file'
					accept={accept}
					onChange={onChange}
				/>
				{label}
			</Label> :
			<input className={'hidden'}
				type='file'
				onChange={onChange}
				accept={accept}
			/>
		}
	</InputWrapper>;
};