import React, {useContext} from 'react';
import ProductsRender from './ProductsRender';
import {QueryResult, useQuery} from '@apollo/client';
import {query} from 'actions/queries';
import {UserContext, userContextInterface} from 'context/userContext';
import {Brand} from '../../interfaces';

export const Products = ():JSX.Element => {
	const {user}:userContextInterface =useContext(UserContext);
	const {data:brandData}:QueryResult=useQuery(query('brandFindMany'),{
		variables:{findBrandFilter:{company:user._id}},
		fetchPolicy:'network-only'
	});
	console.log('dsd');
	const products:Brand[]=brandData?.brandFindMany||[];
	return <ProductsRender products={products} />;
};