import styled from 'styled-components';
import {TextProps} from 'stylesOpus/typography';

export type GlowTagProps = {
    color?: string
    colorGlow?: string
    fontColor?: string
} & TextProps
export const GlowTag:any = styled.p<GlowTagProps>`
  padding: 0.125rem 0.25rem;
  border-radius: 0.125rem;
  width: fit-content;
  font-size: ${({fontSize}:GlowTagProps) : string=> fontSize || '0.625rem'};	  
  background-color: ${({color}:GlowTagProps) : string=> color || '#1C2FDD'};
  box-shadow: 0 0 8px 0 ${({colorGlow}:GlowTagProps) : string=> colorGlow || '#1C2FDD'};
  font-weight: 800;
`;

export const Tag:any = styled.p<GlowTagProps>`
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  border-radius: 12px;
  width: fit-content;
  font-weight: 800;
  font-size: ${({fontSize}:GlowTagProps) : string=> fontSize || '0.625rem'};	  
  background-color: ${({color}:GlowTagProps) : string=> color || '#1C2FDD'};
  color: ${({fontColor}:GlowTagProps) : string=> fontColor || '#FFF'};
`;