import React from 'react';
import {FormButton, Input} from 'components/ui';
import { ReactComponent as Instagram } from 'styles/icons/companies/instagram.svg';
import {FormProps} from 'interfaces';
import {OnboardingForm} from 'containers/OnBoarding/components';

type SocialMediaProps = {
	initTensor:boolean,
} & FormProps;

export default function SocialMediaRender({submitLoading,errors,onSubmit, registerInput}:SocialMediaProps):JSX.Element{
	return (
		<OnboardingForm
			title={'Social Media'}
			onSubmit={onSubmit}
		>
			
			<Input
				placeholder={'Instagram ( required )'}
				Icon= {
					<Instagram/>
				}
				{...registerInput('instagram')}
			/>
			<FormButton
				className={'mt-3'}
				width={'100%'}
				label='Continue'
				variant='grey'
				loading={submitLoading}
			/>
		</OnboardingForm>
	);
}
