import {ChartDataHoc, ChartItem, ChartItemApi, Distribution} from '../interfaces';

export const  monthLabels:any = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const weekLabels:string[]=['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];

export const getFraction = (value: number, max: number):number=> {
	return (value / max);
};

export const getPercentage = (value: number, max: number, fixed: number = 2): number => {
	return parseFloat((getFraction(value, max) * 100).toFixed(fixed))||0;
};
export const getTotalFromArray = (list: ChartItemApi[]): number => {
	return list.reduce((acc: number, item: ChartItemApi): number => acc + item.mean, 0);
};

export const sortItems=(items:ChartItem[], sortType:'asc'|'desc'='asc', sortBy:'label'|'value'='value'):void=> {
	if(sortType==='asc'){
		items.sort((a: ChartItem, b: ChartItem): number => a[sortBy] < b[sortBy] ? 1 : -1);
	}
	if(sortType==='desc'){
		items.sort((a: ChartItem, b: ChartItem): number => a[sortBy] < b[sortBy] ? -1 : 1);
	}
};


export const distToChartItem=(dis:Distribution):ChartItem=>({
	value:dis.mean,
	label:dis.metadata
});
export const getDataFromDisribution=(distributionData:Distribution[]):ChartDataHoc=>{
	const total:number = Math.max(...distributionData.map((i:Distribution):number=>i.value))||0;
	let data:ChartItem[] = distributionData.map((dis:Distribution):ChartItem=>distToChartItem(dis));

	if(data.length){
		data.sort((a:ChartItem,b:ChartItem):number=>
			(parseInt(a.label)<parseInt(b.label))?-1:1
		);

		data=data.map((d:ChartItem,index:number,array:ChartItem[]):ChartItem=>
			index? {...d,value : d.value - (array[index].value || 0)}:d
		);
	}

	return {total:total,data};
};


export const itemsMeanToValue=(items:ChartItemApi[]):ChartItem[]=>
	items.map((item:ChartItemApi,i:number,array:ChartItemApi[]):ChartItem=>(
		{
			label:item.metadata,
			value:i!==0?item.mean-array[i-1].mean :item.mean
		})
	);

export const fakeDistributionList:Distribution[]=[
	{mean:69,metadata:'1666647692000',value:1842},
	{mean:72,metadata:'1666734092000',value:1842},
	{mean:40,metadata:'1666820492000',value:1842},
	{mean:51,metadata:'1666906892000',value:1842}
];
