import styled from 'styled-components';
import {color} from 'styles/colors';

export const Followers:any=styled.p`
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 0.1rem;
`;

export const SocialNetwork:any=styled.p`
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  text-transform: capitalize;
  color: ${color.get('neutral-400')};
`;


export const NewFollowers:any=styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${color.get('success-100')};
`;

