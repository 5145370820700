import React, {useState} from 'react';
import {SearchIcon} from '../../../../icons';
import {InputBox, InputLabel, InputOptionsList, InputOptionsListItem} from '../styledComponents';
import {IAutoFillInput, IOption} from '../../DiscoverInterfaces';

export default function AutoFillInput({title,options=[],keyword,setKeyword, placeholder, selectOption}:IAutoFillInput):JSX.Element {

	const [showList, setShowList] = useState(false);

	const selected = (option:IOption):void => {
		setShowList(false);
		setKeyword('');
		selectOption(option);
	};

	return (
		<InputBox>
			<InputLabel className="flex align-center">
				<input
					type="text"
					placeholder={placeholder}
					value={keyword}
					onChange={(e:any):void=>setKeyword(e.target.value)}
					onFocus={():void => setShowList(true)}
					//onBlur={():void => setShowList(false)}

				/>
				<SearchIcon />
			</InputLabel>
			{showList && options.length > 0 &&
				<InputOptionsList>
					{options?.map((option:IOption):JSX.Element=>{
						return (
							<InputOptionsListItem key={option._id} onClick={():void=>selected(option)}>
								{option.label}
							</InputOptionsListItem>
						);
					})}
				</InputOptionsList>
			}
		</InputBox>
	);
}