import React, {useContext} from 'react';
import {Collapsable} from 'components';
import styled from 'styled-components';
import {TalentListSummary} from 'components/TalentListSummary';
import {QueryResult, useQuery} from '@apollo/client';
import {query} from 'actions/queries';
import {UserContext, userContextInterface} from 'context/userContext';
import {TalentList} from 'interfaces';
import {CreateListForm} from '../forms/CreateListForm';

const Container:any=styled.div`
  width: 350px;
  
`;

export const HeaderMenu=():JSX.Element=>{
	const {user}:userContextInterface=useContext(UserContext);
	const {data:listsData,loading:listsLoading}:QueryResult=useQuery(query('listFindMany'),{
		variables:{
			filter:{user:user._id},
			skip:0,
			userSkip:0,
			userLimit:4
		},
		fetchPolicy:'network-only'
	});

	if(!listsData || listsLoading )
		return <p>loading...</p>;

	const listArray: TalentList[] =listsData.listFindMany;

	return <Container>{
		listArray.length< 1 ?
			<CreateListForm onCreate={():void=> console.log('created')}	/>
			:
			<>
				{listArray.map((list:TalentList):JSX.Element=>
					<Collapsable
						key={list._id}
						defaultShow={ false}
						budget={{
							label:{
								text:list.talents.length.toString()
							}
						}}
						className={''} label={list.name}
					>
						<TalentListSummary list={list}/>
					</Collapsable>
				)}
			</>
			
	}
	</Container>;
};
