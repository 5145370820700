import React from 'react';
import TalentDetail from 'components/alerts/TalentDetail';
import {ModalContainer} from 'components/ModalContainer';
import {ListModal} from 'components/complexComponents';
interface ModalsProps {
	currentTalent: string
	showTalent: boolean
	setShowTalent: (show:boolean)=>void
	showAddList: boolean
	setShowAddList: (show:boolean)=>void
	add:boolean
	score?:any
}

export default function DiscoveryTalentModalRender({showTalent, showAddList, setShowAddList, setShowTalent,score, currentTalent, add}:ModalsProps):JSX.Element {
	return (
		<div>
			<ModalContainer maxWidth='max-w-1200px' show={showTalent} close={():void => setShowTalent(false)} centered={false}>
				<TalentDetail score={score} setShowAddList={setShowAddList} id={currentTalent} close={():void => setShowTalent(false)} add={add} />
			</ModalContainer>
			<ModalContainer show={showAddList} close={():void => setShowAddList(false)}>
				<ListModal talentId={currentTalent} close={():void => setShowAddList(false)} />
			</ModalContainer>
		</div>
	);
}