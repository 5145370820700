import React from 'react';
import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';
import {TalentMediaI} from '../../../hooks/useTalentDetail';

interface TalentMediaRenderI {
	media?:  TalentMediaI[]
}

export default function TalentMediaRender({media}:TalentMediaRenderI):JSX.Element{

	return (
		<Masonry columnsCount={3} gutter={'1rem'}>
			{media && media?.length > 0 && media.map(({title, link, files, _id}:TalentMediaI):JSX.Element=>{
				if (files[0]?.url){
					return (
						<a key={_id} target="_blank" rel="noreferrer" className="relative flex align-end justify-center w-100" href={link}>
							<img src={files[files?.length -1 || 0]?.url} className="w-100 obj-cover br obj-top" />
							<div className="absolute bottom-0 w-100 flex justify-center">
								<div className="neutral-800 br-half phor pver-half flex align-center t-white relative mb f-urbanist f-12px f-700">
									<div className="flex align-center">
										{title}
									</div>
								</div>
							</div>
						</a>
					);
				}
			})}
		</Masonry>
	);
}