import React from 'react';
import {Opportunity} from 'interfaces';
import {TalentOpportunityCard} from 'components';
import {useParams} from 'react-router-dom';
import {LinkButton} from 'components/ui';
import {BrandsLightningIcon} from 'icons';
import {color} from 'styles/colors';
import TabLink from '../../../components/Tabs/TabLink';
import styled from 'styled-components';
import {themeOpus} from 'stylesOpus/theme';
import {ContentLayout} from '../../ContentLayout';

interface CampaignListProps{
	opportunities: Opportunity[]
}

const CreateOpportunity:any= styled(LinkButton)`
	background-color: ${ themeOpus.colors.grey_900 };
	font-family: sans-serif, Urbanist;
	font-weight: 500;
	font-size: 0.875rem;
	gap: 1rem;
	display: flex;
	align-items: center;
`;

export default function CampaignListRender({opportunities: opportunities}:CampaignListProps):JSX.Element{
	const {filter}:any = useParams();
	return (
		<ContentLayout
			header={
				<div className={'pb flex align-center justify-between'}>
					<div>
						<TabLink styleTheme={'opusV2'} selected={ filter === 'draft'} link={'/campaigns/draft'}>Draft</TabLink>
						<TabLink styleTheme={'opusV2'} selected={ filter === 'active'} link={'/campaigns/active'}>Active</TabLink>
					</div>
					
					<CreateOpportunity to="/opportunities/create" >
						<BrandsLightningIcon color={color.get('white')} />
							Create New Opportunity
					</CreateOpportunity>
			
				</div>
			}
		>
			<div className={'w-100 flex direction-column grows'}>

				{opportunities && opportunities.length<1&&
					<div className="pver-6 max-w-500px m-auto flex direction-column align-center">
						<p className="mb-2 cente r">No { filter } opportunities 😢</p>
						<CreateOpportunity  to="/opportunities/create" >
							<p className={'f-700 f-1rem'}>Create Opportunity</p>
							<BrandsLightningIcon  />
						</CreateOpportunity>
					</div>
				}
				<div className="pb-4">
					<div className="grid4 col-gap-2 mobile:grid1">
						{opportunities && opportunities.map((op:Opportunity):JSX.Element=>
							<TalentOpportunityCard key={op._id} opportunity={op}/>
						)}
					</div>
				</div>
			</div>
		</ContentLayout>


	);
}