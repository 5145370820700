import React, {useEffect, useState} from 'react';
import AutoFillInput from '../form/AutoFillInput';
import {QueryResult, useQuery} from '@apollo/client';
import {query} from '../../../../actions/queries';
import {IMatch, IMatchAudience, IOption} from '../../DiscoverInterfaces';
import {MultiSelectTag} from '../styledComponents';
import {CloseIcon} from '../../../../icons';
import {useRecoilState} from 'recoil';
import {matchQueryAtom, stateFormAtom} from '../../../../context/recoil/store';

interface ASRI {
	options:IOption[]
	optionsFilter:IOption[]
	setOptionsFilter:(o:IOption[])=>void
}

export default function AudienceStateRender({options, setOptionsFilter, optionsFilter}:ASRI):JSX.Element {

	const [matchQuery, setMatchQuery] = useRecoilState<IMatch>(matchQueryAtom);
	const [stateForm, setStateForm] = useRecoilState<IOption[]>(stateFormAtom);
	const [optionsSelected, setOptionsSelected] = useState<IOption[]>([]);
	const [keyword, setKeyword] = useState<string>('');
	const [loadingC, setLoadingC] = useState<boolean>(true);


	useEffect(():void => {
		const k:string = keyword.toLowerCase();
		const cf:IOption[] = options.filter((e:IOption):boolean => e.value.startsWith(k));
		setOptionsFilter(cf);
	},[keyword]);

	const selectOption = (sel:IOption):void =>{
		let os:IOption[] = [...optionsSelected];
		if (!os.includes(sel)){
			os.push(sel);
			setOptionsSelected(os);
		}

	};

	const deleteOption = (id:string):void =>{
		let os:IOption[] = optionsSelected.filter((e:IOption):boolean => e._id !== id);
		setOptionsSelected(os);
	};

	useEffect(():void => {

		// let m: IMatch = {...matchQuery};
		// let a: IMatchAudience = {...matchQuery.audience};
		// m.audience = a;
		//m.audience.states = optionsSelected.map(({value}:IOption):string=>value);
		//setMatchQuery(m);

		setStateForm(optionsSelected);

	}, [optionsSelected]);

	useEffect(():void=>{
		if(loadingC) {
			if(stateForm && stateForm.length > 0){
				setOptionsSelected(stateForm);
			}
			setLoadingC(false);
		}
	},[loadingC]);


	return (
		<div>
			<AutoFillInput
				title=""
				options={optionsFilter}
				placeholder="Search States"
				keyword={keyword}
				setKeyword={setKeyword}
				selectOption={selectOption}
			/>

			<div className="mt">
				{optionsSelected.map(({label,_id,value}:IOption):any => {
					return(
						<MultiSelectTag key={_id} onClick={():void=>deleteOption(_id)}>
							{label} <CloseIcon color="#626579" width={14} height={14} />
						</MultiSelectTag>
					);
				})}
			</div>
		</div>
	);
}