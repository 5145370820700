import React from 'react';
import {DoubleRange, FormButton, Input, Select, TextBox} from 'components/ui';
import {FormProps, Item} from 'interfaces';
import {FileInput} from 'components/ui/FileInput';

interface EventLocationProps extends FormProps{
	options:{
		states:Item[]
		paid:Item[]
	}
}

export const EventLocationRender = ({options,onSubmit,registerInput,registerList,formData,registerSelect,registerTextArea,onChangeFile}:EventLocationProps):JSX.Element => {

	return <>
		<form className={'mt-4'} onSubmit={onSubmit}>
			<h4>Event Location</h4>
			<div className={'grid3 col-gap'}>
				<Input
					label ={'Venue name'}
					{...registerInput('venue')}
				/>
				<Input
					label={'city'}
					{...registerInput('city')}
				/>
				<Select
					label={'state'}
					options={options.states}
					{...registerSelect('state')}
				/>
				<Input
					label="Date from"
					placeholder="MM/DD/YYY"
					type="date"
					{...registerInput('eventFrom')}
				/>
				<Input
					label="date to"
					placeholder="MM/DD/YYY"
					type="date"
					{...registerInput('eventTo')}
				/>
				<Select
					label={'Paid or Free event'}
					options={options.paid}
					{...registerSelect('eventPaid')}
				/>
			</div>
			<FileInput
				label={'Event Deck'}
				helpText={'Upload Document (PDF or JPG)'}
				accept={'.pdf, .jpg'}
				onChange={onChangeFile}
				defaultFile={formData['deck']}
			/>
			<h4 className={'mt-4 mb-2'}>Event Partnership</h4>
			<DoubleRange
				label={'Preferred Sponsorship Fee'}
				minValue={0}
				maxValue={1e6}
				prefix={'$'}
				styleProps={{
					minAlias:'$0',
					maxAlias:'$1M+'
				}}
				{...registerList('feeRange')}
			/>
			<TextBox
				label={'List Partnership opportunities'}
				{...registerTextArea('eventPartnership')}
			/>
			<div className={'w-100 flex justify-between'}>
				<FormButton
					label={'continue'}
					size={'small'}
				/>
			</div>
		</form>
	</>;
};