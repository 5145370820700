import React, {useEffect, useState} from 'react';
import {AuthorityScores} from '../../../components/complexComponents';
import {useParams} from 'react-router-dom';
import {TAudience, AudienceItem} from '../../../hooks/useTalentDetail';
import MapUsa from '../components/MapUsa';
import MapWorld from '../components/MapWorld';
import {BoxTitle} from '../../../stylesOpus/typography';
import {getFlag} from '../../../tools/globalActions';
import {Flag} from '../../../components/AudienceByCountry/styles';
import GradientBox from '../../../styles/images/GradientBox';

interface TalentOpusRenderI {
	audience?: TAudience
}

export default function TalentOpusRender({audience}:TalentOpusRenderI):JSX.Element{

	const {id}:any = useParams();
	const [genders, setGenders] = useState<AudienceItem[]>([]);
	const [ages, setAges] = useState<AudienceItem[]>([]);


	useEffect(():void=>{
		//console.log(audience);
		if(audience?.audienceGenderAge){
			const m:AudienceItem[] = audience?.audienceGenderAge?.filter((e:AudienceItem):boolean=>e.name.includes('M.'));
			const f:AudienceItem[] = audience?.audienceGenderAge?.filter((e:AudienceItem):boolean=>e.name.includes('F.'));

			const a1:AudienceItem[] = audience?.audienceGenderAge?.filter((e:AudienceItem):boolean=>e.name.includes('13-17'));
			const a2:AudienceItem[] = audience?.audienceGenderAge?.filter((e:AudienceItem):boolean=>e.name.includes('18-24'));
			const a3:AudienceItem[] = audience?.audienceGenderAge?.filter((e:AudienceItem):boolean=>e.name.includes('25-34'));
			const a4:AudienceItem[] = audience?.audienceGenderAge?.filter((e:AudienceItem):boolean=>e.name.includes('35-44'));
			const a5:AudienceItem[] = audience?.audienceGenderAge?.filter((e:AudienceItem):boolean=>e.name.includes('45-64'));
			const a6:AudienceItem[] = audience?.audienceGenderAge?.filter((e:AudienceItem):boolean=>e.name.includes('65'));

			const g:AudienceItem[] = [
				{
					name: 'Male',
					weight: m.reduce((partialSum:number, a:AudienceItem):number => partialSum + a?.weight, 0)
				},
				{
					name: 'Female',
					weight: f.reduce((partialSum:number, a:AudienceItem):number => partialSum + a?.weight, 0)
				}
			];

			const a:AudienceItem[] = [
				{
					name: '13-17',
					weight: a1.reduce((partialSum:number, a:AudienceItem):number => partialSum + a?.weight, 0)
				},
				{
					name: '18-24',
					weight: a2.reduce((partialSum:number, a:AudienceItem):number => partialSum + a?.weight, 0)
				},
				{
					name: '25-34',
					weight: a3.reduce((partialSum:number, a:AudienceItem):number => partialSum + a?.weight, 0)
				},
				{
					name: '35-44',
					weight: a4.reduce((partialSum:number, a:AudienceItem):number => partialSum + a?.weight, 0)
				},
				{
					name: '45-64',
					weight: a5.reduce((partialSum:number, a:AudienceItem):number => partialSum + a?.weight, 0)
				}
				,
				{
					name: '65+',
					weight: a6.reduce((partialSum:number, a:AudienceItem):number => partialSum + a?.weight, 0)
				}
			];
			setGenders(g);
			setAges(a);

		}
	},[audience]);

	const ethnicityName = (name:string):string => {
		switch (name) {
			case 'White / Caucasian':
				return 'White';
				break;
			case 'African American':
				return 'Black';
				break;
			case 'Hispanic':
				return 'Latino';
				break;
			default:
				return name;
		}
	};

	return (
		<div>
			<div className="neutral-800 p-20px mb-2 br">
				<BoxTitle fontSize={'16px'}>High-Scoring Categories</BoxTitle>
				<p className="f-12px t-neutral-300 f-600 mt-half mb-3 max-w-600px lh-150 f-urbanist">We need a short description on what “Brands Per Category” are. Shouldn’t be too long, maybe just a couple of sentences bc we need context here. </p>
				<div className="phor-6">
					<AuthorityScores talentId={id} />
				</div>
			</div>

			<div className="neutral-800 p-20px mb-2 br">
				<BoxTitle fontSize={'16px'}>Audience Summary</BoxTitle>
				<p className="f-12px t-neutral-300 f-600 mt-half mb-3 max-w-600px lh-150 f-urbanist">We need a short description on what “Brands Per Category” are. Shouldn’t be too long, maybe just a couple of sentences bc we need context here.Here, we can explain a little on what this section is about. Why they are seeing the score and what value does it bring to the user. </p>

				<div className="grid4 col-gap-4 laptop:col-gap phor-2">
					<div className="overflow-hidden br-2 b b-neutral-600 neutral-1000 pver-2 phor-4 col-span2 relative">
						<div className="absolute left-0 bottom-2-n">
							<GradientBox />
						</div>
						<div className="relative">
							<BoxTitle textAlign={'center'}>Age</BoxTitle>
							<div className="mt-2 grid2 col-gap-2">
								<div>
									{ages && ages?.length > 0 && ages?.slice(0,3)?.map(({name, weight}:AudienceItem):JSX.Element=>{
										return (
											<div key={name} className="grid2 mb ">
												<BoxTitle>{name}</BoxTitle>
												<BoxTitle>{(weight*100).toFixed(1)} %</BoxTitle>
											</div>
										);
									})}
								</div>
								<div>
									{ages && ages?.length > 0 && ages?.slice(3,6)?.map(({name, weight}:AudienceItem):JSX.Element=>{
										return (
											<div key={name} className="grid2 mb ">
												<BoxTitle>{name}</BoxTitle>
												<BoxTitle>{(weight*100).toFixed(1)} %</BoxTitle>
											</div>
										);
									})}
								</div>

							</div>
						</div>
					</div>

					<div className="br-2 b-neutral-600 b neutral-1000 pver-2 phor-4 laptop:phor-2 br overflow-hidden relative">
						<div className="absolute left-6-n bottom-2-n">
							<GradientBox />
						</div>
						<div className="relative">
							<BoxTitle textAlign={'center'} className="mb-2">Gender</BoxTitle>
							{genders && genders?.length > 0 && genders?.map(({name, weight}:AudienceItem):JSX.Element=>{
								return (
									<div key={name} className="grid2 mb col-gap">
										<BoxTitle>{name}</BoxTitle>
										<BoxTitle>{(weight*100).toFixed(1)} %</BoxTitle>
									</div>
								);
							})}
						</div>
					</div>

					<div className="br-2 b-neutral-600 b neutral-1000 pver-2 phor-4 laptop:phor-2 overflow-hidden relative">
						<div className="absolute left-6-n bottom-2-n">
							<GradientBox />
						</div>
						<div className="relative">
							<BoxTitle textAlign={'center'} className="mb-2">Ethnicity</BoxTitle>
							{audience?.audienceEthnicities && audience?.audienceEthnicities?.length > 0 && audience?.audienceEthnicities.map(({name, weight}:AudienceItem):JSX.Element=>{
								return (
									<div key={name} className="grid2 mb col-gap">
										<BoxTitle>{ethnicityName(name)}</BoxTitle>
										<BoxTitle>{(weight*100).toFixed(1)} %</BoxTitle>
									</div>
								);
							})}
						</div>
					</div>

				</div>




			</div>

			<div className="neutral-800 p-20px mb-2 br">
				<BoxTitle fontSize={'16px'}>World Hotspots</BoxTitle>
				<p className="f-12px t-neutral-300 f-600 mt-half max-w-600px lh-150 f-urbanist">We need a short description on what “World HotSpots” are. Shouldn’t be too long, maybe just a couple of sentences bc we need context here.  </p>

				<div className="grid2">
					<div className="flex align-center ">
						<div className="phor-2 w-100">
							<div className="grid2">
								<div>
									{audience?.audienceCountry && audience?.audienceCountry?.length > 0 && audience?.audienceCountry?.slice(0,3)?.map(({name, weight}:AudienceItem):JSX.Element=>{
										return (
											<div key={name} className="grid2 mb col-gap">
												<div className="flex align-center">
													<Flag className="mr-half" country={getFlag(name)}/>
													<BoxTitle>
														{name}
													</BoxTitle>
												</div>

												<BoxTitle>{(weight*100).toFixed(1)} %</BoxTitle>
											</div>
										);
									})}
								</div>
								<div>
									{audience?.audienceCountry && audience?.audienceCountry?.length > 0 && audience?.audienceCountry?.slice(3,6)?.map(({name, weight}:AudienceItem):JSX.Element=>{
										return (
											<div key={name} className="grid2 mb col-gap">
												<div className="flex align-center">
													<Flag className="mr-half" country={getFlag(name)}/>
													<BoxTitle>
														{name}
													</BoxTitle>
												</div>
												<BoxTitle>{(weight*100).toFixed(1)} %</BoxTitle>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
					<MapWorld countries={audience?.audienceCountry?.slice(0,6)} />
				</div>

				<div className="grid5 mb col-gap phor-3">
					{audience?.audienceCity && audience?.audienceCity?.length > 0 && audience?.audienceCity?.slice(0,5)?.map(({name, weight}:AudienceItem):JSX.Element=>{
						return (
							<div key={name} className="flex align-center">
								<div className="w-halfrem h-halfrem br-50 lemon-yellow mr"></div>
								<BoxTitle>{name}</BoxTitle>
							</div>
						);
					})}
				</div>

			</div>

			<div className="neutral-800 p-20px mb-2 br">
				<BoxTitle fontSize={'16px'}>U.S. Hotspots</BoxTitle>
				<p className="f-12px t-neutral-300 f-600 mt-half max-w-600px lh-150 f-urbanist">We need a short description on what “U.S. HotSpots” are. Shouldn’t be too long, maybe just a couple of sentences bc we need context here. </p>

				<div className="grid2">
					<div className="flex align-center ">
						<div className="phor-2 w-100 grid2">
							<div>
								{audience?.audienceState && audience?.audienceState?.length > 0 && audience?.audienceState?.slice(0,3)?.map(({name, weight}:AudienceItem, index:number):JSX.Element=>{
									return (
										<div key={name} className="mb col-gap">
											<BoxTitle>{index+1}. {name}</BoxTitle>
											{/*<BoxTitle>{(weight*100).toFixed(1)} %</BoxTitle>*/}
										</div>
									);
								})}
							</div>
							<div>
								{audience?.audienceState && audience?.audienceState?.length > 0 && audience?.audienceState?.slice(3,6)?.map(({name, weight}:AudienceItem, index:number):JSX.Element=>{
									return (
										<div key={name} className="mb col-gap">
											<BoxTitle>{index+4}. {name}</BoxTitle>
											{/*<BoxTitle>{(weight*100).toFixed(1)} %</BoxTitle>*/}
										</div>
									);
								})}
							</div>
						</div>
					</div>
					<MapUsa states={audience?.audienceState?.slice(0,6)} />
				</div>

				<div className="grid5 mb col-gap phor-3">
					{audience?.audienceCity && audience?.audienceCity?.length > 0 && audience?.audienceCity?.filter((e:AudienceItem):boolean=>e?.country?.name==='United States').slice(0,5)?.map(({name, weight}:AudienceItem):JSX.Element=>{
						return (
							<div key={name} className="flex align-center">
								<div className="w-halfrem h-halfrem br-50 lemon-yellow mr"></div>
								<BoxTitle>{name}</BoxTitle>
							</div>
						);
					})}
				</div>

			</div>

		</div>
	);
}