import {color} from 'styles/colors';
import styled from 'styled-components';

interface RangeStyleProps{
	size?:string;
	$active?:boolean;
	steps:number;
}

export const StepsWrapper:any=styled.div`
  width:  ${({size}:RangeStyleProps):string=>size || 'fit-content'};

  ${({size,steps}:RangeStyleProps):string=>size ? `
  	width:${size};
  	display:grid;
  	grid-template-columns: repeat(${steps}, minmax(0, 1fr));
  	column-gap: 0.3rem;
  ` : `
		width:fit-content;
  		display:flex;
  		justify-content: center;
  		column-gap: 0.3rem
  `}
`;

export const Step:any=styled.div`
  	width: ${({size}:RangeStyleProps):string=>size ? '100%' : '20px'};
  	height: 3px !important;
	display: flex;
	border-radius: 3.5px;
  	background-color: ${({$active}:RangeStyleProps):string=>$active ? color.get('primary') : color.get('white')};
	
`;