import styled from 'styled-components';
import {color} from 'styles/colors';
import {mediaQuery} from '../../styles/mediaQueries';

interface ContainerProps {
	size:string
	image:string
}

interface ImageOptionProps {
	active:boolean
}


export const GalleryContainer:any=styled.div`
  position: relative;
  min-width: 200px;
  width: 100%;
  max-width: ${({size}:ContainerProps):string=>size};
  aspect-ratio: 1;
 
  ${mediaQuery('mobile')}{
	max-width: 100%;
    
    max-height: 360px;
  }
`;

export const Image:any=styled.div`
  width: 100%;
  height: 70%;
  background-image: ${ ({image}:ContainerProps):string=>`url(${image})`};
  background-size: cover;
  background-position: center;
  border-radius: 7px ;
  >div{
	width: 100%;
	height: 100%;
  	padding: 0.75rem 1rem;
	display: flex;
	flex-direction: column-reverse;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgb(0, 0, 0, 0.7) 90%);
  }
`;

export const ImagePicker:any =styled.div`
  display: flex;
  gap: 0.3rem;
  display: none;
`;

export const ImageOption:any=styled.div`
  width: 7.44px;
  height: 7.44px;
  border-radius: 8.68104px;
  background: ${ ({active}:ImageOptionProps):string=>active? color.get('primary'):'white'};
  cursor: pointer;
`;

export const GalleryName:any=styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
`;