import styled from 'styled-components';

export const LogoutContainer: any = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  cursor: pointer;

  font-weight: 600;
  font-size: 16px;
  color:white;
`;
