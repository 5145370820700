import React from 'react';
import {Location, Navigate, NavigateFunction, Outlet, useLocation, useNavigate} from 'react-router-dom';
import { SecurityRender } from './SecurityRender';

export interface AuthRenderProps{
	children?:JSX.Element;
}

export const Security = (): JSX.Element => {
	const { pathname }: Location = useLocation();
	const navigate : NavigateFunction = useNavigate();
	const setTab = (tab: string): void => {
		navigate(`/settings/security/${tab}`);
	};


	let path:string[]=pathname.split('/');
	if(path.length<4 || !path[3])
		return <Navigate to={'email'}/>;
	const tab:string = pathname.split('/')[3];
	return (
		<SecurityRender tab={tab} setTab={setTab}>
			<Outlet />
		</SecurityRender>
	);
};
