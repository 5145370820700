import {ErrorsHook, ErrorsMap, FieldErrors, FormField} from './interfaces';
import {MutableRefObject, useRef} from 'react';

export type fieldErrors ={
	required?:boolean
	pattern?:boolean
	validate?:boolean
};


export const useErrors=():ErrorsHook=>{

	const errors:MutableRefObject<ErrorsMap> =  useRef(new Map<string, fieldErrors|string>());

	const updateErrors=(fieldName:string,err:any):void=> {
		let newMap:ErrorsMap=new Map(errors.current);
		newMap.set(fieldName, err);
		errors.current = newMap;
	};

	const fieldError = (field:FormField,data:any):FieldErrors|null=>{
		let fieldError:FieldErrors={};
		if(field.required && field.array && data[field.name].length<1){
			fieldError.required= true;
		}
		if(field.required && !data[field.name]){
			fieldError.required= true;
		}else if(field.validate && !data[field.name]){
			fieldError.validate= true;
		}
		if(field.pattern && !field.pattern.test(data[field.name])){
			fieldError.pattern=true;
		}
		if(!Object.entries(fieldError).length){
			return null;
		}
		return fieldError;
	};

	const watchFieldErrors=(field:FormField,data:any):void=>{
		setFieldError(field,data);
	};

	const setFieldError=(field:FormField,data:any):void=>{
		let CurrentFieldErrors:fieldErrors = fieldError(field,data);
		if(CurrentFieldErrors){
			updateErrors(field.name, CurrentFieldErrors);
		}
		else{
			errors.current.delete(field.name);
		}

	};

	const getFormErrors=(fields:FormField[],data:any):ErrorsMap=>{

		fields.forEach((field:FormField):void=> {
			setFieldError(field,data);
		});

		return errors.current;
	};

	const setSubmitError=(error:Error):ErrorsMap=> {
		if(error){
			console.log({
				error,message:error.message
			});
			let messageError:string=error.message;
			if(messageError.includes('E11000')){
				messageError='The email already exists. Please use a different email.';
			}
			updateErrors('submitError',messageError);
		}
		else{
			errors.current.delete('submitError');
		}
		return errors.current;
	};
	return {errors:errors.current,watchFieldErrors,getFormErrors,setFieldError,setSubmitError};
};

