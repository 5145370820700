import React, {ReactNode} from 'react';
import {SectionUi} from '../SectionUi';

type ArticlesGridProps = {
    title: string
    subtitle?: string
    link?: string
	columns: number
    children?: ReactNode;
};
export const ArticlesGrid = ({ title,subtitle,link,columns, children}:ArticlesGridProps):JSX.Element => {
	return (
		<SectionUi
			title={title}
			description={subtitle}
			link={link &&{
				path: link || '',
				label: 'View All'
			}}>
			<div className={`w-100 grid${columns} col-gap mt row-gap-2`}>
				{children}
			</div>
		</SectionUi>
	);
};