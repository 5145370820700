import React from 'react';

export const TalkIcon=():JSX.Element=>
	<svg
		width={55}
		height={55}
		viewBox="0 0 55 55"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M28.5 13C21.0564 13 15 19.0564 15 26.5C15 29.0954 15.7273 31.5878 17.106 33.736L15.0607 38.8424C14.9359 39.1562 15.0084 39.514 15.2481 39.7536C15.4084 39.9139 15.6244 40 15.8438 40C15.9501 40 16.0547 39.9797 16.1576 39.9392L21.264 37.894C23.4122 39.2727 25.9046 40 28.5 40C35.9436 40 42 33.9436 42 26.5C42 19.0564 35.9436 13 28.5 13ZM28.5 38.3125C26.0936 38.3125 23.7902 37.6004 21.8411 36.2521C21.6994 36.1525 21.5306 36.1019 21.3619 36.1019C21.2556 36.1019 21.1493 36.1221 21.048 36.1626L17.3591 37.6409L18.8374 33.952C18.942 33.6888 18.9082 33.3901 18.7479 33.1589C17.3996 31.2098 16.6875 28.9064 16.6875 26.5C16.6875 19.9863 21.9863 14.6875 28.5 14.6875C35.0138 14.6875 40.3125 19.9863 40.3125 26.5C40.3125 33.0138 35.0138 38.3125 28.5 38.3125Z"
			fill="white"
		/>
		<rect x="0.5" y="0.5" width={54} height={54} rx="19.5" stroke="white"/>
	</svg>
;
