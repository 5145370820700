import React from 'react';
import {IconProps} from '../interfaces';

export const DeleteTagIcon=({size=12,color}:IconProps):JSX.Element=>{
	return <svg
		width={size}
		height={size}
		viewBox="0 0 12 12"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M9 9L6 6L9 3"
			stroke={color||'#FF5656'}
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M3 9L6 6L3 3"
			stroke={color||'#FF5656'}
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>;
};
