import styled from 'styled-components';

interface CircleProps {
	color?:string
	size?:string
	border?:string
}

interface IconDataProps {
	centered?:boolean
}

export const AlertContainer:any = styled.div`
	background: #111111;
  	border-radius: 12px;
`;

export const AlertBox:any = styled.div`
	background: #191B1E;
  	border-radius: 12px;
  	padding: 1rem;
  	display: flex;
  	flex-direction: column;
  	h1{
	  font-size: 40px;
	}
  	h4{
	  font-size: 14px;
	  text-transform: uppercase;
	  margin-bottom: 12px;
	}
  	p {
	  font-size: 14px;
	  line-height: 150%;
	  color: #838383;
	  margin-bottom: 1rem;
      white-space: pre-wrap;
	}
  	p:last-child{
	  margin-bottom: 0;
	}
`;

export const TalentInfo:any = styled.div`
	position: absolute;
  	left: 30px;
  	top: 0;
  	bottom: 0;
  	display: flex;
  	flex-direction: column;
	justify-content: center;
  	h3 {
	  font-size: 40px;
	  line-height: 100%;
	  margin-bottom: 1rem;
	}
  	p {
	  font-size: 14px;
	  line-height: 180%;
	}
`;

export const Circle:any = styled.div`
	width: 2rem;
  	height: 2rem;
  	background: #000000;
  	border-radius: 50%;
  	display: flex;
  	justify-content: center;
  	align-items: center;
  	box-shadow: 0px 2px 10px rgba(0,0,0,0.3);
`;

export const IconCircle:any = styled.div<CircleProps>`
	border: ${(p:CircleProps):string => p.border || '4px solid #FF5656'};
  	border-radius: 50%;
  	display: flex;
  	align-items: center;
  	justify-content: center;
  	margin-bottom: 0.5rem;
  	width: ${(p:CircleProps):string => p.size || '50px'};
  	height: ${(p:CircleProps):string => p.size || '50px'};
  	background: ${(p:CircleProps):string => p.color || ''};
  	box-shadow: 2px 2px 6px rgba(0,0,0,0.3);
`;

export const IconData:any = styled.div<IconDataProps>`
  	text-align: ${(p:IconDataProps):string => p.centered ? 'center' : 'left'};
	display: flex;
  	flex-direction: column;
  	align-items: ${(p:IconDataProps):string => p.centered ? 'center' : 'start'};;
  	margin-right: ${(p:IconDataProps):string => p.centered ? '0' : '2rem'};
  	h6{
	  font-size: 10px;
	  font-weight: bold;
	  text-transform: uppercase;
	}
	p{
	  font-size: 14px;
	  font-weight: 400;
	}	
`;

export const AlertHero:any = styled.div`
	position: relative;
`;

export const AlertHeroData:any = styled.div`
  	top:0;
  	left: 0;
  	bottom: 0;
  	right: 0;
  	width: 100%;
  	height: 100%;
  	display: flex;
  	flex-direction: column;
  	padding: 3rem;
`;

export const AlertHeroIntro:any =styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  max-width: 500px;
  h1{
	font-size: 40px;
	margin-bottom: 0.5rem;
  }
  label{
	font-size: 10px;
  }
  h6{
	color:#FF5656;
	font-size: 14px;
	text-transform: uppercase;
    margin-bottom: 0.5rem;
  }
  p{
	font-size: 14px;
	line-height: 180%;
  }
`;

export const AlertActions:any = styled.div`
  	top:3rem;
  	right: 3rem;
`;

export  const AlertSocial:any = styled.div`
	position: absolute;
  	bottom: 3rem;
  	right: 3rem;
  	h2{
	  margin-bottom: -0.3rem;
	}
`;