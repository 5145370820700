import React from 'react';
import {Button} from 'components/ui';

interface AlertsHeaderProps {
	action:()=>void
}

export const AlertsHeader =({action}:AlertsHeaderProps):JSX.Element=>{


	return <div className={'mb-2'}>
		<Button
			size={'small'}
			variant={'primary'}
			className={' ml-2'}
			label={'+ new alert'}
			onClick={action}
		/>
	</div>;
};