import React from 'react';
import {AyrShareInstagramData, ChartItem, ChartItemUI, SocialCardData} from 'interfaces';
import AudienceAyrShareRender from './AudienceAyrShareRender';
import {color} from 'styles/colors';

interface AudienceAyrShareProps {
	instagramAyrShare:AyrShareInstagramData
	socialCards:{
		data:any
		loading:boolean
		error:any
	}
}

const gendersOptions:string[] =['F','M','O'];
const ageOptions:string[] =['18-24','25-34','35-44','45-54','55-64','65+'];


export const AudienceAyrShare =({instagramAyrShare, socialCards}:AudienceAyrShareProps):JSX.Element=>{
	const {followersCount,audienceGenderAge,audienceCountry,audienceCity}:AyrShareInstagramData =instagramAyrShare;
	const GenderColors:string[]=[color.get('primary'),color.get('success-100'),color.get('purple')];
	const genders:ChartItem[]=[
		{label:'Women',value:0},
		{label:'Men',value:0},
		{label:'Other',value:0}
	];
	const ages:ChartItem[]=[
		{label:'18-24',value:0},
		{label:'25-34',value:0},
		{label:'35-44',value:0},
		{label:'45-54',value:0},
		{label:'55-64',value:0},
		{label:'65+',value:0}
	];
	let totalAudience:number=0;
	for (const genderAge in audienceGenderAge){
		let genderIndex:number;
		let ageIndex:number;
		let currentValue:number=audienceGenderAge[genderAge];
		if(genderAge.includes('F')) genderIndex=gendersOptions.indexOf('F');
		if(genderAge.includes('M')) genderIndex=gendersOptions.indexOf('M');
		if(genderAge.includes('U')) genderIndex=gendersOptions.indexOf('O');

		if(genderAge.includes('13-17')) ageIndex=ageOptions.indexOf('18-24');
		if(genderAge.includes('18-24')) ageIndex=ageOptions.indexOf('18-24');
		if(genderAge.includes('25-34')) ageIndex=ageOptions.indexOf('25-34');
		if(genderAge.includes('35-44')) ageIndex=ageOptions.indexOf('35-44');
		if(genderAge.includes('45-54')) ageIndex=ageOptions.indexOf('45-54');
		if(genderAge.includes('55-64')) ageIndex=ageOptions.indexOf('55-64');
		if(genderAge.includes('65+')) ageIndex=ageOptions.indexOf('65+');

		genders[genderIndex].value+=currentValue;
		ages[ageIndex].value+=currentValue;
		totalAudience+=currentValue;
	}

	const gendersChart:ChartItemUI[]=genders.map((gender:ChartItem,indx:number):ChartItemUI=>({
		...gender,color:GenderColors[indx],id:gender.label,
		value:gender.value/totalAudience*100
	}));

	//countries
	const countriesChart:ChartItem[]=[];
	for (const country in audienceCountry){
		countriesChart.push({
			label:country.toLowerCase(),
			value:parseFloat((audienceCountry[country]/totalAudience*100).toFixed(2))
		});
	}


	//Cities
	const citiesChart:ChartItem[]=[];
	for (const city in audienceCity){
		citiesChart.push({
			label:city,
			value:parseFloat((audienceCity[city]/totalAudience*100).toFixed(2))
		});
	}

	// total interactions
	const socialCardsArray:SocialCardData[] = [socialCards.data?.instagram];
	let totalInteractions: ChartItem[] = socialCardsArray
	.map((card:SocialCardData):ChartItem=>({
		label:'---',
		value:card.comments+card.likes
	}));
	return <AudienceAyrShareRender
		followersCount={followersCount}
		totalInteractions={totalInteractions}
		totalAudience={totalAudience}
		gendersChart={gendersChart}
		agesChart={ages}
		countries={countriesChart}
		cities={citiesChart}
	/>;
};