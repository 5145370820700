import styled from 'styled-components';
import {Card} from 'components';
import {mediaQuery} from 'styles/mediaQueries';


export const PicturesContainer:any=styled.div`
	width: 100%;
  aspect-ratio: 1/1;
  display: grid;
  grid-template-columns: 1fr 1fr ;
  column-gap: 1rem;
`;

export const StyledGender:any=styled.div`
  width: 80px;
  margin-bottom: 1rem;
  margin-left: 2rem;
  >p{
	margin-bottom: 0.2rem;
	color:${({color}:any):string=>color};
    font-weight: 700;
    font-size: 19.726px;
    line-height: 24px;
    text-transform: capitalize;
  }
`;

export const AudienceSection:any=styled(Card)`
	${mediaQuery('tablet')}{
	  background-color: black;
	  border:none;
	  padding-right: 0;
	  padding-left: 0;
	}
`;

export const AudienceWrapper:any=styled.div`
	grid-gap: 1.5rem;
`;