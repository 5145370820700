import React, {MutableRefObject, useEffect} from 'react';
import {Picture} from 'stylesOpus/layout';
import styled from 'styled-components';
import {NavigateFunction, useNavigate} from 'react-router-dom';

type CarouselPictureProps = {
    src: string;
	path?:string;
    selectedImageId: string | number;
    id: string | number;
	label?:string;
	description?:string;
	labelColor?:string;
    onSetActive:(id:number|string,rect:DOMRect)=>void;
}


const CarouselImage:any = styled(Picture)`
    width: 100%;
    height: 100%;
  	border-radius: 0;
`;

const Label:any = styled.p<{$color:string}>`
	color: ${({$color}:any):string => $color};
	font-size: 22px;
	font-weight: 800;
	line-height: normal;
	letter-spacing: 0.02875rem;
  	font-family: "Urbanist", sans-serif;
`;

export const CarouselPicture = ({src,path,selectedImageId,id,onSetActive,label,labelColor='#fff', description}: CarouselPictureProps): JSX.Element => {
	const navigate :NavigateFunction = useNavigate();

	const pictureRef:MutableRefObject<HTMLDivElement> = React.useRef<HTMLImageElement>(null);
	let rect:DOMRect ;


	useEffect(():void => {
		if (pictureRef.current){
			rect = pictureRef.current.getBoundingClientRect();
		}
	}, [pictureRef.current]);

	useEffect(():void => {
		if(selectedImageId=== id) {
			rect = pictureRef.current.getBoundingClientRect();
			onSetActive(id, rect);
		}
	}, [selectedImageId]);
	return (
		<div className={'relative cursor-pointer'} onClick={():void=>navigate(path)}>
			<CarouselImage
				ref={pictureRef}
				key={src}
				src={src}
	
			/>
			{label &&
				<div className="bottom-0 left-0 absolute w-100">
					<div className="absolute w-100 h-100 shadow-bar" />
					<div className="pver-4 phor-2 max-w-50 relative">
						<div className="mb">
							<Label $color={labelColor ? labelColor : 'white'}>{label}</Label>
						</div>
						{description &&
							<div className="f-14px f-urbanist">
								{description} ... <a className="f-bold">Read More</a>
							</div>
						}
					</div>
				</div>
			}

		</div>
	);
};