import React from 'react';
import {BarchartWrapper} from './styles';
import {Dataset} from 'interfaces';
import {Bars} from '../charts';


export interface BarChartRenderProps {
	value:string
	title:string
	labels:string[]
	dataset:Dataset
}

export const TrackingGraphRender= ({value,title,labels,dataset}:BarChartRenderProps):JSX.Element=>{
	return <BarchartWrapper>
		<h4>{value}</h4>
		<p>{title}</p>
		<Bars
			labels={labels}
			dataset={dataset}
		/>
	</BarchartWrapper>;
};