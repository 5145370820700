import styled, {keyframes} from 'styled-components';
import {color} from 'styles/colors';

const percentageAnimation:any=keyframes`
  0% {
    stroke-dasharray: 0 100;
  }
`;

type PercentageProps={
	$color?:string;
	$strokeWidth?:string;
}

export interface RoundImageStyleProps{
	size:string;
}

export const PercentageSVG:any=styled.svg<{size:string}>`
	width:${({size}:RoundImageStyleProps):string=>size};
	height:${({size}:RoundImageStyleProps):string=>size};
`;

export const PercentageBackground:any=styled.path`
  stroke: ${color.get('neutral-400')};
  fill: none;
  stroke-width:${({strokeWidth}:any):string=>strokeWidth};
`;

export const Percentage:any=styled.path<PercentageProps>`
  fill: none;
  stroke: ${({$color}:any):string=>$color ? $color : color.get('primary')};
  stroke-width:${({$strokeWidth}:any):string=>$strokeWidth};
  stroke-linecap:round;
  animation: ${percentageAnimation} 1s ease-out forwards;
`;

export const PercentageValue:any=styled.text`
  inline-size: 200px;

  fill: #fff;
  font-size: ${({fontSize}:any):string=>fontSize};
  font-weight: 700;
  text-anchor: middle;
  dominant-baseline: central;

`;

export const Foreign:any = styled.foreignObject`

  width: 100%;
  height: 100%;
  >div{
    width: 100%;
    height: 100%;
    display: flex;
				flex-direction: column;
   	justify-content: center;
    align-items: center;
	font-weight: 700;
	  >p:nth-child(2){
		text-align: center;
		width: 15px;
        overflow-wrap: break-word;
		font-size: 2.5px;
		word-wrap: break-word;

	  }
  }
`;
