import React, { useEffect, useState} from 'react';
import ProfileRender from './ProfileRender';
import {Outlet, Location, useLocation, NavigateFunction, useNavigate, Params, useParams} from 'react-router-dom';
import {fakeItems} from 'tools/MuckData/items';
import {serpapiQuery} from 'actions/socialActions';
import {SerpapiObj, User} from 'interfaces';
import {QueryResult, QueryTuple, useLazyQuery, useQuery} from '@apollo/client';
import {query} from '../../actions/queries';

export interface ProfileProps {
	user:User
	ownProfile?:boolean
	children?:JSX.Element;
}

export interface ProfileContext {
	user:User
	instagramAyrShare:any
	feed:SerpapiObj|undefined,
	socialCards:{
		data:any
		loading:boolean
		error:any
	}
}

export const Profile=({children,user,ownProfile=true}:ProfileProps):JSX.Element=>{
	const {opportunityId}:Params=useParams();
	const {pathname} :Location=useLocation();
	const navigate:NavigateFunction=useNavigate();

	const [feed,setFeed]=useState<SerpapiObj>(undefined);
	const [instagramAyrShare,setInstagramAyrShare]=useState<any>(undefined);

	let pathArray:string[]=pathname.split('/');

	const {data:socialInfoData}:QueryResult=
		useQuery(query('socialInfoByUserFindMany'));

	const {data:socialCardsData,loading:socialCardsLoading,error:socialCardsError}:QueryResult=useQuery(query('socialCardFindOne'),{
		variables:{user:user._id}
	});

	const [geInstagramAyrShareData]:QueryTuple<any, any> =
		useLazyQuery(query('instagramAudienceFindOne'));

	useEffect(():void=>{
		if (pathArray.length<3 || !pathArray[2]){
			navigate('/profile/audience');
		}
	},[]);

	useEffect(():void=>{
		if(socialInfoData && socialInfoData.socialInfoByUserFindMany && socialInfoData.socialInfoByUserFindMany.length>0)
			serpapiQuery(socialInfoData.socialInfoByUserFindMany[0].url).then((res:any):void=>{
				setFeed(res);
			});
		if(socialInfoData){
			const {socialInfoByUserFindMany}:any=socialInfoData;
			const infoAyrShare:any =socialInfoByUserFindMany.find(({identifier}:any):boolean=>identifier==='ayrshare');
			if(infoAyrShare){
				geInstagramAyrShareData({variables:{user:user._id}})
				.then(({data}:QueryResult):void=>setInstagramAyrShare(data.instagramAudienceFindOne));
			}
		}
	},[socialInfoData]);

	const profileContext:ProfileContext={
		feed,
		user,
		instagramAyrShare,
		socialCards:{
			data:socialCardsData?.socialCardFindOne,
			loading:socialCardsLoading,
			error:socialCardsError
		}
	};
	if (!user){return ;}
	let render:JSX.Element=children || <Outlet context={profileContext}/>;
	return <ProfileRender
		user={user}
		ownProfile={ownProfile}
		opportunityId={opportunityId}
		tags={fakeItems}
		path={pathname}
		baseUrl={ownProfile? pathname.split('/')[1] : `campaign/${opportunityId}/matches/talent-profile/${user?._id}`}
	>
		{render}
	</ProfileRender>;
};
