import React, {ChangeEvent} from 'react';
import styled from 'styled-components';
import {CheckIcon, NotificationsIcon} from 'icons';
import {buttonStyles} from 'styles/ui';

interface FileInputRenderProps {
	file?:File
	defaultFile?:string
	label?:string
	accept?:string
	onChange?:(e:ChangeEvent<HTMLInputElement>)=>void
}

const Container:any=styled.div`
 	${buttonStyles};
	display: flex;
	align-items: center;
	justify-content: center;
	height: auto;
	width: fit-content;
	padding: 0.5rem 1.5rem;
`;

const Label:any=styled.label`
  display: flex;
	width: fit-content;
  align-items: center;
    cursor: pointer;
	font-family: Urbanist;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 800;
    >input{
      display: none;
    }
`;

export const FileRender = ({label,defaultFile,file,accept,onChange}:FileInputRenderProps):JSX.Element => {

	return(
		<Container variant ='secondary-white' >
			<Label >
				<input
					type='file'
					accept={accept}
					onChange={onChange}
				/>
				{label}
				{defaultFile || file && <div className={'ml'}>
					<CheckIcon/>
				</div>}
			</Label>
		</Container>
	);
};
