
export const abbreviateMount=(mount:number|string,fixedValue:number=1):string=>{
	if(!mount && mount !== 0)
		return '';
	let value:number=typeof mount === 'string'? parseFloat(mount): mount;
	let suffix:string='';
	if(value/1000>0.5 && value/1000<=950) {
		suffix = 'K';
		value/=1000;
		return `${value.toFixed(0)}${suffix}`;
	}

	if(value/1000000>0.95) {
		suffix = 'M';
		value/=1000000;
		return `${value.toFixed(fixedValue)}${suffix}`;
	}
	return value.toFixed(fixedValue);
};