import React from 'react';

interface IconProps {
	size?: number
	color?: string
}

export const ArrowRightIcon=({size, color ='#fff'}:IconProps):JSX.Element=>
	<svg
		width={size || 24}
		height={size || 24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M9 18l6-6-6-6"
			stroke={color}
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
;
