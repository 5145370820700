import React from 'react';
import {QueryResult, useQuery} from '@apollo/client';
import {query} from 'actions/queries';
import {Item, ItemApi} from 'interfaces';
import OnboardingContextProvider from 'context/onboardingContext';
import {InterestRender} from './InterestsRender';
import {useForm} from 'hooks/useForm/useForm';
import {FormField, FormHook} from 'hooks/useForm/interfaces';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import {useModal} from 'hooks';
import {ModalHook} from 'hooks/useModal';

export const Interests=():JSX.Element=>{
	const navigate:NavigateFunction=useNavigate();
	const cancelModal: ModalHook = useModal(false);
	const {data:interestData}:QueryResult=useQuery(query('interestFindMany'));
	const interestOptions : Item[] = interestData?.interestFindMany.map((i:ItemApi):Item=>({
		...i, id:i._id
	})) || [];
	// const interestOptions : Item[] = [];

	const formFields: FormField[] = [
		{name: 'interests', required: true }
	];

	const onSubmit = (values: any):void => {
		console.log({values});
		navigate('/dashboard');
	};

	const {data,errors,loading, handleSubmitForm , ...rest} :FormHook= useForm(formFields,onSubmit);

	return(<OnboardingContextProvider steps={3}>
		<InterestRender
			interestsOptions={interestOptions}
			submitLoading={loading}
			errors={errors}
			formData={data}
			onSubmit={handleSubmitForm}
			{...rest}

			cancelModal={cancelModal}
		/>
	</OnboardingContextProvider>);
};