import React from 'react';
import Stepper from '../../OpprtunityCreate/Components/Stepper';
import {User} from 'interfaces';
import {Button, CircleButton} from 'components/ui';
import {ReactComponent as ChevronLeft} from 'styles/icons/chevron-left.svg';
import html2canvas from 'html2canvas';

type AlertDetailHeaderProps = {
    artist:User|null
}

export const AlertDetailHeader = ({artist}:AlertDetailHeaderProps): JSX.Element => {

	function takeSnapShot():void {
		// @ts-ignore
		html2canvas(document.querySelector('#alert', {backgroundColor: '#0E0F14'}), {scale: 2, useCORS: true, allowTaint: false})
		// @ts-ignore
		.then( (canvas9:any):any => {
			//document.body.appendChild(canvas9);
			// @ts-ignore
			let theimage9:any = canvas9.toDataURL('image/png');
			// @ts-ignore
			const element:any = document.createElement('a');
			element.download = 'profile.png';
			element.href = theimage9;
			element.click();
			element.remove();

		});
	}

	return (
		<header className={'flex justify-between align-center pt pb-2'}>
			<div className={'flex col-gap align-center'}>
				<CircleButton
					to={-1}
					size={'small'}
					variant={'dark-grey'}
				>
					<ChevronLeft/>

				</CircleButton>
				<Stepper
					links={['/alerts']}
					steps={[
						{id:'opportunities',label:'Opportunity Alert', identifier:''},
						{id:'-',label:`${artist.firstName} ${artist.lastName}` , identifier:'_'}
					]}
					current={'_'}
				/>

			</div>
			<div className={'flex col-gap'}>

				<Button
					size={'small'}
					variant={'dark-grey'}
					onClick={():void=>takeSnapShot()}
				>
					<div className={'flex col-gap'}>
						<p>Download Alert</p>
					</div>
				</Button>
			</div>

		</header>
	);
};