import React from 'react';

export const NewsIcon=():JSX.Element=>
	<svg
		width={24}
		height={24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19.5 0C17.567 0 16 1.567 16 3.5C16 5.433 17.567 7 19.5 7C21.433 7 23 5.433 23 3.5C23 1.567 21.433 0 19.5 0ZM14 1.21951C14 1.14574 14.0025 1.07254 14.0074 1H8C4.68629 1 2 3.68629 2 7V17C2 20.3137 4.68629 23 8 23H16C19.3137 23 22 20.3137 22 17V8.99263C21.9275 8.99752 21.8543 9 21.7805 9H20.2857V16.8902C20.2857 19.3755 18.271 21.3902 15.7857 21.3902H8.21428C5.729 21.3902 3.71429 19.3755 3.71429 16.8902V7.10975C3.71429 4.62447 5.729 2.60976 8.21429 2.60976H14V1.21951Z"
			fill="white"
		/>
	</svg>
;
