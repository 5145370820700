import React, {ChangeEvent, useEffect, useState} from 'react';
import {AppendableSelectRender} from './AppendableSelectRender';
import {listHandler, useListHandler} from 'hooks/useListHandler';
import { Item, ListInput, SelectProps} from 'interfaces';

interface AppendableProps extends ListInput {
    withAllOption?:boolean
}
export const AppendableSelect = ({options,onChange,name,value,withAllOption=false,placeholder,error,...props}:AppendableProps): JSX.Element => {
	const optionsWithAll:Item[]=[...options ];
	const isEmpty: boolean = ( !value || !value.length);
	const {handleUnselected,handleSelected,setSelectedItems,getSelectedItems, resetSelected}:listHandler=useListHandler({
		name, value,
		options: withAllOption? optionsWithAll : options,
		onChange
	});
	const [ isSelectShown, setIsSelectShown ] = useState((value?.length === 0));
	const handleSelect=(e:ChangeEvent<HTMLSelectElement>):void=>{
		if(withAllOption && e.target.value===''){
			setSelectedItems([]);
			return;
		}
		let item:Item=options.find((i:Item):boolean=>i.id===e.target.value);
		handleSelected(item);
		setIsSelectShown(false);
	};
	useEffect(():void=>{
		if( isEmpty){
			resetSelected();
		}
	},[value]);
	const selectProps:SelectProps={
		...props,
		// label: isEmpty ? props.label : '',
		value:getSelectedItems()[0]?.id || '',
		placeholder: placeholder || (withAllOption? 'All' : 'Select'),
		options: withAllOption? optionsWithAll : options,
		onChange:handleSelect
	};
	const selectedItems: Item[] = (withAllOption && !getSelectedItems().length) ?
		[{id:'',label:'All'}] : getSelectedItems();

	return (
		<AppendableSelectRender
			error={error}
			isSelectShown={isSelectShown}
			isEmpty={isEmpty}
			selectProps={selectProps}
			selectedItems={selectedItems}
			unSelect={handleUnselected}
			showSelect={():void=>setIsSelectShown(true)}
		/>
	);
};