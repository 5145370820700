import React from 'react';
import {Slider, Spinner} from 'components/ui';
import {Opportunity, OpportunityAudience, TPicture} from 'interfaces';
import {Card} from 'components';
import {ContentLayout} from 'containers';
import {OpportunityDetailHeader, OpportunityInfo} from './components';
import {BodyText, BoxTitle} from 'stylesOpus/typography';
import {Picture} from 'stylesOpus/layout';
import {StyledAnchorButton} from 'styles/ui';
import {FileIcon} from 'icons';
import {themeOpus} from 'stylesOpus/theme';


interface OppDetailProps {
	opportunityQuery : {
		loading: boolean
		error: any
		data: Opportunity
	}
	opportunityAudienceQuery : {
		loading: boolean
		error: any
		data: OpportunityAudience
	}
	deleteModal : {
		isOpen: boolean
		open: ()=>void
		close:()=>void
	}
	emailAlert: ()=>void
	deleteAction: ()=>void
	confirmPop:boolean
	setConfirmPop: (state:boolean)=>void
}

export default function OpportunityOldDetailOldRender({opportunityQuery,opportunityAudienceQuery}:OppDetailProps):JSX.Element{
	return (
		!opportunityQuery.loading && !opportunityAudienceQuery.loading?
			<ContentLayout
				header={<OpportunityDetailHeader opportunity={opportunityQuery.data}/>}
			>
				<div className="w-100 grid2 col-gap mobile:grid1">
					<div className={'flex direction-column row-gap h-100'}>

						{ opportunityQuery?.data?.image.length ?
							<Slider
								images={opportunityQuery.data.image.map((image: TPicture): string => image.url)}
							/>

							:
							<Picture src={ opportunityQuery?.data?.image&& opportunityQuery.data.image[0]?.url }/>
						}
						<Card >
							<div className={'w-100 flex justify-between align-center mb'}>
								<BoxTitle fontSize={'1.125rem'}>{opportunityQuery.data.name}</BoxTitle>
								{opportunityQuery.data.treatment?.url &&
									<StyledAnchorButton
										rounded
										width={'auto'}
										size={'small'}
										variant={'white'}
										href={`${opportunityQuery.data.treatment?.url}`}
										download
									>
										<div className={'flex col-gap'}>
											<FileIcon color={themeOpus.colors.grey_1000} />
											<p>View Brief</p>
										</div>
									</StyledAnchorButton>}
							</div>
							<BodyText>{ opportunityQuery.data.description }</BodyText>
						</Card>

						{opportunityQuery?.data?.requirements &&
							<Card>
								<BoxTitle fontSize={'0.875rem'}>Deliverables</BoxTitle>
								<BodyText>{ opportunityQuery.data.requirements }</BodyText>
							</Card>
						}
					</div>


					<section className={'flex direction-column'}>
						<Card
							className={'flex row-gap h-100 direction-column'}
							padding={'24px 16px '}
							theme={'opusV2'}
						>
							{ (!opportunityAudienceQuery.loading )?

								<OpportunityInfo opportunity={opportunityQuery.data} oppAudience={opportunityAudienceQuery.data}/>

								:
								<Spinner/>
							}
						</Card>
					</section>

				</div>
			</ContentLayout>
			:

			<div className={'w-100'}>
				<Spinner/>
			</div>
	);
}

