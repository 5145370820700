import React, {useContext, useEffect, useState} from 'react';
import {NavigateFunction, Params, useNavigate, useParams} from 'react-router-dom';
import {FormField, FormHook} from 'hooks/useForm/interfaces';
import {useForm} from 'hooks/useForm/useForm';
import {AdditionalInfoRender} from './AdditionalInfoRender';
import {MutationTuple, QueryResult, useMutation, useQuery} from '@apollo/client';
import {mutation} from 'actions/mutations';
import {query} from 'actions/queries';
import {UserContext, userContextInterface} from 'context/userContext';
import {CulturalAlert, TPicture} from 'interfaces';
import {uploadCulturalAlertPicture} from 'actions/restActions';

export const AdditionalInfo = ():JSX.Element => {

	const navigate:NavigateFunction=useNavigate();
	const {id}:Params = useParams();
	const {user}:userContextInterface=useContext(UserContext);
	// alert
	const {data:alertsData}:QueryResult =useQuery(query('culturalAlertFindMany'),
		{variables:{
			filter:{
				_id:id,
				user:user._id
			}
		}});


	// file
	const [picture,setPicture]=useState<File>(null);
	const handleChangePicture = async(file:File):Promise<void> => {
		setPicture(file);
	};
	const [updateAlert]:MutationTuple<any, any> =useMutation(mutation('culturalAlertUpdateOne'));

	const setAdditionalInfo=async(data:any):Promise<void>=>{
		console.log('submit',{data});

		await updateAlert({
			variables:{
				_id:id,
				record:{...data}
			}
		});

		picture && await uploadCulturalAlertPicture(picture,id);
		navigate('/company/alerts/');
	};
	const alertData:CulturalAlert= alertsData?.culturalAlertFindMany[0]||{};
	const  formFields:FormField[]=[
		// {name:'brandType'},
		{name:'additionalInfo',default:alertData?.additionalInfo}
	];

	const {loading,errors,resetForm,...rest}:FormHook=useForm(formFields,setAdditionalInfo);

	useEffect(():void=>{
		if(alertData)
			resetForm();
	},[]);
	const photos:TPicture[] = alertData?.photos?.length ? alertData.photos: null;

	return <AdditionalInfoRender
		defaultPhoto={photos?.length ? photos[0].url:null}
		submitLoading={loading}
		errors={errors}
		onSubmit={rest.handleSubmitForm}
		{...rest}
		onChangeFile={handleChangePicture}
	/>;

};