import React from 'react';
import {AlertBox, IconCircle, IconData} from 'components/alerts/styles';
import LocationIcon from 'icons/LocationIcon';
import AskIcon from 'icons/AskIcon';
import ExpirationIcon from 'icons/ExpirationIcon';
import {RoundImage} from 'components/ui';
import {CompanyProfile, CulturalAlert} from 'interfaces';

type DetailsProps = {
    alert:CulturalAlert
    company:CompanyProfile|null
    copies:{
        title:string
        expiration:string
        location:string
        estimatedAsk:string
    }
};

export const Details = ({alert,company,copies}:DetailsProps):JSX.Element => {
  
	return(
		<div className="w-100">
			<div className="w-100 grid2 col-gap">
				<AlertBox className="grows">
					<h6 className="mb">
						{copies.title}
					</h6>
					<p>{alert?.additionalInfo}</p>
				</AlertBox>

				<div className="flex direction-column">
					<AlertBox className="mb">
						<div className="flex grid3">
							{ (alert?.city || alert?.state) &&
                                <IconData centered={true}><IconCircle><LocationIcon size={18} /></IconCircle><h6>{copies.title}</h6>
                                </IconData>
							}
							{alert?.feeRange &&
                                <IconData centered={true}><IconCircle><AskIcon size={18} /></IconCircle><h6>{copies.estimatedAsk}</h6>
                                </IconData>
							}
							{copies.expiration &&
                                <IconData centered={true}><IconCircle><ExpirationIcon size={18} /></IconCircle><h6>{copies.expiration}</h6>
                                </IconData>
							}
						</div>
					</AlertBox>

					<AlertBox className='grows'>
						<div className="p-2 justify-center align-center flex direction-column">
							<RoundImage src={company?.logo?.url} size={'200px'}/>
							<h6 className="mt">{company?.name}</h6>
						</div>

					</AlertBox>
				</div>
			</div>

		</div>
	);
};