import styled from 'styled-components';

interface ChartLabelProps {
	color:string
}

export const BarLabel:any=styled.div`
  	display: flex;
  	align-items: center;
	>div{
	  width: 6px;
	  height: 6px;
	  aspect-ratio: 1/1;
	  border-radius: 100%;
      background: ${({color}:ChartLabelProps):string=>color};
	}
  >p{
	font-size: 12px;
	margin-left: 0.5rem;
  }
`;