import styled from 'styled-components';

export const SimpleChartWrapper:any=styled.div`
	margin: 0;
`;

export const ItemPercentage:any=styled.div`
  display: flex;
  align-items: center;
  padding: 0.2rem 0;
  >p{
	width: 4rem;
	text-align: end;
	margin-left: 0.5rem;
	font-size: 14px;
	  line-height: 17px;
	  font-weight: 800;
	}
`;

export const ItemPercentageWithLabel:any=styled.div`
  display: flex;
  align-items: center;
  padding: 0.2rem 0;
  
  >div:first-child{
	width: 80px;
  }
  
  >p{
	width: 4rem;
	text-align: end;
	margin-left: 0.5rem;
	font-size: 14px;
	  line-height: 17px;
	  font-weight: 800;
	}
`;

export const LabelsContainer:any=styled.div`
  display: flex;
  margin-top: 1rem;
  flex-wrap:wrap;
  >div{
	margin: 0.1rem 0.8rem 0.1rem 0;
  }
`;