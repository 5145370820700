import React, {useContext, useEffect} from 'react';
import ProfileEditRender from './ProfileEditRender';
import {Location, NavigateFunction, Outlet, useLocation, useNavigate} from 'react-router-dom';
import {UserContext} from 'context/userContext';
import {User, TalentProfile, UserType} from 'interfaces';
import {MutationFunction, MutationTuple, QueryResult, useMutation, useQuery} from '@apollo/client';
import {mutation} from 'actions/mutations';
import {query} from 'actions/queries';
import {enumToMap} from '../../tools/globalActions';
import {EditCompanyProfile} from '../../EditPRofile/Company';
import {Layout} from '../Layout';
import {Header} from '../../components';

export interface EditProfileContext {
	user:User
	profileData:TalentProfile
	updateProfile:MutationFunction
}

const userTypesMap:Map<string,any> = enumToMap(UserType);


export const ProfileEdit=():JSX.Element=>{
	const {user}:any=useContext(UserContext);
	const {pathname} :Location=useLocation();

	const {data:profileData,loading:profileLoading}:QueryResult=useQuery(query('usersTalentProfileFindOne'),{variables:{
		user:user._id
	}});
	const [updateProfile]:MutationTuple<MutationFunction, any> =useMutation(mutation('usersUpsertTalentProfile'), {refetchQueries:['usersTalentProfileFindOne']});
	let editContext:EditProfileContext={
		user,
		profileData:profileData?.usersTalentProfileFindOne,
		updateProfile
	};

	let pathArray:string[]=pathname.split('/');
	const navigate:NavigateFunction=useNavigate();

	useEffect(():void=>{
		if (pathArray.length<3 || !pathArray[2]){
			navigate('/profile-edit/profile');
		}
	},[]);


	if(profileLoading){
		return <h1>Loading...</h1>;
	}

	if(user.type._id === userTypesMap.get('Company') || user.type._id === userTypesMap.get('Agent')  ){
		return <EditCompanyProfile/>;
	}

	return <ProfileEditRender
		path={pathname}
	>
		<Outlet context={editContext}/>
	</ProfileEditRender>;
};