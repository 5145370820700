import styled from 'styled-components';
import {color} from 'styles/colors';
import {Link} from 'react-router-dom';
import {Button} from 'components/ui';

export const StyledTitle:any=styled.h6`
  margin-top: 1rem;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: ${color.get('neutral-400')};
`;

export const StyledDescription:any=styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
`;

export const StyledScore:any=styled.p`
  font-size: 11px;
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: ${color.get('primary')};
  width: 28px;
  height: 28px;
  border-radius: 50%;
  padding: 0.4rem 0.4rem;
  margin-top: 1rem;
`;

export const StyledPlus:any=styled(Link)`
  position: absolute;
  right: 8px;
  top: 8px;
  display: flex;
  width: 28px;
  height: 28px;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0 5.6px 8.4px rgba(0, 0, 0, 0.25);
  color: ${color.get('black')};
  font-weight: 600;
  border-radius: 100%;
`;

export const ShareButton:any= styled(Button)`
	width: 42px;
	height: 18px;
	position: absolute;
	padding :  0 ;
	top: 0.8rem;
	left: 0.75rem;
`;
