import styled from 'styled-components';

export type LayoutProps = {
    maxWidth?: string,

}

export const Content: any = styled.section<LayoutProps>`
    width: 100%;
    height: 100%;

    max-width: ${({maxWidth}:LayoutProps):string=>maxWidth || '100%'};
  display: grid;
  grid-template-areas:  
   "column content-header"
   "column content-main";
  
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  
`;

export const ContentHeader: any = styled.section`
    grid-area: content-header;
`;

export const ContentColumn: any = styled.section`
  margin-right: 1.25rem;
  grid-area: column;
`;

export const ContentMain: any = styled.section`
  grid-area: content-main;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
  
`;