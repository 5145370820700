import React from 'react';
import {Content} from './styles';
import {Opportunities} from '../../interfaces';
import {OpportunityCard} from '../../components';


export interface ProfileRenderProps{
	path:string
	talentOpportunities:Opportunities[]
	loading:boolean
}

export default function OpportunitiesRender({path,talentOpportunities,loading}:ProfileRenderProps):JSX.Element{
	return (
		<div className={'w-100'}>
			{!loading?<div className="w-100 grid3 mobile:grid1 col-gap-2 row-gap-2">
				{talentOpportunities?.map((op: Opportunities): JSX.Element =>
					<OpportunityCard key={op._id} opportunity={op}/>
				)}
			</div>
				:
				<h6>Loading...</h6>
			}
			{/*{!talentOpportunities.length && <h6>Void</h6>}*/}
		</div>

	);
}
