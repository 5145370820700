import React from 'react';

interface IconProps {
	size?: number
	color?: string
}


export const CaratDown=({size=24,color='white'}:IconProps):JSX.Element=>{
	
	return <svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M6 9L12 15L18 9"
			stroke={color}
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>;
};
