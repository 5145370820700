import React from 'react';

export const FacebookOutlineIcon=():JSX.Element=>
	<svg
		width={24}
		height={24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M17 3.12981V5.98558H15.3646C14.0833 5.98558 13.8438 6.6238 13.8438 7.54327V9.58774H16.8958L16.4896 12.7897H13.8438V21H10.6562V12.7897H8V9.58774H10.6562V7.22957C10.6562 4.49279 12.2708 3 14.625 3C15.75 3 16.7188 3.08654 17 3.12981Z"
			fill="white"
		/>
	</svg>
;
