import React, {useContext, useState} from 'react';
import {PhotoGalleryRender} from './PhotoGallery';
import {UserContext, userContextInterface} from 'context/userContext';

interface PhotoGalleryProps {
	images:string[]
}

export const PhotoGallery=({images}:PhotoGalleryProps):JSX.Element=>{
	const[currentImage,setCurrentImage]=useState<number>(0);

	const handleChangeImage=(imageIndex:number):void=>{
		setCurrentImage(imageIndex);
	};

	const {user}:userContextInterface=useContext(UserContext);
	return <PhotoGalleryRender
		name={`${user.firstName} ${user.lastName}`}
		images={images}
		currentImage={currentImage}
		setCurrentImage={handleChangeImage}
	/>;
};