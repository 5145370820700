import React from 'react';
import {InputProps} from '../../../interfaces';
import {color} from '../../../styles/colors';
import styled from 'styled-components';

export const StyledInput:any=styled.div`
	display:flex;
	align-items:center;
	:focus-within{
		border-color: ${color.get('primary')};
	}
	>label{
		font-size: 0.875rem;
		line-height: 150%;
		color: ${color.get('white')};
		
		margin-left: 0.75rem;
	}
	>input{
		position: relative;
		box-sizing: border-box;
		-webkit-appearance: none;
		width: 1.5rem;
		height: 1.5rem;
		border: 1px solid ${color.get('neutral-500')};
		border-radius: 50%;
		background-color:transparent ;
		:checked::before{
			position: absolute;
			width: 50%;
			height: 50%;
			top: 25%;
			left: 25%;
			border-radius: 50%;
			content: '';
			background-color: ${color.get('primary')};
		}
		:focus{
			
			outline: 2px solid  ${color.get('primary')};
		}
	}
`;

export default function RadioButtonRender({label,className,...props}:InputProps):JSX.Element{

	return (
		<StyledInput className={className}>
			<input type="radio" {...props}  />
			<label>{label}</label>
		</StyledInput>
	);
}
