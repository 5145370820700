import React from 'react';
import {ArrowLeft, OpusIcon} from 'icons';
import {color} from 'styles/colors';
import {Border, CardMainWrapper, Content} from './styles';

export interface CardMainProps {
	label:string
	children?:JSX.Element
}

export default function CardMainRender({label,children}:CardMainProps):JSX.Element {
	return <CardMainWrapper>
		<Border>
			<OpusIcon size={12} color={color.get('primary')}/>
			<p>{label}</p>
		</Border>
		<Content className={'grows'}>
			{children}
		</Content>
		<Border>
			<ArrowLeft/>
			<ArrowLeft/>
		</Border>
	</CardMainWrapper>;
}