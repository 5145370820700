import React from 'react';
import {AuthContent, LayoutContainer, VideoContainer} from './authLayout.styles';
import { ReactComponent as Logo } from 'styles/icons/logo_2_white.svg';

type AuthLayout = {
    children: React.ReactNode;
    videoUrl?:string
	direction?: 'row' | 'row-reverse'
}
export const AuthLayout = ({children,videoUrl='/assets/login_video.mp4',direction= 'row'}:AuthLayout): JSX.Element => {

	return (
		<LayoutContainer direction={direction}>
			<AuthContent className = 'content'>
				<div className={'w-100'}>
					<Logo width={70}/>
				</div>

				
				<div className={'grows flex direction-column align-center justify-center'}>
					{children}
				</div>
			</AuthContent>

			<VideoContainer>
				<video autoPlay muted loop>
					<source src={videoUrl } type="video/mp4"/>
				</video>
			</VideoContainer>

		</LayoutContainer>
	);
};