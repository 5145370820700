import React, {useState, FormEvent, useContext, useEffect} from 'react';
import VerifyRender from './VerifyRender';
import {useMutation} from '@apollo/client';
import {mutation} from 'actions/mutations';
import {UserContext} from 'context/userContext';
import {ErrorsMap} from 'hooks/useForm/interfaces';
import {UserType} from 'interfaces';

const navigateTo=(userType : UserType):string=>{
	const pathByUserType:any={
		'Regular':`/onboarding/link-social`,
		'Company':'/dashboard',
		'Agent':'/dashboard',
		'default':'/dashboard'
	};
	return pathByUserType[userType] || '/onboarding';
};
export const Verify=():JSX.Element=>{
	const {user, refresh}:any=useContext(UserContext);
	const [verificationCode,setVerificationCode]=useState<string>('');
	const [verified,setVerified]=useState<boolean>(user.verified);
	const [setCodeValidation]:any=useMutation(mutation('setCodeValidation'));
	// eslint-disable-next-line @typescript-eslint/typedef
	const [checkCodeValidation, {loading,error}]:any=useMutation(mutation('checkCodeValidation'));
	const [errors,setErrors]= useState<Map<string,string>>(new Map<string, string>());

	const onChangeCode=({name,value}:any):void=>{
		setVerificationCode(value);
	};

	const onSubmit= async(e:FormEvent):Promise<void>=>{
		e.preventDefault();
		try {
			await checkCodeValidation({
				variables:{
					codeVerification:verificationCode
				}});
			await refresh();
			setVerified(true);
		}
		catch (e) {
			setErrors(new Map(errors.set('network',e.message)));
		}
	};


	useEffect(():void => {
		console.log('send code');
		!verified && setCodeValidation();
	}, []);

	const ErrorsMap : ErrorsMap= new Map(errors);
	ErrorsMap.set('submitError',error?.message);
	return <VerifyRender
		user={user}
		submitLoading={loading}
		errors={errors}
		verified={verified}
		loading={false}
		nextPath={navigateTo(user.type.label)}
		onSubmit={onSubmit}
		onChangeInput={onChangeCode}
		sendCode={setCodeValidation}
	/>;
};
