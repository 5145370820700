import styled from 'styled-components';

export const ListName:any=styled.h2`
  margin-bottom: 0.8rem;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 18px;
`;

export const Campaign:any=styled.h2`
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  margin-right: 4px;
`;

export const HeaderTitle:any=styled.h2`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-right: 12px;
`;