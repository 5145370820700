import React, {useContext, useEffect, useState} from 'react';
import TalentDashBoardRender from './TalentDashBoardRender';
import {UserContext} from 'context/userContext';
import {QueryResult, QueryTuple, useLazyQuery, useQuery} from '@apollo/client';
import {query} from 'actions/queries';
import {getFeeds} from 'actions/strapiActions';
import {FeedType, Item, SerpapiObj} from 'interfaces';
import {Location, Outlet, useLocation, NavigateFunction, useNavigate} from 'react-router-dom';
import {serpapiQuery} from '../../actions/socialActions';
import {ProfileContext} from '../../containers/profile';

const socialNetworks:Item[]=[
	{id:'instagram',label:'instagram'},
	{id:'tiktok',label:'tiktok'},
	// {id:'twitter',label:'twitter'},
	{id:'youtube',label:'youtube'}
	// {id:'facebook',label:'facebook'}

];

export const TalentDashboard=():JSX.Element=>{
	const navigate:NavigateFunction=useNavigate();
	const {user}:any=useContext(UserContext);
	const [feeds,setFeeds]=useState<FeedType[]>();
	const [selectedSocialNetwork,setSelectedSocialNetwork]=useState<string>('instagram');
	const {pathname}:Location=useLocation();
	let pathArray:string[]=pathname.split('/');

	useEffect(():void=>{
		if (pathArray.length<3 || !pathArray[2]){
			navigate('/dashboard/opportunities');
		}
	},[pathname]);

	const {data:invitationsData}:QueryResult=useQuery(query('opportunityFindMany'),{
		variables:{
			filter:{
				talent:user._id,
				accepted:null
			},
			limit:9
		},
		fetchPolicy:'network-only'
	});




	// ********** Profile ********
	const [feed,setFeed]=useState<SerpapiObj>(undefined);
	const [instagramAyrShare,setInstagramAyrShare]=useState<any>(undefined);

	const {data:socialInfoData}:QueryResult=
		useQuery(query('socialInfoByUserFindMany'));

	const {data:socialCardsData,loading:socialCardsLoading,error:socialCardsError}:QueryResult=useQuery(query('socialCardFindOne'),{
		variables:{user:user._id}
	});

	// const [geInstagramAyrShareData]:QueryTuple<any, any> =
	// 	useLazyQuery(query('instagramAudienceFindOne'));

	useEffect(():void=>{
		if(socialInfoData && socialInfoData.socialInfoByUserFindMany && socialInfoData.socialInfoByUserFindMany.length>0)
			serpapiQuery(socialInfoData.socialInfoByUserFindMany[0].url).then((res:any):void=>{
				setFeed(res);
			});
		if(socialInfoData){
			const {socialInfoByUserFindMany}:any=socialInfoData;
			const infoAyrShare:any =socialInfoByUserFindMany.find(({identifier}:any):boolean=>identifier==='ayrshare');
			if(infoAyrShare){
				// geInstagramAyrShareData({variables:{user:user._id}})
				// .then(({data}:QueryResult):void=>setInstagramAyrShare(data.instagramAudienceFindOne))
				// ;
			}
		}
	},[socialInfoData]);

	const profileContext:ProfileContext={
		feed,
		user,
		instagramAyrShare,
		socialCards:{
			data:socialCardsData?.socialCardFindOne,
			loading:socialCardsLoading,
			error:socialCardsError
		}
	};


	// ********   Profile  *******
	useEffect(():void=>{
		getFeeds({
			populate:'*',
			sort:'createdAt:desc',
			'pagination[start]':0,
			'pagination[limit]':3
		})
		.then((res:any):void=> res.json())
		.then((res:any):void=>setFeeds(res.data));
	},[]);
	if (!user){return ;}

	return<TalentDashBoardRender
		user={user}
		//interests={fakeInterests}
		opportunities={invitationsData?.talentOpportunityFindMany || []}
		feeds={feeds||[]}
		socialNetworks={{
			selected:selectedSocialNetwork,
			options:socialNetworks,
			handleSelect:(value:string):void=>setSelectedSocialNetwork(value)
		}}
	>
		<Outlet context={profileContext}/>
	</TalentDashBoardRender>;

};
