import React, {useEffect, useState} from 'react';
import {FeedRender} from './FeedRender';
import {getFeeds} from '../../actions/strapiActions';
import {FeedType} from 'interfaces';

export const Feed = ():JSX.Element => {
	const fakeFeedInfoDownload:any = [
		{
			id: '1',
			url: 'https://static.politico.com/4c/01/739ff7e740638482750f52d60c1c/201015-ice-cube-getty-773.jpg',
			date: 'DEC',
			new: true
		},
		{
			id: '2',
			url: 'https://www.biography.com/.image/t_share/MTcyMzE3NDY5Mzk3MDM0NTIx/gettyimages-1201061980.jpg',
			date: 'NOV',
			new: false
		},
		{
			id: '3',
			url: 'https://static.politico.com/4c/01/739ff7e740638482750f52d60c1c/201015-ice-cube-getty-773.jpg',
			date: 'ABR',
			new: false
		}
	];
	const [feed,setFeeds] =useState<FeedType[]>([]);
	useEffect(():void=>{
		getFeeds({
			populate:'*',
			sort:'createdAt:desc'
		})
		.then((res:any):void=> res.json())
		.then((res:any):void=>setFeeds(res.data));
	},[]);

	return (
		<FeedRender feedInfo={feed||[]} feedDownload={fakeFeedInfoDownload} />
	);
};
