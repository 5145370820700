export interface TensorCategory {
	tensor: string
	iab: string
}

export const InterestsTensorMap:TensorCategory[] = [
	{
		tensor: 'Activewear',
		iab: 'Sporting Goods'
	},
	{
		tensor: 'Camera & Photography',
		iab: 'Consumer Electronics'
	},
	{
		tensor: 'Fitness & Yoga',
		iab: 'Recreation and Fitness Activities'
	},
	{
		tensor: 'Home Decor',
		iab: 'Home and Garden'
	},
	{
		tensor: 'Furniture & Garden',
		iab: 'Home and Garden'
	},
	{
		tensor: 'Restaurants',
		iab: 'Food and Beverage Services'
	},
	{
		tensor: 'Food & Grocery',
		iab: 'Food and Beverage Services'
	},
	{
		tensor: 'Toys',
		iab: 'Hobbies and Interests'
	},
	{
		tensor: 'Children & Baby',
		iab: 'Family and Parenting'
	},
	{
		tensor: 'Art & Design',
		iab: 'Fine art'
	},
	{
		tensor: 'Cars & Motorbikes',
		iab: 'Automotive'
	},
	{
		tensor: 'Friends, Family & Relationships',
		iab: 'Family and Relationships'
	},
	{
		tensor: 'Family & Relationships',
		iab: 'Family and Relationships'
	},
	{
		tensor: 'Jewellery & Watches',
		iab: 'Fashion and Style'
	},
	{
		tensor: 'Shopping & Retail',
		iab: 'Shopping'
	},
	{
		tensor: 'Travel, Tourism & Aviation',
		iab: 'Travel and Tourism'
	},
	{
		tensor: 'Beauty & Cosmetics',
		iab: 'Beauty Services'
	},
	{
		tensor: 'Clothes, Shoes, Handbags & Accessories',
		iab: 'Fashion and Style'
	},
	{
		tensor: 'Shoes',
		iab: 'Fashion and Style'
	},
	{
		tensor: 'Handbags & Accessories',
		iab: 'Fashion and Style'
	},
	{
		tensor: 'Gaming',
		iab: 'Video Gaming'
	},
	{
		tensor: 'Luxury Goods',
		iab: 'Fashion and Style'
	},
	{
		tensor: 'Sports',
		iab: 'Sports'
	},
	{
		tensor: 'Wedding',
		iab: 'Life Events'
	},
	{
		tensor: 'Beer, Wine & Spirits',
		iab: 'Food and Drink'
	},
	{
		tensor: 'Coffee, Tea & Beverages',
		iab: 'Food and Drink'
	},
	{
		tensor: 'Healthcare & Medicine',
		iab: 'Health and Medical Services'
	},
	{
		tensor: 'Music',
		iab: 'Arts and Entertainment'
	},
	{
		tensor: 'Television & Film',
		iab: 'Arts and Entertainment'
	},
	{
		tensor: 'Business & Careers',
		iab: 'Business and Finance'
	},
	{
		tensor: 'Electronics & Computers',
		iab: 'Technology and Computing'
	},
	{
		tensor: 'Healthy Lifestyle',
		iab: 'Healthy Living'
	},
	{
		tensor: 'Pets',
		iab: 'Pets'
	},
	{
		tensor: 'Tobacco & Smoking',
		iab: 'Lifestyle Choices'
	}
];




