import React, {useContext} from 'react';
import {QueryResult, useQuery} from '@apollo/client';
import {query} from 'actions/queries';
import {Params, useParams} from 'react-router-dom';
import {Sku} from 'interfaces';
import {ProductDetailRender} from './ProductDetailRender';
import {UserContext, userContextInterface} from 'context/userContext';
import {Spinner} from 'components/ui';

export const ProductDetail= ():JSX.Element=>{
	const {productId}:Params=useParams();
	const {user}:userContextInterface=useContext(UserContext);

	const{data:profileData, loading:profileLoading}:QueryResult=useQuery(query('usersCompanyProfileFindOne'),{
		variables:{user:user._id}
	});

	const{data:brandData, loading:brandLoading}:QueryResult=useQuery(query('brandFindMany'),{
		variables:{
			findBrandFilter: {_id:productId}
		}
	});

	const {data:skusData, loading:skusLoading}:QueryResult=useQuery(query('skuFindMany'),{
		variables:{
			findSkuFilter:{brand:productId}
		},
		fetchPolicy:'network-only'
	});

	if (profileLoading||brandLoading||skusLoading)
		return <Spinner/>;
	const skus:Sku[]=skusData?.skuFindMany||[];
	return <ProductDetailRender
		productId={productId}
		product={brandData?.brandFindMany[0]}
		profile={profileData.usersCompanyProfileFindOne}
		skus={skus}
	/>;
};