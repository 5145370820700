import React from 'react';
import TagRender from './TagRender';
import {TagProps} from 'interfaces';

export const Tag=({...props}:TagProps):JSX.Element=>{

	return <TagRender
		{...props}
	/>;
};
