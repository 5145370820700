import React from 'react';
import {PercentageSVG, PercentageBackground, Percentage, Foreign} from './styles';

export interface RoundPercentageProps{
	size?:string
	percentage:number
	label?:string
	insideStyle?:React.CSSProperties
	fixed?:number
	percentageIcon?:boolean

	strokeWidth?:string
	strokeColor?:string
}


export default function RoundPercentageRender({size='32px',fixed=1, insideStyle={fontSize:'10px'},strokeColor,label, percentage,strokeWidth='3', percentageIcon}:RoundPercentageProps):JSX.Element{
	return (

		<PercentageSVG size={size} viewBox="0 0 36 36">
			<PercentageBackground
				strokeWidth={''}
				d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
			/>
			<Percentage
				$strokeWidth={strokeWidth}
				$color={strokeColor}
				strokeDasharray={`${percentage}, 100`}
				d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
			/>
			<Foreign
				{...insideStyle}
			>
				<div className="flex align-center justify-center">
					<p>{percentage < 100 ? percentage.toFixed(fixed): percentage}{percentageIcon && '%'}</p>
					{label&&<p>{label}</p>}
				</div>
			</Foreign>
		</PercentageSVG>

	);
}
