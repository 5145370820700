import React, {useState} from 'react';
import styled from 'styled-components';
import {Card} from 'components';
import {CreateList} from './components/CreateList';
import {AddToList} from './components/AddToList';

interface ListModalProps{
    talentId:string
    close:()=>void
}

const StyledCard:any=styled(Card)`
	width: 540px;
`;

export const ListModal = ({talentId,close}:ListModalProps):JSX.Element=>{
	const [currentForm,setCurrentForm]=useState<string>('add');
	return <div className={'w-100 h-100 flex justify-center align-center' }>
		<StyledCard className={''}>
			{ currentForm==='add'?
				<AddToList
					talentId={talentId}
					close={close}
					goToCreate={():void=>setCurrentForm('create')}
				/>
				:
				<CreateList
					close={close}
					goToAdd={():void=>setCurrentForm('add')}
				/>
			}

		</StyledCard>
	</div>;
};