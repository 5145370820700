import React, { useContext} from 'react';
import {Navigate, Outlet} from 'react-router-dom';

import {UserContext, userContextInterface} from 'context/userContext';

export interface AuthRouteProps {
	children?:JSX.Element
}

const linkByUserType:any= {
	'Regular':'/dashboard',
	'Company':'/dashboard',
	'Agent':'/alerts'
};

export const NoAuthRoute = ({children}:AuthRouteProps):JSX.Element=>{
	const render:JSX.Element=children||<Outlet/>;

	const {user}:userContextInterface=useContext(UserContext);
	const redirectTo:string= linkByUserType[user?.type.label||'/dashboard'];
	return !user? render :<Navigate to={redirectTo} />;
};


