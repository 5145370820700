import {QueryResult, useQuery} from '@apollo/client';
import {query} from '../actions/queries';
import {CulturalAlert} from '../interfaces';

export type CulturalAlertResponse= {data:CulturalAlert,loading:boolean,error:string};

type WithCulturalAlertProps = {
    id: string
    children: (data:CulturalAlertResponse) => JSX.Element
};
export const WithCulturalAlert = ({id,children}:WithCulturalAlertProps):JSX.Element =>{
	const {data,loading, error}:QueryResult = useQuery(query('culturalAlertFindOne'), {
		variables: {filter: {_id: id}}
	});
	return children({data:data?.culturalAlertFindOne, loading, error:error?.message || ''});
};