import styled from 'styled-components';
import {Input,InputWrapper} from 'styles/ui';
import {color} from 'styles/colors';

export const TextAreaWrapper:any=styled(InputWrapper)`
  width: 100%;
  height: 180px;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  >div:first-child{
	width: 100%;
	flex-grow: 0;
    flex-direction: row;
	justify-content: space-between;
	font-size: 10px;
  }
  >div p{
    color:${color.get('neutral-400')}
  }
`;

export const TextBox:any=styled(Input)`
  resize: none;
  flex-grow: 1;
`;