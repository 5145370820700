import React, {ReactNode, useEffect} from 'react';
import {Spinner} from 'components/ui';
import {Carousel, FeaturedTalent} from 'components/complexComponents';
import {BodyText, BoxTitle, PageTitle} from 'stylesOpus/typography';
import {HotListContainer, MainContainer} from './hotListDetail.styles';
import {ReactComponent as CalendarIcon} from 'styles/icons/calendar.svg';
import {Pill} from '../../styles/styledComponents';
import {Section} from '../../styles/styledComponents/containers';
import {CardItem} from '../../components';
import {TArticle} from '../../components/ArticleCard/ArticleCard';
import {WithTalentDetailData} from '../../hocs';
import {TalentDetailData} from '../../hooks/useTalentDetail';
import {Link, Params, useParams} from 'react-router-dom';
import {TStrapiTag} from '../../interfaces/strapi';
import BookmarkHotlist from './components/BookmarkHotlist';
import ArticleRow from '../HotList/components/ArticleRow';
import HotlistTactics from './components/HotlistTactics';


type HotListItemProps ={
	loading?:boolean
	error?:string
	title:string
	subtitle?:string
	picture?:string
	tags?:TStrapiTag[]
	date?:string
	body?:string
	embed?:string
	pictures?:string[]
	publishedAt?:string
	relatedTalent?:string[]
	featuredTalent?:string[]
	articles?:TArticle[]
	tactics?:string[]
}
export const HotListDetailRender = (props:HotListItemProps):JSX.Element => {
	const {title,subtitle,body,publishedAt, embed, pictures, picture, tags,articles, tactics, relatedTalent,featuredTalent}:HotListItemProps = props;

	const {item}:Params=useParams();

	useEffect(():void=>{
		console.log('Loading twitter widgets');
		if(window['twttr']) {
			window['twttr']?.widgets?.load();
		}


	},[embed]);

	return (
		<HotListContainer >
			<section className={'flex direction-column align-center max-w-60'}>
				<PageTitle
					className={'pb center'}
					fontSize={'2.75rem'}
					fontWeight={'900'}
				>{title}</PageTitle>
				<BodyText
					className={'pt-half center'}
					color={'#AFB4D0'}
					textAlign={'center'}
				>{subtitle}</BodyText>
			</section>
			<Carousel
				width={'800px'}
				images={picture ? [picture, ...pictures] : pictures}
			/>
			<MainContainer>
				<div className={'w-100 flex justify-between'}>
					<div className={'flex align-center'}>
						<CalendarIcon/>
						<BodyText
							fontSize={'0.75rem'}
							color={'#fff'}
							className="f-700 ml-half"
						>
							{publishedAt &&
								new Date(publishedAt.replace(/-/g, '/')).toLocaleString('en-US', {
									month: 'short',
									day: 'numeric',
									year: 'numeric'
								})
							}
						</BodyText>
					</div>

					<BookmarkHotlist />
				</div>
				<div className={'w-100 flex col-gap'}>
					{tags?.map(({attributes: {title, slug}}:TStrapiTag):JSX.Element=>
						<Link to={`/hotlist/${slug}`} key={title}>
							<Pill
								$width={'fit-content'}
								$color={'white'}
								$backgroundColor={'#1916CC'}>
								#{title}
							</Pill>
						</Link>
					)}
				</div>

				{body && <div className="body" dangerouslySetInnerHTML={{__html: body}} />}

			</MainContainer>

			{embed && <div className={`max-w-800px w-100 center flex justify-center embed ${embed.includes('https://www.youtube.com/embed') ? 'apspect-16-9' : ''}`} dangerouslySetInnerHTML={{__html: embed}} />}

			{featuredTalent && featuredTalent?.length > 0 &&
				<Section>
					<BoxTitle className={'pb'} fontSize={'1rem'}>
						<span className="f-900"> Featured Talent</span>
					</BoxTitle>
					{featuredTalent && featuredTalent?.length > 0 && featuredTalent.map((id: string): JSX.Element => {
						return (
							<FeaturedTalent key={`ft-${id}`} talentId={id}/>
						);
					})}
				</Section>
			}

			{tactics && tactics?.length > 0 &&
				<Section>
					<BoxTitle className={'pb'} fontSize={'1rem'} >Preferred Tactics ( How to bring to life )</BoxTitle>
					<HotlistTactics tactics={tactics} />
				</Section>
			}

			{relatedTalent && relatedTalent?.length > 0 &&
				<Section>
					<div className="left w-100">
						<BoxTitle className={'pb'} fontSize={'1rem'}>
							<span className="f-900">
								Related Talent
							</span>
						</BoxTitle>
						<div className="grid5 col-gap">
							{relatedTalent && relatedTalent?.length > 0 && relatedTalent?.slice(0.5).map((talent: string): ReactNode =>
								<WithTalentDetailData talentId={talent} key={talent}>
									{({discoverData, instagramAudience}: TalentDetailData): ReactNode =>
										!discoverData.loading && !instagramAudience.loading ?
											<CardItem
												picture={discoverData?.data?.userObj?.profilePicture?.url}
												title={`${discoverData?.data?.userObj?.firstName} ${discoverData.data?.userObj?.lastName}`}
												subtitle={`${discoverData?.data?.industryObj[0]?.label} ${ discoverData?.data?.industryObj[0] && discoverData?.data?.industrySegmentObj? '|':''} ${discoverData?.data?.industrySegmentObj[0]?.label}`}
												link={`/talent/${talent}/opus`}
											/>
											:
											<Spinner/>
									}
								</WithTalentDetailData>
							)}

						</div>
					</div>
				</Section>
			}

			<ArticleRow
				title="Related Articles"
				subtitle=""
				params={{
					'sort':'publishDate:desc',
					'filters[id][$ne]': item,
					'filters[tags]': tags?.map(({id}:TStrapiTag):any=>id)
				}}
			/>

		</HotListContainer>
	);
};
