import React, {useContext} from 'react';
import CampaignAudienceRender from './CamapignAudienceRender';
import {
	NavigateFunction,
	Params,
	useNavigate,
	useOutletContext,
	useParams
} from 'react-router-dom';
import {
	MutationFunction,
	MutationTuple,
	useMutation
} from '@apollo/client';
import {mutation} from 'actions/mutations';
import {FormField,FormHook} from 'hooks/useForm/interfaces';
import {useForm} from 'hooks/useForm/useForm';
import {GeneralDataContext, GeneralDataContextInterface} from 'context/genalDataContext';
import {Item} from 'interfaces';
import {Spinner} from 'components/ui';
import {OpportunityFormContext} from '../../OpportunityCreate';


export function CampaignAudience():JSX.Element {
	const { id }: Params<string> = useParams();
	const {activateOpportunity, audience, refetch}:OpportunityFormContext = useOutletContext();
	const { genders, ethnicities, languages, countries, loading:dataLoading }:GeneralDataContextInterface = useContext(GeneralDataContext);
	const navigate:NavigateFunction = useNavigate();


	const [updateAudience]:MutationTuple<MutationFunction, any> =useMutation(mutation('audienceUpdateOne'));

	const update = async(data:any):Promise<any>=>{
		let uploadData:any = {...data};

		uploadData.ageRange ={
			min: parseInt(data.ageRange[0]),
			max: parseInt(data.ageRange[1])
		};

		await updateAudience({variables:{
			_id: audience._id,
			record:{ opportunity: id ,...uploadData},
			income:'ok'
		}});
		await activateOpportunity();
		refetch();
		navigate(`/opportunities/${id}/edit/talent`);

	};

	const defaultAgeRange: number[] |null= audience?.ageRange.length? [ audience?.ageRange[0]?.min, audience?.ageRange[0]?.max ] : [ 18, 81 ];

	const  formFields:FormField[]=[
		{name :'ageRange', default:  defaultAgeRange || [18, 80]},
		{name :'genders' , default:  audience?.genders || [], array:true, required:false},
		{name :'ethnicity' , default:  audience?.ethnicity || [], array:true, required:false},

		{name :'country' , default:  audience?.country, array:true}
	];
	const  {data:formData,errors,loading,...rest}:FormHook=useForm(formFields,update );
	if( dataLoading.genders || dataLoading.ethnicities || dataLoading.countries ){
		return <div className={'w-100 flex justify-center'}>
			<Spinner/>
		</div>;
	}

	return (
		<CampaignAudienceRender
			formData={formData}
			submitLoading={loading}
			errors={errors}

			options={{
				genders,ethnicities,countries,languages
			}}
			registerInput={rest.registerInput}
			registerList={rest.registerList}
			onSubmit={rest.handleSubmitForm}
		/>

	);
}