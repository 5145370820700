import React from 'react';
import {IslandMarker, GraphMarkersDiv} from '../styles';

export default function GraphMarkers():JSX.Element {

	const markers:any[]=[
		{
			title: 'Cities',
			color: '#8D6CEC'
		},
		{
			title: 'Countries',
			color: '#FF5656'
		},
		{
			title: 'Ethnicities',
			color: '#D5AF33'
		},
		{
			title: 'Brands',
			color: '#1C9882'
		},
		{
			title: 'Locales',
			color: '#DA7298'
		},
		{
			title: 'Gender/Age',
			color: '#F2A541'
		},
		{
			title: 'Interests',
			color: '#F08A4B'
		}
	];

	return (
		<GraphMarkersDiv>
			{markers.map(({color, title}:any):JSX.Element => {
				return (
					<div className="flex mr align-center" key={title}>
						<IslandMarker color={color} className="mr-half" />
						{title}
					</div>
				);
			})}
		</GraphMarkersDiv>
	);

}