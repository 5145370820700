import React from 'react';
import {Brand, CompanyProfile, FormProps} from 'interfaces';
import {BrandNav} from 'components/BrandNav';
import {SkuForm} from 'components';

interface CreateSkuProps extends FormProps{
	nav?:boolean
	productId:string
	edit?:boolean
	defaultPicture?:string
	product:Brand
	profile:CompanyProfile
	onChangeFile:(file:File)=>void
}

export const CreateSkuRender =({nav=true,edit=false,productId,defaultPicture,product,profile,submitLoading,errors,onChangeFile,onSubmit,registerInput,registerTextArea}:CreateSkuProps):JSX.Element=>{
	return <>
		{nav&&<BrandNav companyName={profile.name} ProductName={product.name} productId={productId}/>}
		<SkuForm
			edit={edit}
			defaultPicture={defaultPicture}
			brandId={productId}
			submitLoading={submitLoading}
			errors={errors}
			onChangeFile={onChangeFile}
			onSubmit={onSubmit}
			registerInput={registerInput}
			registerTextArea={registerTextArea}
		/>
	</>;
};