import {VerticalMenu} from 'components';
import React from 'react';
import {NavItem} from 'interfaces';
import {NotificationsIcon, OpusIcon, SecurityIcon} from 'icons';
import {Location, useLocation} from 'react-router-dom';
import styled from 'styled-components';

const MenuWrapper :any = styled.div`
    width: 16rem;
    height: 100%;
  padding-top: 2.5rem;
  border-right: 1px solid  #22232B;
`;
export const SettingsMenu = ():JSX.Element => {
	const { pathname }: Location = useLocation();
	const navItems: NavItem[] = [
		{
			id: 'security',
			label: 'Security and Privacy',
			path: '/settings/security',
			Icon: SecurityIcon
		},
		{
			id: 'notifications',
			label: 'Notifications',
			path: '/settings/notifications',
			Icon: NotificationsIcon
		},
		// {
		// 	id: 'help',
		// 	label: 'Help Center',
		// 	path: '/settings/help',
		// 	Icon: HelpIcon
		// },
		{
			id: 'about',
			label: 'About Opus',
			path: '/settings/about',
			Icon: OpusIcon
		}
	];

	const settingPath:string= pathname.split('/')[2];
	return (
		<MenuWrapper >
			<VerticalMenu
				navItems={navItems}
				currentItem={settingPath}
			/>
		</MenuWrapper>
	);
};