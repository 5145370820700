import React from 'react';
import { FormButton, Input } from 'components/ui';
import {FormProps} from 'interfaces';
import {Message} from 'components';
import {color} from 'styles/colors';
import {SecurityForm} from '../../styles';
import {BoxTitle} from 'stylesOpus/typography';

export const EmailRender = ({registerInput,submitLoading,onSubmit,errors}:FormProps): JSX.Element => {
	return (
		<>
			<SecurityForm
				autoComplete={'nope'}
				onSubmit={onSubmit}
			>
				<BoxTitle>Update Email Address</BoxTitle>
				<div>
					<Input
						label={'Email'}
						{...registerInput('email')}
					/>
					<Input
						label={'Password'}
						autoComplete={'new-password'}
						type={'password'}
						{...registerInput('password')}
					/>
				</div>
				<div className="center">
					<FormButton
						loading={submitLoading}
						variant={'white'}
						label={'Save'}
					/>
					{errors?.get('submitError') && <Message message={errors.get('submitError') } color={color.get('primary')}/>}
					{errors?.get('email') && <Message message='The Password is required' color={color.get('primary')}/>}
					{errors?.get('password') && <Message message='The Password is required' color={color.get('primary')}/>}
				</div>

			</SecurityForm>
		</>
	);
};
