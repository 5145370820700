import React, {ChangeEvent, useContext, useState} from 'react';
import { NotificationsRender, Toggle } from './NotificationsRender';
import {MutationTuple, QueryResult, useMutation, useQuery} from '@apollo/client';
import {mutation} from 'actions/mutations';
import {query} from 'actions/queries';
import {UserContext, userContextInterface} from 'context/userContext';

type Settings ={
	opportunitiesNotification:boolean,
	newsNotification:boolean,
	paymentsNotification:boolean,
	emailNotifications:boolean,
	smsNotifications:boolean
};

export const Notifications = ():JSX.Element => {
	const {user}:userContextInterface=useContext(UserContext);
	const {data}:QueryResult=useQuery(query('userSettingsFindOne'),{
		fetchPolicy:'network-only',
		variables:{filter:{user:user._id}
		}
	});
	const [updateSettings]:MutationTuple<any, any> =useMutation(mutation('userUpdateSettings'));
	const optionsNotifications: Toggle[] = [
		{
			id:'opportunitiesNotification',
			name:'Opportunities',
			defaultChecked: !!data?.userSettingsFindOne.opportunitiesNotification
		},
		{
			id:'newsNotification',
			name:'Wallet and Payments',
			defaultChecked: !!data?.userSettingsFindOne.newsNotification
		},
		{
			id:'paymentsNotification',
			name:'New Updates',
			defaultChecked: !!data?.userSettingsFindOne.paymentsNotification
		},
		{
			id:'emailNotifications',
			name:'Email Newsletter',
			defaultChecked: !!data?.userSettingsFindOne.emailNotifications
		},
		{
			id:'smsNotifications',
			name:'SMS Text Message',
			defaultChecked: !!data?.userSettingsFindOne.smsNotifications
		}
	];
	const [settings,setSettings]=useState<Settings>({
		opportunitiesNotification:optionsNotifications[0].defaultChecked,
		newsNotification:optionsNotifications[1].defaultChecked,
		paymentsNotification:optionsNotifications[2].defaultChecked,
		emailNotifications:optionsNotifications[3].defaultChecked,
		smsNotifications:optionsNotifications[4].defaultChecked
	});

	const onchangeOption=async({target}:ChangeEvent<HTMLInputElement>):Promise<void>=>{
		const {checked,name,id}:any=target;
		const newSettings:Settings={...settings,[name]:checked};
		console.log('---', {checked,name,id, settings,newSettings});
		setSettings((prev:Settings):Settings=>({...prev,[name]:checked}));

		await updateSettings({variables:{...settings,[name]:checked}});
	};

	if(!data)
		return <h6>Loading...</h6>;
	return (
		<NotificationsRender
			options={optionsNotifications}
			onChangeOption={onchangeOption}
		/>
	);
};
