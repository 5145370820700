import React, {useContext, useEffect, useState} from 'react';
import {EventLocationRender} from './EventLocationRender';
import {FormField, FormHook} from 'hooks/useForm/interfaces';
import {NavigateFunction, Params, useNavigate, useParams} from 'react-router-dom';
import {useForm} from 'hooks/useForm/useForm';
import {MutationTuple, QueryResult, useMutation, useQuery} from '@apollo/client';
import {mutation} from 'actions/mutations';
import {query} from 'actions/queries';
import {CulturalAlert, Item, ItemApi} from 'interfaces';
import {UserContext, userContextInterface} from 'context/userContext';
import {uploadCulturalAlertDeck} from '../../../../actions/restActions';
import { sortListBy } from 'tools/globalActions';

const formatDate = (stringDate:string):string=>new Date(stringDate)?.toISOString().split('T')[0];

export const Location = ():JSX.Element=>{

	const navigate:NavigateFunction=useNavigate();
	const {id}:Params = useParams();
	const {user}:userContextInterface=useContext(UserContext);
	const [deck,setDeck]=useState<File|null>(null);
	// optionsData
	const {data:stateData}:QueryResult=useQuery(query('stateFindMany'));
	// alert
	const {data:alertsData}:QueryResult =useQuery(query('culturalAlertFindMany'),
		{variables:{
			filter:{
				_id:id,
				user:user._id
			}
		}});
	const alertData:CulturalAlert= alertsData?.culturalAlertFindMany[0]||{};
	const [updateAlert]:MutationTuple<any, any> =useMutation(mutation('culturalAlertUpdateOne'));
	const setEventLocation=async(formData:any):Promise<void> =>{
		const data:any= {...formData};
		console.log('submit',{data});
		const feeRange:any={
			min:data.feeRange[0],
			max:data.feeRange[1]
		};
		data.eventPaid=!!data.eventPaid;
		data.feeRange=feeRange;
		delete data.venue;
		deck && await uploadCulturalAlertDeck( deck,id );
		await updateAlert({
			variables:{
				_id:id,
				record:{...data}
			}
		});
		navigate(`/company/alerts/${id}/edit/additional-info`);
	};

	const  formFields:FormField[]=[
		{name:'venue'},
		{name:'city',default:alertData?.city},
		{name:'state',default:alertData?.state},
		{name:'eventFrom',default:alertData?.eventFrom? formatDate(alertData.eventFrom) :''},
		{name:'eventTo', default: alertData.eventTo? formatDate(alertData.eventTo) :''},
		{name:'eventPaid', default:alertData?.eventPaid},
		{
			name:'feeRange',
			default:alertData?.feeRange ?
				[alertData.feeRange.min, alertData.feeRange.max] :
				[25e4, 75e4]
		},
		{name: 'eventPartnership', default:alertData?.eventPartnership}
	];


	const {data,loading,errors,resetForm,...rest}:FormHook=useForm(formFields,setEventLocation);
	useEffect(():void=>{
		resetForm();
	},[alertsData]);

	const states:Item[]=stateData?.stateFindMany.map((e:ItemApi):Item=> ({id:e.label,label:e.label}) )||[];


	return <EventLocationRender
		submitLoading={loading}
		errors={errors}
		onSubmit={rest.handleSubmitForm}
		options={{
			states:sortListBy(states,'label'),
			paid:[
				{id:'',label:'Free'},
				{id:'1',label:'Paid'}
			]
		}}
		formData={data}
		onChangeFile={(file:File):void=>setDeck(file)}
		{...rest}
	/>;
};