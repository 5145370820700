import React, {FormEvent, useContext, useState} from 'react';
import { AccountRender } from './AccountRender';
import {MutationTuple, useMutation} from '@apollo/client';
import {mutation} from 'actions/mutations';
import {UserContext, userContextInterface} from 'context/userContext';

export const Account = (): JSX.Element => {
	const {user,logout}: userContextInterface=useContext(UserContext);
	const [deleteAccount]:MutationTuple<any, any> =useMutation(mutation('userDeleteOne'));
	const [loading,setLoading]=useState<boolean>(false);
	const [error,setError]=useState<any>(null);
	const handleDelete=async(e:FormEvent):Promise<void> =>{
		e.preventDefault();
		setLoading(true);
		try {
			await deleteAccount({variables:{_id:user._id}});
			await logout();
		}
		catch (e) {
			setError({submit: e.message});
		}
		finally {
			setLoading(false);
		}
	};

	return <AccountRender
		errors={error}
		submitLoading={loading}
		onSubmit={handleDelete}
	/>;
};
