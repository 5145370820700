import React from 'react';

interface IconProps {
	width?: number
	height?: number
	color?: string
}

export const CloseIcon=({width, height, color='white'}:IconProps):JSX.Element=>{
	return <svg
		width={width || 24}
		height={height || 24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M18 18L12 12L18 6"
			stroke={color}
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M6 18L12 12L6 6"
			stroke={color}
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>;
};
