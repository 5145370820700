import React, {useContext, useEffect} from 'react';
import {FavoritesRender} from './FavoritesRender';
import {QueryResult, useQuery} from '@apollo/client';
import {UserContext, userContextInterface} from 'context/userContext';
import {query} from 'actions/queries';
import {TLike} from 'hooks/useAddToFavorites';
import {likedHotlist} from 'actions/strapiActions';
import {NavigateFunction, Params, useNavigate, useParams} from 'react-router-dom';
import {TStrapiItem} from 'interfaces/strapi';
import {SetterOrUpdater, useSetRecoilState} from 'recoil';
import {BreadCrumbAtom, TCrumb} from '../../context/recoil/store';

export const Favorites = ():JSX.Element => {
	const setBreadCrumb: SetterOrUpdater<TCrumb[]>=useSetRecoilState(BreadCrumbAtom);

	const {user} :userContextInterface = useContext(UserContext);
	const [likedHotLists, setLikedHotLists] = React.useState<TStrapiItem[]>([]);
	
	const navigate:NavigateFunction = useNavigate();
	const {tab} :Params= useParams();
	const {data,loading,error}:QueryResult = useQuery(query('likeFindMany'),{
		variables:{
			filter:{
				user: user._id
			}
		}
	});

	
	const getLikedHotlist = ():void => {
		likedHotlist({
			'filters[user]':user._id,
			'populate[0]': 'hotlist',
			'populate[1]': 'hotlist.cover'
		}).then(({data}: any):void => {
			const hotLists : TStrapiItem[] = data.data.map((d:any):string => d.attributes.hotlist.data);
			setLikedHotLists(hotLists);
		});
	};
	
	useEffect(():void => {
		getLikedHotlist();
	}, []);

	useEffect(():void => {
		if (!tab) {
			setBreadCrumb([
				{id: 'favorites', label: 'favorites', path:'/favorites'}
			]);
		}else {
			setBreadCrumb([
				{id: 'favorites', label: 'favorites', path:'/favorites'},
				{id: tab, label: tab, path:`/favorites/${tab}`}
			]);
		}
	}, [tab]);

	const onchangeTab = (tab:string):void => {
		navigate(`/favorites/${tab ==='all'? '': tab}`);
	};
	const likes : TLike[] = data?.likeFindMany.filter((like:TLike):boolean=> !like.deletedAt ) || [];
	const talentLikes : string[] = likes.filter((like:TLike):boolean => like.talent !== null).map((l:TLike):string=>l.talent);
	const opportunityLikes : string[] = likes.filter((like:TLike):boolean => like.culturalAlert !== null).map((l:TLike):string=>l.culturalAlert);

	return (
		<FavoritesRender
			talents={talentLikes}
			opportunities={opportunityLikes}
			hotlistArticles={likedHotLists}
			currentTab={tab}
			onchangeTab={onchangeTab}
		/>
	);
};