import React, {MutableRefObject, useEffect} from 'react';
import {
	SliderImageContainer,
	SliderArrow,
	CarouselButton,
	CarouselWrapper,
	CarouselImageContainer
} from './carousel.styles';
import {ReactComponent as ChevronLeft} from 'styles/icons/chevron-left.svg';
import styled from 'styled-components';
import {CarouselPicture} from './components/carouselPicture';

type SliderProps = {
    images?: string[];
	paths?: string[];
	titles?: string[];
	colors?: string[];
	bodies?:string[];

    width?: string;
    aspectRatio?: string;
	imageWidth?:number;
}

const ChevronRight :any = styled(ChevronLeft)`
	rotate: 180deg;
`;

type Tpos = {
	current:number;
	prev:number;
}

export const Carousel = ({images=[],colors,paths,titles, bodies, width, aspectRatio, imageWidth}:SliderProps): JSX.Element => {

	// ref for current image container
	const containerRef:MutableRefObject<HTMLDivElement> = React.useRef<HTMLDivElement>(null);

	// ref for carousel container
	const wrapperRef:MutableRefObject<HTMLDivElement> = React.useRef<HTMLDivElement>(null);
	// left position of the wrapper on screen
	const [wrapperLeft,setWrapperLeft] = React.useState<number|undefined>(undefined);
	// right position of the wrapper on screen
	const [wrapperRight,setWrapperRight] = React.useState<number|undefined>(undefined);

	const [activeImageIndx, setActiveImageIndx] = React.useState<number>(0);
	const [pos,setPos] = React.useState<Tpos>({
		current:0,
		prev:0
	});


	let imagePercentageWidth:number = imageWidth || 100;
	imagePercentageWidth = imagePercentageWidth<0 ? 0 : imagePercentageWidth > 100 ? 100 : imagePercentageWidth;

	//container images pos

	const handleChangeImage = (newIndex:number):void => {
		if(newIndex >=0 && newIndex < images.length)  {
			setActiveImageIndx(newIndex);
		}
	};

	useEffect(():void => {
		if (containerRef.current){
			setWrapperLeft(wrapperRef.current.getBoundingClientRect().left);
			setWrapperRight(wrapperRef.current.getBoundingClientRect().right);
		}
	}, [wrapperRef.current]);


	const handleSetActive = (id:number|string,rect:DOMRect):void => {
		if (typeof wrapperLeft ==='number'){
			// check if the selected image is the last in the list
			if (id === images.length - 1) {
				const newDX :number = wrapperRight - rect.right;
				setPos( (prevState:Tpos):Tpos=>({
					current:prevState.current + newDX,
					prev:prevState.current
				}));
			}
			else{
				const newDX :number = wrapperLeft - rect.left;
				setPos( (prevState:Tpos):Tpos=>({
					current:prevState.current + newDX,
					prev:prevState.current
				}));
			}
		}
	};

	return (
		<CarouselWrapper  $width={width} $aspectRatio={aspectRatio} ref={wrapperRef} className={'carousel-wrapper'}>
			<div className={'carousel-dimension'}>
				<SliderImageContainer
					ref ={containerRef}
					$startPosition={pos.prev}
					$endPosition={pos.current}
				>
					{images.map((image:string,index:number):JSX.Element =>
						<CarouselImageContainer key={image} $width={imagePercentageWidth}>
							<CarouselPicture
								label={titles ? titles[index] : undefined}
								description={bodies ? bodies[index] : undefined}
								path={paths ? paths[index] : undefined}
								src={image}
								id={index}
								selectedImageId={activeImageIndx}
								onSetActive={handleSetActive}
								labelColor={colors ? colors[index] : '#fff'}
							/>
						</CarouselImageContainer>
					)}
				</SliderImageContainer>
			</div>


			{ images.length > 1 &&
			<>
				<SliderArrow
					className={'absolute left-1'}
					active={activeImageIndx > 0}
					onClick={(): void => handleChangeImage(-1 + activeImageIndx)}
				>
					<ChevronLeft/>
				</SliderArrow>
				<SliderArrow
					className={'absolute right-1'}
					active={(activeImageIndx < images.length - 1)}
					onClick={(): void => handleChangeImage(1 + activeImageIndx)}
				>
					<ChevronRight/>
				</SliderArrow>
			</>
			}

			{images.length > 1 &&
				<div className={'flex col-gap'}>
					{images.map((image: string, index: number): JSX.Element =>
						<CarouselButton
							key={image}
							active={activeImageIndx === index}
							onClick={(): void => handleChangeImage(index)}
						/>
					)}
				</div>
			}

		</CarouselWrapper>
	);
};
