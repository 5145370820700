import React from 'react';
import {color} from 'styles/colors';

interface CheckChannelIconProps {
	active?:boolean
}

export const CheckIcon=({active=false}:CheckChannelIconProps):JSX.Element=>{
	return <svg
		width={18}
		height={19}
		viewBox="0 0 18 19"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx={9} cy="9.26392" r={9} fill={active?color.get('primary') :color.get('neutral-600')} />
		<path
			d="M8.03076 12.7227C8.29899 12.7227 8.50472 12.6172 8.64795 12.4062L12.2847 6.83984C12.3341 6.76172 12.3706 6.6862 12.394 6.61328C12.4175 6.54036 12.4292 6.47005 12.4292 6.40234C12.4292 6.22005 12.368 6.06901 12.2456 5.94922C12.1232 5.82943 11.9683 5.76953 11.7808 5.76953C11.6532 5.76953 11.5451 5.79557 11.4565 5.84766C11.3706 5.89714 11.2873 5.98307 11.2065 6.10547L8.01514 11.1484L6.39014 9.13672C6.25212 8.96745 6.07894 8.88281 5.87061 8.88281C5.68311 8.88281 5.52686 8.94401 5.40186 9.06641C5.27946 9.1862 5.21826 9.33854 5.21826 9.52344C5.21826 9.60417 5.23258 9.68229 5.26123 9.75781C5.28988 9.83333 5.33936 9.91146 5.40967 9.99219L7.42529 12.4336C7.58675 12.6263 7.78857 12.7227 8.03076 12.7227Z"
			fill={active? 'white': color.get('neutral-400')}
		/>
	</svg>;
};
