import React, {useContext, useState} from 'react';
import CompanyAlertsRender from './CompanyAlertsRender';
import {MutationTuple, QueryResult, useMutation, useQuery} from '@apollo/client';
import {query} from 'actions/queries';
import {UserContext, userContextInterface} from 'context/userContext';
import {Spinner} from 'components/ui';
import {mutation} from '../../actions/mutations';

export const CompanyAlerts=():JSX.Element=>{
	const {user}:userContextInterface=useContext(UserContext);
	const [modalIsOpen, setModalIsOpen] = useState( false );
	const [alertId, setAlertId] = useState( '' );
	const {data:alertsData,loading:alertsLoading}:QueryResult =useQuery(query('culturalAlertFindMany'),
		{variables:{
			filter:{user:user._id}
		},
		fetchPolicy:'network-only'});
	
	const [ deleteAlert ] : MutationTuple<any, any> = useMutation(mutation('culturalAlertDeleteOne'), {
		refetchQueries:['culturalAlertFindMany']
	});
	
	const handleDelete = async():Promise<void>=> {
		await deleteAlert({
			variables: {_id: alertId}
		});
		handleClose();
	};
	
	const handleOpen = (id:string): void=>{
		setModalIsOpen(true);
		setAlertId(id);

	};
	const handleClose = (): void=>{
		setModalIsOpen(false);
		setAlertId('');
	};
	
	if(alertsLoading)
		return <div className={'w-100 h-100 flex justify-center align-center'}>
			<Spinner/>
		</div>;

	return <CompanyAlertsRender
		alerts={alertsData?.culturalAlertFindMany ||[]}
		deleteModal={{
			isOpen: modalIsOpen,
			open : handleOpen,
			close : handleClose
		}}
		deleteAction={handleDelete}
	/>;
};