import React, {useContext} from 'react';
import {OnboardingContext} from 'context/onboardingContext';
import {BodyText} from 'stylesOpus/typography';
import styled from 'styled-components';

const StepContainer:any = styled.div`
  margin-bottom: 3.5rem;
`;

const StepSpan:any = styled.span`
  color :white;
`;
export const OnboardingStepper = ():JSX.Element => {
	const {currentStep, steps} :any = useContext(OnboardingContext);
	return (<StepContainer>
        
		<BodyText>step <StepSpan>{currentStep|| 1}</StepSpan> of {steps}</BodyText>
	</StepContainer>);
};