import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import TalentDetailRender from './TalentDetail.render';
import {useLazyQuery} from '@apollo/client';
import {query} from '../../actions/queries';
import {SetterOrUpdater, useRecoilValue, useSetRecoilState} from 'recoil';
import {BreadCrumbAtom, TCrumb} from '../../context/recoil/store';
import {TalentData} from '../../hooks/useTalentDetail';

export default function TalentDetailLoad():JSX.Element{
	const bc:TCrumb[] = useRecoilValue(BreadCrumbAtom);
	const setBreadCrumb: SetterOrUpdater<TCrumb[]>=useSetRecoilState(BreadCrumbAtom);
	const {id}:any = useParams();
	const [loading, setLoading] = useState<boolean>(true);
	const [totalMedia, setTotalMedia] = useState<number>(0);

	// eslint-disable-next-line @typescript-eslint/typedef
	const [getTalent, { data }] = useLazyQuery(query('usersTalentProfileFindOne'));

	// eslint-disable-next-line @typescript-eslint/typedef
	const [getSocial, { data:social }] = useLazyQuery(query('socialCardFindMany'));

	// eslint-disable-next-line @typescript-eslint/typedef
	const [getData, { data:media }] = useLazyQuery(query('talentMediaFindMany'));

	useEffect(():void=>{
		if(loading){
			getData({
				variables: {
					filter: {
						user: id
					},
					limit: 15
				},
				fetchPolicy: 'no-cache',
				onCompleted: (data:any):void => setTotalMedia(data?.talentMediaFindMany?.length || 0)
			});
		}
	},[loading]);

	useEffect(():void=>{
		if(loading){
			getTalent({
				variables: {
					user: id
				},
				fetchPolicy: 'no-cache',
				onCompleted: (data:any):void => setLoading(false)
			});
			getSocial({
				variables: {
					user: id
				},
				fetchPolicy: 'no-cache'
			});
		}
	},[loading]);

	useEffect(():void=>{
		let current:TCrumb[] = [...bc];
		if(data?.usersTalentProfileFindOne && current.length < 3){
			const td:TalentData = data?.usersTalentProfileFindOne;
			let segment:TCrumb = {id: td?._id, label: `${td?.userObj?.firstName} ${td?.userObj?.lastName}`, path:`/talent/${td?.userObj?._id}/opus`};
			current.push(segment);
			setBreadCrumb(current);
		}
	},[data]);

	return (
		<TalentDetailRender
			talent={data?.usersTalentProfileFindOne}
			social={social?.socialCardFindOne}
			totalMedia={totalMedia}
		/>
	);
}