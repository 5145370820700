import styled from 'styled-components';

export const LogoutButton:any=styled.a`
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  text-transform: uppercase;
  cursor: pointer;
  border: 1px solid white;
  padding: 5px 10px;
  text-align: center;
  border-radius: 4px;
  display: block;
`;