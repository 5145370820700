import {MutableRefObject, useState} from 'react';
import { QueryResult, useQuery, WatchQueryFetchPolicy} from '@apollo/client';
import useClickOut from './useClickOut';
import {query } from '../actions/queries';

export interface SearchProps {
	searchAction:string
	variables?:any
	fetchPolicy?:WatchQueryFetchPolicy
}

export interface SearchHandler {
	showOptions:boolean
	optionWrapperRef:MutableRefObject<any>
	queryResult:QueryResult,
	setQuery:(query:string)=>void,
	setShowOptions:(value:boolean)=>void
}


export const useSearch=({searchAction,variables={},fetchPolicy='network-only'}:SearchProps):SearchHandler=>{

	const [showOptions, setShowOptions]=useState<boolean>(false);
	const [queryString,setQueryString]=useState<string>();
	const optionWrapperRef:MutableRefObject<any> =useClickOut(():void=>setShowOptions(false));
	const result:QueryResult=useQuery(query(searchAction),
		{
			variables:{...variables,query:queryString},
			fetchPolicy
		});

	return {
		showOptions:showOptions,
		optionWrapperRef:optionWrapperRef,
		queryResult:result,
		setQuery:setQueryString,
		setShowOptions
	};
};

