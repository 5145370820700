import React from 'react';
import {Item,SelectProps} from 'interfaces';
import {SelectOption,StyledSelect} from './styles';
import {InputWrapper,Label,RequiredText} from 'styles/ui';

export default function SelectRender({
	label,
	required,
	className,
	unselected,
	options,
	defaultValue,
	placeholder='Select an option',
	theme='opus',
	styleProps,
	...props
}:SelectProps):JSX.Element{

	return (
		<InputWrapper
			localTheme={theme}
			className={className}
		>
			<div>
				<div>
					<Label>{label}</Label>
					{required && <RequiredText>Required</RequiredText>}
				</div>
				<StyledSelect
					defaultValue={defaultValue}
					unselected={unselected}
					{...props}
					required={required}
					{...styleProps}
				>
					{(placeholder && !defaultValue) && <SelectOption value='' disabled = {!placeholder}>{placeholder}</SelectOption>}
					{options.map((opt:Item):JSX.Element=>
						<SelectOption
							key={opt.id}
							value={opt.id}
						>
							{opt.label}
						</SelectOption>
					)}

				</StyledSelect>

			</div>
		</InputWrapper>
	);
}
