import React from 'react';
import {CulturalAlert, TacticI} from '../../interfaces';
import {CardItem} from '../CardItem';
import {Section} from '../../styles/styledComponents/containers';
import {Link} from 'react-router-dom';

interface SubtacticAlertRenderI {
	alerts?:CulturalAlert[]
	subtactic? : TacticI
	title? : string
	description? : string
	viewAll?: string
	hideViewAll?: boolean
	withRemainTime?: boolean
}

export default function SubtacticAlertRowRender({alerts, title, description, subtactic, viewAll, hideViewAll=false,withRemainTime}:SubtacticAlertRenderI):JSX.Element {
	return (
		<Section className={`${hideViewAll ? '' : ''}`}>
			<div className="flex">
				<h3 className="f-1rem f-urbanist  f-900 ls-1-6 mb-half grows">
					{subtactic?.title ? subtactic?.title : title ? title : ''}
				</h3>
				{!hideViewAll &&
					<Link to={viewAll ? viewAll : `/opportunities/subtactic/${subtactic.slug}`} className="f-urbanist f-12px neutral-900 f-800 pver-half phor br t-white">
						View All
					</Link>
				}
			</div>

			<div className="f-12px t-neutral-300 ls-1-2 mb" dangerouslySetInnerHTML={{__html: subtactic?.description ? subtactic?.description : description ? description : ''}} />

			<div className={'w-100 grid4 col-gap mt row-gap-2'}>
				{alerts && alerts.length > 0 && alerts?.map((culturalAlert:CulturalAlert):JSX.Element=>
					<CardItem
						key={culturalAlert._id}
						title={culturalAlert.title}
						subtitle={''}
						picture={culturalAlert.photos[0]?.url}
						link={`/opportunities/${culturalAlert._id}`}
						remainTime={withRemainTime ?culturalAlert.endDate :''}
					/>
				)}
			</div>

		</Section>
	);
}
