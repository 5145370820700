import {Item} from './data';

type Range={
	min?:number
	max?:number
}

export type ShortLink = {
	_id:string
	slug:string
	type:string
	password:string
	expiredAt:string
}

export enum GendersEnum {
	Female='Female',
	Male='Male',
	'non-binary'='Non-Binary'
}

export enum EthnicityEnum {
	'White / Caucasian'='White / Caucasian',
	'Asian'='Asian',
	'Hispanic'='Hispanic',
	'African American'='African American'
}

export enum EthnicitySortEnum {
	white='White / Caucasian',
	asian='Asian',
	hispanic='Hispanic',
	african='black',
	'all'=''
}

type TalentAudience ={
	age?:string[]
	gender?:string[]
}

export enum SortEnum {
	asc='_ID_ASC',
	desc='_ID_DESC'
}

export const enumToItemArray=(enumObject:Record<string, string>):Item[]=> Object.entries(enumObject).map(([key, value]:any):Item => ({
	label: key,
	id: value
}));

export const enumToItemArrayEth=(enumObject:Record<string, string>):Item[]=> Object.entries(enumObject).map(([key, value]:any):Item => ({
	label: value,
	id: key
}));

export interface FilterFindManytalentInput{
	audienceSize?: Range
	age?:Range
	gender?: GendersEnum[]
	ethnicity?: EthnicityEnum[]

	type?:string[]
	interest?:string[]
	city?:string[]
	state?:string[]
	country?:string[]
	socialNetwork?:string[]
	talent? :TalentAudience
}