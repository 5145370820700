import React, {FC} from 'react';
import styled from 'styled-components';
import {color} from 'styles/colors';
import {InputLabel} from 'stylesOpus/typography';
import {themeOpus} from 'stylesOpus/theme';

type IconOptionInterface ={
	icon:FC
	label?:string
	selected?:boolean
	onClick: React.MouseEventHandler<HTMLButtonElement>
}

const IconContainer:any=styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 73px;
  aspect-ratio: 1;
  border-radius: 16px;
  background-color: ${themeOpus.colors.grey_900};
  
`;

const IconLabel : any = styled(InputLabel)`
	  font-size: 12px;
`;


export const IconOption =({icon,selected,label,onClick}:IconOptionInterface):JSX.Element=>{
	return<div className={'flex direction-column align-center row-gap'}>
		<IconContainer selected={selected} onClick={onClick}>
			{icon({color: selected ? color.get('primary-100') : color.get('neutral-400')})}
		</IconContainer>
		<IconLabel selected={selected}>{label} </IconLabel>
	</div>;
};