import React from 'react';
import {NavItem} from 'interfaces';
import {SelectDotContainer} from './components/SelectDotContainer';
import {MenuItem} from './components/MenuItem';

type VerticalMenuProps = {
    navItems: NavItem[];
    currentItem: string;
};

export const VerticalMenu= ({navItems,currentItem}:VerticalMenuProps):JSX.Element => {
	let currentItemIndex :number = navItems.findIndex((nav:NavItem):boolean=>nav.id===currentItem);

	return (
		<section className={'flex direction-column row-gap relative'}>
			<SelectDotContainer index={currentItemIndex} array={navItems}/>
			{navItems?.map((nav:NavItem):JSX.Element=>
				<MenuItem
					key={nav.path} nav={nav} active={nav.id===currentItem}
				/>
			)}
		</section>
	);
};
