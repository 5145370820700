import React,{ChangeEvent,useEffect,useState} from 'react';
import VerificationCodeRender from './VerificationCodeRender';

type digit=number|string;
type target={name:string;value:string};
export interface VerificationCodeProps{
	name?:string;
	value?:number;
	codeSize:number;

	onChange?:(target:any)=>void;
}

export const VerificationCode=({codeSize,name,onChange}:VerificationCodeProps):JSX.Element=>{
	let array:number[]=new Array(codeSize).fill('');
	const [digits,setDigits]=useState<digit[]>(array);
	let code:string=digits.join('');
	let regex:RegExp=new RegExp('^[0-9]*$');

	useEffect(():void=>{
		onChange({name,value:code});
		//console.log('useEffect: ',{name,code});
	},[code]);

	const onSetDigit=(e:ChangeEvent<HTMLInputElement>):void=>{
		const {value,name}:any=e.target;

		if(regex.test(value)){
			const nweArrray:number[]=digits.map((_:number,i:number):number=>{
				if(name==i){
					return value;
				}
				return _;
			});
			setDigits(nweArrray);
		}

	};
	return <VerificationCodeRender
		codeSize={codeSize}
		digits={digits}
		changeEvent={onSetDigit}
	/>;
};
