import React, {useContext, useEffect, useState} from 'react';
import TalentGraphRender from './TalentGraph.render';
import {useQuery} from '@apollo/client';
import {query} from '../../actions/queries';
import {kgFormat} from './kgFormat';
import {Igraph, Iuser} from './types';
import {UserContext} from '../../context/userContext';
import {Header} from 'components';
import {Layout} from 'containers';

export const TalentGraphLoad = ():JSX.Element => {

	const [n, setN] = useState<Igraph>({nodes: [], relations: []});
	const {user}:any=useContext(UserContext);

	//const userObj:Iuser = {userObj: {firstName: user.firstName, lastName: user.lastName}};

	const {data:d}:any = useQuery(
		query('usersTalentProfileFindOne'),
		{
			variables: {
				filter: {
					user : user._id
				}
			}
			//fetchPolicy: 'cache-first'
		}
	);

	const {data, loading}:any = useQuery(
		query('instagramAudienceFindOne'),
		{
			variables: {
				filter:{user: user._id}
			}
			//fetchPolicy: 'cache-first'
		}
	);

	useEffect(():any=>{
		if(data?.instagramAudienceFindOne && user) {
			const userObj:Iuser = {userObj: {firstName: user.firstName, lastName: user.lastName}};
			const graph:any = kgFormat(data?.instagramAudienceFindOne, userObj);
			setN(graph);
		}
		else{
			console.log('Error:', user, data?.instagramAudienceFindOneAdmin);
		}
	}, [data]);


	return (

		<div>
			{loading ?
				'Loading Graph'
				:
				n?.nodes && n?.nodes?.length > 0 && n?.relations && n?.relations?.length > 0 ?
					<TalentGraphRender nodes={n.nodes} relations={n.relations} />
					:
					'Graph does not contain data'
			}
		</div>
	);
};