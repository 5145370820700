import styled from 'styled-components';

interface UIProps {
	//text styles
	textTransform?: string
	fontSize?: string
	fontFamily?:string

	//box styles
	width?:string
	height?:string
	borderRadius?: string
	padding?:string
	margin?:string

	//color styles
	fontColor?:string
	color?:string

	border?:string

}

/**
 * Edit any of the following params as CSS strings for values:
 * - textTransform
 * - fontSize
 * - borderRadius
 * - height
 */
export const Tag:any = styled.span<UIProps>`
  
	font-size: ${(p:UIProps):string =>  p.fontSize || '14px' };
	text-transform: ${(p:UIProps):string =>  p.textTransform || 'none' };
	border-radius: ${(p:UIProps):string =>  p.borderRadius || '20px' };
	height: ${(p:UIProps):string =>  p.height || '35px' };
  	margin: ${({margin}:UIProps):string => margin || '0 12px 0 0'};
  	white-space: nowrap;

  	color: ${({fontColor, theme: {colors}}:any):string => colors[fontColor] || colors.white};
  	background: ${({color, theme: {colors}}:any):string => colors[color] || colors.grey_800};
	
	font-family: 'Urbanist', sans-serif;
  	font-weight: bold;
	
  	display: inline-flex;
	align-items: center;
	padding: 0 1rem;
`;

export const Tabs:any = styled.div`
	height: 45px;
  	background: ${({theme: {colors}}:any):string => colors.grey_900};
  	padding: 5px;
  	border-radius: 30px;
  	display: flex;
`;

type TabProps = {
	selected?: boolean
	activeColor?: string,
	theme:any
}
export const Tab:any = styled.div<TabProps>`
	flex-grow: 1;
  	height: 35px;
  	background: ${({selected, theme: {colors},activeColor}:TabProps):string => selected ?  activeColor || colors.red : ''};
  	padding: 0 20px;
  	border-radius: 30px;
  	display: flex;
  	align-items: center;
  	justify-content: center;
  	text-align: center;
  	font-weight: bold;
  	font-family: 'Urbanist', sans-serif;
  	font-size: 14px;
  	cursor: pointer;
`;

/**
 * - Include color from the `"theme.colors"` list
 * - Include margin as a css string.
 * - Onclude width as a css string
 */
export const Line:any = styled.div<UIProps>`
	height: 1px;
  	border-bottom: 1px solid;
  	border-color: ${({color, theme: {colors}}:any):string => colors[color] || colors.white};
  	width: ${({width}:UIProps):string => width || 'auto'};
  	margin: ${({margin}:UIProps):string => margin || '0'};
`;

export const Avatar:any = styled.div<UIProps>`
  	width: ${({width}:UIProps):string => width || '45px'};
  	margin: ${({margin}:UIProps):string => margin || '0'};
  	height: ${({height}:UIProps):string => height || '45px'};
  	border-radius: ${({borderRadius}:UIProps):string => borderRadius || '50%'};
  	overflow: hidden;
  	flex-shrink: 0;
  	display: flex;
   	align-items: center;
  	justify-content: center;
  	font-size: 12px;
  	background: ${({color, theme: {colors}}:any):string => colors[color] || colors.grey_800};
  	border: ${({color, theme: {colors}}:any):string => `${colors.grey_800} solid`};
  	border-width: ${({borderWidth}:any):string => borderWidth || `0`};
  
  	img{
	  	object-fit: cover;
	  	object-position: center;
	  	display: flex;
	  	flex-grow: 1;
	  	height: 100%;
	}
`;

export const SidebarAvatar:any = styled.div<UIProps>`
	margin: 90px 0 70px;
  	padding: 6px;
  	background: ${({color, theme: {colors}}:any):string => colors[color] || colors.grey_820};
  	border-radius: 50%;
`;


export const Circle:any = styled.div<UIProps>`

  	width: ${({width}:UIProps):string => width || '2rem'};
  	height: ${({height}:UIProps):string => height || '2rem'};	
  	background: ${({color, theme: {colors}}:any):string => colors[color] || colors.grey_800};
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 2px 10px rgba(0,0,0,0.3);
  
`;

export const ScoreLineCircle:any = styled.div<UIProps>`
  	width: ${({width}:UIProps):string => width || '45px'};
  	height: ${({height}:UIProps):string => height || '45px'};
  	margin: ${({margin}:UIProps):string => margin || '0 0 0 8px'};
  	border: 3px solid;
  	border-color: ${({color, theme: {colors}}:any):string => colors[color] || colors.red};
  	border-radius: 50%;
  	flex-shrink: 0;
  	display: flex;
  	align-items: center;
  	justify-content: center;
  	font-size: ${({fontSize}:UIProps):string => fontSize || '16px'};;
  	font-family: 'Urbanist', sans-serif;
  	font-weight: bold;
`;


/*
Head
 */
export const CircleHero:any = styled.div`
  width: ${({width}:UIProps):string => width || '500px'};
  height: ${({height}:UIProps):string => height || '500px'};
  border: ${({border, theme: {colors}}:any):string => border || `12px solid ${colors.grey_850}`};
  background: ${({background, theme: {colors}}:any):string => colors[background] || colors.grey_900};
  margin: ${({margin}:UIProps):string => margin || '0 0 40px'};
  border-radius: 50%;
  overflow: hidden;

  img{
    object-fit: cover;
    object-position: center;
    display: flex;
    flex-grow: 1;
    height: 100%;
  }

  video{
    object-fit: cover;
    object-position: center;
    display: flex;
    flex-grow: 1;
    height: 100%;
	width: 100%;
  }
  
`;


/*
Forms
 */

// border-color: ${({color, theme: {colors}}:any):string => colors[color] || colors.red};
export const Input:any = styled.input<UIProps>`
  	height: ${({height}:UIProps):string => height || '54px'};
  	margin: ${({margin}:UIProps):string => margin || '0'};
  	padding: ${({padding}:UIProps):string => padding || '0 24px'};
  	background: ${({color, theme: {colors}}:any):string => colors[color] || colors.grey_850};
  	border-radius: 8px;
  	width: 100%;
  	box-sizing: border-box;
  	color:#626579;
  	font-size: 14px;
  	font-family: 'Urbanist', sans-serif;
  	font-weight: 500;
	border: 0;
  	display: block;
  
`;


export const Button:any = styled.button<UIProps>`
  	height: ${({height}:UIProps):string => height || '54px'};
  	margin: ${({margin}:UIProps):string => margin || '0'};
  	padding: ${({padding}:UIProps):string => padding || '0 24px'};
  	width: ${({width}:UIProps):string => width || '100%'};
  	display: flex;
  	justify-content: center;
  	align-items: center;
  
  	background: ${({color, theme: {colors}}:any):string => colors[color] || colors.light_grey_200};
  	color: ${({color, theme: {colors}}:any):string => colors[color] || colors.grey_1000};
  	border-radius: 8px;
  	
  	box-sizing: border-box;
  	font-size: 16px;
  	font-family: ${({fontFamily}:UIProps):string => fontFamily || "'Urbanist', sans-serif"};
  	font-weight: 900;
	border: 1px solid white;
  	cursor: pointer;
  
`;

export const ButtonSmall:any = styled(Button)<UIProps>`
  	height: ${({height}:UIProps):string => height || '36px'};
  	padding: ${({padding}:UIProps):string => padding || '0 50px'};
  	margin: ${({margin}:UIProps):string => margin || '0'};
  	width: ${({width}:UIProps):string => width || '100%'};
  	font-size: 14px;
  	font-family: 'Lato', 'Urbanist', sans-serif;
  	font-weight: 700;
`;