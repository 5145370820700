import React from 'react';
import styled from 'styled-components';
import {mediaQuery} from '../../styles/mediaQueries';

interface GridProps {
	cols?:number
	rows?:number
	gap?:string
}

interface ItemsCollectionProps extends GridProps{
	children: React.ReactNode
}

const ItemsContainer:any=styled.div.attrs((props:GridProps):any=>({
	cols:`repeat(${props.cols},1fr)`,
	rows: `repeat(${props.rows},1fr)`,
	gap:props.gap||'1rem'
}))`
  display: grid;
  grid-template-columns:${({cols}:any):string=>cols};
  grid-template-rows:${({rows}:any):string=>rows};
  justify-items: stretch;
  grid-gap: 1rem;
  ${mediaQuery('laptop')}{
    grid-template-columns:repeat(4,1fr);
  }
  ${mediaQuery('tablet')}{
    grid-template-columns:repeat(2,1fr);
  }
  ${mediaQuery('mobile')}{
	display: block;
	>*{
	  margin-bottom:1rem ;
	}
  }
`;

export const ItemsCollection = ({children,cols=4,rows=1}:ItemsCollectionProps):JSX.Element => {
	return <ItemsContainer
		cols={cols}
		rows={rows}
	>
		{children}
	</ItemsContainer>;
};