import React, {useContext, useState} from 'react';
import {FormButton, MultiCheckButton} from 'components/ui';
import {UserContext, userContextInterface} from 'context/userContext';
import {FormField, FormHook} from 'hooks/useForm/interfaces';
import {useForm} from 'hooks/useForm/useForm';
import {GenericHeader, Message} from 'components/index';
import {color} from 'styles/colors';
import {CloseIcon} from 'icons';
import {Item} from 'interfaces';
import {MutationTuple, QueryResult, useMutation, useQuery} from '@apollo/client';
import {query} from 'actions/queries';
import {mutation} from 'actions/mutations';

interface AddToListProps{
	talentId:string
	close:()=>void
	goToCreate:()=>void
}


export const AddToList = ({talentId,close,goToCreate}:AddToListProps):JSX.Element => {
	const [error,setError]=useState('');
	const {user}:userContextInterface=useContext(UserContext);
	const {data:listsData,loading:listsLoading,error:listsError}:QueryResult=useQuery(query('listFindMany'),{
		variables:{
			filter:{
				user:user._id
			},
			skip:0,
			limit:100
		},
		fetchPolicy:'network-only'
	});

	const [addTalent]:MutationTuple<any, any>=useMutation(mutation('listAddUserOne'), {
		refetchQueries:['listFindMany']
	});

	const  formFields:FormField[]=[
		{name:'talent', required:true,default:talentId},
		{name:'list', required:true, default:[]}
	];


	const addToList=async(addData:any):Promise<void> =>{
		let variables:any={id:addData.list[0],user:addData.talent};
		if( listsData?.listFindMany.includes(addData.list[0]) ) {
			setError('this list contains this talent already');
			return;
		}
		console.log('will do');
		await addTalent({variables});
		console.log('done');
		close();
	};

	const  {errors,loading,registerList,...rest}:FormHook=useForm(formFields,addToList);

	if(!listsData|| listsLoading || listsError)
		return <p>Loading...</p>;
	const listsOptions:Item[]=listsData.listFindMany?.map((list:any):Item=>(
		{id:list._id,label:list.name}
	)) || [];

	return<>

		<GenericHeader title={'Add to list'} action={close} Element={CloseIcon}/>
		<form
			className={'w-100 flex direction-column align-center mt'}
			onSubmit={rest.handleSubmitForm}
		>
			<div className={'w-100 mt'}>
				<p className={'f-small mb'}>
					Select a list or
					<span
						className={'f-700 t-primary cursor-pointer'}
						onClick={goToCreate}
					> Create a NEW LIST</span>
				</p>
			</div>
			<MultiCheckButton
				onChange={({name,value}:any):void=>console.log({name,value})}
				max={1}
				options={listsOptions}
				{...registerList('list')}
			/>
			{listsOptions.length > 0 &&
				<FormButton
					loading={loading}
					label={'Add To List'}
				/>
			}
			{errors.get('list') && <Message message={'Please select a list'} color={color.get('primary')}/>}
			{errors.get('submit') && <Message message={'error has occurred'} color={color.get('primary')}/>}
			{error && <Message message={error} color={color.get('primary')}/>}
		</form>
	</>;
};