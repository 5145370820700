import React from 'react';

interface SearchProps {
	color?:string
	size?:number
	className?:string
}

export const SearchIcon=({color='white',size=16,className='white'}:SearchProps):JSX.Element=>
	<svg width={size} height={size} viewBox="0 0 16 16" fill={color} xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M2.25 7C2.25 4.23858 4.48858 2 7.25 2C10.0114 2 12.25 4.23858 12.25 7C12.25 8.34069 11.7223 9.55813 10.8633 10.456C10.8346 10.479 10.8068 10.5038 10.7802 10.5304C10.7536 10.5571 10.7288 10.5848 10.7058 10.6135C9.80794 11.4724 8.59058 12 7.25 12C4.48858 12 2.25 9.76142 2.25 7ZM11.442 12.6065C10.2735 13.4816 8.82227 14 7.25 14C3.38401 14 0.25 10.866 0.25 7C0.25 3.13401 3.38401 0 7.25 0C11.116 0 14.25 3.13401 14.25 7C14.25 8.5724 13.7316 10.0237 12.8563 11.1923L15.4569 13.7929C15.8474 14.1835 15.8474 14.8166 15.4569 15.2072C15.0664 15.5977 14.4332 15.5977 14.0427 15.2072L11.442 12.6065Z" />
	</svg>
;
