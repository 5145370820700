import {FormEvent, useEffect, useMemo, useState} from 'react';
import {
	FormField,
	FormHook,
	ChangeEvent,
	ErrorsHook,
	Register,
	ListObjInput,
	fieldValue,
	listFieldValue, ValuesMap, defaultMap, defaultValue, ErrorsMap
} from './interfaces';
import {useErrors} from './useErrors';

const getDefaultValue = (value:any, isArray:boolean): string| any[] =>{
	if (isArray){
		return value ? value : [];
	}
	return value ? value : '';

};

export const useForm = (fields: Array<FormField>, submitAction: (data: any) => void, deactivatable?:boolean):FormHook => {
	const values:ValuesMap=new Map<string, fieldValue | listFieldValue>();
	const defaultValues:defaultMap= new Map<string, defaultValue>();
	fields.forEach((field:FormField):void=> {
		defaultValues.set(field.name, getDefaultValue(field.default,field.array));
		values.set(field.name, getDefaultValue(field.default,field.array));
	});


	const initialValues:any=Object.fromEntries(values);
	const [loading,setLoading]:any = useState(false);
	const {errors:err,getFormErrors,setSubmitError}:ErrorsHook = useErrors();
	const [errors,setErrors]= useState(err);
	const [hasChanges,setHasChanges]=useState(false);

	const [data,setData]:any = useState(initialValues);

	useMemo(():void=>{
		if(JSON.stringify(data) !== JSON.stringify(initialValues)) {
			setData((): any => Object.fromEntries(defaultValues));
		}
	},[JSON.stringify(initialValues)]);

	useEffect(():void=>{
		if(deactivatable && JSON.stringify(data)!==JSON.stringify(Object.fromEntries(values))) {
			setHasChanges(true);
			validateForm();
		}
		setSubmitError(null);
	},[data]);

	const onChangeInput = ({target}: ChangeEvent<HTMLInputElement>['target']):void|null =>{
		if (data[target.name]===undefined){
			return null;
		}
		if(target.type === 'checkbox') {
			return setData((prev:any):any=>({...prev, [target.id]: target.checked}));
		}
		setData((prev:any):any=>({...prev, [target.name]:target.value}));
	};

	const onChangeField = (value:string|number|boolean,name:string):void =>{
		setData((prev:any):any=>({...prev, [name||'badInput']:value}));
	};

	const onChangeSelect = ({target}: ChangeEvent<HTMLSelectElement>['target']):void|null =>{
		if (data[target.name]===undefined){
			return null;
		}
		setData((prev:any):any=>({...prev, [target.name]:target.value}));
	};

	const onChangeTextArea = ({target}: ChangeEvent<HTMLTextAreaElement>['target']):void|null =>{
		if (data[target.name]===undefined){
			return null;
		}
		setData((prev:any):any=>({...prev, [target.name]:target.value}));
	};

	const onChangeList = ({name, value}:any):void=>{
		setData((prev:any):any=>({...prev, [name]:value}));
	};

	const onBlur=():void=>{
		getFormErrors(fields,data);
	};

	const validateForm=():boolean=>{

		const errorMap:ErrorsMap=getFormErrors(fields,data);
		setErrors(errorMap);
		return !errorMap.size;
	};

	const handleSubmitForm=async(e:FormEvent):Promise<void> =>{
		e.preventDefault();
		const isValid:boolean=validateForm();
		setSubmitError(null);
		setLoading(true);
		if(isValid){
			try {
				await submitAction({...data});
			}
			catch (e) {
				console.log('submit',{error:e});
				setErrors(setSubmitError(e));
			}
		}
		setLoading(false);
	};

	const registerInput=(name:string):Register<HTMLInputElement> =>({
		name,
		value:data[name]||'',
		valid:!errors.get(name),
		onChange:onChangeInput,
		onBlur
	});

	const registerSelect=(name:string):Register<HTMLSelectElement> =>({
		name,
		value:data[name],
		valid:!errors.get(name),
		onChange:onChangeSelect,
		onBlur:onBlur
	});

	const registerTextArea=(name:string):Register<HTMLTextAreaElement> =>({
		name,
		value:data[name],
		valid:!errors.get(name),
		onChange:onChangeTextArea,
		onBlur:onBlur
	});

	const registerList=(name:string):ListObjInput =>({
		name,
		value:data[name],
		valid:!errors.get(name),
		onChange:onChangeList,
		onBlur:onBlur
	});


	const setformState=(data?: Record<string,fieldValue>):void=>{
		console.log('resetForm',{data});
		if(data){
			const values:ValuesMap=new Map<string, fieldValue | listFieldValue>();
			const defaultValues:defaultMap= new Map<string, defaultValue>();
			fields.forEach((field:FormField):void=> {
				defaultValues.set(field.name, getDefaultValue(field.default,field.array));
				values.set(field.name, getDefaultValue(field.default,field.array));
			});
			const initialValues:any=Object.fromEntries(values);
			setData(initialValues);
		}else{
			setData(initialValues);
		}
	};

	return{
		data,errors,loading,hasChanges,defaultValues,handleSubmitForm,onChangeInput,onChangeSelect,onChangeTextArea,onChangeList,onChangeField,onBlurInput: onBlur,
		registerInput,registerSelect,registerTextArea,registerList,
		setFormData:(data:Record<string,fieldValue>):void=>setData(data),
		resetForm:setformState
	};
};
