import {color} from 'styles/colors';
import styled,{css} from 'styled-components';
import {themeOpus} from '../../stylesOpus/theme';

type WrapperProps = {
	localTheme?: 'opus' | 'opusV2'
};

type Theme =Record <string,string>;

const opusTheme: Record <string,Theme> ={
	opus: {
		height: '64px',
		background: color.get('black'),
		border: `1px solid ${color.get('neutral-500')}`,
		fontFamily: 'Inter, sans-serif',
		borderRadius: '8px'
	},
	opusV2: {
		height: 'auto',
		background: themeOpus.colors.grey_840,
		border: `none`,
		fontFamily: 'Urbanist, sans-serif',
		borderRadius: '16px'
	}
};
const setTheme =(theme: 'opus'|'opusV2'= 'opus'):Theme=>opusTheme[theme];



export const inputWrapper:any=css<WrapperProps>`
  width: 100%;
  height: ${ ({localTheme}:WrapperProps):string=>setTheme(localTheme).height};
  display:flex;
  align-items: center;
	
  padding: 12px 24px 12px 24px ;
  margin: 0 0 1rem;
  background-color:${ ({localTheme}:WrapperProps):string=>setTheme(localTheme).background}; 
  border:${ ({localTheme}:WrapperProps):string=>setTheme(localTheme).border}; 
  border-radius :${ ({localTheme}:WrapperProps):string=>setTheme(localTheme).borderRadius};
	
	font-family: 'Urbanist', sans-serif;
  :focus-within{
    border-color: ${color.get('primary')};
  }


  >div{
    display:flex;
    flex-direction:column;
  }
  >div:nth-child(1){
    flex-grow: 1;
    >div{
      display: flex;
      justify-content: space-between;
    }
  }
  >div:nth-child(2){
    align-items: center;
  }
`;

export const InputWrapper:any=styled.fieldset<WrapperProps>`
	${inputWrapper};
`;

export const inputField:any=css`
  -moz-appearance: textfield;
  width: 100%;
  align-self: end;
  border: none;
  color: white;
  font-size: 0.875rem;
  font-weight: 700;
  background-color: transparent;
 font-family: 'Urbanist', sans-serif;
  :focus-visible{
    border: none;
  }
  :focus{
    outline: none;
  }
  ::placeholder{
    /*color: ${color.get('neutral-400')};*/
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus{
    -webkit-text-fill-color: white;
    background-color: transparent ;
    transition: background-color 100000000000s ease-in-out 0s;
  }
	&[type="date"]::-webkit-calendar-picker-indicator {
		filter: invert(80%);
		:hover{
			cursor:pointer;
		}
	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	&[type=number] {
		-moz-appearance: textfield;
	}
`;

export const Input:any=styled.input`
	${inputField}
`;

export const Label:any=styled.label`
  font-size: 0.625rem;
  font-weight: 800;
  color: #fff;
  text-transform: uppercase;
`;

export const RequiredText:any=styled.p`
  font-size: 0.625rem;
  color: ${color.get('neutral-400')};
`;