import React from 'react';

export default function GradientBox({size=500}:any):JSX.Element{
	return (
		<svg stroke="none" width={size} height={size/2} viewBox="0 0 503 213" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M579 26.625L551.75 53.25C524.5 79.875 470 133.125 415.5 146.437C361 159.75 306.5 133.125 252 102.062C197.5 71 143 35.5 88.5 17.75C34 0 -20.5 0 -47.75 0H-75V213H-47.75C-20.5 213 34 213 88.5 213C143 213 197.5 213 252 213C306.5 213 361 213 415.5 213C470 213 524.5 213 551.75 213H579V26.625Z" fill="url(#paint0_linear_5777_1031)" fillOpacity="0.5"/>
			<defs>
				<linearGradient id="paint0_linear_5777_1031" x1="252" y1="0" x2="252" y2="213" gradientUnits="userSpaceOnUse">
					<stop stopColor="#22232B"/>
					<stop offset="1" stopColor="#22232B" stopOpacity="0"/>
				</linearGradient>
			</defs>
		</svg>
	);
}