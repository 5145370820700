import React, {useEffect, useState} from 'react';
import {SetterOrUpdater, useSetRecoilState} from 'recoil';
import {BreadCrumbAtom, TCrumb} from '../../context/recoil/store';
import {HotListRender} from './HotListRender';
import {opusContent} from '../../actions/strapiActions';

export const HotList = ():JSX.Element => {
	const setBreadCrumb: SetterOrUpdater<TCrumb[]>=useSetRecoilState(BreadCrumbAtom);
	const [loading, setLoading] = useState<boolean>(true);
	const [home, setHome] = useState();

	useEffect(():void => {
		setBreadCrumb([
			{id: 'hotlist', label: 'hotlist', path:'/hotlist'}
		]);
	}, []);

	useEffect(():void => {
		if(loading){
			getHomeData();
		}
	}, [loading]);

	const getHomeData = async():Promise<any> => {
		const h:any = await opusContent('hotlist-home', {
			'populate[0]' : 'tagRow',
			'populate[1]' : 'Feed',
			'populate[2]' : 'tagRow.cover',
			'populate[3]' : 'tagRow.tags',
			'populate[4]' : 'Feed.tags'
		});
		setLoading(false);
		setHome(h?.data?.data?.attributes);
	};

	return (
		<HotListRender
			homeData={home}
		/>
	);
};
