import React, {useContext} from 'react';
import {AppendableSelect, FieldSection, FormButton, Input, LikeCheckList, Select} from 'components/ui';
import {FormProps} from 'interfaces';
import {CampaignSuccessModal} from '../../Components/CampaignSuccessModal';
import {GeneralDataContext, GeneralDataContextInterface} from 'context/genalDataContext';
import styled from 'styled-components';
import {buttonStyles} from 'styles/ui';
import {themeOpus} from 'stylesOpus/theme';
import {REACH_TIERS_OPTIONS} from 'constants/constants';

type OpporunityTalentFormProps = {
	modal : {
		show : boolean
		searchState: any
	}
}& FormProps

const CompensationLabel:any = styled.p`
	${buttonStyles};
	width: auto;
	height: auto;
	display: flex;
	padding: 0.5rem 1.5rem;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
	font-family: 'Urbanist', sans-serif;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 700 !important;
	line-height: 137.5%; /* 1.20313rem */
	letter-spacing: 0.025rem;
	color: white !important;
	background-color: ${themeOpus.colors.grey_600};
`;

const FormContainer:any = styled.form`
	display: flex;
	flex-direction: column;
	row-gap: 4rem;
	margin:  2rem 0;
	
`;

export default function CampaignTalentRender({formData,modal,submitLoading,onSubmit,registerInput,registerList,registerSelect}:OpporunityTalentFormProps):JSX.Element {
	const { languages }:GeneralDataContextInterface  = useContext(GeneralDataContext);
	return (
		<FormContainer onSubmit={onSubmit} autoComplete='off' >

			<CampaignSuccessModal
				show={modal.show}
				searchState={modal.searchState}
			/>
			<FieldSection
				label = {'Compensation'}
				helperText={'Enter the amount of compensation you’re offering to Talent.'}
			>
				<Input
					type={'number'}
					label={'Talent Compensation'}
					placeholder={'Enter amount'}
					appendableElement={formData.compensation &&<CompensationLabel>
						$ {formData.compensation}
					</CompensationLabel>}
					{...registerInput('compensation')}
				/>
			</FieldSection>
			<FieldSection
				label = {'Total Audience Reach'}
				helperText={'Select the desire reach of the Talent.'}
			>
				<Select
					label={'Select Desired Reach'}
					placeholder={'All'}
					theme={'opusV2'}

					options={REACH_TIERS_OPTIONS}
					{...registerSelect('totalReach')}
				/>

			</FieldSection>
			{/*<FieldSection*/}
			{/*	label = {'Language(s)'}*/}
			{/*	helperText={'Lorem ipsum dolor sit amet consectetur. In auctor enim lobortis diam varius. sit amet consectetur.'}*/}
			{/*>*/}
			{/*	<AppendableSelect*/}
			{/*		theme={'opusV2'}*/}
			{/*		placeholder={'add another language'}*/}
			{/*		options={languages}*/}
			{/*		{...registerList('language')}*/}
			{/*	/>*/}
			{/*</FieldSection>*/}

			{/*<FieldSection*/}
			{/*	label = {'Social Channel'}*/}
			{/*	helperText={'Select the social channels that you’d like your talent to have a presence on. Select all that apply.'}*/}
			{/*>*/}
			{/*	<LikeCheckList*/}
			{/*		options={[*/}
			{/*			{id:'instagram', label:'Instagram'},*/}
			{/*			{id:'tiktok', label:'TikTok'},*/}
			{/*			{id:'youtube', label:'Youtube'}*/}
			{/*		]}*/}
			{/*		value={['instagram']}*/}
			{/*	/>*/}
			{/*</FieldSection>*/}

			<div className="w-100 flex justify-center pver-2 align-center">
				<FormButton
					variant={'white'}
					loading={submitLoading}
				>
					Finish
				</FormButton>
				{/*<div className="grows justify-end flex">*/}
				{/*	<Stepper steps={3} current={3} />*/}
				{/*</div>*/}
			</div>
		</FormContainer>
	);
}