import React from 'react';
import {FormField, FormHook} from 'hooks/useForm/interfaces';
import {MutationTuple, useMutation} from '@apollo/client';
import {mutation} from 'actions/mutations';
import {FormButton, Input} from 'components/ui';
import {Message} from 'components';
import {color} from 'styles/colors';
import {useForm} from 'hooks/useForm/useForm';

interface CreateListProps{
	onCreate:(listData:any)=>void
}
export  const CreateListForm = ({ onCreate } : CreateListProps): JSX.Element => {
	const  formFields:FormField[]=[
		{name:'name', required:true,default:''}
	];
	const [createList]:MutationTuple<any, any>=useMutation(mutation('listCreateOne'),
		{ refetchQueries:['listFindMany']} );

	const handleCreateList=async(listData:any):Promise<void> =>{
		console.log({listData});
		if(!listData.name)
			return ;
		const list: any = await createList({
			variables:{name:listData.name}
		});
		onCreate(list.listCreateOne);

	};
	const  {errors,loading,registerInput,...rest}:FormHook=useForm(formFields,handleCreateList);

	return (
		<form
			className={'flex direction-column align-center mt'}
			onSubmit={rest.handleSubmitForm}
		>
			<p className={'f-small mb'}>Enter your Opportunity Title to create a new list of new Talent matches.</p>
			<Input
				label={'List Name'}
				placeholder={'Name'}
				{...registerInput('name')}
			/>
			<FormButton
				loading={loading}
				label={'Save List'}
			/>
			{errors.get('name') && <Message message={'Name is Required'} color={color.get('primary')}/>}
		</form>
	);
};