import React, {useContext, useEffect, useState} from 'react';
import AudienceRender from './AudienceRender';
import {QueryResult, useQuery} from '@apollo/client';
import {query} from 'actions/queries';
import {useOutletContext} from 'react-router-dom';
import {AudienceAyrShare} from '../AudienceAyrShare';
import {UserContext, userContextInterface} from '../../../../context/userContext';
import Insights from '../../../../components/alerts/Insights';


export const Audience=():JSX.Element=>{

	const {user}:userContextInterface=useContext(UserContext);
	const [reach,setTReach]=useState<number>(0);

	const {data:followersData}:QueryResult=useQuery(query('socialInfoFollowersFindOne'));
	const {instagramAyrShare,socialCards}:any=useOutletContext();
	useEffect(():void=>{
		if(followersData?.socialInfoFollowersFindOne){
			setTReach(followersData.socialInfoFollowersFindOne[0]?.value);
		}
	},[followersData]);

	// if(!followersData?.socialInfoFollowersFindOne?.length && !instagramAyrShare){
	// 	return <div className='w-100'>
	// 		<h4 className='center'> This User do not have audience data</h4>
	// 	</div>;
	// }
	// console.log({instagramAyrShare});
	return instagramAyrShare?
		<AudienceAyrShare socialCards={socialCards} instagramAyrShare={instagramAyrShare}/>
		// :<AudienceRender reach={reach}/>;
		: <div className="max-w-940px"><Insights id={user._id} /></div>;
};
