import React from 'react';
import styled from 'styled-components';
import {themeOpus} from 'stylesOpus/theme';

type OnboardingFormProps = {
    title?: string
    subtitle?: string
    children?: React.ReactNode
} & React.FormHTMLAttributes<HTMLFormElement>;


const FormContainer:any = styled.section`
    gap: 3.5rem;
`;

const FormTitle:any = styled.h2`
  color: #FFF;

  font-family: 'lato', sans-serif;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 137.5%; /* 3.4375rem */
`;

const FormSubTitle:any = styled.p`
  color: ${themeOpus.colors.grey_200};

  text-align: center;
  font-family: Lato;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 137.5%; /* 1.375rem */
`;
export const OnboardingForm = ({title,subtitle,children,...props}:OnboardingFormProps):JSX.Element => {

	return (
		<FormContainer className={'center flex direction-column align-center'}>
			<div className={'w-100 flex direction-column row-gap'}>
				<FormTitle>{title}</FormTitle>
				<FormSubTitle>{subtitle}</FormSubTitle>
			</div>
			<form
				className={`mt w-100 flex direction-column align-center row-gap`}
				{...props}
			>
				{children}
			</form>
		</FormContainer>
	);
};