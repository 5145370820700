import React from 'react';
import RecentPostsRender from './RecentPostsRender';

export const RecentPosts=():JSX.Element=>{

	return <RecentPostsRender
		posts={[
			{
				url:'https://d2r55xnwy6nx47.cloudfront.net/uploads/2020/07/Photosynthesis_2880_Lede.jpg',
				type:'image'
			},
			{
				url:'https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.webm',
				type:'video'
			},
			{
				url:'https://d2r55xnwy6nx47.cloudfront.net/uploads/2020/07/Photosynthesis_2880_Lede.jpg',
				type:'image'
			},
			{
				url:'https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.webm',
				type:'video'
			}
		]}
	/>;
};
