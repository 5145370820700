import React, {FC} from 'react';
import {IconProps} from './IconsInterfaces';

export const HomeIcon :FC<IconProps> =({size=17,color}:IconProps):JSX.Element=>{

	return(
		<svg width={size} height={size} viewBox="0 0 16 17" fill= {color} xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M8.61394 0.210648C8.25283 -0.0702159 7.74717 -0.0702159 7.38606 0.210648L0.636059 5.46065C0.392473 5.6501 0.25 5.94141 0.25 6.25V14.5C0.25 15.163 0.513392 15.7989 0.982233 16.2678C1.45107 16.7366 2.08696 17 2.75 17H5.75H10.25H13.25C13.913 17 14.5489 16.7366 15.0178 16.2678C15.4866 15.7989 15.75 15.163 15.75 14.5V6.25C15.75 5.94141 15.6075 5.6501 15.3639 5.46065L8.61394 0.210648ZM11.25 15H13.25C13.3826 15 13.5098 14.9473 13.6036 14.8536C13.6973 14.7598 13.75 14.6326 13.75 14.5V6.73908L8 2.26686L2.25 6.73908V14.5C2.25 14.6326 2.30268 14.7598 2.39645 14.8536C2.49022 14.9473 2.61739 15 2.75 15H4.75V8.5C4.75 7.94772 5.19772 7.5 5.75 7.5H10.25C10.8023 7.5 11.25 7.94772 11.25 8.5V15ZM6.75 15V9.5H9.25V15H6.75Z"/>
		</svg>

	);
};
