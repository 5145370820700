import styled from 'styled-components';
import {color} from 'styles/colors';

export const AvatarWrapper:any=styled.div`
  display: flex;
  justify-content: center;
  padding: 0 50px 1rem 30px ;
`;

export const Content:any=styled.div`
  	>h3{
	  text-transform: capitalize;
	}
  	>p:nth-child(2){
	  font-size: 12px;
	  font-weight: 700;
	  line-height: 17.52px;
	  color: ${color.get('neutral-400')};
	  text-transform: uppercase;
	}
  	>p:last-child{
	  margin-top: 1rem;
      font-size: 12px;
      line-height: 22px;
	  >span{
		text-decoration: underline;
		color: ${color.get('neutral-400')};
	  }
	}
`;
