import React from 'react';
import {BodyText, BoxTitle} from '../../../stylesOpus/typography';
import {ContentBox} from '../../../stylesOpus/layout';

interface OpportunityBoxProps{
	title?: string
	description?: string
	children?: JSX.Element
	visible?: boolean
}

export default function OpportunityBox({title, description, children, visible}:OpportunityBoxProps):JSX.Element {
	return (
		<ContentBox className="mver" visible={visible}>
			<BoxTitle className='mb-quarter'>
				{title}
			</BoxTitle>
			<BodyText>
				{description}
			</BodyText>
			{children}
		</ContentBox>
	);
}