import {useEffect, useRef, MutableRefObject} from 'react';

export default function(action:()=>void):MutableRefObject<any | null> {
	// create ref
	const ref:MutableRefObject<any | null> = useRef(null);
	// action
	const handleClick = (event:MouseEvent):void => {
		if (ref.current && !ref.current.contains(event.target as Node)) {
			action();
		}
	};
	useEffect(():()=>void => {
		// bind event listener
		document.addEventListener('mousedown', handleClick);
		return ():void => {
			// unbind event listener
			document.removeEventListener('mousedown', handleClick);
		};
	}, []);
	return ref;
}

