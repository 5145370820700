import styled from 'styled-components';
import {color} from 'styles/colors';

interface DividerProps {
	margin?: string
}

export const StyledDivider:any=styled.hr<DividerProps>`
	width: 100%;
  	height: 1px;
    margin: ${(p:DividerProps):string => p.margin ? p.margin : '1.5rem 0'};
  	border:none;
  	background-color:#5c5f80;
	opacity: 0.3;
`;