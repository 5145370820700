import {inputWrapper} from 'styles/ui';
import styled from 'styled-components';
import {color} from 'styles/colors';

export const SocialWrapper:any=styled.fieldset`
  ${inputWrapper};
  background-color: ${color.get('neutral-600')};
  border-color: ${color.get('neutral-600')};
  justify-content: space-between;
  font-weight: 800;
  max-width: 355px;
  >div{
	display: flex;
	align-items: center;
	flex-direction: row;
    grid-column-gap: 1rem;
	>div{
	  flex-direction: column;
	  justify-content: center;
	}
  }
`;

export const SocialLabel:any=styled.p`
  font-size: 0.875rem;
  line-height: 150%;
`;

export const SocialSubLabel:any=styled.p`
  color: ${color.get('neutral-300')};
  font-size: 0.5rem;
  line-height: 0.625rem;
`;

export const IconWrapper:any=styled.div`
  ${({pointer}:any):string=>pointer && 'cursor:pointer;'}
`;



