import React from 'react';
import {Step, StepsWrapper} from './styles';

interface RangeProps{
	size?:string;
	steps:number;
	currentStep:number;
	className?:string;
}

export const Steps=({size,steps,currentStep,className}:RangeProps):JSX.Element=>{
	let array:any[]=Array.from(Array(steps).keys());
	return (
		<StepsWrapper
			size={size}
			steps={steps}
			className={className}
		>
			{array.map((_:any,i:number):JSX.Element=>
				<Step key={i} $active={(i+1===currentStep)} size={size}/>
			)}
		</StepsWrapper>
	);
};
