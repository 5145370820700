import React from 'react';
import {Section} from 'styles/styledComponents/containers';
import {BoxTitle} from 'stylesOpus/typography';
import {TacticI} from 'interfaces';
import SubtacticAlertRowLoad from '../../components/SubtacticAlertRow/SubtacticAlertRow.load';
import {UserContext, userContextInterface} from '../../context/userContext';

type OpportunitiesTacticRenderProps = {
	tactic?: TacticI
	subtactics?: TacticI[]
};

export const OpportunitiesTacticRender = ({tactic, subtactics}:OpportunitiesTacticRenderProps) :JSX.Element => {

	const {user} : userContextInterface = React.useContext(UserContext);

	return (
		<div>
			<div className={'grid2 col-gap-3 mb-2'}>
				{/*{photos?.length>0 ?
					<Carousel
						images={photos}
						width={'572px'}
					/>
					:null
				}*/}
				<div>
					{tactic?.cover && tactic?.cover.length > 0 &&
						<img src={tactic?.cover[tactic?.cover.length - 1]?.url} className="w-100 aspect-1-1 br obj-cover" />
					}
				</div>

				<div className =' flex direction-column justify-center grows pver-2 pr-2'>
					<BoxTitle  fontFamily={`Urbanist, 'sans-serif'`} fontWeight={'900'} fontSize={'32px'} className={'mb-2'}>
						{tactic?.title}
					</BoxTitle>
					<Section className ='pb'>
						<div className="tactic-d" dangerouslySetInnerHTML={{__html: tactic?.description || ''}} />
					</Section>
				</div>
			</div>

			{subtactics && subtactics?.length > 0 && subtactics.map((subtactic:TacticI):JSX.Element=>{
				return (
					<SubtacticAlertRowLoad
						key={subtactic?._id}
						subtactic={subtactic}
						filter={{
							active:true,
							subtactic:[subtactic?._id],
							tactic:[tactic?._id],
							ENDDATE_GREATERTHAN: new Date().toISOString(),
							_operators:{tenant:{in:user.tenantList}}
						}}
					/>
				);
			})}

		</div>
	);
};
