import styled from 'styled-components';

export const FormContainer:any = styled.div`
	height: calc(100vh - 92px);
  	overflow-y: scroll;
  	width: 340px;
  	position: absolute;
  	top:0;
  	left: 0;
  	padding-right: 15px;
  	padding-bottom: 75px;
`;

export const FieldGroup:any = styled.div`
  	border-bottom: ${({borderBottom, theme: {colors}}:any):string => borderBottom || `solid 1px ${colors.grey_600}`};
  
	h4{
		font-weight: 700;
		font-family: 'Urbanist', sans-serif;
		font-size: 14px;
	}
	p{
		font-size: 14px;
		font-family: 'Lato', sans-serif;
		font-weight: 400;
		line-height: 150%;
		color: ${({theme: {colors}}:any):string => `${colors.grey_200}`};
	}
`;

export const FieldGroupTitle:any = styled.a`
	padding: 24px 0;
  	display: flex;
  	align-items: center;
  	h3{
	  flex-grow: 1;
	  font-size: 1rem;
	  font-family: 'Urbanist', sans-serif;
	  font-weight: 800;
	}
`;

export const FieldError:any = styled.p`
	padding: 0.5rem 0;
  	color: ${({theme: {colors}}:any):string => `${colors.red}`} !important;
  	font-family: 'Urbanist', sans-serif;
  	font-size: 14px;
`;

export const ButtonContainer:any = styled.div`
	position: fixed;
  	bottom: 0px;
  	padding: 16px 0px;
  	z-index: 1000;
  	width: 324px;
  	background: ${({background, theme: {colors}}:any):string => background ? `${colors[background]}` : `${colors.grey_1000}`};
`;


export const TalentsContainer:any = styled.div`
	top:0;
  	left: 0;
  	position: absolute;
  	right: 0;
`;


export const InputBox:any = styled.div`
  	position: relative;
  	background: ${({background, theme: {colors}}:any):string => background ? `${colors[background]}` : `${colors.grey_900}`};
  	border-radius: ${({borderRadius}:any):string => borderRadius ? `${borderRadius}` : `16px;`};
  	font-weight: 700;
  	margin: ${({margin}:any):string => margin ? margin : '1rem 0 0'};
  	padding: ${({padding}:any):string => padding ? padding : '0'};
  	
  	label {
	  font-family: 'Urbanist', sans-serif;
	  font-size: 14px;
	  display: block;
	  color:#fff;
	}

	h4 {
		font-family: 'Urbanist', sans-serif;
		font-size: 14px;
		display: block;
		color:#fff;
	  	font-weight: 500;
	  	padding: 0 0 12px 0;
	}
  	
  	span {
      font-size: 14px;
	  font-weight: 600;
      color: ${({theme: {colors}}:any):string => `${colors.grey_300}`};
	}
`;

export const InterestBox:any = styled.div`
	padding: 16px 24px;
	display: flex;
	align-items: center;
	font-weight: bold;
	font-family: 'Urbanist', sans-serif;
	color: ${({theme: {colors}}:any):string => `${colors.white}`};
  	font-size: 14px;
`;

export const InputLabel:any = styled.a`
	padding: 12px 24px;
  	display: flex;
  	align-items: center;
  	span {
	  font-weight: 500;
	}
  	input{
	  border: 0;
	  background: transparent;
      font-size: 14px;
	  color:#fff;
	  font-family: 'Urbanist', sans-serif;
	  font-weight: normal;
	  stroke: none;
	  display: flex;
	  flex-grow: 1;
	}
  	input:focus{
	  outline: none;
	}
`;

export const InputMultiSelect:any = styled.a`
	padding: 12px 24px;
  	display: flex;
  	align-items: center;
  	font-weight: 500;
  	font-size: 14px;
  	color: white;
  	font-family: 'Urbanist', sans-serif;
`;


export const InputOptionsList:any = styled.div`
	border-top: ${({borderBottom, theme: {colors}}:any):string => borderBottom || `solid 1px ${colors.grey_600}`};
  	max-height: 210px;
  	overflow-y: scroll;
`;

export const InputOptionsListItem:any = styled.a`
	display: block;
  	padding: 12px 24px;
  	font-size: 14px;
  	font-family: 'Urbanist', sans-serif;
  	font-weight: 600;
  	:hover{
      background: ${({theme: {colors}}:any):string => `${colors.grey_840}`};
	}
`;

export const CheckListItem:any = styled.a`
	display: block;
  	padding: 12px 0px;
  	display: flex;
  	align-items: center;
  	font-size: 14px;
  	font-family: 'Urbanist', sans-serif;
  	font-weight: ${({fontWeight}:any):string => fontWeight ? fontWeight : `500`};
	color: ${({color, theme: {colors}}:any):string => color ? `${colors[color]}`: `${colors.grey_200}`};
`;

export const CheckBox:any = styled.div`
	display: block;
  	width: 14px;
  	height: 14px;
  	border: 1px solid #fff;
  	border-radius: 4px;
  	flex-shrink: 0;
  	margin-right: 8px;
  	background: ${({background, theme: {colors}}:any):string => background ? `${colors[background]}` : 'transparent'};
`;


export const CircleContainer:any = styled.div`
  	width:35px;
  	height: 35px; 
  	border-radius:50%;
  	display: flex;
  	align-items: center;
  	justify-content: center;
  	background: ${({background, theme: {colors}}:any):string => background ? `${colors[background]}` : `${colors.grey_900}`};
`;

export const MultiSelectTag:any = styled.a`
	padding: 4px 16px;
  	font-size: 12px;
  	font-family: 'Urbanist', sans-serif;
  	font-weight: normal;
  	color: #fff;
  	display: inline-flex;
  	align-items: center;
  	border-radius: 100px;
  	margin-right: 4px;
  	margin-bottom: 8px;
  	background: ${({theme: {colors}}:any):string => colors.grey_900};
  	
  	svg {
	  margin-left: 8px;
	}
`;