import React, {createContext, useState} from 'react';
import {Outlet} from 'react-router-dom';

type companyData= {
	type?:string
	company?:string
	brand?:string
	sku?:string
}
export type onboardingContextInterface = {
	companyData?:companyData
	steps?:number
	currentStep?:number

	setCurrentStep?: (value: number)=>void
	setCompanyData?: (value: (prev: companyData) => companyData)=>void
}
export const OnboardingContext:React.Context<onboardingContextInterface> =createContext({});

const OnboardingContextProvider=({children,steps}:any):JSX.Element=>{
	const [companyData,setCompanyData]=useState<companyData>();
	const [currentStep,setCurrentStep]=useState<number>(0);
	let render:JSX.Element=children || <Outlet/>;
	const value:onboardingContextInterface={
		companyData,
		steps,
		currentStep,
		setCurrentStep,
		setCompanyData
	};

	return <OnboardingContext.Provider value={value}>
		{render}
	</OnboardingContext.Provider>;
};

export default OnboardingContextProvider;