import {Opportunity, User} from '../../interfaces';
import {MatchPercentage} from '../MatchPercentage';
import {Button, LinkButton, Tag} from '../ui';
import {BrandTalentAvatars} from '../BrandTalentAvatars';
import React from 'react';
import {color} from 'styles/colors';
import styled from 'styled-components';

const ReviewContainer:any=styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  background-color: ${color.get('neutral-800')};
`;

const Description:any=styled.div`
  margin-top: 2rem;
  max-width: 290px;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${color.get('neutral-300')};
`;

const OfferText:any=styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  >p{
	font-size: 14px;
    font-weight: 400;
  }
`;

interface OpportunityReviewRenderProps {
	match:number
	talent: User,
	userType:string
	opportunity:Opportunity
	loading:boolean

	isAlerted?:boolean

	acceptOpportunity:()=>void
	rejectOpportunity:()=>void

	sendAlert:()=>void
	closeReview:()=>void
}

const CPMTag:any=styled(Tag)`
	border-color: ${color.get('primary')};
`;

export const OpportunityReviewRender= ({match,talent,loading,opportunity,userType,isAlerted,acceptOpportunity,rejectOpportunity,sendAlert,closeReview}:OpportunityReviewRenderProps):JSX.Element=> {
	return userType==='Regular'?
		<ReviewContainer>
			<div className="w-100 flex justify-center mb-2">
				<MatchPercentage
					matchPercentage={match}
					sizeValue={60}
					sizeType={'px'}
					length={2}
					percentage={75}
				/>
			</div>
			<BrandTalentAvatars
				talent={talent}
				brandPicture={opportunity.companyProfile.logo?.url}
				sizeValue={124}
				sizeType={'px'}
				length={2}
				percentage={75}
			/>
			<OfferText className={'center mt'}>
				<h6>
					{opportunity.companyProfile.name} x {talent.firstName} {talent.lastName}
				</h6>
				<p>HYPERDRUNK</p>
			</OfferText>
			<Description>By Accepting this offering you accept the terms and conditions of the campaign. </Description>
			<div className={'mver-2'}>
				<CPMTag category={{id: 'd', label: `CPM $${'3.49'}`}}/>
			</div>
			<div className={'w-100 mt flex direction-column align-center'}>
				<Button
					label={'ACCEPT'}
					onClick={acceptOpportunity}
				/>
				<Button
					label={'REJECT'}
					variant={'secondary'}
					onClick={rejectOpportunity}
				/>
			</div>
		</ReviewContainer> :
		<ReviewContainer>

			<MatchPercentage
				className={'mt-2 mb'}
				matchPercentage={match}
				label={'Campaign Match'}
				sizeValue={60}
				sizeType={'px'}
				length={2}
				percentage={75}
			/>

			<BrandTalentAvatars
				talent={talent}
				brandPicture={opportunity.companyProfile.logo?.url}
				sizeValue={124}
				sizeType={'px'}
				length={2}
				percentage={75}
			/>
			<OfferText className={'center mt'}>
				<h6>{talent.firstName} {talent.lastName}</h6>
				<p>{opportunity.companyProfile.name} </p>
			</OfferText>
			{isAlerted?<>
				<Description>
					The talent has been alerted and contacted for approval.
				</Description>
				<Description>
					You will receive a notification once the user accepts
				</Description>
				<Description>
					Thank You!
				</Description>
			</>:
				<Description>
				This talent has {match}% match with your campaign. You can submit an alert to contact this talent.
				</Description>}
			{!isAlerted?<div className={'w-100 mt flex direction-column align-center'}>
				<Button
					loading={loading}
					label={'Send Alert'}
					onClick={sendAlert}/>
				<Button
					label={'Cancel'}
					variant={'secondary'}
					onClick={closeReview}
				/>
			</div> :
				<div className={'w-100 mt flex direction-column align-center'}>
					<LinkButton to={`/campaign/${opportunity._id}/alerted`} label={'Continue'}/>
				</div>
			}
		</ReviewContainer>;
};