import React from 'react';
import {Item, ListInput} from 'interfaces';
import {CheckboxButton} from '../CheckboxButton';
import {listHandler, useListHandler} from 'hooks/useListHandler';


export const MultiCheckButton=({onChange,value,max,name,options}:ListInput):JSX.Element=>{
	const {handleUnselected,handleSelected}:listHandler=useListHandler({name,value,options,onChange});

	const handleClick=(item:Item):void=>{
		if (value.includes(item.id)){
			return handleUnselected(item);
		}
		if(value.length===max){return ;}
		handleSelected(item);
	};

	return <div className={'w-100'}>
		{options.map((element:Item):JSX.Element=>
			<CheckboxButton
				key={element.id}
				item={element}
				defaultSelected={value.includes(element.id)}
				onClick={handleClick}
			/>
		)}
	</div>;
};