export const themeOpus:any = {//new Opus theme
	colors : {
		black: '#000000',
		white: '#FFFFFF',
		light_grey_200: '#CFCFD0',
		light_grey_500: '#424242',
		red: '#FF5656',
		blue: '#1C2FDD',
		grey_1000: '#0E0F14',
		grey_900: '#13141A',
		grey_850: '#15161E',
		grey_840: '#191A23',
		grey_820: '#1D1F27',
		grey_800: '#1E2028',
		grey_750: '#22232B',
		grey_700: '#22232D',
		grey_650: '#232530',
		grey_600: '#262932',
		grey_550: '#383A47',
		grey_500: '#545766',
		grey_300: '#5B5E7A',
		grey_200: '#626579',
		grey_100: '#9498B1',
		grey_80: '#BCC0DC',
		green: '#2CAE50',
		purple: '#6125B2',
		lile: '#95AAFC'
	}
};
