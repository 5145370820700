import React, {ForwardedRef, forwardRef, useState} from 'react';
import {InputProps} from 'interfaces';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {InputWrapper} from 'styles/ui';
import {ReactComponent as CalendarIcon} from 'styles/icons/calendar.svg';
import {DatePickerContainer, DatePickerStyle, Label, PlaceHolder} from './dateInput.styles';
import {BodyText} from 'stylesOpus/typography';

const formatValue=(value:string):string=>{
	const defaultDate:Date = new Date(value as string);
	const year: number = defaultDate.getFullYear();
	const month: number = defaultDate.getMonth()+1;
	const day: number = defaultDate.getUTCDate();
	return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
};


type DateProps= {
	picker?:{
		minDate?:Date,
		maxDate?:Date,
		dateFormat?:string
	}
	onChange:(e: {target:{ name: string, value: string }
})=>void
} & Omit<InputProps,'onChange'>

export const DateInput=({name,value,placeholder='mm/dd/yyyy',required,className,helperText,label,appendableElement,picker,onChange,...props}:DateProps):JSX.Element=>{
	let defaultValue :string;
	defaultValue = value as string;
	const [startDate, setStartDate] = useState(defaultValue? new Date(defaultValue) : null);

	const handleChange=(date:Date):void=>{
		setStartDate(date);
		onChange && onChange({
			target:{ name, value:formatValue(date.toISOString()) }
		});
	};

	// eslint-disable-next-line react/display-name
	const ExampleCustomInput:any = forwardRef(({ value, onClick }:any, ref:ForwardedRef<any>):JSX.Element => (
		<InputWrapper  className=" w-100 align-center col-gap cursor-pointer" onClick={onClick} ref={ref} localTheme={'opusV2'}>
			<DatePickerStyle className ='w-100'>
				{value ? value : <PlaceHolder >{placeholder}</PlaceHolder>}
			</DatePickerStyle>
			<CalendarIcon/>
		</InputWrapper>
	));

	return (
		<div className={className}>
			{label && <Label>{label}</Label>}
			{helperText && <BodyText>{helperText}</BodyText>}
			<DatePickerContainer>
				<DatePicker
					required={required}
					dateFormat="MM/dd/yyyy"
					onChange={handleChange}
					selected={startDate}
					customInput={<ExampleCustomInput/>}
					{...picker}
					placeholder='some placeholder'
				/>
			</DatePickerContainer>
		</div>
	);
};