import React, {useState} from 'react';
import {ListDetailRender} from './ListDetailRender';
import {QueryResult, useQuery} from '@apollo/client';
import {query} from 'actions/queries';
import {useParams, Params} from 'react-router-dom';
import {OppEmail, ShortLink, Talent, TalentOpportunity} from 'interfaces';
import {sendEmail} from 'actions/restActions';
import {ModalHook, useModal} from 'hooks/useModal';

export const ListDetail = ():JSX.Element => {
	const {id}:Params = useParams();
	const {data:listData,loading:listLoading,error:listError}:QueryResult=useQuery(query('listFindOne'),{
		variables:{ _id: id, userSkip:0, userLimit:20 }
	});
	const listPopup: ModalHook = useModal(false);
	const confirmPopup: ModalHook = useModal(false);
	const sharePopup: ModalHook = useModal(false);

	const [ talent, setTalent ] = useState<Talent>(null);
	const [ linkData, setLinkData ] =
		useState<{talentOpportunity:TalentOpportunity,shortLink: ShortLink}>(null);

	const handleOpenShare = (talent:Talent): void => {
		setTalent(talent);
		sharePopup.open();
	};

	const handleShare = (shortLink:ShortLink, talentOpportunity:TalentOpportunity): void => {
		setLinkData({
			shortLink,
			talentOpportunity
		});
	};

	const emailAlert = async():Promise<any> => {
		const d:OppEmail = {
			emails: ['sergio@moadw.com', 'david@moadw.com'],
			subject: 'You’re the Perfect Partner for Our Opportunity',
			body: ``,
			html: `<p>We are launching an exciting campaign, and we believe you are the perfect partner to lead it! Please review the details and let us know if you are interested and available to participate!</p>
				<p>We understand that your time is valuable, and we assure you that our team is committed to making this collaboration a seamless and enjoyable experience for you. Your comfort, preferences, and creative input are of utmost importance to us.Please let us know your thoughts and availability for a brief meeting to discuss further details. Our team is eagerly awaiting your response, and we can’t wait to get started on this remarkable adventure together!</p>
				<p>Looking forward to hearing from you soon!</p>
			`
		};

		const r:any = await sendEmail(d);
		console.log('Email Sent', r);
		confirmPopup.open();
		listPopup.close();

	};

	return <ListDetailRender
		list={listData?.listFindOne}
		loading={listLoading}
		error={!!listError}
		
		listPop={listPopup}
		confirmPop={confirmPopup}
		shareLink={{
			popup: {
				...sharePopup,
				close: ():void=> {
					setLinkData(null);
					sharePopup.close();
				}
			},
			talent: talent,
			talentOpportunity: linkData?.talentOpportunity,
			shortLink: linkData?.shortLink,
			handleOpenShare: handleOpenShare,
			handleShare: handleShare
		}}

		emailAlert={emailAlert}
	/>;
};