import React from 'react';

import { Text, Container } from './styles';

export const ImageSelectRender = ({image,text}: any):JSX.Element => {
	return (
		<>
			<Container image={image} />
			<Text>{text}</Text>
		</>
	);
};
