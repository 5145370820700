import styled,  {css,keyframes} from 'styled-components';
import {mediaQuery} from '../../styles/mediaQueries';

const menuKeys:any=keyframes`
  from{
    transform: translateY(100%);
  }
  to{
    transform: translateY(0) ;
  }
`;

const menuKeysOut:any=keyframes`
  from{
    transform: translateY(0);
  }
  to{
    transform: translateY(100%);
  }
`;

const menuIn:any=css`
  ${menuKeys} 0.5s  ease-in-out ;
`;

const menuOut:any=css`
  ${menuKeysOut} 0.5s  ease-in-out;
`;


export const Review:any=styled.section`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  z-index: 1;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 0;
  transform: translateY(100%);
  &.show {
    z-index: 100;
    transform: translateY(0) ;
    animation: ${menuIn};
  }
  &.hide{
    transform: translateY(100%) ;
    animation: ${menuOut}
  }
  
  ${mediaQuery('mobile')}{
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
  }
  
`;
