
export const compareStrings=(a:string,b:string):number=>{
	if (a < b) {
		return -1;
	}
	if (a > b) {
		return 1;
	}
	return 0;
};

export const compareNumbers=(a:number,b:number):number=>{
	if (a > b) {
		return -1;
	}
	if (a < b) {
		return 1;
	}
	return 0;
};

export const sortList=(array:any[],sortBy:string):void=>{
	array.sort((prev:any,current:any):number=>compareStrings(prev[sortBy],current[sortBy]));
};

export const sortListByNumbers=(array:any[],sortBy:string):void=>{
	array.sort((prev:any,current:any):number=>compareNumbers(prev[sortBy],current[sortBy]));
};