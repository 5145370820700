import React from 'react';

export const ProfileIcon=():JSX.Element=>
	<svg
		width={24}
		height={24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8 1C4.68629 1 2 3.68629 2 7V17C2 20.3137 4.68629 23 8 23H16C19.3137 23 22 20.3137 22 17V7C22 3.68629 19.3137 1 16 1H8ZM8.21429 2.60976C5.729 2.60976 3.71429 4.62447 3.71429 7.10975V16.8902C3.71429 19.3755 5.729 21.3902 8.21428 21.3902H15.7857C18.271 21.3902 20.2857 19.3755 20.2857 16.8902V7.10976C20.2857 4.62448 18.271 2.60976 15.7857 2.60976H8.21429ZM12 5.33333C13.4958 5.33333 14.7083 6.5459 14.7083 8.04167C14.7083 9.53744 13.4958 10.75 12 10.75C10.5042 10.75 9.29167 9.53744 9.29167 8.04167C9.29167 6.5459 10.5042 5.33333 12 5.33333ZM12 18.6682C13.9646 18.6682 15.8291 17.8017 17.0958 16.3C17.4121 15.9057 17.4598 15.3598 17.2167 14.9167C16.1302 13.0757 14.1373 11.9615 12 12C9.86266 11.9615 7.86984 13.0757 6.78333 14.9167C6.54023 15.3598 6.58792 15.9057 6.90417 16.3C8.17087 17.8017 10.0354 18.6682 12 18.6682Z"
			fill="white"
		/>
	</svg>
;
