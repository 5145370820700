import React from 'react';
import VideoCardRender, {VideoCardRenderProps} from './VideoCardRender';


export const VideoCard=({...props}:VideoCardRenderProps):JSX.Element=>{

	return <VideoCardRender
		{ ...props}
	/>;
};
