import styled, {css} from 'styled-components';
import {mediaQuery} from 'styles/mediaQueries';

export const RecentPostsWrapper:any=styled.section`
	width: 100%;
  >h6{
	margin-bottom: 1rem;
  }
`;


export const PostsContainer:any=styled.div`
  width: 100%;
  display: grid;
  margin-bottom: 2rem;
  
  grid-template-columns: repeat(4,1fr);
  gap: 1rem;
  
  ${mediaQuery('tablet')}{
    grid-template-columns: repeat(2,1fr);
  }
`;

const Post:any=css`
  aspect-ratio: 1;
  object-fit: cover;
  width: 180px;
  border-radius: 8px;
`;

export const PostImage:any=styled.img`
	${Post}
`;


export const PostVideo:any=styled.video`
	${Post}
`;