import styled from 'styled-components';

export const Box:any = styled.div`
	background: rgba(255,255,255,0.15);
  	padding: 2rem;
  	border-radius: 1rem;
`;

export const Tag:any = styled.p`
	color:#cecece;
  	font-size: 14px;
  	margin-bottom: 6px;
  	&:hover{
	  color: #fff;
	}
`;