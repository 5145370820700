import React from 'react';
import AudienceCityRender from './AudienceCity.render';

export default function AudienceCity():JSX.Element {

	return (
		<div>
			<AudienceCityRender />
		</div>
	);
}