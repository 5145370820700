import React from 'react';
import styled from 'styled-components';
import {Item} from 'interfaces';
import {Tag} from '../Tag';

export interface SuggestionsRenderProps{
	options:Item[]
	selectedItems:Item[]
	onClickTag?:(tag:Item)=>void
	label:string
}

const StyledTags:any=styled.div`
	display: flex;
	flex-wrap: wrap;
`;


export default function TagsSelectRender({options,label,selectedItems,onClickTag}:SuggestionsRenderProps):JSX.Element{
	return <div className={'mb mt pt-half'}>
		<h6 className={'mb-half'}>{label}</h6>
		<StyledTags>
			{options.map((category:Item):JSX.Element=>
				<Tag
					category={category}
					selected={selectedItems.map((i:Item):string=>i.id).includes(category.id)}
					key={category.id}

					onClickTag={onClickTag}
				/>
			)}
		</StyledTags>
	</div>;
}
