import React, {useEffect, useState} from 'react';
import MultiSelector from './form/MultiSelector';
import {IFilter, IMatch, IMatchAudience, IOption, ITalentFilter} from '../DiscoverInterfaces';
import {useRecoilState} from 'recoil';
import {matchQueryAtom} from '../../../context/recoil/store';

export default function AudienceEthnicity():JSX.Element {

	const [matchQuery, setMatchQuery] = useRecoilState<IMatch>(matchQueryAtom);
	const [value, setValue] = useState<string[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const options: IOption[] = [
		{
			label: 'Asian',
			_id: 'asian'
		},
		{
			label: 'Hispanic',
			_id: 'hispanic'
		},
		{
			label: 'Black',
			_id: 'black'
		},
		{
			label: 'White',
			_id: 'white / caucasian'
		}
	];

	useEffect((): void => {

		let m: IMatch = {...matchQuery};
		let a: IMatchAudience = {...matchQuery.audience};
		m.audience = a;

		if (value.length > 0) {
			m.audience.ethnicities = value;
		} else {
			delete m.audience.ethnicities;
		}
		setMatchQuery(m);

	}, [value]);

	useEffect(():void => {
		if(loading) {
			if(matchQuery?.audience?.ethnicities){
				setValue(matchQuery.audience.ethnicities);
			}
			setLoading(false);
		}
	},[loading]);

	return (
		<div>
			<MultiSelector options={options} value={value} setValue={setValue}/>
		</div>
	);
}