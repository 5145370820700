import React from 'react';

export const RefreshIcon=():JSX.Element=>
	<svg
		width={24}
		height={24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M23 4.99762V9.99762H18"
			stroke="white"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M1 18.9976V13.9976H6"
			stroke="white"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M3.51 8.99763C4.01717 7.56442 4.87913 6.28304 6.01547 5.27305C7.1518 4.26307 8.52547 3.5574 10.0083 3.22189C11.4911 2.88639 13.0348 2.93198 14.4952 3.35441C15.9556 3.77684 17.2853 4.56235 18.36 5.63763L23 9.99763M1 13.9976L5.64 18.3576C6.71475 19.4329 8.04437 20.2184 9.50481 20.6409C10.9652 21.0633 12.5089 21.1089 13.9917 20.7734C15.4745 20.4379 16.8482 19.7322 17.9845 18.7222C19.1209 17.7122 19.9828 16.4308 20.49 14.9976"
			stroke="white"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
;
