import React, {ReactNode} from 'react';
import {TalentDetailData} from 'hooks/useTalentDetail';
import {CardItem} from 'components';
import {Spinner} from 'components/ui';
import {WithTalentDetailData} from 'hocs';

type FavoriteCardProps = {
  talentId: string;
};

export const FavoriteCard = ({talentId}:FavoriteCardProps):JSX.Element => {
    
	return (
		<WithTalentDetailData talentId={talentId}>
			{({discoverData, instagramAudience}: TalentDetailData): ReactNode =>
				!discoverData.loading && !instagramAudience.loading ?
					<CardItem
						picture={discoverData?.data?.userObj?.profilePicture?.url}
						title={`${discoverData?.data?.userObj?.firstName} ${discoverData.data?.userObj?.lastName}`}
						subtitle={`${discoverData?.data?.industryObj[0]?.label} ${ discoverData?.data?.industryObj[0] && discoverData?.data?.industrySegmentObj? '|':''} ${discoverData?.data?.industrySegmentObj[0]?.label}`}
						link={`/talent/${talentId}/opus`}
					/>
					:
					<Spinner/>
			}
		</WithTalentDetailData>
	);
};