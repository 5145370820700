import React,{ChangeEvent,useState} from 'react';
import {TextBoxProps} from 'interfaces';
import TextBoxRender from './TextBoxRender';

export const TextBox=({maxLength=190,onChange,...props}:TextBoxProps):JSX.Element=>{

	const [currentLength,setCurrentLength]=useState(0);
	const onChangeTextBox=(event:ChangeEvent<HTMLTextAreaElement>):void=>{
		let length:number=event.target.value.length;
		setCurrentLength(length);
		onChange(event);
	};

	return <TextBoxRender
		{...props}
		maxLength={maxLength}
		currentLength={currentLength}
		onChange={onChangeTextBox}
	/>;
};
