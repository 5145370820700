import React from 'react';
import ResetPasswordRender from './ResetPasswordRender';
import {FormField, FormHook} from 'hooks/useForm/interfaces';
import {useForm} from 'hooks/useForm/useForm';
import {MutationTuple, useMutation} from '@apollo/client';
import {mutation} from 'actions/mutations';
import {NavigateFunction, Params, useNavigate, useParams} from 'react-router-dom';

export const ResetPassword=():JSX.Element=>{
	const [resetPassword]:MutationTuple<any, any>=useMutation(mutation('userResetPassword'));
	const navigate:NavigateFunction=useNavigate();
	const {token}:Params=useParams();
	const  formFields:FormField[]=[
		{name:'password', required:true,default:''},
		{name:'confirmPassword', required:true,default:''}
	];
	console.log({token});
	const reset=async(data:any):Promise<void> =>{
		const {password,confirmPassword}:any= data;
		if(password ===confirmPassword){
			await resetPassword({
				variables:{
					resetTokenPassword:token,
					password:data.password
				}
			});
			navigate('/');
		}
		else {
			throw new Error('The passwords do not match');
		}
	};

	const  {data,errors,loading,...rest}:FormHook=useForm(formFields,reset);

	return <ResetPasswordRender
		formData={data}
		submitLoading={loading}
		errors={errors}

		registerInput={rest.registerInput}
		onSubmit={rest.handleSubmitForm}
	/>;
};