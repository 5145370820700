import React, {useEffect, useState} from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie, Doughnut } from 'react-chartjs-2';
import {AudienceItem} from '../interfaces';

ChartJS.register(ArcElement, Tooltip, Legend);

const initialColors:string[] = [
	'rgba(149, 150, 272, 1)',
	'rgba(97, 37, 178, 1)',
	'rgba(66, 66, 66, 1)'
];

interface AudienceCat {
	items?: AudienceItem[],
	colors?: string[]
	doughtnut?: boolean
}


interface DataSetItem {
	label?: string
	data: number[]
	backgroundColor?: string[],
	borderWidth? : number
}

interface DataItem {
	labels: string[]
	datasets: DataSetItem[]
}



export default function GenderPie({items, colors=initialColors, doughtnut=false}:AudienceCat):JSX.Element {

	const [data, setData] = useState<DataItem>({
		labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
		datasets: [
			{
				label: '# of Votes',
				data: [12, 19, 3, 5, 2, 3],
				backgroundColor: [
					'rgba(255, 99, 132, 0.2)',
					'rgba(54, 162, 235, 0.2)',
					'rgba(255, 206, 86, 0.2)',
					'rgba(75, 192, 192, 0.2)',
					'rgba(153, 102, 255, 0.2)',
					'rgba(255, 159, 64, 0.2)'
				],
				borderWidth: 0
			}
		]
	});

	useEffect(():any => {

		let one:number = 0;
		let two:number = 0;
		let three:number = 0;
		let four:number = 0;
		let five:number = 0;
		let six:number = 0;

		items.map(({name, weight}:AudienceItem):void=>{
			if(name.includes('13-17')){
				one = one+weight;
			}
			else if(name.includes('18-24')){
				two = two+weight;
			}
			else if(name.includes('25-34')){
				three = three+weight;
			}
			else if(name.includes('35-44')){
				four = four+weight;
			}
			else if(name.includes('45-64')){
				five = five+weight;
			}
			else if(name.includes('65-')){
				six = six+weight;
			}
		});

		let d:DataItem = {
			labels: ['13-17', '18-24', '25-34', '35-44', '45-64', '65+'],
			datasets: [
				{
					label: '# of Votes',
					data: [one*100, two*100, three*100, four*100, five*100, six*100],
					backgroundColor: colors,
					borderWidth: 0
				}
			]
		};
		setData(d);

	}, [items]);

	return doughtnut ? <Doughnut
		data={data}
		options={{
			cutout: 98,
			plugins: {
				legend : {
					display : false
				}
			}
		}}
	/>
		:
		<Pie data={data} />;
}
