import styled from 'styled-components';
import {color} from 'styles/colors';
import {Link} from 'react-router-dom';

interface LinkTabProps {
	$active?:boolean
}

export const Content:any=styled.section`
  width: 100%;
  padding: 20px 24px ;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LinkTab:any=styled(Link)<LinkTabProps>`
  font-weight: 500;
  line-height: 19px;
  font-size: 1rem;
  text-transform: capitalize;
   padding-bottom: 6px;
  ${({$active}:LinkTabProps):string=>$active?
		`border-bottom:solid 4px ${color.get('primary')};
		 color: ${color.get('white')};
		` :
		`border-bottom:solid 4px ${color.get('black')};
		color: ${color.get('neutral-400')};
		`
}`;