import React from 'react';
import {StackContainer, StackContainerProps} from 'styles/ui';
import styled from 'styled-components';
import {color} from '../../styles/colors';

interface MatchPercentageProps extends StackContainerProps{
	className?:string
	matchPercentage:number
	label?:string
}

const RoundMatch:any=styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${color.get('neutral-800')};
  
  border-radius: 50%;
  width:${({sizeValue,sizeType}:StackContainerProps):string=>`${sizeValue}${sizeType}`};
  aspect-ratio: 1;
  color:  ${():string=>color.get('primary')};
  border: solid 4px;
  font-weight: 700;
  font-size: 1rem;
  line-height: 19px;
`;

const RoundMatchText:any=styled(RoundMatch)`
  color:  ${():string=>color.get('white')};
  border: solid 2px;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
`;

export const MatchPercentage = ({matchPercentage,label='MATCH',...props}:MatchPercentageProps):JSX.Element => {
	return <StackContainer
		{...props}
	>
		<RoundMatch {...props}>
			{matchPercentage}%
		</RoundMatch>
		<RoundMatchText {...props}>{label}</RoundMatchText>
	</StackContainer>;
};
