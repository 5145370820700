import React, {FormEvent, useContext, useEffect, useState} from 'react';

import LinkSocialRender from './LinkSocialRender';
import {UserContext, userContextInterface} from 'context/userContext';
import {NavigateFunction, useLocation,Location, useNavigate} from 'react-router-dom';
import {SocialsHook, useSocials} from 'hooks/useSocials';
import {MutationTuple, useMutation} from '@apollo/client';
import {mutation} from 'actions/mutations';

const URL_APP:string|undefined=process.env.REACT_APP_REST_API;

export const LinkSocial=():JSX.Element=>{
	const {user,refresh}:userContextInterface=useContext(UserContext);
	const {socialsData}:SocialsHook=useSocials(user?._id);
	const navigate:NavigateFunction=useNavigate();
	const [errors,setErrors]= useState<Map<string,string>>(new Map<string, string>());
	const [isLoading,setIsLoading]= useState<boolean>(false);
	const [message,setMessage]= useState<string>('');
	let connectedAccounts:string[]= [];

	const setConnectedAccounts =(accounts:string[]):string[]=> connectedAccounts=accounts;
	const {pathname}:Location=useLocation();
	let hasAyrShareAccounts:boolean=false;
	// eslint-disable-next-line @typescript-eslint/typedef
	const [getAyrShareJWT,{data:ayrShareData}]:MutationTuple<any, any>=useMutation(mutation('setAyrShareJWT'));

	// instagram
	const [setInstagramAudience]:MutationTuple<any, any>=useMutation(mutation('setAyrshareInstagramAudience'));
	const [setSocialCard]:MutationTuple<any, any>=useMutation(mutation('setAyrshareSocialCard'));

	useEffect(():void=>{
		getAyrShareJWT({variables:{user:user._id}});
	},[]);

	useEffect(():void=>{
		console.log('SData', socialsData);
	},[socialsData]);

	const handleSocialCards= async(connectedAccounts:string[]):Promise<void>=>{
		console.log('Connected Accounts', connectedAccounts);
		if(connectedAccounts.length){
			try {
				if(connectedAccounts.includes('instagram') ||connectedAccounts.includes('tiktok') ){
					await setInstagramAudience();
					setMessage('Instagram connected');
					await setSocialCard();
					setMessage('Social-networks connected successfully');
				}
				//todo: connect all social network
				// await setSocialCard();
				// setMessage('Social-networks connected successfully');
			}
			catch (e) {
				console.log({e});
			}
		}
	};

	const handleSubmit =(e:FormEvent):void=>{
		e.preventDefault();
		setErrors(new Map());

		if (socialsData?.length || hasAyrShareAccounts){
			setIsLoading(true);
			handleSocialCards(connectedAccounts)
			.then(():void=>{
				refresh().then(
					():void=> navigate('/onboarding/hello-user')
				);
			})
			.finally(():void=>setIsLoading(false));
		}
		else{
			setErrors(new Map(errors.set('submit','please add at least one social network') ));
		}
	};

	const setError=(errorMessage:string,socialNetwork:string):void=>{
		setErrors(new Map(errors.set(socialNetwork,errorMessage) ));
	};
	let url:string=`${URL_APP}${pathname}`;

	return <LinkSocialRender
		submitLoading={isLoading}
		errors={errors}
		onSubmit={handleSubmit}
		ayrShareLink={`${ayrShareData?.setAyrShareJWT}&redirect=${url}`||''}
		user={user?._id||''}
		setError={setError}
		message={message}
		setConnectedAccounts={setConnectedAccounts}
		userAccounts={socialsData}
	/>;
};
