import styled from 'styled-components';

type ContainerProps = {position : string}

export const Container: any = styled.div<ContainerProps>`
    position: absolute;
    z-index: 200;
    top: 0px;
    background-color: #38383A;
      width: 200px;
    height: fit-content;
    border-radius: 5px;
    padding: 15px;
    ${({position}:ContainerProps):string=>position}
    z-index: 10;
    > p {
      color: white !important;
      font-size: 12px;
      line-height: 18px;
    }
` ;

export const MainTooltip: any = styled.div`
    position: relative;
`;