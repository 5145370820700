import React from 'react';
import styled from 'styled-components';
import {themeOpus} from 'stylesOpus/theme';
import {Item} from 'interfaces';
import {ArrowRightIcon} from '../../../icons';
import {Link} from 'react-router-dom';

interface StepperProps {
	steps:Item[]
	current:string
	links?:string[]
}

type StepStyleProps =  {
	active:boolean;
}

const StepContainer:any = styled.div`
	background-color: ${themeOpus.colors.grey_900};

	display: flex;
	align-items: center;
	border-radius: 20px ;
	height: 2.18rem;
	width: fit-content;
	padding: 0.5rem;
	gap: 0.5rem;
	
`;

const Step:any = styled.p<StepStyleProps>`
	
  	border-radius: 1.5px;
	padding: 0 0.6rem;
	color: ${  ({active}:StepStyleProps):string=>active? 'white': themeOpus.colors.grey_500  };
	font-family: 'Urbanist', sans-serif;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 137.5%; 
	white-space: nowrap;
`;

export default function Stepper({current, steps, links}:StepperProps):JSX.Element {

	return (
		<StepContainer >
			{steps.map((step:Item, i:number):JSX.Element=>{
				return ((links && links.length!==i)?
					<Link to={ links[i]} className={'flex'}>
						<Step key={step.id} active={current === step.identifier} >
							{step.label}
						</Step>
						{ i < steps.length- 1 && <ArrowRightIcon />}
					</Link>
					:
					<>
						<Step key={step.id} active={current === step.identifier} >
							{step.label}
						</Step>
						{ i < steps.length- 1 && <ArrowRightIcon />}
					</>
				);
			})}
		</StepContainer>
	);
}