import styled from 'styled-components';
import { mediaQuery } from 'styles/mediaQueries';
import { color } from 'styles/colors';
import {themeOpus} from '../../stylesOpus/theme';

type HeaderStyleProps = {
    styleTheme: string;
};
export const StyledHeader: any = styled.header<HeaderStyleProps>`
  position: relative;
  padding: 1.5rem 1.25rem 0.25rem 0;
  align-self: stretch;
  justify-self: stretch;
  max-width: 1400px;
  width: 100%;
  justify-content: center;
  z-index: 200;
  grid-area: header;

  
  background: 
    ${({ gradient }: any): string => gradient ? `linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.696832) 43.75%, rgba(0, 0, 0, 0.880208) 75%, #000 100%)` : '#000;'};
  ${ (props:any):string=> props.styleTheme === 'opusV2' && `background: transparent`};
  >div:last-child{
	display: none;
	width: 100%;
	height: fit-content;
	justify-content: space-between;
	align-items: center;
    
	${mediaQuery('mobile')}{	
	  display: flex;
	}
  }
`;

export const DesktopHeader: any = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
  	margin-bottom: 1rem;
  
    background-color: #171720 ;
  border-radius: 50px;
  >hr{
    border: 1px solid  ${color.get('neutral-500')};
  }
    :first-child{
      ${mediaQuery('mobile')}{
        display: none;
      }
    }
    :last-child{
      display: none;
      ${mediaQuery('mobile')}{
        display: flex;
      }
    }
  ${mediaQuery('mobile')}{
	display: none;
  }
`;

export const Tittle: any = styled.div<HeaderStyleProps>`
    font-style: normal;
    font-size: 14px;
    font-family: 'Lato', sans-serif;
    color: #A8ADCB;
    line-height: 24px;
    text-transform: capitalize;
    font-weight: 500;
    border-radius: 100px;
    font-weight: ${(props:any):string=>props.styleTheme === 'opusV2' ? '500' : '700'};
    font-size: ${(props:any):string=>props.styleTheme === 'opusV2' ? '14px' : '20px'};
    padding: ${(props:any):string=>props.styleTheme === 'opusV2' ? '6px 28px' : ''};
    background-color: ${ (props:any):string=> props.styleTheme === 'opusV2' ? themeOpus.colors.grey_900 : ''};
    >svg{
      font-weight: 400;
    
    }
`;

