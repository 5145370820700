import React from 'react';
import styled from 'styled-components';
import {ImageCard} from '../ui';
import {color} from 'styles/colors';
import {formatDate} from '../../tools/date';
import {Opportunity} from '../../interfaces';
import {NavigateFunction, useNavigate} from 'react-router-dom';

export interface CampaignItemProps {
	campaign:Opportunity
}

const StyledTitle:any=styled.h6`
  
  margin-top: 1rem;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  
`;


const StyledDescription:any=styled.span`
  	text-transform: none;
  display: -webkit-box;
  overflow: hidden;
  height: 16px;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const StyledScore:any=styled.section`
  width: 60px;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 1rem;
  right: 0.5rem;
  font-size: 0.7rem;
  background-color: ${color.get('primary')};
  border-radius: 50%;
  padding: 0.4rem 0.4rem;
  margin-top: 1rem;
`;

const StyledInactive:any=styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
	width: 100%;
  	height: 100%;
  text-transform: uppercase;
  	background-color: rgba(50,50,50,0.8);
  	>p:first-child{
	  color: ${color.get('primary')};
	}
`;

const StyledDate:any=styled.p`
	font-weight: 700;
  font-size: 12px;
  margin-top: 0.5rem;
`;


export const CampaignItem=({campaign}:CampaignItemProps):JSX.Element=>{
	const matches:number= 18;
	const active:boolean=campaign.active;
	const navigate:NavigateFunction=useNavigate();
	const handleOnClick=():void=>navigate(`/opportunities/${campaign._id}`);

	return <div className={'cursor-pointer'}  onClick={handleOnClick}>
		<ImageCard img={campaign.image&& campaign.image[0]?.url}>
			{active ?
				// <StyledScore>
				// 	{/*<p>{matches}</p>
				// 	<p>Matches</p>*/}
				// </StyledScore>
				''
				:
				<StyledInactive>
					<p>Draft</p>
					<p>Not Published</p>
				</StyledInactive>
			}

		</ImageCard>
		<StyledTitle>
			{campaign.name}
			{/*<StyledDescription>
				{campaign.description}
			</StyledDescription>*/}
		</StyledTitle>

		{active&&<StyledDate>ENDS : {formatDate(campaign.endDate)}</StyledDate>}
	</div>;
};