import React from 'react';
import {IconProps} from 'interfaces';

export const DashboardIcon=({color='white', size=18}:IconProps):JSX.Element=>
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.3999 2H19.6011C20.9256 2 22 3.07443 22 4.3999V7.6001C22 8.92456 20.9256 10 19.6011 10H16.3999C15.0744 10 14 8.92456 14 7.6001V4.3999C14 3.07443 15.0744 2 16.3999 2Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4.3999 2H7.6001C8.92557 2 10 3.07443 10 4.3999V7.6001C10 8.92456 8.92557 10 7.6001 10H4.3999C3.07443 10 2 8.92456 2 7.6001V4.3999C2 3.07443 3.07443 2 4.3999 2Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4.3999 14H7.6001C8.92557 14 10 15.0744 10 16.4009V19.6001C10 20.9256 8.92557 22 7.6001 22H4.3999C3.07443 22 2 20.9256 2 19.6001V16.4009C2 15.0744 3.07443 14 4.3999 14Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.3999 14H19.6011C20.9256 14 22 15.0744 22 16.4009V19.6001C22 20.9256 20.9256 22 19.6011 22H16.3999C15.0744 22 14 20.9256 14 19.6001V16.4009C14 15.0744 15.0744 14 16.3999 14Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
;
