import {TalentScoreRes, TTalentScoreArgs} from './types';
import {QueryResult, useQuery} from '@apollo/client';
import {query} from 'actions/queries';

export const useTalentsScores = ({interests,talentIds}:TTalentScoreArgs):TalentScoreRes=>{
	const {data:talentScoreData,loading:talentScoreLoading, error:talentScoreError}:QueryResult = useQuery(query('talentScoreFindMany'),{
		variables: {
			platforms : ['instagram'],
			audience : {
				topic:  interests
			},
			talent : talentIds
		}
	});
	return {
		data: talentScoreData?.talentScoreFindMany.scores,
		loading: talentScoreLoading,
		error: talentScoreError?.message
	};
};