import React from 'react';
import {FormButton, Input, Spinner} from 'components/ui/';
import {Message} from 'components';
import {color} from 'styles/colors';
import {FormProps} from 'interfaces';
import {Link} from 'react-router-dom';

export interface ForgotPassword extends FormProps {
	message:string
}

export default function ForgotPasswordRender({submitLoading,message,errors,onSubmit,registerInput}:ForgotPassword):JSX.Element{
	return (
		<div className="w-100">
			<h1 className='mb-2 center'>Forgot Password</h1>
			<p className="center mb-2">
				Enter your email below and we will send you recovery instructions.
			</p>
			<form
				className='grows max-h-600px mb-2 flex direction-column justify-between'
				onSubmit={onSubmit}
				autoComplete='off'
			>

				<Input
					label={'EMAIL'}
					placeholder={'Email'}
					{...registerInput('email')}
				/>
				<div>
					<p className="center">
						<FormButton
							width={'100%'}
							loading={submitLoading}
							label={'Submit'}
							variant={'grey'}
						/>
						{message &&
						<Message message={message} color={color.get('success-100')}/>
						}
						{errors?<div className={'mt center'}>
							{errors.get('submitError') && <Message message={errors.get('submitError')} color={color.get('primary')}/>}
							{errors.get('email')?.pattern && <Message message={'Email must be a valid email'} color={color.get('primary')}/>}
						</div>:null}
					</p>
				</div>
				<p className="center f-small mt-2 t-grey500">
					Return to <Link className="t-white f-700" to='/'>Login</Link>
				</p>
			</form>
		</div>
	);
}
