import {Item} from '../../interfaces';
import {CategoriesContainer, ProfileTag, TagsWrapper} from '../UserScore/styles';
import React from 'react';

interface UserCategoriesProps {
	tags:Item[]
	rounded?:boolean
	selected?:string
	setSelectedTag?:(sel:string)=>void
}

export const UserCategories = ({tags,rounded=false, selected='', setSelectedTag}:UserCategoriesProps):JSX.Element=>
	<CategoriesContainer>
		<TagsWrapper>
			{tags.map((tag:Item, index:number):JSX.Element=>{
				if (index<6) {
					return <a key={tag.id} >
						<ProfileTag
							rounded={rounded}
							category={tag}
							selected={selected===tag.label ? true : false}
							onClickTag={():void=>setSelectedTag(tag.label)}
						/>
					</a>;
				}
			})}
		</TagsWrapper>
		<div/>
	</CategoriesContainer>;

