import React from 'react';
import { Bar } from 'react-chartjs-2';
import {BarsWrapper} from './styles';

export interface BarsRenderProps{
	options:any
	data:any
}

export const BarsRender= ({data,options}:BarsRenderProps):JSX.Element=>{
	return<BarsWrapper>
		<Bar options={options} data={data}/>
	</BarsWrapper>
	;
};