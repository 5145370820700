/* eslint-disable @typescript-eslint/typedef */
import React, { useState } from 'react';

import { ArrowDownIcon } from 'icons';

import {
	Main,
	ListItem,
	DropDownList,
	DropDownHeader,
	DropDownContainer,
	DropDownListContainer
} from './styles';

const options: any = ['INSTAGRAM', 'TIKTOK', 'YOUTUBE'];

export const SelectNetwork = (): JSX.Element => {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedOption, setSelectedOption] = useState(null);

	const toggling = (): any => setIsOpen(!isOpen);

	const onOptionClicked = (value: any): any => (): any => {
		setSelectedOption(value);
		setIsOpen(false);
		console.log(selectedOption);
	};

	return (
		<Main>
			<DropDownContainer>
				<DropDownHeader onClick={toggling}>
					{selectedOption || 'INSTAGRAM'}
					<div className='ml ml-1.5'>
						<ArrowDownIcon />
					</div>
				</DropDownHeader>
				{isOpen && (
					<DropDownListContainer>
						<DropDownList>
							{options.map((option): any => (
								<ListItem onClick={onOptionClicked(option)} key={Math.random()}>
									{option}
								</ListItem>
							))}
						</DropDownList>
					</DropDownListContainer>
				)}
			</DropDownContainer>
		</Main>
	);
};