import React from 'react';
import {IconProps} from '../interfaces';

export const PlusIcon=({size=24,color='white'}:IconProps):JSX.Element=>
	<svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M12 5V19" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M5 12H19" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
	</svg>

;
