import styled from 'styled-components';

export const BioSection:any=styled.div`
  padding-top: 2rem;
  width: 100%;
  >p{
	font-weight: 700;
	font-size: 14px;	  
  }
`;

export const Info:any=styled.div`
  display:flex;
  font-size:12px;
  line-height:14.52px;
  margin:1rem 0;
  >p:first-child{
	  font-weight: 700;
  }
  >p:last-child{
	
	margin-left: 0.5rem;
	font-weight: 400;
  }
`;
