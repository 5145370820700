import React from 'react';
import {ItemPercentage, LabelsContainer, SimpleChartWrapper,ItemPercentageWithLabel} from './styles';
import {Range} from '../Range';
import {ChartLabel} from '../ui';
import {ChartItemUI, Chart} from 'interfaces';

export interface SimpleChartRenderProps extends Chart{
	inLine?:boolean
}

export function SimpleChartRender({title,items,inLine,maxValue=100}:SimpleChartRenderProps):JSX.Element{
	return <SimpleChartWrapper>
		{title&&<h6 className={'mb'}>{title}</h6>}
		{items.map((item:ChartItemUI):JSX.Element=>
			inLine?
				<ItemPercentageWithLabel key={item.id}>
					<ChartLabel key={item.label} color={item.color} label={item.label}/>
					<Range color={item.color} max={maxValue} value={item.value}/>
					<p>{item.value}%</p>
				</ItemPercentageWithLabel>
				:
				<ItemPercentage key={item.label}>
					<Range color={item.color} max={maxValue} value={item.value}/>
					<p>{item.value}%</p>
				</ItemPercentage>
		)}
		{inLine?
			<div/>
			:
			<LabelsContainer>
				{items.map((item:ChartItemUI):JSX.Element=>
					<ChartLabel key={item.label} color={item.color} label={item.label}/>
				)}
			</LabelsContainer>
		}
	</SimpleChartWrapper>;
}