import {AuthRoute, ProfileEdit} from '../containers';
import React from 'react';
import {Route} from './interfaces';
import {
	BrandForm,
	ContactForm, Ethnicity,
	FamilyForm,
	Interests,
	ProfileForm,
	Spirits
} from '../containers/profileEdit/components';

export const profileEditRoutes:Route[] =[
	{
		path:'/profile-edit',
		element:<AuthRoute redirectTo={'/'}><ProfileEdit /></AuthRoute>,
		children:[
			{
				path: 'Profile',
				element: <ProfileForm/>
			},
			{
				path:'Contact',
				element: <ContactForm/>
			},
			{
				path: 'interests',
				element: <Interests/>
			},
			{
				path: 'family',
				element: <FamilyForm/>
			},
			{
				path: 'spirits',
				element: <Spirits/>
			},
			{
				path: 'size',
				element: <div/>
			},
			{
				path: 'brand',
				element: <BrandForm/>
			},
			{
				path: 'ethnicity',
				element: <Ethnicity/>
			}
		]
	}
];