import React from 'react';
import {HeaderIcon} from '../Header/components/HeaderIcon';
import {Tittle} from '../Header/header.styles';

interface GenericHeaderProps{
	title:string
	label?:string
	Element?:React.FC
	action:()=>void
}

export const GenericHeader=({title,label,Element,action}:GenericHeaderProps):JSX.Element=>{

	return <div >
		<div className={'w-100 flex justify-between'}>
			<Tittle>
				{title}
			</Tittle>
			<HeaderIcon label={label} Element={Element} action={action}/>
		</div>
		<hr className={'w-100 mt'}/>
	</div>;
};

