/* global FB */

import {socialInterface} from '../interfaces';
import {ApolloError, MutationTuple, QueryResult, useMutation, useQuery} from '@apollo/client';
import {mutation} from '../actions/mutations';
import {query} from '../actions/queries';
import {useState} from 'react';

export interface SocialsHook{
	socialsData: socialInterface[]
	loading:boolean
	error:ApolloError
	connectionError?:string
	instagramConnect?:()=>void
}

export const useSocials=(userId?:string):SocialsHook=>{
	const {data,loading,error, refetch}:QueryResult=
		useQuery(query('socialInfoByUserFindMany'));

	const [connectionError,setConnectionError]=useState<string>('');

	const [linkInstagram]:MutationTuple<any,any> = useMutation(mutation('linkInstagram'));
	const getInstagramBusinessAccountId=(accountsData:any[]):string=>{
		for (let account of accountsData) {
			if (account.instagram_business_account)
				return account.instagram_business_account.id;
		}
		return '';
	};
	const instagramConnect = ():void =>{
		setConnectionError('');
		// @ts-ignore
		FB.login(({authResponse}: fb.StatusResponse):any=> {
		// @ts-ignore
			return FB.api(
				'/me',
				'get',
				{'fields':'accounts{instagram_business_account}'},
				(response:any):void=>{
					console.log('API',response);
					if(response.error){
						return setConnectionError('a problem has occurred');
						// return setConnectionError(response.error.message);
					}
					let accounts:any=response.accounts;
					if(!accounts) {
						return setConnectionError('a problem has occurred accounts');
					}
					//let id:string =accounts.data[0].instagram_business_account.id||'';
					let id:string =getInstagramBusinessAccountId(accounts.data);
					if(!id) {
						return setConnectionError('Seems like your Instagram account is not Creator or Business type. Please make sure to convert your Instagram account and connect it to a Facebook Page so we can connect your account to Opus');

					}
					linkInstagram({
						variables:{
							accessToken:authResponse.accessToken,
							id:id
						}
					})
					.then((res:any):void=> {
						console.log('link Instagram');
						console.log(res);
						refetch();
					})
					.catch((err:Error):void=> {
						setConnectionError(err.message);
						console.log('ERROR', err);
					});
				}
			);
		},{scope:'email,pages_show_list,instagram_basic,instagram_manage_insights,pages_read_engagement,public_profile'});
	};

	return {socialsData:data?.socialInfoByUserFindMany,loading,error,connectionError, instagramConnect};
};