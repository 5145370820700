import React, {useContext} from 'react';
import {Avatar} from '../../ui';
import {UserContext, userContextInterface} from 'context/userContext';
import styled from 'styled-components';
import {themeOpus} from 'stylesOpus/theme';
import {GlowTag} from '../../../styles/styledComponents';

const Container :any = styled.div`
  display: flex;
  padding: 0.625rem 1rem;
  justify-content: space-between;
  align-items: center;
  gap: 0.625rem;
  background: #22232B;
  border-radius: 1rem;
  ${themeOpus.colors.grey_750}
`;

const UserName :any = styled.p`
	width: 8rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;
export const Profile = ():JSX.Element => {
    
	const {user} : userContextInterface =useContext(UserContext);
    
	return (
		<Container>
			<div className={'flex align-center col-gap-half'}>
				<Avatar
					size={'1.5rem'}
					user={user}
				/>
				<UserName >{user.firstName} {user.lastName}</UserName>
			</div>
			<GlowTag
				color={'#881CDD'}
				colorGlow={'#881CDD'}
			>PRO</GlowTag>
		</Container>
	);
};