import React from 'react';
import styled from 'styled-components';

type PercentageGraphProps = {
	percentage:number
	styles?:GraphProps & BarProps
}

type GraphProps = {
	$width?:string
	$height?:string
	$gap?:string
	$padding?:string
}

type BarProps = {
	$color?:string
	$height?:string
	$active?:boolean
}

const Graph:any=styled.div<GraphProps>`
	
	display: flex;
	align-items: end;
	gap: ${({$gap}:GraphProps):string=>$gap ? $gap : '0.5rem'};
	padding: ${({$padding}:GraphProps):string=>$padding ? $padding : '0.5rem'};
	height: ${({$height}:GraphProps):string=>$height ? $height : '5rem'};
	width: ${({$width}:GraphProps):string=>$width ? $width : '100%'};

`;

const Bar:any=styled.div<BarProps>`
	flex-grow: 1;
	height: ${({$height}:BarProps):string=>$height ? $height : '100%'};
	background-color: ${({$color,$active}:BarProps):string=> $active ? $color || '#3A49D3' : '#2B2B3A'};
	box-shadow: ${({$active,$color}:BarProps):string=> $active ? `0px 0px 7.31px 0px ${$color||'#3A49D3'}` : 'none'};
`;

export const PercentageGraph = ({percentage,styles}:PercentageGraphProps) : JSX.Element => {
	const barsPercentages :number[] = Array.from({length:10},(_:number,index:number):number=>(index+1)*10);
	const setActive= (barPercentage:number):boolean=> barPercentage < percentage;

	return (
		<Graph
			{...styles}
		>
			{barsPercentages.map((percentage:number,index:number):JSX.Element=>
				<Bar
					key={index}
					$height={`${percentage}%`}
					$active={setActive(index)}
				/>
			)}
		</Graph>
	);
};