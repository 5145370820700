import {Link} from 'react-router-dom';
import {BodyText, BoxTitle} from 'stylesOpus/typography';
import styled from 'styled-components';

export const ArticleContainer:any = styled(Link)`
    display: flex;
	position: relative;
    flex-direction: column;
    row-gap: 1.5rem;
`;

export const TitleContainer:any = styled(BoxTitle)`
	max-height: 3em; /* Puedes ajustar este valor según sea necesario */
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
`;

export const TextBody:any = styled(BodyText)`
	font-size: 14px;
`;

export const PaddingContainer:any = styled.div`
	padding: 0.5rem 0;
`;