import React from 'react';
import styled from 'styled-components';
import {PictureInput} from '../../PictureInput';

interface MultiPicturesInputRenderProps {
	size?:string
}

const ImagesWrapper:any=styled.div`
  display: grid;
  width: ${(props:any):string=> props.size};	
 
  grid-template-columns: repeat(4,1fr);
  column-gap: 2rem;
`;

const VoidPicture:any=styled.div`
	
`;

export const MultiPicturesInputRender = ({size='154px'}:MultiPicturesInputRenderProps):JSX.Element => {

	return <ImagesWrapper size={size}>
		<PictureInput size={size} rounded={'20px'} plus={true} fontSize={'3rem'} label={''}/>
		<PictureInput size={size} rounded={'20px'} plus={true} fontSize={'3rem'} label={''}/>
		<PictureInput size={size} rounded={'20px'} plus={true} fontSize={'3rem'} label={''}/>
		<PictureInput size={size} rounded={'20px'} plus={true} fontSize={'3rem'} label={''}/>
	</ImagesWrapper>;
};