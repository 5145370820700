import React,{ChangeEvent,useState} from 'react';
import {SelectProps} from 'interfaces';
import SelectRender from './SelectRender';
import {SelectNetwork} from '../SelectNetworks/SelectNwtworks';

export const Select=({network, ...props}:SelectProps):JSX.Element=>{
	const [unselected,setUnselected]=useState(true);
	const onChangeSelect=(event:ChangeEvent<HTMLSelectElement>):void=>{
		setUnselected(false);
	};

	return network ?
		<SelectNetwork unselected={unselected} onChange={onChangeSelect} {...props}
		/>
		:
		<SelectRender unselected={unselected} onChange={onChangeSelect} {...props}
		/>;
};
