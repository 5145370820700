import React from 'react';
import {Spinner} from './styles';

export interface SpinnerProps {
	color?:string
}

export default function SpinnerRender({color='white'}:SpinnerProps):JSX.Element{

	return (
		<Spinner spinColor ={color}>
			<div/><div/><div/><div/>
			<div/><div/><div/><div/>
			<div/><div/><div/><div/>
		</Spinner>
	);
}
