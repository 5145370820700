import React from 'react';
import {AlertBox} from '../styles';
import {AudienceByCountry} from '../../AudienceByCountry';
import {ChartItem} from '../../../interfaces';
import {AudienceItem} from '../../../pages/Insights/interfaces';
import VerticalChart from '../../../pages/Insights/Components/VerticalChart';
import HorizontalChart from '../../../pages/Insights/Components/HorizontalChart';
import GenderPie from '../../../pages/Insights/Components/GenderPie';

interface InsightsRenderProps {
	countries?:ChartItem[]
	brands?:AudienceItem[]
	interests?: AudienceItem[]
	genders?: AudienceItem[]
	followersCount?: number
}

export default function InsightsRender({countries=[], brands=[], interests=[], genders=[], followersCount=0}:InsightsRenderProps):JSX.Element {
	console.log({followersCount, fc:followersCount.toLocaleString()});
	return (
		<div className="grid2 col-gap-2 row-gap-2 mt">
			<AlertBox>
				<h4>Total Reach</h4>
				<h1>{followersCount.toLocaleString()}</h1>
				<p className='mb'>Instagram</p>
				<div className="phor">
					<GenderPie doughtnut={true} colors={['#22577A', '#38A3A5', '#80ED99']} items={genders} />
				</div>
			</AlertBox>
			<AlertBox>
				<h4>
					Audience INTERESTS
				</h4>
			
				<div className="grows flex mt">
					<VerticalChart color={'#80ED99'} brands={interests} />
				</div>
			</AlertBox>
			<AlertBox>
				<h4>
					Audience per country
				</h4>
			
				<AudienceByCountry title="Countries" items={countries} />

			</AlertBox>
			<AlertBox>
				<h4>
					BRAND AFFINITY
				</h4>
		
				<div className="grows flex">
					<HorizontalChart color={'#38A3A5'} items={brands} />
				</div>


			</AlertBox>
		</div>
	);
}