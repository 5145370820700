import React from 'react';
import {AudienceByCountry, Card, SimpleChart, TotalReach, TrackingGraph} from 'components';
import {ChartItem, ChartItemUI} from 'interfaces';
import {CardMain} from 'components/CardMain';
import {Doughnut} from 'components/charts';
import {ChartLabel,Divider} from 'components/ui';
import {AudienceSection, AudienceWrapper, StyledGender} from '../Audience/styles';

export interface AudienceAyrShareRenderProps {
	followersCount:number
	totalInteractions:ChartItem[]
	totalAudience:number
	gendersChart:ChartItemUI[]
	agesChart:ChartItem[]
	countries:ChartItem[]
	cities:ChartItem[]
}

export default function AudienceAyrShareRender({followersCount,totalInteractions,totalAudience,gendersChart,agesChart,countries,cities}:AudienceAyrShareRenderProps):JSX.Element{
	console.log({totalInteractions});
	return (
		<AudienceWrapper className={'w-100 grid2 tablet:block'}>
			<Card >
				<TotalReach reach={followersCount}/>
				<CardMain label={'GENDER'}>

					<div className={'flex'}>
						<Doughnut
							className={''}
							datasets={[
								{
									data:gendersChart.map((d:ChartItemUI):number=> d.value),
									backgroundColor:gendersChart.map((d:ChartItemUI):string=> d.color),
									spacing:1,
									borderWidth:0,
									hoverOffset:4,
									hoverBackgroundColor:gendersChart.map((d:ChartItemUI):string=> d.color)

								}
							]}
							labels={gendersChart.map((i:ChartItem):string=>i.label)}
						/>
						<div className='flex direction-column justify-end'>
							{gendersChart.map((i:ChartItemUI):JSX.Element=>
								<StyledGender key={i.id} color={i.color}>
									<p>{i.value.toFixed(1)}%</p>
									<ChartLabel color={i.color} label={i.label}/>
								</StyledGender>)
							}
						</div>
					</div>

				</CardMain>
			</Card>

			<Divider/>

			<div className={'flex direction-column'}>
				<AudienceSection className={'mb mobile:mb-0'}>
					<SimpleChart
						title={'Age Distribution'}
						percentage
						items={agesChart}
						maxValue={totalAudience}
						inLine
						sort
						sortBy={'label'}
						sortType={'desc'}
					/>

				</AudienceSection>
				<Divider/>
				<AudienceSection >

					<TrackingGraph
						value={`${totalInteractions[0].value}`}
						title={'Total Interactions Lifetime'}
						distributionData={totalInteractions}
					/>

				</AudienceSection>
			</div>

			<Divider/>

			<AudienceSection>

				<AudienceByCountry
					title={'Audience by Country'}
					items={countries}
					length={5}
				/>

			</AudienceSection>
			<AudienceSection>
				<SimpleChart
					title={'Cities'}
					items={cities}
					sort
					length={7}
				/>
			</AudienceSection>
		</AudienceWrapper>
	);
}