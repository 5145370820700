import React from 'react';
import {Item, PercentageLAbel} from './styles';
import {RoundPercentage} from '../RoundPercentage';

export interface ItemPercentageProps{
	percentage:number
	label:string
	size?:string
	fontSize?:string
}


export default function ItemPercentageRender({percentage,label,size='68px',fontSize='0.625em'}:ItemPercentageProps):JSX.Element{
	return (
		<Item>
			<RoundPercentage
				percentage={percentage}
				size={size}
			/>
			<PercentageLAbel fontSize={fontSize}>{label}</PercentageLAbel>
		</Item>
	);
}
