import React, {useEffect, useState} from 'react';
import {QueryResult, useQuery} from '@apollo/client';
import IndustrySegmentList from './IndustrySegmentList';
import {InputBox} from '../styledComponents';
import {query} from '../../../../actions/queries';
import {IFilter, IIndustry, IOption, ITalentFilter} from '../../DiscoverInterfaces';
import {useRecoilState} from 'recoil';
import {filterAtom, segmentsFormAtom, stateFormAtom} from '../../../../context/recoil/store';

export default function TalentIndustry():JSX.Element {

	const [filter, setFilter] = useRecoilState<IFilter>(filterAtom);
	const [segmentForm, setSegmentForm] = useRecoilState<string[]>(segmentsFormAtom);
	const [value,setValue] = useState<string[]>([]);
	const [loadingC, setLoadingC] = useState<boolean>(true);

	const {data,loading,error, networkStatus}:QueryResult=useQuery(query('industryFindMany'), {
		variables:{
			limit: 100,
			skip: 0,
			sort: '_ID_ASC'
		},
		fetchPolicy: 'network-only'
	});

	useEffect(():void=>{

		setSegmentForm(value);
		if(value.length > 0){
			let f: IFilter = {...filter};
			let t: ITalentFilter = {...filter.talent};
			f.talent = t;
			f.talent.industrySegment = [...value];
			setFilter(f);
		}
		else{
			let f: IFilter = {...filter};
			let t: ITalentFilter = {...filter.talent};
			f.talent = t;
			delete f.talent.industrySegment;
			setFilter(f);
		}

	},[value]);

	useEffect(():void=>{
		if(loadingC) {
			if(segmentForm && segmentForm.length > 0){
				setValue(segmentForm);
			}
			setLoadingC(false);
		}
	},[loadingC]);

	return (
		<InputBox padding="12px 8px 12px 24px" borderRadius="0">
			<div className="max-h-250px overflow-y-scroll">
				{loading ?
					'Loading Industries...'
					:
					<div>
						{data?.industryFindMany?.length > 0 && data?.industryFindMany.map(
							({_id, label, identifier}:IIndustry):JSX.Element=>{
								return (
									<div className="mb" key={_id}>
										<h4>{label}</h4>
										<IndustrySegmentList _id={_id} value={value} setValue={setValue} />
									</div>
								);
							}
						)}
					</div>
				}
			</div>

		</InputBox>
	);
}
