import React from 'react';
import {color} from 'styles/colors';
import {Message, PictureInput} from 'components';
import {FormProps} from 'interfaces';
import {FormButton, Input, TextBox} from 'components/ui';
import {Link} from 'react-router-dom';

export interface SkuCreateProps extends FormProps {
	brandId:string
	defaultPicture?:string
	edit?:boolean
	onChangeFile:(file:File)=>void
}

export function SkuForm({edit=false,brandId,defaultPicture,submitLoading,errors,onSubmit, registerTextArea,registerInput,onChangeFile}:SkuCreateProps):JSX.Element{

	// @ts-ignore
	return (
		<div className='w-100 p flex direction-column   align-center'>
			<h2>{edit?'Edit Sku':'Create Sku'}</h2>
			<PictureInput
				defaultImage={defaultPicture}
				plus size="120px"
				rounded="20%"
				onChange={onChangeFile}
			/>
			<form className={'w-100'} onSubmit={onSubmit} autoComplete='off'>
				<Input
					label='sku name'
					{...registerInput('name')}
				/>
				<Input
					label='SKU'
					{...registerInput('sku')}
				/>
				<TextBox
					label='DESCRIPTION'
					maxLength={400}
					{...registerTextArea('description')}
				/>
				<div className={'w-100 center flex direction-column align-center'}>
					<FormButton
						variant='secondary'
						label='SAVE SKU'
						loading={submitLoading}
					/>
					{<div className={'mver'} style={{width:'fit-content'}}>
						{// @ts-ignore
							<Link to={-1}>
								<p className="w-full t-white text-center phor-2">CANCEL</p>
							</Link>}
					</div>
					}
				</div>

				{errors?<div className={'mt center'}>
					{errors.get('submitError') && <Message message={errors.get('submitError')} color={color.get('primary')}/>}
				</div>:null}
			</form>
		</div>
	);
}
