import React from 'react';
import {EthnicityRender} from './EthnicityRender';
import {FormField, FormHook} from 'hooks/useForm/interfaces';
import {useForm} from 'hooks/useForm/useForm';
import {QueryResult, useQuery} from '@apollo/client';
import {query} from 'actions/queries';
import {Item, TalentProfile} from 'interfaces';
import {EditProfileContext} from '../../index';
import {useOutletContext} from 'react-router-dom';

const countries:Item[]=[
	{id:'us',label:'United States'},
	{id:'co',label:'Colombia'},
	{id:'mx',label:'Mexico'},
	{id:'nz',label:'New Zealand'},
	{id:'uk',label:'United Kingdom'},
	{id:'jp',label:'Japan'}
];

export const Ethnicity = ():JSX.Element=>{
	const {data:languageData}:QueryResult=useQuery(query('languageFindMany'));
	const {data:ethnicityData}:QueryResult=useQuery(query('ethnicityFindMany'));
	const {profileData,updateProfile}:EditProfileContext=useOutletContext();

	const  formFields:FormField[]=[
		{name:'languages', required:false,default:profileData?.languages||[]},
		{name:'ethnicity', required:false,default:profileData?.ethnicity||''},
		{name:'country', required:false,default:profileData?.country||''}
	];

	const handleSubmit=async(formData:TalentProfile):Promise<void> =>{
		console.log('submit', {data});
		updateProfile({variables:{
			profileData:formData
		}});
	};

	const  {data,errors,loading,...rest}:FormHook=useForm(formFields,handleSubmit);

	const languages:Item[]=languageData?.languageFindMany
	?.map((i:any):Item=>({label:i.label,id:i._id}))||[];

	const ethnicities:Item[]=ethnicityData?.ethnicityFindMany
	?.map((i:any):Item=>({label:i.label,id:i._id}))||[];

	return <EthnicityRender
		languages={languages}
		ethnicities={ethnicities}
		countries={countries}
		submitLoading={loading}
		errors={errors}
		registerInput={rest.registerInput}
		registerSelect={rest.registerSelect}
		registerList={rest.registerList}
		onSubmit={rest.handleSubmitForm}
	/>;
};