import React from 'react';
import {BrandFormRender} from './BrandFormRender';
import {FormField, FormHook} from 'hooks/useForm/interfaces';
import {useForm} from 'hooks/useForm/useForm';
import {fakeBrands} from 'tools/MuckData/items';
import {EditProfileContext} from '../../index';
import {useOutletContext} from 'react-router-dom';
import {QueryResult, useQuery} from '@apollo/client';
import {query} from 'actions/queries';
import {Item, TalentProfile} from 'interfaces';

export const BrandForm = ():JSX.Element=>{
	const {data:shoesSizesData}:QueryResult=useQuery(query('shoeSizeFindMany'));
	const {data:clothingSizeData}:QueryResult=useQuery(query('clothingSizeFindMany'));

	const {profileData,updateProfile}:EditProfileContext=useOutletContext();

	const  formFields:FormField[]=[
		{name:'brands', required:false,default:profileData?.brands||[]},
		{name:'shoeSizes', required:false,default:profileData?.shoeSizes||[]},
		{name:'clothingSizes', required:false,default:profileData?.clothingSizes||[]}
	];

	const handleSubmit=async(formData:TalentProfile):Promise<void> =>{

		await updateProfile({variables:{
			profileData:formData
		}});
	};

	const  {errors,loading,...rest}:FormHook=useForm(formFields,handleSubmit);

	const shoesSizes:Item[]=shoesSizesData?.shoeSizeFindMany
	?.map((i:any):Item=>({label:i.identifier,id:i._id}))||[];
	const clothingSizes:Item[]=clothingSizeData?.clothingSizeFindMany
	?.map((i:any):Item=>({label:i.identifier,id:i._id}))||[];

	return <BrandFormRender
		submitLoading={loading}
		errors={errors}
		brands={fakeBrands}
		shoeSizes={shoesSizes}
		clothingSizes={clothingSizes}
		registerList={rest.registerList}
		onSubmit={rest.handleSubmitForm}
	/>;
};