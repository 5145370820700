import styled from 'styled-components';
import {color} from 'styles/colors';

interface OutlineTextProps{
	color:string;
	percentage:string;
	fontSize:string
	fontFamily:string
	lineStroke:string
}

export const OutlineText:any=styled.p<OutlineTextProps>`
  color: rgb(0,0,0,0);
  
  font-size:  ${({fontSize}:OutlineTextProps):string=>fontSize};
  font-family:  ${({fontFamily}:OutlineTextProps):string=>fontFamily};
  
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: ${({color}:OutlineTextProps):string=>color};
`;

OutlineText.defaultProps={
	color:color.get('neutral-400'),
	fontSize:'1rem',
	fontFamily:'Helvetica Neue',

	lineStroke:'1px'
};