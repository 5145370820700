import React, {useEffect, useState} from 'react';
import {QueryResult, QueryTuple, useLazyQuery, useQuery} from '@apollo/client';
import styled from 'styled-components';
import {query} from '../../../actions/queries';
import {Detail} from './Detail';
import {RoundPercentage} from '../../../components/ui';
import {GridContentBox} from '../opportunityDetail.styles';
import {BodyText} from '../../../stylesOpus/typography';
import CarImage from '../../../styles/images/pages/opportunities/car.png';
import {AuthScoreI} from '../../../interfaces';
import Popup from '../../../components/Popup';

interface AudienceOverlapI{
	authSelected?:AuthScoreI
	user?:string
	setWeight:(w:number)=>void
}

const BodyOpportunityText:any = styled(BodyText)<{
	fontSize?:string
	fontWeight?:string
}>`
	font-family: 'Urbanist', sans-serif;
	font-size:  ${({fontSize}:any):string=>fontSize?fontSize:' 0.75rem'};
	font-weight: ${({fontWeight}:any):string=>fontWeight?fontWeight:'700'};
	opacity: 0.6;
`;


export default function AudienceOverlap({user, setWeight, authSelected}:AudienceOverlapI):JSX.Element {

	return (
		<GridContentBox $padding = {'3rem 1.5rem 1rem 2rem'}>
			<Detail title={'Audience Overlap'}>
				<div className={'h-100 flex direction-column justify-between p-0'}>
					<BodyOpportunityText>
						Select a category above to view the overlap with the target audience’s interests

					</BodyOpportunityText>
					<div className={'flex w-100 justify-between align-center pt-2'}>
						<div className="w-3rem h-3rem shrinks-0 br-50 p-half flex align-center justify-center b b-neutral-500 neutral-700">
							<img src={authSelected?._id?.picture?.url} alt={authSelected?._id?.title} className="w-100" />
						</div>
						<RoundPercentage
							percentage={authSelected?.value * 100 || 0}
							fixed={1}
							strokeColor={'#1916CC'}
							strokeWidth={'2'}
							insideStyle={{fontSize:'0.5rem'}}
							size={'5rem'}
						/>
					</div>
				</div>
			</Detail>

		</GridContentBox>
	);
}
