import gql from 'graphql-tag';

const mutations: any = {
	'linkInstagram': gql`
	mutation linkInstagram($accessToken:String!,$id:String!){
	  linkInstagram(accessToken: $accessToken, id: $id)
	}`,
	'checkCodeValidation': gql`
	mutation checkCodeValidation($codeVerification:String!){
	  checkCodeValidation(codeVerification: $codeVerification)
	}
	`,
	'setCodeValidation': gql`
		mutation setCodeValidation {
	  setCodeValidation
	}
`,
	'usersUpsertTalentProfile': gql`
	mutation usersUpsertTalentProfile($profileData:UpdateOnetalentProfileInput!){
	  usersUpsertTalentProfile(type: $profileData)
	}`,
	'usersUpsertCompanyProfile': gql`
	mutation usersUpsertCompanyProfile($companyProfileData:UpdateOnecompanyProfileInput!){
	  usersUpsertCompanyProfile(type: $companyProfileData)
	}`,
	'usersUpdateOne': gql`
	mutation usersUpdateOne ($userRecord:UpdateOneuserInput!){
	  usersUpdateOne(record: $userRecord) {
		recordId
		record {
		  _id
		  firstName
		  lastName
		}
	  }
	}`,
	'brandCreateOne': gql`
	mutation brandCreateOne($name:String!, $website:String, $description: String, $company: String!, $interest: [String!]!){
	  brandCreateOne(
		name: $name
		website: $website
		description: $description
		company: $company
		interest: $interest
	  ) {
		_id
		name
		website
		description
		company
		updatedAt
		createdAt
	  }
	}`,
	'skuCreateOne': gql`
	mutation skuCreateOne ($brand:String!, $name:String!, $description:String, $sku:String!){
	  skuCreateOne(
		brand: $brand
		name: $name
		description: $description
		sku: $sku
	  ) {
		_id
		brand 
		name
		description
		sku
		picture {
		  url
		  type
		}
		updatedAt
		createdAt
	  }
	}`,
	'brandUpdateById': gql`
	mutation brandUpdateById($id:String!,$record:UpdateByIdbrandInput!) {
	  brandUpdateById(_id: $id, record: $record) {
		recordId
		record {
		  _id
		  name
		  website
		  description
		  company
		  interest
		  updatedAt
		  createdAt
		}
		error{
		  message
		}
	  }
	}`,
	'skuUpdateOne': gql`mutation skuUpdateOne($_id:String!,$brand:String!,$name:String,$description:String,$sku:String){
	  skuUpdateOne(
		brand:$brand
		_id:$_id
		name: $name
		description: $description
		sku: $sku
	  )
	}`,
	'opportunityCreateOne': gql`
	mutation opportunityCreateOne($record:CreateOneopportunityInput!) {
	  opportunityCreateOne(record: $record) {
		_id
		slug
		active
		company
		name
		category
		campaignTypes
		primaryGoal
		description
		strategy
		requirements
		notes
		product
		sku
		type
		budget {
		  minNumber
		  maxNumber
		  updatedAt
		  createdAt
		}
		startDate
		endDate
		image {
		  url
		  type
		}
		treatment {
		  url
		  type
		}
		deletedAt
		updatedAt
		createdAt
	
	  }
	}`,
	'audienceCreateOne': gql`
	mutation audienceCreateOne($genders:[String],$place:[String],$ageRange:[Range],$interests:[String],$income:String,$opportunity:String!,$country:[String],$ethnicity:[String],$language:[String]) {
	  audienceCreateOne(
		genders: $genders
		place: $place
		ageRange: $ageRange
		interests: $interests
		income: $income
		opportunity: $opportunity
		country: $country
		ethnicity: $ethnicity
		language: $language
	  ) {
		_id
		genders
		place
		ageRange{
			min
			max
		}
		interests
		income
		opportunity
		country
		ethnicity
		language
		updatedAt
		createdAt
	  }
	}`,
	'talentOpportunityCreateOne': gql`
	mutation talentOpportunityCreateOne($record:CreateOneopportunityTalentInput!){
	  talentOpportunityCreateOne(record:$record) {
	    _id
	  }
	}`,
	'userUpdateSettings': gql`
	mutation userUpdateSettings($opportunitiesNotification:Boolean!,$newsNotification:Boolean!,$paymentsNotification:Boolean!,$emailNotifications:Boolean!,$smsNotifications:Boolean!) {
	  userUpdateSettings(
		opportunitiesNotification: $opportunitiesNotification
		newsNotification: $newsNotification
		paymentsNotification: $paymentsNotification
		emailNotifications: $emailNotifications
		smsNotifications: $smsNotifications
	  )
	}`,
	'changePassword': gql`
	mutation changePassword ($newPassword:String! $password:String!){
	  changePassword(newPassword: $newPassword, password: $password)}`,
	'userChangeEmail': gql`
	mutation userChangeEmail($password:String!,$email:String!){
	  userChangeEmail(password: $password, email: $email)
	}`,
	'userDeleteOne': gql`
	mutation userDeleteOne($_id:String!){
	  userDeleteOne(_id: $_id)
	}`,
	'talentOpportunityUserCreateOne': gql`	
	mutation talentOpportunityUserCreateOne( $talent: String!, $opportunity: String! ){
	  talentOpportunityUserCreateOne(talent: $talent, opportunity: $opportunity) {
		_id
		accepted
		opportunity(skip: 0) {
		  _id
		  slug
		  active
		  company
		  name
		  category
		  campaignTypes
		  primaryGoal
		  description
		  strategy
		  requirements
		  notes
		  product
		  sku
		  type
		  budget{
			maxNumber
			minNumber
		  }
		  startDate
		  endDate
		  image{
			type
			url
		  }
		  treatment{
			type
			url
		  }
		  deletedAt
		  createdAt
		  updatedAt
		}
		talent(skip: 0) {
		  _id
		  firstName
		  lastName
		  email
		  emailVerified
		  type
		  acceptTerms
		  profilePicture{
			type
			url
		  }
		  deleted
		  tenants
		  verified
		  createdAt
		  updatedAt
		  socialNetwork(skip: 0) {
			url
		  }
		  tenantsObj(skip: 0, limit: 100, sort: _ID_ASC) {
			_id
			label
			identifier
			createdAt
			updatedAt
		  }
		}
		company{
			_id
			interest
		}
		createdAt
		updatedAt
	  }
	}
	`,
	'talentOpportunityUserUpdateOne': gql`
	mutation talentOpportunityUserUpdateOne($talent:String!, $opportunity:String!,$accepted:Boolean!){
	  talentOpportunityUserUpdateOne(
		talent: $talent,
		opportunity: $opportunity
		accepted: $accepted
	  )
	}`,
	'setAyrShareJWT': gql`
	mutation setAyrShareJWT{
	  setAyrShareJWT
	}`,
	'setAyrshareInstagramAudience': gql`
	mutation setAyrshareInstagramAudience{
	  setAyrshareInstagramAudience
	}`,
	'setAyrshareSocialCard': gql`
	mutation setAyrshareSocialCard {
	  setAyrshareSocialCard
	}
	`,
	'userForgotPassword': gql`
	mutation userForgotPassword($email:String!) {
	  userForgotPassword(email:$email)
	}`,
	'userResetPassword': gql`
	mutation userResetPassword($resetTokenPassword:String!,$password:String!){
	  userResetPassword(resetTokenPassword: $resetTokenPassword, password:$password)
	}`,
	'listCreateOne': gql`
	mutation listCreateOne($name:String!) {
	  listCreateOne(name:$name) {
		_id
		user
		talents
		featured
		name
		cover {
		  url
		  type
		}
		order
		updatedAt
		createdAt
	  }
	}`,
	'listAddUserOne': gql`
	mutation listAddUserOne($id:String!,$user:String!){
	  listAddUserOne(_id: $id, user: $user)
	}`,
	'culturalAlertCreateOne': gql`
	mutation culturalAlertCreateOne($culturalType:String!,$title:String!,$description:String,$instagram:String,$videoSongTitle:String,$videoSongUrl:String,$tiktok:String,$interest:[String!],$website:String,$artist:String) {
	  culturalAlertCreateOne(
		culturalType: $culturalType
		title: $title
		description: $description
		instagram: $instagram
		videoSongTitle: $videoSongTitle
		videoSongUrl: $videoSongUrl
		tiktok: $tiktok
		interest: $interest
		website: $website
		artist: $artist
	  ) {
		_id
		user
		culturalType
		title
		videoSongTitle
		videoSongUrl
		videoDirector
		videoShootDate
		videoShootTime
		videoTreatment {
		  url
		  type
		}
		eventPaid
		eventCategory
		eventFrom
		eventTo
		eventPartnership
		endDate
		photos {
		  url
		  type
		}
		description
		instagram
		tiktok
		feeRange {
		  min
		  max
		  createdAt
		  updatedAt
		}
		interest
		additionalInfo
		eventLocation
		city
		state
		website
		deck {
		  url
		  type
		}
		artist
		slug
		createdAt
		updatedAt
	  }
	}
	`,
	'culturalAlertUpdateOne': gql`
	mutation culturalAlertUpdateOne($_id:String!,$record:UpdateByIdculturalAlertInput!){
	  culturalAlertUpdateOne(_id: $_id, record: $record) {
		recordId
		record {
		  _id
		  user
		  culturalType
		  title
		  videoSongTitle
		  videoSongUrl
		  videoDirector
		  videoShootDate
		  videoShootTime
		  videoTreatment{
			url
			type
		  }
		  eventPaid
		  eventCategory
		  eventFrom
		  eventTo
		  eventPartnership
		  endDate
		  photos{
			url
			type
		  }
		  description
		  instagram
		  tiktok
		  feeRange{
			min
			max
		  }
		  interest
		  additionalInfo
		  eventLocation
		  city
		  state
		  website
		  deck{
			url
			type
		  }
		  artist
		  slug
		  createdAt
		  updatedAt
		}
		error {
		  message
		}
	  }
	}`,
	'opportunityDeleteOne': gql`
	mutation opportunityDeleteOne($_id:String!){
		opportunityDeleteOne(_id: $_id)
	  }
	  `,
	'culturalAlertDeleteOne': gql`
	mutation culturalAlertDeleteOne( $_id:String! ) {
	  culturalAlertDeleteOne(_id: $_id)
	}
	`,
	'audienceUpdateOne' : gql`
	mutation audienceUpdateOne ($_id:String!, $record:UpdateByIdopportunityAudienceInput! ){
	  audienceUpdateOne(_id: $_id, record: $record) {
		recordId
		record {
		  _id
		  genders
		  place
		  ageRange{
			min
			max
		  }
		  interests
		  income
		  opportunity
		  country
		  ethnicity
		  language
		  updatedAt
		  createdAt
		}
		error {
		  message
		}
	  }
	}`,
	'opportunityUpdateOne' : gql`
	mutation opportunityUpdateOne ( $_id: String! , $record : UpdateByIdopportunityInput! ) {
	  opportunityUpdateOne(_id: $_id, record: $record) {
		recordId
		record {
		  _id
		  slug
		  active
		  company
		  name
		  category
		  campaignTypes
		  primaryGoal
		  description
		  strategy
		  requirements
		  notes
		  product
		  sku
		  type
		  budget{
			maxNumber
			minNumber
		  }
		  startDate
		  endDate
		  image{
			type
			url
		  }
		  treatment{
			type
			url
		  }
		  deletedAt
		  updatedAt
		  createdAt
		  companyProfile(skip: 1, sort: _ID_ASC){
			
			logo{
			  type
			  url
			}
			name
		  }
		  productObj(skip: 1, sort: _ID_ASC){
			company
			name
			picture{
			  type
			  url
			}
			description
			interest
			interest
			website
		  }
		}
		error {
		  message
		}
	  }
	}`,
	'shortLinkCreateOne':gql`	
	mutation shortLinkCreateOne($type:String){
	  shortLinkCreateOne(type: $type) {
		_id
		slug
		type
		password
		expiredAt
	  }
	}
	`,
	'listDeleteOne':gql`	
	mutation listDeleteOne($id:String!){
	  listDeleteOne(_id: $id)
	}
	`,
	'listRemoveUserOne':gql`	
	mutation listRemoveUserOne ($id:String!,$user:String!){
	  listRemoveUserOne(_id: $id, user: $user)
	}
	`,
	'linkTensorSocial':gql`	
	mutation linkTensorSocial(
	  $username:String!, 
	  $firstName:String!,
	  $lastName:String,
	  $email:String!
	  $tenant:String,
	  $industry:[String]
	  $industrySegment: [String]
	  $country : String,
	  $birthday : String
	){
	  linkTensorSocial(
		username: $username
		firstName: $firstName
		lastName: $lastName
		email: $email
		tenant: $tenant
		industry: $industry
		industrySegment: $industrySegment
		country: $country
		birthday: $birthday
	  )
	}
	`,
	'LikeCreateOne':gql`
	mutation likeCreateOne($culturalAlert:String,$talent:String) {
	  likeCreateOne(culturalAlert:$culturalAlert , talent: $talent) {
		_id
		user
		talent
		culturalAlert
		deletedAt
		updatedAt
		createdAt
	  }
	}
`,
	'inquireCulturalAlert':gql`
	mutation inquireCulturalAlert($opportunity:String!){
	  inquireCulturalAlert(opportunity: $opportunity)
	}
	`

};


export const mutation = (value: any): any => {
	return mutations[value];
};