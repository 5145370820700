import styled from 'styled-components';
import {mediaQuery} from '../../styles/mediaQueries';

export const EditLayout:any=styled.div`
	display: grid;
  grid-template-areas:
	"header"
 	"content"
	;
  grid-template-rows: 64px 1fr;
`;

export const Content:any=styled.div`
  margin-top: 2rem;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
	grid-area: content;
  	max-width: 900px;
  	width: 100%;
  	justify-self: center;
  >p{
    width: 100%;
    text-align: left;
    font-size: 1rem;
    line-height: 32px;
    letter-spacing: 0.3px;
    margin-bottom: 0.4rem;
  }
	>form{
	  width: 100%;
      max-width: 600px;
      align-items: center;
      >button{
        display: block;
        margin-right: auto;
        margin-left: auto;
      }
	}
`;

export const TabsWrapper:any=styled.div`
	width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1.5rem;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  ${mediaQuery('mobile')}{
    max-width: calc(100vw - 2rem);
  }
`;

export const Title:any=styled.h1`
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  text-align: left;
  width: 100%;
  margin-bottom: 2rem;
`;