import React from 'react';
import {GridSelect} from 'components/ui';
import {Item} from 'interfaces';

interface InterestProps {
	interestsOptions:Item[]
	selectedInterests:string[]
	onChange:({value,name}:any)=>void
}

export const SpiritsRender = ({interestsOptions,selectedInterests,onChange}:InterestProps): JSX.Element => {

	return<>
		<p>Select up to 5 interests below</p>

		<GridSelect
			value={selectedInterests}
			onChange={onChange}
			options={interestsOptions}
			max={5}
		/>
	</>;
};
