import styled from 'styled-components';
import {themeOpus} from './theme';

export const Flex:any = styled.div`
	display: flex;
  	align-items: ${({alignItems}:any):string => alignItems || 'center'};
  	flex-wrap: ${({wrap}:any):string => wrap ? wrap : 'nowrap'};
`;

export const FlexGrow:any = styled.div`
	flex-grow: 1;
`;


/**
 * Accepts a special boolean value to display or hide specially designed for UI's that has tabs in mobile but always visible in desktop.
 * visible: boolean
 */
export const ContentBox:any = styled.div`
	background: ${({background, theme: {colors}}:any):string => colors[background] || colors.grey_900};
  	padding: ${({padding}:any):string => padding || '20px'};
  	border-radius: ${({borderRadius}:any):string => borderRadius || '1rem'};
  	display: ${({visible}:any):string => visible == null ? 'block' : visible ? 'block' : 'none'};
`;


export type PicturePros ={
    src?:string
    ratio?:string
}
export const Picture:any =styled.div<PicturePros>`
	background-image: ${({src}:PicturePros):string=>`url(${src})`};
  	background-size: cover;
    background-position: center;
  	aspect-ratio: ${({ratio}:PicturePros):string=> ratio || '1'   };
	border-radius: 1rem;
	background-color: ${():string=>themeOpus.colors.grey_900};
`;