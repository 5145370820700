import styled from 'styled-components';
import {Card} from 'components';
import {color} from 'styles/colors';

export const StyledCard:any=styled(Card)`
  	flex-grow: 1;
	>svg{
	  margin-bottom: 2rem;
	}
  	>p{
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      text-transform: capitalize;
	  margin-bottom:7px;
      :nth-child(3){
        font-weight: 800;
        font-size: 24px;
        line-height: 29px;
        text-transform: capitalize;
      }
      :last-child{
        color: ${color.get('neutral-400')};
      }
	}

	
`;