import React, {MutableRefObject, useContext, useState} from 'react';
import HeaderRender from './HeaderRender';
import {UserContext, userContextInterface} from 'context/userContext';
import {useLocation,Location} from 'react-router-dom';
import {HeaderIconProps} from './components/HeaderIcon';
import useClickOut from '../../hooks/useClickOut';
import {LayoutContext, LayoutContextInterface} from '../../context/LayoutContext';

interface HeaderProps {
	theme?:'opus'|'opusV2'
	icon?:HeaderIconProps
	menu?:JSX.Element
	content?:JSX.Element
	description?:string
}

export const Header=({icon,theme,menu,content,description}:HeaderProps):JSX.Element=>{
	const [showMenu,setShowMenu]= useState<boolean>(false);
	const menuRef:MutableRefObject<Element>=useClickOut(():void=>setShowMenu(false));
	const {header}:LayoutContextInterface =useContext(LayoutContext);

	const [scroll,setScroll]=useState(false);
	const {pathname}:Location = useLocation();
	document.addEventListener('scroll',():void=>{
		if(window.scrollY >100){
			setScroll(true);
		}else {
			setScroll(false);
		}
	});

	const {loading,error}:userContextInterface =useContext(UserContext);

	if (error){return <h1>Error...</h1>;}
	if (loading){return <h1>Loading...</h1>;}

	return <HeaderRender
		theme={theme}
		currentPath={pathname.split('/')[1]}
		scroll={scroll}
		menuAction={header.handleShowMenu}
		icon={icon}
		menu={{
			element:menu,
			show:showMenu,
			open:():void=> setShowMenu(true),
			ref:menuRef
		}}
		content={content}
		description={description}
	/>;
};