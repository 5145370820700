import React from 'react';
import {IconProps} from '../../interfaces';



export const TiktokOutlineIcon=({size=24,color='#FEFEFE'}:IconProps):JSX.Element=>
	<svg width={size} height={size} viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M9.0574 2.44744C8.39969 2.01861 7.92499 1.33248 7.77691 0.532636C7.74492 0.359824 7.72734 0.181972 7.72734 0H5.6282L5.62484 8.41262C5.58955 9.35468 4.81419 10.1108 3.86367 10.1108C3.56825 10.1108 3.29008 10.0369 3.04515 9.90805C2.4835 9.61248 2.09914 9.02376 2.09914 8.34628C2.09914 7.37327 2.89075 6.58166 3.86367 6.58166C4.04529 6.58166 4.21952 6.61163 4.38432 6.66325V4.52026C4.21377 4.49704 4.04048 4.48252 3.86367 4.48252C1.7332 4.48252 0 6.2158 0 8.34628C0 9.65342 0.653081 10.8102 1.64943 11.5097C2.27701 11.9503 3.04038 12.2099 3.86367 12.2099C5.99414 12.2099 7.72734 10.4767 7.72734 8.34628V4.08036C8.55065 4.67128 9.55934 5.0195 10.6478 5.0195V2.92037C10.0615 2.92037 9.51538 2.74606 9.0574 2.44744" fill={color}/>
	</svg>

;
