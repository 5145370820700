import React from 'react';
import {BarLabel} from './styles';

export interface ChartLabelProps {
	color:string
	label:string
}

export default function ChartLabelRender({color,label}:ChartLabelProps):JSX.Element{

	return (
		<BarLabel color={color}>
			<div/>
			<p>{label}</p>
		</BarLabel>
	);
}
