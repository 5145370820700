import {color} from 'styles/colors';
import styled from 'styled-components';
import {themeOpus} from '../../stylesOpus/theme';

export interface CardPropsRenderProps{
	padding?:string
	margin?:string
	color?:string
	$flexGrow?:string
	$height?:string
	$gap?:string
	theme?: 'opus' | 'opusV2'
	borderRadius?:string

	$justifyContent?:string
	$alignItems?:string
}

const getBackGroundColor = ({color:colorB, theme}:CardPropsRenderProps):string=>
	colorB? colorB  : themeOpus.colors.grey_900;

export const Card:any=styled.div<CardPropsRenderProps>`
	display: flex;
	flex-direction: column;
	padding: ${({padding}:CardPropsRenderProps):string=>padding||'24px 30px 34px 24px'} ;
	margin: ${({margin}:CardPropsRenderProps):string=>margin||'0'} ;
	background-color: ${(props:CardPropsRenderProps):string=> getBackGroundColor(props)};
  	border: ${({theme}:CardPropsRenderProps):string=> theme=== 'opus'? `1px solid ${color.get('neutral-500')}` : 'none'};

	justify-content: ${({$justifyContent}:CardPropsRenderProps):string=>$justifyContent||'flex-start'};
	align-items: ${({$alignItems}:CardPropsRenderProps):string=>$alignItems||'flex-start'};
	flex-grow: ${({$flexGrow}:CardPropsRenderProps):string=>$flexGrow||'0'};
	gap: ${({$gap}:CardPropsRenderProps):string=>$gap||'0'};
	height: ${({$height}:CardPropsRenderProps):string=>$height||'auto'};
	border-radius: ${({borderRadius}:CardPropsRenderProps):string=>borderRadius||'12px'};
	box-shadow: 0px 4px 12px rgba(0,0,0,0.4);
`;

