import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import TalentFeedRender from './TalentFeed.render';
import {useLazyQuery} from '@apollo/client';
import {query} from '../../../actions/queries';

export default function TalentFeedLoad():JSX.Element{
	const {id}:any = useParams();
	const [loading, setLoading] = useState<boolean>(true);

	// eslint-disable-next-line @typescript-eslint/typedef
	const [getData, { data }] = useLazyQuery(query('postFindMany'));

	useEffect(():void=>{
		if(loading){
			getData({
				variables: {
					filter: {
						user: id
					},
					limit: 15
				},
				fetchPolicy: 'no-cache',
				onCompleted: (data:any):void => setLoading(false)
			});
		}
	},[loading]);


	return (
		<TalentFeedRender posts={data?.postFindMany} />
	);
}