import React, { ChangeEvent } from 'react';
import { StyledLabel } from './styles';

interface ToggleRenderProps {
	id:string
	name:string
	switchState:boolean
	activeLabel?:string,
	inactiveLabel?:string
	justify?: 'right'| 'left' |'center'
	onChange:(e: ChangeEvent<HTMLInputElement>)=>void
}

const justifyByLabel:any={
	right:'align-end',
	left:'align-start',
	center:'align-center'
};

export const ToggleRender = ({id,name,onChange,switchState,justify='center',activeLabel,inactiveLabel}:ToggleRenderProps):JSX.Element => {
	return (
		<div className={`flex direction-column ${justifyByLabel[justify]}`}>
			<StyledLabel htmlFor={id} checked={switchState}>
				<input
					name={name}
					id={id}
					type="checkbox"
					checked={switchState}
					onChange={onChange}
				/>
			</StyledLabel>
			{switchState ?
				<p className='t-neutral-400 f-small mt-quarter'>{activeLabel}</p>
				:
				<p className='t-neutral-400 f-small mt-quarter'>{inactiveLabel}</p>
			}
		</div>
	);
};
