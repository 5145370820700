import React, {useEffect, useState} from 'react';
import {QueryTuple, useLazyQuery} from '@apollo/client';
import {query} from 'actions/queries';
import {TacticI} from 'interfaces';
import {SetterOrUpdater, useSetRecoilState} from 'recoil';
import {BreadCrumbAtom, TCrumb} from 'context/recoil/store';
import {Params, useParams} from 'react-router-dom';
import OpportunitiesSubtacticRender from './OpportunitiesSubtactic.render';

export const OpportunitiesSubtacticLoad = ():JSX.Element => {
	const setBreadCrumb: SetterOrUpdater<TCrumb[]>=useSetRecoilState(BreadCrumbAtom);
	const {slug}:Params = useParams();
	const [subtactic, setSubtactic] = useState<TacticI>();
	const [loadingSub, setLoadingSub] = useState<boolean>(true);

	// eslint-disable-next-line @typescript-eslint/typedef
	const [querySubtactics, { data:datasub }]:QueryTuple<any, any> = useLazyQuery(query('subtacticFindMany'));


	useEffect(():void=>{
		if(loadingSub){
			querySubtactics({
				variables: {
					filter : {
						slug
					}
				},
				fetchPolicy: 'no-cache',
				onCompleted: (data:any):void => {
					setLoadingSub(false);
					setSubtactic(data?.subtacticFindMany[0]);
				}
			});
		}
	},[loadingSub]);

	useEffect(():void => {
		setBreadCrumb([
			{id: 'opportunities', label: 'opportunities', path:'/opportunities'},
			{id: 'tactic', label: subtactic?.title || '', path:`/opportunities/subtactic/${subtactic?.slug}`}
		]);
	}, [subtactic]);


	return (
		<OpportunitiesSubtacticRender
			subtactic={subtactic}
		/>
	);
};
