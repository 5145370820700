import React, {useEffect} from 'react';
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';
import {AudienceItem} from '../../../hooks/useTalentDetail';

const geoUrl:string = '/assets/world.json';

interface MapUsaI {
	countries: AudienceItem[]
}

export default function MapWorld({countries}:MapUsaI):JSX.Element {

	return (
		<div className="map">
			<ComposableMap>
				<Geographies geography={geoUrl}>
					{({ geographies }:any):any =>
						geographies.map((geo:any):any => {

							let s:any = countries?.find((e:any):boolean=>e.name===geo.properties.name);
							const selected:boolean = s ? true : false;

							return <Geography
								key={geo.rsmKey}
								geography={geo}
								id={geo.id}
								style={{
									default: {
										fill: selected ? '#FAFF19' : '#1F222C'
									},
									hover: {
										fill: selected ? '#FAFF19' : '#1F222C'
									},
									pressed: {
										fill: selected ? '#FAFF19' : '#1F222C'
									}
								}}
								className={selected ? 'selected' : ''}
							/>;
						})
					}
				</Geographies>
				{/*<Marker coordinates={[-74.006, 40.7128]}>*/}
				{/*	<circle r={8} fill="#F53" />*/}
				{/*</Marker>*/}
			</ComposableMap>
		</div>
	);
}
