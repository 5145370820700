import React, {useState} from 'react';
import styled from 'styled-components';
import {ImageCard} from '../ui';
import {color} from 'styles/colors';
import {Score, Talent} from 'interfaces';
import {DiscoveryTalentModal} from 'components/complexComponents';

export interface TalentItemProps {
	talent:Talent
	score?:Score
}

const StyledTitle:any=styled.h6`
  margin-top: 1rem;
  font-weight: 900;
  font-size: 16px;
  line-height: 16px;
  font-family: 'Urbanist', sans-serif;
  color: #fff;
  
`;


const StyledDescription:any=styled.p`
  font-weight: 700;
  font-size: 12px;
  color: #545766;
  line-height: 12px;
  margin-top: 0.5rem;
  letter-spacing: 1px;
`;

const StyledScore:any=styled.p`
 	font-size: 1.6rem;
	background-color: ${color.get('primary')};
  width: 44px;
  border-radius: 50%;
  padding: 0.4rem 0.4rem;
  margin-top: 1rem;
`;


export const TalentItem=({talent,score}:TalentItemProps):JSX.Element=>{

	const [showTalent, setShowTalent] = useState<boolean>(false);

	return <div className="cursor-pointer">
		<DiscoveryTalentModal
			talentId={talent._id}
			setShowTalent={setShowTalent}
			isVisible={showTalent}
			add={true}
		/>

		<ImageCard img={talent.profilePicture?.url} onClick={():void=>setShowTalent(true)} />
		<div className={'flex justify-between'}>
			<div>
				<StyledTitle>{talent.firstName} {talent.lastName}</StyledTitle>
				<StyledDescription>
					{talent.profile?.industry?.length > 0 && talent.profile.industry[0]}
					{(talent?.profile?.industry.length > 0 && talent?.profile?.industrySegment.length > 0) ? ' | ' : ''}
					{talent.profile?.industrySegment?.length > 0 && talent.profile.industrySegment[0]}
				</StyledDescription>
			</div>
			{score&&<StyledScore>{score}</StyledScore>}
		</div>
	</div>;
};