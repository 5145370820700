import React from 'react';
import {FeedType, Item, TalentOpportunity, User} from 'interfaces';
import {Analytics, Content} from './components';
import {useLocation, Location} from 'react-router-dom';
import {ContentLayout} from '../../containers';

interface TalentDashBoard{
	user:User
	opportunities:TalentOpportunity[]
	feeds:FeedType[]
	socialNetworks:{
		selected:string
		options:Item[]
		handleSelect:(value:string)=>void
	}
	children?:React.ReactNode
}


export default function TalentDashBoardRender({user,opportunities,socialNetworks,children}:TalentDashBoard):JSX.Element {
	const {pathname}:Location=useLocation();
	const tabsLinks:Item[]=[
		{label:'opportunities',id:'/dashboard/opportunities'},
		// {label:'highlights',id:'/dashboard/highlights'},
		{label:'social',id:'/dashboard/social'},
		{label:'survey',id:'/dashboard/survey'}
	];

	return<>
		<ContentLayout
			column={<Analytics user={user} socialNetworks={socialNetworks}/>}
		>
			<Content
				opportunities={opportunities}
				user={user}
				path={pathname.split('/').slice(-1)[0]}
				tabsLinks={tabsLinks}
			>
				{children}
			</Content>
		</ContentLayout>
	</>;
}