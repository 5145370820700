import React, {useContext} from 'react';
import { EmailRender } from './EmailRender';
import {FormField, FormHook} from 'hooks/useForm/interfaces';
import {UserContext, userContextInterface} from 'context/userContext';
import {useForm} from 'hooks/useForm/useForm';
import {MutationTuple, useMutation} from '@apollo/client';
import {mutation} from 'actions/mutations';

export const Email = (): JSX.Element => {
	const {user,refresh}:userContextInterface=useContext(UserContext);
	const [changeEmail]:MutationTuple<any, any> =useMutation(mutation('userChangeEmail'));

	const formFields:FormField[]=[
		{name:'email',required:true,default:user?.email||'', pattern: /^\S+@\S+\.\S+$/},
		{name:'password',required:true,default:''}
	];
	const updateEmail=async(data:any):Promise<void>=>{
		if(data.email===user.email)
			return;
		await changeEmail({
			variables:{...data}
		});
		await refresh();

	};

	const {loading,errors,registerInput,handleSubmitForm}:FormHook =useForm(formFields,updateEmail);
	return <EmailRender
		submitLoading={loading}
		errors={errors}
		registerInput={registerInput}

		onSubmit={handleSubmitForm}
	/>;
};
