import React from 'react';
import {IgPostI} from '../../../hooks/useTalentDetail';
import IgLikeIcon from '../../../icons/IgLikeIcon';
import IgCommentIcon from '../../../icons/IgCommentIcon';
import {abbreviateMount} from '../../../tools/prices';


interface TalentFeedI {
	posts?: IgPostI[]
}

export default function TalentFeedRender({posts}:TalentFeedI):JSX.Element{

	return (
		<div className="grid3 col-gap-2 row-gap-2">
			{posts && posts?.length > 0 && posts.map(({mediaUrl, commentsCount, likesCount, permalink, postId, file}:IgPostI):JSX.Element=>{
				return (
					<a target="_blank" rel="noreferrer" className="relative flex align-end justify-center w-100 aspect-1-1" href={permalink || postId} key={postId}>
						<img src={file?.url || mediaUrl || ''} className="absolute edges w-100 aspect-1-1 obj-cover br w-100 obj-top" />
						<div className="neutral-800 br-half phor pver-half flex align-center t-white relative mb f-urbanist f-12px f-700">
							<div className="flex align-center mr-2">
								<span className="mr-half">
									<IgLikeIcon className="white op-60" />
								</span>
								{abbreviateMount(likesCount, 0)}
							</div>
							<div className="flex align-center">
								<span className="mr-half">
									<IgCommentIcon className="white op-60" />
								</span>
								{abbreviateMount(commentsCount, 0)}
							</div>
						</div>
					</a>
				);
			})}
		</div>
	);
}