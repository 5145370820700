import React, {useEffect, useState} from 'react';
import MultiSelector from './form/MultiSelector';
import {IFilter, IOption, ITalentFilter} from '../DiscoverInterfaces';
import {useRecoilState} from 'recoil';
import {filterAtom} from '../../../context/recoil/store';

export default function TalentGender():JSX.Element {

	const [filter, setFilter] = useRecoilState<IFilter>(filterAtom);
	const [value, setValue] = useState<string[]>([]);
	const [loading, setLoading] = useState<boolean>(true);

	const options:IOption[] = [
		{
			label:'Male',
			_id: 'MALE'
		},
		{
			label: 'Female',
			_id: 'FEMALE'
		}
	];

	useEffect(():void=>{
		if(loading){
			if(filter?.talent?.gender){
				setValue(filter?.talent?.gender);
			}
			setLoading(false);
		}
	},[loading]);

	useEffect(():void=>{
		let f: IFilter = {...filter};
		let t: ITalentFilter = {...filter.talent};
		f.talent = t;
		if(value.length === 1 && !value.includes('NB')){
			f.talent.gender = [...value];
		}
		else{
			delete f.talent.gender;
		}
		setFilter(f);

	},[value]);

	return (
		<div>
			<MultiSelector options={options} value={value} setValue={setValue} />
		</div>
	);
}