import React, {useEffect, useState} from 'react';
import {AudienceItem} from 'pages/Insights/interfaces';
import {Avatar, Tag} from 'stylesOpus/ui';
import {BoxTitle} from 'stylesOpus/typography';
import {Flex} from 'stylesOpus/layout';
import {RoundPercentage, Spinner} from 'components/ui';
import {Score} from 'interfaces';
import {useTalentsScores} from 'hooks';
import {TalentScoreRes} from 'hooks/useTalentsScores/types';

interface AuthorityRowProps {
	talentId: string
	interest: string
	weight: number
	avatar?: string
	audienceBrand?: AudienceItem[]
	audienceInterests?: AudienceItem[]
}


export default function AuthorityRow({interest,talentId, avatar, weight, audienceBrand, audienceInterests}:AuthorityRowProps):JSX.Element {

	const {data:talentScoreData,loading:talentScoreDataLoading}:TalentScoreRes = useTalentsScores({
		interests:[interest],
		talentIds:[talentId]
	});


	const [brands, setBrands] = useState<string[]>();

	useEffect(():void => {
		if(audienceBrand.length) {
			const res:any = audienceBrand.filter((e:any):any => e.interest.includes(interest));
			setBrands(res.map(({name}:any):string => name));
		}
	}, audienceBrand);

	let score: Score|null= null;
	!talentScoreDataLoading && (score = talentScoreData && talentScoreData[0]);

	return (
		<div className="pver">
			<Flex className="mver flex">
				<Avatar width='38px' height='38px' borderWidth='5px' >
					<img src={avatar} alt={'Profile picture'}/>
				</Avatar>
				<BoxTitle className="ml">
					{interest}
				</BoxTitle>
				<div className={'grows flex justify-end'}>
					{!talentScoreDataLoading ?
						score && <RoundPercentage
							size={'38px'}
							percentage={score.instagram.authority * 100}
							fixed={0}
						/> :

						<Spinner/>
					}
				</div>
			</Flex>
			{brands && brands.length > 0 &&
				<Flex alignItems='start' className='mt-2'>
					<BoxTitle className="no-wrap mr mt-half" fontSize="10px">Top Brands:</BoxTitle>
					<Flex wrap='wrap'>
						{brands?.slice(0,5).map((name:string):JSX.Element=><Tag key={name} margin='0 12px 12px 0'>{name}</Tag>)}
					</Flex>
				</Flex>
			}


		</div>
	);
}