import React from 'react';

export const BackIcon=():JSX.Element=><svg
	width={16}
	height={13}
	viewBox="0 0 16 13"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M0.992188 6.35156C0.992188 6.59635 1.08594 6.8099 1.27344 6.99219L6.42188 12.1328C6.60417 12.3099 6.8099 12.3984 7.03906 12.3984C7.27865 12.3984 7.47917 12.3203 7.64062 12.1641C7.80208 12.0078 7.88281 11.8125 7.88281 11.5781C7.88281 11.4583 7.85938 11.3464 7.8125 11.2422C7.76562 11.138 7.70312 11.0469 7.625 10.9688L5.875 9.20312L2.96875 6.54688L2.6875 7.04688L5.39844 7.20312H14.8906C15.151 7.20312 15.3594 7.125 15.5156 6.96875C15.6771 6.80729 15.7578 6.60156 15.7578 6.35156C15.7578 6.09635 15.6771 5.89062 15.5156 5.73438C15.3594 5.57292 15.151 5.49219 14.8906 5.49219H5.39844L2.6875 5.65625L2.96875 6.16406L5.875 3.5L7.625 1.72656C7.70312 1.64844 7.76562 1.5599 7.8125 1.46094C7.85938 1.35677 7.88281 1.24219 7.88281 1.11719C7.88281 0.882812 7.80208 0.6875 7.64062 0.53125C7.47917 0.375 7.27865 0.296875 7.03906 0.296875C6.92448 0.296875 6.8151 0.320312 6.71094 0.367188C6.60677 0.414062 6.50781 0.484375 6.41406 0.578125L1.27344 5.71094C1.08594 5.89323 0.992188 6.10677 0.992188 6.35156Z"
		fill="white"
	/>
</svg>;
