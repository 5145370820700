import React from 'react';
import {StoryText, StoryWrapper, Thumbnail} from './styles';
import {SerpapiStory} from 'interfaces';

export interface StoryRenderProps {
	story:SerpapiStory
}
export default function StoryRender({story}:StoryRenderProps):JSX.Element{
	return <StoryWrapper href ={`${story.link}`} target={'_blank'}>
		<Thumbnail src={story.thumbnail} alt={story.title}/>
		<StoryText>
			<p>{story.source}</p>
			<p>{story.title}</p>
			<p>{story.date}</p>
		</StoryText>
	</StoryWrapper>;
}