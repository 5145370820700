import React from 'react';
import {Route} from './interfaces';
import {OpportunityCreate} from 'pages';
import {CampaignAudience, CampaignCreate,CampaignTalent } from 'pages/OpprtunityCreate/forms';

export const opportunityCreateRoutes:Route[] =[


	{
		path:'/opportunities/form/:id',
		element: <OpportunityCreate/>,
		children:[
			{
				path:'edit',
				element:<CampaignCreate/>
			},
			{
				path:'edit/audience',
				element:<CampaignAudience/>
			},
			{
				path:'edit/talent',
				element:<CampaignTalent/>
			}

		]
	}
];