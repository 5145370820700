import React from 'react';
import styled, {StyledProps} from 'styled-components';

export interface MessageRenderProps {
	className?:string
	message:string
	color:string
}

const StyledSMessage:any= styled.p`
  	font-size: 0.9rem;
	color: ${({color}:StyledProps<any>):string=>color||'red'} !important;
`;

export const MessageRender=({message,color,...props}:MessageRenderProps):JSX.Element=>{
	return (
		<StyledSMessage color={color} {...props}>
			{message}
		</StyledSMessage>
	);
};
