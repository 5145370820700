import React, {useEffect} from 'react';
import ScoreCircleRender from './ScoreCircleRender';
import {QueryResult, useQuery} from '@apollo/client';
import {query} from '../../../actions/queries';

interface CircleProps{
	interest: string,
	userId:string
}

export default function ScoreCircle({interest,userId}:CircleProps):JSX.Element {

	const {data,loading,error}:QueryResult=useQuery(query('talentScoreFindMany'),{
		variables:{
			talent: [userId], //Ice Spice Test c5ad2120-1424-11ee-91ae-c117988020a8
			audience: {
				topic: [interest]
			},
			platforms: [
				'instagram'
			]
		}//,
		//fetchPolicy:'cache-and-network'
	});

	if(loading){
		return <div className="center">Loading Score...</div>;
	}
	else if(error){
		return <div className="center">Could not load Score for this interest...</div>;
	}
	else if(data && data.talentScoreFindMany?.scores.length) {
		return (
			<ScoreCircleRender score={data.talentScoreFindMany.scores[0]}/>
		);
	}
	else{
		return <div className="center">Could not load Score for this interest...</div>;
	}

}