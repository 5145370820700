import React from 'react';
import {Section} from 'styles/styledComponents/containers';
import {Link} from 'react-router-dom';
import ArticleRow from './components/ArticleRow';

interface HomeDataI {
	homeData: {
		tagRow? : TagRowI[]
	}
}

interface TagRowI {
	title?: string
	cover?: string | any
	coverId?: string | number
	tags?: TagI[] | any
	id?: string | number
	titleColor?:string
}

interface TagI {
	id: number | string
	title: string
}

export const HotListRender = ({homeData}:HomeDataI):JSX.Element => {

	return (

		<Section>
			<h3 className="f-1rem f-urbanist ls-1-6">
				Dive into your favorite
			</h3>
			<p className="f-12px mt-half t-neutral-300 f-urbanist ls-1-2">
				Explore the latest in Fashion, Music, Sports and more
			</p>
			<div className="mt">
				<div className="flex mb">
					{homeData?.tagRow && homeData?.tagRow.length > 0 && homeData?.tagRow.slice(0,2).map(({title, cover, titleColor, id, tags}:TagRowI, index:number):JSX.Element => {
						return (
							<div key={`${title}-home-item`} className={`${index === 0 ? 'w-55 mr' : 'grows'} h-170px br neutral-800 relative p-1half border-box flex justify-start align-end t-${titleColor}`}>
								<Link to={`/hotlist/home/${id}`}>
									{cover &&
										<img
											src={`${cover?.data?.attributes?.url}`}
											className="w-100 h-100 obj-cover absolute edges br"
										/>
									}
									<span className={`f-urbanist f-1rem f-700 relative f-20px t-${titleColor}`}>{title}</span>
								</Link>
							</div>
						);
					})}
				</div>

				<div className="grid3 col-gap mb">
					{homeData?.tagRow && homeData?.tagRow.length > 0 && homeData?.tagRow.slice(2,5).map(({title, cover, titleColor, id, tags}:any):JSX.Element => {
						return (
							<div key={`${title}-home-item`} className={`h-170px br neutral-800 relative p-1half border-box flex justify-start align-end t-${titleColor}`}>
								<Link to={`/hotlist/home/${id}`}>
									{cover &&
										<img
											src={`${cover?.data?.attributes?.url}`}
											className="w-100 h-100 obj-cover absolute edges br"
										/>
									}
									<span className={`f-urbanist f-1rem f-700 f-20px relative t-${titleColor}`}>{title}</span>
								</Link>
							</div>
						);
					})}
				</div>

				<div className="flex mb">
					{homeData?.tagRow && homeData?.tagRow.length > 0 && homeData?.tagRow.slice(5,7).map(({title, cover, titleColor, id, tags}:any, index:number):JSX.Element => {
						return (
							<div key={`${title}-home-item`} className={`${index !== 0 ? 'w-55' : 'grows mr'} h-170px br neutral-800 relative p-1half border-box flex justify-start align-end t-${titleColor}`}>
								<Link to={`/hotlist/home/${id}`}>
									{cover &&
										<img
											src={`${cover?.data?.attributes?.url}`}
											className="w-100 h-100 obj-cover absolute edges br"
										/>
									}
									<span className={`f-urbanist f-1rem f-700 f-20px relative t-${titleColor}`}>{title}</span>
								</Link>
							</div>
						);
					})}
				</div>

			</div>
			<ArticleRow
				title="Opus Spotlight"
				subtitle="Check out some of our team’s favorites."
				params={{
					'filters[tags]' : 7,
					'sort':'publishDate:desc'
				}}
				viewAll="/hotlist/spotlight"
			/>

			<ArticleRow
				title="Latest"
				subtitle="Stay up-to-date with the most recent cultural trends and insights."
				params={{
					'sort':'publishDate:desc'
				}}
				viewAll="/hotlist/latest"
			/>

		</Section>

	);
};
