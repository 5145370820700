import React from 'react';
import {CompanyProfile, FormProps, Item, User, UserType} from 'interfaces';
import {FormButton, Input, Select, TextBox} from 'components/ui';
import {PictureInput} from 'components';

export interface ProfileFormProps extends FormProps{
	user:User
	genders:Item[]
	companyProfile?:CompanyProfile
}

export const ProfileFormRender = ({user,companyProfile,submitLoading,genders,registerInput,registerTextArea,registerSelect, onSubmit,onChangeFile}:ProfileFormProps):JSX.Element=>{

	const defaultImage:string= user.type.label === UserType.Regular ? user.profilePicture?.url : companyProfile?.logo?.url;

	return <div className="w-100 max-w-700px pb-4">
		<div className={'flex w-100 justify-center mver-2'}>
			<PictureInput
				defaultImage={defaultImage}
				onChange={onChangeFile}
				plus
				size={'200px'}
			/>
		</div>

		<form onSubmit={onSubmit}>
			<Input
				label={'First Name'}
				placeholder={'Name'}
				{...registerInput('firstName')}
			/>
			<Input
				label={'Last Name'}
				placeholder={'Last Name'}
				{...registerInput('lastName')}
			/>

			<TextBox
				label={'Bio'}
				{...registerTextArea('description')}
			/>
			<Input
				label={'Date of Birth'}
				placeholder={'mm/dd/yyyy'}
				{...registerInput('birthday')}
			/>
			<Select
				label={'Gender'}
				options={genders}
				// defaultValue={'8c27e9f8-3518-11ed-a261-0242ac120002'}
				{...registerSelect('gender')}

			/>
			<div className="center">
				<FormButton
					loading={submitLoading}
					label={'save'}
					variant={'secondary'}
				/>
			</div>

		</form>
	</div>;
};