import React, {useEffect} from 'react';
import CheckList from '../form/CheckList';
import {QueryResult, useQuery} from '@apollo/client';
import {query} from '../../../../actions/queries';
import {IIndustry} from '../../DiscoverInterfaces';

interface ISegmentList {
	_id: string
	value?: string[]
	setValue?: (segments:string[]) => void
}

export default function IndustrySegmentList({_id, value, setValue}:ISegmentList):JSX.Element {

	const {data,loading,error, networkStatus}:QueryResult=useQuery(query('industrySegmentFindMany'), {
		variables:{
			filter: { parent : _id}
		},
		fetchPolicy: 'network-only'
	});

	return (
		<div>
			{loading ?
				''
				:
				data?.industrySegmentFindMany?.length > 0 && data.industrySegmentFindMany.map(
					({_id, label, identifier}:IIndustry):JSX.Element=>{
						return (
							<CheckList key={_id} label={label} _id={_id} value={value} setValue={setValue} />
						);
					}
				)
			}
		</div>
	);
}