import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig: any = {
	apiKey: 'AIzaSyANFszS4HdCBm2L2eL0lrbHg_TIRk0Kzp8',
	authDomain: 'opus-test-2.firebaseapp.com',
	projectId: 'opus-test-2',
	storageBucket: 'opus-test-2.appspot.com',
	messagingSenderId: '560774950588',
	appId: '1:560774950588:web:a3f142053f75b79d106ab9'
};

// Initialize Firebase
const firebaseApp:any = initializeApp(firebaseConfig);
// Initialize Firebase Auth provider
const provider : GoogleAuthProvider = new GoogleAuthProvider();

// whenever a user interacts with the provider, we force them to select an account
provider.setCustomParameters({
	prompt : 'select_account '
});
export const auth:any = getAuth();
export const signInWithGooglePopup = ():any => signInWithPopup(auth, provider);

