import React from 'react';
import {StyledDivider} from './styles';

interface DividerProps {
	margin?: string
}

export const Divider=({margin}:DividerProps):JSX.Element=>{
	return <StyledDivider margin={margin} />;
};
