import React, {useEffect, useState} from 'react';
import {ArticleCard, TArticle} from '../../../components/ArticleCard/ArticleCard';
import {Section} from '../../../styles/styledComponents/containers';
import {opusContent} from '../../../actions/strapiActions';
import {Link} from 'react-router-dom';

interface ArticleRowI {
	title: string
	params?: any
	limit?: number
	subtitle?: string
	viewAll?: string
}

export default function ArticleRow({title, params, limit=4, subtitle, viewAll}:ArticleRowI):JSX.Element{
	const [articles, setArticles] = useState<TArticle[]>([]);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(():void => {
		if(loading){
			getEntries();
		}
	}, [loading]);

	const getEntries = async():Promise<any> => {

		const h:any = await opusContent('hotlists', {
			...params,
			'pagination[pageSize]': limit,
			'populate[0]': 'cover'
		});
		setLoading(false);
		const a:TArticle[] = h?.data?.data?.map(({id, attributes}:any):TArticle => {
			return {
				title: attributes?.title || '',
				subtitle: attributes?.subtitle || '',
				picture: attributes?.cover?.data?.attributes?.url,
				date: attributes?.publishDate,
				link: `/hotlist/${id}/detail`
			};
		});
		if(a.length > 0){
			setArticles(a);
		}
	};

	return (
		<Section className={'pt-2 mt-half'}>
			<div>
				<div className="flex">
					<h3 className="f-1rem f-urbanist ls-1-6 grows">
						{title}
					</h3>
					{viewAll &&
						<Link to={viewAll} className="f-urbanist f-12px neutral-900 f-800 pver-half phor br t-white">
							View All
						</Link>
					}
				</div>
				{subtitle &&
					<p className="f-12px t-neutral-300 f-urbanist ls-1-2">
						{subtitle}
					</p>
				}
			</div>
			<div className={'w-100 grid4 col-gap mt row-gap-2'}>
				{articles?.length > 0 && articles.map((article:TArticle, index:number):JSX.Element =>
					<ArticleCard
						key={`${index}-${article.title}`}
						article={article}
						isNew={false}
					/>
				)}
			</div>
		</Section>
	);
}
