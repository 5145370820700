import axios, {AxiosInstance, AxiosPromise} from 'axios';

const REACT_APP_STRAPI_API:string|undefined=`${process.env.REACT_APP_STRAPI_API}/api`;
const bearer:string =process.env.REACT_APP_STRAPI_TOKEN;


export const getFeeds=(params:any):any=>{
	const searchParams:string=new URLSearchParams(params).toString();

	return fetch(`${REACT_APP_STRAPI_API}/feeds?${searchParams}`,{
		method:'get',
		credentials:'include'
	});
};

export const getHotlists=(params:any):any=>{
	const searchParams:string=new URLSearchParams(params).toString();

	return fetch(`${REACT_APP_STRAPI_API}/hotlists?${searchParams}`,{
		method:'get',
		credentials:'include'
	});
};


export const getFeed=({id,params}:any):any=>{
	const searchParams:string=new URLSearchParams(params).toString();

	return fetch(`${REACT_APP_STRAPI_API}api/feeds/${id}?${searchParams}`,{
		method:'get',
		credentials:'include'
	});
};

const OpusContentApi:AxiosInstance = axios.create({
	baseURL: REACT_APP_STRAPI_API,
	headers: {
		Authorization: `Bearer ${bearer}`
	}
});

export const opusContent= (endpoint:string,params:any): AxiosPromise => {

	const d:string = new Date().toISOString();
	let p:any = {};
	if(endpoint === 'hotlists'){
		p = {
			'filters[published]' : true,
			'filters[publishDate][$lt]': d,
			'filters[archive]': false,
			...params
		};
	}
	else{
		p = {
			...params
		};
	}

	return OpusContentApi.get(
		`${endpoint}`,
		{
			params : p
		}
	);
};


export const likeHotlist = (data:any): AxiosPromise => {
	return OpusContentApi.post(
		'favorite-hotlists',
		{
			data
		}
	);
};

export const likedHotlist = (params:any): AxiosPromise => {
	return OpusContentApi.get(
		'favorite-hotlists',
		{
			params
		}
	);
};

export const deleteHotlist = ({id}:any): AxiosPromise => {
	return OpusContentApi.delete(
		`favorite-hotlists/${id}`
	);
};
