import React from 'react';
import {LogoutContainer} from './styles';
import {ShareIcon} from '../../icons';
import styled from 'styled-components';
import {themeOpus} from '../../stylesOpus/theme';

interface LogoutButtonProps {
	active?:boolean
	logout:()=>void
}

const Text :any = styled.p`
	color:  ${({$active}:any):string=> $active? '#fff' : '#6F7386'};
	font-size: 12px;
	letter-spacing: 1.2px;
`;

export default function LogoutRender({logout, active =false}:LogoutButtonProps):JSX.Element{
	return (
		<LogoutContainer>
			<div className="w-1halfrem justify-center align-center aspect-1-1 flex">
				<ShareIcon color={active? themeOpus.colors.white : '#6F7386'} />
			</div>

			<Text onClick={logout} $active={active}>
				Logout
			</Text>
		</LogoutContainer>
	);
}
