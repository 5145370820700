import styled from 'styled-components';

interface StyledBarProps{
	color:string;
	percentage:string;
	size:string
}

export const BarWrapper:any=styled.div`
  width: ${({size}:StyledBarProps):string=>size};
  display: flex;
  align-items: center;
`;

export const Bar:any=styled.div`
  width: ${({percentage}:StyledBarProps):string=>percentage}%;
  height: 40px;
  display: flex;
  justify-content: start;
  border-radius: 0 24px 24px 0;
  margin-right: 1rem;
  background: ${({color}:StyledBarProps):string=>color};
`;

