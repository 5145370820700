import React from 'react';
import {NotificationIcon} from 'icons';


export interface HeaderIconProps {
	label?:string
	Element?:React.FC
	action?:()=>void
}

export const HeaderIcon=({Element,label,action}:HeaderIconProps):JSX.Element=>{

	return <div className={'cursor-pointer flex align-center'}  onClick={action}>
		<div onClick={action}>
			{Element && Element({}) ||<NotificationIcon />}
		</div>
		{label &&<p className={'ml f-700'}>{label}</p>}
	</div>;
};