import React, { useContext} from 'react';
import {Navigate, Outlet, useLocation, Location} from 'react-router-dom';

import {UserContext} from 'context/userContext';
import {useSocials} from 'hooks/useSocials';
import {TUserType} from 'interfaces';

export interface AuthRouteProps {
    redirectTo?:string
    verified?:boolean
    children?:JSX.Element
    withsSocials?:boolean
}

const verifyPathByUSerType:Record<TUserType,string>={
	Regular:'/onboarding/talent/verify',
	Company:'/onboarding/brand/verify',
	Agent:'/onboarding/company/verify'
};

export const AuthRoute = ({redirectTo='/login',children,verified=true}:AuthRouteProps):JSX.Element=>{
	const {pathname}:Location = useLocation();
	const {user,loading}:any=useContext(UserContext);
	const {socialsData,loading:socialsLoading}:any=useSocials();
	if (loading || socialsLoading)
		return <p>Loading...</p>;
	if ( !pathname.includes('/verify') && user && verified && !user.verified){
		return <Navigate to={verifyPathByUSerType[user.type.label]} />;
	}
	let redirectSocials:boolean=!socialsData || socialsData.length<1;
	// if( withsSocials && redirectSocials && user?.type.label==='Regular')
	// 	return <Navigate to={'/onboarding/link-social'} />;
	return user? children||<Outlet/> :<Navigate to={redirectTo} />;
};

