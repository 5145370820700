import React from 'react';
import {FormButton, Input} from 'components/ui/';
import {Message} from 'components';
import {color} from 'styles/colors';
import {FormProps} from 'interfaces';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

const Title:any = styled.h1`
	font-family: 'Lato', sans-serif;
	font-size: 2.5rem;
	font-style: normal;
	font-weight: 700;
	line-height: 137.5%;

`;

export default function LoginRender({submitLoading,errors,onSubmit,registerInput}:FormProps):JSX.Element{

	return (
		<div className="w-100 p">
			<Title className="center mb f-lato">Welcome Back</Title>
			{/*<BodyText className="mb-3 center">*/}
			{/*	Lorem ipsum dolor sit amet consectetur. Elementum magna donec lacus sit morbi tempus et feugiat dui.*/}
			{/*</BodyText>*/}
			<form onSubmit={onSubmit} className={'flex direction-column'} autoComplete='off'>
				<Input
					placeholder={'Email Address'}
					{...registerInput('email')}
				/>
				<Input
					placeholder={'Password'}
					type={'password'}
					{...registerInput('password')}
				/>
				<div className="center mt-2">
					<FormButton
						variant={'grey'}
						label={'Submit'}
						loading={submitLoading}
					/>
				</div>
				{errors?<div className={'mt center'}>
					{errors.get('submitError') && <Message message={errors.get('submitError')} color={color.get('primary')}/>}
					{errors.get('email')?.pattern && <Message message={'Email must be a valid email'} color={color.get('primary')}/>}
				</div>:null}

			</form>
			<Link
				className="t-white f-small  mt-half align-self-end"
				to ='/forgot-password'
			>Forgot Password?</Link>
		</div>
	);
}
