import React, {useEffect, useState} from 'react';
import {QueryResult, QueryTuple, useLazyQuery, useQuery} from '@apollo/client';
import {query} from 'actions/queries';

import {CulturalAlert, TacticI} from 'interfaces';

import {OpportunitiesRender} from './OpportunitiesRender';
import {SetterOrUpdater, useSetRecoilState} from 'recoil';
import {BreadCrumbAtom, TCrumb} from 'context/recoil/store';


export const Opportunities = ():JSX.Element => {	// get cultural alerts
	const setBreadCrumb: SetterOrUpdater<TCrumb[]>=useSetRecoilState(BreadCrumbAtom);

	const [loading, setLoading] = useState<boolean>(true);
	const [tactics, setTactics] = useState<TacticI[]>([]);
	const [subtactics, setSubtactics] = useState<TacticI[]>();
	const [loadingSub, setLoadingSub] = useState<boolean>(true);

	// eslint-disable-next-line @typescript-eslint/typedef
	const [queryTactics, { data }]:QueryTuple<any, any> = useLazyQuery(query('tacticsFindMany'));

	// eslint-disable-next-line @typescript-eslint/typedef
	const [querySubtactics, { data:datasub }]:QueryTuple<any, any> = useLazyQuery(query('subtacticFindMany'));


	useEffect(():void=>{
		if(loading){
			queryTactics({
				variables: {
					filter: {
						active: true
					}
				},
				fetchPolicy: 'no-cache',
				onCompleted: (data:any):void => {
					console.log('tactics', data);
					setLoading(false);
					setTactics(data?.tacticFindMany || []);
				}
			});
		}
	},[loading]);

	useEffect(():void=>{
		if(loadingSub){
			querySubtactics({
				variables: {
					filter: {
						active: true
					}
				},
				fetchPolicy: 'no-cache',
				onCompleted: (data:any):void => {
					console.log('subtactics', data);
					setLoadingSub(false);
					setSubtactics(data?.subtacticFindMany || []);
				}
			});
		}
	},[loadingSub]);


	const {data:alertsData,loading:alertsLoading}:QueryResult =useQuery(query('culturalAlertByCompanyFindMany'),
		{variables:{
			skip:0,
			limit:4
		}});
	const alerts :CulturalAlert[] = alertsData?.culturalAlertByCompanyFindMany || [];

	useEffect(():void => {
		setBreadCrumb([
			{id: 'opportunities', label: 'opportunities', path:'/opportunities'}
		]);
	}, []);

	return (
		<OpportunitiesRender
			opportunities={alerts}
			tactics={tactics}
			subtactics={subtactics}
		/>
	);
};
