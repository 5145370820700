import React from 'react';

export const ArrowLeftLargeIcon=():JSX.Element=>{
	return <svg
		width={16}
		height={13}
		viewBox="0 0 16 13"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M.992 6.352c0 .244.094.458.281.64l5.149 5.14a.864.864 0 00.617.266c.24 0 .44-.078.602-.234a.782.782 0 00.242-.586.809.809 0 00-.07-.336.912.912 0 00-.188-.273l-1.75-1.766-2.906-2.656-.281.5 2.71.156h9.493c.26 0 .468-.078.625-.234a.836.836 0 00.242-.617.82.82 0 00-.242-.618c-.157-.161-.365-.242-.625-.242H5.398l-2.71.164.28.508L5.876 3.5l1.75-1.773a.828.828 0 00.258-.61c0-.234-.08-.43-.242-.586a.831.831 0 00-.602-.234.79.79 0 00-.328.07 1.006 1.006 0 00-.297.211l-5.14 5.133a.866.866 0 00-.282.64z"
			fill="#fff"
		/>
	</svg>;
	
};
