import React, {useEffect, useRef, useState} from 'react';
import {AuthScoreI} from '../../../interfaces';
import {QueryTuple, useLazyQuery} from '@apollo/client';
import {query} from '../../../actions/queries';
import {CaratDown} from '../../../icons';

interface CategorySelectorI{
	user:string
	authSelected?:AuthScoreI
	setAuthSelected:(a:AuthScoreI)=>void
	setWeight:(w:number)=>void
}

function useOutsideAlerter(ref:any, setSelector:(s:boolean)=>void):any {
	useEffect(():any => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event:any):any {
			if (ref.current && !ref.current.contains(event.target)) {
				setSelector(false);
			}
		}
		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return ():any => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref]);
}

export default function CategorySelector({user, authSelected, setAuthSelected, setWeight}:CategorySelectorI):JSX.Element {

	const [selector, setSelector] = useState<boolean>(false);
	const [authScores, setAuthScores] = useState<AuthScoreI[]>([]);
	const [loading, setLoading] = useState<boolean>(true);

	const wrapperRef:any = useRef(null);
	useOutsideAlerter(wrapperRef, setSelector);

	// eslint-disable-next-line @typescript-eslint/typedef
	const [queryAuthority, { data }]:QueryTuple<any, any> = useLazyQuery(query('talentAuthorityFindOne'));

	// eslint-disable-next-line @typescript-eslint/typedef
	const [queryAudience, { data:dataAudience }]:QueryTuple<any, any> = useLazyQuery(query('instagramAudienceFindOne'));

	useEffect(():void=>{
		if(loading){
			queryAuthority({
				variables : {user},
				fetchPolicy: 'no-cache',
				onCompleted: (data:any):void => {
					//Query audience after authority
					queryAudience({
						variables : {
							filter : {user}
						},
						fetchPolicy: 'no-cache',
						onCompleted: (audience:any):void => {
							setLoading(false);
							setUserInfo(data,audience);
						}
					});

				}
			});
		}
	},[loading]);

	const setUserInfo = (data:any,audience:any):void => {

		if(data?.talentAuthorityFindOne){
			let d:any = [...data.talentAuthorityFindOne];
			let dd:any = d.filter((e:any):any => !e._id.hidden);
			dd.sort((a:any,b:any):any => b.value - a.value);
			setAuthSelected(dd[0]);

			dd.sort((a:any,b:any):any => b?._id?.title < a?._id?.title);
			//dd.sort((a:any,b:any):any => b.value - a.value); (Order values)
			setAuthScores(dd);
		}
	};

	useEffect(():void=>{
		const a:any = dataAudience?.instagramAudienceFindOne?.audienceInterests;
		if(a && a.length > 0 && authSelected?._id){
			const category:any = a.find((e:any):any => e.name === authSelected?._id?.tensorInterests[0]);
			setWeight(category?.weight || 0);
		}
	},[dataAudience, authSelected]);

	return (
		<div ref={wrapperRef} className="neutral-800 br-half f-urbanist f-bold t-white relative box-shadow w-300px mver">
			<div className="pver-half phor-1quarter flex align-center" onClick={():void=>setSelector(!selector)}>
				<span className="grows">{authSelected?._id?.title || authSelected?._id?.label}</span> <CaratDown />
			</div>
			{selector &&
				<div className="max-h-300px overflow-y-scroll b-top b-neutral-600 absolute t-2 z-1000 neutral-800 box-shadow w-100">
					{authScores && authScores?.length > 0 && authScores?.map(({_id: {title, label, _id, hidden, picture}, value}:AuthScoreI, index:number):JSX.Element=>{
						return (
							<div
								className={`pver-half phor-1quarter cursor-pointer ${title === authSelected?._id?.title ? 't-white' : 't-neutral-300'}`}
								key={_id}
								onClick={():void=>{
									setAuthSelected(authScores[index]);
									setSelector(false);
								}}
							>
								{title ? title : label}
							</div>
						);
					})}
				</div>
			}
		</div>
	);
}