import React from 'react';
import {StyledAnchorButton} from 'styles/ui';

import {CulturalAlert, User} from 'interfaces';
import {Picture} from 'stylesOpus/layout';
import {BodyText, PageTitle} from 'stylesOpus/typography';
import {GenericContainer} from 'styles/styledComponents/containers';
import styled from 'styled-components';
import {ReactComponent as VideoIcon} from 'styles/icons/video-icon.svg';
import {themeOpus} from 'stylesOpus/theme';

type AlertHeroViewProps = {
    alert:CulturalAlert
    artist:User|null
    followersCount:number
    copies:{
        location:string
        expiration:string
        estimatedAsk:string
    }
}

enum EventType {
    'musicvideo'='Music Video',
    'event' = 'Event'
}

const HeroContainer:any =styled.div`
	width: 100%;
`;

const TalentName:any = styled(PageTitle)`
	font-weight: 800;
	letter-spacing: 0.1875rem;
`;

export const AlertHeroView = ({alert, artist}:AlertHeroViewProps): JSX.Element => {
	const artistName:string = `${artist.firstName} ${artist.lastName}` ;
	const copy2:string = EventType[alert.culturalType] ;
	const alertTitle:string =alert.culturalType==='musicvideo' ? `${alert.title}` : `${alert.title}` ;
	const deckText:string= 'Learn More';
	const imageUrl:string = alert?.photos?.length ? alert?.photos[0].url : artist?.profilePicture?.url;
	console.log('alert', {alert,artist, imageUrl});
	return(
		<HeroContainer className={'grid2 col-gap-4'}>
			<div>
				<Picture className={'w-100'}
					src={imageUrl}
				/>
			</div>

			<div className={'flex direction-column row-gap'}>
			
				<div className={'pt-2 flex direction-column row-gap-2'}>
					<TalentName fontSize={'1.875rem'}>{artistName}</TalentName>
					<GenericContainer color={themeOpus.colors.grey_600} className={'col-gap'} padding ='0.5rem 1.5rem'>
						<VideoIcon/>
						<p>{copy2}</p>
					</GenericContainer>
					{/*<h6>{alertTitle}</h6>*/}
					<BodyText>
						{alert?.description}
					</BodyText>
				</div>
			


				<div className={'w-100 pt flex direction-column row-gap'}>
					{
						(alert.deck || alert.videoTreatment) &&
							<StyledAnchorButton width={'100%'} href={alert.deck[0]?.url ||alert.videoTreatment?.url } target="_blank" variant="secondary-white"
							>{deckText}
							</StyledAnchorButton>
					}
					<StyledAnchorButton href={'mailto:erick@opusintel.co '} variant='grey'>
                        Inquire
					</StyledAnchorButton>
				</div>
			</div>

		</HeroContainer>
	);
};