import React, {useEffect, useState} from 'react';
import Selector from '../form/Selector';
import {IFilter, IInterest, IMatch, IMatchAudience, IOption, ITalent, ITalentFilter} from '../../DiscoverInterfaces';
import {QueryResult, useQuery} from '@apollo/client';
import {query} from '../../../../actions/queries';
import {InputBox, InterestBox} from '../styledComponents';
import {CloseIcon} from '../../../../icons';
import {useRecoilState} from 'recoil';
import {filterAtom, interestFormAtom, matchQueryAtom} from '../../../../context/recoil/store';
import AudienceInterestRender from './AudienceInterest.render';

export default function AudienceInterest():JSX.Element {

	const [options, setOptions] = useState<IOption[]>([]);

	const {data,loading,error, networkStatus}:QueryResult=useQuery(query('interestFindMany'), {
		variables:{
			filter: {hidden: false},
			limit: 50,
			skip: 0,
			sort: '_ID_ASC'
		},
		onCompleted: (data:any):void => {
			const i:IOption[] = data.interestFindMany.map(({label, title, _id, tensorInterests}:IInterest):IOption=>{
				return {
					label,
					_id,
					value: title
				};
			});

			i.sort((a:IOption,b:IOption):any => a.value.localeCompare(b.value));
			setOptions(i);
		},
		fetchPolicy: 'network-only'
	});



	return (
		<div>
			{options && options?.length > 0 &&
				< AudienceInterestRender options={options} />
			}
		</div>
	);
}