import React, {useState} from 'react';
import {AuthRoute} from '../AuthRoute';
import { CreateAlertModal} from './components';

export const AlertsContainer= ():JSX.Element=>{
	const [showModal,setShowModal]=useState<boolean>(false);
	return <>
		{showModal && <CreateAlertModal
			close={():void=>setShowModal(false)}
		/>}
		<AuthRoute redirectTo={'/'}/>
	</>;
};