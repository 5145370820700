import {ReachIcon} from 'icons/ReachIcon';
import {TotalReachWrapper,TextWrapper} from './styles';
import React from 'react';

interface TotalReachRenderProps {
	reach:string
}

export const TotalReachRender = ({reach}:TotalReachRenderProps): JSX.Element => {
	return <TotalReachWrapper>
		<TextWrapper>
			<h6>Total Reach</h6>
			<p>{reach}</p>
			<p>Instagram</p>
		</TextWrapper>
		<ReachIcon/>
	</TotalReachWrapper>;
};