import React, {ChangeEvent, Ref, useEffect, useRef, useState} from 'react';
import {ListInput} from 'interfaces';
import {DoubleRangeRender} from './DoubleRangeRender';
import {abbreviateMount} from '../../../tools/prices';

interface DoubleRangeInput extends Omit<ListInput, 'options'>{
	suffix?:string
	prefix?:string
	minValue?:number
	maxValue?:number

	styleProps?:{
		maxAlias?:string
		minAlias?:string
		decimals?:number
	}
}


export const DoubleRange=({minValue,maxValue,name,suffix='',prefix='',value=[],onChange,styleProps={}, ...props}:DoubleRangeInput):JSX.Element=>{
	const transformLeftValue=():string=>leftValue?leftValue.toString():minValue.toString();
	const transformRightValue=():string=>rightValue?rightValue.toString():maxValue.toString();
	
	const setupValue=():void=>{
		if(!value || value.length <2){
			value= [minValue.toString(), maxValue.toString()];
		}
		value[0] = Number(value[0]) < minValue ? minValue.toString() : value[0];
		value[0] = Number(value[0]) > maxValue ? maxValue.toString() : value[0];

		value[1] =  Number(value[1]) < minValue ? minValue.toString() : value[1];
		value[1] =  Number(value[1]) > maxValue ? maxValue.toString() : value[1];
	};
	
	const {minAlias,maxAlias,decimals}:any=styleProps;

	const showValue=(value:number|string):string=>`${prefix}${value}${suffix}`;
	const minGap:number=0;
	const rangeLeft:Ref<HTMLInputElement>|null =useRef(null);
	const rangeRight:Ref<HTMLInputElement>|null =useRef(null);
	let leftValue:number=parseInt(rangeLeft.current?.value || value[0]) || null;
	let rightValue:number=parseInt(rangeRight.current?.value || value[1]) ||null;

	const getPercentage=(value:number):number=>{
		const res:number= (value-minValue)/(maxValue-minValue);
		return res*100;
	};

	const [leftPercentage,setLeftPercentage]=useState<number>(getPercentage(leftValue));
	const [rightPercentage,setRightPercentage]=useState<number>(getPercentage(rightValue));

	useEffect(():void=>{
		setupValue();
		if(!value || value.length <2){
			onChange({name,value:[leftValue,rightValue]});
		}

		if(parseFloat(value[0])>parseFloat(value[1]) || parseFloat(value[0])<minValue){
			onChange({name,value:[minValue,rightValue]});
			leftValue=minValue;
			setLeftPercentage(getPercentage(minValue));
		}

		if(parseFloat(value[1])>maxValue){
			onChange({name,value:[transformLeftValue(),maxValue.toString()]});
			rightValue=maxValue;
			setRightPercentage(getPercentage(maxValue));
		}
	},[]);

	useEffect(():void=>{
		if(rangeLeft.current && rangeRight.current){
			setupValue();
			rangeLeft.current.value=value[0] ;
			rangeRight.current.value=value[1];
			setLeftPercentage( getPercentage( Number(value[0] )) );
			setRightPercentage( getPercentage( Number(value[1] )) );
		}
	},[rangeLeft,rangeRight,value]);


	const onChangeMin=(e:ChangeEvent<HTMLInputElement>):void=> {
		const newValue:number=parseInt(e.target.value);
		if( rightValue-newValue <= minGap){
			rangeLeft.current.value=transformRightValue();
		}
		(newValue>rightValue-1) && (e.target.value=(rightValue-1).toString());

		setLeftPercentage(getPercentage(parseFloat(rangeLeft.current?.value)));
	};

	const onChangeMax=(e:ChangeEvent<HTMLInputElement>):void=> {
		const newValue:number=parseInt(e.target.value);
		if( newValue-leftValue <= minGap){
			rangeRight.current.value=transformLeftValue();
		}
		(newValue<leftValue+1) && (e.target.value=(leftValue+1).toString());

		setRightPercentage(getPercentage(parseFloat(rangeRight.current?.value)));
	};

	const onMouseUpLeft=():void=>{
		onChange({
			name,
			value: [parseFloat(rangeLeft.current.value),parseFloat(value[1]) ]
		});
	};

	const onMouseUpRight=():void=>{

		onChange({
			name,
			value: [parseFloat(value[0]),parseFloat(rangeRight.current.value)]
		});
	};

	const onTouchEndRight =():void=>onMouseUpRight();
	const onTouchEndLeft =():void=>onMouseUpLeft();

	const valueLabel=(value:number,alias:string,refValue:number):string=>{
		if (refValue===value && (!!alias || alias == '0') ){
			return alias;
		}
		return abbreviateMount(value,decimals);
	};

	return <DoubleRangeRender
		value={value}
		leftPercentage={leftPercentage}
		rightPercentage={rightPercentage}
		range1={rangeLeft}
		range2={rangeRight}
		values={{
			min:minValue,
			max:maxValue
		}}
		labels={{
			leftValue:valueLabel(leftValue,minAlias,minValue),
			rightValue:valueLabel(rightValue,maxAlias,maxValue),
			minValue: minAlias || showValue(abbreviateMount( minValue,decimals )),
			maxValue: maxAlias || showValue(abbreviateMount( maxValue,decimals ))
		}}
		actions={{
			onChangeMin : onChangeMin,
			onChangeMax : onChangeMax,
			onMouseUpLeft : onMouseUpLeft,
			onMouseUpRight : onMouseUpRight,
			onTouchEndLeft: onTouchEndLeft,
			onTouchEndRight: onTouchEndRight,
			showValue : showValue
		}}
		{...props}
	/>;
};