import React, {useEffect, useState} from 'react';
import {QueryTuple, useLazyQuery} from '@apollo/client';
import {query} from 'actions/queries';
import {TacticI} from 'interfaces';
import {SetterOrUpdater, useSetRecoilState} from 'recoil';
import {BreadCrumbAtom, TCrumb} from 'context/recoil/store';
import {Params, useParams} from 'react-router-dom';
import OpportunitiesFeaturedRender from './OpportunitiesFeatured.render';

export const OpportunitiesFeaturedLoad = ():JSX.Element => {
	const setBreadCrumb: SetterOrUpdater<TCrumb[]>=useSetRecoilState(BreadCrumbAtom);
	const {slug}:Params = useParams();


	useEffect(():void => {
		setBreadCrumb([
			{id: 'opportunities', label: 'opportunities', path:'/opportunities'},
			{id: 'feature', label: slug === 'new' ? 'Row based on upload date' : 'Row based on expiration date', path:`/opportunities/featured/${slug === 'new' ? 'new' : 'expiring'}`}
		]);
	}, [slug]);


	return (
		<OpportunitiesFeaturedRender/>
	);
};
