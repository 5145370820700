import React, {useEffect} from 'react';
import TalentBrandsRender from './TalentBrandsRender';
import {QueryResult, useQuery} from '@apollo/client';
import {query} from '../../../../actions/queries';

interface TalentBrandsProps {
	user: string
}

export default function TalentBrands({user}:TalentBrandsProps):JSX.Element {

	const {data, error, loading}:QueryResult=useQuery(query('instagramAudienceFindOne'),{
		variables:{
			filter : {
				user
			}
		}//,
		//fetchPolicy:'network-only'
	});

	useEffect(():void => {
		console.log('Talent Brands', data);
	}, [data]);

	return (
		<>
			{!loading && <TalentBrandsRender brands={data?.instagramAudienceFindOne?.audienceBrand} />}
		</>

	);
}