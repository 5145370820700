interface Color {
	name: string,
	hex: string
}
const colors: Array<Color> = [
	{
		name: 'transparent',
		hex: 'transparent'
	},
	{
		name: 'white',
		hex: '#ffffff'
	},
	{
		name: 'black',
		hex: '#000000'
	},
	{
		name: 'primary',
		hex: '#FF5656'
	},
	{
		name: 'primary-100',
		hex: '#FF5656'
	},
	{
		name: 'primary-200',
		hex: '#FF7272'
	},
	{
		name: 'primary-300',
		hex: '#FF8888'
	}, {
		name: 'text',
		hex: '#FF7272'
	},
	{
		name: 'text-inverted',
		hex: '#FF8888'
	},
	{
		name: 'neutral-',
		hex: '#FF8888'
	},
	{
		name: 'neutral',
		hex: '#181818'
	},
	{
		name: 'neutral-100',
		hex: '#EEEEEE'
	},
	{
		name: 'neutral-200',
		hex: '#AFB4D0'
	},
	{
		name: 'neutral-300',
		hex: '#6F7386'
	},
	{
		name: 'neutral-400',
		hex: '#818184'
	},
	{
		name: 'neutral-500',
		hex: '#49494C'
	},
	{
		name: 'neutral-600',
		hex: '#313144'
	}
	, {
		name: 'neutral-700',
		hex: '#252533'
	},
	{
		name: 'neutral-800',
		hex: '#171720'
	},
	{
		name: 'neutral-840',
		hex: '#191A23'
	},
	{
		name: 'neutral-900',
		hex: '#13141A'
	},
	{
		name: 'neutral-1000',
		hex: '#0E0F14'
	},
	{
		name: 'success-100',
		hex: '#35F28C'
	},
	{
		name: 'success-200',
		hex: '#30DA7E'
	},
	{
		name: 'success-300',
		hex: '#209154'
	},
	{
		name: 'error-100',
		hex: '#F23557'
	},
	{
		name: 'error-200',
		hex: '#C22A46'
	},
	{
		name: 'error-300',
		hex: '#912034'
	},
	{
		name: 'warning-100',
		hex: '#F2EA35'
	},
	{
		name: 'warning-200',
		hex: '#DAD330'
	},
	{
		name: 'warning-300',
		hex: '#A9A425'
	},
	{
		name: 'purple',
		hex: '#8D6CEC'
	}


];
type colorOpts = typeof colors[number]['name'];
type ColorMap = Map<colorOpts, string>;

const colorsArray: any[] = colors.map((color: Color): string[] => Object.values(color));
const color: ColorMap = new Map<colorOpts, string>(colorsArray);


// eslint-disable-next-line @typescript-eslint/typedef
let style = colors.map(
	({ name, hex }: any): string => {
		return (
			`.t-${name}, .hover\\:t-${name}:hover{ color: ${hex}; }
.${name}, .hover\\:${name}:hover{ background-color: ${hex}; fill: ${hex} }
.b-${name}, .hover\\:b-${name}:hover, .focus\\:b-${name}:focus{ border-color: ${hex}; stroke: ${hex}; }
.sh-4-${name}, .hover\\:sh-4-${name}:hover, .focus\\:sh-4-${name}:focus{box-shadow: 0px 0px 4px ${hex};}
.sh-10-${name}, .hover\\:sh-10-${name}:hover, .focus\\:sh-10-${name}:focus{filter: drop-shadow(0px 0px 10px ${hex});}
.sh-20-${name}, .hover\\:sh-20-${name}:hover, .focus\\:sh-20-${name}:focus{filter: drop-shadow(0px 0px 20px ${hex});}
@media (max-width: 640px) {
.mobile\\:${name} { background-color: ${hex}}
.mobile\\:b-${name} { border-color: ${hex}; stroke: ${hex};}
.mobile\\:sh-10-${name}{filter: drop-shadow(0px 0px 10px ${hex});}
}
@media (max-width: 1024px) {
.tablet\\:${name} { background-color: ${hex}}
.tablet\\:b-${name} { border-color: ${hex}; stroke: ${hex};}
.tablet\\:sh-10-${name}{filter: drop-shadow(0px 0px 10px ${hex});}
}`
		);
	}
).join(' \n\n');


// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function addcss(css: string) {
	// eslint-disable-next-line @typescript-eslint/typedef
	let head = document.getElementsByTagName('head')[0];
	// eslint-disable-next-line @typescript-eslint/typedef
	let s = document.createElement('style');
	s.setAttribute('type', 'text/css');
	// eslint-disable-next-line no-mixed-spaces-and-tabs
	// the world
	s.appendChild(document.createTextNode(css));

	head.appendChild(s);
}

addcss(style);

export { color };