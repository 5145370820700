import React from 'react';
import {BodyText, BoxTitle} from 'stylesOpus/typography';
import {BodyOpportunityText, GridContentBox, GridWrapper} from '../opportunityDetail.styles';
import {GridContainer} from 'styles/styledComponents';
import {Detail} from './Detail';
import dayjs from 'dayjs';
import {Section} from 'styles/styledComponents/containers';
import {CulturalAlert} from 'interfaces';
import {getRemainDays, getRemainHours} from 'tools/date';
import {ReactComponent as CalendarIcon} from 'styles/icons/calendar.svg';
import {ReactComponent as LocationIcon} from 'styles/icons/location.svg';
import {ReactComponent as LinkIcon} from 'styles/icons/link.svg';
import styled from 'styled-components';
import {AddFavoriteButton} from '../../../components';
import {useModal} from '../../../hooks';
import {ModalHook} from '../../../hooks/useModal';
import {Modal} from '../../../components/Modal';
import Popup from '../../../components/Popup';

type OpportunityDetailsProps = {
    alert:CulturalAlert
}

export const DetailGrid:any =styled(GridWrapper)`
  >div{
    display: grid;
    gap: 0;
  }

  >div{
    grid-template-columns: repeat(3, 1fr);
  }
   >div:nth-child(1){
     grid-template-columns: 6fr 4fr;
   }
	>div:last-child{
		>div:last-child{
			border-right: none;
		}
	}
`;

const TestGridCont :any = styled(GridContainer)<{color:string}>`
    height: 200px;
    background-color: ${({color}:any):string=>color || 'white'};
`;

export const OpportunityDetails =({alert}:OpportunityDetailsProps): JSX.Element => {
	const location:string = (alert?.state || alert?.city) ? `${alert?.city||''}, ${alert?.state||''}`:'';

	const releaseDate:string = alert.eventFrom;
	const shootDate:string = alert.videoShootDate;
	const respondBy:string= alert.endDate || '';

	const remainingDays:number = getRemainDays( new Date(),new Date(respondBy) );
	const remainingHours:number = getRemainHours( new Date(),new Date(respondBy));
	const longDate :string[] =dayjs(respondBy).format('dddd, MMMM DD, YYYY by h:mm A').split('by');
	const date:string = longDate[0];
	const hour:string = longDate[1];

	const productModal:ModalHook = useModal(false);
	return (
		<Section className='pt-2'>
			<div className={'w-100 flex justify-between align-center'}>
				<BoxTitle fontSize={'20px'}>Opportunity Details</BoxTitle>
				<AddFavoriteButton culturalAlertId={alert._id} />
			</div>
			<DetailGrid >

				<GridContainer $colGap={'0'} $columns={'6fr 4fr'} className={'first'}>

					<GridContentBox>
						<Detail title={alert?.title}>
							<div className="f-urbanist f-12px op-60 lh-150 rich-text" dangerouslySetInnerHTML={{__html: alert?.description}} />
						</Detail>
					</GridContentBox>
					<GridContentBox $padding ='2rem 6.88rem 2rem 6.91rem'>
						<div className={'w-100 h-100 flex direction-column align-center'}>
							<BoxTitle fontSize={'1.2rem'} textAlign={'center'} className={'pb-half'}>Respond By</BoxTitle>
							{respondBy ?
								<div className={'h-100 flex direction-column align-center justify-center row-gap-2'}>
									<div className={'flex col-gap-half align-center'}>
										<BodyText fonSize={'0.875rem'} className={'center'}>
                                            Day(s)
										</BodyText>
										<BoxTitle fontSize={'1.875rem'} as={'span'}>{remainingDays< 0 ? 0 : remainingDays}</BoxTitle>

										<BodyText fonSize={'0.875rem'} className={'center'}>
                                            Hour(s)
										</BodyText>
										<BoxTitle fontSize={'1.875rem'}  as={'span'}>{ remainingHours < 0 ? remainingHours * -1: remainingHours}</BoxTitle>
									</div>
									<div className={'flex'}>
										<p className={'f-12px t-neutral-300'}>{ date } by
											<span className={'f-12px t-white'}> { hour }</span>
										</p>
									</div>
								</div>
								:
								null
							}
						</div>
					</GridContentBox>

				</GridContainer>

				<div className={'grid-3'}>


					<GridContentBox>
						<Detail title={'Location'}>
							<div className={'h-100 flex align-end'}>
								<div className={'h-100 w-100 flex col-gap'}>
									<LocationIcon width={16}/>
									{alert?.eventLocation ?
										<div>
											<BodyOpportunityText>
												{alert?.eventLocation}
											</BodyOpportunityText>
											<BodyOpportunityText fontWeight={'500'}>
												{location}
											</BodyOpportunityText>

										</div>
										:
										<div>
											<BodyOpportunityText>
												TBD
											</BodyOpportunityText>
										</div>
									}
								</div>
							</div>
						</Detail>
					</GridContentBox>

					<GridContentBox>
						<Detail title={'Release Date'}>
							<div className={'h-100 flex'}>
								<div className={'h-100 flex col-gap'}>
									<CalendarIcon width={16}/>
									<BodyOpportunityText>
										{alert?.eventFrom ?
											new Date(alert?.eventFrom)?.toLocaleString('en-US', {
												month: 'short',
												day: 'numeric',
												year: 'numeric'
											})
											:
											'TBD'
										}
									</BodyOpportunityText>
								</div>
							</div>
						</Detail>
					</GridContentBox>

					<GridContentBox>
						<Detail title={'Estimated Cost'}>
							<BodyOpportunityText>
								${alert?.feeRange?.min.toLocaleString()} - ${alert?.feeRange?.max.toLocaleString()}
							</BodyOpportunityText>
						</Detail>
					</GridContentBox>

					{alert.videoShootDate &&
						<GridContentBox>
							<Detail title={'Shoot Date'}>
								<div className={'h-100 flex'}>
									{
										alert.videoShootDate ?
											<div className={'flex col-gap'}>
												<CalendarIcon width={16}/>
												<BodyOpportunityText>
													{dayjs(shootDate).format('MMMM DD, YYYY')}
												</BodyOpportunityText>
											</div>
											:
											null
									}
								</div>
							</Detail>
						</GridContentBox>
					}


					{alert.website &&
						<GridContentBox>
							<Detail title={'Website'}>
								<a href={alert.website} target="_blank" rel="noreferrer" className={'flex col-gap-half align-center'}>
									<LinkIcon/>
									<BodyOpportunityText>{alert.website}</BodyOpportunityText>
								</a>
							</Detail>
						</GridContentBox>
					}

					{alert.videoSongTitle &&
						<GridContentBox>
							<Detail title={'Song Title'}>
								<BodyOpportunityText>{alert.videoSongTitle}</BodyOpportunityText>
							</Detail>
						</GridContentBox>
					}

					{alert.videoDirector &&
						<GridContentBox>
							<Detail title={'Video Director'}>
								<BodyOpportunityText>{alert.videoDirector}</BodyOpportunityText>
							</Detail>
						</GridContentBox>
					}

					{alert.videoSongUrl &&
						<GridContentBox>
							<Detail title={'Audio'}>
								<a href={alert.videoSongUrl} target="_blank" rel="noreferrer" className={'flex col-gap-half align-center'}>
									<LinkIcon/>
									<BodyOpportunityText>{alert.videoSongUrl}</BodyOpportunityText>
								</a>
							</Detail>
						</GridContentBox>
					}

					{alert?.videoTreatment &&
						<GridContentBox>
							<Detail title={'Treatment'}>
								<a href={alert.videoTreatment?.url} target="_blank" rel="noreferrer" className={'flex col-gap-half align-center'}>
									<LinkIcon/>
									<BodyOpportunityText>Video Treatment</BodyOpportunityText>
								</a>
							</Detail>
						</GridContentBox>
					}

					{alert?.deck && alert?.deck?.length > 0 &&
						<GridContentBox>
							<Detail title={'Deck'}>
								<a href={alert.deck[alert?.deck?.length - 1 || 0]?.url} target="_blank" rel="noreferrer" className={'flex col-gap-half align-center'}>
									<LinkIcon/>
									<BodyOpportunityText>Deck</BodyOpportunityText>
								</a>
							</Detail>
						</GridContentBox>
					}

					{alert?.availableSlots ?
						<GridContentBox>
							<Detail title={'Available Slots'}>
								<BodyOpportunityText>{alert?.availableSlots.toLocaleString()}</BodyOpportunityText>
							</Detail>
						</GridContentBox>:null
					}

					{alert?.expectedAttendees ?
						<GridContentBox>
							<Detail title={'Expected Attendees'}>
								<BodyOpportunityText>{alert?.expectedAttendees.toLocaleString()}</BodyOpportunityText>
							</Detail>
						</GridContentBox> :null
					}

					{alert?.additionalFees ?
						<GridContentBox>
							<Detail title={'Additional Fees'}>
								<BodyOpportunityText>{alert?.additionalFees}</BodyOpportunityText>
							</Detail>
						</GridContentBox> :null
					}

					{alert?.paymentDetails ?
						<GridContentBox>
							<Detail title={'Payment Details'}>
								<BodyOpportunityText>{alert?.paymentDetails}</BodyOpportunityText>
							</Detail>
						</GridContentBox> :null
					}

					{alert?.product ?
						<GridContentBox>
							<Detail title={'Product'}>
								<BodyOpportunityText>{alert?.product}</BodyOpportunityText>
							</Detail>
						</GridContentBox> :null
					}

					{alert?.productDescription ?
						<GridContentBox>
							<Detail title={'Product Description'}>
								<div
									className="f-urbanist f-12px f-700 op-60 lh-150"
									dangerouslySetInnerHTML={{__html: alert?.productDescription .slice(0,150)+' ...'}}
								/>
								<p className={'mt cursor-pointer'} onClick={productModal.open}>
									Read More
								</p>
							</Detail>

							{productModal.isOpen && <Popup modal={productModal} title={'Product details'}>
								<div
									className="f-urbanist f-12px f-700 op-60 lh-150"
									dangerouslySetInnerHTML={{__html: alert?.productDescription }}
								/>
							</Popup>}
						</GridContentBox>:null
					}

					{alert?.productIntegrationGuidelines ?
						<GridContentBox>
							<Detail title={'Product Integration Guidelines'}>
								<BodyOpportunityText>{alert?.productIntegrationGuidelines}</BodyOpportunityText>
							</Detail>
						</GridContentBox>:null
					}

				</div>


			</DetailGrid>
		</Section>
	);
};