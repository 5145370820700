import {AuthRoute} from '../containers';
import {ListDetail} from '../pages';
import React from 'react';
import {Route} from './interfaces';
import Discover from '../pages/Discover';

export const discoverRoutes:Route[] =[
	{
		path:'discover',
		element:<AuthRoute redirectTo={'/'}/>,
		children:[
			{
				path: '',
				element: <Discover/>
			},
			{
				path: 'list/:id',
				element: <ListDetail/>
			}
		]
	}
];