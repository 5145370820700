import React from 'react';
import {BodyOpportunityText, GridWrapper} from '../opportunityDetail.styles';
import {Section} from 'styles/styledComponents/containers';
import styled, {keyframes} from 'styled-components';
import {Button} from 'components/ui';
import {useMutation} from '@apollo/client';
import {mutation} from 'actions/mutations';
import {ErrorText} from 'stylesOpus/typography';

type RequireSectionProps = {
  remainingDays: number
	opportunityId: string
};

type KeysProps = {
	$init: number
	$end: number
};

const translateYAnimation = ({ $init, $end }: KeysProps):any => keyframes`
  
	0% {
		transform: translateY( ${getPositionPercentage($init,'negative')}% );
	} 
	
	80% {
		transform: translateY(${getPositionPercentage($end,'negative', 5)}% );
	}
  
	100% {
		transform: translateY(${getPositionPercentage($end,'negative')}% );
	}
`;

const getPositionPercentage = (step:number, direction : 'positive' | 'negative', delta:number=0):number => {
	let pos :number = step * (100+ delta);
	return direction === 'positive' ? pos : pos * -1;
};

const Container:any = styled(GridWrapper)`
	height: 10rem;
`;

const Wrapper :any = styled.div<KeysProps>`
	height: 10rem;
	animation: ${(props:KeysProps):string=>translateYAnimation(props)} 0.4s 1 ease-in-out;
	transform: translateY(${(props:KeysProps):string=>`${getPositionPercentage(props.$end,'negative')}%`});
`;

export const InquireSection = ({remainingDays,opportunityId}:RequireSectionProps): JSX.Element => {
	const [step, setStep] = React.useState({
		init:0, end:0
	});
	const [error, setError] = React.useState(false);
	const handleStep = (step:number):void => {
		setStep((prev:any):any=>({init:prev.end, end:step}));
	};

	const [inquire] = useMutation(mutation('inquireCulturalAlert'));

	const HandleInquire = ():void => {
		inquire({
			variables: {opportunity: opportunityId}
		})
		.then(():void => handleStep(2))
		.catch((err:any):void => setError(err.message));
	};

	return (
		<Section >
			<Container >
				<Wrapper $init={step.init} $end={step.end}  className={'---- hi'}>
					<div className={'flex justify-between align-center p-3 h-100'}>
						<BodyOpportunityText className={'max-w-600px'}>
							Once you inquire, the owner of this opportunity will be notified of you interest and review your request to discuss next steps.
						</BodyOpportunityText>
						<Button
							label={'Inquire'}
							style={{opacity: remainingDays < 0 ? 0.5 : 1}}
							variant={'blue'}
							disabled={remainingDays < 0}
							// href={remainingDays <0 ? null : 'mailto:erick@opusintel.co'}
							onClick={():void => handleStep(1)}
						/>
					</div>

					<div className={'flex justify-between align-center p-3 h-100'}>
						<BodyOpportunityText className={'max-w-600px'}>
							Once you inquire, the owner of this opportunity will be notified of you interest and review your request to discuss next steps.

						</BodyOpportunityText>
						<div className={'flex direction-column align-center row-gap'}>
							<div className={'w-400px flex col-gap '}>
								<Button
									label={'Not now'}
									style={{opacity: remainingDays < 0 ? 0.5 : 1}}
									variant={'secondary-white'}
									disabled={remainingDays < 0}
									onClick={():void => handleStep(0)}
								/>
								<Button
									label={'Yes I am sure'}
									style={{opacity: remainingDays < 0 ? 0.5 : 1}}
									variant={'blue'}
									disabled={remainingDays < 0}
									// href={remainingDays <0 ? null : 'mailto:erick@opusintel.co'}
									onClick={HandleInquire}
								/>
							</div>
							{error?<ErrorText>{error ? 'An error occurred, please try again' : ''}</ErrorText> : null}
						</div>

					</div>

					<div className={'flex justify-center align-center p-3 h-100'}>
						<BodyOpportunityText className={'max-w-600px'}>
							Thank you for inquiring - we have alerted the opportunity owner. You can expect a response within 5 business days.
						</BodyOpportunityText>
					</div>
				</Wrapper>

			</Container>
		</Section>
	);
};