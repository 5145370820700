import styled from 'styled-components';
import { color } from 'styles/colors';

export const Card: any = styled.div<{img: any}>`
    width: 211px;
    height: 205px;
    background-image: ${({img}:any):string=>`url(${img})`};
    background-size: cover;
    background-position: center;
    border-radius: 12px;
    >div{
	width: 100%;
	height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  }
`;

export const CardFeed: any = styled.div<{img: any}>`
    width: 100%;
    height: 331px;
    background-image: ${({img}:any):string=>`url(${img})`};
    background-size: cover;
    background-position: center;
    border-radius: 12px;
    >div{
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0) 20%, #000000 100%);
  }
`;

export const Date: any = styled.h4`
    font-size: 16px;
    margin:0 10px 0 5px;
`;

export const New: any = styled.h4`
    font-size: 16px;
    margin-left: 10px;
    color: ${color.get('primary')};
`;

export const WrapperContain: any = styled.div`
    position: absolute;
    bottom: 20px;
    left: 10px;
    display: flex;
`;