import React, {useState} from 'react';
import {CompanyProfile, CulturalAlert, User} from 'interfaces';
import {abbreviateMount} from 'tools/prices';
import {getRemainDays} from 'tools/date';
import {TalentData} from 'hooks/useTalentDetail';
import {TalentInfo} from 'components/TalentInfo';
import {Details,AlertHeroView} from './components';
import {ContentLayout} from 'containers';
import {AlertDetailHeader} from './components/AlertDetailHeader';
import {Tab} from 'stylesOpus/ui';
import {GenericContainer} from 'styles/styledComponents/containers';
import {themeOpus} from 'stylesOpus/theme';

interface AlertDetailRenderProps {
	alert:CulturalAlert
	artist:User|null
	audience :any
	talentData:TalentData
	company:CompanyProfile|null
	followersCount:number
}

export default function AlertDetailRender({audience,talentData,alert,artist,company,followersCount}:AlertDetailRenderProps):JSX.Element {

	const location:string = (alert?.state || alert?.city) ? `${alert?.city||''}, ${alert?.state||''}`:'';
	const estimatedAsk:string = alert?.feeRange ? `${ abbreviateMount(alert?.feeRange.min)} - ${abbreviateMount( alert?.feeRange.max)}`:'';
	const lastDate:string=alert.eventTo || alert.endDate;
	const expiration:string = lastDate ? `Ends in ${getRemainDays( new Date(),new Date(lastDate))} days`:'';

	// text copies :
	const titleCopy:string = alert.culturalType==='musicvideo' ? 'Music Video' : 'Event' ;
	const [about, setAbout] = useState<boolean>(true);

	return (
		<ContentLayout
			maxWidth={'1160px'}
			header={<AlertDetailHeader artist={artist}/>}
		>
			<div id="alert" style={{background:'#0E0F14'}}>
				<AlertHeroView
					alert={alert}
					artist={artist}
					followersCount={followersCount}
					copies={{
						expiration,
						location,
						estimatedAsk
					}}
				/>

				<GenericContainer className="mver flex justify-center" padding={'0.2rem 0.25rem'}  margin=' 1rem 0'  color ='#191A23'>
					<Tab activeColor={themeOpus.colors.grey_900} selected={!about} onClick={():void=>setAbout(false)}>
						Opportunity Details
					</Tab>
					<Tab activeColor={themeOpus.colors.grey_900} selected={about} onClick={():void=>setAbout(true)}>
						Talent Audience
					</Tab>
				</GenericContainer>

				{about ?
					<div className="w-100 ">
						{artist ?
							<TalentInfo talentData={talentData} audience={audience}/>
							:
							<h1>No data is available for this artist</h1>
						}
					</div>
					:
					<Details
						alert={alert}
						company={company}
						copies={{
							title:titleCopy,
							expiration,
							location,
							estimatedAsk
						}}
					/>
				}
			</div>

		</ContentLayout>
	);
}