import styled from 'styled-components';
import {color} from '../../styles/colors';

interface TabLinkDivProps {
	selected: boolean
	styleTheme: 'opusV2' | 'opus'
}

const selectedColors:Record<'opus'|'opusV2',string>={
	opusV2: 'white',
	opus: color.get('primary')
};

export const TabLinkDiv:any = styled.div<TabLinkDivProps>`
	font-size: 1rem;
  	display: inline-flex;
  	padding: 0.75rem 1rem;
  	font-weight: ${(p:TabLinkDivProps):string => p.selected ? 'bold' : 'normal'};
  	color: ${(p:TabLinkDivProps):string => p.selected ? '#fff' : '#757575'};
  	border-bottom: 2px solid ${(p:TabLinkDivProps):string => p.selected ? selectedColors[p.styleTheme] : 'transparent'};
	
	${( { styleTheme }:TabLinkDivProps ):string=>styleTheme==='opusV2' && `font-family: 'Urbanist', sans-serif;`}
	
`;