import React, {useEffect} from 'react';
import { QueryResult, useQuery } from '@apollo/client';
import { query } from '../../../../actions/queries';
import AuthorityCircleRender from './AuthorityCircleRender';

interface AuthorityCircleProps{
	field : string
	talent : string
	topic: string
}

export default function AuthorityCircle({field='authority', topic, talent}:AuthorityCircleProps):JSX.Element {

	const {data,loading,error}:QueryResult=useQuery(query('talentScoreFindMany'),{
		variables:{
			talent: [talent], //Ice Spice Test c5ad2120-1424-11ee-91ae-c117988020a8
			audience: {
				topic: [topic]
				// ages: {
				// 	min_age: 25,
				// 	max_age: 81
				// },
				// gender: 'female',
				// countries: [
				// 	'united states'
				// ]
			},
			platforms:[['instagram', 100000]]
		}//,
		//fetchPolicy:'cache-and-network'
	});


	return (
		<AuthorityCircleRender value={data?.talentScoreFindMany?.scores[0]?.instagram?.[field]} />
	);
}