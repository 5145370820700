import React, {useEffect, useState} from 'react';
import Selector from './form/Selector';
import {IAudienceSize, IFilter, IOption} from '../DiscoverInterfaces';
import {useRecoilState} from 'recoil';
import {filterAtom} from '../../../context/recoil/store';

interface ITalentReach {
	audienceSize?: IAudienceSize,
	setAudienceSize?: (val:IAudienceSize) => void
}

export default function TalentReach({audienceSize, setAudienceSize}:ITalentReach):JSX.Element {

	const [filter, setFilter] = useRecoilState<IFilter>(filterAtom);
	const [loading, setLoading] = useState<boolean>(true);

	const [options, setOptions] = useState<IOption[]>([
		{
			_id: '1',
			label : '1K - 10K'
		},
		{
			_id: '2',
			label : '10K - 50K'
		},
		{
			_id: '3',
			label : '50K - 500K'
		},
		{
			_id: '4',
			label : '500K - 1M'
		},
		{
			_id: '5',
			label : '1M - 5M'
		},
		{
			_id: '6',
			label : '5M+'
		},
		{
			_id: '0',
			label : 'All'
		}
	]);
	const [fieldValue, setFieldValue] = useState<string>('All');

	/**
	 * Update fieldValue
	 */
	useEffect(():void=> {
		switch (fieldValue){
			case '1K - 10K':
				setFilter({...filter, audienceSize: {min:1000, max:10000}});
				break;
			case '10K - 50K':
				setFilter({...filter, audienceSize: {min:10000, max:50000}});
				break;
			case '50K - 500K':
				setFilter({...filter, audienceSize: {min:50000, max:500000}});
				break;
			case '500K - 1M':
				setFilter({...filter, audienceSize: {min:500000, max:1000000}});
				break;
			case '1M - 5M':
				setFilter({...filter, audienceSize: {min:1000000, max:5000000}});
				break;
			case '5M+':
				setFilter({...filter, audienceSize: {min:5000000, max:500000000}});
				break;
			case 'All': {
				let f: IFilter = {...filter};
				delete f.audienceSize;
				setFilter(f);
				break;
			}
		}
	},[fieldValue]);

	/**
	 * Initial fieldValue
	 */
	useEffect(():void => {
		if(loading) {
			if(filter?.audienceSize?.min){
				const min:number = filter?.audienceSize?.min || 0;

				switch (min) {
					case 1000:
						setFieldValue('1K - 10K');
						break;
					case 10000:
						setFieldValue('10K - 50K');
						break;
					case 50000:
						setFieldValue('50K - 500K');
						break;
					case 500000:
						setFieldValue('500K - 1M');
						break;
					case 1000000:
						setFieldValue('1M - 5M');
						break;
					case 5000000:
						setFieldValue('5M+');
						break;
					case 0:
						setFieldValue('All');
						break;
				}

			}
			setLoading(false);
		}
	},[loading]);

	return (
		<Selector title='Select Desired Reach' value={fieldValue} options={options} setFieldValue={setFieldValue} />
	);
}